import dayjs, { ConfigType } from 'dayjs'

export function friendlyDate(
  date?: string | Date,
  format = 'MM/DD/YYYY hh:mm A',
  fallback = 'N/A',
) {
  if (!date) return fallback

  return dayjs.utc(date).local().format(format)
}

export function dateToStringWithLocalTimezoneAbbr(
  date?: string | Date | ConfigType,
  format = 'ddd, MMM DD hh:mm a',
) {
  if (!date) return null
  const tz = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2]

  return dayjs(date).format(`${format} [${tz}]`)
}
