import { Icon, type IconName } from '@fv/client-components'
import {
  type AccountSettingsKey,
  type ShippingLabelSize,
} from '@fv/client-types'

import { type ShippingOptionConfig } from './types'

export const sheetOptions: ShippingOptionConfig[] = [
  { size: '8.5x11', label: '8&#189; x 11' },
  { size: '5.5x8.5', label: '5&#189; x 8&#189' },
  { size: '4x3.3', label: '4 x 3&#8531;' },
  { size: '4x2', label: '4 x 2' },
  { size: '3.5x5', label: '3&#189; x 5' },
]

export const rollOptions: ShippingOptionConfig[] = [
  { size: '4x8', label: '4 x 8' },
  { size: '4x6', label: '4 x 6' },
  { size: '3x5', label: '5 x 3' },
  { size: '3x4', label: '3 x 4' },
  { size: '4x2.125', label: '4 x 2⅛' },
  { size: '4x1', label: '4 x 1' },
]

export type LabelSizeChange = (size: ShippingLabelSize) => void

const ShippingLabelOptions = ({
  label,
  icon,
  options,
  setting,
  selection,
  onChange,
}: {
  label: string
  icon: IconName
  options: ShippingOptionConfig[]
  setting: AccountSettingsKey
  selection: string | undefined
  onChange: LabelSizeChange
}) => {
  return (
    <div className="settings-label-sizes flex items-center">
      <span>
        <Icon icon={icon} className="color-dark fa-fw" />
        <span className="settings-label-sizes__descriptor">{label}</span>
      </span>
      {options.map(o => (
        <div className="radio" key={`${setting}.${o.size}`}>
          <input
            checked={selection === o.size}
            onChange={v => onChange(o.size)}
            className="radio__input"
            id={`${setting}.${o.size}`}
            name={setting}
            type="radio"
          />
          <label htmlFor={`${setting}.${o.size}`} className="radio__display" />
          <label
            htmlFor={`${setting}.${o.size}`}
            className="radio__label"
            dangerouslySetInnerHTML={{ __html: o.label }}
          />
        </div>
      ))}
    </div>
  )
}

export const ShippingLabelSizeForm = ({
  onChange,
  setting,
  selection,
}: {
  onChange: LabelSizeChange
  setting: AccountSettingsKey
  selection: string | undefined
}) => (
  <>
    <ShippingLabelOptions
      icon="file"
      label="Sheet"
      options={sheetOptions}
      setting={setting}
      selection={selection}
      onChange={onChange}
    />
    <hr />
    <ShippingLabelOptions
      icon="toilet-paper-under"
      label="Roll"
      options={rollOptions}
      setting={setting}
      selection={selection}
      onChange={onChange}
    />
  </>
)
