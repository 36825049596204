import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'

export type MessageParticipant = {
  carrierId?: string
  deliveryStatus?: string
  email?: string
  id?: string
  isAuthor?: boolean
  name?: string
  readDate?: string
  role: 'carrier' | 'shipper' | 'system'
}

export type Message = {
  _id: string
  author?: MessageParticipant // Added here in `select`
  createdDate: string
  messageText: string
  participants: MessageParticipant[]
  subType: 'audit' | 'spot-quote'
  type:
    | 'award'
    | 'cancel'
    | 'change'
    | 'confirm'
    | 'decline'
    | 'dispute'
    | 'message'
    | 'not-awarded'
    | 'quote'
    | 'reject'
    | 'request'
    | 'retract-tender'
    | 'retract'
    | 'share'
    | 'tender'
    | 'tracking-update'
    | 'upload'
}

export const messageKeys = {
  all: ['messages'] as const,
  load: (id?: string) => [...messageKeys.all, id ?? null] as const,
}

async function fetchMessages(loadId?: string): Promise<Message[]> {
  if (!loadId) throw new Error('`loadId` required to fetch messages')

  const endpoint = `${apiUri}/shipments/${loadId}/messages`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useMessages(opts: {
  carrierId?: string
  loadId?: string
  userId?: string
}) {
  const { carrierId, loadId, userId } = opts

  const select = useCallback(
    (data: Message[]) => {
      if (!data || (!carrierId && !userId)) return []

      const messages: Message[] = []

      data.forEach(m => {
        const participant = m.participants.find(p => {
          if (carrierId) return carrierId === p.carrierId
          return userId && userId === p.id
        })

        if (!participant) return

        const author = participant.isAuthor
          ? participant
          : m.participants.find(p => p.isAuthor)

        messages.push({ ...m, author })
      })

      return messages.sort((a, b) => b.createdDate.localeCompare(a.createdDate))
    },
    [carrierId, userId],
  )

  return useQuery(messageKeys.load(loadId), () => fetchMessages(loadId), {
    enabled: Boolean(loadId),
    select,
  })
}
