import { type FormAddressBase } from '@fv/client-types'
import { type Country } from '@fv/models'

export interface FormCustomsBroker extends FormAddressBase {
  companyName: string
  email: string
  phone: string
  phoneExt: string
  value: string
  type: 'import' | 'export' | ''
}

export type CustomsBroker = Partial<
  Omit<FormCustomsBroker, 'country' | 'value'>
> & {
  companyName: string
  country?: Country
  phone: string
  value?: number
}

export const customsBrokerTypes = ['import', 'export'] as const
export type CustomsBrokerType = (typeof customsBrokerTypes)[number]
