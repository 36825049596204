import { Outlet, useLocation } from 'react-router-dom'

import { TabLink, TabLinkList } from '../../../components/TabLink'
import { HasFeature } from '../../auth'

export const ContractedRatesPage = () => {
  const { pathname } = useLocation()
  return (
    <div className="settings-main">
      <TabLinkList className="pb-4">
        <TabLink
          to="."
          icon="file-contract"
          isActive={!pathname.includes('add') && !pathname.includes('fuel')}
        >
          <span>Your contracted rates</span>
        </TabLink>
        <TabLink to="add" icon="plus">
          Add contracted rates
        </TabLink>

        <HasFeature name="fuelAndDistance">
          <TabLink to="fuel" icon="gas-pump">
            Account fuel table
          </TabLink>
        </HasFeature>
      </TabLinkList>

      <Outlet />
    </div>
  )
}
