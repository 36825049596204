import { apiUri } from '@fv/client-core'

import { type LoadDocumentDTO } from '../types'

type InlineInvoiceDisplayProps = {
  loadId: string
  document: LoadDocumentDTO
}

const iframeStyle = {
  backgroundColor: 'transparent',
  height: 500,
  padding: '20px 0',
  width: '100%',
}

export const InlineInvoiceDisplay = ({
  loadId,
  document,
}: InlineInvoiceDisplayProps) => {
  if (document.mimeType === 'application/pdf') {
    return (
      <iframe
        frameBorder={0}
        src={`${apiUri}/audit/${loadId}/invoice-pdf`}
        style={iframeStyle}
        title="Invoice"
      />
    )
  }

  if (document.mimeType.indexOf('image') >= 0) {
    return (
      <div className="mt-5 items-center justify-center bg-[#525659]">
        <div className="flex w-full items-center justify-center bg-[#323639] p-4 text-sm text-white shadow-md">
          Invoice image
        </div>
        <div className="flex max-h-[22rem] items-center justify-center overflow-auto p-4">
          <img
            src={document.url}
            alt="Carrier invoice"
            className="block max-w-full bg-white p-6"
          />
        </div>
      </div>
    )
  }

  return null
}
