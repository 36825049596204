import { flexRender, Row, SortDirection } from '@tanstack/react-table'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Fragment } from 'react/jsx-runtime'

import { FvTableResult } from '@fv/client-core'

import { FvLinkButton } from './FvButton'
import { Icon } from './Icon'

export interface FvTableProps<TData> {
  table: FvTableResult<TData>
  tableClassName?: string
  theadClassName?: string
  thClassName?: string
  tbodyClassName?: string
  trClassName?: string
  tdClassName?: string
  renderSubRow?: (row: Row<TData>) => ReactNode
}

export function FvTable<TModel>(props: FvTableProps<TModel>) {
  const {
    table: { table },
  } = props
  return (
    <>
      <table className={clsx(props.tableClassName)}>
        <thead className={clsx(props.theadClassName)}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="">
              {headerGroup.headers.map(header => (
                <th
                  className={clsx(
                    props.thClassName,
                    header.column.columnDef.meta?.className,
                  )}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <FvLinkButton
                      onClick={header.column.getToggleSortingHandler()}
                      theme={
                        header.column.getCanSort() ? 'underlined' : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <TableSortIcon sort={header.column.getIsSorted()} />
                    </FvLinkButton>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={clsx(props.tbodyClassName)}>
          {table.getRowModel().rows.map(row => {
            return (
              <Fragment key={row.id}>
                <tr className={clsx(props.trClassName)}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        className={clsx(
                          props.tdClassName,
                          cell.column.columnDef.meta?.className,
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    )
                  })}
                </tr>
                {row.getIsExpanded() && props.renderSubRow && (
                  <>{props.renderSubRow(row)}</>
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export const TableSortIcon = ({ sort }: { sort: false | SortDirection }) => {
  if (!sort) return null

  return (
    <Icon className="ml-2" icon={sort === 'asc' ? 'arrow-up' : 'arrow-down'} />
  )
}
