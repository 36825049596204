import { flexRender } from '@tanstack/react-table'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { FvVirtualizedTableResult } from '@fv/client-core'

import { FvLinkButton } from './FvButton'
import { TableSortIcon } from './FvTable'

interface Props<TData> {
  table: FvVirtualizedTableResult<TData>
  loadMore?: ReactNode
  tableClassName?: string
  theadClassName?: string
  theadRowClassName?: string
  thClassName?: string
  tbodyClassName?: string
  trClassName?: string
  tdClassName?: string
}

export function VirtualizedTable<TModel>(props: Props<TModel>) {
  const {
    table: { table, rowVirtualizer },
  } = props
  const { rows } = table.getRowModel()
  return (
    <>
      <table className={clsx(props.tableClassName)}>
        <thead className={clsx(props.theadClassName)}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className={clsx(props.theadRowClassName)}>
              {headerGroup.headers.map(header => (
                <th
                  className={clsx(
                    'relative',
                    props.thClassName,
                    header.column.columnDef.meta?.className,
                  )}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <FvLinkButton
                      onClick={header.column.getToggleSortingHandler()}
                      theme={
                        header.column.getCanSort() ? 'underlined' : undefined
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <TableSortIcon sort={header.column.getIsSorted()} />
                    </FvLinkButton>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className={clsx('relative', props.tbodyClassName)}
          style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
        >
          {rowVirtualizer.getVirtualItems().map(({ start, index }) => {
            const row = rows[index]
            return (
              <tr
                data-index={index}
                ref={n => rowVirtualizer.measureElement(n)}
                key={row.id}
                className={clsx(
                  'absolute flex w-full items-stretch',
                  props.trClassName,
                )}
                style={{
                  transform: `translateY(${start}px)`,
                }}
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    className={clsx(
                      props.tdClassName,
                      cell.column.columnDef.meta?.className ?? 'flex-auto',
                    )}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
      {props.loadMore}
    </>
  )
}
