import { useCallback } from 'react'

import { usePrettyNumber } from '.'

export function useCurrency() {
  const prettyNumber = usePrettyNumber()

  const priceParts = useCallback(
    (price?: number | null) => {
      if (price === null || price === undefined) return []

      const prettyPrice = prettyNumber(Math.abs(price))
      if (!prettyPrice) return []

      const [dollars, cents] = prettyPrice.split('.')
      const paddedCents = cents ? cents.padEnd(2, '0') : '00'

      return [dollars, paddedCents]
    },
    [prettyNumber],
  )

  return priceParts
}
