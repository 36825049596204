import clsx from 'clsx'
import { type ChangeEventHandler, type ForwardedRef, forwardRef } from 'react'

import { yesNoOptions } from '../../hooks/settings/useEnumOptions'
import CheckboxField from './CheckboxField'
import { RadioField } from './RadioField'

export type YesNoToggleProps = {
  type?: 'toggle' | 'radio' | 'checkbox'
  checked?: boolean
  className?: string
  disabled?: boolean
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const YesNoInput = forwardRef(
  (
    {
      checked,
      className,
      disabled,
      name,
      onChange,
      type = 'toggle',
    }: YesNoToggleProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    if (type === 'radio') {
      return (
        <RadioField
          options={yesNoOptions}
          name={name}
          className={className}
          disabled={disabled}
          value={checked ? 'Yes' : 'No'}
          onChange={e => {
            onChange?.({
              ...e,
              target: {
                ...e.target,
                checked: e.target.value === 'Yes',
              },
            })
          }}
        />
      )
    }
    if (type === 'checkbox') {
      return (
        <CheckboxField
          label="Yes"
          ref={ref}
          className={clsx('checkbox checkbox--vertical', className)}
          checked={checked}
          onChange={onChange}
          name={name}
          id={name}
          disabled={disabled}
        />
      )
    }
    return (
      <div
        className={clsx(className, {
          disabled,
          'yes-no-toggle': type === 'toggle',
        })}
      >
        <input
          ref={ref}
          checked={checked}
          disabled={disabled}
          id={name}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <label htmlFor={name} />
      </div>
    )
  },
)

export default YesNoInput
