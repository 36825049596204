import capitalize from 'lodash/capitalize'

export const workflows = ['ltl', 'parcel', 'truckload'] as const
export const displayWorkflow = (workflow: Workflow) => {
  if (workflow === 'ltl') return 'LTL'

  return capitalize(workflow)
}

export type Workflow = (typeof workflows)[number]
