import { create } from 'zustand'

import { type ProductCatalogEntry, type ProductMode } from '@fv/client-types'

export type SetProduct = (product: ProductCatalogEntry) => void

export type ProductCatalogInit = {
  filter: ProductCatalogFilter
  setProduct: SetProduct
  useSlider?: boolean
}

export type ProductCatalogFilter = {
  mode: ProductMode
  onlyContainable?: boolean
}

type ProductCatalogActions = {
  close: () => void
  edit: (handlingUnitId?: string) => void
  open: (init: ProductCatalogInit) => void
  search: (searchText: string) => void
  selectProduct: SetProduct
  setFilter: (filter: ProductCatalogFilter) => void
  showForm: () => void
  showList: () => void
}

type ProductCatalogState = {
  editingId?: string
  isOpen: boolean
  useSlider: boolean
  filter: ProductCatalogFilter
  searchText: string
  setProduct?: SetProduct
  view: 'form' | 'list'
}

const initialState: ProductCatalogState = {
  editingId: undefined,
  isOpen: false,
  useSlider: true,
  filter: {
    mode: 'ltl',
  },
  searchText: '',
  setProduct: undefined,
  view: 'list',
}

export const useProductCatalog = create<
  ProductCatalogActions & ProductCatalogState
>((set, get) => ({
  ...initialState,
  close: () => set(initialState),
  edit: editingId => {
    set({
      editingId,
      view: editingId === undefined ? 'list' : 'form',
    })
  },
  setFilter: filter => set({ filter }),
  open: ({ filter, setProduct, useSlider }) => {
    set({
      filter,
      setProduct,
      useSlider: useSlider !== false,
      isOpen: true,
    })
  },
  search: searchText => set({ searchText }),
  selectProduct: product => {
    get().setProduct?.(product)
    get().close()
  },
  showForm: () => set({ view: 'form' }),
  showList: () => {
    set({
      editingId: undefined,
      view: 'list',
    })
  },
}))
