import clsx from 'clsx'
import { type ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { FvLinkButton, Icon, type IconName } from '@fv/client-components'

import { List, ListItem } from '../../components/List'
import { freightviewUri, routes } from '../../constants'
import { HasFeature, HasPermission } from '../auth'
import { useIsCarrierAccount } from '../auth/hooks'

type ItemProps = {
  icon?: IconName
  label: ReactNode
  to?: string
  href?: string
}
const SettingsNavItem = ({ to, icon, href, label }: ItemProps) => {
  const { pathname } = useLocation()
  return (
    <ListItem
      custom
      className={clsx('standard-list__action !border-fv-blue-200', {
        active: to && pathname.startsWith(to),
      })}
    >
      <FvLinkButton icon={icon} iconClass="fa-fw" to={to} href={href}>
        {label}
      </FvLinkButton>
    </ListItem>
  )
}

type Props = {
  isDemoAccount: boolean
  isRegistered: boolean
}
export const SettingsNav = ({ isDemoAccount, isRegistered }: Props) => {
  const isCarrierAccount = useIsCarrierAccount()

  if (isCarrierAccount) {
    return <nav className="settings-nav" />
  }

  return (
    <nav className="settings-nav relative">
      <div className="border-fv-blue-200 after:bg-fv-blue-500 after:animate-mouseWheel  absolute right-[2rem] top-2/4 hidden h-4 w-6 translate-y-[-50%] rounded-lg border-2 bg-white after:absolute after:top-2/4 after:h-[.3rem] after:w-[.5rem] after:translate-y-[-50%] after:rounded-full" />
      <List fcp0>
        <SettingsNavItem
          icon="toggle-on"
          to={routes.settings('user-preferences')}
          label="User preferences"
        />

        <HasPermission name="manage">
          <SettingsNavItem
            icon="toggle-on"
            to={routes.settings('account-settings')}
            label="Account settings"
          />
          <SettingsNavItem
            icon="usd-circle"
            to={routes.settings('bill-to')}
            label="Bill-to information"
          />
        </HasPermission>
        <SettingsNavItem
          icon="building"
          to="/settings/company-locations"
          label="Company locations"
        />
        <HasPermission name="manage">
          {isRegistered && (
            <SettingsNavItem
              to="/settings/company-users"
              label={
                <>
                  <span className="fa-layers fa-fw">
                    <Icon icon="male" className="fa-fw" transform="left-5" />
                    <Icon icon="female" transform="right-3" />
                  </span>
                  <span>Company users</span>
                </>
              }
            />
          )}

          <SettingsNavItem
            icon="paint-brush"
            to={routes.settings('branding')}
            label="Company branding"
          />
        </HasPermission>

        <SettingsNavItem
          icon="truck"
          to="/settings/carriers"
          label="Carriers"
        />
        <SettingsNavItem
          icon="address-book"
          to={routes.settings('address-book')}
          label="Address book"
        />
        <HasPermission name="manage">
          <SettingsNavItem
            icon="envelope"
            to={routes.settings('spot-quote')}
            label="Spot-quote groups"
          />

          <HasFeature name="truckLoad">
            <SettingsNavItem
              icon="money-check-alt"
              to={routes.contractedRates('list')}
              label="Contracted rates"
            />
          </HasFeature>
          <SettingsNavItem
            icon="box"
            to={routes.settings('product-catalog')}
            label="Product catalog"
          />
          <SettingsNavItem
            icon="tags"
            to={routes.settings('tag-management')}
            label="Tag management"
          />
        </HasPermission>
        <SettingsNavItem
          icon="key"
          to={routes.settings('integration')}
          label="API integration"
        />
        {!isDemoAccount && (
          <HasPermission name="manage">
            <SettingsNavItem
              icon="credit-card-blank"
              href={`${freightviewUri}/app#subscription`}
              label="Subscription"
            />
          </HasPermission>
        )}
      </List>
    </nav>
  )
}
