export type SpotQuoteContact = {
  carrierId: string
  carrierName: string
  email: string
  name: string
  userId: string
  requiresQuoteNumber?: boolean
}
export type SpotQuoteGroup = {
  groupId: string
  groupName: string
  users: SpotQuoteContact[]
}
export const ALL_CONTACTS_ID = '62696dfa94bcbd76265856a2'
export const UNGROUPED_CONTACTS_ID = '62696dfa94bcbd76265856a0'

export interface UpsertSpotQuoteContactDto {
  emails: string[]
}
