import { type ReactNode } from 'react'

import { ValidatedForm } from '../inputs'

type RadioButtonFormProps = {
  checked: boolean
  children: ReactNode
  id: string
  label: string
  onChange: () => void
  onValidSubmit?: () => void
}

const RadioButtonForm = ({
  checked,
  children,
  id,
  label,
  onChange,
  onValidSubmit,
}: RadioButtonFormProps) => {
  return (
    <>
      <div className="radio">
        <input
          checked={checked}
          className="radio__input"
          id={id}
          onChange={onChange}
          type="radio"
        />
        <label htmlFor={id} className="radio__display" />
        <label htmlFor={id} className="radio__label">
          {label}
        </label>
      </div>

      {checked && (
        <ValidatedForm className="form-row mb-2" onValidSubmit={onValidSubmit}>
          {children}
        </ValidatedForm>
      )}
    </>
  )
}

export default RadioButtonForm
