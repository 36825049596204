import { useMutation } from '@tanstack/react-query'
import minBy from 'lodash/minBy'

import { type FullShipment, type QuoteSelectionReason } from '@fv/client-types'

import { useUpdateCachedLoad } from '../../hooks/shipments'
import { shipperFetch } from '../../utils/shipperFetch'
import { useAccountSettings } from '../settings/account-settings/hooks'

export type QuoteReasonPayload = Omit<QuoteSelectionReason, 'user'>
const putReason = async (loadId: string, dto: QuoteReasonPayload) => {
  return shipperFetch<FullShipment>(`/shipments/${loadId}/quote-reason`, {
    body: dto,
    method: 'PUT',
  })
}
export const useUpdateQuoteReason = (loadId: string) => {
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation(
    ({ reasonType, description }: QuoteReasonPayload) =>
      putReason(loadId, {
        reasonType,
        ...(reasonType === 'other' && {
          description,
        }),
      }),
    {
      onSettled(updatedLoad, error) {
        if (!error && updatedLoad) {
          updateCachedLoad({ updatedLoad })
        }
      },
    },
  )
}

export const useUpdateMultipleQuoteReasons = () => {
  const updateCachedLoad = useUpdateCachedLoad()
  return useMutation(
    async (reasons: Record<string, QuoteReasonPayload>) => {
      return await Promise.all(
        Object.entries(reasons).map(([loadId, { reasonType, description }]) =>
          putReason(loadId, {
            reasonType,
            ...(reasonType === 'other' && {
              description,
            }),
          }),
        ),
      )
    },
    {
      onSettled(shipments, error) {
        if (!error && shipments) {
          shipments.forEach(updatedLoad => updateCachedLoad({ updatedLoad }))
        }
      },
    },
  )
}

type QuoteReasonProps = {
  amount?: number
}
export const useNeedsQuoteReason = () => {
  const settings = useAccountSettings()
  return (
    quotes: QuoteReasonProps[],
    selectedQuote: QuoteReasonProps | undefined,
  ) => {
    if (
      !selectedQuote ||
      !quotes.length ||
      !settings.booking?.requireReasonForQuoteChoice
    ) {
      return false
    }
    return !isCheapestQuote(quotes, selectedQuote)
  }
}

export const isCheapestQuote = (
  allQuotes: QuoteReasonProps[],
  quoteInQuestion?: QuoteReasonProps,
) => {
  const cheapestAmount = minBy(
    allQuotes.filter(q => q.amount !== undefined && q.amount > 0),
    r => r.amount,
  )?.amount
  return quoteInQuestion?.amount === cheapestAmount
}
