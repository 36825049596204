import { type ChangeEventHandler } from 'react'

import { FvLinkButton, Icon, Tooltip } from '@fv/client-components'
import { type SelectFieldOption } from '@fv/client-types'

import {
  InputAdornment,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { SearchField } from '../../components/inputs/SearchField'
import { SelectField } from '../../components/inputs/SelectField'
import {
  getFilterQueryData,
  isSavedFilterGroup,
  type SavedFilter,
  type SavedFilterGroup,
} from '../../types/SavedFilter'
import { getFilterDescriptions } from './FilterDescription'
import { useFilterControls } from './hooks'
import {
  useShipmentFilterActions,
  useShipmentFilterListContext,
} from './ShipmentFilterListProvider'
import { type FilterControlProps } from './types'

export const CompactFilterBar = ({ onChange }: FilterControlProps) => {
  const {
    search,
    setSearch,
    searchParams,
    filters,
    flattenedFilters,
    canSearch,
    activeFilter,
    defaultFilter,
    isCustomFilter,
  } = useFilterControls()
  const filterConfig = useShipmentFilterListContext(s => s.filterConfig)
  const { setForcePanelOpen } = useShipmentFilterActions()
  const filterDescriptions = isCustomFilter
    ? getFilterDescriptions(filterConfig, searchParams)
    : undefined
  const options = [
    ...buildOptionsFromFilters(filters),
    ...(isCustomFilter ? [{ text: 'Custom', value: 'custom' }] : []),
  ]

  const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = e => {
    const selected = flattenedFilters.find(f => f._id === e.target.value)
    handleChange(selected)
  }

  const handleChange = (filter?: SavedFilter | SavedFilterGroup) => {
    onChange?.({
      ...(filter && getFilterQueryData(filter)),
    })
  }

  const handleEditClick = () => {
    setForcePanelOpen(true)
  }

  return (
    <div className="flex flex-1 items-center gap-2">
      <InputGroupWrapper name="filter" className="mb-0">
        <InputAdornment position="start">
          <Tooltip
            label={
              filterDescriptions?.length
                ? `Custom filter > ${filterDescriptions.join(' > ')}`
                : activeFilter?.name
            }
          >
            <Icon icon="info-circle" transform="down-1" />
          </Tooltip>
        </InputAdornment>
        <InputAdornment position="start" icon="filter" className="color-dark" />
        <SelectField
          className="form-control form-control--select w-auto"
          name="filter"
          onChange={handleSelectChange}
          options={options}
          value={
            isCustomFilter ? 'custom' : (activeFilter?._id ?? defaultFilter._id)
          }
        />
      </InputGroupWrapper>
      {canSearch && (
        <SearchField
          initialValue={search}
          onChange={search => setSearch(search)}
          className="mb-0 flex-1"
        />
      )}
      <FvLinkButton onClick={handleEditClick} icon="pen-alt" className="ml-2">
        Edit filters
      </FvLinkButton>
    </div>
  )
}

const buildOptionsFromFilters = (
  filters: SavedFilter[] | SavedFilterGroup[],
): SelectFieldOption[] => {
  return filters.flatMap<SelectFieldOption>(f => [
    ...(f.isDisabled ? [] : [{ text: f.name, value: f._id }]),
    ...(isSavedFilterGroup(f)
      ? f.children.map(c => ({
          text: c.fullLabel ?? c.name ?? '',
          value: c._id,
        }))
      : []),
  ])
}
