import { PhoneNumberField } from '@fv/client-components'
import { type FormRegister, validateEmailList } from '@fv/client-core'
import { type CarrierProvisioningDetails } from '@fv/client-types'

import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { type AccountCarrierUpsertModel } from './types'

type SalesRepFormModel = Pick<
  AccountCarrierUpsertModel,
  'salesRepEmails' | 'salesRepFirstName' | 'salesRepLastName' | 'salesRepPhone'
>
type Props = {
  register: FormRegister<SalesRepFormModel>
  carrier: CarrierProvisioningDetails
  showOptionalText?: boolean
}
export function SalesRepForm({ register, carrier, showOptionalText }: Props) {
  const label = (val: string) =>
    `${val}${showOptionalText ? ' (optional)' : ''}`
  return (
    <>
      <div className="grid w-full grid-cols-2 gap-x-4 b1300:grid-cols-1">
        <InputGroup
          label={label('Carrier rep first name')}
          inputType="text"
          inputProps={{
            ...register('salesRepFirstName'),
          }}
        />
        <InputGroup
          label={label('Carrier rep last name')}
          inputType="text"
          inputProps={{
            ...register('salesRepLastName'),
          }}
        />
        <InputGroupWrapper
          label={label('Carrier rep phone')}
          name="salesRepPhone"
        >
          <PhoneNumberField
            className="form-control"
            {...register('salesRepPhone')}
          />
        </InputGroupWrapper>
        <InputGroup
          label={`Carrier rep email ${
            carrier.requireSalesRepEmail ? '' : '(optional)'
          }`}
          required={!showOptionalText && carrier.requireSalesRepEmail}
          inputType="text"
          inputProps={{
            placeholder: `joe@freightcarrier.com`,
            ...register('salesRepEmails', {
              validate: e =>
                validateEmailList(e, !!carrier.requireSalesRepEmail)
                  ? ''
                  : 'Invalid email format',
            }),
            required: carrier.requireSalesRepEmail,
          }}
        />
      </div>
    </>
  )
}
