import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type FullShipment, type Workflow } from '@fv/client-types'

import { apiUri } from '../../constants'

interface FetchRecentShipmentOpts {
  workflow: Workflow
}
async function fetchRecentShipment({
  workflow,
}: FetchRecentShipmentOpts): Promise<FullShipment> {
  const qs = new URLSearchParams({ workflow })
  const endpoint = `${apiUri}/shipments/most-recent?${qs.toString()}`
  const options = buildFetchOptionsWithAuth({
    method: 'GET',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useRecentShipment(workflow: Workflow) {
  return useQuery(
    ['recent-shipment', workflow],
    () => fetchRecentShipment({ workflow }),
    { retry: false },
  )
}
