import { type ChangeEvent } from 'react'

import {
  FvButton,
  FvLinkButton,
  TextField,
  useFvNavigate,
} from '@fv/client-components'

import { countLoads } from '../../features/quote-request/quoteRequestUtils'
import { type QuoteFormParams } from '../../features/quote-request/types'
import {
  useQuoteFormActions,
  useQuoteFormLoads,
  useQuoteFormMetadata,
} from '../../features/quote-request/useQuoteFormState'
import { useQuoteFormSubmission } from '../../features/quote-request/useQuoteFormSubmission'
import InfoBox from '../InfoBox'

type Props = {
  isEditing: boolean
  mode?: QuoteFormParams['mode']
  quoteRequestId?: string
}

export const QuoteRequestFooter = ({
  isEditing,
  mode,
  quoteRequestId,
}: Props) => {
  const { editQuoteRequest, fetchQuotes, isBusy } = useQuoteFormSubmission(
    quoteRequestId,
    mode,
  )

  const { setMetadata } = useQuoteFormActions()
  const loads = useQuoteFormLoads()
  const metadata = useQuoteFormMetadata()
  const navigate = useFvNavigate()

  function setLiveLoad(e: ChangeEvent<HTMLInputElement>) {
    setMetadata({ isLiveLoad: e.target.id === 'live-load' })
  }

  function setOrderNumber(e: ChangeEvent<HTMLInputElement>) {
    setMetadata({ orderNumber: e.target.value })
  }

  if (isEditing && !countLoads(loads)) {
    return (
      <div className="footer-actions">
        <InfoBox icon="spinner">Loading...</InfoBox>
      </div>
    )
  }

  return (
    <div className="row-start-[-1] col-start-1 col-span-3 bg-fv-blue-150 p-3 flex justify-center items-center border-fv-blue-400 border-t flex-wrap footer-actions">
      {isEditing && (
        <>
          <div className="edit-quote-request-reference flex flex-nowrap items-center">
            <span className="limit-characters mb-0">
              Editing &gt; {metadata.requestIdentifier}
            </span>
            <FvLinkButton
              className="ml-6"
              icon="times"
              transform="down-.5"
              onClick={() => navigate(-1)}
            >
              <span>Cancel editing</span>
            </FvLinkButton>
          </div>
          <hr className="col-12 mx-0 my-2 flex-none" />
        </>
      )}

      <div className="radio radio--horizontal">
        <input
          checked={!metadata.isLiveLoad}
          className="radio__input"
          id="quote-only"
          onChange={setLiveLoad}
          type="radio"
        />
        <label htmlFor="quote-only" className="radio__display" />
        <label htmlFor="quote-only" className="radio__label">
          Quote only
        </label>
      </div>
      <div className="radio radio--horizontal">
        <input
          checked={metadata.isLiveLoad}
          className="radio__input"
          id="live-load"
          onChange={setLiveLoad}
          type="radio"
        />
        <label htmlFor="live-load" className="radio__display" />
        <label htmlFor="live-load" className="radio__label">
          Live load
        </label>
      </div>
      <label
        htmlFor="orderNumber"
        className="label divider-left divider-left--dark mb-0 mr-2"
      >
        Ref#:
      </label>
      <TextField
        className="form-control w-auto"
        name="orderNumber"
        onChange={setOrderNumber}
        placeholder="i.e. 45315576"
        value={metadata.orderNumber}
      />

      <FvButton
        fwd
        theme="primary"
        className="ml-4"
        onClick={isEditing ? editQuoteRequest : fetchQuotes}
        type="button"
        icon={isBusy ? 'spinner' : isEditing ? 'pen-alt' : 'dollar-sign'}
      >
        {isEditing ? 'Update quote request' : 'Start getting quotes'}
      </FvButton>
    </div>
  )
}
