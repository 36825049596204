import clsx from 'clsx'
import upperFirst from 'lodash/upperFirst'

import { type PendingLoadToBook } from '../features/booking/types'
import {
  type QuoteReasonPayload,
  useNeedsQuoteReason,
} from '../features/quote-reason/hooks'
import { CompactQuoteReasonForm } from '../features/quote-reason/QuoteReasonForm'
import { usePrettyNumber, useTypeNames } from '../hooks/settings'
import { useQuotes } from '../hooks/shipments'
import { Price } from './shared/Price'

export type AwardCarrierLoad = Pick<
  PendingLoadToBook,
  'equipment' | 'loadId' | 'status' | 'quoteId'
>

type AwardCarrierRowProps = {
  load: AwardCarrierLoad
  quoteId?: string
  truckNumber: number
  onQuoteReasonChange?: (reason: QuoteReasonPayload) => void
}

const AwardCarrierRow = ({
  load,
  quoteId,
  truckNumber,
  onQuoteReasonChange,
}: AwardCarrierRowProps) => {
  const { equipment, loadId, status } = load
  const { equipmentName } = useTypeNames()
  const isAwarded = status === 'awarded' || status === 'confirmed'
  const prettyNumber = usePrettyNumber()
  const quotesQuery = useQuotes(loadId)
  const quote = quotesQuery.data?.find(q => q._id === quoteId)
  const needsReason = useNeedsQuoteReason()(quotesQuery.data ?? [], quote)

  return (
    <>
      <div className="flex items-start">
        <div
          className={clsx('round-cue', {
            'round-cue--callout': !isAwarded && Boolean(quote),
          })}
        >
          {truckNumber}
        </div>
        <div className="ml-4 flex-auto">
          <div className="mb-0" style={{ lineHeight: '1.4em' }}>
            Truck {truckNumber} /{' '}
            {equipmentName(quote?.equipmentType ?? equipment.type)} /{' '}
            {upperFirst(equipment.description)}
            <br />
            {prettyNumber(equipment.weight)} {equipment.weightUOM}.
            {equipment.declaredValue &&
              ` / $${prettyNumber(equipment.declaredValue)}`}
            <div className="flex items-center">
              <div>
                {quote?.providerName || 'No carriers'} selected to take this
                load.
              </div>
              <div className="flex-pinline" />
              {quote ? (
                <>
                  {isAwarded && <span>Awarded&nbsp;</span>}
                  <Price amount={quote?.amount} />
                </>
              ) : (
                <div>Not selected</div>
              )}
            </div>
          </div>
          {needsReason && onQuoteReasonChange && (
            <div className="pt-4">
              <CompactQuoteReasonForm onChange={onQuoteReasonChange} />
            </div>
          )}
        </div>
      </div>
      <hr />
    </>
  )
}

export default AwardCarrierRow
