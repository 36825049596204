import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { setDataByPage } from '@fv/client-core'

import { quoteRequestKeys } from '../../features/quote-request/useQuoteRequest'

// Used to keep load data in sync across all load queries
export function useUpdateCachedLoad() {
  const queryClient = useQueryClient()

  const updateCachedLoad = useCallback(
    ({
      getUpdatedData = Function.prototype, // Access to previous load
      loadId = null, // Required if no `updatedLoad`
      quoteRequestId = null, // Required if no `updatedLoad`
      keysToSkip = [], // Keys to ignore to avoid fetch/update loop
      updatedData = null, // Merge new data into load
      updatedLoad, // Replace entire load
    }) => {
      loadId = loadId ?? updatedLoad?.loadId
      quoteRequestId = quoteRequestId ?? updatedLoad?.quoteRequestId

      if (!loadId || !quoteRequestId) {
        return console.error(
          '`loadId` and `quoteRequestId` are required to update a cached load.',
        )
      }

      const buildNextLoad = prevLoad => {
        if (prevLoad.loadId !== loadId) return prevLoad
        if (updatedLoad) return { ...prevLoad, ...updatedLoad }
        return {
          ...prevLoad,
          ...getUpdatedData(prevLoad),
          ...updatedData,
        }
      }

      if (!keysToSkip?.includes('shipments')) {
        const queryCache = queryClient.getQueryCache()
        const shipmentListQueries = queryCache.findAll(['shipments'])
        const idleListQueries = shipmentListQueries.filter(
          q => !q.state.isFetching && !q.state.isInvalidated,
        )

        // Don't set data for queries that are currently fetching
        idleListQueries.forEach(q => {
          queryClient.setQueryData(
            q.queryKey,
            setDataByPage(data => data.map(buildNextLoad)),
          )
        })
      }

      // Update load if in use
      if (
        !keysToSkip?.includes('load') &&
        (updatedLoad || queryClient.getQueryData(['load', loadId]))
      ) {
        queryClient.setQueryData(['load', loadId], (prev = { loadId }) =>
          buildNextLoad(prev),
        )
      }

      // Update quote request if in use
      if (!keysToSkip?.includes('quote-request')) {
        queryClient.setQueriesData(
          quoteRequestKeys.request(quoteRequestId),
          (prev = []) => {
            const prevLoad = prev.find(load => load.loadId === loadId)
            if (prevLoad) return prev.map(buildNextLoad)
            return prev.concat(updatedLoad)
          },
        )
      }
    },
    [queryClient],
  )

  return updateCachedLoad
}
