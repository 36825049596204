import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

import InfoBox from './InfoBox'

type Props = PropsWithChildren<PropsWithClassName>
export const Loading = ({ children, className }: Props) => (
  <InfoBox className={className} icon="sync">
    {children}
    {!children && <>Loading...</>}
  </InfoBox>
)
