import { type Dispatch, type SetStateAction, useState } from 'react'

import { FvDate, FvLinkButton, QuoteCarrier } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { routes } from '../../../constants'
import { usePermissions } from '../../../features/auth'
import { quoteFuncs } from '../../../features/quote/quoteFuncs'
import {
  useEditBookingPage,
  useQuoteRequestResponses,
  useQuotes,
} from '../../../hooks/shipments'
import InfoBox from '../../InfoBox'
import { PricePlain } from '../../shared/Price'
import { type PanelName } from '../LoadDetailPanel'
import ShipmentActions from '../ShipmentActions'
import { PanelFooter } from './PanelFooter'
import PanelHeader from './PanelHeader'
import RatesPanel from './RatesPanel'

type Props = {
  load: FullShipment
  setActivePanel: Dispatch<SetStateAction<PanelName>>
}

export const PendingLoadDetail = ({ load, setActivePanel }: Props) => {
  const responses = useQuoteRequestResponses({
    load,
  })
  const hasAnyResponses =
    responses.active.length ||
    responses.declined.length ||
    responses.pending.length
  const [isShowingRates, setShowingRates] = useState(false)
  const [isViewingActive, setViewingActive] = useState(true)
  const { canEdit, composeEditLink } = useEditBookingPage(load)
  const { status } = load
  const hasAwardedCarrier = status === 'awarded'

  return (
    <div className="shipment-list-ancillary-details-ctn">
      <div className="shipment-list-ancillary-details overflow-auto">
        {load.pickup?.status === 'error' && (
          <InfoBox icon="exclamation-triangle">
            Booking failed: {load.pickup.error ?? 'Unknown error'}.
            {canEdit && ' Please '}
            {canEdit && (
              <FvLinkButton to={composeEditLink()} style={{ color: '#45abd3' }}>
                update this load
              </FvLinkButton>
            )}
            {canEdit && ' and try booking again.'}
          </InfoBox>
        )}

        {load.workflow !== 'truckload' && !hasAwardedCarrier && (
          <DirectRateInfoBox load={load} />
        )}

        <PanelHeader
          isShowingRates={isShowingRates}
          load={load}
          setShowingRates={setShowingRates}
          hasAnyResponses={hasAnyResponses}
        />

        {(!!hasAnyResponses || load.workflow === 'truckload') && (
          <>
            <hr className="mx-0 my-2 mb-4" />

            {(!hasAwardedCarrier || isShowingRates) && (
              <RatesPanel
                isViewingActive={isViewingActive}
                load={load}
                setShowRequestPanel={() => setActivePanel('request')}
                setViewingActive={setViewingActive}
              />
            )}

            {hasAwardedCarrier && !isShowingRates && (
              <ShipmentActions
                setActivePanel={setActivePanel}
                shipment={load}
                showRatesPanel={() => setShowingRates(true)}
              />
            )}
          </>
        )}
      </div>

      <PanelFooter
        isShowingRates={isShowingRates}
        load={load}
        setShowingRates={setShowingRates}
        setViewingActive={setViewingActive}
      />
    </div>
  )
}

const BookShipmentSentence = ({ load }: { load: FullShipment }) => {
  const { canBook } = usePermissions()
  if (!load.selectedQuote || !canBook) {
    return null
  }
  const bookRoute = routes.book({
    loadId: load.loadId,
    quoteId: load.selectedQuote?._id,
  })
  return (
    <>
      To book this shipment with the rate you selected{' '}
      <FvLinkButton theme="callout" to={bookRoute}>
        {' '}
        click here
      </FvLinkButton>
      .
    </>
  )
}

function DirectRateInfoBox({ load }: { load: FullShipment }) {
  const ratesRoute = routes.rates(load)
  const quotes = useQuotes(load.loadId)
  const spotMessage =
    'If you sent out for spot quotes, they will be shown below.'
  const quote =
    load.selectedQuote ??
    load.cheapestQuote ??
    quoteFuncs.cheapestQuote(quoteFuncs.filterActiveDirect(quotes.data))
  const quoteDate = quoteFuncs.getRecentAPIQuoteDate(load, quotes.data)

  const quoteDetail = quote ? (
    <>
      <b>
        <PricePlain amount={quote?.amount} />
      </b>{' '}
      from{' '}
      <b>
        <QuoteCarrier quote={quote} />
      </b>
      .
    </>
  ) : null

  if (load.selectedQuote) {
    return (
      <InfoBox icon="hand-paper">
        You ran this quote on{' '}
        <b>
          <FvDate val={quoteDate} format="ddd MMM D" />
        </b>
        . You saved the rate for {quoteDetail} {spotMessage}
        <BookShipmentSentence load={load} />
      </InfoBox>
    )
  }

  return (
    <InfoBox icon="hand-paper">
      You ran this quote on{' '}
      <b>
        <FvDate val={quoteDate} format="ddd MMM D" />
      </b>
      .
      {quoteDetail && (
        <>The cheapest direct rate at that time was {quoteDetail}</>
      )}
      {spotMessage}
      To compare all of your rates{' '}
      <FvLinkButton theme="underlined" to={ratesRoute}>
        click here
      </FvLinkButton>
      .
    </InfoBox>
  )
}
