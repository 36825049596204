import InfoBox from '../../../components/InfoBox'
import { type FxfeProvisionResponse } from './types'

type Props = {
  response?: FxfeProvisionResponse
  error?: string
}
export const FedexError = ({ response, error }: Props) => {
  if (response?.status !== 'error' && !error) {
    return null
  }
  const message =
    response?.errors?.map(e => e.message)?.join('. ') ??
    'There was an error' ??
    error
  return (
    <InfoBox gradient icon="exclamation-triangle">
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </InfoBox>
  )
}
