import { UsernamePasswordForm } from './components/UsernamePasswordForm'
import { useCarrierCredComponent } from './CarrierComponentProvider'
import { type CredentialFormProps } from './types'

export const AccountCarrierCredentialForm = (
  props: CredentialFormProps,
): JSX.Element => {
  const CarrierComp = useCarrierCredComponent()
  return (
    <div>
      {!CarrierComp && <UsernamePasswordForm {...props} />}
      {CarrierComp && <CarrierComp {...props} />}
    </div>
  )
}
