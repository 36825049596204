import { useRef, useState } from 'react'

import {
  FvButton,
  FvLinkButton,
  SliderPanel,
  type SliderPanelProps,
  ValidatedForm,
} from '@fv/client-components'
import { submitForm } from '@fv/client-core'

import { ProductCatalogPanelContents } from '../ProductCatalog'
import { useProductCatalog } from '../useProductCatalog'
import { ContainsRow } from './ContainsRow'
import { defaultLoadContainsItem, isValidCommodity } from './loadItemHelpers'
import { useItemActions, useItemsState } from './loadItemsContext'
import { PanelItemSummary } from './PanelItemSummary'
import {
  type ItemChangeFn,
  type LoadHandlingUnitFormModel,
  type LoadItemFormModel,
} from './types'

const isLoadItemFormModel = (
  v: LoadItemFormModel | undefined,
): v is LoadItemFormModel => {
  return v !== undefined && !!v._id
}

type Props = Pick<SliderPanelProps, 'closePanel' | 'isOpen'> & {
  handlingUnit?: LoadHandlingUnitFormModel
  editingIx?: number
  onSubmit: (contains: Pick<LoadHandlingUnitFormModel, 'contains'>) => void
}

const HandlingUnitPanelContents = ({
  handlingUnit,
  onSubmit,
  closePanel,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null)
  const { isOpen: productCatalogOpen, close: closeProductCatalog } =
    useProductCatalog()
  const { workflow, activeContainsIndex, step } = useItemsState()
  const { activateContains } = useItemActions()
  const [contains, setContains] = useState(
    handlingUnit?.contains?.filter(isLoadItemFormModel) ?? [],
  )
  const handleRemove = (index: number) => {
    setContains(prev => prev.filter((i, ix) => ix !== index))
  }
  const verifyFormValidity = (): boolean => {
    const isValid = formRef.current && formRef.current.checkValidity()
    if (!isValid) {
      submitForm(formRef.current)
    } else {
      formRef.current.reset()
    }
    return !!isValid
  }
  const handleAddClick = () => {
    if (!verifyFormValidity()) {
      return
    }
    setContains([...contains, defaultLoadContainsItem()])
    activateContains(contains.length)
  }
  const handleEditClick = (index: number) => {
    if (!verifyFormValidity()) {
      return
    }
    activateContains(index)
  }
  const handleChange =
    (index: number): ItemChangeFn =>
    value => {
      setContains(prev =>
        prev.map((p, ix) =>
          ix !== index
            ? p
            : {
                ...p,
                ...value,
              },
        ),
      )
    }

  const handleSubmit = (form: HTMLFormElement) => {
    let isValid = true
    contains.forEach((c, ix) => {
      if (!isValidCommodity(c, step)) {
        activateContains(ix)
        isValid = false
      }
    })
    if (isValid) {
      onSubmit({ contains })
    } else {
      form.checkValidity()
      submitForm(form)
    }
  }

  if (!handlingUnit) return null

  if (productCatalogOpen) {
    return (
      <div className="p-8">
        <div className="bg-fv-gray-200 -ml-8 -mt-8 mb-8 px-8 py-3">
          <FvLinkButton icon="arrow-left" onClick={closeProductCatalog}>
            Back to commodities
          </FvLinkButton>
        </div>
        <ProductCatalogPanelContents />
      </div>
    )
  }
  return (
    <ValidatedForm onValidSubmit={handleSubmit} ref={formRef}>
      <div className="blue-container-light !border-x-0 !border-t-0 p-4">
        <PanelItemSummary item={handlingUnit} workflow={workflow} />
      </div>
      <div>
        {contains.map((item, index) => (
          <ContainsRow
            key={index}
            index={index + 1}
            value={item}
            editing={activeContainsIndex === index}
            onRemoveClick={() => handleRemove(index)}
            onEditClick={() => handleEditClick(index)}
            onChange={handleChange(index)}
            isInPanel
          />
        ))}
        <div className="p-2">
          <FvButton theme="plain" icon="plus" onClick={handleAddClick}>
            Add commodity
          </FvButton>
        </div>
      </div>
      <div className="blue-container-dark fixed bottom-0 left-0 flex w-full justify-end px-4 py-6">
        <FvButton theme="plain" icon="times" onClick={closePanel}>
          Cancel
        </FvButton>
        <FvButton theme="primary" fwd icon="check" type="submit">
          Finish and save
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
export const HandlingUnitContainsPanel = (
  props: Omit<Props, 'onProductCatalogOpen'>,
) => {
  const { close: closeProductCatalog } = useProductCatalog()
  const handleClose = () => {
    closeProductCatalog()
    props.closePanel?.()
  }
  return (
    <SliderPanel
      isOpen={props.isOpen}
      closePanel={handleClose}
      position="right"
      className="!p-0"
    >
      <HandlingUnitPanelContents {...props} />
    </SliderPanel>
  )
}
