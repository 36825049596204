import clsx from 'clsx'
import { memo, useMemo } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

import { useCurrency } from '../../hooks/settings'

type Props = {
  amount?: number
  currency?: string
  className?: string
}
export const Price = memo(function Price({
  amount,
  currency,
  className,
}: Props) {
  const composePriceParts = useCurrency()
  const [dollars, cents] = amount !== undefined ? composePriceParts(amount) : []

  if (dollars === undefined || cents === undefined) return null

  return (
    <span className={clsx('price font-oxygen', className)}>
      <span className="price__dollars">${dollars}</span>
      <span className="price__cents">{cents}</span>
      {currency && (
        <span className="price__currency">{currency?.toUpperCase()}</span>
      )}
    </span>
  )
})

export const PricePlain = memo(function PricePlain({
  amount,
  className,
}: PropsWithClassName<{ amount?: number }>) {
  const composePriceParts = useCurrency()
  const [dollars, cents] = useMemo(
    () => (amount !== undefined ? composePriceParts(amount) : []),
    [amount, composePriceParts],
  )

  if (dollars === undefined || cents === undefined) return null

  return (
    <span className={className}>
      ${dollars}.{cents}
    </span>
  )
})
