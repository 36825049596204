import { create } from 'zustand'

export type OlarkHelperState = {
  loadId?: string | null
}
type Actions = {
  update: (state: Partial<OlarkHelperState>) => void
}
type StateWithActions = OlarkHelperState & { actions: Actions }
const initialState: OlarkHelperState = {
  loadId: undefined,
}

export const useOlarkStore = create<StateWithActions>(set => ({
  ...initialState,
  actions: {
    update(state) {
      set(state)
    },
  },
}))
