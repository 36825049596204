import { isHolidayOrWeekend, nextBusinessDay } from '@freightview/holidays'
import dayjs, { Dayjs, ManipulateType } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(minMax)

type ParseableDate = Date | Dayjs | string
const rangeRegex = /(-?\d*)-(-?\d*)/

export const dates = {
  now: () => dayjs.utc().toDate(),
  threeYearsFromNow: () => dayjs.utc().add(3, 'years').toDate(),
  threeYearsAgo: () => dayjs.utc().subtract(3, 'years').toDate(),
  sixMonthsAgo: () => dayjs.utc().subtract(6, 'months').toDate(),
  nineteyDaysAgo: () => dayjs.utc().subtract(90, 'days').toDate(),
  sixtyDaysAgo: () => dayjs.utc().subtract(60, 'days').toDate(),
  twoWeeksAgo: () => dayjs.utc().subtract(14, 'days').toDate(),
  thirtyDaysAgo: () => dayjs.utc().subtract(30, 'days').toDate(),
  yesterday: () => dayjs.utc().subtract(1, 'days').toDate(),
  isToday: (date: ParseableDate) => dayjs.utc(date).isSame(dates.now(), 'day'),
  daysAway: (date: ParseableDate) => dayjs.utc(date).diff(dates.now(), 'day'),

  isBefore(date: ParseableDate, compareDate: ParseableDate) {
    return dayjs.utc(date).isBefore(compareDate)
  },

  isBeforeTime(time: string, compareTime: string) {
    return this.timeToMoment(time).isBefore(this.timeToMoment(compareTime))
  },

  timeToMoment(time: string): Dayjs {
    return dayjs(`${dayjs().format('YYY-MM-DD')} ${time}`)
  },

  formatTime(time: string, timezone: string): string {
    if (!time) {
      return time
    }

    return dayjs(time, 'HH:m A', timezone).format('hh:mm A')
  },

  formatDate(date: ParseableDate, format = 'MM/DD/YYYY'): string {
    const m = dayjs.utc(date)
    if (!date || !m.isValid()) {
      return ''
    }

    return m.format(format)
  },

  max(...dates: Array<ParseableDate>): Date | undefined {
    const filtered = dates.filter(d => !!d).map(d => dayjs.utc(d))

    if (!filtered.length) {
      return
    }

    return dayjs.max(...filtered).toDate()
  },

  subtract(date: ParseableDate, amount: number, unit: ManipulateType): Date {
    return dayjs.utc(date).subtract(amount, unit).toDate()
  },

  add(date: ParseableDate, amount: number, unit: ManipulateType): Date {
    return dayjs.utc(date).add(amount, unit).toDate()
  },

  today() {
    return dayjs.utc().startOf('d').toDate()
  },

  utcNoon(date: ParseableDate): Date {
    if (!date) {
      return undefined
    }

    return dayjs.utc(date).startOf('day').add(12, 'hours').toDate()
  },

  nextAvailablePickupDate() {
    return dayjs.utc(nextBusinessDay(dates.today())).add(12, 'hours').toDate()
  },

  isDayInPast(date: ParseableDate) {
    return dayjs.utc(date).startOf('d').isBefore(dayjs.utc().startOf('d'))
  },
  getEstimatedDeliveryDate(
    pickupDate?: string | Date,
    {
      transitDaysMin,
      deliveryDate,
    }: { transitDaysMin?: number; deliveryDate?: string | Date } = {},
  ) {
    if (deliveryDate) {
      return dayjs(deliveryDate)
    }

    if (!transitDaysMin || !pickupDate) {
      return
    }

    let incrementDate = 1

    while (incrementDate <= transitDaysMin) {
      const day = dayjs(pickupDate).add(incrementDate, 'days')
      if (isHolidayOrWeekend(day.toDate())) {
        transitDaysMin += 1
      }
      incrementDate += 1
    }

    return dayjs(pickupDate).add(transitDaysMin, 'days')
  },

  parseDateRange(val: string) {
    let start: Date | undefined = undefined
    let end: Date | undefined = undefined
    const matches = val.match(rangeRegex)
    if (matches?.length > 2) {
      start = new Date(Number(matches[1]))
      end = new Date(Number(matches[2]))
    }
    return {
      start,
      end,
    }
  },
}
