import clsx from 'clsx'
import dayjs from 'dayjs'
import upperFirst from 'lodash/upperFirst'

import { Icon, type IconProps } from '@fv/client-components'

type Props = {
  icon?: IconProps['icon']
  iconClassName?: string
  summary: string
  timestamp?: string
}

export const TrackEvent = ({
  icon = 'dot-circle',
  iconClassName,
  summary,
  timestamp,
}: Props) => (
  <>
    <Icon
      className={clsx('fa-fw', iconClassName)}
      icon={icon}
      transform="down-2.5"
    />
    <p className="mb-0">{upperFirst(summary)}</p>
    {timestamp && (
      <span className="mt-[.225rem] whitespace-nowrap text-xs">
        ~ {dayjs.utc(timestamp).local().fromNow()}
      </span>
    )}
  </>
)
