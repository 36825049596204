import { type PropsWithChildren } from 'react'

import {
  FvLinkButton,
  type FvLinkButtonProps,
  Tooltip,
} from '@fv/client-components'

import { useDownloadRatesPDF } from './useDownloadRatesPDF'

type Props = FvLinkButtonProps & {
  loadId: string
  tooltip?: boolean
}

export const PrintRatesButton = ({
  loadId,
  tooltip,
  ...rest
}: PropsWithChildren<Props>) => {
  const { setFetching, isFetchingFile } = useDownloadRatesPDF(loadId)
  return (
    <Tooltip label={tooltip ? `Print rates` : ''}>
      <FvLinkButton
        onClick={() => setFetching(true)}
        icon={isFetchingFile ? 'spinner' : 'print'}
        {...rest}
      >
        {rest.children}
      </FvLinkButton>
    </Tooltip>
  )
}
