import toast from 'react-hot-toast'

import { FvButton, ValidatedForm } from '@fv/client-components'
import { useFormModelState, validateEmailString } from '@fv/client-core'
import { type AccountBillTo } from '@fv/models'

import InfoBox from '../../../components/InfoBox'
import { useUserSettings } from '../../../hooks/settings'
import { AccountBillToForm } from '../../account-bill-to/AccountBillToForm'
import { useUpdateBillTo } from '../../account-bill-to/muations'
import { usePermissions } from '../../auth'
import { PageHeader } from '../PageHeader'

const billToPageHeaderText =
  'This is the address where you want your carriers/brokers to send their invoices. If your carrier already has a bill-to address on file for your account, they may use that address instead of this one. This will not be used for Freightview billing.'

export const BillToPage = () => {
  const settingsQuery = useUserSettings()
  const { canManage } = usePermissions()
  const updateBillTo = useUpdateBillTo()
  const billTo = settingsQuery.data?.registration?.billTo[0]

  const initialState: AccountBillTo = {
    address: billTo?.address ?? '',
    company: billTo?.company ?? '',
    contactName: billTo?.contactName ?? '',
    contactPhone: billTo?.contactPhone?.replace(/\D/g, ''),
    contactPhoneExt: billTo?.contactPhoneExt,
    contactEmail: billTo?.contactEmail,
    city: billTo?.city ?? '',
    state: billTo?.state ?? '',
    postalCode: billTo?.postalCode ?? '',
    country: 'US',
  }

  const { register, value, setValue } = useFormModelState<AccountBillTo>({
    initialValue: {
      ...initialState,
    },
    schema: {
      contactEmail: {
        validate: e => (validateEmailString(e) ? '' : 'Must be a valid email'),
      },
    },
  })

  const isDirty =
    initialState.address !== value.address ||
    initialState.company !== value.company ||
    initialState.contactName !== value.contactName ||
    initialState.contactPhone?.replace(/[\s-]/g, '') !== value.contactPhone ||
    initialState.contactPhoneExt !== value.contactPhoneExt ||
    initialState.contactEmail !== value.contactEmail ||
    initialState.city !== value.city ||
    initialState.state !== value.state ||
    initialState.postalCode !== value.postalCode

  const handleUpdate = () => {
    updateBillTo.mutateAsync(
      {
        address: value.address as string,
        city: value.city as string,
        state: value.state as string,
        postalCode: value.postalCode as string,
        company: value.company as string,
        contactName: value.contactName as string,
        contactPhone: value.contactPhone?.replace(/\D/g, '') as string,
        contactPhoneExt: value.contactPhoneExt as string,
        contactEmail: value.contactEmail as string,
        country: 'US',
      },
      {
        onSuccess: () => {
          toast.success('Account successfuly updated')
        },
      },
    )
  }

  const handleCancel = () => {
    setValue(initialState)
  }

  return (
    <ValidatedForm
      onValidSubmit={handleUpdate}
      className="settings-main flex flex-col pb-0"
    >
      <PageHeader title="Bill-to address (for bill of lading)" noBorder />
      <InfoBox className="mb-6">{billToPageHeaderText}</InfoBox>

      <div className="form-row mb-8 justify-start">
        <AccountBillToForm
          companyClassName="basis-1/3"
          addressClassName="basis-1/3"
          postalCodeClassName="basis-1/3"
          contactNameClassName="basis-1/3"
          contactPhoneClassName="basis-1/3"
          contactEmailClassName="basis-1/3"
          register={register}
          value={value}
          onChange={setValue}
        />
      </div>

      {canManage && isDirty && (
        <div className="flyout-footer-actions mb-0 mt-auto flex">
          <FvButton
            theme="primary"
            type="submit"
            loading={updateBillTo.isLoading}
            icon="check"
          >
            Save address
          </FvButton>
          <FvButton icon="times" theme="plain" onClick={handleCancel}>
            Cancel
          </FvButton>
        </div>
      )}
    </ValidatedForm>
  )
}
