import { useEffect, useRef } from 'react'

import { FvButton } from './FvButton'
import { Icon } from './Icon'

type Props = {
  className?: string
  fetchNextPage: () => void
  hasNextPage?: boolean
  isLoading: boolean
}

export const LoadMore = ({
  className,
  fetchNextPage,
  hasNextPage = false,
  isLoading,
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  // Auto fetch when button enters viewport
  useEffect(() => {
    const buttonElement = buttonRef.current
    if (!hasNextPage || !buttonElement) return

    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          entry.isIntersecting && fetchNextPage()
        })
      },
      {
        root: null, // use bounds of actual document viewport
        threshold: 1.0, // run callback at 100% visibility
      },
    )

    observer.observe(buttonElement)

    return () => {
      observer.unobserve(buttonElement)
    }
  }, [fetchNextPage, hasNextPage])

  if (!hasNextPage) return null

  return (
    <FvButton
      icon="check"
      theme="default"
      className={className}
      onClick={() => {
        !isLoading && fetchNextPage()
      }}
      ref={buttonRef}
    >
      <span>more... </span> {isLoading && <Icon icon="spinner" />}
    </FvButton>
  )
}
