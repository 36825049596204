import { CarrierTrackingLink, FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { useArchiveLoads } from '../../hooks/shipments'
import { useRepeatShipment } from '../../hooks/shipments/useRepeatShipment'

type Props = { load: FullShipment }

export const Footer = ({ load }: Props) => {
  const { isArchived, loadId, status } = load
  const { archive, canArchive, isArchiving } = useArchiveLoads(status)
  const repeat = useRepeatShipment(load)

  return (
    <div className="footer-actions !px-3 !py-4 col-span-3 row-start-[-1]">
      <div className="divided-content divided-content--start">
        <FvLinkButton icon="clone" onClick={repeat}>
          Repeat shipment
        </FvLinkButton>

        {canArchive && (
          <FvLinkButton
            icon={isArchiving ? 'spinner' : 'archive'}
            onClick={() => {
              archive({
                loadIds: [loadId],
                shouldArchive: !isArchived,
              })
            }}
          >
            {isArchived ? 'Unarchive' : 'Archive'} shipment
          </FvLinkButton>
        )}
        {load.carrierTracking && (
          <CarrierTrackingLink
            Button={props => (
              <a {...props}>
                <Icon icon="square-arrow-up-right" />
                <span>External tracking</span>
              </a>
            )}
            carrierTracking={load.carrierTracking}
          />
        )}
      </div>
    </div>
  )
}
