import { type PropsWithChildren } from 'react'

import { Icon } from '@fv/client-components'

import { Help } from '../../components/Help'

type Props = {
  containerClassName?: string
  height?: number
  length?: number
  quantity?: number
  weight?: number
  width?: number
}

export const FreightClassEstimate = ({
  children,
  ...props
}: PropsWithChildren<Props>) => {
  if (!showClassEstimate(props)) return children

  const volume = calculateVolume(props)
  const density = calculateDensity(props, volume)

  if (density <= 0 || volume <= 0) return null

  const classEstimate = (
    <div className="box bg-skin-3">
      <div className="flex items-center">
        <span>
          <Icon icon="calculator" className="color-dark" />
          <span>=</span>
        </span>
        <span className="ml-2">
          {volume} cu ft @ {density} lbs. per cu ft
        </span>
        <span className="ml-auto flex items-center">
          <span>Class</span>
          <span className="ml-2 text-[1.4rem]">
            {getClassEstimate(density)}{' '}
          </span>
          <Help transform="up-1">
            Freightview is only estimating this class based on density. There
            are other factors that can influence freight class. We are not
            responsible for freight discrepancies. Use as a recommendation only.
          </Help>
        </span>
      </div>
    </div>
  )

  if (props.containerClassName) {
    return <div className={props.containerClassName}>{classEstimate}</div>
  }

  return classEstimate
}

function calculateDensity(values: Props, volume: number): number {
  if (volume <= 0) return 0

  const weight = Number(values.weight)
  const density = (weight / volume).toFixed(2)

  return Number(density)
}

function calculateVolume(values: Props): number {
  const pieces = Number(values.quantity)
  const height = Number(values.height)
  const length = Number(values.length)
  const width = Number(values.width)
  const volume = ((pieces * height * length * width) / 1728).toFixed(2)

  return Number(volume)
}

function getClassEstimate(density: number): number {
  if (density < 1) return 500
  if (density < 2) return 400
  if (density < 3) return 300
  if (density < 4) return 250
  if (density < 5) return 200
  if (density < 6) return 175
  if (density < 7) return 150
  if (density < 8) return 125
  if (density < 9) return 110
  if (density < 10.5) return 100
  if (density < 12) return 92.5
  if (density < 13.5) return 85
  if (density < 15) return 77.5
  if (density < 22.5) return 70
  if (density < 30) return 65
  if (density < 35) return 60
  if (density < 50) return 55
  return 50
}

function showClassEstimate(values: Props): boolean {
  const pieces = values.quantity
  const weight = values.weight
  const height = values.height
  const length = values.length
  const width = values.width

  if (
    !pieces ||
    isNaN(pieces) ||
    !weight ||
    isNaN(weight) ||
    !height ||
    isNaN(height) ||
    !length ||
    isNaN(length) ||
    !width ||
    isNaN(width)
  ) {
    return false
  }

  return true
}
