import { lazy, Suspense } from 'react'

import InfoBox from '../../../components/InfoBox'
import { HasFeature } from '../../auth'
import FuelTable from './FuelTable'

const FuelTableUpload = lazy(() => import('./FuelTableUpload'))

const message =
  'Please download the example file for the proper upload format. This fuel table will be used for any carriers that do not have their own fuel tables. If you have already uploaded an account-level fuel table, it will be overwritten.'

const AccountFuelTable = () => {
  return (
    <HasFeature name="fuelAndDistance" redirect="/settings/user-preferences">
      <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
        <FuelTableUpload carrierId={null} message={message} />
      </Suspense>

      <FuelTable carrierId={null} className="mt-6" />
    </HasFeature>
  )
}

export default AccountFuelTable
