import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { shipperFetch } from '../../utils/shipperFetch'

export type Notifications = {
  _id: string
  created: Date
  notificationType: string
  canPin: boolean
  userId: string
  accountId: string
  shipmentId: string
  urgent: boolean
  text: string
  html: string
  link: string
}

export const notificationKey = ['notifications']

const fetchNotifications = async (filter: string): Promise<Notifications[]> => {
  const params = new URLSearchParams()
  params.set('filter', filter)
  return await shipperFetch(`/notifications?${params.toString()}`)
}

export const useNotifications = (filter: string) => {
  return useQuery([...notificationKey, filter], () =>
    fetchNotifications(filter),
  )
}

const deleteNote = async (id: string): Promise<Notifications> => {
  return await shipperFetch(`/notifications/${id}`, {
    method: 'DELETE',
  })
}
export const useDelete = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(notificationKey)
      toast.success('Notification deleted successfully.', {
        id: 'notifications',
      })
    },
    onError() {
      toast.error(
        'There was a problem removing the selected notification. Please try again later',
      )
    },
  })
}
