import clsx from 'clsx'
import { type PropsWithChildren, type ReactNode } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

import { ActionList } from './ActionList'

type ListProps = PropsWithClassName<
  PropsWithChildren<{
    fcp0?: boolean
    lcp0?: boolean
    bare?: boolean
  }>
>

export const List = ({ children, className, fcp0, lcp0, bare }: ListProps) => {
  return (
    <ul
      className={clsx(
        !bare ? 'divide-fv-gray-300 divide-y' : '[&>li]:py-1',
        fcp0 && '[&>li:first-child]:pt-0',
        lcp0 && '[&>li:last-child]:pb-0',
        className,
      )}
    >
      {children}
    </ul>
  )
}

type ListItemProps = PropsWithClassName<
  PropsWithChildren<{
    actions?: ReactNode
    custom?: boolean
    dashed?: boolean
    dotted?: boolean
    itemsStart?: boolean
  }>
>
export const ListItem = ({
  children,
  actions,
  className,
  custom,
  dashed,
  dotted,
  itemsStart,
}: ListItemProps) => {
  return (
    <li
      className={clsx(
        'flex',
        className,
        dashed && 'border-dashed',
        dotted && 'border-dotted',
        !custom && 'py-[.6rem]',
        itemsStart ? 'items-start' : 'items-center',
      )}
    >
      {children}
      {actions}
    </li>
  )
}

type LiaProps = PropsWithChildren<PropsWithClassName>
export const ListItemActions = ({ children, className }: LiaProps) => {
  return (
    <div className={clsx(className, 'ml-auto')}>
      <ActionList>{children}</ActionList>
    </div>
  )
}

export const ListItemConnector = ({ className }: PropsWithClassName) => {
  return (
    <div
      className={clsx(
        className,
        'border-fv-gray h-px flex-1 border-t border-dotted',
      )}
    />
  )
}

export const ListHeaderActions = ({
  children,
  className,
}: PropsWithClassName<PropsWithChildren>) => {
  return (
    <div
      className={clsx(
        'mt-2 border-b border-dashed border-[#ccc] pb-2',
        className,
      )}
    >
      {children}
    </div>
  )
}
