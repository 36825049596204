import { FvButton } from '@fv/client-components'

import { useRetractTender } from '../../hooks/shipments/useRetractTender'
import { ListSubActions, ListSubContainer } from './ListSubContainer'

type Props = {
  loadId: string
}

export const RetractTenderForm = ({ loadId }: Props) => {
  const retract = useRetractTender()
  const onClick = () => {
    if (!retract.confirm()) {
      return
    }

    retract.mutate(loadId)
  }

  return (
    <ListSubContainer>
      <ListSubActions justify="start">
        <FvButton
          loading={retract.isLoading}
          icon="undo"
          onClick={() => onClick()}
          theme="default"
        >
          Retract tender
        </FvButton>
      </ListSubActions>
    </ListSubContainer>
  )
}
