import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import { TextField, useFvNavigate } from '@fv/client-components'

import { InputAdornment } from './inputs/InputGroup'

const TopNavSearch = ({ focusSearch, resetNav }) => {
  const [searchText, setSearchText] = useState('')
  const navigate = useFvNavigate()
  const searchInput = useRef()

  useEffect(() => {
    if (focusSearch) {
      searchInput.current?.focus()
    }
  }, [focusSearch])

  return (
    <div
      className={clsx(
        'input-group flex-1 b1350:hidden ml-4',
        focusSearch && '!flex',
      )}
    >
      <InputAdornment
        icon="search"
        iconClass="color-success"
        position="start"
      />
      <TextField
        className="form-control"
        name="searchText"
        onChange={e => setSearchText(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter' && searchText.trim()) {
            const text = encodeURIComponent(searchText.trim())
            setSearchText('')
            navigate(`/search?text=${text}`)
            resetNav && resetNav()
          }
        }}
        placeholder="PRO#, BOL#, REF#, tracking#, location or company"
        ref={searchInput}
        value={searchText}
      />
    </div>
  )
}

TopNavSearch.propTypes = {
  focusSearch: PropTypes.bool,
  resetNav: PropTypes.func,
}

export default TopNavSearch
