import { getProperty, setProperty } from 'dot-prop'
import {
  ChangeEventHandler,
  Dispatch,
  HTMLProps,
  SetStateAction,
  useState,
} from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'

import { isSelectElement } from './input'

type InputElement = HTMLInputElement | HTMLSelectElement
export type TBaseModel = FieldValues

export type FieldOpts<TModel> = {
  validate?: (e: string, value: TModel) => string
  readOnly?: (value: TModel) => boolean
  valueAsNumber?: boolean
  valueAsChecked?: boolean
  disabled?: boolean
  min?: number
  required?: (value: TModel) => boolean
}

export type FormSchema<TModel extends TBaseModel> = Partial<
  Record<FieldPath<TModel>, FieldOpts<TModel>>
>

// TODO figure out if we can make FormRegister return a generic function
// export interface FormRegister<T extends TBaseModel> {
//   <TModel extends T>(
//     path: FieldPath<TModel>,
//     schemaOpts?: FieldOpts<TModel>,
//   ): FormRegisterResult
// }
export type FormRegister<
  TModel extends TBaseModel,
  TPath extends FieldPath<TModel> = FieldPath<TModel>,
  TOpts extends FieldOpts<TModel> = FieldOpts<TModel>,
> = (path: TPath, schemaOpts?: TOpts) => FormRegisterResult

// Note:
// Most of our custom input components require a name,
// so this overwrites the optional nature of the native HTMLInputElement to require name
export type FormRegisterResult = Omit<
  HTMLProps<InputElement>,
  'ref' | 'name'
> & {
  name: string
}

interface Opts<TModel extends TBaseModel> {
  value: TModel
  onChange: (values: TModel) => void
  key?: string
  schema?: FormSchema<TModel>
  disabled?: boolean
}
export const useFormModel = <TModel extends TBaseModel>(opts: Opts<TModel>) => {
  const register: FormRegister<TModel> = (path, schemaOpts) => {
    const name = [opts.key, path].filter(v => !!v).join('.') ?? path
    const schema = schemaOpts ?? getSchema(path, opts.schema)
    const { min } = schema ?? {}
    const propValue = getProperty(opts.value, path)

    const inputChange: ChangeEventHandler<InputElement> = e => {
      let targetValue: boolean | string | number | undefined = e.target.value
      if (!schema) {
        opts.onChange(setProperty({ ...opts.value }, path, targetValue))
        return
      }

      const errMsg = schema.validate?.(targetValue, opts.value) ?? ''
      e.target.setCustomValidity?.(errMsg)

      if (schema.valueAsNumber) {
        const num = parseFloat(targetValue.trim().replace(/[^0-9.]/, ''))
        targetValue = isNaN(num) ? undefined : num

        if (num !== undefined && schema.min !== undefined && num < schema.min) {
          e.target.setCustomValidity?.(
            `Value must be greater than ${schema.min}`,
          )
        }
      }

      if (schema.valueAsChecked && !isSelectElement(e.target)) {
        targetValue = e.target.checked
      }
      opts.onChange(setProperty({ ...opts.value }, path, targetValue))
    }

    return {
      onChange: inputChange,
      name: name,
      id: name,
      ...(schema?.valueAsChecked && {
        checked: propValue as boolean,
      }),
      ...(!isNaN(min ?? NaN) && { min }),
      disabled: schema?.disabled ?? opts.disabled,
      readOnly: schema?.readOnly?.(opts.value),
      required: schema?.required?.(opts.value),
      value: (propValue as string) ?? '', // TODO this could be improved
    }
  }

  return {
    register,
  }
}

export type FormModelState<TModel extends TBaseModel> = {
  register: FormRegister<TModel>
  value: TModel
  setValue: Dispatch<SetStateAction<TModel>>
}

export const useFormModelState = <TModel extends TBaseModel>(
  opts: Omit<Opts<TModel>, 'value' | 'onChange'> & { initialValue: TModel },
): FormModelState<TModel> => {
  const [modelValue, updateModelValue] = useState(opts.initialValue)
  const { register } = useFormModel({
    ...opts,
    value: modelValue,
    onChange: updateModelValue,
  })
  return { register, setValue: updateModelValue, value: modelValue }
}

const getSchema = <TModel extends TBaseModel>(
  path: FieldPath<TModel>,
  schema?: FormSchema<TModel>,
): FieldOpts<TModel> | undefined => {
  if (!schema) {
    return undefined
  }
  return schema[path]
}
