import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'

import logo from '@fv/client-assets/freightview.svg'
import { FvButton, FvNavLink, Icon } from '@fv/client-components'
import { type Workflow } from '@fv/client-types'

import { freightviewUri, routes } from '../constants'
import { HasPermission } from '../features/auth'
import { useAccountFeatures, useIsCarrierAccount } from '../features/auth/hooks'
import { useAccountSettings } from '../features/settings/account-settings/hooks'
import { SystemNotifications } from '../features/system-notifications/SystemNotifications'
import { useUnreadNotificationCount } from '../hooks/notifications'
import { useAppContext } from '../hooks/settings'
import TopNavSearch from './TopNavSearch'

const TopNav = () => {
  const contextQuery = useAppContext()
  const { firstName, lastName, isAdmin } = contextQuery.data?.user ?? {}
  const { quoting } = useAccountSettings()
  const isCarrier = useIsCarrierAccount()
  const { ltl, truckLoad } = useAccountFeatures()

  const notificationsQuery = useUnreadNotificationCount()
  const unreadNotificationCount = notificationsQuery.data?.unreadCount ?? 0
  const location = useLocation()

  const [searchActive, setSearchActive] = useState(false)
  const resetNav = useCallback(() => {
    setSearchActive(false)
  }, [])
  const quoteWorkflow: Workflow =
    quoting?.defaultWorkflow ??
    (ltl ? 'ltl' : truckLoad ? 'truckload' : 'parcel')
  return !searchActive ? (
    <div className="col-span-full">
      <SystemNotifications />
      <nav className="main-nav">
        <a
          className="pr-4"
          href={`${freightviewUri}/app#${isAdmin ? 'admin' : 'home'}`}
        >
          <img src={logo} alt="Freightview" className="min-w-[3rem]" />
        </a>

        {isCarrier && (
          <FvNavLink
            className={r =>
              clsx('main-nav-link', {
                active:
                  r.isActive || location.pathname.includes('/provisioning'),
              })
            }
            to={routes.provisioning('requested')}
          >
            <Icon icon="user-check" />
            <span>Provisioning</span>
          </FvNavLink>
        )}

        {!isCarrier && (
          <>
            <HasPermission name="quote">
              <FvNavLink
                className={r =>
                  clsx('main-nav-link', {
                    active: r.isActive || location.pathname.includes('/quote'),
                  })
                }
                to={routes.quote(quoteWorkflow)}
              >
                <Icon icon="usd-square" />
                <span>Get a quote</span>
              </FvNavLink>
            </HasPermission>
            <FvNavLink
              className={({ isActive }) => {
                let className = 'main-nav-link'
                if (isActive) className += ' active'
                return className
              }}
              to="/pending"
            >
              <Icon icon="warehouse" />
              <span className="main-nav-link__process">In process</span>
            </FvNavLink>

            <FvNavLink
              className={({ isActive }) => {
                let className = 'main-nav-link'
                if (isActive) className += ' active'
                return className
              }}
              to="/active"
            >
              <Icon icon="shipping-fast" />
              <span className="main-nav-link__shipments">Shipments</span>
            </FvNavLink>

            <FvNavLink
              className={({ isActive }) => {
                let className = 'main-nav-link'
                if (isActive) className += ' active'
                return className
              }}
              to="/analytics"
            >
              <Icon icon="analytics" />
              <span className="main-nav-link__analytics">Analytics</span>
            </FvNavLink>

            <HasPermission name="audit">
              <FvNavLink
                className={({ isActive }) => {
                  let className = 'main-nav-link'
                  if (isActive) className += ' active'
                  return className
                }}
                to="/audit"
              >
                <Icon icon="donate" />
                <span className="main-nav-link__auditing">Auditing</span>
              </FvNavLink>
            </HasPermission>

            <FvButton
              className="main-nav-link hidden b1350:flex"
              onClick={e => {
                setSearchActive(prev => !prev)
              }}
            >
              <Icon icon="search" />
            </FvButton>

            <TopNavSearch />
          </>
        )}

        <div className="items-center flex overflow-hidden">
          <FvNavLink to="/notifications" rel="noreferrer">
            <Icon
              icon="bell-on"
              transform="up-.5"
              className={clsx('block', 'ml-4', 'mr-2', {
                'bellshake-animation text-fv-orange':
                  unreadNotificationCount > 0,
              })}
            />
          </FvNavLink>

          <FvNavLink
            className="limit-characters"
            to={routes.settings('user-preferences')}
          >
            <Icon icon="cog" />
            <span className="main-nav__user">
              {firstName} {lastName}
            </span>
          </FvNavLink>
        </div>
      </nav>
    </div>
  ) : (
    <div className="main-nav-search row-start-1 col-span-full">
      <SystemNotifications />
      <TopNavSearch focusSearch resetNav={resetNav} />
      <FvButton icon="times" theme="plain" onClick={resetNav} />
    </div>
  )
}

export default TopNav
