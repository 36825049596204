import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type ListShipment } from '@fv/client-types'
import { type LoadDocumentType } from '@fv/models'

import { apiUri, supportMessage } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'

async function deleteLoadDocument(dto: {
  documentId?: string
  loadId: string
}): Promise<ListShipment> {
  if (!dto.documentId) {
    throw new Error('`documentId` required to delete document')
  }

  const endpoint = `${apiUri}/shipments/${dto.loadId}/documents/${dto.documentId}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useDeleteLoadDocument(loadId: string, documentId?: string) {
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation(() => deleteLoadDocument({ loadId, documentId }), {
    onError: (e: { message?: string }) => {
      toast.error(e?.message ?? `Unable to remove document, ${supportMessage}`)
    },
    onSuccess: updatedLoad => {
      updateCachedLoad({ updatedLoad })

      toast.success('Document removed successfully.', {
        id: 'document-removed',
      })
    },
  })
}

async function uploadLoadDocument(dto: {
  document: File
  documentSource: 'carrier' | 'shipper'
  documentType: LoadDocumentType
  loadId: string
}): Promise<ListShipment> {
  const { document, documentType, loadId } = dto
  const endpoint = `${apiUri}/shipments/${loadId}/documents`
  const formData = new FormData()

  formData.append('document', document)

  const options = buildFetchOptionsWithAuth({
    body: formData,
    headers: {
      'x-document-type': documentType,
      'x-document-source': dto.documentSource,
    },
    method: 'POST',
  })

  // Content-type set automatically from `formData`
  delete options.headers['Content-type']

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUploadLoadDocument() {
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation(uploadLoadDocument, {
    onSuccess: updatedLoad => {
      updateCachedLoad({ updatedLoad })
      toast.success('Document uploaded successfully.')
    },
  })
}
