import clsx from 'clsx'
import { type ChangeEvent } from 'react'

import { validateEmailListInput } from '@fv/client-core'
import { type PropsWithClassName } from '@fv/client-types'

import { InputGroup } from '../../components/inputs/InputGroup'
import { FormSectionProps } from '../../components/inputs/types'
import { useBookingForm } from '../booking/hooks'
import { useBookingFormCtx } from './BookingFormProvider'

type Props = {
  value: string
  disabled?: boolean
  onChange: (v: string) => void
  helpText?: string
  label?: string
}

export const BookingFormShareInput = ({
  disabled,
  value,
  onChange,
  className,
  helpText: helpTextOverride,
  label: labelOverride,
}: PropsWithClassName<Props>) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateEmailListInput(e.target)
    onChange(e.target.value)
  }
  return (
    <div className={clsx('form-row', className)}>
      <InputGroup
        className="basis-full"
        helpText={
          helpTextOverride ??
          'Enter as many email addresses as you want, they will be sent a notification email containing a link to the BOL for this shipment.'
        }
        inputProps={{
          disabled,
          name: 'shareEmails',
          onChange: handleChange,
          placeholder: 'bill@example.com, ted@example.com',
          value: value,
        }}
        inputType="text"
        label={labelOverride ?? 'Send this bill of lading to more people'}
      />
    </div>
  )
}

export const LtlBookingFormShare = ({
  className,
  disabled,
}: PropsWithClassName<{ disabled?: boolean }>) => {
  const { setShareEmails } = useBookingFormCtx(s => s.actions)
  const shareEmails = useBookingFormCtx(s => s.shareEmails)
  const isParcel = useBookingFormCtx(s => s.workflow === 'parcel')
  return (
    <BookingFormShareInput
      value={shareEmails}
      onChange={setShareEmails}
      disabled={disabled}
      helpText={
        isParcel
          ? 'Enter as many email addresses as you want, they will be sent a notification email containing a link to this shipment'
          : undefined
      }
      label={
        isParcel ? 'Share this shipment with more people (optional)' : undefined
      }
      className={className}
    />
  )
}

export const TLBookingFormShare = ({
  className,
  disabled,
}: PropsWithClassName<{ disabled?: boolean }>) => {
  const { shareEmails, setShareEmails } = useBookingForm()
  return (
    <BookingFormShareInput
      value={shareEmails}
      onChange={setShareEmails}
      disabled={disabled}
      className={className}
    />
  )
}
