import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { FvButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { composeShipmentLocationSummary } from '../../utils/shipmentFuncs'

type HeaderProps = Pick<FullShipment, 'bol' | 'locations' | 'tracking'> & {
  className?: string
  prefix?: string
  showBolNumber?: boolean
  trackingNumLabel?: 'PRO#' | 'Tracking#'
}

type GenericHeaderProps = {
  handleClose?: () => void
  content: string
}

export const SliderContentHeader = ({
  bol,
  className,
  locations,
  prefix,
  showBolNumber = true,
  tracking,
  trackingNumLabel,
}: HeaderProps) => {
  const locationSummary = composeShipmentLocationSummary(locations)

  return (
    <div className={clsx('mb-4"', className)}>
      {prefix && `${prefix?.trim()} `}
      {showBolNumber && bol?.bolNumber && `BOL# ${bol?.bolNumber}`}
      {trackingNumLabel &&
        tracking?.trackingNumber &&
        `${trackingNumLabel} ${tracking.trackingNumber}`}
      {' - '}
      {locationSummary}
      <hr className="mx-0 my-2 mb-4" />
    </div>
  )
}

export const SliderContentFooter = ({ children }: PropsWithChildren) => (
  <div className="flyout-footer-actions">{children}</div>
)

export const SliderContentLayout = ({ children }: PropsWithChildren) => (
  <div className="flex h-full flex-col flex-nowrap items-stretch">
    {children}
  </div>
)

export const SliderGenericHeader = ({
  content,
  handleClose,
}: GenericHeaderProps) => (
  <div className="mx-0 mb-4 flex items-center justify-between">
    <h6 className="mb-0">{content}</h6>
    {handleClose && (
      <FvButton icon="times" onClick={handleClose} theme="plain">
        Close
      </FvButton>
    )}
  </div>
)
