import uniq from 'lodash/uniq'
import { shallow } from 'zustand/shallow'

import { type LoadNotification } from '@fv/client-types'

import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { removeUndefinedValues } from '../../utils/removeUndefinedValues'
import { useBookingFormCtx } from './BookingFormProvider'

function getAccountLocationIds(
  locations: Array<{ accountLocationId?: string }>,
) {
  const ids = locations
    .filter(x => x.accountLocationId)
    .map(x => x.accountLocationId)

  return removeUndefinedValues(ids)
}

export function requireRateLocation(
  locations: Array<{ accountLocationId?: string }>,
  isFreightCollect: boolean,
) {
  if (isFreightCollect) return false
  const accountLocationIds = getAccountLocationIds(locations)
  return uniq(accountLocationIds).length > 1
}

export function processsLoadNotifications(
  not?: LoadNotification,
): LoadNotification[] | undefined {
  return not?.email
    ?.split(/[,;]/)
    .filter(e => !!e)
    .map(e => ({
      email: e,
      notificationTypes: not?.notificationTypes ?? [],
    }))
}

export const useCarrierBookingFormSettings = () => {
  const { selectedQuote } = useBookingFormCtx(
    s => ({
      selectedQuote: s.quote,
    }),
    shallow,
  )
  return useCarrierPublicSettings(selectedQuote?.carrierId)
    .carrierPublicSettings
}
