import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { setDataByPage } from '@fv/client-core'

export function useRemoveFromList() {
  const queryClient = useQueryClient()

  return useCallback(
    ({ loadId, queryFilter }) => {
      queryClient.setQueriesData(
        queryFilter,
        setDataByPage(data => data.filter(s => s.loadId !== loadId)),
      )
    },
    [queryClient],
  )
}
