import { Country } from './country'

export type State = {
  abbreviation: string
  name: string
  country: Country
}
export const usStates: State[] = [
  { country: 'us', abbreviation: 'AL', name: 'Alabama' },
  { country: 'us', abbreviation: 'AK', name: 'Alaska' },
  { country: 'us', abbreviation: 'AZ', name: 'Arizona' },
  { country: 'us', abbreviation: 'AR', name: 'Arkansas' },
  { country: 'us', abbreviation: 'CA', name: 'California' },
  { country: 'us', abbreviation: 'CO', name: 'Colorado' },
  { country: 'us', abbreviation: 'CT', name: 'Connecticut' },
  { country: 'us', abbreviation: 'DE', name: 'Delaware' },
  { country: 'us', abbreviation: 'DC', name: 'District Of Columbia' },
  { country: 'us', abbreviation: 'FL', name: 'Florida' },
  { country: 'us', abbreviation: 'GA', name: 'Georgia' },
  { country: 'us', abbreviation: 'HI', name: 'Hawaii' },
  { country: 'us', abbreviation: 'ID', name: 'Idaho' },
  { country: 'us', abbreviation: 'IL', name: 'Illinois' },
  { country: 'us', abbreviation: 'IN', name: 'Indiana' },
  { country: 'us', abbreviation: 'IA', name: 'Iowa' },
  { country: 'us', abbreviation: 'KS', name: 'Kansas' },
  { country: 'us', abbreviation: 'KY', name: 'Kentucky' },
  { country: 'us', abbreviation: 'LA', name: 'Louisiana' },
  { country: 'us', abbreviation: 'ME', name: 'Maine' },
  { country: 'us', abbreviation: 'MD', name: 'Maryland' },
  { country: 'us', abbreviation: 'MA', name: 'Massachusetts' },
  { country: 'us', abbreviation: 'MI', name: 'Michigan' },
  { country: 'us', abbreviation: 'MN', name: 'Minnesota' },
  { country: 'us', abbreviation: 'MS', name: 'Mississippi' },
  { country: 'us', abbreviation: 'MO', name: 'Missouri' },
  { country: 'us', abbreviation: 'MT', name: 'Montana' },
  { country: 'us', abbreviation: 'NE', name: 'Nebraska' },
  { country: 'us', abbreviation: 'NV', name: 'Nevada' },
  { country: 'us', abbreviation: 'NH', name: 'New Hampshire' },
  { country: 'us', abbreviation: 'NJ', name: 'New Jersey' },
  { country: 'us', abbreviation: 'NM', name: 'New Mexico' },
  { country: 'us', abbreviation: 'NY', name: 'New York' },
  { country: 'us', abbreviation: 'NC', name: 'North Carolina' },
  { country: 'us', abbreviation: 'ND', name: 'North Dakota' },
  { country: 'us', abbreviation: 'OH', name: 'Ohio' },
  { country: 'us', abbreviation: 'OK', name: 'Oklahoma' },
  { country: 'us', abbreviation: 'OR', name: 'Oregon' },
  { country: 'us', abbreviation: 'PA', name: 'Pennsylvania' },
  { country: 'us', abbreviation: 'RI', name: 'Rhode Island' },
  { country: 'us', abbreviation: 'SC', name: 'South Carolina' },
  { country: 'us', abbreviation: 'SD', name: 'South Dakota' },
  { country: 'us', abbreviation: 'TN', name: 'Tennessee' },
  { country: 'us', abbreviation: 'TX', name: 'Texas' },
  { country: 'us', abbreviation: 'UT', name: 'Utah' },
  { country: 'us', abbreviation: 'VT', name: 'Vermont' },
  { country: 'us', abbreviation: 'VA', name: 'Virginia' },
  { country: 'us', abbreviation: 'WA', name: 'Washington' },
  { country: 'us', abbreviation: 'WV', name: 'West Virginia' },
  { country: 'us', abbreviation: 'WI', name: 'Wisconsin' },
  { country: 'us', abbreviation: 'WY', name: 'Wyoming' },
]

export const caStates: State[] = [
  { country: 'ca', abbreviation: 'AB', name: 'Alberta' },
  { country: 'ca', abbreviation: 'BC', name: 'British Columbia' },
  { country: 'ca', abbreviation: 'MB', name: 'Manitoba' },
  { country: 'ca', abbreviation: 'NB', name: 'New Brunswick' },
  { country: 'ca', abbreviation: 'NL', name: 'Newfoundland and Labrador' },
  { country: 'ca', abbreviation: 'NS', name: 'Nova Scotia' },
  { country: 'ca', abbreviation: 'ON', name: 'Ontario' },
  { country: 'ca', abbreviation: 'PE', name: 'Prince Edward Island' },
  { country: 'ca', abbreviation: 'QC', name: 'Quebec' },
  { country: 'ca', abbreviation: 'SK', name: 'Saskatchewan' },
  { country: 'ca', abbreviation: 'NT', name: 'Northwest Territories' },
  { country: 'ca', abbreviation: 'NU', name: 'Nunavut' },
  { country: 'ca', abbreviation: 'YT', name: 'Yukon' },
]

export const mxStates: State[] = [
  { name: 'Aguascalientes', abbreviation: 'AGU', country: 'mx' },
  { name: 'Baja California', abbreviation: 'BCN', country: 'mx' },
  { name: 'Baja California Sur', abbreviation: 'BCS', country: 'mx' },
  { name: 'Campeche', abbreviation: 'CAM', country: 'mx' },
  { name: 'Coahuila', abbreviation: 'COA', country: 'mx' },
  { name: 'Colima', abbreviation: 'COL', country: 'mx' },
  { name: 'Chiapas', abbreviation: 'CHP', country: 'mx' },
  { name: 'Chihuahua', abbreviation: 'CHH', country: 'mx' },
  { name: 'Mexico City', abbreviation: 'CMX', country: 'mx' },
  { name: 'Durango', abbreviation: 'DUR', country: 'mx' },
  { name: 'Guanajuato', abbreviation: 'GUA', country: 'mx' },
  { name: 'Guerrero', abbreviation: 'GRO', country: 'mx' },
  { name: 'Hidalgo', abbreviation: 'HID', country: 'mx' },
  { name: 'Jalisco', abbreviation: 'JAL', country: 'mx' },
  { name: 'México', abbreviation: 'MEX', country: 'mx' },
  { name: 'Michoacán', abbreviation: 'MIC', country: 'mx' },
  { name: 'Morelos', abbreviation: 'MOR', country: 'mx' },
  { name: 'Nayarit', abbreviation: 'NAY', country: 'mx' },
  { name: 'Nuevo León', abbreviation: 'NLE', country: 'mx' },
  { name: 'Oaxaca', abbreviation: 'OAX', country: 'mx' },
  { name: 'Puebla', abbreviation: 'PUE', country: 'mx' },
  { name: 'Querétaro', abbreviation: 'QUE', country: 'mx' },
  { name: 'Quintana Roo', abbreviation: 'ROO', country: 'mx' },
  { name: 'San Luis Potosí', abbreviation: 'SLP', country: 'mx' },
  { name: 'Sinaloa', abbreviation: 'SIN', country: 'mx' },
  { name: 'Sonora', abbreviation: 'SON', country: 'mx' },
  { name: 'Tabasco', abbreviation: 'TAB', country: 'mx' },
  { name: 'Tamaulipas', abbreviation: 'TAM', country: 'mx' },
  { name: 'Tlaxcala', abbreviation: 'TLA', country: 'mx' },
  { name: 'Veracruz', abbreviation: 'VER', country: 'mx' },
  { name: 'Yucatán', abbreviation: 'YUC', country: 'mx' },
  { name: 'Zacatecas', abbreviation: 'ZAC', country: 'mx' },
]

export const states = [...usStates, ...caStates, ...mxStates] as const
export type StateCode = (typeof states)[number]['abbreviation']
