import clsx from 'clsx'
import { type ReactNode, useState } from 'react'

import {
  FvButton,
  FvLinkButton,
  TextField,
  ValidatedForm,
} from '@fv/client-components'
import { getTrackingNumLabel } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import {
  EditableRefNum,
  NOT_AVAILABLE,
} from '../../components/shared/EditableRefNum'
import { usePermissions } from '../auth'
import { useEditBolNumber } from '../bol/useEditBolNumber'
import { useEditConfirmationNumber } from '../confirm/useEditConfirmationNumber'
import { useEditableRefNums } from '../track/useEditableRefNums'
import { useEditTrackingNumber } from '../track/useEditTrackingNumber'

type Props = {
  load: FullShipment
  wrap?: (children: ReactNode) => JSX.Element
}

export const RefNumsEditor = ({ load, wrap }: Props) => {
  const { bol, loadId, pickup, refNums, tracking, workflow } = load

  const parsedWrap = wrap || (children => children)

  const editBOLNum = useEditBolNumber(loadId)
  const editPickupNum = useEditConfirmationNumber(loadId)
  const editTrackingNum = useEditTrackingNumber(loadId)
  const [editingRefNumbers, setEditingRefNums] = useState(false)

  function toggleEditingRefNums() {
    if (canEditRefNums) setEditingRefNums(!editingRefNumbers)
  }

  const { canBook } = usePermissions()
  const {
    editableRefNums,
    canEditRefNums,
    staticRefNums,
    onRefNumChange,
    onRefNumSave,
    isLoading: refNumsLoading,
  } = useEditableRefNums(load)

  return (
    <>
      {Boolean(load.workflow === 'truckload') &&
        parsedWrap(
          <FvLinkButton
            theme="underlined"
            onClick={toggleEditingRefNums}
            className={clsx({ disabled: !canEditRefNums })}
          >
            Ref# {refNums?.map(n => n.value).join(' / ') || NOT_AVAILABLE}
          </FvLinkButton>,
        )}
      {canEditRefNums &&
        editingRefNumbers &&
        parsedWrap(
          <>
            {!!staticRefNums?.length && (
              <div className="paragraph ancillary-details-snapshot mb-2 mt-2 leading-[1.6rem]">
                <span>
                  Static Ref#: {staticRefNums?.map(n => n.value).join(' / ')}
                </span>
              </div>
            )}

            <ValidatedForm
              className="b1350:flex b1350:items-center"
              onValidSubmit={() => {
                onRefNumSave({
                  onSuccess: () => setEditingRefNums(false),
                })
              }}
            >
              {editableRefNums.map((n, i) => {
                return (
                  <div
                    className="mb-2 mt-2 flex flex-col space-y-2 b1350:m-0 b1350:mr-2"
                    key={`ref-num-${i}`}
                  >
                    <div className="input-group b1350:w-80">
                      <div className="input-group__prepend whitespace-nowrap">
                        <div className="input-group__addon">Ref# {i + 1}</div>
                      </div>
                      <TextField
                        required
                        minLength={1}
                        type="text"
                        name={`ref-num-${i}`}
                        className="form-control"
                        onChange={e => onRefNumChange(e, i)}
                        readOnly={refNumsLoading}
                        value={n.value}
                      />
                    </div>
                  </div>
                )
              })}

              <div className="flex justify-end">
                <FvButton
                  icon="check"
                  theme="default"
                  loading={refNumsLoading}
                  type="submit"
                >
                  Save
                </FvButton>
                <FvButton
                  theme="plain"
                  onClick={() => setEditingRefNums(false)}
                  icon="times"
                  className="ml-0 pr-0"
                />
              </div>
            </ValidatedForm>

            <hr className="b1350:hidden" />
          </>,
        )}
      {parsedWrap(
        <EditableRefNum
          defaultValue={tracking?.trackingNumber}
          label={getTrackingNumLabel(workflow)}
          loading={editTrackingNum.isLoading}
          name="trackingNum"
          onSave={editTrackingNum.mutate}
          readOnly={!canBook}
        />,
      )}

      {parsedWrap(
        <EditableRefNum
          defaultValue={pickup?.confirmationNumber}
          label={`${workflow === 'truckload' ? '' : 'Pickup '}Confirmation#`}
          loading={editPickupNum.isLoading}
          name="pickupNum"
          onSave={editPickupNum.mutate}
          readOnly={!canBook}
        />,
      )}
      {workflow !== 'parcel' &&
        parsedWrap(
          <EditableRefNum
            defaultValue={bol?.bolNumber}
            label="BOL#"
            loading={editBOLNum.isLoading}
            name="bolNum"
            onSave={editBOLNum.mutate}
            readOnly={!canBook}
          />,
        )}
    </>
  )
}
