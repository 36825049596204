import {
  createCredentialDetailsFromModel,
  createCredentialEditModel,
  useFormModelState,
} from '@fv/client-core'

import { FormActions } from '../../../components/forms/FormActions'
import { ValidatedForm } from '../../../components/inputs'
import { Loading } from '../../../components/Loading'
import { useAppContext } from '../../../hooks/settings'
import { useProvisionerAccountId } from '../hooks'
import { useUpdateAccountProvisioning } from '../mutations'
import {
  useProvisionerAccountDetails,
  useProvisionerCarrierConfig,
} from '../queries'
import { ProvisioningField } from './ProvisioningField'
import { ProvisionerSection, ProvisionerSectionHeader } from './Section'

export const ProvisionerCredentials = () => {
  const { data: context } = useAppContext()
  const { data: config, isLoading: configLoading } =
    useProvisionerCarrierConfig()
  const accountId = useProvisionerAccountId()
  const { data: details, isLoading: detailsLoading } =
    useProvisionerAccountDetails(accountId)
  const updateAccount = useUpdateAccountProvisioning()
  const carrierName = context?.user.company
  const credTypes = config?.credentialTypes ?? []
  const { register, value: model } = useFormModelState({
    initialValue: createCredentialEditModel(
      config?.credentialTypes ?? [],
      details?.details ?? [],
    ),
  })

  if (configLoading || detailsLoading) return <Loading />
  if (!accountId || !credTypes?.length) return null

  const handleSubmit = async () => {
    await updateAccount.mutateAsync({
      accountId,
      model: { details: createCredentialDetailsFromModel(model) },
    })
  }

  return (
    <>
      <ProvisionerSectionHeader>
        {carrierName} Credentials
      </ProvisionerSectionHeader>
      <ProvisionerSection>
        <ValidatedForm onValidSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-3">
            {credTypes.map(c => (
              <ProvisioningField key={c.name} cred={c} register={register} />
            ))}
          </div>
          <FormActions
            submitText={
              details?.details?.some(d => d.value) ? 'Update' : 'Save'
            }
          />
        </ValidatedForm>
      </ProvisionerSection>
    </>
  )
}
