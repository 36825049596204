import { type ChangeEvent } from 'react'

import { Icon, Tooltip } from '@fv/client-components'
import { toggleArrayItem } from '@fv/client-core'
import { type UserPermissionType } from '@fv/client-types'

import CheckboxField from '../../components/inputs/CheckboxField'

const orderdPermissions: UserPermissionType[] = [
  'manage',
  'book',
  'quote',
  'audit',
]

const labels: Record<UserPermissionType, string> = {
  audit: 'Audit',
  book: 'Book',
  quote: 'Quote',
  manage: 'Manage',
}
const tooltips: Record<UserPermissionType, string> = {
  audit: 'Gives access to invoices and auditing tools',
  book: 'Gives access to book shipments',
  quote: 'Gives access to request quotes on shipments',
  manage: 'Gives access to manage organization settings',
}

type Props = {
  value: UserPermissionType[]
  onChange: (permissions: UserPermissionType[]) => void
  disabled?: boolean
  name?: string
}
export const UserPermissionFormField = ({
  value,
  onChange,
  disabled,
  name,
}: Props) => {
  const handleChange =
    (type: UserPermissionType) => (e: ChangeEvent<HTMLInputElement>) => {
      if (type === 'manage' && e.target.checked) {
        onChange([...orderdPermissions])
      } else {
        const targetValue = e.target.checked
          ? value
          : value.filter(v => v !== 'manage')
        onChange(toggleArrayItem(targetValue, type, e.target.checked))
      }
    }
  return (
    <>
      {orderdPermissions.map(type => (
        <CheckboxField
          key={type}
          name={`${name ?? ''}-${type}`}
          disabled={disabled}
          checked={value.includes(type)}
          onChange={handleChange(type)}
          label={
            <span>
              {labels[type]}
              <Tooltip label={[tooltips[type]]}>
                <Icon icon="question-circle" className="ml-1" />
              </Tooltip>
            </span>
          }
        />
      ))}
    </>
  )
}
