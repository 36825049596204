import clsx from 'clsx'
import { type MouseEventHandler, useEffect, useState } from 'react'

import { FvLinkButton, Icon, type IconProps } from '@fv/client-components'
import { type AuditStatus } from '@fv/models'

import { useAuditListCount } from './queries'

const animationLength = 450

type AuditListTabProps = {
  icon: IconProps['icon']
  iconClassName?: string
  isActive: boolean
  label: string
  onClick: MouseEventHandler<HTMLAnchorElement>
  status: AuditStatus
}

const AuditListTab = ({
  icon,
  iconClassName,
  isActive,
  label,
  onClick,
  status,
}: AuditListTabProps) => {
  const { count, isLoading } = useAuditListCount(status)
  const [prevCount, setPrevCount] = useState(count)

  useEffect(() => {
    let isMounted = true

    setTimeout(() => {
      isMounted && setPrevCount(count)
    }, animationLength)

    return () => {
      isMounted = false
    }
  }, [count])

  return (
    <FvLinkButton
      className={clsx({ 'active-hint-indicator': isActive })}
      icon={icon}
      iconClass={iconClassName}
      id={status}
      onClick={onClick}
    >
      <span>{label}</span>
      {status !== 'archived' && (
        <span
          className={clsx('number-hint ml-2', {
            'scale-up-down-animation': count > prevCount,
          })}
        >
          {isLoading ? <Icon icon="spinner" /> : count}
        </span>
      )}
    </FvLinkButton>
  )
}

export default AuditListTab
