import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type AccountBillToRequestModel } from '@fv/models'

import { userSettingsKey } from '../../hooks/settings/useUserSettings'
import { shipperFetch } from '../../utils/shipperFetch'

const updateBillTo = async (billToInfo: AccountBillToRequestModel) => {
  return shipperFetch('/accounts/settings/bill-to', {
    body: billToInfo,
    method: 'PUT',
  })
}

export const useUpdateBillTo = (notify?: boolean) => {
  const queryClient = useQueryClient()
  return useMutation(updateBillTo, {
    onSuccess: () => {
      notify &&
        toast.success('Bill-to information updated successfully.', {
          id: 'bill-to-information-update',
        })
    },
    onSettled() {
      queryClient.invalidateQueries(userSettingsKey)
    },
    onError() {
      toast.error('Error saving bill-to information')
    },
  })
}
