import {
  type MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { type Dispatch, type SetStateAction, useState } from 'react'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  showApiError,
} from '@fv/client-core'
import {
  type FullShipment,
  type ListShipment,
  type LoadDocument,
} from '@fv/client-types'

import { apiUri } from '../../constants'
import {
  type LabeledDocument,
  useLabeledDocuments,
} from '../../hooks/documents'
import { loadKeys } from '../../hooks/shipments/useLoad'
import { removeUndefinedValues } from '../../utils/removeUndefinedValues'
import { filterDocs } from './share-utils'

type ShareShipmentResponse = Pick<ListShipment, 'shares' | 'documents'>
type ShareShipmentDto = {
  documents?: string[]
  emails: string[]
  loadId: string
  message?: string
}

async function shareShipment(dto: ShareShipmentDto) {
  const { documents = [], emails, loadId, message } = dto
  const endpoint = `${apiUri}/share/${loadId}`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      documents,
      emails,
      ...(message && { message }),
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json as ShareShipmentResponse
  throw response.errorMessage
}

export function useShareShipment() {
  const queryClient = useQueryClient()
  return useMutation(shareShipment, {
    onSettled: (response, error, variables) => {
      if (error) return showApiError('Unable to share shipment', error)

      if (response) {
        const { shares, documents } = response

        // Share also publishes an event that is handled and
        // refreshes the load, but there's a significant delay
        queryClient.setQueryData<FullShipment>(
          loadKeys.id(variables.loadId),
          prev =>
            prev && {
              ...prev,
              shares,
              documents,
            },
        )
      }

      toast.success('Shipment shared successfully.')
    },
  })
}

export type ShareShipmentForm = {
  documents: LabeledDocument[]
  isSaving: boolean
  message: string
  setMessage: Dispatch<SetStateAction<string>>
  setSharedDocs: Dispatch<SetStateAction<string[]>>
  sharedDocs: string[]
  submitShare: (
    emails: string[],
    options?: MutateOptions<
      ShareShipmentResponse,
      unknown,
      ShareShipmentDto,
      unknown
    >,
  ) => void
  variables?: ShareShipmentDto
}

export const useShareShipmentForm = ({
  documents,
  loadId,
}: {
  documents?: LoadDocument[]
  loadId: string
}): ShareShipmentForm => {
  const docs = useLabeledDocuments(documents, { filter: filterDocs })
  const [message, setMessage] = useState('')
  const [sharedDocs, setSharedDocs] = useState<string[]>(() =>
    removeUndefinedValues(
      docs.filter(d => d.isShared || d.type === 'bol').map(d => d._id),
    ),
  )

  const shareMutation = useShareShipment()
  const submitShare: ShareShipmentForm['submitShare'] = (emails, options) => {
    if (shareMutation.isLoading) return

    shareMutation.mutate(
      {
        documents: sharedDocs,
        emails,
        loadId,
        message,
      },
      options,
    )
  }

  return {
    documents: docs,
    isSaving: shareMutation.isLoading,
    message,
    setMessage,
    setSharedDocs,
    sharedDocs,
    submitShare,
    variables: shareMutation.variables,
  }
}
