import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { Icon, type IconProps, Tooltip } from '@fv/client-components'

export const Help = ({
  children,
  ...iconProps
}: PropsWithChildren<Partial<IconProps>>) => {
  return (
    <Tooltip label={children}>
      <Icon
        {...iconProps}
        className={clsx('color-secondary ml-1', iconProps.className)}
        icon="question-circle"
      />
    </Tooltip>
  )
}
