import { useEffect } from 'react'

import { buildFetchOptionsWithAuth, debug, fetchJson } from '@fv/client-core'

export type LiveEvent = {
  data: any
  event: string
}

type LiveEventsProcessorOpts = {
  authenticateUri?: string
  handleEvent: (e: LiveEvent) => void
  liveEventsUri?: string
}

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
const waitForRetry = async (retryCount: number) => {
  const retryDelay = Math.min(60000, retryCount * 2000)
  console.log(
    `LiveEventsProcessor: reauthenticating in ${retryDelay / 1000} seconds`,
  )
  await wait(retryDelay)
}

export function useLiveEventsProcessor(opts: LiveEventsProcessorOpts) {
  const { authenticateUri, handleEvent, liveEventsUri } = opts

  useEffect(() => {
    let eventSource: EventSource | null = null
    if (!authenticateUri || !liveEventsUri) return

    let isMounted = true
    let retries = 0

    async function fetchToken(): Promise<string | null> {
      console.log(`LiveEventsProcessor: authenticating with ${authenticateUri}`)

      try {
        if (!isMounted) return null
        if (!authenticateUri) throw new Error('Missing authentication URI')
        if (!liveEventsUri) throw new Error('Missing live events URI')

        const options = buildFetchOptionsWithAuth({ method: 'POST' })
        const { error, json, ok } = await fetchJson(authenticateUri, options)

        if (ok && json?.token) return json.token
        else throw error ?? new Error('Token not found')
      } catch (err) {
        retries++
        if (retries >= 5 && /localhost/.test(liveEventsUri ?? '')) return null

        await waitForRetry(retries)
        return isMounted ? await fetchToken() : null
      }
    }

    function subscribeToEvents(token: string | null) {
      if (!token || !isMounted) return

      console.log(`LiveEventsProcessor: connecting to ${liveEventsUri}`)
      eventSource = new EventSource(`${liveEventsUri}/es/${token}`)

      eventSource.onerror = async e => {
        debug.error(e as any)
        eventSource?.close()
        console.log('LiveEventsProcessor: disconnected')
        if (isMounted) {
          await waitForRetry(retries)
          fetchToken().then(subscribeToEvents)
        }
        retries++
      }

      eventSource.onmessage = (message: any) => {
        if (!message?.data) return

        try {
          const event = JSON.parse(message.data)
          if (!event) return

          handleEvent(event)
          debug.log(`LiveEventsProcessor: ${event.event}`)
          debug.log(event)
        } catch (err) {
          debug.error(err as any)
        }
      }

      eventSource.onopen = () => {
        retries = 0
        console.log('LiveEventsProcessor: connection established!')
      }
    }

    fetchToken().then(subscribeToEvents)

    return () => {
      isMounted = false
      eventSource?.close()
      console.log('LiveEventsProcessor: unmounted')
    }
  }, [authenticateUri, handleEvent, liveEventsUri])
}
