import { type PropsWithClassName } from '@fv/client-types'

import { InputGroup } from '../../components/inputs/InputGroup'
import { useBookingForm } from '../booking/hooks'
import { useBookingFormCtx } from './BookingFormProvider'

type Props = {
  value: string
  onChange: (value: string) => void
}
export const BookingFormBolField = ({
  className,
  value,
  onChange,
}: PropsWithClassName<Props>) => {
  return (
    <InputGroup
      className={className}
      inputType="text"
      inputProps={{
        maxLength: 50,
        onChange: e => {
          onChange(e.target.value)
        },
        name: 'bol',
        value,
      }}
      label="BOL#"
      helpText="Enter your own BOL# or leave blank and Freightview will assign one for you."
    />
  )
}

export const LtlBookingBolField = ({ className }: PropsWithClassName) => {
  const bolNumber = useBookingFormCtx(s => s.bolNumber)
  const { updateBolNumber } = useBookingFormCtx(s => s.actions)
  return (
    <BookingFormBolField
      className={className}
      value={bolNumber ?? ''}
      onChange={updateBolNumber}
    />
  )
}
export const TLBookingBolField = ({ className }: PropsWithClassName) => {
  const { bolNumber, setBolNumber } = useBookingForm()
  return (
    <BookingFormBolField
      className={className}
      value={bolNumber ?? ''}
      onChange={setBolNumber}
    />
  )
}
