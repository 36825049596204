import { Icon, LoadMore } from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'

import { supportMessage } from '../constants'
import { useSearchShipments } from '../features/shipment-list/hooks/useSearchShipments'
import {
  useSearchListParams,
  useSetActiveLoadId,
} from '../features/shipment-list/hooks/useShipmentListParams'
import { ShipmentCard } from '../features/shipment-list/ShipmentCard'
import { useQueryParam } from '../hooks/settings'
import LoadDetailPanel from './list/LoadDetailPanel'
import InfoBox from './InfoBox'
import TopNav from './TopNav'

const SearchResults = () => {
  const searchText = useQueryParam('text')
  const shipmentsQuery = useSearchShipments(searchText)
  const shipmentList: ListShipment[] = shipmentsQuery.data ?? []
  const { activeLoadId } = useSearchListParams(shipmentList)
  const setActiveLoad = useSetActiveLoadId()

  return (
    <div className="grid overflow-hidden bg-[#f2f2f2] grid-tl-shipment-list grid-cols-[19rem_1fr_30.5rem] grid-rows-[auto_1fr_0] h-screen">
      <TopNav />

      <div className="shipment-view-filter-ctn shipment-view-filter-ctn--search b1450:border-b b1450:border-gray-300 b1450:py-6 b1450:px-8 px-6">
        <div className="filter-summary filter-summary--search border-none p-0">
          <p className="mb-0 flex flex-nowrap items-start">
            <Icon icon="search" className="color-dark" transform="down-2.5" />
            <span>Search results for "{searchText}"</span>
          </p>
        </div>
      </div>

      <div
        className="shipment-list-in-process b1400:col-span-2"
        id="shipmentList"
      >
        {shipmentsQuery.isError && (
          <InfoBox icon="triangle">
            Unable to load shipments, {supportMessage}
          </InfoBox>
        )}

        {shipmentsQuery.isLoading && shipmentsQuery.isFetching ? (
          <InfoBox icon="spinner">Loading...</InfoBox>
        ) : (
          <>
            {shipmentList.map(load => (
              <ShipmentCard
                isActive={load.loadId === activeLoadId}
                key={load.loadId}
                onClick={() => setActiveLoad(load.loadId)}
                shipment={load}
              />
            ))}

            {!shipmentList.length && <InfoBox>No shipments found.</InfoBox>}
          </>
        )}

        <LoadMore
          className="mt-4"
          fetchNextPage={shipmentsQuery.fetchNextPage}
          hasNextPage={shipmentsQuery.hasNextPage}
          isLoading={shipmentsQuery.isFetchingNextPage}
        />
      </div>

      <LoadDetailPanel key={activeLoadId} loadId={activeLoadId} />
    </div>
  )
}

export default SearchResults
