import { flattenPages } from '@fv/client-core'

import InfoBox from '../../components/InfoBox'
import { Loading } from '../../components/Loading'
import { supportMessage } from '../../constants'
import { useConfirmedListParams } from './hooks/useShipmentListParams'
import { ShipmentCard } from './ShipmentCard'
import { type ShipmentListProps } from './types'

export type ShipmentListType = 'table' | 'card'
type Props = ShipmentListProps
export const ShipmentCardList = (props: Props) => {
  const { query, onActivate } = props
  const { activeLoadId } = useConfirmedListParams()
  const shipments = flattenPages(query.data?.pages)
  const isInitialLoading =
    query.isLoading || (query.isFetching && !shipments.length)

  if (isInitialLoading) {
    return <Loading />
  }
  if (query.isError) {
    return (
      <InfoBox icon="triangle">
        Unable to load shipments, {supportMessage}
      </InfoBox>
    )
  }
  if (!shipments.length) {
    return <InfoBox>No shipments available.</InfoBox>
  }

  return (
    <>
      {shipments.map(s => (
        <ShipmentCard
          isActive={s.loadId === activeLoadId}
          key={s.loadId}
          onClick={() => onActivate?.(s)}
          shipment={s}
        />
      ))}
    </>
  )
}
