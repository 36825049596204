/* eslint-disable react-hooks/exhaustive-deps */
import { type MutableRefObject, useEffect } from 'react'

export function useIgnoreWheelEvents(
  ref: MutableRefObject<HTMLInputElement | null>,
) {
  useEffect(() => {
    const inputEl = ref.current

    if (!inputEl) return

    function handleMouseWheel(e: WheelEvent) {
      e.preventDefault()
    }

    inputEl.addEventListener('wheel', handleMouseWheel, { passive: false })

    return () => {
      inputEl.removeEventListener('wheel', handleMouseWheel)
    }
  }, [])
}
