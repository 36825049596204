import clsx from 'clsx'
import { useEffect, useRef } from 'react'

import { FvButton, Icon } from '@fv/client-components'
import { addressFuncs } from '@fv/client-core'

import { ValidatedForm } from '../../components/inputs'
import { List, ListItem } from '../../components/List'
import { PillHeader } from '../../components/PillHeader'
import { BillToForm } from '../billTo/BillToForm'
import { billToMapper } from '../billTo/billToFuncs'
import { useFetchLoadBillTo } from '../billTo/useFetchLoadBillTo'
import { useBookingForm } from './hooks'
import { billToKey } from './types'

export const PaymentTerms = () => {
  const { setFormRef, setBillTo, ...form } = useBookingForm()
  const fetchBillTo = useFetchLoadBillTo(
    form.billTo.address ? '' : form.loads[0].loadId,
  )
  const formRef = useRef(null)
  const isEditing = form.activeSection === billToKey
  const section = form.formSections.find(s => s.key === billToKey)
  const hasSection = !!section

  useEffect(() => {
    if (!hasSection) return
    setFormRef(billToKey, formRef)

    if (fetchBillTo.data?.address) {
      setBillTo(billToMapper.dtoToForm(fetchBillTo.data))
    }
  }, [hasSection, setFormRef, setBillTo, fetchBillTo.data])

  if (!hasSection) return null

  function editPaymentTerms() {
    form.activateSection(billToKey)
  }

  return (
    <section className="booking-step-ctn">
      <div
        className={clsx('booking-step', {
          editing: isEditing,
          valid: section?.isValid,
        })}
      >
        <PillHeader
          st
          className={clsx(
            'beer-container-light -top-3.5 z-10 mb-3 flex min-h-[3.5rem] items-center',
            !isEditing &&
              !section?.isValid &&
              '-ml-[calc(.5rem)] w-[calc(100%-3rem)]',
            isEditing && 'absolute left-[calc(1.5rem)] w-[calc(100%-7rem)]',
            section?.isValid &&
              'absolute -left-[calc(.5rem+1px)] w-[calc(100%-3rem+1px)]',
          )}
        >
          {section?.isValid && (
            <FvButton
              theme="round"
              onClick={() => editPaymentTerms()}
              icon="pen-alt"
            />
          )}
          <div>
            <Icon icon="file-invoice-dollar" className="color-dark fa-fw" />
            <span>Billing terms</span>
          </div>
        </PillHeader>

        {isEditing && (
          <ValidatedForm onValidSubmit={form.goToNextSection} ref={formRef}>
            <div className="form-row">
              <BillToForm
                workflow="truckload"
                autoFocus
                requireEmail
                setValues={values => {
                  setBillTo(prev => ({
                    ...prev,
                    ...values,
                  }))
                }}
                values={form.billTo}
              />
            </div>

            <FvButton
              theme="secondary"
              icon="arrow-down-to-line"
              fwd
              type="submit"
            >
              Looks good, proceed
            </FvButton>
          </ValidatedForm>
        )}

        {section?.isValid && (
          <List fcp0 lcp0>
            <ListItem className="leading-6">
              Bill to{' '}
              {addressFuncs.companyAddress(form.billTo, {
                includeLine2: true,
                dependentOnCompany: false,
              })}
            </ListItem>
          </List>
        )}
      </div>
    </section>
  )
}
