import {
  type FullShipment,
  type HoldAtLocation,
  type LoadHandlingUnit,
} from '@fv/client-types'

import { useTerminals } from '../../features/quote/useTerminals'
import { RouteLeg } from './RouteLeg'
import { type LegItem } from './types'

type RouteDetailsProps = {
  holdAtLocation?: HoldAtLocation
  load: FullShipment
}

export const RouteDetails = ({ holdAtLocation, load }: RouteDetailsProps) => {
  const { data: terminals } = useTerminals(load.selectedQuote?.terminals)
  return (
    <>
      {load.locations.map((x, i, locs) => {
        if (i === 0) return null

        const origin = locs[i - 1]
        const destination = x

        return (
          <RouteLeg
            destination={destination}
            items={getLegItems(load.items, destination.sequence)}
            key={destination.sequence}
            origin={origin}
            holdAtLocation={holdAtLocation}
            load={load}
            terminals={terminals}
          />
        )
      })}
    </>
  )
}

function mergeOrAddItem(item: LegItem, items: LegItem[]) {
  const existingItem = items.find(i => i._id === item._id)

  if (existingItem) {
    existingItem.quantity += item.quantity
    existingItem.weight += item.weight
  } else {
    items.push(item)
  }
}

function getLegItems(items: LoadHandlingUnit[], sequence: number): LegItem[] {
  const deliveries: LegItem[] = []
  const pickups: LegItem[] = []
  const legItems: LegItem[] = items
    .filter(
      i =>
        i.dropLocationSequence === sequence ||
        i.pickupLocationSequence === sequence,
    )
    .map(i => ({
      _id: i._id,
      description: i.description,
      dimensionsUOM: i.dimensionsUOM ?? 'in',
      height: i.height,
      length: i.length,
      quantity: i.quantity,
      stopType: i.dropLocationSequence === sequence ? 'delivery' : 'pickup',
      type: i.type,
      weight: i.weight ?? 0,
      weightUOM: i.weightUOM ?? 'lbs',
      width: i.width,
      nmfcNumber: i.nmfcNumber,
      nmfcSubNumber: i.nmfcSubNumber,
      freightClass: i.freightClass,
      ...(i.hazardous?.[0] && { hazardous: i.hazardous[0] }),
      contains: i.contains,
    }))

  legItems
    .filter(i => i.stopType === 'delivery')
    .forEach(i => mergeOrAddItem(i, deliveries))

  legItems
    .filter(i => i.stopType === 'pickup')
    .forEach(i => mergeOrAddItem(i, pickups))

  return [...deliveries, ...pickups]
}
