import YesNoToggle from '../../../components/inputs/YesNoInput'
import { AccountSettingRow } from './AccountSettingRow'
import { useAccountSettings } from './hooks'
import { useAccountSettingMutation } from './mutations'

export const TruckloadSettings = () => {
  const accountSettings = useAccountSettings()
  const { updateAccountSetting } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  const enableMexico = accountSettings.truckload.enableMexico
  const name = 'truckload-mexico'
  return (
    <AccountSettingRow label="Enable Mexico support for FTL" name={name}>
      <YesNoToggle
        checked={enableMexico}
        name={name}
        onChange={v =>
          updateAccountSetting('truckload.enableMexico', v.target.checked)
        }
      />
    </AccountSettingRow>
  )
}
