import { type PropsWithChildren } from 'react'

import TopNav from '../../../components/TopNav'

export const ProvisionerLayout = ({ children }: PropsWithChildren) => {
  return (
    <div>
      <TopNav />
      {children}
    </div>
  )
}

export const ProvisionerContents = ({ children }: PropsWithChildren) => {
  return <div className="mx-auto max-w-7xl p-4">{children}</div>
}
