import {
  vBoolean,
  vCoerceArray,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { ChargeName } from './charge'
import {
  modes,
  pricingMethods,
  pricingTypes,
  shipmentDirections,
} from './common'
import { Country } from './country'
import { LoadDocumentType } from './load-document'

export const analyticsSearchSchema = vObject({
  bookedBy: vOptional(vCoerceArray(vString())),
  bookedDate: vOptional(vString()),
  carrier: vOptional(vCoerceArray(vString())),
  direction: vOptional(vCoerceArray(vPicklist(shipmentDirections))),
  isInterline: vOptional(vBoolean()),
  location: vOptional(vCoerceArray(vString())),
  mode: vOptional(vCoerceArray(vPicklist(modes))),
  pickupDate: vString(),
  pricingMethod: vOptional(vCoerceArray(vPicklist(pricingMethods))),
  pricingType: vOptional(vCoerceArray(vPicklist(pricingTypes))),
  quotedBy: vOptional(vCoerceArray(vString())),
  tag: vOptional(vCoerceArray(vString())),
})

export type AnalyticsSearchDTO = vOutput<typeof analyticsSearchSchema>

export const overviewReportMetrics = ['count', 'spend'] as const
export const overviewReportTimeframes = ['yearly', 'weekly', 'monthly'] as const
export const overviewReportSchema = vObject({
  ...analyticsSearchSchema.entries,
  metric: vPicklist(overviewReportMetrics),
  timeframe: vPicklist(overviewReportTimeframes),
})
export type OverviewReportDto = vOutput<typeof overviewReportSchema>

export type OverviewCountsResponse = {
  totalShipments: number
  totalSpend: number
  totalProviders: number
}

export interface OverviewReportResult {
  id: string
  parcel: number
  truckload: number
  ltl: number
}
export interface OverviewReportResponse {
  results: OverviewReportResult[]
}

export interface ProviderAnalyticsEntry {
  _id: string
  costPerCwt: number
  count: number
  providerName: string
  spend: number
  weight: number
  transitDays: number
  rejectionCount: number
}
export interface ProviderAnalyticsResponse {
  providerCount: number
  providers: ProviderAnalyticsEntry[]
  realizedSavings: number
  shipmentCount: number
  totalSpend: number
  unrealizedSavings: number
}

export interface AccessorialsEntry {
  key: ChargeName
  count: number
  cost: number
}

export interface AccessorialsProviderEntry {
  providerName: string
  accessorials: AccessorialsEntry[]
}

export interface AccessorialsReportResponse {
  providers: AccessorialsProviderEntry[]
  accessorialTypes: Array<{ key: ChargeName; name: string }>
}

export interface DocAnalyticsDocEntry {
  type: LoadDocumentType
  exists: number
  notExists: number
}
export interface DocAnalyticsProviderEntry {
  carrierId: string
  carrierName: string
  docs: DocAnalyticsDocEntry[]
}
export interface DocumentsAnalyticsResponse {
  providers: DocAnalyticsProviderEntry[]
}

export interface LanesLocationEntry {
  // TODO - replace with Pick<LoadLocation> when that model is brought into this package
  city: string
  state: string
  postalCode: string
  country: Country
  lat: number
  lng: number
  type: 'origin' | 'destination'
}
export interface LaneAnalyticData {
  count: number
  spend: number
  weight: number
}
export interface CarrierLaneData extends LaneAnalyticData {
  carrierName: string
  carrierId: string
}
export interface LanesAnalyticsEntry extends LaneAnalyticData {
  destination: LanesLocationEntry
}
export interface TableLanesAnalyticsEntry
  extends LanesAnalyticsEntry,
    LaneAnalyticData {
  origin: LanesLocationEntry
  carriers: CarrierLaneData[]
}
export interface LanesAnalyticsResponse {
  mapLanes: LanesAnalyticsEntry[]
  tableLanes: TableLanesAnalyticsEntry[]
  shipmentCount: number
}
