import { useMutation } from '@tanstack/react-query'
import { useContext } from 'react'

import { generateString } from '@fv/client-core'
import { type CredentialDetail } from '@fv/client-types'
import { type Country } from '@fv/models'

import { shipperFetch } from '../../../utils/shipperFetch'
import {
  type BillToWithAccountNumber,
  FedexWizardContext,
  type FxfeMfaInitializationRequest,
  type FxfeMfaValidationRequestDto,
  type FxfeProvisionOptionRequest,
  type FxfeProvisionResponse,
} from './types'

export const useFxfeProvisionInitMutation = () => {
  return useMutation((model: FxfeMfaInitializationRequest) =>
    shipperFetch<FxfeProvisionResponse>('/provision/fxfe', {
      method: 'POST',
      body: model,
    }),
  )
}

export const useFxfeProvisionOptionMutation = () => {
  return useMutation((model: FxfeProvisionOptionRequest) =>
    shipperFetch<FxfeProvisionResponse>('/provision/fxfe/generate', {
      method: 'POST',
      body: model,
    }),
  )
}

export const useFxfeProvisionValidateMutation = () => {
  return useMutation((model: FxfeMfaValidationRequestDto) =>
    shipperFetch<FxfeProvisionResponse>('/provision/fxfe/validate', {
      method: 'POST',
      body: model,
    }),
  )
}

export const useFxfeWizardContext = () => {
  const context = useContext(FedexWizardContext)
  if (!context) throw new Error('FedexWizardContext.Provider not rendered')
  return context
}

export const useFxfeSetStep = () => {
  const {
    step: [, setStep],
  } = useFxfeWizardContext()
  return setStep
}

export const useFxfeToken = () => {
  const {
    token: [token],
  } = useFxfeWizardContext()
  return token
}

export const useFxfeSetToken = () => {
  const {
    token: [, setToken],
  } = useFxfeWizardContext()

  return {
    setToken,
    setTokenFromDetails: (details: FxfeProvisionResponse['details']) => {
      setToken(details?.find(d => d.type === 'account-token')?.value ?? '')
    },
  }
}

export const buildDetailsFromModel = (
  prefix: 'freight-account' | 'direct-account',
  model: BillToWithAccountNumber,
) => {
  const details: CredentialDetail[] = []
  if (model.accountNumber) {
    details.push({ type: `${prefix}-number`, value: model.accountNumber })
    model.address &&
      details.push({ type: `${prefix}-address`, value: model.address })
    model.city && details.push({ type: `${prefix}-city`, value: model.city })
    model.state && details.push({ type: `${prefix}-state`, value: model.state })
    model.postalCode &&
      details.push({ type: `${prefix}-postalcode`, value: model.postalCode })
    model.country &&
      details.push({ type: `${prefix}-country`, value: model.country })
  }
  return details
}
export const buildModelFromDetails = (
  prefix: 'freight-account' | 'direct-account',
  details: CredentialDetail[],
): BillToWithAccountNumber => {
  const accountNumber = details.find(d => d.type === `${prefix}-number`)
  const address = details.find(d => d.type === `${prefix}-address`)
  const city = details.find(d => d.type === `${prefix}-city`)
  const state = details.find(d => d.type === `${prefix}-state`)
  const postalCode = details.find(d => d.type === `${prefix}-postalcode`)
  const country = details.find(d => d.type === `${prefix}-country`)

  return {
    accountNumber: accountNumber?.value?.toString() ?? '',
    address: address?.value?.toString() ?? '',
    city: city?.value?.toString() ?? '',
    postalCode: postalCode?.value?.toString() ?? '',
    state: state?.value?.toString() ?? '',
    country: (country?.value?.toString() as Country) ?? 'us',
  }
}

export const genCustomerName = (accountId: string) =>
  `${accountId}-${generateString(7)}`
