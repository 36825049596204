import clsx from 'clsx'
import {
  type Dispatch,
  type FormEvent,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import { Icon, Tooltip } from '@fv/client-components'

import CheckboxField from '../../components/inputs/CheckboxField'
import { AcceptedAuditForm } from './AcceptedAuditForm'
import ArchivedAuditForm from './ArchivedAuditForm'
import AuditCardHeader from './AuditCardHeader'
import DisputedAuditForm from './DisputedAuditForm'
import OutstandingAuditForm from './OutstandingAuditForm'
import { type Audit } from './types'

type AuditListCardProps = {
  auditsToArchive: string[]
  data: Audit
  isActive: boolean
  isRemitting: boolean
  setActiveAudit: Dispatch<SetStateAction<string | null>>
  setAuditsToArchive: Dispatch<SetStateAction<string[]>>
  status: 'accepted' | 'archived' | 'disputed' | 'outstanding'
}

const AuditListCard = ({
  auditsToArchive,
  data,
  isActive,
  isRemitting,
  setActiveAudit,
  setAuditsToArchive,
  status,
}: AuditListCardProps) => {
  const { loadId, payments } = data
  const cardRef = useRef<HTMLDivElement | null>(null)
  const isAccepted = status === 'accepted'
  const autoAccepted = !!payments?.some(p => p.addedBy?.type === 'system')

  useEffect(() => {
    isActive && cardRef.current?.scrollIntoView({ block: 'nearest' })
  }, [isActive])

  const onCheckboxChange = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const { checked } = e.target as HTMLInputElement

      setAuditsToArchive(prev => {
        if (checked) return prev.concat(loadId)
        return prev.filter((id: string) => id !== loadId)
      })
    },
    [loadId, setAuditsToArchive],
  )

  return (
    <div
      className={clsx('audit-card', {
        'ml-10': isAccepted,
      })}
      ref={cardRef}
    >
      {isAccepted && (
        <div className="absolute -left-[2.5rem] bottom-0 top-0 flex w-[26px] flex-col border border-[#99d87a] bg-[#f2fbed]">
          {isRemitting ? (
            <span className="flex !justify-center">
              <Icon icon="spinner" />
            </span>
          ) : (
            <>
              <CheckboxField
                className="-ml-px -mt-px"
                checked={auditsToArchive.includes(loadId)}
                name={`archive_${loadId}`}
                onChange={onCheckboxChange}
              />
              {autoAccepted && (
                <Tooltip label="This invoice was automatically accepted">
                  <Icon icon="server" className="mt-2" />
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}

      <AuditCardHeader data={data} />
      <hr />

      {isAccepted && (
        <AcceptedAuditForm
          audit={data}
          isActive={isActive}
          setActive={setActiveAudit}
        />
      )}

      {status === 'archived' && (
        <ArchivedAuditForm
          audit={data}
          isActive={isActive}
          setActive={setActiveAudit}
        />
      )}

      {status === 'disputed' && (
        <DisputedAuditForm
          audit={data}
          isActive={isActive}
          setActive={setActiveAudit}
        />
      )}

      {status === 'outstanding' && (
        <OutstandingAuditForm
          audit={data}
          isActive={isActive}
          setActive={setActiveAudit}
        />
      )}
    </div>
  )
}

export default AuditListCard
