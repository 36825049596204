import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri, supportMessage } from '../../constants'
import { userSettingsKey } from '../../hooks/settings/useUserSettings'
import { shipperFetch } from '../../utils/shipperFetch'

type LogoRequest = {
  file: File
}

type LogoResponse = {
  imageUrl: string
}

const postLogo = async (dto: LogoRequest) => {
  const formData = new FormData()
  formData.append('logoFile', dto.file)

  const endpoint = `${apiUri}/branding`
  const options = buildFetchOptionsWithAuth({
    body: formData,
    method: 'POST',
    headers: {
      'x-document-type': dto.file.type,
    },
  })

  delete options.headers['Content-type']
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

const removeLogo = async (): Promise<LogoResponse> => {
  return await shipperFetch(`/branding/`, {
    method: 'DELETE',
  })
}

export const useLogoMutations = () => {
  const addMutation = useAddMutation()
  const removeMutation = useRemoveMutation()

  return {
    addLogo: addMutation.mutateAsync,
    removeLogo: removeMutation.mutateAsync,
    isAdding: addMutation.isLoading,
    isRemoving: removeMutation.isLoading,
  }
}

const useRemoveMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(() => removeLogo(), {
    onSettled() {
      queryClient.invalidateQueries(userSettingsKey)
    },
    onError() {
      toast.error(`Unable to remove logo, ${supportMessage}`)
    },
  })
}

const useAddMutation = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation(postLogo, {
    onSettled() {
      queryClient.invalidateQueries(userSettingsKey)
    },
    onError(error: { message: string }) {
      toast.error(`Unable to add logo, ${error.message}`)
    },
  })
  return mutation
}
