import { addressFuncs } from '@fv/client-core'
import { type SelectFieldOption } from '@fv/client-types'

import { InputGroup } from '../../components/inputs/InputGroup'
import { type FormSectionProps } from '../../components/inputs/types'
import { requireRateLocation } from './bookFuncs'
import { useBookingFormCtx } from './BookingFormProvider'

export const LTLRateLocation = ({ disabled }: FormSectionProps) => {
  const { setRateLocationType } = useBookingFormCtx(s => s.actions)
  const isFreightCollect = useBookingFormCtx(s => !!s.isFreightCollect)
  const rateLocationType = useBookingFormCtx(s => s.rateLocationType)

  const options: SelectFieldOption[] = useBookingFormCtx(s => {
    if (!requireRateLocation(s.locations, isFreightCollect)) return []
    return s.locations.map(x => ({
      text: addressFuncs.streetAddress(x),
      value: x.type,
    }))
  })

  if (!options.length) return null

  return (
    <div className="border-fv-gray mb-8 border bg-fv-gray-100 px-4 pb-2 pt-4">
      <div className="form-row">
        <InputGroup
          className="basis-full"
          inputProps={{
            disabled,
            name: 'rateLocationType',
            onChange: setRateLocationType,
            options,
            value: rateLocationType,
          }}
          inputType="select"
          label="Which account would you like to use to rate this shipment?"
        />
      </div>
    </div>
  )
}
