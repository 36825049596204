import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth } from '@fv/client-core'

import { apiUri } from '../../constants'

async function fetchSpotQuoteEmailPreview({
  loadId,
  shipperMessage,
  systemMessage,
}) {
  const endpoint = `${apiUri}/notifications/preview/spot-quote-request`
  const params = new URLSearchParams()
  params.set('loadId', loadId)
  params.set('systemMessage', systemMessage)
  if (shipperMessage) {
    params.set('shipperMessage', shipperMessage)
  }

  const url = endpoint + '?' + params.toString()
  const options = buildFetchOptionsWithAuth()
  const response = await fetch(url, options)
  if (response.ok) return response.text()
  const error = await response.json()
  throw Error(error.message)
}

export function useSpotQuoteEmailPreview({
  isOpen,
  loadId,
  shipperMessage,
  systemMessage,
}) {
  return useQuery(
    ['spot-quote-email-preview', loadId, shipperMessage, systemMessage],
    () => fetchSpotQuoteEmailPreview({ loadId, shipperMessage, systemMessage }),
    {
      enabled: Boolean(isOpen && loadId),
    },
  )
}
