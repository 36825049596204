import { useMutation, useQueryClient } from '@tanstack/react-query'

import { type Permission } from '../../types/Permission'
import { shipperFetch } from '../../utils/shipperFetch'
import { useToastedMutation } from '../../utils/useToastedMutation'
import { accountUserKeys } from './queries'

const postUser = (model: {
  permissions: Permission[]
  email: string
  markup?: number
}) =>
  shipperFetch(`/account-users`, {
    body: model,
    method: 'POST',
  })
export const useAddUser = () => {
  const queryClient = useQueryClient()
  return useToastedMutation(postUser, {
    onSettled: () => {
      queryClient.invalidateQueries(accountUserKeys.all)
    },
    toastErrorPrefix: () => 'Error creating user',
  })
}
const putUser = (model: {
  id: string
  permissions: Permission[]
  markup?: number
}) => {
  const { id, ...body } = model
  return shipperFetch(`/account-users/${id}`, {
    body,
    method: 'PUT',
  })
}
export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  return useMutation(putUser, {
    onSettled: () => {
      queryClient.invalidateQueries(accountUserKeys.all)
    },
  })
}

const deleteUser = ({ _id }: { _id: string }) =>
  shipperFetch(`/account-users/${_id}`, {
    method: 'DELETE',
  })
export const useRemoveUser = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteUser, {
    onSettled: () => {
      queryClient.invalidateQueries(accountUserKeys.all)
    },
  })
}

export const useResendInvite = () => {
  return useMutation(({ _id }: { _id: string }) =>
    shipperFetch(`/account-users/${_id}/resend-invite`, {
      method: 'POST',
    }),
  )
}
