import isEqual from 'lodash/isEqual'

type StrippableValue =
  | number
  | boolean
  | undefined
  | string
  | null
  | []
  | Record<string, never>

type Entry<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T]

export function strip<T>(
  collection: T,
  opts: { toStrip?: StrippableValue[]; emptyReplacement?: any } = {},
): any {
  if (!collection) return collection
  let toStrip = opts.toStrip || [null, undefined, '', [], {}]
  const shouldReplace = !!Object.entries(opts).find(
    ([k, v]) => k === 'emptyReplacement',
  )
  if (shouldReplace)
    toStrip = toStrip.filter(v => !isEqual(opts.emptyReplacement, v))

  const recursed = Object.entries(collection).reduce(
    (acc: Entry<T>[], [key, value]) => {
      let newVal = value
      if (
        typeof value === 'object' &&
        value !== null &&
        !(value instanceof Date)
      ) {
        newVal = strip(value, opts)
      }

      if (shouldReplace && specialArrayIncludes(toStrip, newVal)) {
        //@ts-expect-error: this type should always be accurate
        acc.push([key, opts.emptyReplacement])
      }

      if (!specialArrayIncludes(toStrip, newVal)) {
        //@ts-expect-error: this type should always be accurate
        acc.push([key, newVal])
      }
      return acc
    },
    [],
  )

  if (Array.isArray(collection)) return recursed.map(([i, v]) => v)
  else return Object.fromEntries(recursed)
}

function specialArrayIncludes(arr: StrippableValue[], value: any) {
  return arr.some(v => isEqual(v, value))
}
