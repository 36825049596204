import { type ChangeEvent, useCallback, useEffect } from 'react'

import { MultiFilterRow } from './MultiFilterRow'
import { type MultiValueFilterComponentProps } from './types'

export const MultiSelectFilter = ({
  name,
  value,
  options,
  setQueryString,
}: MultiValueFilterComponentProps) => {
  const removeRow = useCallback(
    (v: string) => {
      setQueryString(qs => {
        const params = new URLSearchParams(qs)
        params.delete(name)

        const nextValues = value.filter(o => o !== v)
        nextValues.forEach(v => params.append(name, v))

        return params.toString()
      })
    },
    [name, value, setQueryString],
  )

  // Sometimes the values saved as filters are no longer valid (carriers removed, tags removed, user removed, etc)
  // https://github.com/freightview/freightview/issues/22705
  useEffect(() => {
    const removedRows = value.filter(v => !options.some(o => o.value === v))
    removedRows.forEach(r => removeRow(r))
  }, [removeRow, value, options])

  return (
    <>
      {value.map((v, i) => {
        const fieldName = `${name}_${i}`
        const fieldOptions = options.filter(o => !value.includes(o.value))
        const isLastRow = i === value.length - 1

        function onChange(e: ChangeEvent<HTMLSelectElement>) {
          setQueryString(qs => {
            const params = new URLSearchParams(qs)
            params.delete(name)

            const nextValues = value.slice()
            nextValues[i] = e.target.value
            nextValues.forEach(v => params.append(name, v))

            return params.toString()
          })
        }

        function addRow() {
          setQueryString(qs => {
            const params = new URLSearchParams(qs)
            params.append(name, fieldOptions[0].value)

            return params.toString()
          })
        }

        const existing = options.find(o => o.value === v)

        if (!existing) {
          return null
        }

        return (
          <MultiFilterRow
            addRow={addRow}
            canAdd={isLastRow && fieldOptions.length > 0}
            canRemove={value.length > 1}
            isLastRow={isLastRow}
            key={fieldName}
            name={fieldName}
            onChange={onChange}
            options={[existing].concat(fieldOptions)}
            removeRow={() => removeRow(v)}
            value={v}
          />
        )
      })}
    </>
  )
}
