import {
  type Query,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useSearchParams } from 'react-router-dom'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  parseQueryString,
  setDataByPage,
} from '@fv/client-core'
import { type AuditStatus } from '@fv/models'

import { apiUri, defaultPageSize, supportMessage } from '../../constants'
import { useRemoveFromList } from '../../hooks/shipments'
import { migrateParams } from '../../utils/stringTransforms'
import { type Audit } from './types'

// Ensure order in queryKey used by cache
function normalizeQueryString(qs: string) {
  const source = new URLSearchParams(qs)
  const target = new URLSearchParams()

  migrateParams('pickupDate', source, target)
  migrateParams('deliveryDate', source, target)
  migrateParams('bookedDate', source, target)
  migrateParams('direction[]', source, target)
  migrateParams('mode[]', source, target)
  migrateParams('carrier[]', source, target)
  migrateParams('hasInvoice', source, target)
  migrateParams('isInterline', source, target)
  migrateParams('pricingMethod[]', source, target)
  migrateParams('pricingType[]', source, target)
  migrateParams('tag[]', source, target)
  migrateParams('location[]', source, target)
  migrateParams('isLiveLoad', source, target)
  migrateParams('search', source, target)

  return target.toString()
}

export const auditKeys = {
  all: ['audits'] as const,
  counts: () => [...auditKeys.all, 'counts'] as const,
  list: (status: AuditStatus | null) => [...auditKeys.all, status] as const,
  filteredCounts: (qs: string) => {
    return [...auditKeys.counts(), normalizeQueryString(qs)] as const
  },
  filteredList: (status: AuditStatus | null, qs: string) => {
    return [...auditKeys.list(status), normalizeQueryString(qs)] as const
  },
}

type AuditListCounts = {
  accepted: number
  archived: number
  disputed: number
  outstanding: number
}

export const defaultAuditStatus = 'outstanding'

export function useAuditListStatus() {
  const [searchParams, setSearchParams] = useSearchParams()
  const status = searchParams.get('status[]') as AuditStatus | null

  useEffect(() => {
    if (!status) {
      setSearchParams({
        ...parseQueryString(searchParams.toString()),
        'status[]': [defaultAuditStatus],
      })
    }
  }, [searchParams, setSearchParams, status])

  return status
}

function calculateBucketChanges(
  prev: Pick<Audit, 'statuses'>,
  current: Pick<Audit, 'statuses'>,
) {
  let outstanding = 0
  let accepted = 0
  let disputed = 0

  if (
    prev.statuses.includes('outstanding') &&
    !current.statuses.includes('outstanding')
  ) {
    outstanding = -1
  } else if (
    !prev.statuses.includes('outstanding') &&
    current.statuses.includes('outstanding')
  ) {
    outstanding = 1
  }

  if (
    prev.statuses.includes('accepted') &&
    !current.statuses.includes('accepted')
  ) {
    accepted = -1
  } else if (
    !prev.statuses.includes('accepted') &&
    current.statuses.includes('accepted')
  ) {
    accepted = 1
  }

  if (
    prev.statuses.includes('disputed') &&
    !current.statuses.includes('disputed')
  ) {
    disputed = -1
  } else if (
    !prev.statuses.includes('disputed') &&
    current.statuses.includes('disputed')
  ) {
    disputed = 1
  }

  return {
    outstanding,
    accepted,
    disputed,
    archived: 0,
  }
}

type PagedAudits = {
  continuationToken?: string
  data: Audit[]
  hasMore?: boolean
}

type InfiniteAuditQueryData = {
  pageParams: Array<number | null>
  pages: PagedAudits[]
}

function buildCountsQueryFilter(qs: string) {
  return {
    queryKey: auditKeys.counts(),
    predicate: (q: Query) => {
      const queryKeyParams = q.queryKey[2]
      const currentQuery = normalizeQueryString(qs)
      return queryKeyParams === '' || queryKeyParams === currentQuery
    },
  }
}

function applyBucketChanges(
  counts: AuditListCounts | undefined,
  changes: AuditListCounts,
) {
  if (!counts) {
    return
  }

  return {
    accepted: Math.max(counts.accepted + changes.accepted, 0),
    archived: Math.max(counts.archived + changes.archived, 0),
    disputed: Math.max(counts.disputed + changes.disputed, 0),
    outstanding: Math.max(counts.outstanding + changes.outstanding, 0),
  }
}

function updateAuditList(audits: Audit[], audit: Audit) {
  return audits.map(a => (a.loadId === audit.loadId ? audit : a))
}

function useApplyStatusChange(currentView: AuditStatus) {
  const { search } = useLocation()
  const queryClient = useQueryClient()
  const removeFromList = useRemoveFromList()

  return useCallback(
    ({ previous, current }: { previous: Audit; current: Audit }) => {
      const isInCurrentList = current.statuses.includes(currentView)

      if (!isInCurrentList) {
        removeFromList({
          loadId: current.loadId,
          queryFilter: auditKeys.list(currentView),
        })
      } else {
        queryClient.setQueriesData<InfiniteAuditQueryData>(
          auditKeys.list(currentView),
          setDataByPage(audits => updateAuditList(audits, current)),
        )
      }

      const bucketChanges = calculateBucketChanges(previous, current)
      const changedBuckets = Object.keys(bucketChanges).filter(
        b => bucketChanges[b as AuditStatus] !== 0 && b !== currentView,
      ) as AuditStatus[]

      changedBuckets.forEach(status =>
        queryClient.invalidateQueries(auditKeys.list(status)),
      )

      queryClient.setQueriesData<AuditListCounts | undefined>(
        buildCountsQueryFilter(search),
        prev => applyBucketChanges(prev, bucketChanges),
      )

      // Invalidate count queries not set above
      queryClient.invalidateQueries(auditKeys.counts(), {
        predicate: (q: Query) => {
          const queryKeyParams = q.queryKey[2]
          if (queryKeyParams === '') return false
          return queryKeyParams !== normalizeQueryString(search)
        },
      })
    },
    [queryClient, removeFromList, currentView, search],
  )
}

async function fetchAuditList(qs: string): Promise<PagedAudits> {
  const endpoint = `${apiUri}/audit?${qs}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useAuditList() {
  const { search } = useLocation()
  const status = useAuditListStatus()

  const auditsQuery = useInfiniteQuery(
    auditKeys.filteredList(status, search),
    ({ pageParam = '' }) => {
      const params = new URLSearchParams(search)
      params.set('limit', String(defaultPageSize))
      params.set('continuationToken', pageParam)

      return fetchAuditList(params.toString())
    },
    {
      enabled: Boolean(status),
      getNextPageParam: lastPage => {
        if (!lastPage?.hasMore) return
        return lastPage.continuationToken
      },
    },
  )

  const auditList = useMemo(
    () =>
      (auditsQuery.data?.pages ?? []).reduce(
        (all, { data }) => all.concat(...data),
        [] as Audit[],
      ),
    [auditsQuery.data?.pages],
  )

  // Handle invalid filters
  useEffect(() => {
    if (
      auditsQuery.error instanceof Error &&
      auditsQuery.error.name === 'EventValidationError'
    ) {
      toast.error(auditsQuery.error.message)
    }
  }, [auditsQuery.error])

  return {
    ...auditsQuery,
    data: auditList,
  }
}

type AddPaymentDTO = {
  amount?: number | string
  invoiceAmount?: string
  invoiceNumber?: string
  loadId: string
  message?: string
  invoiceDate?: string
}

async function addLoadPayment(dto: AddPaymentDTO): Promise<Audit> {
  const amount = Number(dto.amount)
  const invoiceAmount = Number(dto.invoiceAmount)
  const invoiceNumber = dto.invoiceNumber?.trim()
  const invoiceDate = dto.invoiceDate
  const message = dto.message?.trim()
  const includeInvoiceAmount =
    typeof invoiceAmount === 'number' && !isNaN(invoiceAmount)

  const endpoint = `${apiUri}/audit/${dto.loadId}/payment`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      amount,
      invoiceDate,
      ...(includeInvoiceAmount && { invoiceAmount }),
      ...(invoiceNumber && { invoiceNumber }),
      ...(message && { message }),
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useAddPayment(audit: Audit, currentView: AuditStatus) {
  const applyStatusChange = useApplyStatusChange(currentView)

  const addPaymentMutation = useMutation(addLoadPayment, {
    onSuccess: updatedAudit => {
      applyStatusChange({
        previous: audit,
        current: updatedAudit,
      })
    },
  })

  async function addPayment(dto: AddPaymentDTO) {
    return addPaymentMutation.mutateAsync(dto).catch(() => {
      toast.error(`Unable to add payment, ${supportMessage}`)
    })
  }

  return {
    addPayment,
    isAddingPayment: addPaymentMutation.isLoading,
  }
}

async function resetLoadAudit(loadId: string): Promise<Audit> {
  const endpoint = `${apiUri}/audit/${loadId}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useResetAudit(audit: Audit, currentView: AuditStatus) {
  const applyStatusChange = useApplyStatusChange(currentView)

  const resetAuditMutation = useMutation(resetLoadAudit, {
    onSuccess: updatedAudit => {
      applyStatusChange({
        previous: audit,
        current: updatedAudit,
      })
    },
  })

  function resetAudit(loadId: string) {
    resetAuditMutation.mutateAsync(loadId).catch(() => {
      toast.error(`Unable to reset audit, ${supportMessage}`)
    })
  }

  return {
    resetAudit,
    isResettingAudit: resetAuditMutation.isLoading,
  }
}

async function fetchAuditListCounts(qs: string): Promise<AuditListCounts> {
  const endpoint = `${apiUri}/audit/counts${qs}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useAuditListCount(status: AuditStatus) {
  const { search } = useLocation()
  const listCountsQuery = useQuery(auditKeys.filteredCounts(search), () => {
    return fetchAuditListCounts(search)
  })

  const count = listCountsQuery.data?.[status]

  return useMemo(
    () => ({
      count: count ?? 0,
      isLoading: listCountsQuery.isLoading,
    }),
    [count, listCountsQuery.isLoading],
  )
}

type DisputeInvoiceDTO = {
  amount: number | string
  emails: string[]
  invoiceNumber?: string
  loadId: string
  message?: string
  invoiceDate?: string
}

async function disputeLoadInvoice(dto: DisputeInvoiceDTO): Promise<Audit> {
  const amount = Number(dto.amount)
  const emails = dto.emails.map(e => e.trim().toLocaleLowerCase())
  const invoiceNumber = dto.invoiceNumber?.trim()
  const message = dto.message?.trim()
  const invoiceDate = dto.invoiceDate
    ? dayjs.utc(dto.invoiceDate).format('YYYY-MM-DD')
    : ''

  const endpoint = `${apiUri}/audit/${dto.loadId}/dispute`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      amount,
      emails,
      ...(invoiceNumber && { invoiceNumber }),
      ...(message && { message }),
      ...(invoiceDate && { invoiceDate }),
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useDisputeInvoice(audit: Audit, currentView: AuditStatus) {
  const applyStatusChange = useApplyStatusChange(currentView)

  const disputeInvoiceMutation = useMutation(disputeLoadInvoice, {
    onSuccess: updatedAudit => {
      applyStatusChange({
        previous: audit,
        current: updatedAudit,
      })
    },
  })

  function disputeInvoice(dto: DisputeInvoiceDTO) {
    disputeInvoiceMutation.mutateAsync(dto).catch(() => {
      toast.error(`Unable to dispute invoice, ${supportMessage}`)
    })
  }

  return {
    disputeInvoice,
    isDisputing: disputeInvoiceMutation.isLoading,
  }
}

type RemitPaymentsDTO = {
  doExport?: boolean
  loadIds: string[]
}

async function remitLoadPayments(dto: RemitPaymentsDTO): Promise<string[]> {
  if (!dto.loadIds.length) throw new Error('Missing load ids')

  const endpoint = `${apiUri}/audit/remit`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      doExport: Boolean(dto.doExport),
      loadIds: dto.loadIds,
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return dto.loadIds
  throw response.errorMessage
}

export function useRemitPayments() {
  const { search } = useLocation()
  const queryClient = useQueryClient()
  const removeFromList = useRemoveFromList()

  const remitPaymentsMutation = useMutation(remitLoadPayments, {
    onSuccess: loadIds => {
      loadIds.forEach(loadId => {
        removeFromList({
          loadId,
          queryFilter: auditKeys.list('accepted'),
        })
      })

      queryClient.invalidateQueries(auditKeys.list('archived'))
      queryClient.setQueriesData<AuditListCounts | undefined>(
        buildCountsQueryFilter(search),
        prev =>
          prev && {
            ...prev,
            accepted: prev.accepted - loadIds.length,
            archived: prev.archived + loadIds.length,
          },
      )

      // Invalidate count queries not set above
      queryClient.invalidateQueries(auditKeys.counts(), {
        predicate: (q: Query) => {
          const queryKeyParams = q.queryKey[2]
          if (queryKeyParams === '') return false
          return queryKeyParams !== normalizeQueryString(search)
        },
      })
    },
  })

  function remitPayments(dto: RemitPaymentsDTO) {
    remitPaymentsMutation.mutateAsync(dto).catch(() => {
      toast.error(`Unable to remit payments, ${supportMessage}`)
    })
  }

  const isLoading = remitPaymentsMutation.isLoading
  const isExporting = remitPaymentsMutation.variables?.doExport ?? false

  return {
    remitPayments,
    isArchiving: isLoading && !isExporting,
    isExporting: isLoading && isExporting,
  }
}

type AddNoteDTO = {
  loadId: string
  note: string
}

async function addAuditNote(dto: AddNoteDTO): Promise<Audit> {
  const endpoint = `${apiUri}/audit/${dto.loadId}/notes`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ note: dto.note.trim() }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useAddNote() {
  const queryClient = useQueryClient()

  return useMutation(addAuditNote, {
    onSuccess: updatedAudit => {
      queryClient.setQueriesData<InfiniteAuditQueryData>(
        auditKeys.list('disputed'),
        setDataByPage(audits => updateAuditList(audits, updatedAudit)),
      )
    },
  })
}

type RemoveDisputeDTO = {
  loadId: string
}

async function removeDispute(dto: RemoveDisputeDTO): Promise<Audit> {
  const endpoint = `${apiUri}/audit/${dto.loadId}/dispute`
  const options = buildFetchOptionsWithAuth({
    method: 'DELETE',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useRemoveDispute(audit: Audit, currentView: AuditStatus) {
  const applyStatusChange = useApplyStatusChange(currentView)

  const removeDisputeMutation = useMutation(removeDispute, {
    onSuccess: updatedAudit => {
      applyStatusChange({
        previous: audit,
        current: updatedAudit,
      })

      toast.success('Dispute removed successfully.')
    },
  })

  function removeInvoiceDispute(dto: RemoveDisputeDTO) {
    removeDisputeMutation.mutateAsync(dto).catch(() => {
      toast.error(`Unable to remove disputed amount, ${supportMessage}`)
    })
  }

  return {
    removeInvoiceDispute,
    isRemovingDispute: removeDisputeMutation.isLoading,
  }
}

type AuditSettings = {
  autoArchiveShipment?: boolean
  emailDispute?: boolean
  carriers: Array<{
    carrierId: string
    emails: string[]
  }>
}

async function fetchAuditSettings(): Promise<AuditSettings> {
  const endpoint = `${apiUri}/audit/settings`
  const options = buildFetchOptionsWithAuth({
    method: 'GET',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

function useAuditSettings() {
  return useQuery(['audit-settings'], fetchAuditSettings, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}

export function useAuditSetting(
  setting: 'autoArchiveShipment' | 'emailDispute',
) {
  const settingsQuery = useAuditSettings()
  const auditSettings: AuditSettings | undefined = settingsQuery.data

  return useMemo(() => {
    return auditSettings?.[setting] ?? false
  }, [auditSettings, setting])
}

export function useCarrierEmails(carrierId: string) {
  const settingsQuery = useAuditSettings()
  const auditSettings: AuditSettings | undefined = settingsQuery.data

  return useMemo(() => {
    return (
      auditSettings?.carriers?.find(c => c.carrierId === carrierId)?.emails ??
      []
    )
  }, [auditSettings, carrierId])
}

type ResendDisputeEmail = {
  loadId: string
}

async function resendDispute(dto: ResendDisputeEmail): Promise<Audit> {
  const endpoint = `${apiUri}/audit/${dto.loadId}/resend-dispute-email`
  const options = buildFetchOptionsWithAuth({
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useResendDisputeEmail() {
  const queryClient = useQueryClient()

  const resendDisputeMutation = useMutation(resendDispute, {
    onSuccess: audit => {
      queryClient.setQueriesData<InfiniteAuditQueryData>(
        auditKeys.list('disputed'),
        setDataByPage(audits => updateAuditList(audits, audit)),
      )

      toast.success('Dispute email resent.')
    },
  })

  function resendDisputeEmail(dto: RemoveDisputeDTO) {
    resendDisputeMutation.mutateAsync(dto).catch(() => {
      toast.error(`Unable to send dispute email, ${supportMessage}`)
    })
  }

  return {
    resendDisputeEmail,
    isResending: resendDisputeMutation.isLoading,
  }
}
