import clsx from 'clsx'
import omit from 'lodash/omit'
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react'
import {
  type NavigateOptions,
  NavLink,
  type NavLinkProps,
  type To,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { create } from 'zustand'

type NavState = {
  refreshWaiting: boolean
  notifyRefresh: () => void
}
export const useNavState = create<NavState>(set => ({
  refreshWaiting: false,
  notifyRefresh: () => set({ refreshWaiting: true }),
}))

export const useFvNavigate = () => {
  const navigate = useNavigate()
  const refreshWaiting = useNavState(s => s.refreshWaiting)

  return useCallback(
    (to: To | number, options?: NavigateOptions) => {
      if (refreshWaiting) {
        window.location.href = to.toString()
      } else if (typeof to === 'number') {
        return navigate(to)
      } else {
        return navigate(to, options)
      }
    },
    [navigate, refreshWaiting],
  )
}

type Props = NavLinkProps
export const FvNavLink = forwardRef(
  (
    { to, children, style, className, ...props }: Props,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const refreshWaiting = useNavState(s => s.refreshWaiting)
    const location = useLocation()
    const refreshHref = useMemo(() => {
      if (!refreshWaiting) return undefined
      return convertRelativeToFullPath(location.pathname, to.toString())
    }, [refreshWaiting, to, location])

    const isActive = useMemo(() => {
      if (!refreshWaiting) return false

      const url = new URL(refreshHref, window.location.origin)
      return (
        location.pathname === url.pathname &&
        ((!location.search && !url.search) || location.search === url.search)
      )
    }, [location, refreshWaiting, refreshHref])

    const implClassName = !className
      ? clsx({ active: isActive })
      : typeof className === 'function'
        ? className({
            isActive,
            isPending: false,
            isTransitioning: false,
          })
        : className

    if (refreshWaiting) {
      return (
        <a
          ref={ref}
          href={refreshHref?.toString() ?? '#'}
          className={implClassName}
          style={
            typeof style === 'function'
              ? style({ isActive, isPending: false, isTransitioning: false })
              : style
          }
          {...omit(props, 'end')}
        >
          {typeof children === 'function'
            ? children({ isActive, isPending: false, isTransitioning: false })
            : children}
        </a>
      )
    } else {
      return (
        <NavLink
          ref={ref}
          to={to}
          style={style}
          className={className}
          {...props}
        >
          {children}
        </NavLink>
      )
    }
  },
)
const convertRelativeToFullPath = (
  currentLocation: string,
  relPath: string,
) => {
  if (!relPath.startsWith('/') && !relPath.startsWith('?')) {
    return `${currentLocation.substring(0, currentLocation.lastIndexOf('/'))}/${relPath}`
  }
  return relPath
}
