import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import {
  type EquipmentAccessorialType,
  type PackagingType,
  type Workflow,
} from '@fv/client-types'
import { type EquipmentType, type LocationAccessorialType } from '@fv/models'

import { apiUri } from '../../constants'
import { type EquipmentTypeEnum } from '../../types'
import { type ApiError } from '../../types/ApiError'
import { type CarrierSettings } from '../../types/UserSettings'
import { alphabeticalSorter } from '../../utils/alphabeticalSorter'
import { type AccessorialTypeEnum } from './useAccessorialOptions'
import { useAppSettings } from './useAppSettings'
import { useUserSettings } from '.'

export const quoteSettingsKey = 'quote-settings'

type BasePackage = {
  canBeContained?: boolean
  containsFluid?: boolean
  isContainer?: boolean
  isFluid?: boolean
  key: PackagingType
}

export type PackageOption = BasePackage & { name: string }

type RecentSpotQuoteGroup = {
  groupId: string
}

type MappedQuoteSettings = {
  accessorialOptions: AccessorialTypeEnum[]
  apiEnabledCarriers: CarrierSettings[]
  equipmentOptions: EquipmentTypeEnum[]
  packageOptions: Array<PackageOption>
  recentSpotQuoteGroups: RecentSpotQuoteGroup[]
}

export type QuoteSettings = {
  accessorialTypes: Array<{
    favorite: boolean
    key: EquipmentAccessorialType | LocationAccessorialType
  }>
  apiEnabledCarriers: string[]
  equipmentTypes: Array<{
    favorite: boolean
    key: EquipmentType
  }>
  packageTypes: Array<BasePackage & { favorite: boolean }>
  recentSpotQuoteGroups?: RecentSpotQuoteGroup[]
}

async function fetchQuoteSettings(workflow: Workflow): Promise<QuoteSettings> {
  const endpoint = `${apiUri}/quote-settings/${workflow}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useQuoteSettings(workflow: Workflow) {
  const appSettingsQuery = useAppSettings()
  const userSettingsQuery = useUserSettings()

  const mapToAppSettings = useCallback(
    (data: QuoteSettings): MappedQuoteSettings => {
      const {
        accessorialTypes,
        equipmentTypes,
        packageTypes,
        recentSpotQuoteGroups = [],
      } = data

      const carriers = userSettingsQuery.data?.carriers ?? []
      const enums = appSettingsQuery.data?.enums

      const accessorialOptions = accessorialTypes
        .map(t => {
          const accessorial = enums?.accessorialTypes?.find(
            e => e.mode === workflow && e.key === t.key,
          )

          if (!accessorial) return null

          return {
            key: t.key,
            name: accessorial?.name ?? t.key,
            prompts: accessorial?.prompts ?? [],
            type: accessorial?.type,
          }
        })
        .filter(o => !!o) as AccessorialTypeEnum[]

      const equipmentOptions = equipmentTypes
        .map(t => {
          const equipment = enums?.equipmentTypes?.find(e => e.key === t.key)
          if (!equipment) return null

          return {
            accessorialOptions: (equipment.accessorialTypes ?? []).map(key => {
              const accessorial = enums?.accessorialTypes?.find(
                e => e.mode === workflow && e.key === key,
              )

              return {
                key,
                name: accessorial?.name ?? key,
                prompts: accessorial?.prompts ?? [],
                type: accessorial?.type,
              }
            }),
            favorite: t.favorite,
            key: t.key,
            name: equipment.name ?? t.key,
          }
        })
        .filter(o => !!o)
        .sort(alphabeticalSorter('name')) as EquipmentTypeEnum[]

      const packageOptions = packageTypes.map(({ favorite, ...pkg }) => {
        const type = enums?.packagingTypeList?.find(e => e.key === pkg.key)

        return {
          ...pkg,
          name: type?.name ?? pkg.key,
        }
      })

      return {
        accessorialOptions,
        apiEnabledCarriers: data.apiEnabledCarriers
          .map(id => carriers.find(c => c._id === id))
          .filter(c => !!c) as CarrierSettings[],
        equipmentOptions,
        packageOptions,
        recentSpotQuoteGroups,
      }
    },
    [appSettingsQuery.data?.enums, userSettingsQuery.data?.carriers, workflow],
  )

  const query = useQuery<QuoteSettings, ApiError, MappedQuoteSettings>(
    [quoteSettingsKey, workflow],
    () => fetchQuoteSettings(workflow),
    {
      enabled: Boolean(workflow),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select: mapToAppSettings,
      staleTime: Infinity,
    },
  )

  // App is unusable without quote settings, throw to ErrorBoundary
  useEffect(() => {
    if (query.error && query.error.status !== 401) {
      throw new Error(query.error.message)
    }
  }, [query.error])

  return {
    ...query,
    isLoading:
      (query.isLoading && query.isFetching) || appSettingsQuery.isLoading,
  }
}
