import { useMemo } from 'react'

import { useMessages } from '.'

export function useUnreadMessageIds(opts: {
  carrierId?: string
  loadId?: string
  userId?: string
}) {
  const messagesQuery = useMessages(opts)

  return useMemo(() => {
    const messages = messagesQuery.data ?? []

    return messages
      .filter(m => {
        if (m.author?.role === 'shipper') return false
        const shipper = m.participants.find(p => p.role === 'shipper')
        return !shipper?.readDate
      })
      .map(m => m._id)
  }, [messagesQuery.data])
}
