import { useVirtualizer } from '@tanstack/react-virtual'
import { RefObject } from 'react'

type Props<T> = {
  approximateHeight?: number
  estimateSize?: (index: number) => number
  scrollRef: RefObject<HTMLElement>
  items: T[]
  renderItem: (item: T) => JSX.Element
}
export function VirtualList<T>({
  approximateHeight,
  scrollRef,
  items,
  estimateSize,
  renderItem,
}: Props<T>) {
  if (!estimateSize && !approximateHeight)
    throw new Error('estimateSize or approximateHeight must be supplied')
  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => scrollRef.current,
    estimateSize: estimateSize
      ? estimateSize
      : approximateHeight
        ? () => approximateHeight
        : () => 50,
  })
  const virtualItems = rowVirtualizer.getVirtualItems()
  return (
    <div
      className="relative w-full"
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
        contain: 'strict',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
        }}
        className="divide-fv-gray-300 divide-y "
      >
        {virtualItems.map(vi => (
          <div
            key={vi.key}
            ref={rowVirtualizer.measureElement}
            data-index={vi.index}
          >
            {renderItem(items[vi.index])}
          </div>
        ))}
      </div>
    </div>
  )
}
