import { ReactNode, useEffect } from 'react'

import { OlarkHelperState, useOlarkStore } from './useOlarkStore'

type Props = Partial<OlarkHelperState>

export const useOlarkHelper = (state: Props) => {
  const { update } = useOlarkStore(s => s.actions)
  useEffect(() => () => {
    update({ loadId: undefined })
  })
  useEffect(() => {
    update(state)
  }, [state, update])
}

export const OlarkHelper = (state: Props): ReactNode => {
  useOlarkHelper(state)
  return null
}
