import { type ChangeEvent, useRef, useState } from 'react'

import { FvButton, TextField } from '@fv/client-components'
import { emailRegExpMatcher } from '@fv/client-core'

import { ValidatedForm } from '../../components/inputs'
import { InputAdornment } from '../../components/inputs/InputGroup'
import { type ShareShipmentForm } from './mutations'

type ShareFormProps = {
  form: ShareShipmentForm
  shareShipment?: (emails: string[]) => void
}

const ShareForm = ({
  form,
  shareShipment = form.submitShare,
}: ShareFormProps) => {
  const { isSaving } = form
  const [emails, setEmails] = useState('')
  const emailsRef = useRef<HTMLInputElement | null>(null)

  return (
    <>
      <label htmlFor="emails" className="label col-12">
        Recipient email(s) to share with
      </label>

      <ValidatedForm
        className="input-group col-12"
        onValidSubmit={() => {
          shareShipment(emails.match(emailRegExpMatcher) as string[])
        }}
      >
        <TextField
          className="form-control"
          name="emails"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const emails = e.target.value
            const isValid = !!emails.match(emailRegExpMatcher)
            let error = isValid ? '' : 'Please enter a valid email.'

            if (!emails.trim()) error = 'Carrier rep email is required.'
            emailsRef.current?.setCustomValidity(error)

            setEmails(e.target.value)
          }}
          placeholder="i.e. bill@example.com, ted@example.com"
          readOnly={isSaving}
          ref={emailsRef}
          required
          value={emails}
        />
        <InputAdornment>
          <FvButton
            className=""
            type="submit"
            icon={isSaving ? 'spinner' : 'share'}
            theme="primary"
          >
            Share
          </FvButton>
        </InputAdornment>
      </ValidatedForm>
    </>
  )
}

export default ShareForm
