import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { type HydratedRecipient } from '../../types'

const spotQuoteRecipientKeys = {
  all: ['spot-quote-recipients'] as const,
  load: (id?: string) => [...spotQuoteRecipientKeys.all, id ?? null] as const,
}

async function fetchSpotQuoteRecipients(
  loadId?: string,
): Promise<HydratedRecipient[]> {
  if (!loadId) {
    throw new Error('`loadId` required to fetch spot quote recipients')
  }

  const endpoint = `${apiUri}/shipments/${loadId}/spot-quote-recipients`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useSpotQuoteRecipients(loadId?: string) {
  return useQuery(
    spotQuoteRecipientKeys.load(loadId),
    () => fetchSpotQuoteRecipients(loadId),
    {
      enabled: Boolean(loadId),
    },
  )
}
