import { cleanPhoneNumber } from '@freightview/string-helpers'
import { transform } from 'valibot'

import { formatPhone, parsePhone } from '../../core'
import { dates } from '../../core/dates'
import {
  allowEmptyStringRegex,
  combineRegexWithOr,
} from '../../core/regex-utils'
import {
  Currency,
  currency,
  modes,
  weightUOM,
  workflows,
} from '../../models/common'
import { countries, CountryMapper } from '../../models/country'
import {
  vNumberToString,
  vOptionalDef,
  vPicklist,
  vPipe,
  vRegex,
  vString,
  vTransform,
} from './core'

const dateRegex = (allowEmptyString: boolean) =>
  allowEmptyStringRegex(
    allowEmptyString,
    combineRegexWithOr(
      /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/, //2023-05-09
      /^\d{1,2}[-/]\d{1,2}[-/]\d{4}$/, // 05/09/2023
      /^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/, // 2023-5-9
    ),
  )

export const vMode = vPicklist(modes)
export const vCountry = vPicklist(countries.map(c => c.key))
export const vCountryUpper = vPicklist(countries.map(c => c.name))
export const vCountryAny = vPipe(
  vPicklist([
    ...countries.map(c => c.key),
    ...countries.map(c => c.name),
    'USA',
    'MEX',
    'CAN',
    'usa',
    'mex',
    'can',
  ]),
  transform(value => (value ? CountryMapper.legacyToCore(value) : undefined)),
)
export const vMilitaryTime = vPipe(
  vString(),
  vRegex(/([01]?[0-9]|2[0-3]):[0-5][0-9]/),
)
export const vPhoneExt = () => vPipe(vString(), transform(cleanPhoneNumber))
export const vPhoneNumber = () =>
  vPipe(vNumberToString(), transform(parsePhone))
export const vFormattedPhoneNumber = () =>
  vPipe(vString(), transform(formatPhone))
export const vWeightUOM = vPicklist(weightUOM)
export const vCurrency = (defaultValue?: Currency) =>
  vPipe(
    vOptionalDef(
      vPicklist([...currency.map(c => c.key), ...currency.map(c => c.name)]),
      defaultValue,
    ),
    vTransform(value => value.toLowerCase() as Currency),
  )
// export const vEquipmentType = (mode?: Mode) =>
//   vPicklist(
//     equipmentTypes.filter(e => !mode || e.mode === mode).map(e => e.key),
//   )

export const vDateUtcNoon = (allowEmptyString = false) =>
  vPipe(
    vString(),
    vRegex(dateRegex(allowEmptyString), 'Invalid date'),
    vTransform(v => {
      if (!v) {
        return undefined
      }

      return dates.utcNoon(v as string)
    }),
  )

export const vPostalCode = (allowEmptyString = false) =>
  vPipe(
    vString(),
    vRegex(
      allowEmptyStringRegex(
        allowEmptyString,
        /(^\d{5}([ :-]?\d{4})?)$|(^[A-z]\d[A-z][ :-]?\d[A-z]\d$)/,
      ),
      'Invalid postal code',
    ),
    vTransform(v => (v ? String(v).toUpperCase() : v)),
  )

export const vWorkflow = () => vPicklist([...workflows])
