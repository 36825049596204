import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { useFvNavigate } from '@fv/client-components'
import { parseQueryString, stringifyQueryString } from '@fv/client-core'
import { type ListShipment } from '@fv/client-types'

import { useAppContext, useUserSettings } from '../../../hooks/settings'

export const defaultSortBy = 'pickupDate'
export const defaultSortDirection = '-1'
export type SortBy =
  | 'messageDate'
  | 'pickupDate'
  | 'status'
  | 'carrier'
  | 'deliveryDate'
export type SortDirection = '1' | '-1'

export function useSetActiveLoadId() {
  const { pathname, search } = useLocation()
  const navigate = useFvNavigate()

  return useCallback(
    (loadId?: string) => {
      if (!loadId) return

      const params = new URLSearchParams(search)
      params.set('load', loadId)
      navigate(`${pathname}?${params.toString()}`, { replace: true })
    },
    [navigate, pathname, search],
  )
}

export function useShouldSetActiveLoadId(shipmentList?: ListShipment[]) {
  const { search } = useLocation()

  const activeLoadId = useMemo(() => {
    return new URLSearchParams(search).get('load')
  }, [search])

  const notInList = useMemo(
    () => !shipmentList?.find(s => s.loadId === activeLoadId),
    [activeLoadId, shipmentList],
  )

  return shipmentList && (!activeLoadId || notInList)
}

export function useConfirmedListParams(shipmentList?: ListShipment[]) {
  const [searchParams, setSearchParams] = useSearchParams()
  const firstLoadId = shipmentList?.[0]?.loadId ?? null
  const shouldSetActiveLoadId = useShouldSetActiveLoadId(shipmentList)

  useEffect(() => {
    if (shouldSetActiveLoadId && firstLoadId) {
      setSearchParams(
        p =>
          stringifyQueryString({
            ...parseQueryString(p.toString()),
            load: firstLoadId,
          }),
        {
          replace: true,
        },
      )
    }
  }, [firstLoadId, setSearchParams, shouldSetActiveLoadId])

  return useMemo(
    () => ({
      activeLoadId: searchParams.get('load'),
      qs: searchParams.toString(),
      isArchived: searchParams.get('isArchived') === 'true',
      sortBy: (searchParams.get('sortBy') ?? defaultSortBy) as SortBy,
      sortDirection: (searchParams.get('sortDirection') ??
        defaultSortDirection) as SortDirection,
    }),
    [searchParams],
  )
}

export function useInProcessListParams(shipmentList: ListShipment[]) {
  const [searchParams, setSearchParams] = useSearchParams()
  const firstLoadId = shipmentList[0]?.loadId ?? null
  const shouldSetActiveLoadId = useShouldSetActiveLoadId(shipmentList)
  const isFirstRender = useRef(true)

  const contextQuery = useAppContext()
  const settingsQuery = useUserSettings()
  const userId = contextQuery.data?.user._id
  const prefersAll =
    settingsQuery.data?.preferences?.['spot-quote-list-show-all']

  useEffect(() => {
    const params = new URLSearchParams(searchParams)
    const quotedById = searchParams.get('quotedBy[]')
    const prevQueryString = searchParams.toString()

    if (shouldSetActiveLoadId && firstLoadId) {
      params.set('load', firstLoadId)
    }

    if (isFirstRender.current && !prefersAll && !quotedById && userId) {
      params.set('quotedBy[]', userId)
    }

    if (params.toString() !== prevQueryString) {
      setSearchParams(params, { replace: true })
    }
    isFirstRender.current = false
    return () => {
      isFirstRender.current = true
    }
    // we don't want to watch for the user preference change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstLoadId,
    setSearchParams,
    searchParams,
    shouldSetActiveLoadId,
    userId,
  ])

  const params = searchParams
  return {
    activeLoadId: params.get('load'),
    quotedById: params.get('quotedBy[]'),
    sortBy: (params.get('sortBy') ?? defaultSortBy) as SortBy,
    sortDirection: (params.get('sortDirection') ??
      defaultSortDirection) as SortDirection,
    onChange: (v: Record<string, unknown>) => {
      const qs = {
        ...parseQueryString(params.toString()),
        ...v,
      }
      setSearchParams(new URLSearchParams(stringifyQueryString(qs)))
    },
  }
}

export function useSearchListParams(shipmentList: ListShipment[]) {
  const { pathname, search } = useLocation()
  const navigate = useFvNavigate()
  const firstLoadId = shipmentList[0]?.loadId ?? null
  const shouldSetActiveLoadId = useShouldSetActiveLoadId(shipmentList)

  useEffect(() => {
    const params = new URLSearchParams(search)
    const prevQueryString = params.toString()

    if (shouldSetActiveLoadId && firstLoadId) {
      params.set('load', firstLoadId)
    }

    if (params.toString() !== prevQueryString) {
      navigate(`${pathname}?${params.toString()}`, { replace: true })
    }
  }, [firstLoadId, navigate, pathname, search, shouldSetActiveLoadId])

  return useMemo(() => {
    const params = new URLSearchParams(search)

    return {
      activeLoadId: params.get('load'),
    }
  }, [search])
}
