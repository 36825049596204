import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  type InfiniteQueryData,
  setDataByPage,
  showApiError,
} from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { addressBookKeys } from './useAddressBookAddresses'

type Args = {
  id: string
}
async function deleteAddress({ id }: Args): Promise<null> {
  return shipperFetch(`/addresses/${id}`, { method: 'DELETE' })
}

export function useDeleteAddress() {
  const queryClient = useQueryClient()

  return useMutation(deleteAddress, {
    onSettled: (_, error, { id }) => {
      if (error) return showApiError('Unable to delete address', error)

      queryClient.setQueriesData<InfiniteQueryData<ClientAddressLookupResult>>(
        addressBookKeys.all,
        setDataByPage(addresses => addresses.filter(a => a._id !== id)),
      )

      toast.success('Address deleted successfully.')
    },
  })
}
