import { useEffect } from 'react'

import { InputGroup } from '../../components/inputs/InputGroup'
import { type FormSectionProps } from '../../components/inputs/types'
import { useBookingFormCtx } from '../book/BookingFormProvider'
import { type CustomerRoutedDetail } from '../book/types'
import { useAccountSettings } from '../settings/account-settings/hooks'
import { BillToForm } from './BillToForm'
import { billToMapper } from './billToFuncs'
import { useFetchLoadBillTo } from './useFetchLoadBillTo'

export const BillTo = (props: FormSectionProps) => {
  const actions = useBookingFormCtx(s => s.actions)
  const billTo = useBookingFormCtx(s => s.billTo)
  const loadId = useBookingFormCtx(s => s.loadId)
  const workflow = useBookingFormCtx(s => s.workflow)
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const isNew = useBookingFormCtx(s => s.isNew)
  const settings = useAccountSettings()
  const terms = useBookingFormCtx(s => s.customerRoutedDetail.terms)
  const show =
    isFreightCollect ||
    (settings.booking?.showBillToOnBooking && workflow !== 'parcel')

  const showForm =
    workflow !== 'parcel' &&
    ((isFreightCollect && terms === 'third-party') ||
      (!isFreightCollect && settings.booking?.showBillToOnBooking))
  const computedBillTo = useFetchLoadBillTo(
    show && !billTo.address && !isNew ? loadId : '',
  )
  const label =
    workflow === 'parcel' ? 'Bill to' : 'Bill to address (for bill of lading)'
  const termOpts: Array<{
    label: string
    value: CustomerRoutedDetail['terms']
  }> = [
    {
      label: 'Same as consignee (outbound collect)',
      value: 'outbound-collect',
    },
    {
      label:
        workflow === 'parcel'
          ? 'Third party'
          : 'Enter a different address (third party)',
      value: 'third-party',
    },
  ]

  useEffect(() => {
    if (computedBillTo.data?.address) {
      actions.setBillToValues(billToMapper.dtoToForm(computedBillTo.data))
    }
  }, [computedBillTo.data, actions])

  if (!show) return null

  return (
    <div className="border-fv-gray mb-8 mt-4 border bg-fv-gray-100 px-4 pb-2 pt-6">
      <div className="form-row">
        {isFreightCollect && (
          <InputGroup
            className="col-12"
            inputProps={{
              name: 'billToTerms',
              onChange: e => {
                actions.setCustomerRoutedValues({
                  terms: e.target.value as CustomerRoutedDetail['terms'],
                })
              },
              options: termOpts,
              value: terms,
            }}
            inputType="radio"
            label={label}
          />
        )}

        {showForm && (
          <BillToForm
            {...props}
            workflow={workflow}
            autoFocus={terms === 'third-party'}
            required={true}
            requirePhone
            setValues={actions.setBillToValues}
            values={billTo}
          />
        )}
      </div>
    </div>
  )
}
