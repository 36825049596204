import clsx from 'clsx'
import dayjs from 'dayjs'
import { useState } from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type ShipmentShare } from '@fv/client-types'

import { List, ListItem } from '../../components/List'
import { getDeliveryStatusProps } from './share-utils'

type Props = {
  processingEmails?: string[]
  shares: ShipmentShare[]
  shareShipment: (emails: string[]) => void
}

export const ShareRecipientList = ({
  processingEmails,
  shares,
  shareShipment,
}: Props) => {
  const [shareStatusDisplay, setShareStatusDisplay] = useState(() => {
    const statusDisplay: { [key: string]: boolean } = {}

    shares.forEach(s => {
      statusDisplay[s.email] = false
    })

    return statusDisplay
  })

  return (
    <List fcp0>
      {shares.map(s => {
        const { icon, label } = getDeliveryStatusProps(s.status)
        const isResending = processingEmails?.includes(s.email)

        return (
          <ListItem key={s.email}>
            <FvLinkButton
              onClick={() => {
                setShareStatusDisplay(prev => ({
                  ...prev,
                  [s.email]: !prev[s.email],
                }))
              }}
              icon={icon}
              iconClass="fa-fw"
            >
              {s.email}
            </FvLinkButton>

            {shareStatusDisplay[s.email] && (
              <>
                <hr className="hr-subtle mx-0 my-2" />
                <div className="flex flex-nowrap">
                  <span>
                    <Icon
                      className={clsx('fa-fw', {
                        'color-success':
                          label === 'Delivered' || label === 'Sent',
                        'color-warning': label === 'Failed',
                      })}
                      icon="envelope"
                    />
                    <span>
                      {label}{' '}
                      {dayjs
                        .utc(s.updatedAt)
                        .local()
                        .format('ddd MMM D [at] h:mm a')}
                    </span>
                  </span>
                  <FvLinkButton
                    className="ml-auto"
                    icon={isResending ? 'spinner' : 'paper-plane'}
                    onClick={() => {
                      if (isResending) return
                      shareShipment([s.email])
                    }}
                  >
                    Resend
                  </FvLinkButton>
                </div>
              </>
            )}
          </ListItem>
        )
      })}
    </List>
  )
}
