import findIndex from 'lodash/findIndex'
import findLastIndex from 'lodash/findLastIndex'
import orderBy from 'lodash/orderBy'
import { useState } from 'react'
import toast from 'react-hot-toast'

import { RouteStopDate } from '../../components/QuoteRequest/RouteStopDate'
import { useBookingForm } from '../../features/booking/hooks'
import {
  commoditiesKey,
  composeLocationKey,
  type FormSection,
  locationKeyPrefix,
} from '../../features/booking/types'
import { type FormLocation } from '../../types/FormLocation'
import { buildStop } from '../../utils/locationFuncs'
import { resequenceFormItems } from '../../utils/shipmentFuncs'
import { BookingLocation } from './BookingLocation'
import { getBookingItemsBySequence } from './helpers'

const PICKUP_REASSIGN_WARNING =
  'You have items being picked up at this stop. They will be reassigned to the previous pickup location. Are you sure?'

const DELIVERY_REASSIGN_WARNING =
  'You have items being delivered at this stop. They will be reassigned to the next delivery location. Are you sure?'

export const BookingLocations = () => {
  const [dateSequence, setDateSequence] = useState<number | null>(null)
  const {
    activateSection,
    loads,
    locations,
    formSections,
    setLoads,
    setLocations,
    setFormSections,
  } = useBookingForm()
  const status = loads[0].status

  function updateFormSections(updatedLocations: FormLocation[]) {
    const updatedLocFormSections = orderBy(
      updatedLocations,
      'sequence',
    ).map<FormSection>(l => {
      const key = composeLocationKey(l.sequence)
      const existing = formSections.find(fs => fs.key === key)
      return existing ?? { key, isValid: false, ref: null }
    })

    const startIndex = findIndex(formSections, k =>
      k.key.includes(locationKeyPrefix),
    )
    const stopIndex = findLastIndex(formSections, k =>
      k.key.includes(locationKeyPrefix),
    )
    // replace location sections & add commodities if necessary
    setFormSections([
      ...formSections.slice(0, startIndex),
      ...updatedLocFormSections,
      ...(updatedLocFormSections.length > 2
        ? [{ key: commoditiesKey, isValid: false, ref: null }]
        : []),
      ...formSections
        .slice(stopIndex + 1)
        .filter(fs => fs.key !== commoditiesKey),
    ])
  }

  const resequenceStops = (locs: FormLocation[]) => {
    return locs.map<FormLocation>((loc, index) => ({
      ...loc,
      sequence: index,
      type:
        index === 0
          ? 'origin'
          : index === locs.length - 1
            ? 'destination'
            : 'stop',
    }))
  }

  const addNewStop = (sequence: number) => {
    const prompt =
      'In order for this new stop to appear on your BOL, ensure you mark at least one shipping item as being picked up or delivered to this location.'

    if (!window.confirm(prompt)) {
      return
    }

    const newSequence = sequence + 1
    const nextStop = buildStop({
      sequence: newSequence,
      shipType: 'business dock',
      stopType: 'delivery',
    })

    //insert new location after the specified sequence number
    locations.splice(newSequence, 0, nextStop)

    const updatedLocations = resequenceStops(locations)
    setLocations(updatedLocations)
    updateFormSections(updatedLocations)
    setLoads(prev =>
      resequenceFormItems(prev, updatedLocations, newSequence, true),
    )

    const newKey = composeLocationKey(sequence + 1)
    activateSection(newKey)
  }

  const removeStop = (sequence: number) => {
    const prevStop = locations.find(l => l.sequence === sequence - 1)
    if (!prevStop) {
      return
    }

    const items = getBookingItemsBySequence(loads, sequence)

    if (items.length) {
      const pickups = items.filter(i => i.pickSequence === sequence)
      const deliveries = items.filter(i => i.dropSequence === sequence)
      let shouldUpdate = true

      if (pickups.length) {
        shouldUpdate = window.confirm(PICKUP_REASSIGN_WARNING)
      }

      if (deliveries.length) {
        shouldUpdate = window.confirm(DELIVERY_REASSIGN_WARNING)
      }

      if (!shouldUpdate) return
    }

    const updatedLocations = resequenceStops(
      locations.filter(loc => loc.sequence !== sequence),
    )

    setLoads(prev => resequenceFormItems(prev, updatedLocations, sequence))
    setLocations(updatedLocations)
    updateFormSections(updatedLocations)
    toast.success('Stop removed successfully')
  }

  const setStopData = (data: Partial<FormLocation>) => {
    const sequence = dateSequence ?? 0
    let shouldReassignItems = false
    const prevStop = locations[sequence]
    const nextStop = { ...prevStop, ...data }
    const nextStops = locations.map(x =>
      x.sequence === sequence ? nextStop : x,
    )

    if (
      nextStop?.stopType !== prevStop?.stopType &&
      nextStop?.stopType !== 'both'
    ) {
      const items = getBookingItemsBySequence(loads, sequence)

      if (items.length) {
        const pickups = items.filter(i => i.pickSequence === sequence)
        const deliveries = items.filter(i => i.dropSequence === sequence)
        let shouldUpdate = true

        if (pickups.length && nextStop?.stopType === 'delivery') {
          shouldUpdate = window.confirm(PICKUP_REASSIGN_WARNING)
        }

        if (deliveries.length && nextStop?.stopType === 'pickup') {
          shouldUpdate = window.confirm(DELIVERY_REASSIGN_WARNING)
        }

        if (!shouldUpdate) return
        shouldReassignItems = true
      }
    }

    // Reassign items to prev pickup/next delivery sequence
    if (shouldReassignItems) {
      setLoads(prev => resequenceFormItems(prev, nextStops, sequence))
    }

    setLocations(nextStops)
  }

  return (
    <>
      {locations.map(loc => (
        <BookingLocation
          key={loc.sequence}
          location={loc}
          setDateSequence={setDateSequence}
          addStop={addNewStop}
          removeStop={removeStop}
        />
      ))}

      <RouteStopDate
        onClose={() => setDateSequence(null)}
        isOpen={dateSequence !== null}
        sequence={dateSequence ?? 0}
        onChange={setStopData}
        shouldRestrictRange={status === 'awarded' || status === 'pending'}
        stops={locations}
      />
    </>
  )
}
