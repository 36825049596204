import { vPicklist, vPipe, vTransform } from '../validation'

export type SortDir = 1 | -1
export const sortDirections = [
  1,
  -1,
  '1',
  '-1',
  'asc',
  'desc',
  'ascending',
  'descending',
] as const
type SortDirOptions = (typeof sortDirections)[number]

const parseSortDir = (v: SortDirOptions): SortDir | undefined => {
  switch (v) {
    case -1:
    case '-1':
    case 'desc':
    case 'descending':
      return -1
    case 1:
    case '1':
    case 'asc':
    case 'ascending':
      return 1
    default:
      return undefined
  }
}
export const sortDirSchema = vPipe(
  vPicklist(sortDirections),
  vTransform(parseSortDir),
)
