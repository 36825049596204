import {
  type CredentialDetailEditModel,
  type FormRegister,
  prettifyCredentialType,
} from '@fv/client-core'
import { type CredentialConfig } from '@fv/client-types'

import { InputGroup } from '../../../components/inputs/InputGroup'

type Props = {
  register: FormRegister<CredentialDetailEditModel>
  cred: CredentialConfig
}
export const ProvisioningField = ({ register, cred }: Props) => {
  return (
    <InputGroup
      inputType="text"
      label={prettifyCredentialType(cred.name)}
      inputProps={{
        ...register(cred.name),
        ...(cred.type === 'checkbox' && {
          type: 'toggle',
        }),
      }}
    />
  )
}
