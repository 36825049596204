export const countries = [
  {
    key: 'us',
    name: 'US',
  },
  {
    key: 'ca',
    name: 'CA',
  },
  {
    key: 'mx',
    name: 'MX',
  },
] as const
export type Country = (typeof countries)[number]['key']
export type CountryUpper = (typeof countries)[number]['name']
export type LegacyCountry = 'US' | 'CA' | 'MX' | 'USA' | 'CAN' | 'MEX'

export const CountryMapper = {
  coreToLegacy(country: Country | string): LegacyCountry {
    switch (country?.toLowerCase()) {
      case 'can':
      case 'ca':
        return 'CA'
      case 'mex':
      case 'mx':
        return 'MX'
      default:
        return 'US'
    }
  },

  legacyToCore(country: LegacyCountry | Country | string): Country {
    if (!country) {
      return 'us'
    }

    if (['ca', 'can'].includes(country.toLowerCase())) {
      return 'ca'
    }

    if (['mx', 'mex'].includes(country.toLowerCase())) {
      return 'mx'
    }

    return 'us'
  },

  toUpper(country: LegacyCountry | Country): CountryUpper {
    return this.legacyToCore(country).toUpperCase() as CountryUpper
  },
}
