import { useInfiniteQuery } from '@tanstack/react-query'

import {
  apiUri,
  buildFetchOptionsWithAuth,
  fetchJson,
  flattenPages,
  getNextPageParam,
} from '@fv/client-core'
import { type PagedShipments } from '@fv/client-types'

import { defaultPageSize } from '../../../constants'

type searchShipmentsArgs = {
  search: string
  skip: number
}

async function searchShipments({
  search,
  skip,
}: searchShipmentsArgs): Promise<PagedShipments> {
  const endpoint = `${apiUri}/shipments/search`
  const options = buildFetchOptionsWithAuth({
    query: {
      limit: defaultPageSize,
      search,
      skip,
    },
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useSearchShipments(searchText: string | null) {
  const shipmentsQuery = useInfiniteQuery(
    ['shipments', 'search', searchText],
    ({ pageParam = 0 }) =>
      searchShipments({
        search: searchText ?? '',
        skip: pageParam,
      }),
    {
      enabled: Boolean(searchText),
      getNextPageParam,
    },
  )

  return {
    ...shipmentsQuery,
    data: flattenPages(shipmentsQuery.data?.pages),
  }
}
