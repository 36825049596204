import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { createStore, useStore } from 'zustand'

import { Loading } from '../../components/Loading'
import { useAccountCarrier, useCarrierProvisioningDetails } from './queries'
import {
  type AccountCarrierFormState,
  type AccountCarrierFormStore,
} from './types'

const createAccountCarrierStore = (state?: AccountCarrierFormState) => {
  const defaultStore: AccountCarrierFormState = {
    ...state,
  }
  return createStore<AccountCarrierFormStore>()((set, get) => ({
    ...defaultStore,
    actions: {
      toggleCarrierOverride: () =>
        set(p => ({ carrierOverride: !p.carrierOverride })),

      close() {
        set({
          carrierId: undefined,
          locationId: undefined,
          isAdding: false,
        })
        get().onClose?.()
      },
    },
  }))
}

type AccountCarrierFormStoreEntity = ReturnType<
  typeof createAccountCarrierStore
>
const AccountCarrierFormContext =
  createContext<AccountCarrierFormStoreEntity | null>(null)

type Props = PropsWithChildren<{
  state: Partial<AccountCarrierFormState>
}>
export const AccountCarrierFormProvider = ({ children, state }: Props) => {
  const store = useRef(createAccountCarrierStore(state)).current
  const accountCarrierQuery = useAccountCarrier(state.carrierId)
  const carrierProvisioningDetailsQuery = useCarrierProvisioningDetails(
    state.carrierId,
  )

  useEffect(() => {
    store.setState({
      ...state,
    })
  }, [state, store])

  if (
    carrierProvisioningDetailsQuery.isLoading ||
    accountCarrierQuery.isLoading
  ) {
    return <Loading />
  }
  return (
    <AccountCarrierFormContext.Provider value={store}>
      {children}
    </AccountCarrierFormContext.Provider>
  )
}

export function useAccountCarrierContext<T>(
  selector: (state: AccountCarrierFormStore) => T,
): T {
  const store = useContext(AccountCarrierFormContext)
  if (!store) throw new Error('Missing AccountCarrierFormProvider in the tree')
  return useStore(store, selector)
}

export const useAccountCarrierActions = () => {
  return useAccountCarrierContext(s => s.actions)
}

export const useAccountCarrierState = () => {
  return useAccountCarrierContext(({ actions, ...state }) => state)
}

export const useCarrierDetails = () => {
  const { carrierId } = useAccountCarrierState()
  const { data } = useCarrierProvisioningDetails(carrierId)
  if (!data) throw new Error('Carrier provisioning details not initialized')
  return data
}

export const useAccountCarrierFromState = () => {
  const { carrierId } = useAccountCarrierState()
  const { data } = useAccountCarrier(carrierId)
  return data
}

export const useIsBeta = () => {
  const accountCarrier = useAccountCarrierFromState()
  const carrierDetails = useCarrierDetails()
  const { isAdding } = useAccountCarrierState()
  return accountCarrier?.isBeta || (isAdding && carrierDetails.betaProvisioning)
}
