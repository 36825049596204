import { useQueryClient } from '@tanstack/react-query'

import { shipperFetch } from '../../utils/shipperFetch'
import { useToastedMutation } from '../../utils/useToastedMutation'
import { spotContactKeys } from './queries'
import { type SpotQuoteGroup, type UpsertSpotQuoteContactDto } from './types'

const deleteSpotQuoteContact = ({
  groupId,
  userId,
}: {
  groupId: string
  userId: string
}) =>
  shipperFetch(`/spot-quote-groups/${groupId}/contacts/${userId}`, {
    method: 'DELETE',
  })

const postContact = ({
  groupId,
  model,
}: {
  groupId: string
  model: UpsertSpotQuoteContactDto
}) =>
  shipperFetch(`/spot-quote-groups/${groupId}/contacts`, {
    body: model,
    method: 'POST',
  })

const upsertGroup = (model: {
  groupId?: string
  groupName: string
  emails: string[]
}) => {
  const { groupId, ...body } = model
  return shipperFetch<SpotQuoteGroup[]>(
    `/spot-quote-groups/${model.groupId ?? ''}`,
    {
      body,
      method: groupId ? 'PUT' : 'POST',
    },
  )
}

const removeGroup = (payload: { groupId: string }) =>
  shipperFetch<SpotQuoteGroup[]>(`/spot-quote-groups/${payload.groupId}`, {
    method: 'DELETE',
  })

const useClearQueryCache = () => {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries(spotContactKeys.all)
}

export const useCreateSpotQuoteContacts = (toast?: boolean) => {
  const clearCache = useClearQueryCache()
  return useToastedMutation(postContact, {
    onSettled() {
      clearCache()
    },
    ...(toast && {
      toastErrorPrefix: () => 'Error creating contacts',
      toastSuccess: () => 'Created contacts',
    }),
  })
}
export const useRemoveSpotQuoteContact = (toast?: boolean) => {
  const clearCache = useClearQueryCache()
  return useToastedMutation(deleteSpotQuoteContact, {
    onSettled() {
      clearCache()
    },
    ...(toast && {
      toastErrorPrefix: () => 'Error removing contacts',
      toastSuccess: () => 'Removed contacts',
    }),
  })
}
export const useUpsertSpotQuoteGroup = (toast?: boolean) => {
  const clearCache = useClearQueryCache()
  return useToastedMutation(upsertGroup, {
    onSettled: () => clearCache(),
    ...(toast && {
      toastSuccess: (_z, _y, vars) =>
        vars?.groupId ? 'Updated group' : 'Created group',
      toastErrorPrefix: (_z, _y, vars) =>
        `Error ${vars?.groupId ? 'updating' : 'creating'} group`,
    }),
  })
}
export const useRemoveSpotQuoteGroup = () => {
  const clearCache = useClearQueryCache()
  return useToastedMutation(removeGroup, {
    onSettled: () => clearCache(),
    toastSuccess: () => 'Removed group',
    toastErrorPrefix: () => 'Error removing group',
  })
}
