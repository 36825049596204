import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import {
  DelimitedContent,
  FvButton,
  FvLinkButton,
  Icon,
  type IconProps,
  Slash,
  TextField,
  ValidatedForm,
} from '@fv/client-components'
import { type FullShipment, type PropsWithLoad } from '@fv/client-types'
import { formatPhone } from '@fv/models/core'

import { HasPermission } from '../../features/auth'
import { useEditConfirmationNumber } from '../../features/confirm/useEditConfirmationNumber'
import { quoteFuncs } from '../../features/quote/quoteFuncs'
import { type SetActiveSlider } from '../../features/shipment-details-panel/types'
import { useEditBookingPage } from '../../hooks/shipments'
import { type CarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import {
  canConfirmShipment,
  getCarrierName,
  isInternational,
  isPuertoRican,
} from '../../utils/shipmentFuncs'
import { List, ListItem } from '../List'
import { CancelationMessage } from '../shared/CancelationMessage'

const linkStyle = { color: '#45abd3' }

const ExportDocuments = ({
  children,
  fw,
  icon,
}: PropsWithChildren<{ fw?: boolean; icon?: IconProps['icon'] }>) => (
  <FvLinkButton
    fw={fw}
    href="https://www.trade.gov/common-export-documents"
    icon={icon}
    rel="noreferrer"
    style={icon ? undefined : linkStyle}
    target="_blank"
  >
    {children || 'export documents'}
  </FvLinkButton>
)

type NotificationProps = PropsWithChildren<{
  icon?: IconProps['icon']
  type?: 'success' | 'warning' | 'dark'
}>

const Notification = ({ children, icon, type }: NotificationProps) => {
  return (
    <ListItem itemsStart className="flex-nowrap">
      {icon && (
        <Icon
          className={clsx('fa-fw flex-none', { [`color-${type}`]: !!type })}
          icon={icon}
          transform="down-2"
        />
      )}
      <p className="mb-0">{children}</p>
    </ListItem>
  )
}

const PhoneLink = ({ phone }: { phone?: string }) => {
  if (!phone) return null
  return <FvLinkButton href={`tel:${phone}`}>{formatPhone(phone)}</FvLinkButton>
}

type NotificationsBannerProps = PropsWithLoad<
  SetActiveSlider & {
    carrierSettings?: CarrierPublicSettings
  }
>

// https://github.com/freightview/freightview/blob/master/src/client/app/templates/shipment-detail-notices.jade
export const NotificationsBanner = ({
  carrierSettings,
  load,
  setActiveSlider,
}: NotificationsBannerProps) => {
  const { bol, isArchived, loadId, pickup, selectedQuote, quoteProcessStatus } =
    load

  const { canEdit, composeEditLink } = useEditBookingPage(load)
  const { hasPuertoRicanStop, isPuertoRicanOrigin } = isPuertoRican(load)
  const carrier = getCarrierName(load)
  const editPickupNum = useEditConfirmationNumber(loadId)
  const hasCarrierBOLNotice = showCarrierBOLNotice(load)
  const hasConfirmation = showConfirmationNotice(load)
  const hasPickupNotice = showPickupNotice(load)
  const hasUpdates = showUpdateNotice(load)
  const international = isInternational(load)
  const noCompat = quoteProcessStatus === 'rerate-no-compat'
  const showCancelBanner =
    load.cancel?.status === 'error' || load.cancel?.status === 'ok'

  const hasNotifications =
    hasCarrierBOLNotice ||
    hasConfirmation ||
    hasPickupNotice ||
    hasPuertoRicanStop ||
    hasUpdates ||
    international ||
    isArchived ||
    pickup?.status === 'error' ||
    showCancelBanner ||
    noCompat

  if (!hasNotifications) return null

  return (
    <List
      fcp0
      lcp0
      className="shipment-details-alert active b1350:px-6 b1350:py-5"
    >
      {isArchived && (
        <Notification icon="archive">
          This shipment has been archived.
        </Notification>
      )}

      {(international || hasPuertoRicanStop) && (
        <>
          {international && (
            <Notification icon="exclamation-triangle" type="warning">
              Since this shipment is crossing international borders, the carrier
              will need copies of your <ExportDocuments /> including a
              commercial invoice, export packing list and statement of origin.
              You also need to contact your customs broker prior to pickup.
            </Notification>
          )}

          {hasPuertoRicanStop && (
            <Notification icon="exclamation-triangle" type="warning">
              Since this shipment is{' '}
              {isPuertoRicanOrigin ? 'coming from' : 'going to'} Puerto Rico,
              the carrier will need copies of your <ExportDocuments /> and
              commercial invoice including description and value of the goods
              being shipped, consignee's name, address and phone number (if
              available to help notify the consignee of arrival of the goods and
              requirement to clear taxes) and tax bond number or tax-exempt
              identification, if available.
            </Notification>
          )}

          {international && !!load.customsBroker && (
            <Notification>
              <div className="flex flex-col gap-2">
                {load.customsBroker?.map((customsBroker, i) => (
                  <div key={i}>
                    <DelimitedContent delimiter={<Slash />}>
                      {customsBroker.phone && (
                        <FvLinkButton
                          fw
                          href={`tel:${customsBroker.phone}`}
                          icon="phone"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {customsBroker.companyName ?? ''}{' '}
                          {formatPhone(customsBroker.phone)}
                        </FvLinkButton>
                      )}
                      {customsBroker?.email && (
                        <FvLinkButton
                          fw
                          href={`mailto:${customsBroker.email}`}
                          icon="envelope"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {customsBroker.email}
                        </FvLinkButton>
                      )}
                    </DelimitedContent>
                  </div>
                ))}
              </div>
            </Notification>
          )}
        </>
      )}

      {hasCarrierBOLNotice && (
        <Notification icon="exclamation-triangle" type="warning">
          <strong>Tip:</strong> Be sure to use the {selectedQuote?.providerName}{' '}
          bill of lading (click the "
          <em>
            <strong>Print carrier BOL</strong>
          </em>
          " button to the left) so you get charged their quoted rate. Call them
          directly if you have billing questions.
        </Notification>
      )}

      {hasPickupNotice && (
        <ListItem itemsStart className="flex-col flex-nowrap">
          <p className="mb-0">
            {bol?.status === 'ok' && 'Your Bill of lading is ready. '}Call{' '}
            <PhoneLink phone={carrierSettings?.dispatchPhone} /> to schedule the
            pickup when the shipment is ready.
          </p>

          {!pickup?.confirmationNumber && (
            <HasPermission name="book">
              <ValidatedForm
                className="input-group mt-2"
                onValidSubmit={form => {
                  if (editPickupNum.isLoading) return
                  const input = form.elements.namedItem(
                    'pickupNum',
                  ) as HTMLInputElement

                  const pickupNum = input?.value.trim()
                  if (!pickupNum) return
                  editPickupNum.mutate(pickupNum)
                }}
              >
                <TextField
                  className="form-control"
                  name="pickupNum"
                  placeholder="Pickup#"
                  readOnly={editPickupNum.isLoading}
                  required
                />
                <div className="input-group__append">
                  <FvButton
                    icon="check"
                    loading={editPickupNum.isLoading}
                    type="submit"
                    theme="default"
                  >
                    Mark as dispatched
                  </FvButton>
                </div>
              </ValidatedForm>
            </HasPermission>
          )}
        </ListItem>
      )}

      {pickup?.status === 'error' && (
        <Notification icon="exclamation-triangle" type="warning">
          Booking failed: {pickup.error ?? 'Unknown error'}.
          {canEdit && ' Please '}
          {canEdit && (
            <FvLinkButton to={composeEditLink('details')} style={linkStyle}>
              update this load
            </FvLinkButton>
          )}
          {canEdit && ' and try booking again.'}
        </Notification>
      )}

      {hasUpdates && (
        <Notification icon="exclamation-triangle" type="warning">
          Your bill of lading has been updated. You need to call{' '}
          <PhoneLink phone={carrierSettings?.dispatchPhone} /> and inform the
          carrier of your changes.
        </Notification>
      )}

      {showCancelBanner && (
        <CancelationBanner
          carrierSettings={carrierSettings}
          load={load}
          setActiveSlider={setActiveSlider}
        />
      )}

      {/* TODO: uncancel notice */}

      {hasConfirmation && (
        <>
          {(pickup?.status === 'pending' ||
            pickup?.status === 'requesting') && (
            <Notification icon="trophy" type="dark">
              This shipment has been awarded but not finalized. Awaiting
              confirmation from {carrier ?? 'the carrier'}.
            </Notification>
          )}

          {pickup?.status === 'ok' &&
            (bol?.status === 'ok' ? (
              <Notification icon="check-circle" type="success">
                Your pickup has been confirmed. Everything is good to go for
                this shipment!
              </Notification>
            ) : (
              <Notification icon="check-circle" type="success">
                Your pickup has been confirmed. Please upload your BOL.
              </Notification>
            ))}
        </>
      )}

      {noCompat && (
        <Notification icon="exclamation-triangle" type="warning">
          We attempted to obtain a new rate from the carrier based on the
          changes made, but the carrier was unable to return a new rate. The
          rate shown may no longer be accurate.
        </Notification>
      )}
    </List>
  )
}

function showCarrierBOLNotice(load: FullShipment) {
  if (load.status === 'canceled') return false

  const { selectedQuote } = load
  const carrier = selectedQuote?.assetCarrierName
  const provider = selectedQuote?.providerName

  return !!(carrier && provider && carrier !== provider)
}

function showConfirmationNotice(load: FullShipment) {
  return canConfirmShipment(load)
}

function showPickupNotice(load: FullShipment) {
  const { pickup, status } = load
  if (
    load.workflow === 'parcel' ||
    status === 'canceled' ||
    pickup?.status === 'ok'
  )
    return false
  return pickup?.status === 'not-requested' || pickup?.method === 'manual'
}

// TODO: Legacy logic for LTL/Parcel
function showUpdateNotice(load: FullShipment) {
  const { bol, pickup, status } = load

  return (
    status !== 'canceled' &&
    pickup?.method === 'api' &&
    bol?.changedAfterDispatch
  )
}

function CancelationBanner({
  load,
  carrierSettings,
  setActiveSlider,
}: NotificationsBannerProps) {
  const { cancel, selectedQuote } = load
  const carrier =
    selectedQuote?.providerName ??
    selectedQuote?.assetCarrierName ??
    'the carrier'

  const isSuccessfulCancel =
    !(cancel?.status === 'error') ||
    !load.schedulePickup ||
    quoteFuncs.isSpotWorkflow(selectedQuote) ||
    cancel?.mode === 'email'
  const notificationType = isSuccessfulCancel ? 'success' : 'warning'

  if (cancel?.status === 'error') {
    const error = cancel.errorMessage ?? 'An unknown error occurred.'

    return (
      <Notification icon="exclamation-triangle" type={notificationType}>
        Unable to auto-cancel the shipment with {carrier}
        <br /> Error: {error}
        <br /> {carrier} is still planning to pick it up.
        <br />
        You will need to call the carrier {carrierSettings?.cancelPhone} to
        cancel this pickup request.
        <br />
        <FvButton theme="callout" onClick={() => setActiveSlider('cancel')}>
          Click here
        </FvButton>{' '}
        to mark this shipment as canceled in Freightview.
      </Notification>
    )
  }

  return (
    <Notification type={notificationType}>
      <CancelationMessage carrierSettings={carrierSettings} load={load} />
    </Notification>
  )
}
