import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import toast from 'react-hot-toast'

import { type ListShipment } from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { useRemoveFromList, useUpdateCachedLoad } from '.'

async function retractTender(loadId: string): Promise<ListShipment> {
  return await shipperFetch<ListShipment>(`/shipments/${loadId}/retract`, {
    method: 'POST',
  })
}

export function useRetractTender() {
  const removeFromList = useRemoveFromList()
  const updateCachedLoad = useUpdateCachedLoad()

  const canRetract = useCallback((load: ListShipment) => {
    return (
      load.selectedQuote?.isSpot &&
      load.pickup?.method !== 'api' &&
      (load.status === 'confirmed' || load.status === 'awarded')
    )
  }, [])

  const ret = useMutation(retractTender, {
    onSuccess: updatedLoad => {
      removeFromList({
        loadId: updatedLoad.loadId,
        queryFilter: ['shipments', 'confirmed'],
      })

      updateCachedLoad({ updatedLoad })
      toast.success('Tender retracted successfully.')
    },
  })

  return {
    ...ret,
    canRetract,
    confirm: () =>
      window.confirm(
        'Are you sure you want to retract your tender to this carrier?',
      ),
  }
}
