import { type ChangeEvent } from 'react'

import { SelectField } from '@fv/client-components'

import { type FilterComponentProps } from './types'

export const SelectFilter = ({
  name,
  options,
  setQueryString,
  value,
}: FilterComponentProps) => {
  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    setQueryString(qs => {
      const value = e.target.value
      const params = new URLSearchParams(qs)
      params.set(name, value)
      return params.toString()
    })
  }

  return (
    <div className="filter-type__options">
      <SelectField
        className="form-control form-control--select"
        name={name}
        onChange={onChange}
        options={options}
        value={value}
      />
    </div>
  )
}
