import { type PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  title?: string
  className?: string
}>
export const SettingsPageLayout = ({ title, children, className }: Props) => {
  return (
    <div className="settings-main flex flex-col pb-0">
      {title && (
        <h3
          className={`${className} border-b-fv-gray-300 mb-4 border-b text-lg`}
        >
          {title}
        </h3>
      )}
      {children}
    </div>
  )
}
