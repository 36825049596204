import { useEffect } from 'react'

import {
  useMarkMessagesRead,
  useMessages,
  useUnreadMessageIds,
} from '../../hooks/messages'
import InfoBox from '../InfoBox'
import Message from './Message'

type Props = {
  carrierId?: string
  isApiMessages?: boolean
  loadId?: string
  userId?: string
}

const MessageList = ({ carrierId, isApiMessages, loadId, userId }: Props) => {
  const markRead = useMarkMessagesRead(loadId)
  const messagesQuery = useMessages({ carrierId, loadId, userId })
  const unreadMessageIds = useUnreadMessageIds({ carrierId, loadId, userId })

  useEffect(() => {
    if (markRead.isLoading || markRead.isError) return

    if (unreadMessageIds.length > 0) {
      markRead.mutate(unreadMessageIds)
    }
  }, [markRead, unreadMessageIds])

  if (messagesQuery.isLoading && messagesQuery.isFetching) {
    return <InfoBox icon="spinner">Loading...</InfoBox>
  }

  return (
    <>
      {messagesQuery.data?.map(m => (
        <Message
          carrierId={carrierId}
          date={m.createdDate}
          displayName={m.author?.email ?? 'System'}
          isApiMessage={isApiMessages}
          isFromShipper={m.author?.role === 'shipper'}
          key={m._id}
          messageText={m.messageText}
          participants={m.participants}
        />
      ))}

      {!messagesQuery.data?.length && (
        <InfoBox>No messages to display.</InfoBox>
      )}
    </>
  )
}

export default MessageList
