export const download = (data: Blob, fileName: string) => {
  const tempLink = document.createElement('a')

  tempLink.download = fileName
  tempLink.href = window.URL.createObjectURL(data)
  tempLink.style.display = 'none'

  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
}
