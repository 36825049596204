import toast from 'react-hot-toast'

export function copyToClipboard(
  value?: string,
  successMessage = 'Copied to clipboard',
) {
  try {
    value && navigator.clipboard.writeText(value)
    toast.success(successMessage, {
      id: 'clipboard',
    })
  } catch (e) {
    toast.error('Unable to copy to clipboard', {
      id: 'clipboard',
    })
  }
}
