import dayjs from 'dayjs'
import { useState } from 'react'

import { isFedex } from '@fv/client-core'
import { type DeliveryOption, type DeliveryOptionType } from '@fv/client-types'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { type RadioFieldOption } from '../../components/inputs/RadioField'
import { useUserSettings } from '../../hooks/settings'
import { DayPickerPanel } from '../dates/DayPickerPanel'
import { quoteFuncs } from '../quote/quoteFuncs'
import { useQuotePageCtx } from '../quote/QuotePageProvider'
import { useBookingFormCtx } from './BookingFormProvider'

const radioOptions: Array<RadioFieldOption<DeliveryOptionType | ''>> = [
  { value: '', label: 'None' },
  { value: 'evening', label: 'FedEx Evening Home Delivery®' },
  { value: 'date-certain', label: 'FedEx Date Certain Home Delivery®' },
  { value: 'appointment', label: 'FedEx Appointment Home Delivery®' },
]

type DatePickerProps = {
  onClose: () => void
  value: Date
  onSelect: (d: Date) => void
}
const DatePickerPanel = ({ onClose, value, onSelect }: DatePickerProps) => {
  const disabledDays = (day: Date) => {
    return dayjs().startOf('day').isAfter(day)
  }
  return (
    <DayPickerPanel
      disabled={disabledDays}
      open={true}
      onClose={onClose}
      onSelect={onSelect}
      selected={value}
      mode="single"
    />
  )
}

type FormProps = {
  value: DeliveryOption | undefined
  onChange: (value?: DeliveryOption) => void
}
const FedExHomeDeliveryForm = ({ value, onChange }: FormProps) => {
  const [showDatepicker, setShowDatepicker] = useState(false)
  return (
    <>
      <InputGroup
        label="FedEx Home Delivery® special services"
        className="mb-0"
        inputType="radio"
        inputProps={{
          onChange: e =>
            onChange(
              e.target.value
                ? {
                    option: e.target.value as DeliveryOptionType,
                  }
                : undefined,
            ),
          value: value?.option ?? '',
          options: radioOptions,
          name: 'fedex-homedelivery',
        }}
      />
      {value?.option === 'date-certain' && (
        <InputGroup
          label="Date"
          className="mb-0 mt-4 w-56"
          startContent={<InputAdornment icon="calendar-day" position="start" />}
          inputType="text"
          inputProps={{
            name: 'fedex-homedelivery-date',
            onFocus: () => setShowDatepicker(true),
            value: value?.date ? dayjs(value?.date).format('MM/DD/YYYY') : '',
          }}
        />
      )}

      {showDatepicker && (
        <DatePickerPanel
          value={value?.date ?? new Date()}
          onClose={() => setShowDatepicker(false)}
          onSelect={date => {
            onChange({
              option: 'date-certain',
              date,
            })
            setShowDatepicker(false)
          }}
        />
      )}
    </>
  )
}
export const FedExHomeDeliveryBooking = () => {
  const quote = useBookingFormCtx(s => s.quote)
  const [, destination] = useBookingFormCtx(s => s.locations)
  const actions = useBookingFormCtx(s => s.actions)
  const deliveryOption = useBookingFormCtx(s => s.deliveryOption)

  const isApplicable =
    quoteFuncs.isFedex(quote) && destination.shipType === 'residential'

  if (!isApplicable) return null
  return (
    <div className="border-fv-gray -mt-8 mb-8 border bg-fv-gray-100 p-4">
      <FedExHomeDeliveryForm
        value={deliveryOption}
        onChange={actions.updateDeliveryOption}
      />
    </div>
  )
}

export const FedExHomeDeliveryQuoting = () => {
  const [, destination] = useQuotePageCtx(s => s.locations)
  const actions = useQuotePageCtx(s => s.actions)
  const deliveryOption = useQuotePageCtx(s => s.deliveryOption)
  const { data: userSettings } = useUserSettings()
  const isApplicable =
    userSettings?.carriers.some(c => isFedex(c.code)) &&
    destination.shipType === 'residential'

  if (!isApplicable) return null
  return (
    <div className="border-fv-gray mt-4 border bg-fv-gray-100 p-4">
      <FedExHomeDeliveryForm
        value={deliveryOption}
        onChange={actions.updateDeliveryOption}
      />
    </div>
  )
}
