import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import {
  FvButton,
  type FvButtonProps,
  FvLinkButton,
  type FvLinkButtonProps,
  isFvLinkButtonProps,
  Tooltip,
} from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

export const ActionList = ({
  children,
  className,
}: PropsWithChildren<PropsWithClassName>) => {
  return (
    <div
      className={clsx(
        className,
        'divide-fv-gray-300 flex items-center divide-x [&>div:first-child]:pl-0 [&>div:last-child]:pr-0',
      )}
    >
      {children}
    </div>
  )
}

type Props = (FvButtonProps | FvLinkButtonProps) & {
  tooltip?: string
}
export const ActionListItem = (props: Props) => {
  return isFvLinkButtonProps(props) ? (
    <div>
      <Tooltip label={props.tooltip}>
        <FvLinkButton
          {...props}
          className={`h-auto px-3 py-1 ${props.className}`}
        />
      </Tooltip>
    </div>
  ) : (
    <div>
      <Tooltip label={props.tooltip}>
        <FvButton
          {...props}
          className={`h-auto px-3 py-1 ${props.className}`}
        />
      </Tooltip>
    </div>
  )
}
