import { PropsWithChildren } from 'react'

import chrome from '@fv/client-assets/chrome.png'
import edge from '@fv/client-assets/edge.png'
import firefox from '@fv/client-assets/firefox.png'
import freightview from '@fv/client-assets/freightview.svg'
import safari from '@fv/client-assets/safari.png'

export const BrowserCheck = ({ children }: PropsWithChildren) => {
  let errorMessage = null

  const hasRequiredFeatures =
    !!Array.prototype.flatMap &&
    !!window.IntersectionObserver &&
    !!window.ResizeObserver

  if (
    navigator.userAgent.indexOf('MSIE ') > 0 ||
    !!navigator.userAgent.match(/Trident.*rv:11\./)
  ) {
    errorMessage = `
      Freightview does not support Internet Explorer.
      This browser is not secure and is no longer supported by Microsoft.
      To continue to use Freightview, we recommend a modern browser of your choosing below.
      Thanks.
    `
  } else if (!hasRequiredFeatures) {
    errorMessage = `
      Your browser is out of date.
      Please upgrade to the latest version to continue using Freightview.
      Thanks.
    `
  }

  if (!errorMessage) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <div className="h-screen w-screen overflow-auto bg-gray-300 text-base text-gray-700">
      <main className="[font-family: 'Roboto'] flex items-center justify-center p-16">
        <div className="flex-shrink flex-grow-0 basis-[500px]">
          <header className="flex justify-center">
            <img
              className="whitespace-nowrap"
              src={freightview}
              alt="Freightview"
            />
          </header>
          <p className="mt-8 text-center">{errorMessage}</p>
          <hr className="mx-0 my-8 h-[1px] w-full border-0 bg-gray-100" />
          <div className="flex justify-between">
            <a
              className="text-center"
              rel="noreferrer"
              href="https://www.google.com/chrome/"
              target="_blank"
            >
              <img alt="Chrome" src={chrome} />
              <div>Chrome</div>
            </a>
            <a
              className="text-center"
              rel="noreferrer"
              href="https://www.mozilla.org/en-US/firefox/new/"
              target="_blank"
            >
              <img alt="Firefox" src={firefox} />
              <div>Firefox</div>
            </a>
            <a
              className="text-center"
              rel="noreferrer"
              href="https://www.apple.com/safari/"
              target="_blank"
            >
              <img alt="Safari" src={safari} />
              <div>Safari</div>
            </a>
            <a
              className="text-center"
              rel="noreferrer"
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
            >
              <img alt="Edge" src={edge} />
              <div>Edge</div>
            </a>
          </div>
        </div>
      </main>
    </div>
  )
}
