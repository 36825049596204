import clsx from 'clsx'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Icon, OlarkHelper, useFvNavigate } from '@fv/client-components'
import { type FullShipment, type UIQuote } from '@fv/client-types'

import { LoadSummary } from '../../components/LoadSummary/LoadSummary'
import TopNav from '../../components/TopNav'
import { routes } from '../../constants'
import { useLoad, useQuotes } from '../../hooks/shipments'
import { isBookedAndDispatched } from '../../utils/shipmentFuncs'
import { RatesListing } from './RatesListing'
import { type RatesListingMode } from './useRatesListing'

type Props = {
  hasError: boolean
  load?: FullShipment
  rates?: UIQuote[]
  isLoading: boolean
  mode?: RatesListingMode
}

const RatesContainer = ({ hasError, load, rates, isLoading, mode }: Props) => {
  const navigate = useFvNavigate()

  useEffect(() => {
    if (!load || isLoading) {
      return
    }

    if (isBookedAndDispatched(load) && mode !== 'change') {
      navigate(routes.rates(load, 'view'), { replace: true })
      return
    }
    if (load.status === 'canceled' && mode !== 'view') {
      navigate(routes.quote(load.workflow), { replace: true })
      return
    }
  }, [load, isLoading, mode, navigate])

  if (isLoading || !load) {
    return (
      <div className="bg-slate-50 p-6">
        <Icon
          className={clsx({ 'color-secondary': hasError })}
          icon={hasError ? 'exclamation-triangle' : 'sync'}
        />
        <span className="ml-2">
          {hasError ? 'Shipment not found.' : 'Loading...'}
        </span>
      </div>
    )
  }

  if (load.workflow !== 'truckload') {
    return <RatesListing load={load} rates={rates} />
  } else {
    return (
      <div className="bg-slate-50 p-6">
        <span>Truckload rates page placeholder.</span>
      </div>
    )
  }
}

export const RatesPage = () => {
  const { loadId, mode } = useParams<{
    loadId: string
    mode?: RatesListingMode
  }>()
  const loadQuery = useLoad(loadId, { refetchWhileQuoting: true })
  const quotesQuery = useQuotes(loadId, {
    enabled: true,
    interval: (data, query) => {
      // If no rates, keep polling for a minute
      if (
        loadQuery.data?.quoteProcessStatus === 'requesting' ||
        loadQuery.data?.quoteProcessStatus === 'rerating' ||
        (!data?.length && query.state.dataUpdateCount <= 4)
      ) {
        return 15000
      }

      return false
    },
  })
  return (
    <div className="grid-ltl-rates grid-rows-[auto_1fr] grid-cols-[minmax(20rem,.3fr)_1fr] bg-white grid h-screen">
      <TopNav />
      <LoadSummary load={loadQuery.data} />

      <RatesContainer
        hasError={loadQuery.isError}
        isLoading={loadQuery.isLoading || quotesQuery.isLoading}
        load={loadQuery.data}
        rates={quotesQuery.data}
        mode={mode}
      />
      <OlarkHelper loadId={loadQuery.data?.loadId} />
    </div>
  )
}
