import { type AccountSettingsFormItem } from './types'

export const quotingSettings: AccountSettingsFormItem[] = [
  {
    label: 'Hide brokered rates with carriers you have contracted pricing with',
    setting: 'quoting.ltl.hideBrokerForDirectRates',
  },
  {
    setting: 'quoting.showPricingIndicator',
    label: 'Show lowest bid indicators to carriers competing for spot quotes',
  },
  {
    setting: 'quoting.showBidExpiration',
    label:
      'Allow users to set an expiration date for carriers bidding on spot quotes',
  },
]

export const bookingSettings: AccountSettingsFormItem[] = [
  {
    label: 'Remember special instructions for origin',
    setting: 'booking.rememberSpecialInstructionsOrigin',
  },
  {
    label: 'Remember special instructions for destination',
    setting: 'booking.rememberSpecialInstructionsDest',
  },
  {
    label: 'Require a user to enter a shipper reference number',
    setting: 'booking.shipperNumberRequired',
  },
  {
    label: 'Require a user to enter a purchase order number',
    setting: 'booking.poNumberRequired',
  },
  {
    label: 'User must give a reason for not choosing lowest cost carrier',
    setting: 'booking.requireReasonForQuoteChoice',
  },
  {
    label: 'Allow a user to edit bill-to information',
    setting: 'booking.showBillToOnBooking',
  },
]

export const auditSettings: AccountSettingsFormItem[] = [
  {
    label: 'Archiving an invoice automatically archives the shipment',
    setting: 'invoicing.autoArchiveShipment',
  },
  {
    label:
      'When disputing an invoice, automatically send an email to my carrier representative to start the resolution process',
    setting: 'invoicing.emailDispute',
  },
]

export const productCatalogSettings: AccountSettingsFormItem[] = [
  {
    label: 'Allow users to quote products that are not in the catalog',
    setting: 'productCatalog.canQuoteCustomProduct',
  },
  {
    label:
      'Have Freightview automatically create products in my catalog after booking them',
    setting: 'productCatalog.autoItemCreate',
  },
]

export const notificationSettings: AccountSettingsFormItem[] = [
  {
    label:
      'Show missed pickup notifications to all company users regardless of who booked it',
    setting: 'notifications.shareMissedPickup',
  },
]

export const addressBookSettings: AccountSettingsFormItem[] = [
  {
    label: 'Do not save new addresses to address book',
    setting: 'addressBook.disableAutoSaveAddresses',
  },
]

export const tagSettings: AccountSettingsFormItem[] = [
  {
    label: 'Prevent users from creating new tags',
    setting: 'tags.preventUsersCreateTags',
  },
]
