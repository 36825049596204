import { type ReactNode, useEffect } from 'react'
import toast from 'react-hot-toast'
import { Navigate, useSearchParams } from 'react-router-dom'

import { DelimitedContent, FvButton, FvLinkButton } from '@fv/client-components'
import { useDownloadFile } from '@fv/client-core'
import {
  type ProvisionerLocationDto,
  type ProvisioningFilterStatus,
} from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { InputGroup } from '../../components/inputs/InputGroup'
import { Loading } from '../../components/Loading'
import { shipperFetchRaw } from '../../utils/shipperFetch'
import { ProvisionerCredentials } from './components/ProvisionerCredentials'
import {
  ProvisionerContents,
  ProvisionerLayout,
} from './components/ProvisionerLayout'
import { ProvisionerLocation } from './components/ProvisionerLocation'
import {
  ProvisionerSection,
  ProvisionerSectionHeader,
} from './components/Section'
import { useProvisionerAccountId } from './hooks'
import {
  useProvisionerAccountDetails,
  useProvisionerCarrierConfig,
} from './queries'

export const ProvisionerAccountPage = () => {
  const [search] = useSearchParams()
  const accountId = useProvisionerAccountId()
  const { isLoading: carrierLoading } = useProvisionerCarrierConfig()
  const { data: details, isLoading } = useProvisionerAccountDetails(accountId)
  const {
    fetch: downloadLoa,
    isDownloading,
    error,
  } = useDownloadFile({
    fetch: () => shipperFetchRaw(`/provisioner/account/${accountId}/loa`),
    fileName: `${details?.companyName}-freightview-auth.pdf`,
  })
  const lastTab: ProvisioningFilterStatus =
    search.get('tab') === 'active' ? 'active' : 'requested'

  useEffect(() => {
    error && toast.error(error)
  }, [error])

  if (isLoading || carrierLoading) {
    return <Loading />
  }

  if (!accountId || !details) {
    return <Navigate to="/provisioning/requested" />
  }

  return (
    <ProvisionerLayout>
      <ProvisionerContents>
        <div className="text-lg">
          <DelimitedContent delimiter={<>&nbsp;/&nbsp;</>}>
            <FvLinkButton
              theme="callout"
              to={`/provisioning/${lastTab}`}
              icon="arrow-left"
            >
              Back
            </FvLinkButton>
            <span>{details.companyName}</span>
          </DelimitedContent>
        </div>

        <ProvisionerSection>
          <div className="flex justify-between">
            <div className="leading-7">
              <h3 className="text-xl">{details.companyName}</h3>
              <div>{details.contact}</div>
              <div>{details.contactEmail}</div>
            </div>
            <div>
              <FvButton
                loading={isDownloading}
                theme="underlined"
                icon="print"
                onClick={downloadLoa}
              >
                View printable authorization
              </FvButton>
            </div>
          </div>
        </ProvisionerSection>

        <ProvisionerSectionHeader>Contacts</ProvisionerSectionHeader>
        <ProvisionerSection>
          <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
            <InputGroup
              inputType="text"
              label="Primary contact's first name"
              inputProps={{
                value: details.salesRepFirstName,
                disabled: true,
                name: 'firstName',
              }}
            />
            <InputGroup
              inputType="text"
              label="Primary contact's last name"
              inputProps={{
                value: details.salesRepLastName,
                disabled: true,
                name: 'lastName',
              }}
            />
          </div>
          <InputGroup
            className="w-full md:w-1/2"
            inputType="text"
            label="Primary contact's phone number"
            inputProps={{
              value: details.salesRepPhone,
              disabled: true,
              name: 'phone',
            }}
          />
          <InputGroup
            className="w-full"
            inputType="text"
            label="Email contacts for this carrier"
            inputProps={{
              value: details.salesReps?.map(e => e.email).join(),
              disabled: true,
              name: 'emails',
            }}
          />
        </ProvisionerSection>

        <ProvisionerCredentials />

        <LocationGroup
          title="These locations need to be provisioned before the shipper can see your rates:"
          note={
            <>
              <p>
                If an address has both a shipping account and a billing-only
                account, enter the account number for the <i>shipping</i>{' '}
                account.
              </p>
              <p>
                If the shipping account is under a different company name,
                please chat with Support or{' '}
                <FvLinkButton
                  href="mailto:support@freightview.com"
                  theme="underlined"
                >
                  email us
                </FvLinkButton>
                .
              </p>
            </>
          }
          locs={details.locations.filter(
            l => l.status === 'testing' || l.status === 'new',
          )}
        />

        <LocationGroup
          title="The following locations have been provisioned and will return rates:"
          locs={details.locations.filter(l => l.status === 'active')}
        />

        <LocationGroup
          title="The shipper does not have rates with you for the following locations:"
          locs={details.locations.filter(l => l.status === 'none')}
        />
      </ProvisionerContents>
    </ProvisionerLayout>
  )
}

const LocationGroup = ({
  title,
  locs,
  note,
}: {
  title: string
  locs: ProvisionerLocationDto[]
  note?: ReactNode
}) => {
  if (!locs.length) return null

  return (
    <>
      <ProvisionerSectionHeader>{title}</ProvisionerSectionHeader>
      {!!note && <InfoBox>{note}</InfoBox>}
      {locs.map(loc => (
        <ProvisionerLocation key={loc.locationId} loc={loc} />
      ))}
    </>
  )
}
