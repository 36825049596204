import {
  type ChangeEvent,
  type Dispatch,
  type MouseEventHandler,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

import { FvButton, Icon, TextAreaField } from '@fv/client-components'
import { emailRegExpMatcher } from '@fv/client-core'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import RadioButtonForm from '../../components/inputs/RadioButtonForm'
import { AuditPriceDisplay } from './components/AuditPriceDisplay'
import { AuditRateBreakdown } from './components/AuditRateBreakdown'
import { InlineInvoiceDisplay } from './components/InvoiceInlineDisplay'
import { AuditCardArrowCtn, AuditCardCtn } from './AuditCardArrowCtn'
import { DatePickerInput } from './DatePickerInput'
import {
  useAddPayment,
  useAuditSetting,
  useCarrierEmails,
  useDisputeInvoice,
} from './queries'
import { type Audit, colorSuccess, type LoadDocumentDTO } from './types'
import { getInvoice } from './utils'

const listStatus = 'outstanding'

type OutstandingAuditFormProps = {
  audit: Audit
  isActive: boolean
  setActive: Dispatch<SetStateAction<string | null>>
}

type OutstandingAuditFormState = {
  acceptedAmount: string
  carrierEmails: string
  disputeMessage: string
  invoicedAmount: string
  invoiceNumber: string
  isShowingInvoice: boolean
  isShowingRadios: boolean
  notes: string
  radioValue: 'accept' | 'dispute' | null
  invoiceDate: string
}

const InvoiceLink = function invoiceLink({
  invoice,
  hasApiData,
  onClick,
}: {
  invoice?: LoadDocumentDTO
  hasApiData?: boolean
  onClick: MouseEventHandler
}) {
  if (!invoice && !hasApiData) {
    return (
      <>
        <span className="b1400:hidden">Does it match your invoice?</span>
        <span className="b1400:block hidden">Match invoice?</span>
      </>
    )
  }

  return (
    <div className="flex items-center">
      <span className="b1400:hidden">Does it match your</span>
      <span className="b1400:block hidden">Match</span>
      <FvButton
        icon="file-invoice-dollar"
        transform="down-1"
        onClick={onClick}
        theme="underlined"
        className="ml-2"
      >
        invoice
      </FvButton>
      ?
    </div>
  )
}

const OutstandingAuditForm = ({
  audit,
  isActive,
  setActive,
}: OutstandingAuditFormProps) => {
  const { invoiceNumber, invoiceDate, quote, loadId } = audit
  const { addPayment, isAddingPayment } = useAddPayment(audit, listStatus)
  const { disputeInvoice, isDisputing } = useDisputeInvoice(audit, listStatus)
  const isBusy = isAddingPayment || isDisputing
  const emailRef = useRef<HTMLInputElement | null>(null)
  const hasEmailField = useAuditSetting('emailDispute')
  const carrierEmails = useCarrierEmails(quote.carrierId)

  const [formState, setFormState] = useState<OutstandingAuditFormState>({
    acceptedAmount: '',
    carrierEmails: '',
    disputeMessage: '',
    invoicedAmount: '',
    invoiceNumber: invoiceNumber ?? '',
    isShowingInvoice: false,
    isShowingRadios: false,
    notes: '',
    radioValue: null,
    invoiceDate: invoiceDate ?? '',
  })

  useEffect(() => {
    if (carrierEmails.length) {
      setFormState(prev => ({
        ...prev,
        carrierEmails: carrierEmails.join(', '),
      }))
    }
  }, [carrierEmails])

  useEffect(() => {
    if (isActive) return

    setFormState(prev => ({
      ...prev,
      isShowingInvoice: false,
      isShowingRadios: false,
    }))
  }, [isActive])

  const handleInvoiceDate = (date: string) => {
    setFormState(prev => ({
      ...prev,
      invoiceDate: date,
    }))
  }

  const invoice = getInvoice(audit)

  return (
    <>
      <div className="flex items-center gap-3">
        <AuditPriceDisplay amount={quote.amount} />
        <div className="flex items-center gap-2">
          <InputGroup
            inputType="text"
            className="mb-0"
            inputProps={{
              name: 'invoiceNumber',
              placeholder: 'Invoice number',
              onChange: e => {
                setFormState(prev => ({
                  ...prev,
                  invoiceNumber: e.target.value,
                }))
              },
              value: formState.invoiceNumber,
              label: '',
              disabled: isBusy,
            }}
            startContent={<InputAdornment position="start" icon="hashtag" />}
          />
          <DatePickerInput
            className="mb-0 w-52"
            isBusy={isBusy}
            invoiceDate={formState.invoiceDate}
            setInvoiceDate={handleInvoiceDate}
          />
        </div>
        <InvoiceLink
          invoice={invoice}
          hasApiData={audit.source === 'api'}
          onClick={e => {
            e.preventDefault()
            setActive(loadId)
            setFormState(prev => ({
              ...prev,
              isShowingInvoice: !prev.isShowingInvoice,
            }))
          }}
        />
        <Icon icon="arrow-right" className="color-dark" transform="up-.75" />
        <div className="flex items-center">
          <FvButton
            theme="default"
            disabled={
              audit.source === 'api' &&
              audit.invoiceAmount &&
              audit.invoiceAmount !== quote.amount
            }
            onClick={() => {
              !isBusy &&
                addPayment({
                  amount: audit.invoiceAmount || quote.amount,
                  invoiceNumber: formState.invoiceNumber,
                  invoiceDate: formState.invoiceDate,
                  loadId,
                })
            }}
            icon="check"
            iconClass="text-fv-green-100"
            loading={isAddingPayment}
          >
            <span>Yes</span>
          </FvButton>
          <FvButton
            theme="default"
            onClick={() => {
              if (isBusy) return

              setActive(loadId)
              setFormState(prev => ({
                ...prev,
                isShowingRadios: !prev.isShowingRadios,
              }))
            }}
            icon="times"
            iconClass="text-fv-orange"
          >
            <span>No</span>
          </FvButton>
        </div>
      </div>
      {formState.isShowingRadios && (
        <AuditCardArrowCtn className="flex-col">
          <RadioButtonForm
            checked={formState.radioValue === 'dispute'}
            id={`dispute_${quote._id}`}
            label="I would like to challenge this and mark as disputed."
            onChange={() => {
              !isBusy &&
                setFormState(prev => ({
                  ...prev,
                  radioValue: 'dispute',
                }))
            }}
            onValidSubmit={() => {
              !isBusy &&
                disputeInvoice({
                  amount: formState.invoicedAmount,
                  emails: hasEmailField
                    ? (formState.carrierEmails.match(
                        emailRegExpMatcher,
                      ) as string[])
                    : [],
                  invoiceNumber: formState.invoiceNumber,
                  invoiceDate: formState.invoiceDate,
                  loadId,
                  message: formState.disputeMessage,
                })
            }}
          >
            <div className="form-group mb-2 w-full">
              <TextAreaField
                className="form-control w-full"
                name="disputeMessage"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  const { name, value } = e.target

                  setFormState(prev => ({
                    ...prev,
                    [name]: value,
                  }))
                }}
                placeholder="Enter reasons for the dispute here"
                readOnly={isBusy}
                value={formState.disputeMessage}
              />
            </div>

            {hasEmailField && (
              <InputGroup
                className="form-group--email mb-0"
                inputType="text"
                required={true}
                inputProps={{
                  name: 'carrierEmails',
                  placeholder: 'Carrier rep email(s)',
                  onChange: e => {
                    const carrierEmails = e.target.value
                    const isValid = !!carrierEmails.match(emailRegExpMatcher)
                    let error = isValid ? '' : 'Please enter a valid email.'

                    if (!carrierEmails.trim()) {
                      error = 'Carrier rep email is required.'
                    }

                    emailRef.current?.setCustomValidity(error)

                    setFormState(prev => ({
                      ...prev,
                      carrierEmails,
                    }))
                  },
                  value: formState.carrierEmails,
                  disabled: isBusy,
                  ref: emailRef,
                }}
                startContent={
                  <InputAdornment
                    position="start"
                    icon="envelope"
                    iconClass="fa-fw color-dark"
                  />
                }
              />
            )}

            <InputGroup
              className="form-group--price-w-placeholder mb-0"
              inputType="amount"
              required={true}
              inputProps={{
                name: 'invoicedAmount',
                onChange: e => {
                  setFormState(prev => ({
                    ...prev,
                    invoicedAmount: e.target.value,
                  }))
                },
                placeholder: 'Invoiced amount',
                disabled: isBusy,
                value: formState.invoicedAmount,
                min: 1,
                isPrice: true,
              }}
              startContent={
                <InputAdornment
                  position="start"
                  icon="dollar-sign"
                  iconClass="fa-fw color-dark"
                />
              }
            />
            <div className="form-group mb-0">
              <FvButton
                icon="archive"
                loading={isDisputing}
                iconClass="color-warning"
                type="submit"
                theme="default"
              >
                <span>
                  {hasEmailField
                    ? 'Email dispute and save to queue'
                    : 'Save to queue'}
                </span>
              </FvButton>
            </div>
          </RadioButtonForm>

          <RadioButtonForm
            checked={formState.radioValue === 'accept'}
            id={`accept_${quote._id}`}
            label="I would like to enter a different amount and mark as accepted."
            onChange={() => {
              !isBusy &&
                setFormState(prev => ({
                  ...prev,
                  radioValue: 'accept',
                }))
            }}
            onValidSubmit={() => {
              !isBusy &&
                addPayment({
                  amount: formState.acceptedAmount,
                  invoiceAmount: formState.acceptedAmount,
                  invoiceNumber: formState.invoiceNumber,
                  invoiceDate: formState.invoiceDate,
                  loadId,
                  message: formState.notes,
                })
            }}
          >
            <div className="form-group mb-2 w-full">
              <TextAreaField
                className="form-control w-full"
                name="notes"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  const { name, value } = e.target

                  setFormState(prev => ({
                    ...prev,
                    [name]: value,
                  }))
                }}
                placeholder="Enter notes here"
                readOnly={isBusy}
                value={formState.notes}
              />
            </div>
            <InputGroup
              className="form-group--price-w-placeholder mb-0"
              inputType="amount"
              required={true}
              inputProps={{
                name: 'acceptedAmount',
                onChange: e => {
                  setFormState(prev => ({
                    ...prev,
                    acceptedAmount: e.target.value,
                  }))
                },
                placeholder: 'Accepted amount',
                disabled: isBusy,
                value: formState.acceptedAmount,
                min: 0,
                isPrice: true,
              }}
              startContent={
                <InputAdornment position="start" icon="dollar-sign" />
              }
            />
            <div className="form-group mb-0">
              <FvButton
                icon="archive"
                loading={isAddingPayment}
                iconClass={colorSuccess}
                type="submit"
                theme="default"
              >
                <span>Save to accepted</span>
              </FvButton>
            </div>
          </RadioButtonForm>
        </AuditCardArrowCtn>
      )}
      {(formState.isShowingRadios ||
        (formState.isShowingInvoice && audit.source === 'api')) && (
        <AuditCardCtn>
          <AuditRateBreakdown audit={audit} />
        </AuditCardCtn>
      )}
      {formState.isShowingInvoice && audit.source !== 'api' && (
        <InlineInvoiceDisplay loadId={audit.loadId} document={invoice} />
      )}
    </>
  )
}
export default OutstandingAuditForm
