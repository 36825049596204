import { type IconName } from '@fv/client-components'
import { parseQueryString, stringifyQueryString } from '@fv/client-core'
import { type ShipmentFilter } from '@fv/client-types'
import { type ScheduledReportDTO } from '@fv/models'

export type FilterBase = {
  _id?: string
  name?: string
  queryString: string
  filter?: ShipmentFilter
  icon?: IconName
  isDefault?: boolean
  isLocked?: boolean
  isSystemFilter?: boolean
  fullLabel?: string
  isDisabled?: boolean
}
export type SavedFilter = FilterBase & {
  _id: string
  name: string
  scheduledReports?: ScheduledReportDTO[]
}

export type SavedFilterGroup = SavedFilter & {
  children: SavedFilter[]
}

export const isSavedFilterGroup = (
  v: SavedFilter | SavedFilterGroup,
): v is SavedFilterGroup => !!(v as SavedFilterGroup).children

export const getFilterQueryString = (filter: FilterBase) => {
  return filter.queryString
    ? filter.queryString
    : stringifyQueryString({
        ...(filter.filter ?? {}),
        view: filter._id,
      })
}

export const getFilterQueryData = (filter: FilterBase) => {
  const params = filter.queryString
    ? parseQueryString(filter.queryString)
    : parseQueryString(stringifyQueryString(filter.filter ?? {}))
  return {
    ...params,
    view: filter._id,
  }
}
