import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  type InfiniteQueryData,
  setDataByPage,
  showApiError,
} from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'

import { apiUri } from '../../constants'
import { addressMapper } from './addressFuncs'
import { type FormAddress } from './types'
import { addressBookKeys } from './useAddressBookAddresses'
import { addressSearchKeys } from './useAddressSearch'

async function updateAddress(address: FormAddress & { id: string }) {
  const endpoint = `${apiUri}/addresses/${address.id}`
  const mapped = addressMapper.formAddressToDTO(address)
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(mapped),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json as ClientAddressLookupResult
  throw response.errorMessage
}

export function useUpdateAddress() {
  const queryClient = useQueryClient()

  return useMutation(updateAddress, {
    onSettled: (address, error, variables) => {
      if (error) return showApiError('Unable to update address', error)

      queryClient.invalidateQueries(addressBookKeys.allSearch)
      queryClient.invalidateQueries(addressSearchKeys.all)

      if (address) {
        queryClient.setQueriesData(
          [...addressBookKeys.all, variables.id],
          address,
        )
        queryClient.setQueriesData<
          InfiniteQueryData<ClientAddressLookupResult>
        >(
          addressBookKeys.all,
          setDataByPage(addresses =>
            addresses.map(a => (a._id === address._id ? address : a)),
          ),
        )
      }

      toast.success('Address updated successfully.')
    },
  })
}
