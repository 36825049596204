import clsx from 'clsx'

import {
  DeclaredValueField,
  DescriptionField,
  DimensionsField,
  NonStandardField,
  PackagingField,
  ProductCatalogField,
  WeightField,
} from './Fields'
import { useItemsState } from './loadItemsContext'
import { type FormLayoutProps } from './types'

export const ParcelItemForm = ({
  register,
  onChange,
  value,
}: FormLayoutProps) => {
  const { step, carrierCode } = useItemsState()
  const isQuoting = step === 'quoting'
  const isBooking = step === 'booking'
  return (
    <>
      <ProductCatalogField
        className={clsx({
          'basis-full': isQuoting,
          'basis-2/5': isBooking,
        })}
        label={isBooking ? 'Description' : 'Search'}
        onChange={onChange}
        doublesAsDescription={isBooking}
        value={value}
        required={isBooking}
      />
      {isQuoting && (
        <DescriptionField register={register} className="basis-2/3" />
      )}
      <DeclaredValueField
        register={register}
        className={clsx({
          'basis-1/3': isQuoting,
          'basis-1/5': isBooking,
        })}
      />
      <PackagingField
        className={clsx({
          'b1600:basis-7/12 b1300:basis-8/12 b1150:basis-7/12 basis-8/12':
            isQuoting,
          'basis-2/5': isBooking,
        })}
        register={register}
        value={value}
        onChange={onChange}
        carrierCode={carrierCode}
      />
      <NonStandardField
        register={register}
        className={clsx({
          'b1600:basis-5/12 b1300:basis-4/12 b1150:basis-5/12 basis-4/12':
            isQuoting,
          'basis-1/3': isBooking,
        })}
      />
      <WeightField
        className={clsx({
          'b1600:basis-5/12 basis-1/2': isQuoting,
          'basis-1/3': isBooking,
        })}
        register={register}
        label="Total weight"
      />

      <DimensionsField
        register={register}
        className={clsx(`form-group form-group--collection`, {
          'b1600:basis-7/12 basis-1/2': isQuoting,
          'basis-1/3': isBooking,
        })}
      />
      {isQuoting && (
        <div className="border-fv-border mx-1 basis-full border-b" />
      )}
    </>
  )
}
