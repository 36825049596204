import { useCurrency } from '../../../hooks/settings'

type Props = {
  amount: number
}
export const AuditPriceDisplay = ({ amount }: Props) => {
  const priceParts = useCurrency()
  const [dollars, cents] = priceParts(amount)
  const priceDisplay = `$${dollars}.${cents}`
  return <span className="price price--audit font-oxygen">{priceDisplay}</span>
}
