import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { showApiError } from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { addressMapper } from './addressFuncs'
import { type FormAddress } from './types'
import { addressBookKeys } from './useAddressBookAddresses'

async function createAddress(address: FormAddress) {
  return shipperFetch<ClientAddressLookupResult>(`/addresses`, {
    body: addressMapper.formAddressToDTO(address),
    method: 'POST',
  })
}

export function useCreateAddress(notify = true) {
  const queryClient = useQueryClient()

  return useMutation(createAddress, {
    onSettled: (_address, error) => {
      if (error && notify) return showApiError('Unable to add address', error)
      queryClient.invalidateQueries(addressBookKeys.all)
      notify && toast.success('Address added successfully.')
    },
  })
}
