import { ChangeEvent, HTMLProps } from 'react'

import { CredentialConfig, CredentialDetail } from '@fv/client-types'

import { FieldOpts } from './forms'

export type CredentialDetailEditModel = Record<string, string | boolean>

export const createCredentailDetails = (
  credTypes: CredentialConfig[] | undefined,
  existing: CredentialDetail[],
): CredentialDetail[] =>
  credTypes?.map(config => {
    const existingEntry = existing.find(e => e.type === config.name)

    return {
      value: coerceValueForConfig(existingEntry?.value, config),
      type: config.name,
      expires: existingEntry?.expires,
    }
  }) ?? []

export const createCredentialEditModel = (
  credTypes: CredentialConfig[],
  details: CredentialDetail[],
): CredentialDetailEditModel => {
  return Object.fromEntries(
    (credTypes ?? []).map(c => [
      c.name,
      coerceValueForConfig(details.find(d => d.type === c.name)?.value, c),
    ]),
  )
}

export const createCredentialDetailsFromModel = (
  model: CredentialDetailEditModel,
): CredentialDetail[] => {
  return Object.entries(model).map(([type, value]) => ({
    type,
    value,
  }))
}

export const createCredentialConfigSchema = (
  configs: CredentialConfig[],
): Record<string, FieldOpts<CredentialDetailEditModel>> => {
  return Object.fromEntries(
    configs
      .filter(c => c.type === 'checkbox')
      .map<[string, FieldOpts<CredentialDetailEditModel>]>(c => [
        c.name,
        {
          valueAsChecked: true,
        },
      ]),
  )
}

const coerceValueForConfig = (
  val: string | boolean | undefined,
  config: CredentialConfig,
) => {
  if (val) return val
  if (config.type === 'checkbox') return !!val
  return ''
}

export const registerCredentialDetailHelper = (
  type: string,
  details: CredentialDetail[],
  onChange: (details: CredentialDetail[]) => void,
): Omit<HTMLProps<HTMLInputElement>, 'ref'> & { name: string } => {
  return {
    name: type,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      onChange(mutateDetails(details, { type, value: e.target.value })),
    value: details.find(d => d.type === type)?.value?.toString() ?? '',
  }
}

// TODO - may need to make this fn add a detail if it's not present.
export const mutateDetails = (
  details: CredentialDetail[],
  mutation: CredentialDetail,
) => details.map(d => (d.type === mutation.type ? mutation : d))
