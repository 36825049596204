import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { type DateRange } from 'react-day-picker'

import { Icon } from '@fv/client-components'
import { parseDateRange } from '@fv/client-core'

import { DateRangeSelector } from '../inputs/DateRangeSelector'
import { SelectFilter } from './SelectFilter'
import { type FilterComponentProps } from './types'

const dateFormat = 'MM/DD/YYYY'

export const DateFilter = ({
  name,
  options,
  setQueryString,
  value,
}: FilterComponentProps) => {
  const [isDatePickerOpen, setDatePickerOpen] = useState(false)
  const { start, end, isCustom, name: dateRangeName } = parseDateRange(value)
  const customOption = {
    text: 'Custom',
    value: 'custom',
  }

  const setDateRange = useCallback(
    (range?: DateRange) => {
      setQueryString(qs => {
        const params = new URLSearchParams(qs)

        if (!range?.from && !range?.to) {
          params.set(name, 'custom')
        } else {
          const startTime = range.from ? dayjs(range.from).valueOf() : null
          const endTime = range.to ? dayjs(range.to).valueOf() : null
          let rangeParam = ''

          if (startTime) rangeParam += startTime
          rangeParam += '-'
          if (endTime) rangeParam += endTime

          params.set(name, rangeParam)
        }

        return params.toString()
      })
    },
    [name, setQueryString],
  )

  return (
    <>
      <SelectFilter
        name={name}
        options={options.concat(customOption)}
        setQueryString={setQueryString}
        value={dateRangeName}
      />

      {isCustom && (
        <>
          <div>
            <a
              href="#"
              onClick={e => {
                e.preventDefault()
                setDatePickerOpen(true)
              }}
            >
              <Icon icon="calendar-day" />
              <span>
                {start ? dayjs(start).format(dateFormat) : 'mm/dd/yyyy'}
                {' - '}
                {end ? dayjs(end).format(dateFormat) : 'mm/dd/yyyy'}
              </span>
            </a>
          </div>

          <DateRangeSelector
            closeDatePicker={() => setDatePickerOpen(false)}
            dateRange={{ from: start, to: end }}
            isOpen={isDatePickerOpen}
            setDateRange={range => {
              setDateRange(range)
              setDatePickerOpen(false)
            }}
          />
        </>
      )}
    </>
  )
}
