import InfoBox from '../../components/InfoBox'
import { useUserSettings } from '../../hooks/settings'

const hasLogoText =
  "Logo shown below is displayed on BOL's, shipping labels, customer rate detail document, and applicable emails."

const noLogoText =
  'Upload your company logo to personalize BOLs, shipping labels, and emails sent to your customers. For optimal results, ensure the logo is at least 300 pixels wide and tightly cropped to the edges.'

type Props = {
  showCurrent?: boolean
}
export const CompanyLogoContent = ({ showCurrent = true }: Props) => {
  const settingsQuery = useUserSettings()
  const logo = settingsQuery?.data?.customLogo

  const showIntro = !showCurrent || !logo
  return (
    <div>
      <InfoBox className="mb-6">
        {!showIntro && hasLogoText}
        {showIntro && noLogoText}
      </InfoBox>

      {!showIntro && (
        <img
          src={logo}
          alt="company logo"
          className="min-w-[16rem] max-w-[20rem]"
        />
      )}
      {showIntro && (
        <div className="mb-10 flex gap-x-4">
          <img
            className="w-72"
            src="https://s3.amazonaws.com/assets.freightview.com/img/upload-logo-incorrect.svg"
            alt="bad logo example"
          />
          <img
            className="ml-2 w-72"
            src="https://s3.amazonaws.com/assets.freightview.com/img/upload-logo-correct.svg"
            alt="good logo example"
          />
        </div>
      )}
    </div>
  )
}
