import clsx from 'clsx'

import { Icon, Tooltip } from '@fv/client-components'
import { type ShippingLabelSize } from '@fv/client-types'

import { ListItem } from '../../components/List'
import arrayOfLength from '../../utils/arrayOfLength'
import { type LabelConfig } from './labelConfig'
import { LabelOptionForm } from './LabelOptionForm'

type Props = {
  active: boolean
  closePanel: () => void
  config: LabelConfig
  defaultCount: number
  loadId: string
  pieceQuantity?: number
  setActive: (s: ShippingLabelSize) => void
  unitQuantity: number
  onSubmit?: (props: {
    count: number
    loadId: string
    size: ShippingLabelSize
    start?: number
  }) => void
}

export const LabelOption = ({
  active,
  closePanel,
  config,
  defaultCount,
  loadId,
  pieceQuantity,
  setActive,
  unitQuantity,
  onSubmit,
}: Props) => {
  return (
    <ListItem>
      <div
        className="flex items-center w-full"
        onClick={() => setActive(config.size)}
      >
        <div
          className={clsx('shipping-label-icon', config.className, {
            'shipping-label-icon--continuous': config.type === 'roll',
            active,
          })}
        >
          {config.count ? (
            arrayOfLength(config.count).map(i => (
              <div className="shipping-label-icon__label" key={i}>
                {i + 1}
              </div>
            ))
          ) : (
            <div className="shipping-label-icon__label">{config.label}</div>
          )}
        </div>

        {active ? (
          <LabelOptionForm
            closePanel={closePanel}
            defaultCount={defaultCount}
            labelConfig={config}
            loadId={loadId}
            pieceQuantity={pieceQuantity}
            unitQuantity={unitQuantity}
            onSubmit={onSubmit}
          />
        ) : (
          <Tooltip label={config.tooltip}>
            <Icon icon="sticky-note" />
          </Tooltip>
        )}
      </div>
    </ListItem>
  )
}
