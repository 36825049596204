import { OlarkChat } from '@fv/client-components'

import { adminUri, apiUri } from '../constants'
import { useAppContext, useAppSettings } from '../hooks/settings'
import { useAccountId } from '../hooks/settings/useAppContext'
import { LiveEventsHandler } from './LiveEventsHandler'

export const AppTooling = () => {
  const appSettings = useAppSettings()
  const contextQuery = useAppContext()
  const accountId = useAccountId()
  const user = contextQuery.data?.user
  const userId = user?._id
  const name = `${user?.firstName} ${user?.lastName}`
  const viewAsLink = `${adminUri}/accounts/${accountId}/overview`

  return (
    <>
      <LiveEventsHandler
        authenticateUri={`${apiUri}/live-events`}
        liveEventsUri={appSettings.data?.liveEventsUri}
      />

      <OlarkChat
        company={user?.company}
        email={user?.email}
        linkLabel="Shipper admin"
        name={name}
        phoneNumber={user?.phoneNumber}
        userId={userId}
        viewAsLink={viewAsLink}
      />
    </>
  )
}
