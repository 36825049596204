import queryString, { ParseOptions, StringifyOptions } from 'query-string'

export function stringify(args: { [key: string]: any }) {
  args = args || {}
  let result = ''
  let added = 0

  Object.keys(args).forEach(key => {
    const value = args[key]
    let kvp = null

    if (typeof value !== 'undefined') {
      kvp = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`

      if (added === 0) result += '?'
      else result += '&'

      result += kvp
      added++
    }
  })

  return result
}

export const parseQueryString = (query: string, options?: ParseOptions) => {
  return queryString.parse(query, {
    arrayFormat: 'bracket',
    ...options,
  })
}
export const stringifyQueryString = (
  data: Record<string, any>,
  options?: StringifyOptions,
) => {
  return queryString.stringify(data, {
    arrayFormat: 'bracket',
    skipNull: true,
    ...options,
  })
}
