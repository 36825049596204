import { useMutation } from '@tanstack/react-query'
import pluralize from 'pluralize'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  showApiError,
} from '@fv/client-core'
import { type ListShipment } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useUpdateCachedLoad } from './useUpdateCachedLoad'

async function cancelLoad(
  loadId?: string,
  isAllLoads?: boolean,
): Promise<ListShipment[]> {
  if (!loadId) throw new Error('`loadId` required to cancel load')

  const endpoint = `${apiUri}/shipments/${loadId}`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ allRelatedLoads: !!isAllLoads }),
    method: 'DELETE',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useCancelLoad(loadId?: string, showNotifications = true) {
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation((isAllLoads?: boolean) => cancelLoad(loadId, isAllLoads), {
    onSettled: (loads, error, isAllLoads) => {
      const loadString = pluralize('load', isAllLoads ? 2 : 1)

      if (error && showNotifications)
        return showApiError(`Unable to cancel ${loadString}`, error)

      loads?.forEach(updatedLoad => {
        updateCachedLoad({ updatedLoad })
      })

      showNotifications && toast.success(`Canceled ${loadString} successfully.`)
    },
  })
}
