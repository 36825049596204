import {
  DeferredFedexConnection,
  DeferredProvisioningNotice,
} from './components/DeferredProvisioningNotice'
import { FedexCredForm } from './fxfe/FedexCredForm'
import { FedexLegacyCredForm } from './fxfe/FedexLegacyCredForm'
import { FedexLocationDetails } from './fxfe/FedexLocationDetails'
import { FedexProvisioning } from './fxfe/FedexProvisioning'
import { TForceProvisioning } from './upgf/TForceProvisioning'
import { UpsProvisioning } from './upgf/UpsProvisioning'
import {
  useAccountCarrierState,
  useCarrierDetails,
  useIsBeta,
} from './AccountCarrierFormProvider'
import {
  type BaseProvisionFormProps,
  type BaseProvisionLocationFormProps,
  type CredentialFormProps,
} from './types'

type CarrierProvisionComponent = (props: BaseProvisionFormProps) => JSX.Element
type CarrierLocationProvisionComponent = (
  props: BaseProvisionLocationFormProps,
) => JSX.Element

type Context = {
  onboarding?: boolean
  beta?: boolean
}

const provisionComponentProvider: Record<
  string,
  (context: Context) => CarrierProvisionComponent | null
> = {
  fxfe: (context: Context) => (context.beta ? FedexProvisioning : null),
}

export const provisionLocationComponentProvider: Record<
  string,
  (beta: boolean) => CarrierLocationProvisionComponent | null
> = {
  fxfe: (beta: boolean) => (beta ? FedexLocationDetails : null),
}

type CarrierCredentialComponent = (props: CredentialFormProps) => JSX.Element
const carrierCredFormProvider: Record<
  string,
  (context: Context) => CarrierCredentialComponent | null
> = {
  fxfe: (context: Context) =>
    context.beta
      ? context.onboarding
        ? DeferredFedexConnection
        : FedexCredForm
      : FedexLegacyCredForm,
  upss: (context: Context) =>
    context.onboarding ? DeferredProvisioningNotice : UpsProvisioning,
  upgf: (context: Context) =>
    context.onboarding ? DeferredProvisioningNotice : TForceProvisioning,
  tfin: (context: Context) =>
    context.onboarding ? DeferredProvisioningNotice : TForceProvisioning,
}

export const useCarrierProvisioningComponent = () => {
  const { onboarding } = useAccountCarrierState()
  const beta = useIsBeta()
  const carrierDetails = useCarrierDetails()
  return provisionComponentProvider[carrierDetails?.code]?.({
    beta,
    onboarding,
  })
}

export const useCarrierCredComponent = () => {
  const beta = useIsBeta()
  const { onboarding } = useAccountCarrierState()
  const carrierDetails = useCarrierDetails()
  return carrierCredFormProvider[carrierDetails?.code]?.({
    beta,
    onboarding,
  })
}
