import { ShipmentTagsControl } from '../../features/tags/ShipmentTagsControl'

type Props = {
  loadId: string
}
export const ShipmentTags = ({ loadId }: Props) => {
  return (
    <div>
      <p>
        If any tags are related to this shipment you will see them below. Tags
        created by an administrator can be applied to this shipment by selecting
        them or typing in the box below. New tags can be created if you have
        permission.
      </p>

      <ShipmentTagsControl loadId={loadId} />
    </div>
  )
}
