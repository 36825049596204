import {
  FvButton,
  LoadMore,
  SliderPanel,
  TextField,
} from '@fv/client-components'
import { addressFuncs } from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { InputAdornment } from '../../components/inputs/InputGroup'
import { List } from '../../components/List'
import { usePermissions } from '../auth'
import { AddressBookEntry } from './AddressBookEntry'
import { AddressForm } from './AddressForm'
import { useAddressBook } from './useAddressBook'
import { useAddressesFromStore } from './useAddressBookAddresses'
import { useCacheFormattedAddress } from './useAddressSearch'

type SearchProps = {
  onUploadClick?: () => void
}
export const AddressBookListSearch = ({ onUploadClick }: SearchProps) => {
  const { setSearch, searchInput, showForm, searchText } = useAddressBook()
  const { canManage } = usePermissions()
  return (
    <div className="flex">
      <div className="input-group flex-1">
        <InputAdornment
          icon="search"
          iconClass="color-success"
          position="start"
        />
        <TextField
          autoFocus
          className="form-control mr-2"
          name="addressSearch"
          onChange={searchInput}
          placeholder="Company, address or postal code"
          value={searchText}
        />
      </div>

      <FvButton icon="plus" onClick={showForm} theme="default">
        New address
      </FvButton>

      {onUploadClick && canManage && (
        <FvButton
          theme="default"
          onClick={() => {
            setSearch('')
            onUploadClick()
          }}
          icon="cloud-upload"
        >
          Upload
        </FvButton>
      )}
    </div>
  )
}

type ListProps = {
  inline?: boolean
  canRemove?: boolean
}
export const AddressBookList = ({ inline, canRemove }: ListProps) => {
  const addressBook = useAddressBook()
  const addressBookQuery = useAddressesFromStore(!!inline)
  const cacheFormattedAddress = useCacheFormattedAddress()
  function selectAddress(address: ClientAddressLookupResult) {
    addressBook.selectAddress?.(address)
    const formatted = addressFuncs.companyAddress(address)
    if (formatted) cacheFormattedAddress({ search: formatted }, address)
  }

  const addresses = addressBookQuery.data
  const mappedAddresses = addresses.map(a => ({
    ...a,
    isDup:
      !!a.isAccountLocation &&
      !!a.accountLocationId &&
      !!addresses.filter(
        o =>
          o._id !== a._id &&
          o.isAccountLocation &&
          o.accountLocationId === a.accountLocationId,
      )?.length,
  }))
  return (
    <>
      {!addressBookQuery.data.length &&
        (addressBookQuery.isLoading ? (
          <InfoBox icon="spinner">Loading...</InfoBox>
        ) : (
          <InfoBox>No addresses found.</InfoBox>
        ))}

      {mappedAddresses.map(a => (
        <AddressBookEntry
          address={a}
          key={a._id}
          selectAddress={addressBook.setAddress ? selectAddress : undefined}
          listCanRemove={canRemove}
          canRemove={canRemove && (!a.isAccountLocation || a.isDup)}
        />
      ))}
      <div>
        <LoadMore
          className="mt-4"
          fetchNextPage={addressBookQuery.fetchNextPage}
          hasNextPage={addressBookQuery.hasNextPage}
          isLoading={addressBookQuery.isFetchingNextPage}
        />
      </div>
    </>
  )
}

export const AddressBookSlider = () => {
  const addressBook = useAddressBook()
  const addressBookQuery = useAddressesFromStore()

  const addressToEdit = addressBookQuery.data.find(
    a => a._id === addressBook.editingId,
  )
  return (
    <SliderPanel
      className="flex flex-col"
      closePanel={addressBook.close}
      isOpen={addressBook.isOpen}
    >
      {addressBook.view === 'list' && (
        <div className="-mt-8">
          {!addressBook.config.postalCodeFilter && (
            <div className="sticky -top-6 z-10 -mx-6 -my-8 border-b bg-white px-6 py-4">
              <AddressBookListSearch />
            </div>
          )}

          <List className="mt-20">
            <AddressBookList />
          </List>
        </div>
      )}

      {addressBook.view === 'form' && (
        <AddressForm
          context={addressBook.config.context}
          initialValues={addressToEdit}
        />
      )}
    </SliderPanel>
  )
}
