import { type FullShipment } from '@fv/client-types'

import { MessagesNotesTagsControls } from './MessagesNotesTagsControls'

type MessagesNotesTagsPanelProps = {
  closePanel?: () => void
  load: FullShipment
}

const MessagesNotesTagsPanel = ({
  closePanel,
  load,
}: MessagesNotesTagsPanelProps) => (
  <MessagesNotesTagsControls
    load={load}
    closePanel={closePanel}
    className="shipment-list-ancillary-details-ctn"
    navClass="shipment-list-ancillary-details__nav"
    contentClass="shipment-list-ancillary-details "
  />
)
export default MessagesNotesTagsPanel
