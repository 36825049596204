import { SliderPanel } from '@fv/client-components'

import { Loading } from '../../components/Loading'
import { useLoad } from '../../hooks/shipments'
import { type SliderPanelComponent, sliderTabs } from './SliderContent'
import { type SliderName, type SliderPanelProps } from './types'

export type ShipmentSliderState<T = SliderName> = {
  tab?: T
  tabProps?: SliderPanelProps<T>
}

type Props = {
  loadId: string
  onChange: (state: ShipmentSliderState) => void
  state?: ShipmentSliderState
}
export function ShipmentDetailsSliderPanel({ state, onChange, loadId }: Props) {
  const { data: load } = useLoad(loadId)
  const SliderContent: SliderPanelComponent | null = state?.tab
    ? sliderTabs[state.tab]
    : null
  const closePanel = () => {
    onChange({ tab: undefined, tabProps: undefined })
  }

  return (
    <SliderPanel isOpen={!!state?.tab} closePanel={closePanel}>
      {!load && <Loading />}
      {load && SliderContent && (
        <SliderContent
          closePanel={closePanel}
          load={load}
          setActiveSlider={(name, props) => {
            onChange({
              tab: name,
              tabProps: props,
            })
          }}
          {...(state?.tabProps ?? {})}
        />
      )}
    </SliderPanel>
  )
}
