import { lazy, Suspense } from 'react'

import InfoBox from '../../../components/InfoBox'
import { ListItem } from '../../../components/List'
import FuelTable from './FuelTable'

const FuelTableUpload = lazy(() => import('./FuelTableUpload'))

type Props = {
  carrierId: string
  isOpen: boolean
}

const FuelTablePanel = ({ carrierId, isOpen }: Props) => {
  if (!isOpen) return null

  return (
    <ListItem className="flex-col">
      <div className="w-full">
        <FuelTable carrierId={carrierId} />
        <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
          <FuelTableUpload carrierId={carrierId} />
        </Suspense>
      </div>
    </ListItem>
  )
}

export default FuelTablePanel
