import { useMutation } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'

type SaveQuoteOptions = {
  loadId: string
  quoteId: string
  refNum: string
}

async function saveQuote(opts: SaveQuoteOptions) {
  const endpoint = `${apiUri}/recent-quotes/${opts.loadId}`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      quoteId: opts.quoteId,
      refNum: opts.refNum,
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useSaveQuote() {
  return useMutation(saveQuote)
}
