import { useParams } from 'react-router-dom'

import { type ProvisioningFilterStatus } from '@fv/client-types'

export const useProvisionerStatus = () => {
  const { status } = useParams()
  return status as ProvisioningFilterStatus
}

export const useProvisionerAccountId = () => {
  const { accountId } = useParams()
  return accountId
}
