import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import { useToggle } from 'usehooks-ts'

import {
  ClipboardButton,
  FvButton,
  FvLinkButton,
  SliderPanel,
  Tooltip,
} from '@fv/client-components'
import { copyToClipboard, friendlyDate, showApiError } from '@fv/client-core'
import { type ApiKeyListDto, type ApiKeyVersion } from '@fv/models'

import { ActionList } from '../../../components/ActionList'
import InfoBox from '../../../components/InfoBox'
import { List, ListHeaderActions, ListItem } from '../../../components/List'
import { Loading } from '../../../components/Loading'
import { TabLink, TabLinkList } from '../../../components/TabLink'
import { useAppContext } from '../../../hooks/settings'
import { SettingsSection } from '../SettingsSection'
import { CreateApiKeyForm } from './CreateApiKeyForm'
import { useDeleteApiKey } from './mutations'
import { useApiKeys } from './queries'
import { apiDocumentationLink } from './utils'

export const IntegrationPage = () => {
  const [params, setSearchParams] = useSearchParams()
  const version: ApiKeyVersion = params.get('version') === '1' ? 1 : 2
  const { isInitialLoading, data: apiKeys } = useApiKeys({ version })
  const removeKey = useDeleteApiKey()
  const [showCreating, toggleCreating] = useToggle()
  const developerLink = apiDocumentationLink(version)
  const contextQuery = useAppContext()
  const { _id: clientId } = contextQuery.data?.user ?? {}

  const handleRemoveClick = async (key: ApiKeyListDto) => {
    if (
      !window.confirm(
        `Any existing integrations using ${key.name} will stop working.  Are you sure you want to remove this API key?`,
      )
    ) {
      return
    }
    try {
      await removeKey.mutateAsync({ id: key._id })
      toast.success('Api key removed')
    } catch (e) {
      showApiError("Couldn't remove API key", e)
    }
  }

  if (isInitialLoading || !apiKeys) {
    return <Loading />
  }

  return (
    <div className="settings-main">
      <TabLinkList className="pb-10">
        <TabLink
          isActive={version === 2}
          onClick={() => setSearchParams({ version: '2' })}
        >
          API credentials for v2
        </TabLink>
        <TabLink
          isActive={version === 1}
          onClick={() => setSearchParams({ version: '1' })}
        >
          API credentials for v1
        </TabLink>
      </TabLinkList>
      <SettingsSection title="API credentials" className="mb-4">
        <ListHeaderActions className="divide-x [&>*]:px-3 divide-fv-gray mb-2 !border-0">
          {version === 2 && (
            <span className="first:pl-0 last:pr-0">
              Your Client ID:{' '}
              <FvLinkButton
                className="break-all"
                theme="underlined"
                onClick={() => copyToClipboard(clientId)}
                title="Your Client ID"
              >
                {clientId}
              </FvLinkButton>
              <ClipboardButton value={clientId} />
            </span>
          )}
          <FvButton
            icon="plus"
            onClick={toggleCreating}
            className="pr-4 first:pl-0 last:pr-0"
          >
            Create API credentials
          </FvButton>
        </ListHeaderActions>
        {!apiKeys.length ? (
          <InfoBox>
            Looks like you haven't created any API credentials yet. Click
            'Create API credentials' to get started.
          </InfoBox>
        ) : (
          <List className="mb-4 border px-4 border-fv-gray bg-fv-beer-50">
            {apiKeys.map(a => (
              <ListItem
                key={a._id}
                actions={
                  <div className="ml-auto flex pl-4 whitespace-nowrap">
                    <div className=" text-xs italic mr-4">
                      Created&nbsp;
                      <Tooltip label={friendlyDate(a.created)}>
                        <span>{dayjs.utc(a.created).fromNow()}</span>
                      </Tooltip>
                    </div>
                    <ActionList>
                      <FvButton
                        icon="trash"
                        iconClass="text-fv-orange"
                        onClick={() => handleRemoveClick(a)}
                      />
                    </ActionList>
                  </div>
                }
              >
                <span className="truncate">{a.name}</span>
              </ListItem>
            ))}
          </List>
        )}

        <p className="text-sm mb-0">
          Our V{version} documentation is at{' '}
          <FvLinkButton
            theme="underlined"
            href={developerLink}
            rel="noreferrer"
            target="_blank"
            className="text-nowrap"
          >
            {developerLink}
          </FvLinkButton>
        </p>
      </SettingsSection>
      {!!apiKeys.length && (
        <InfoBox>
          <p>
            Need help or want to brainstorm about your idea? Send an email to{' '}
            <FvLinkButton
              theme="underlined"
              href="mailto:support@freightview.com"
            >
              support@freightview.com
            </FvLinkButton>
          </p>
        </InfoBox>
      )}
      <SliderPanel isOpen={showCreating} closePanel={toggleCreating}>
        <CreateApiKeyForm onClose={toggleCreating} version={version} />
      </SliderPanel>
    </div>
  )
}
