import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useFvNavigate } from '@fv/client-components'
import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { apiUri } from '../../constants'
import { isProcessingQuotes } from '../../utils/shipmentFuncs'
import { useUpdateCachedLoad } from '.'

const REFETCH_INTERVAL = 15000

type UseLoadOpts = {
  refetchWhileQuoting?: boolean
  refetchWhileDispatching?: boolean
}

export const loadKeys = {
  all: ['load'] as const,
  id: (id?: string | null) => [...loadKeys.all, id ?? null] as const,
}

async function fetchLoad(loadId?: string | null): Promise<FullShipment> {
  if (!loadId) throw new Error('`loadId` required to fetch load')

  const endpoint = `${apiUri}/shipments/${loadId}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useLoad(loadId?: string | null, options?: UseLoadOpts) {
  const updateCachedLoad = useUpdateCachedLoad()
  const navigate = useFvNavigate()
  return useQuery(loadKeys.id(loadId), () => fetchLoad(loadId), {
    enabled: Boolean(loadId),
    onSuccess: updatedLoad => {
      updateCachedLoad({
        updatedLoad,
        keysToSkip: loadKeys.all as any,
      })
    },
    refetchInterval: data => {
      if (options?.refetchWhileQuoting && isProcessingQuotes(data)) {
        return REFETCH_INTERVAL
      }

      if (
        options?.refetchWhileDispatching &&
        data?.pickup?.status === 'requesting'
      ) {
        return REFETCH_INTERVAL
      }

      return false
    },
    onError: (error: { message: string; status: number; name: string }) => {
      if (error?.status === 410 && error?.name === 'DISCARDED') {
        toast.error(error?.message)
        navigate('/active')
      }
    },
  })
}
