import { useMutation } from '@tanstack/react-query'

import { shipperFetch } from '../../utils/shipperFetch'

type TargetPrice = {
  targetPrice: number
  loadId: string
}

const postTargetPrice = async (dto: TargetPrice) => {
  return await shipperFetch(`/shipments/${dto.loadId}/update-target-price`, {
    body: { targetPrice: dto.targetPrice },
    method: 'POST',
  })
}

export const useUpdateTargetPrice = () => {
  return useMutation(postTargetPrice)
}
