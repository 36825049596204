import dayjs from 'dayjs'

export default function createStopDetailOptions({
  isFirstStop,
  isLastStop,
  stopDate,
}: {
  isFirstStop: boolean
  isLastStop: boolean
  stopDate?: Date | string | null
}) {
  const date = stopDate ? dayjs(stopDate).format('MMM D') : null

  if (isFirstStop) {
    return [`Pick up on ${date}`, `Pick up by ${date}`]
  }

  if (isLastStop) {
    return date ? [`Deliver on ${date}`, `Deliver by ${date}`] : ['Deliver']
  }

  return date
    ? [
        `Pick up on ${date}`,
        `Pick up by ${date}`,
        `Pick up and deliver on ${date}`,
        `Pick up and deliver by ${date}`,
        `Deliver on ${date}`,
        `Deliver by ${date}`,
      ]
    : ['Pick up', 'Pick up and deliver', 'Deliver']
}
