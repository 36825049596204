export const combineRegexWithOr = (...regexes: Array<RegExp | undefined>) => {
  const newSource = regexes
    .filter(r => !!r)
    .flatMap(r => r.source)
    .join('|')
  const newFlags = regexes
    .filter(r => !!r)
    .flatMap(r => r.flags)
    .join('')
    .split('')
    .sort()
    .join('')
    .replace(/(.)(?=.*\1)/g, '')

  return new RegExp(newSource, newFlags)
}

export const allowEmptyStringRegex = (
  toggle = false,
  ...regexes: Array<RegExp | undefined>
) => {
  return combineRegexWithOr(...regexes, toggle ? /^$/ : undefined)
}

export const emailRegex =
  /[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/

export const regexMatchAllStrings = (strings: string[], flags?: string) => {
  const inner = strings.map(s => `(?=[\\s\\S]*${s})`).join('')
  return new RegExp(`^${inner}[\\s\\S]*$`, flags)
}
