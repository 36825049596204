import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  Fragment,
  HTMLProps,
  PropsWithChildren,
  useEffect,
  useRef,
} from 'react'

import { SelectFieldOption } from '@fv/client-types'

type SelectOptionsProps = {
  options?: SelectFieldOption[]
}

const SelectOptions = ({ options }: SelectOptionsProps) => {
  if (!options?.length) return null

  return (
    <>
      {options.map(o => {
        const label = typeof o === 'string' ? o : o.text
        const optionValue = typeof o === 'string' ? o : o.value
        return (
          <option key={optionValue} value={optionValue}>
            {label}
          </option>
        )
      })}
    </>
  )
}

export interface SelectFieldProps
  extends Omit<HTMLProps<HTMLSelectElement>, 'ref'> {
  error?: string
  name: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  options: SelectFieldOption[] | SelectFieldOption[][]
  groups?: string[]
}

export const SelectField = forwardRef(
  (
    {
      error,
      name,
      onChange,
      value,
      options,
      groups,
      ...rest
    }: SelectFieldProps,
    ref?: ForwardedRef<HTMLSelectElement | null>,
  ) => {
    const localRef = useRef<HTMLSelectElement | null>(null)
    const optionGroups = getOptionGroups(options)

    function localOnChange(e: ChangeEvent<HTMLSelectElement>) {
      e.target.setCustomValidity('')
      onChange?.(e)
    }

    useEffect(() => {
      if (!error) return localRef.current?.setCustomValidity('')
      localRef.current?.setCustomValidity(error)
      localRef.current?.reportValidity()
    }, [error])

    function setRef(element: HTMLSelectElement | null) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <select
        id={name}
        name={name}
        onChange={localOnChange}
        ref={setRef}
        value={value}
        {...rest}
      >
        {optionGroups.map((opts, i) => (
          <SelectGroup
            groups={groups}
            index={i}
            key={i}
            totalOptions={optionGroups.length}
          >
            <SelectOptions options={opts} />
          </SelectGroup>
        ))}
      </select>
    )
  },
)

function SelectGroup({
  groups,
  index,
  children,
  totalOptions,
}: PropsWithChildren<{
  groups?: string[]
  index: number
  totalOptions: number
}>) {
  const groupLabel = groups?.[index]

  if (groupLabel && totalOptions > 1) {
    return <optgroup label={groupLabel}>{children}</optgroup>
  }

  return (
    <Fragment>
      {children}
      {index < totalOptions - 1 && <option disabled>──────</option>}
    </Fragment>
  )
}

function getOptionGroups(
  options: SelectFieldOption[] | SelectFieldOption[][],
): SelectFieldOption[][] {
  if (Array.isArray(options[0])) {
    return (options as SelectFieldOption[][]).filter(o => o.length > 0)
  }
  return [options as SelectFieldOption[]]
}
