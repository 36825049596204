import { UseQueryResult } from '@tanstack/react-query'

import {
  AppSettings,
  EquipmentAccessorialType,
  PackagingType,
} from '@fv/client-types'
import { EquipmentType, LocationAccessorialType } from '@fv/models'

export interface TypeNamesProvider {
  accessorialName: (
    key: EquipmentAccessorialType | LocationAccessorialType,
  ) => string
  equipmentName: (key: EquipmentType | '') => string
  packageName: (key: PackagingType | '') => string
}

export function useTypeNames(
  useAppSettings: () => UseQueryResult<AppSettings>,
): TypeNamesProvider {
  const settings = useAppSettings()
  const enums = settings.data?.enums
  const accessorialTypes = enums?.accessorialTypes
  const equipmentTypes = enums?.equipmentTypes
  const packageTypes = enums?.packagingTypeList

  return {
    accessorialName: (
      key: EquipmentAccessorialType | LocationAccessorialType,
    ) => accessorialTypes?.find(a => a.key === key)?.name ?? key,
    equipmentName: (key: EquipmentType | '') =>
      equipmentTypes?.find(e => e.key === key)?.name ?? key,
    packageName: (key: PackagingType | '') =>
      packageTypes?.find(p => p.key === key)?.name ?? key,
  }
}
