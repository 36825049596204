export type CarrierStatus = 'active' | 'hidden' | 'pending' | 'removed'

export type CredentialConfigType = 'text' | 'checkbox'
export interface CredentialConfig {
  type?: CredentialConfigType
  name: string
}
export interface CarrierProvisioningDetails {
  status: CarrierStatus
  name: string
  code: string
  carrierId: string
  credentialTypes?: CredentialConfig[]
  locationCredentialTypes?: CredentialConfig[]
  ltl: ProvisioningCarrierMode
  parcel: ProvisioningCarrierMode
  tl: ProvisioningCarrierMode
  userProvisioned?: boolean
  autoProvision?: boolean
  betaProvisioning: boolean
  allowsProNumberGeneration?: boolean
  requireSalesRepEmail: boolean
}

interface ProvisioningCarrierMode {
  enabled: boolean
  carrierNameOverride?: string
}

export const carrierModeTypes = ['ltl', 'tl', 'parcel'] as const
export type CarrierMode = (typeof carrierModeTypes)[number]
