import clsx from 'clsx'
import { type Dispatch, type SetStateAction, useState } from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { freightviewUri, routes } from '../../../constants'
import {
  type InactiveFilterType,
  MiniQuoteList,
} from '../../../features/mini-quote-list/MiniQuoteList'
import { PrintRatesButton } from '../../../features/quote/PrintRatesButton'

type Props = {
  isViewingActive: boolean
  load: FullShipment
  setShowRequestPanel: () => void
  setViewingActive: Dispatch<SetStateAction<boolean>>
}

const RatesPanel = ({
  isViewingActive,
  load,
  setShowRequestPanel,
  setViewingActive,
}: Props) => {
  const [inactiveFilter, setInactiveFilter] =
    useState<InactiveFilterType>('pending')
  const { loadId, selectedQuote } = load

  return (
    <div className="shipment-list-pricing">
      <div className="divided-content divided-content--start flex whitespace-nowrap">
        <FvLinkButton
          icon="comment-alt"
          iconClass="fa-fw"
          transform="down-.5"
          className={clsx({ 'active-hint-indicator': isViewingActive })}
          onClick={() => {
            setViewingActive(true)
          }}
        >
          Active
        </FvLinkButton>
        <FvLinkButton
          className={clsx({ 'active-hint-indicator': !isViewingActive })}
          onClick={() => {
            setViewingActive(false)
          }}
          icon="comment-alt-minus"
          iconClass="fa-fw"
          transform="down-.5"
        >
          Inactive
        </FvLinkButton>
        <FvLinkButton
          to={load.isMultiTruck ? routes.rates(load) : undefined}
          onClick={
            load.isMultiTruck
              ? undefined
              : () => {
                  setShowRequestPanel()
                }
          }
          icon="paper-plane"
          transform="down-.5"
        >
          Get more quotes
        </FvLinkButton>
        <PrintRatesButton
          loadId={load.loadId}
          transform="down-.5"
          title="Print rates"
          className="pr-0"
        />
      </div>
      <hr />
      <div className="divided-content divided-content--start flex">
        {!isViewingActive && (
          <>
            <a
              className={clsx({
                'active-hint-indicator': inactiveFilter === 'pending',
              })}
              href="#"
              onClick={e => {
                e.preventDefault()
                setViewingActive(false)
                setInactiveFilter('pending')
              }}
            >
              <Icon icon="comment-alt-minus" />
              <span>Have not responded</span>
            </a>
            <a
              className={clsx({
                'active-hint-indicator': inactiveFilter === 'declined',
              })}
              href="#"
              onClick={e => {
                e.preventDefault()
                setViewingActive(false)
                setInactiveFilter('declined')
              }}
            >
              <Icon icon="comment-alt-slash" />
              <span>Declined</span>
            </a>
          </>
        )}
      </div>

      {!isViewingActive && <hr />}

      <MiniQuoteList
        isActiveCarriers={isViewingActive}
        key={selectedQuote?._id}
        inactiveFilter={inactiveFilter}
        load={load}
      />
    </div>
  )
}

export default RatesPanel
