import { R } from './RegSymbol'

type Props = {
  freight?: boolean
  direct?: boolean
}
export const FedEx = ({ freight, direct }: Props) => {
  return (
    <>
      FedEx
      {(freight || direct) && <> Freight</>}
      <R />
      {direct && <> Direct</>}
    </>
  )
}
