import { useMutation, useQueryClient } from '@tanstack/react-query'

import { type ApiKeyCreateDto, type ApiKeyCreateResult } from '@fv/models'

import { shipperFetch } from '../../../utils/shipperFetch'
import { apiKeysQueryKey } from './queries'

export const useCreateApiKey = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (body: ApiKeyCreateDto) =>
      shipperFetch<ApiKeyCreateResult>('/api-keys', {
        method: 'POST',
        body,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries(apiKeysQueryKey.all)
      },
    },
  )
}

export const useDeleteApiKey = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id }: { id: string }) =>
      shipperFetch(`/api-keys/${id}`, {
        method: 'DELETE',
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries(apiKeysQueryKey.all)
      },
    },
  )
}
