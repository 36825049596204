import { FvButton } from '@fv/client-components'

import InfoBox from '../InfoBox'

type Props = {
  loading: boolean
  isAutoCancel?: boolean
  onConfirm: () => void
  onDecline: () => void
}

export const CancelShipmentPrompt = ({
  loading,
  isAutoCancel = true,
  onConfirm,
  onDecline,
}: Props) => {
  return (
    <div>
      <InfoBox gradient className="mb-6">
        {!isAutoCancel && (
          <p className="mb-0">
            Canceling this pickup will show it as "canceled" inside of
            Freightview. However, you will still need to notify the carrier
            outside of Freightview to cancel the pickup. Proceed?
          </p>
        )}
        {isAutoCancel && (
          <p className="mb-0">
            Canceling this pickup will cancel the shipment in the carrier's
            system and mark it as "canceled" inside of Freightview.
          </p>
        )}
      </InfoBox>

      <div className="flex--justify-items-e mb-2 flex">
        <FvButton
          loading={loading}
          onClick={onConfirm}
          theme="primary"
          icon="check"
        >
          Yes, {isAutoCancel ? 'cancel it' : 'mark it canceled'}
        </FvButton>
        <FvButton onClick={onDecline} theme="default" icon="times">
          No
        </FvButton>
      </div>
    </div>
  )
}
