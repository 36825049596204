import clsx from 'clsx'
import pluralize from 'pluralize'
import { type ChangeEvent, useState } from 'react'

import { CheckboxField, FvButton, TextAreaField } from '@fv/client-components'
import { type ShipmentShare, type Workflow } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { List, ListItem } from '../../components/List'
import { type ShareShipmentForm } from './mutations'
import { ShareRecipientList } from './ShareRecipientList'

type Props = {
  workflow: Workflow
  className?: string
  form: ShareShipmentForm
  shares: ShipmentShare[]
}

export const SharePanelMain = ({
  workflow,
  form,
  shares,
  className,
}: Props) => {
  const {
    sharedDocs,
    documents,
    message,
    setMessage,
    setSharedDocs,
    isSaving,
    submitShare,
    variables,
  } = form

  const [isShowingUsers, setShowingUsers] = useState(false)

  return (
    <div className={clsx('overflow-auto', className)}>
      {documents.some(d => d.type === 'bol') && (
        <InfoBox className="mb-4" icon="sticky-note">
          The BOLs are automatically shared. Choose additonal documents to share
          below.
        </InfoBox>
      )}

      <List className="mb-4">
        {documents.map((d, index) => {
          const isShared =
            d.isShared ||
            d.type === 'bol' ||
            (workflow === 'parcel' && d.type === 'label-combined')
          const disabled = isShared || !d._id
          const checked = isShared || (!!d._id && sharedDocs.includes(d._id))
          const name = d._id ?? `doc_${index}`

          return (
            <ListItem key={name}>
              <CheckboxField
                checked={checked}
                className="checkbox"
                disabled={disabled}
                label={d.label}
                name={name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.checked) {
                    setSharedDocs(prev => (d._id ? prev.concat(d._id) : prev))
                  } else {
                    setSharedDocs(prev => prev.filter(id => id !== d._id))
                  }
                }}
                readOnly={isSaving}
              />
            </ListItem>
          )
        })}

        {!documents.length && (
          <InfoBox>No documents are available to share.</InfoBox>
        )}
      </List>

      <TextAreaField
        className="form-control flex-shrink-0"
        name="message"
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          setMessage(e.target.value)
        }}
        placeholder="Enter optional message here"
        readOnly={isSaving}
        value={message}
      />

      {shares.length > 0 && (
        <p className="mb-0 mt-4">
          <span>
            Shared with{' '}
            <FvButton
              theme="underlined"
              onClick={() => setShowingUsers(prev => !prev)}
            >
              {shares[0].email}
            </FvButton>
            {shares.length > 1 && ' / '}
            {shares.length > 1 && (
              <FvButton
                theme="underlined"
                className="ml-0"
                onClick={() => setShowingUsers(prev => !prev)}
              >
                {pluralize('other', shares.length - 1, true)}
              </FvButton>
            )}
          </span>
        </p>
      )}

      {isShowingUsers && (
        <>
          <hr className="mx-0 my-2 mb-2" />
          <ShareRecipientList
            processingEmails={isSaving ? variables?.emails : []}
            shares={shares}
            shareShipment={submitShare}
          />
        </>
      )}
    </div>
  )
}
