import {
  createContext,
  type PropsWithChildren,
  useContext,
  useRef,
} from 'react'
import { create, useStore } from 'zustand'

import {
  type ClientAddressLookupResult,
  type FullShipment,
  type LegacyHandlingUnitDTO,
  type PropsWithLoad,
  type QuoteStopType,
  type UIQuote,
} from '@fv/client-types'

import { useLoad } from '../../hooks/shipments'
import { sanitizeLocation } from '../../utils/locationFuncs'
import { buildSharesDto } from '../../utils/shares'
import {
  defaultStopDate,
  getCustomsBrokerLocationContext,
} from '../../utils/shipmentFuncs'
import { billToMapper, defaultFormBillTo } from '../billTo/billToFuncs'
import { toFormLocation, toLocationDTO } from '../booking/hooks'
import {
  buildHandlingUnitDTO,
  unitIsHazardous,
} from '../commodities/load-items/loadItemHelpers'
import {
  buildItemsForStore,
  createItemsStoreFromLoad,
  type LoadItemsStore,
} from '../commodities/load-items/loadItemsFormState'
import {
  customsBrokerMapper,
  defaultFormBroker,
} from '../customsBroker/brokerFuncs'
import {
  defaultFormContact,
  emergencyContactMapper,
} from '../emergencyContact/contactFuncs'
import { processsLoadNotifications, requireRateLocation } from './bookFuncs'
import {
  type BookingFormActions,
  type BookingFormState,
  type BuildAwardRequestArgs,
  type LocationWithFollowers,
} from './types'

const initialState: BookingFormState = {
  billTo: defaultFormBillTo,
  bolNumber: '',
  customerRoutedDetail: {
    accountNum: '',
    carrier: null,
    service: '',
    terms: 'outbound-collect',
  },
  customsBroker: defaultFormBroker,
  tags: [],
  emergencyContact: defaultFormContact,
  equipmentAccessorials: [],
  isEdit: false,
  isNew: false,
  loadId: undefined,
  isFreightCollect: false,
  locations: [],
  quote: undefined,
  rateLocationType: 'origin',
  shareEmails: '',
  workflow: 'ltl',
}

export function createBookingFormStore(props: {
  addresses?: ClientAddressLookupResult[]
  handlingUnits?: LegacyHandlingUnitDTO[]
  isNew: boolean
  load: FullShipment
  quote?: UIQuote
}) {
  const { addresses, handlingUnits, isNew, load, quote } = props

  return create<BookingFormActions & BookingFormState & LoadItemsStore>(
    (...store) => {
      const [set, get] = store
      return {
        ...initialState,
        ...buildFormData(load, addresses),
        ...createItemsStoreFromLoad({
          load,
          step: 'booking',
          handlingUnitLookup: handlingUnits ?? [],
          selectedQuote: quote,
        })(...store),
        isNew,
        quote,
        initialLoad: load,
        actions: {
          buildCRDetail: () => {
            const { actions, customerRoutedDetail } = get()
            const { accountNum, postalCode, carrier, service } =
              customerRoutedDetail

            const billToDTO = actions.buildBillToDTO()
            return {
              billTo: {
                ...(billToDTO ?? {}),
                accountNumber: accountNum,
                postalCode: postalCode || billToDTO?.postalCode,
              },
              carrierId: carrier?.id ?? '',
              serviceId: service,
            }
          },
          buildBillToDTO: () => {
            const { billTo, locations, customerRoutedDetail } = get()
            const dto = billToMapper.formToDTO(
              load.isFreightCollect &&
                customerRoutedDetail?.terms === 'outbound-collect'
                ? locations[1]
                : billTo,
            )
            if (Object.entries(dto).some(([k, v]) => !!v && k !== 'country')) {
              return dto
            }
            return undefined
          },
          buildCustomerRoutedDTO: (createBOL, schedulePickup) => {
            const {
              actions,
              equipmentAccessorials,
              itemState: { items },
              locations,
              workflow,
              tags,
            } = get()
            const customsBroker = actions.buildCustomsBrokerDTO()
            const emergencyContact = actions.buildEmergencyContactDTO()
            const declaredValue = items.reduce(
              (value, item) => value + Number(item.declaredValue || 0),
              0,
            )

            return {
              ...(customsBroker && { customsBroker: [customsBroker] }),
              ...(emergencyContact && { emergencyContact }),
              ...actions.buildCRDetail(),
              createBOL,
              equipment: [
                {
                  ...(declaredValue && { declaredValue }),
                  accessorials: equipmentAccessorials.map(key => ({ key })),
                  alternateTypes: [],
                  declaredValueCurrency: 'usd',
                  description:
                    items[0].description || load.equipment.description,
                  isHazardous: items.some(unitIsHazardous),
                  items: items.map(buildHandlingUnitDTO(workflow)),
                  loadId: load.loadId,
                  mode: workflow,
                  type: workflow === 'parcel' ? 'parcel' : 'ltl',
                  weight: items
                    .map(i => i.weight ?? 0)
                    .reduce((sum, i) => sum + Number(i || 0), 0),
                  weightUOM: 'lbs',
                },
              ],
              tags,
              isFreightCollect: true,
              isLiveLoad: true,
              locations: locations.map(toLocationDTO),
              orderNumber: '',
              schedulePickup: !!schedulePickup,
              shares: actions.buildSharesDTO(),
              workflow: workflow,
            }
          },
          buildCustomsBrokerDTO: () => {
            const { customsBroker, locations } = get()
            const { location: customsBrokerLoc } =
              getCustomsBrokerLocationContext(locations)
            if (!customsBrokerLoc) return
            return customsBrokerMapper.formToDTO(customsBroker)
          },
          buildEmergencyContactDTO: () => {
            const {
              emergencyContact,
              itemState: { items },
            } = get()
            if (!items.some(unitIsHazardous)) return
            return emergencyContactMapper.formToDTO(emergencyContact)
          },
          buildRequestDTO: ({
            createBOL,
            schedulePickup,
            quoteId,
          }: BuildAwardRequestArgs) => {
            const {
              actions,
              deliveryOption,
              equipmentAccessorials,
              holdAtLocation,
              isEdit,
              itemState: { items },
              locations,
              notifications,
              rateLocationType,
              workflow,
              tags,
              bolNumber,
            } = get()

            const billToDTO = actions.buildBillToDTO()
            const customsBroker = actions.buildCustomsBrokerDTO()
            const emergencyContact = actions.buildEmergencyContactDTO()
            const hasRateLocation = requireRateLocation(
              locations,
              !!load.isFreightCollect,
            )

            quoteId = (quoteId || quote?._id) ?? ''

            return {
              ...(!isEdit && { schedulePickup: !!schedulePickup }),
              ...(customsBroker && { customsBroker: [customsBroker] }),
              ...(emergencyContact && { emergencyContact }),
              ...(hasRateLocation && { rateLocationType }),
              ...(billToDTO && { billTo: billToDTO }),
              createBOL,
              isEdit,
              bolNumber,
              loads: [
                {
                  deliveryOption: deliveryOption,
                  equipment: {
                    accessorials: equipmentAccessorials.map(key => ({ key })),
                  },
                  holdAtLocation: holdAtLocation,
                  items: items.map(buildHandlingUnitDTO(workflow)),
                  loadId: load.loadId,
                  notifications: processsLoadNotifications(notifications),
                  quoteId,
                },
              ],
              locations: locations.map(toLocationDTO),
              shares: actions.buildSharesDTO(),
              tags,
            }
          },
          buildSharesDTO: () => {
            const { locations, shareEmails } = get()
            return buildSharesDto(shareEmails, locations)
          },
          setBillToValues: values => {
            set(prev => ({
              billTo: {
                ...prev.billTo,
                ...values,
              },
            }))
          },
          setBrokerValues: values => {
            set(prev => ({
              customsBroker: {
                ...prev.customsBroker,
                ...values,
              },
            }))
          },
          setCustomerRoutedValues: values => {
            set(prev => ({
              customerRoutedDetail: {
                ...prev.customerRoutedDetail,
                ...values,
              },
            }))
          },
          setEmergencyContactValues: values => {
            set(prev => ({
              emergencyContact: {
                ...prev.emergencyContact,
                ...values,
              },
            }))
          },
          setLocationValues: (type, values) => {
            set(prev => {
              const nextLocation = sanitizeLocation(
                {
                  ...prev.locations[type === 'origin' ? 0 : 1],
                  ...values,
                },
                get().workflow,
              )

              return {
                locations: prev.locations.map(x =>
                  x.type === type ? nextLocation : x,
                ),
              }
            })
          },
          setRateLocationType: e => {
            set({ rateLocationType: e.target.value as QuoteStopType })
          },
          setShareEmails: shareEmails => {
            set({ shareEmails })
          },
          toggleEquipmentAccessorial: name => {
            set(prev => {
              const hasAccessorial = prev.equipmentAccessorials.some(
                a => a === name,
              )

              return {
                equipmentAccessorials: hasAccessorial
                  ? prev.equipmentAccessorials.filter(a => a !== name)
                  : prev.equipmentAccessorials.concat(name),
              }
            })
          },
          updateBolNumber(bolNumber) {
            set({ bolNumber })
          },
          updateDeliveryOption(deliveryOption) {
            set({ deliveryOption })
          },
          updateHoldAtLocation(holdAtLocation) {
            set({
              holdAtLocation,
            })
          },
          updateNotifications(notifications) {
            set({ notifications })
          },
          updateTags(tags) {
            set({ tags })
          },
          undoChanges: () =>
            set(prev => {
              return {
                ...buildFormData(load, addresses),
                itemState: {
                  ...prev.itemState,
                  items: buildItemsForStore(load.items, handlingUnits ?? []),
                },
              }
            }),
        },
      }
    },
  )
}

function buildFormData(
  load: FullShipment,
  addresses?: ClientAddressLookupResult[],
): BookingFormState {
  const locations: LocationWithFollowers[] = load.locations.map(x => {
    const formLocation = toFormLocation(x, load, addresses)

    if (load.status === 'pending' || load.pickup?.errorType === 'validation') {
      formLocation.stopDate = defaultStopDate(
        formLocation.type,
        formLocation.stopDate,
      )
    }
    return formLocation
  })

  const rateLocationType: QuoteStopType =
    requireRateLocation(load.locations, !!load.isFreightCollect) &&
    load.direction === 'inbound'
      ? 'destination'
      : 'origin'

  return {
    billTo: billToMapper.dtoToForm(load.billTo),
    customsBroker: customsBrokerMapper.dtoToForm(load.customsBroker?.[0]),
    deliveryOption: load.deliveryOption,
    emergencyContact: emergencyContactMapper.dtoToForm(load.emergencyContact),
    equipmentAccessorials: (load.equipment.accessorials ?? []).map(a => a.key),
    isEdit: load.status !== 'pending' && load.pickup?.status !== 'error',
    isNew: false,
    isFreightCollect: load.isFreightCollect ?? false,
    loadId: load.loadId,
    shareEmails: '',
    locations,
    rateLocationType,
    workflow: load.workflow,
    bolNumber: load.bol?.bolNumber ?? '',
    tags: load.tags?.map(t => t.name ?? '')?.filter(v => !!v) ?? [],
    ...(load.isFreightCollect && load.selectedQuote
      ? {
          customerRoutedDetail: {
            accountNum: load.billTo?.accountNumber ?? '',
            postalCode: load.billTo?.postalCode,
            carrier: {
              code: load.selectedQuote.providerCode,
              id: load.selectedQuote.carrierId,
              name: load.selectedQuote.providerName,
              status: 'pending',
            },
            service: load.selectedQuote.serviceId || '',
            terms:
              load.selectedQuote.paymentTerms === 'third-party-prepaid'
                ? 'third-party'
                : 'outbound-collect',
          },
        }
      : {
          customerRoutedDetail: {
            accountNum: '',
            postalCode: '',
            carrier: null,
            service: '',
            terms: 'outbound-collect',
          },
        }),
  }
}

type BookingFormStore = ReturnType<typeof createBookingFormStore>
const BookingFormContext = createContext<BookingFormStore | null>(null)
type BookingFormProviderProps = PropsWithChildren<
  PropsWithLoad<{
    addresses: ClientAddressLookupResult[]
    handlingUnits: LegacyHandlingUnitDTO[]
    quote?: UIQuote
    isNew?: boolean
  }>
>

export const BookingFormProvider = ({
  addresses,
  children,
  handlingUnits,
  isNew,
  load,
  quote,
}: BookingFormProviderProps) => {
  const storeRef = useRef<BookingFormStore>()

  if (!storeRef.current) {
    storeRef.current = createBookingFormStore({
      addresses,
      handlingUnits,
      isNew: isNew ?? false,
      load,
      quote,
    })
  }

  return (
    <BookingFormContext.Provider value={storeRef.current}>
      {children}
    </BookingFormContext.Provider>
  )
}

export function useBookingFormsStore() {
  const store = useContext(BookingFormContext)

  if (!store) {
    throw new Error('Missing BookingFormContext.Provider in the tree')
  }

  return useStore(store)
}

export function useBookingFormCtx<T>(
  selector: (
    state: BookingFormActions & BookingFormState & LoadItemsStore,
  ) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T {
  const store = useContext(BookingFormContext)

  if (!store) {
    throw new Error('Missing BookingFormContext.Provider in the tree')
  }

  return useStore(store, selector, equalityFn)
}

export function useBookingFormLoad() {
  const loadId = useBookingFormCtx(s => s.loadId)
  const isNew = useBookingFormCtx(s => s.isNew)
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const initialLoad = useBookingFormCtx(s => s.initialLoad)
  const load = useLoad(isNew ? undefined : loadId, {
    refetchWhileQuoting: true,
  })

  if (isNew && isFreightCollect) {
    return initialLoad
  }

  return load.data
}
