import { useState } from 'react'

import { FvButton } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { ValidatedForm } from '../../components/inputs'
import { supportMessage } from '../../constants'
import { useSpotQuoteRecipients } from '../../hooks/spotQuotes'
import { useSpotQuoteGroups } from '../spot-quote-contacts/queries'
import {
  ALL_CONTACTS_ID,
  type SpotQuoteContact,
} from '../spot-quote-contacts/types'
import PanelNav from './PanelNav'
import { SentQuoteRequestsHeader } from './SentQuoteRequestsHeader'
import { SpotQuoteContactListing } from './SpotQuoteContactListing'
import { useSpotQuoteListActions, useSpotQuoteListSelected } from './store'
import { useSubmitForm } from './useSubmitForm'

type Props = {
  closePanel: () => void
  load: FullShipment
}

export const SpotQuoteRequestPanel = ({ closePanel, load }: Props) => {
  const [isViewingSentList, setViewingSentList] = useState(false)
  const groupsQuery = useSpotQuoteGroups()
  const recipientsQuery = useSpotQuoteRecipients(load.loadId)
  const emails = useSpotQuoteListSelected()
  const { setEmails, setGroupId } = useSpotQuoteListActions()

  const isLoading =
    groupsQuery.isLoading ||
    (recipientsQuery.isLoading && recipientsQuery.isFetching)

  const hasError =
    (!groupsQuery.data && groupsQuery.isError) ||
    (!recipientsQuery.data && recipientsQuery.isError)

  const { isSending, isSent, onSubmit } = useSubmitForm({
    load,
  })
  const handleSubmit = async () => {
    await onSubmit(emails)
  }
  const handleClose = () => {
    setEmails([])
    setGroupId(ALL_CONTACTS_ID)
    closePanel()
  }

  if (isSent) {
    return (
      <div className="shipment-list-ancillary-details-ctn">
        <div className="shipment-list-ancillary-details overflow-auto">
          <SentQuoteRequestsHeader emails={emails} />

          <FvButton
            theme="primary"
            className="self-start"
            icon="times"
            onClick={handleClose}
          >
            Close
          </FvButton>
        </div>
      </div>
    )
  }

  const recipientEmails = recipientsQuery.data?.map(r => r.email) ?? []
  const filter = (u: SpotQuoteContact) => {
    const includes = recipientEmails.includes(u.email)
    return isViewingSentList ? includes : !includes
  }

  const footer = (
    <ValidatedForm
      onValidSubmit={handleSubmit}
      className="shipment-list-ancillary-details__footer"
    >
      <FvButton
        theme="primary"
        type="submit"
        icon={isSending ? 'spinner' : 'paper-plane'}
      >
        <span>Send out for quotes</span>
      </FvButton>
      <FvButton theme="plain" onClick={handleClose} type="button" icon="times">
        Cancel
      </FvButton>
    </ValidatedForm>
  )

  return (
    <div className="shipment-list-ancillary-details-ctn flex">
      <PanelNav
        closePanel={handleClose}
        isViewingSentList={isViewingSentList}
        setViewingSentList={setViewingSentList}
      />
      {isLoading && <InfoBox icon="spinner">Loading...</InfoBox>}
      {hasError && (
        <InfoBox icon="triangle">
          Unable to load contacts, {supportMessage}
        </InfoBox>
      )}
      <SpotQuoteContactListing
        headerClassName="px-8 pt-4"
        listingClassName="px-8 overflow-auto"
        mode={isViewingSentList ? 'recipients' : 'send'}
        footerComponent={footer}
        filterUsers={filter}
      />
    </div>
  )
}
