import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

import { FvLinkButton, type IconName } from '@fv/client-components'

import { routes } from '../constants'
import { HasFeature } from '../features/auth'

type Props = {
  icon: IconName
  label: string
  path: string
  redirectToLegacy?: boolean
}

type ModeNavProps = {
  className?: string
}

const ModeLink = ({ icon, label, path, redirectToLegacy }: Props) => {
  const { pathname } = useLocation()

  return (
    <FvLinkButton
      className={clsx(
        'border-fv-blue-300 b1350:px-4 b1350:py-2 b1350:mb-0 b1350:mx-2 relative z-20 mx-4 mb-3 block rounded-lg border p-4',
        {
          'after:border-fv-blue-300 b1350:after:right-1/2 b1350:after:translate-x-1/2  b1350:after:-rotate-45 b1350:after:bg-fv-blue-75 b1350:after:w-2 b1350:after:h-2 b1350:after:top-full bg-fv-beer after:absolute after:-right-3 after:top-2/4 after:z-10 after:h-3 after:w-3 after:-translate-y-1/2 after:translate-x-[calc(-50%_+_1px)] after:rotate-45 after:border after:border-b-0 after:border-l-0 after:bg-fv-beer':
            !redirectToLegacy && pathname.startsWith(path),
        },
        { 'bg-white': !pathname.startsWith(path) },
      )}
      fw
      href={redirectToLegacy ? path : undefined}
      icon={icon}
      to={redirectToLegacy ? undefined : path}
    >
      {label}
    </FvLinkButton>
  )
}

export const ModeNav = ({ className }: ModeNavProps) => {
  return (
    <nav
      className={`${className} mode-nav bg-fv-blue-75 b1350:pt-5 b1350:pb-3 b1350:border-r-0 b1350:border-b b1350:px-4 b1350:flex overflow-auto border-r border-gray-300 py-8`}
    >
      <HasFeature name="parcel">
        <ModeLink icon="box" label="Parcel" path={routes.quote('parcel')} />
      </HasFeature>
      <HasFeature name="ltl">
        <ModeLink icon="truck" label="LTL" path={routes.quote('ltl')} />
      </HasFeature>
      <HasFeature name="truckLoad">
        <ModeLink
          icon="truck-moving"
          label="Truckload"
          path={routes.quote('truckload')}
        />
      </HasFeature>
    </nav>
  )
}
