import { cleanPhoneNumber } from '@freightview/string-helpers'
import { findPhoneNumbersInText } from 'libphonenumber-js/min'

export function capitalizeFirstLetter(s: string) {
  if (!s) {
    return s
  }

  return s.toString().charAt(0).toUpperCase() + s.slice(1)
}

export function addPeriod(sentence: string) {
  if (!sentence) {
    return sentence
  }

  if (!sentence.trim().endsWith('.')) {
    return sentence.trim() + '.'
  }

  return sentence.trim()
}

export function cleanDispositionFilename(val: string) {
  return val?.replace(/[^A-z0-9.]/gi, '')
}

export function toOxfordComma(array: string[]): string {
  if (!array) {
    return ''
  }

  if (array.length === 2) {
    return array.join(' and ')
  }

  if (array.length > 2) {
    return array
      .slice(0, array.length - 1)
      .concat(`and ${array.slice(-1)}`)
      .join(', ')
  }

  return array.join(', ')
}

export const cleanEmail = (email: string) => email.toLowerCase().trim()

const findPhoneNumber = (value?: string) => {
  if (!value) {
    return undefined
  }

  const pn = findPhoneNumbersInText(value, { defaultCountry: 'US' })

  return pn[0]
}

const phoneFirstTen = (value: string) => {
  if (!value) {
    return value
  }

  let cleaned = cleanPhoneNumber(value)

  if (cleaned.length > 10 && cleaned[0] === '1') {
    cleaned = cleaned.slice(1, 11)
  }

  return cleaned.slice(0, 10)
}

export const parsePhone = (value: string) => {
  const phone = findPhoneNumber(value)

  if (phone) {
    if (phone.number.countryCallingCode === '1') {
      return cleanPhoneNumber(phone.number.nationalNumber)
    }
    return phone.number.number
  }

  return phoneFirstTen(value)
}

export const formatPhone = (value: string) => {
  if (!value) return

  const parsed = findPhoneNumber(value)

  if (parsed) {
    return parsed.number.countryCallingCode === '1'
      ? parsed.number.formatNational()
      : parsed.number.formatInternational()
  }

  const nums = phoneFirstTen(value)

  if (!nums?.length) return
  return `(${nums.substring(0, 3)}) ${nums.substring(3, 6)}-${nums.substring(
    6,
  )}`
}

export const replaceDiacritics = (str: string | undefined) =>
  str?.normalize('NFD').replace(/\p{Diacritic}/gu, '')

export const equalsIgnoreDiacritics = (val1: string, val2: string) => {
  return replaceDiacritics(val1) === replaceDiacritics(val2)
}
