import upperFirst from 'lodash/upperFirst'
import pluralize from 'pluralize'
import { type MutableRefObject } from 'react'
import { type SelectInstance } from 'react-select'

import { type PackagingType, type ProductCatalogEntry } from '@fv/client-types'

import { TypeaheadField } from '../../components/inputs/TypeaheadField'
import { useTypeNames } from '../../hooks/settings'
import { getDimensions } from './commodityUtils'
import { useProducts } from './useHandlingUnits'
import { type ProductCatalogFilter, type SetProduct } from './useProductCatalog'

type Props = {
  readOnly?: boolean
  hasDropdownIcon?: boolean
  filter: ProductCatalogFilter
  name: string
  setProduct: SetProduct
  value: string
  onInputChange: (value: string) => void
  searchRef?: MutableRefObject<SelectInstance | null>
  required?: boolean
}

export const ProductCatalogTypeahead = ({
  readOnly,
  hasDropdownIcon,
  filter,
  onInputChange,
  searchRef,
  setProduct,
  value,
  required,
}: Props) => {
  const { packageName } = useTypeNames()
  const productsQuery = useProducts(true, filter, value)
  const productOptions = productsQuery.data.map(value => ({
    label: composeProductLabel(value, packageName),
    value,
  }))

  return (
    <TypeaheadField
      blurInputOnSelect
      isClearable={false}
      isLoading={productsQuery.isFetching}
      name="productSearch"
      onChange={product => {
        if (!product) return
        setProduct(product)
      }}
      onInputChange={onInputChange}
      options={productOptions}
      reactSelectRef={searchRef}
      value={value}
      required={required}
      hasDropdownIcon={hasDropdownIcon ?? true}
      readOnly={readOnly}
    />
  )
}

function composeProductLabel(
  product: ProductCatalogEntry,
  getPackageName: (key: PackagingType) => string,
) {
  const {
    description,
    freightClass,
    hazardous,
    nmfc,
    pieces,
    saidToContain,
    saidToContainPackagingType,
    stackable,
    weight,
  } = product

  const dimensions = getDimensions(product)
  let label = description.trim()

  if (product.package) {
    const pkgName = getPackageName(product.package)
    label += ` - ${pluralize(pkgName, 2)}`
  }

  if (dimensions) label += ` - ${dimensions}`
  if (pieces) label += ` - ${pieces} pieces`
  if (weight) label += ` - ${weight} lbs`
  if (freightClass) label += ` - Class ${freightClass}`
  if (nmfc) label += ` - NMFC# ${nmfc}`

  if (saidToContain || saidToContainPackagingType) {
    const quantity = Number(saidToContain || 2)
    const packageType = pluralize(saidToContainPackagingType, quantity)
    const contains = `${saidToContain} ${upperFirst(packageType)}`.trim()
    label += ` - Contains ${contains}`
  }

  if (stackable === 'Yes') label += ' - Stackable'
  if (hazardous === 'Yes') label += ' - Hazardous'

  return label
}
