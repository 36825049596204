import { useState } from 'react'
import { type ActionMeta, type OnChangeValue } from 'react-select'

import { FvButton } from '@fv/client-components'

import {
  InputAdornment,
  InputGroup,
} from '../../../components/inputs/InputGroup'
import { useUserSettings } from '../../../hooks/settings'
import { TagSelector } from '../../tags/ShipmentTagsControl'
import { PageHeader } from '../PageHeader'
import { useTagMutations } from './useTagMutations'

export type AccountTagOption = {
  label: string
  value: string
}

export const TagManagement = () => {
  const settingsQuery = useUserSettings()
  const tags = settingsQuery?.data?.tags
  const [tagsField, setTagField] = useState('')

  const { addTag, isAdding, isRemoving, removeTag } = useTagMutations()

  const onChange = (
    v: OnChangeValue<AccountTagOption, true>,
    meta: ActionMeta<AccountTagOption>,
  ) => {
    const { action, option, removedValue } = meta
    if (
      (action === 'create-option' || action === 'select-option') &&
      !!option
    ) {
      return addTag(option)
    }

    if (action === 'remove-value') {
      if (
        window.confirm(
          'Are you sure you wish to remove this tag from the account?',
        )
      ) {
        return removeTag(removedValue)
      }
    }
    return
  }

  const handleAddTag = async () => {
    const tagOption: AccountTagOption = { label: tagsField, value: tagsField }
    await addTag(tagOption)
    setTagField('')
  }

  return (
    <div className="settings-main">
      <PageHeader title="Shipment Tags" noBorder />
      <div className="input-group mb-8">
        <InputGroup
          className="mb-0 w-full"
          inputType="text"
          label=""
          inputProps={{
            onChange: e => {
              setTagField(e.target.value)
            },
            value: tagsField,
            name: 'tag',
          }}
          endContent={
            <InputAdornment position="end">
              <FvButton
                loading={isAdding}
                icon={isAdding ? 'spinner' : 'plus'}
                type="submit"
                onClick={handleAddTag}
                theme="default"
              >
                Add Tag
              </FvButton>
            </InputAdornment>
          }
        />
        <div className="input-group__append" />
      </div>
      <div>
        <PageHeader title="Current Tags" noBorder />

        <TagSelector
          isLoading={isAdding || isRemoving}
          tagNames={tags?.map(v => v.label)}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
