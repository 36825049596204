import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { supportMessage } from '../../../constants'
import { shipperFetch } from '../../../utils/shipperFetch'
import { type AccountTagOption } from './TagManagement'

type AccountTag = {
  _id: string
  name: string
}

type TagRequest = {
  tag: string
}

type TagResponse = {
  accountTags: AccountTag[]
}

const postTag = async (tag: TagRequest): Promise<TagResponse> => {
  return await shipperFetch('/account-tags/', {
    body: tag,
    method: 'POST',
  })
}

const removeTag = async ({ tag }: TagRequest): Promise<TagResponse> => {
  return await shipperFetch(`/account-tags/${tag}`, {
    method: 'DELETE',
  })
}

export const useTagMutations = () => {
  const removeMutation = useRemoveMutation()
  const addMutation = useAddMutation()
  const cleanTag = (tag: AccountTagOption): AccountTagOption => {
    const { label, value } = tag
    return {
      label: label.trim(),
      value,
    }
  }
  return {
    removeTag: async (tag: AccountTagOption): Promise<TagResponse> => {
      return await removeMutation.mutateAsync(cleanTag(tag))
    },
    addTag: async (tag: AccountTagOption): Promise<TagResponse> => {
      return await addMutation.mutateAsync(cleanTag(tag))
    },
    isAdding: addMutation.isLoading,
    isRemoving: removeMutation.isLoading,
  }
}
const useRemoveMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (option: AccountTagOption) => removeTag({ tag: option.value }),
    {
      onSettled() {
        queryClient.invalidateQueries(['user-settings'])
      },
      onError() {
        toast.error(`Unable to remove tag, ${supportMessage}`)
      },
    },
  )
}

const useAddMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (option: AccountTagOption) => postTag({ tag: option.label }),
    {
      onSettled() {
        queryClient.invalidateQueries(['user-settings'])
      },
      onError() {
        toast.error(`Unable to add tag, ${supportMessage}`)
      },
    },
  )
}
