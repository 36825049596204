import { useQuery } from '@tanstack/react-query'

import { shipperFetch } from '../../utils/shipperFetch'
import { type AccountUserResponseDto } from './types'

export const accountUserKeys = {
  all: ['account-users'],
}

export const useAccountUsers = (enabled = true) => {
  return useQuery(
    accountUserKeys.all,
    () => shipperFetch<AccountUserResponseDto[]>(`/account-users`),
    {
      enabled,
    },
  )
}
