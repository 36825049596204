import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'

interface RevertRerateArgs {
  loadId?: string
}
async function revertRerate({
  loadId,
}: RevertRerateArgs): Promise<FullShipment> {
  if (!loadId) throw new Error('`loadId` required to revert rerate.')

  const endpoint = `${apiUri}/shipments/${loadId}/quotes/revert-rerate`
  const options = buildFetchOptionsWithAuth({ method: 'POST' })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json as FullShipment
  throw response.errorMessage
}

export function useRevertRerate() {
  const updateCachedLoad = useUpdateCachedLoad()
  return useMutation(revertRerate, {
    onSettled(updatedLoad, error) {
      if (error) {
        toast.error(
          'There was an error going back to the previous version of your shipment',
        )
        return
      }

      if (updatedLoad) updateCachedLoad({ updatedLoad })
    },
  })
}
