import { useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, Icon, TextField, ValidatedForm } from '@fv/client-components'
import { type PropsWithLoad } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { supportMessage } from '../../constants'
import { useConfirmLoad } from '../../hooks/shipments'

type Props = PropsWithLoad<{
  closePanel: () => void
}>

export const ConfirmationPanel = ({ closePanel, load }: Props) => {
  const [confirmationNum, setConfirmationNum] = useState('')
  const confirmLoad = useConfirmLoad()

  function onValidSubmit() {
    if (confirmLoad.isLoading) return

    confirmLoad
      .mutateAsync({
        confirmationNum,
        loadId: load.loadId,
      })
      .then(closePanel)
      .catch(() => {
        confirmLoad.reset()
        toast.error(`Unable to confirm load, ${supportMessage}`)
      })
  }

  return (
    <>
      <InfoBox>
        Generally, your carrier will confirm this online for you. However, if
        you your carrier has confirmed it via any other means you can confirm it
        yourself instead of waiting on them to take action.
      </InfoBox>

      <ValidatedForm onValidSubmit={onValidSubmit} className="flex gap-2">
        <InputGroup
          className="mb-0 flex-1"
          inputType="text"
          inputProps={{
            onChange: e => setConfirmationNum(e.target.value),
            name: 'confirmationNum',
            placeholder: 'Confirmation#',
          }}
          endContent={
            <InputAdornment>
              <FvButton
                theme="primary"
                type="submit"
                icon={confirmLoad.isLoading ? 'spinner' : 'check'}
              >
                <span>Confirm</span>
              </FvButton>
            </InputAdornment>
          }
        />
        <FvButton
          theme="default"
          icon="times"
          onClick={closePanel}
          type="button"
        >
          <span>Cancel</span>
        </FvButton>
      </ValidatedForm>
    </>
  )
}
