import {
  type MutationFunction,
  useMutation,
  type UseMutationOptions,
} from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { showApiError } from '@fv/client-core'

type Options<TData, TError, TVariables, TContext> = UseMutationOptions<
  TData,
  TError,
  TVariables,
  TContext
> & {
  toastErrorPrefix?: (
    data: TData | undefined,
    error: TError | null,
    vars: TVariables | undefined,
    context: TContext | undefined,
  ) => string
  toastSuccess?: (
    data: TData | undefined,
    error: TError | null,
    vars: TVariables | undefined,
    context: TContext | undefined,
  ) => string
}
export function useToastedMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<Options<TData, TError, TVariables, TContext>, 'mutationFn'>,
) {
  return useMutation(mutationFn, {
    ...options,
    onSettled(...args) {
      const [, error] = args
      if (error && options?.toastErrorPrefix) {
        showApiError(options.toastErrorPrefix.apply(null, args), error)
      } else if (!error && options?.toastSuccess) {
        toast.success(options.toastSuccess.apply(null, args))
      }
      options?.onSettled?.apply(null, args)
    },
  })
}
