import { MessagesNotesTagsControls } from '../../components/list/MessagesNotesTagsControls'
import { CancelShipmentPrompt } from '../../components/shared/CancelShipmentPrompt'
import { ConfirmationPanel } from '../../features/confirm/ConfirmationPanel'
import { ShipmentLabels } from '../../features/labels/ShipmentLabels'
import { useCancelLoad } from '../../hooks/shipments'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { DetailsDocumentPanel } from './DetailsDocumentPanel'
import { DetailsQuotePanel } from './DetailsQuotePanel'
import { DetailsSharePanel } from './DetailsSharePanel'
import { SliderContentHeader, SliderContentLayout } from './SliderContentLayout'
import { TrackingPanel } from './TrackingPanel'
import { type ShipmentContentProps, type SliderName } from './types'

export const CancelPanel = ({
  setActiveSlider,
  load,
}: ShipmentContentProps) => {
  const cancelLoad = useCancelLoad(load.loadId)
  const { carrierPublicSettings } = useCarrierPublicSettings(
    load.selectedQuote?.carrierId,
  )
  const closePanel = () => setActiveSlider(undefined)

  return (
    <SliderContentLayout>
      <SliderContentHeader {...load} prefix="Cancel Pickup for " />
      <CancelShipmentPrompt
        loading={cancelLoad.isLoading}
        isAutoCancel={
          carrierPublicSettings?.cancelMethod === 'auto' ||
          carrierPublicSettings?.cancelMethod === 'email'
        }
        onDecline={closePanel}
        onConfirm={() => {
          cancelLoad.mutate(false, {
            onSuccess: closePanel,
          })
        }}
      />
    </SliderContentLayout>
  )
}

export const MessagesPanel = ({ load }: ShipmentContentProps) => {
  return (
    <SliderContentLayout>
      <SliderContentHeader {...load} />
      <MessagesNotesTagsControls
        className="flex flex-1 flex-col items-start"
        contentClass="flex-1 w-full"
        load={load}
        navClass="mb-6"
      />
    </SliderContentLayout>
  )
}

export type SliderPanelComponent = (
  p: ShipmentContentProps & { closePanel: () => void },
) => JSX.Element
export const sliderTabs: Record<SliderName, SliderPanelComponent> = {
  cancel: CancelPanel,
  confirm: ConfirmationPanel,
  documents: DetailsDocumentPanel,
  labels: ShipmentLabels,
  messages: MessagesPanel,
  quotes: DetailsQuotePanel,
  share: DetailsSharePanel,
  track: TrackingPanel,
}
