import { type FullShipment } from '@fv/client-types'

import { useShareShipmentForm } from './mutations'
import ShareForm from './ShareForm'
import SharePanelHeader from './SharePanelHeader'
import { SharePanelMain } from './SharePanelMain'

type SharePanelProps = {
  closePanel: () => void
  load: Pick<FullShipment, 'documents' | 'loadId' | 'shares' | 'workflow'>
}

export const SharePanel = ({ closePanel, load }: SharePanelProps) => {
  const { loadId, documents = [], shares = [] } = load
  const form = useShareShipmentForm({
    loadId,
    documents,
  })

  return (
    <div className="shipment-list-ancillary-details-ctn">
      <SharePanelHeader closePanel={closePanel} />

      <SharePanelMain
        workflow={load.workflow}
        shares={shares}
        form={form}
        className="shipment-list-ancillary-details"
      />

      <div className="shipment-list-ancillary-details__footer">
        <ShareForm
          form={form}
          shareShipment={emails => {
            form.submitShare(emails, {
              onSuccess: closePanel,
            })
          }}
        />
      </div>
    </div>
  )
}
