export const legacyPackingGroups = [
  'I-Great Danger',
  'II-Medium Danger',
  'III-Minor Danger',
] as const

export const packingGroups = [
  {
    key: '1',
    name: 'I',
  },
  {
    key: '2',
    name: 'II',
  },
  {
    key: '3',
    name: 'III',
  },
] as const

export type LegacyPackingGroup = (typeof legacyPackingGroups)[number]
export type PackingGroup = (typeof packingGroups)[number]['key']

export const parseLegacyPackingGroup = (group: string): LegacyPackingGroup => {
  if (group?.match(/3|iii/i)) {
    return 'III-Minor Danger'
  }

  if (group?.match(/2|ii/i)) {
    return 'II-Medium Danger'
  }

  if (group?.match(/3|i/i)) {
    return 'I-Great Danger'
  }

  return undefined
}

export const parsePackingGroup = (group: string): PackingGroup => {
  if (group?.match(/3|iii/i)) {
    return '3'
  }

  if (group?.match(/2|ii/i)) {
    return '2'
  }

  if (group?.match(/3|i/i)) {
    return '1'
  }

  return undefined
}
