import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  type AccountLocation,
  type AccountLocationUpsertModel,
} from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { addressKeys } from '../addresses/useAddress'

const addAccountLocation = ({ body }: { body: AccountLocationUpsertModel }) =>
  shipperFetch<AccountLocation>(`/locations`, {
    body,
    method: 'POST',
  })

interface AddOpts {
  notify: boolean
  refresh: boolean
}
export function useAddAccountLocation(
  opts: AddOpts = { notify: true, refresh: true },
) {
  const queryClient = useQueryClient()
  return useMutation(addAccountLocation, {
    onSuccess() {
      opts.notify && toast.success('Created location')
      opts.refresh &&
        queryClient.invalidateQueries(addressKeys.accountLocations())
    },
    onError() {
      opts.notify && toast.error('Error creating location')
    },
  })
}

const removeAccountLocation = ({ locationId }: { locationId: string }) =>
  shipperFetch(`/locations/${locationId}`, { method: 'DELETE' })

export function useRemoveAccountLocation() {
  const queryClient = useQueryClient()
  return useMutation(removeAccountLocation, {
    onSuccess() {
      toast.success('Removed company location')
      queryClient.invalidateQueries(addressKeys.accountLocations())
    },
    onError() {
      toast.error('Error removing location')
    },
  })
}
