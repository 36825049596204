import LogRocket from 'logrocket'
import { useEffect } from 'react'

import { adminUri } from '@fv/client-core'

import { useOlarkStore } from './useOlarkStore'

export interface OlarkOperatorCommandEvent {
  command: { name: string }
}

export interface OlarkInfo {
  carrierId?: string
  company?: string
  email?: string
  linkLabel: string
  name?: string
  phoneNumber?: string
  userId?: string
  viewAsLink: string
}

export const useOlarkHooks = ({
  carrierId,
  company,
  email,
  linkLabel,
  name,
  phoneNumber,
  userId,
  viewAsLink,
}: OlarkInfo) => {
  const loadId = useOlarkStore(s => s.loadId)

  useEffect(() => {
    window.fvOlarkHooks.onBeginConversation = () => {
      if (!window.olark || !userId) return

      window.olark('api.visitor.updateCustomFields', {
        internalCustomerId: userId,
        ...(carrierId && { carrierId }),
        ...(company && { companyName: company }),
      })

      window.olark('api.visitor.updateEmailAddress', {
        emailAddress: email,
      })

      if (name) {
        window.olark('api.visitor.updateFullName', {
          fullName: name,
        })
      }

      if (phoneNumber) {
        window.olark('api.visitor.updatePhoneNumber', {
          phoneNumber,
        })
      }

      window.olark('api.chat.updateVisitorStatus', {
        snippet: viewAsLink,
      })

      window.olark('api.chat.sendNotificationToOperator', {
        body: `${linkLabel}: ${viewAsLink}`,
      })
    }
  }, [
    userId,
    carrierId,
    company,
    email,
    name,
    phoneNumber,
    viewAsLink,
    linkLabel,
  ])

  useEffect(() => {
    window.fvOlarkHooks.onOperatorCommand = (
      event: OlarkOperatorCommandEvent,
    ) => {
      if (event.command.name === 'load') {
        window.olark?.('api.chat.sendNotificationToOperator', {
          body: loadId
            ? `Admin load link: ${adminUri}/loads/${loadId}`
            : `User isn't currently viewing a load`,
        })
      }

      if (event.command.name === 'session') {
        LogRocket.getSessionURL(sessionURL => {
          if (!window.olark) return

          window.olark('api.chat.sendNotificationToOperator', {
            body: sessionURL
              ? `Logrocket session: ${sessionURL}`
              : 'No session available',
          })
        })
      }
    }
  }, [loadId])
}
