import { create } from 'zustand'

import { type AddressBookState, type AddressBookStore } from './types'

const initialState: AddressBookState = {
  editingId: undefined,
  isOpen: false,
  config: {},
  searchText: '',
  setAddress: undefined,
  view: 'list',
}

export const useAddressBook = create<AddressBookStore>((set, get) => ({
  ...initialState,
  close: () =>
    set({
      isOpen: false,
      editingId: undefined,
      view: 'list',
      setAddress: undefined,
    }),
  edit: editingId => {
    set({
      editingId,
      view: !editingId ? 'list' : 'form',
    })
  },
  open: (setAddress, config) => {
    set({
      isOpen: true,
      config,
      setAddress,
    })
  },
  searchInput: e => set({ searchText: e.target.value }),
  setConfig: config => set({ config }),
  setSearch: searchText => set({ searchText }),
  selectAddress: address => {
    const { setAddress, close } = get()
    if (setAddress) {
      // using setAddress as an indicator if we're on the address book page
      setAddress(address)
      set({ searchText: '' })
    }
    close()
  },
  showForm: () => {
    set({ view: 'form' })
  },
}))
