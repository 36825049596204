import { useEffect, useRef, useState } from 'react'

import { ValidatedForm } from '@fv/client-components'
import { type ScheduledReportDTO } from '@fv/models'

import { FormActions } from '../../components/forms/FormActions'
import InfoBox from '../../components/InfoBox'
import { List } from '../../components/List'
import {
  SliderPanelFooter,
  SliderPanelLayout,
} from '../../components/SliderPanelLayout'
import { useCurrentUser } from '../../hooks/settings/useAppContext'
import { getFilterQueryString } from '../../types/SavedFilter'
import { useFilterControls } from '../shipment-filters/hooks'
import { useUpsertScheduledReports } from './mutations'
import {
  ScheduledReportConfig,
  type ScheduledReportConfigHandle,
} from './ScheduledReportConfig'

const scheduledReportDefaults: ScheduledReportDTO = {
  enabled: false,
  frequency: 'daily',
  time: '06:00',
  type: 'basic',
  recipients: [],
}
interface ModelWrapper {
  filterId: string
  model?: ScheduledReportDTO
}
type Props = {
  onClose: () => void
}
export const ScheduledReportsPanel = ({ onClose }: Props) => {
  const configs = useRef<{ [k: string]: ScheduledReportConfigHandle }>({})
  const { filters, activeFilter } = useFilterControls()
  const user = useCurrentUser()
  const listRef = useRef<HTMLDivElement>(null)
  const upsertScheduledReports = useUpsertScheduledReports()
  const [active, setActive] = useState<string>(activeFilter._id)
  const [models, setModels] = useState<ModelWrapper[]>(
    filters
      .filter(f => !f.isLocked)
      .map(f => {
        return {
          filterId: f._id,
          model:
            active === f._id && !f.scheduledReports
              ? {
                  ...scheduledReportDefaults,
                  enabled: true,
                  recipients: [user.email],
                }
              : f.scheduledReports?.[0],
        }
      }),
  )

  const updateModel = (id: string, updates: Partial<ScheduledReportDTO>) => {
    setModels(prev =>
      prev.map(p =>
        p.filterId !== id
          ? p
          : {
              filterId: p.filterId,
              model: {
                ...p.model,
                ...updates,
              },
            },
      ),
    )
  }

  useEffect(() => {
    if (activeFilter) {
      setTimeout(() => {
        // timeout to wait for panel to open
        const el = listRef.current?.querySelector(
          `[data-filterid="${activeFilter._id}"]`,
        )
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 500)
    }
  }, [activeFilter])

  const handleActivate = (model: ModelWrapper) => {
    setModels(prev =>
      prev.map(prevModel => {
        if (prevModel.filterId === model.filterId && !model.model) {
          return {
            filterId: prevModel.filterId,
            model: {
              ...scheduledReportDefaults,
              enabled: true,
              recipients: [user.email],
            },
          }
        } else if (
          prevModel.filterId === active &&
          !prevModel.model?.recipients.length
        ) {
          return {
            filterId: prevModel.filterId,
            model: undefined,
          }
        }

        return prevModel
      }),
    )
    setActive(model.filterId)
  }

  const handleSubmit = async () => {
    let recipOverride: string[] = []
    if (active) {
      const validateResult = configs.current[active]?.validate()
      if (!validateResult?.success || !validateResult.value?.length) {
        return
      }
      recipOverride = validateResult.value
    }
    await upsertScheduledReports.mutateAsync(
      models
        .filter(m => !!m.model)
        .map(({ filterId, model }) => ({
          model,
          filter: filters.find(f => f._id === filterId),
        }))
        .map(({ model, filter }) => ({
          ...(filter.isSystemFilter && {
            systemFilter: {
              query: getFilterQueryString(filter),
              name: filter.name,
              filterId: filter._id,
            },
          }),
          ...(!filter.isSystemFilter && {
            savedViewId: filter._id,
          }),
          scheduledReport: {
            ...model,
            ...(filter._id === active && {
              recipients: recipOverride,
            }),
          },
        })),
    )
    onClose()
  }

  return (
    <SliderPanelLayout title="Schedule reports to be automatically sent via email">
      <InfoBox>
        Please configure the settings below to determine the frequency of your
        scheduled reports. The data in the report will be determined based on
        the filters applied to the saved view.
      </InfoBox>
      <ValidatedForm
        className="flex flex-col h-full"
        onValidSubmit={handleSubmit}
      >
        <div ref={listRef}>
          <List>
            {models.map(m => (
              <div data-filterid={m.filterId} key={m.filterId}>
                <ScheduledReportConfig
                  filter={filters.find(f => f._id === m.filterId)}
                  value={m.model}
                  ref={r => (configs.current[m.filterId] = r)}
                  onChange={value => updateModel(m.filterId, value)}
                  isActive={active === m.filterId}
                  onActivate={() => handleActivate(m)}
                  onDeactivate={() => setActive(undefined)}
                />
              </div>
            ))}
          </List>
        </div>
        <SliderPanelFooter>
          <FormActions
            jstart
            loading={upsertScheduledReports.isLoading}
            submitText="Save scheduled reports"
            onCancel={onClose}
          />
        </SliderPanelFooter>
      </ValidatedForm>
    </SliderPanelLayout>
  )
}
