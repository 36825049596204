import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useRemoveFromList, useUpdateCachedLoad } from '.'

async function confirmLoad({ confirmationNum, loadId }) {
  const endpoint = `${apiUri}/shipments/${loadId}/confirm`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      pickupConfirmationNumber: confirmationNum.trim(),
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json // updatedLoad
  throw response.errorMessage
}

export function useConfirmLoad() {
  const removeFromList = useRemoveFromList()
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation(confirmLoad, {
    onSuccess: shipment => {
      removeFromList({
        loadId: shipment.loadId,
        queryFilter: ['shipments', 'inProcess'],
      })

      updateCachedLoad({ updatedLoad: shipment })
      toast.success('Shipment confirmed successfully.')
    },
  })
}
