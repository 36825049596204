import { type PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getToken } from '@fv/client-core'

import { useAccountLocations } from '../../features/addresses/useAddress'
import {
  useAppContext,
  useAppSettings,
  useUserSettings,
} from '../../hooks/settings'
import InfoBox from '../InfoBox'

const style = { margin: '2rem auto', maxWidth: '11rem' }

type Props = PropsWithChildren<{
  sessionRedirect: string
}>
export const SessionGate = ({ children, sessionRedirect }: Props) => {
  const location = useLocation()
  const sessionToken = getToken()
  const appSettingsQuery = useAppSettings(!!sessionToken)
  const contextQuery = useAppContext(!!sessionToken)
  const userSettingsQuery = useUserSettings(!!sessionToken)
  const locationsQuery = useAccountLocations(!!sessionToken) // used by onboarding check
  const currentPath = location.pathname + location.search

  useEffect(() => {
    const redirect = new URL(sessionRedirect)
    redirect.searchParams.set('redirectTo', currentPath)

    if (!sessionToken) window.location.href = redirect.toString()
  }, [sessionRedirect, sessionToken, currentPath])

  const hasRequiredData =
    sessionToken &&
    appSettingsQuery.isSuccess &&
    contextQuery.isSuccess &&
    userSettingsQuery.isSuccess &&
    locationsQuery.isSuccess

  return hasRequiredData ? (
    children
  ) : (
    <div style={style}>
      <InfoBox icon="sync">Authenticating</InfoBox>
    </div>
  )
}
