import {
  type PropsWithChildren,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  FvButton,
  FvLinkButton,
  type IconProps,
  SliderPanel,
  ValidatedForm,
} from '@fv/client-components'
import { type PropsWithLoad } from '@fv/client-types'

import { CarrierServiceTypeahead } from '../../components/carrier-service-types/CarrierServiceTypeSelector'
import InfoBox from '../../components/InfoBox'
import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { RadioField } from '../../components/inputs/RadioField'
import {
  filterOption,
  TypeaheadField,
  type TypeaheadOption,
} from '../../components/inputs/TypeaheadField'
import { useTypeNames } from '../../hooks/settings'
import { useSpotQuoteContacts } from '../spot-quote-contacts/queries'
import { type SpotQuoteContact } from '../spot-quote-contacts/types'
import { type SlimCarrier, useCarrierList } from './useCarrierList'
import { newQuoteId, useCreateQuote } from './useCreateQuote'
import {
  type ManualCarrierRate,
  type ManualRateType,
  type ManualSpotQuote,
  useManualRateForm,
} from './useManualRateForm'

type FormProps = {
  formRef: RefObject<HTMLFormElement>
}

const ManualRateForm = ({ formRef }: FormProps) => {
  const { actions, manualRate, load } = useManualRateForm()
  const carrierOptions = useCarrierOptions()
  if (load?.workflow !== 'ltl') return null

  const carrier = carrierOptions.list.find(
    c => c.value.name === manualRate.carrierName,
  )?.value

  const getInputProps = (
    name: keyof ManualCarrierRate,
    placeholder?: string,
  ) => ({
    name,
    onChange: actions.handleChange,
    placeholder,
    value: manualRate[name],
  })

  return (
    <div className="form-row mb-8">
      <InputGroupWrapper
        className="col-6"
        label="Carrier"
        name="carrierName"
        required
      >
        <TypeaheadField
          autoFocus
          isLoading={carrierOptions.loading}
          name="carrierName"
          onChange={carrier => {
            actions.handleCarrierChange(carrier, formRef.current)
          }}
          onInputChange={actions.handleCarrierChange}
          options={carrierOptions.list.filter(c =>
            filterOption(c, manualRate.carrierName),
          )}
          required
          shouldClearInvalidInput={!!manualRate.carrierName}
          value={manualRate.carrierName}
        />
      </InputGroupWrapper>

      <InputGroup
        className="col-3"
        inputProps={{
          ...getInputProps('days', 'e.g. 3'),
          min: 1,
        }}
        inputType="amount"
        label="Days"
      />

      <InputGroup
        className="col-3"
        inputProps={{
          ...getInputProps('price', 'e.g. 1200'),
          isPrice: true,
          min: 1,
        }}
        inputType="amount"
        label="Price"
        required
        startContent={<InputAdornment icon="dollar-sign" position="start" />}
      />

      <InputGroupWrapper
        className="col-6"
        label="Service type"
        name="serviceType"
        required
      >
        <CarrierServiceTypeahead
          scac={carrier?.code}
          name="serviceType"
          value={manualRate.serviceType}
          type="typeahead"
          onChange={v => actions.handleServiceChange(v)}
        />
      </InputGroupWrapper>

      <InputGroup
        className="col-6"
        inputProps={getInputProps('quoteNumber')}
        inputType="text"
        label="Quote number"
        startContent={<InputAdornment icon="hashtag" position="start" />}
      />
    </div>
  )
}

const SpotQuoteForm = ({ formRef }: FormProps) => {
  const { actions, load, spotQuote } = useManualRateForm()
  const { equipmentName } = useTypeNames()
  const contactOptions = useContactOptions()
  const requiresQuoteNumber = spotQuote.contact?.requiresQuoteNumber ?? true
  const altEquipmentTypes = load?.equipment.alternateTypes ?? []
  const equipmentOptions = load?.equipment.type
    ? [load.equipment.type, ...altEquipmentTypes].map(t => ({
        text: equipmentName(t),
        value: t,
      }))
    : []

  const getInputProps = (name: keyof Omit<ManualSpotQuote, 'contact'>) => ({
    name,
    onChange: actions.handleChange,
    value: spotQuote[name],
  })

  return (
    <div className="form-row mb-8">
      <InputGroupWrapper
        className="col-12"
        label="Email address"
        name="carrierEmail"
        required
      >
        <TypeaheadField
          autoFocus
          filterOption={filterOption}
          isLoading={contactOptions.loading}
          name="carrierEmail"
          onChange={contact => {
            actions.handleContactChange(contact, formRef.current)
          }}
          onInputChange={actions.handleContactChange}
          options={contactOptions.list}
          required
          type="email"
          value={spotQuote.carrierEmail}
        />
      </InputGroupWrapper>

      <InputGroup
        className="col-6"
        inputProps={{
          ...getInputProps('carrierName'),
          readOnly: !!spotQuote.contact?.carrierName,
        }}
        inputType="text"
        label="Carrier name"
        required
      />

      <InputGroup
        className="col-3"
        inputProps={getInputProps('quoteNumber')}
        inputType="text"
        label="Quote number"
        required={requiresQuoteNumber}
        startContent={<InputAdornment icon="hashtag" position="start" />}
      />

      <InputGroup
        className="col-3"
        inputProps={{
          ...getInputProps('price'),
          isPrice: true,
          min: 1,
        }}
        inputType="amount"
        label="Price"
        required
        startContent={<InputAdornment icon="dollar-sign" position="start" />}
      />

      {!!altEquipmentTypes.length && (
        <InputGroup
          className="col-12"
          inputProps={{
            ...getInputProps('equipmentType'),
            options: equipmentOptions,
          }}
          inputType="select"
          label="Equipment type"
          required
        />
      )}
    </div>
  )
}

const rateTypeOptions: Array<{ label: string; value: ManualRateType }> = [
  {
    label: 'Manual rate',
    value: 'manual-rate',
  },
  {
    label: 'Spot quote',
    value: 'spot-quote',
  },
]

type PanelProps = {
  close?: () => void
}

export const ManualRatePanel = ({ close }: PanelProps) => {
  const { reset, setRateType } = useManualRateForm(s => s.actions)
  const formRef = useRef<HTMLFormElement>(null)
  const isOpen = useManualRateForm(s => s.isOpen)
  const load = useManualRateForm(s => s.load)
  const createQuote = useCreateQuote()
  const rateType = useManualRateForm(s => s.rateType)

  function closePanel() {
    close?.()
    reset()
  }

  function onValidSubmit() {
    const loadId = load?.loadId
    if (!loadId) return

    const { manualRate, onError, onSuccess, spotQuote } =
      useManualRateForm.getState()

    createQuote(
      {
        loadId,
        quote: rateType === 'manual-rate' ? manualRate : spotQuote,
      },
      {
        ...(onError && { onError: () => onError(loadId) }),
        ...(onSuccess && {
          onSuccess: quote => {
            onSuccess(loadId, quote._id)
          },
        }),
      },
    )

    onSuccess?.(loadId, newQuoteId)
    closePanel()
  }

  const rateTypeInput =
    load?.workflow === 'ltl' ? (
      <>
        <RadioField
          className="mb-6"
          name="rateType"
          onChange={setRateType}
          options={rateTypeOptions}
          value={rateType}
        />
        <hr />
      </>
    ) : null

  const FormComponent =
    rateType === 'manual-rate' ? ManualRateForm : SpotQuoteForm

  const panelContent = (
    <>
      {rateType === 'manual-rate' ? (
        <InfoBox className="mb-6">
          Manually entering a rate allows you to compare rates and/or book
          shipments with carriers that do not return automatic rates. This is
          great for smaller carriers or niche carriers that do not have auto
          rates returned inside of Freightview. After you enter information
          about the carrier's rate, you will then be able to choose it, book it,
          print labels and a BOL and capture analytical data.
        </InfoBox>
      ) : (
        <InfoBox className="mb-6">
          Manual entry is good for entering a quote that you and a
          carrier/broker negotiate over the phone, or outside of Freightview.
          You do not need to enter quotes manually for carriers/brokers that
          quote within our software.
        </InfoBox>
      )}

      {rateTypeInput}

      <ValidatedForm
        autoReportNextError={false}
        onValidSubmit={onValidSubmit}
        ref={formRef}
      >
        <FormComponent formRef={formRef} />

        <div className="flex w-full justify-end">
          <FvButton onClick={closePanel} theme="plain" icon="times">
            Cancel
          </FvButton>
          <FvButton fwd theme="primary" type="submit" icon="check">
            Save {rateType === 'manual-rate' ? 'rate' : 'quote'}
          </FvButton>
        </div>
      </ValidatedForm>
    </>
  )

  // Using as standalone form without the `SliderPanel`
  if (close) return panelContent

  return (
    <SliderPanel closePanel={closePanel} isOpen={isOpen}>
      <div>
        <h6>Manually enter a rate</h6>
        {panelContent}
      </div>
    </SliderPanel>
  )
}

type AddRateButtonProps = PropsWithChildren<
  PropsWithLoad<{
    className?: string
    hasOwnPanel?: boolean
    icon?: IconProps['icon']
    onClick?: () => void
    onError?: (loadId: string) => void
    onSuccess?: (loadId: string, quoteId: string) => void
    rateType?: ManualRateType
  }>
>

export const AddRateButton = ({
  children,
  className,
  hasOwnPanel = false,
  icon,
  load,
  onClick,
  onError,
  onSuccess,
  rateType = 'spot-quote',
}: AddRateButtonProps) => {
  const { initializeForm } = useManualRateForm(s => s.actions)

  return (
    <FvLinkButton
      className={className}
      icon={icon}
      onClick={() => {
        initializeForm({
          load,
          onError,
          onSuccess,
          openSlider: !hasOwnPanel,
          rateType,
        })

        onClick?.()
      }}
    >
      {children}
    </FvLinkButton>
  )
}

function useCarrierOptions(): {
  loading: boolean
  list: TypeaheadOption<SlimCarrier>[]
} {
  const carriersQuery = useCarrierList('ltl')
  const [list, setList] = useState<TypeaheadOption<SlimCarrier>[]>([])

  useEffect(() => {
    setList(
      (carriersQuery.data ?? [])
        .filter(c => c.supportsManualRates)
        .map(c => ({
          label: c.name.trim(),
          value: c,
        })),
    )
  }, [carriersQuery.data])

  return {
    list,
    loading: carriersQuery.isLoading,
  }
}

function useContactOptions(): {
  loading: boolean
  list: TypeaheadOption<SpotQuoteContact>[]
} {
  const contactsQuery = useSpotQuoteContacts()
  const list = (contactsQuery.data ?? [])
    .filter(c => c.email)
    .map(c => ({
      label: c.email.trim(),
      value: c,
    }))

  return {
    list,
    loading: contactsQuery.isLoading,
  }
}
