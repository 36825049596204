import { FvButton, Icon } from '@fv/client-components'

type Props = {
  html: string
  handleDelete: () => void
}
export const NotificationRow = ({ html, handleDelete }: Props) => {
  return (
    <li className="border-fv-gray notification-row relative mb-4 mr-10 flex border bg-white last:mb-0">
      <div className="bg-fv-beer border-r-fv-beer-700 border-r px-4 py-5">
        <Icon icon="comment-alt-lines" className="fa-fw color-dark" />
      </div>
      <div className="flex-1 p-4">
        <p
          className="mb-0"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </div>

      <FvButton
        theme="plain"
        className="absolute -right-10 top-0"
        icon="trash"
        iconClass="text-fv-orange"
        onClick={() => handleDelete()}
      />
    </li>
  )
}
