import { Navigate, useParams } from 'react-router-dom'

import { OlarkHelper } from '@fv/client-components'

import { ModeNav } from '../../components/ModeNav'
import TopNav from '../../components/TopNav'
import { routes } from '../../constants'
import { QuotePageForm } from './QuotePageForm'
import { QuotePageProvider } from './QuotePageProvider'

type Params = {
  loadId: string
  mode: string
  workflow: string
}

export const QuotePage = () => {
  const { loadId = '', mode, workflow } = useParams<Params>()
  const validMode =
    (!mode && !loadId) ||
    mode === 'copy' ||
    mode === 'edit' ||
    mode === 'return'
  const validWorkflow = workflow === 'ltl' || workflow === 'parcel'

  return (
    <div className="grid grid-cols-[15rem_1fr] bg-[#f2f2f2] h-screen grid-rows-[auto_1fr_auto] b1200:block b1200:bg-white b1350:grid-cols-[18rem_1fr] b1350:grid-rows-[auto_auto_1fr_auto] b1300:grid-cols-1">
      <TopNav />
      <ModeNav className="row-start-2 b1350:col-span-full" />

      {(workflow === 'ltl' || workflow === 'parcel') && validMode && (
        <QuotePageProvider key={workflow} workflow={workflow}>
          <QuotePageForm />
        </QuotePageProvider>
      )}

      {!validMode && validWorkflow && (
        <Navigate
          to={routes.quote(workflow, { id: loadId, mode: 'copy' })}
          replace
        />
      )}

      {!validWorkflow && <Navigate to={routes.quote('truckload')} replace />}
      <OlarkHelper loadId={loadId} />
    </div>
  )
}
