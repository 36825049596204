import { useEffect, useRef, useState } from 'react'

import { FvButton } from '@fv/client-components'
import { submitForm, validatePasswordStrength } from '@fv/client-core'

import {
  InputAdornment,
  InputGroup,
} from '../../../components/inputs/InputGroup'
import { ValidatedForm } from '../../../components/inputs/ValidatedForm'
import { usePasswordMutations } from './mutations'

export const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [inputType, setInputType] = useState('password')
  const newPwRef = useRef<HTMLInputElement>(null)
  const confirmNewPwRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const { savePassword, isSaving } = usePasswordMutations()

  useEffect(() => {
    resetScreen()
  }, [])

  const handleSavePassword = async () => {
    if (isSaving) return
    if (!formRef.current?.checkValidity()) {
      return submitForm(formRef.current)
    }

    if (newPassword !== confirmNewPassword) {
      confirmNewPwRef.current?.setCustomValidity(
        'Your new passwords do not match.',
      )
      confirmNewPwRef.current?.reportValidity()
      return
    }
    if (!validatePasswordStrength(newPassword)) {
      newPwRef.current?.setCustomValidity(
        'Passwords must be at least 8 characters long, have an upper and lowercase character, a digit and a special character',
      )
      newPwRef.current?.reportValidity()
      return
    }

    await savePassword({ currentPassword, newPassword })
    resetScreen()
  }

  const resetScreen = () => {
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
    formRef.current?.reset()
    setInputType('password')
  }

  const togglePasswordVisibility = () => {
    setInputType(inputType === 'text' ? 'password' : 'text')
  }

  return (
    <ValidatedForm
      onValidSubmit={handleSavePassword}
      className="settings-main flex flex-col pb-0"
      ref={formRef}
    >
      <div className="form-row mb-10">
        <InputGroup
          className="basis-full"
          inputType="text"
          label="Current password"
          required
          inputProps={{
            onChange: e => {
              setCurrentPassword(e.target.value)
            },
            value: currentPassword,
            name: 'currentPassword',
            type: inputType,
            placeholder: 'Enter your existing password',
          }}
          endContent={
            <InputAdornment position="end">
              <FvButton
                theme="default"
                icon={'eye'}
                onClick={togglePasswordVisibility}
              />
            </InputAdornment>
          }
        />
        <InputGroup
          className="basis-1/2"
          inputType="text"
          label="New password"
          required
          inputProps={{
            onChange: e => {
              setNewPassword(e.target.value)
            },
            value: newPassword,
            name: 'newPassword',
            type: inputType,
            ref: newPwRef,
            placeholder: 'Enter a new password',
          }}
          endContent={
            <InputAdornment position="end">
              <FvButton
                theme="default"
                icon={'eye'}
                onClick={togglePasswordVisibility}
              />
            </InputAdornment>
          }
        />

        <InputGroup
          className="basis-1/2"
          inputType="text"
          label="Confirm new password"
          required
          inputProps={{
            onChange: e => {
              setConfirmNewPassword(e.target.value)
            },
            value: confirmNewPassword,
            name: 'confirmNewPassword',
            type: inputType,
            ref: confirmNewPwRef,
            placeholder: 'Confirm new password',
          }}
          endContent={
            <InputAdornment position="end">
              <FvButton
                theme="default"
                icon={'eye'}
                onClick={togglePasswordVisibility}
              />
            </InputAdornment>
          }
        />
      </div>
      <div className="flyout-footer-actions mb-0">
        <FvButton theme="primary" loading={isSaving} type="submit" icon="check">
          Save password
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
