import { useQuery } from '@tanstack/react-query'

import { type UserSettings } from '../../types/UserSettings'
import { shipperFetch } from '../../utils/shipperFetch'

export const userSettingsKey = ['user-settings']

const fetchUserSettings = async (): Promise<UserSettings> =>
  shipperFetch<UserSettings>(`/context/user-settings`)

export function useUserSettings(enabled = true) {
  return useQuery(userSettingsKey, fetchUserSettings, {
    enabled,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}
