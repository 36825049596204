import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type ShipperAccountSettingsModel } from '@fv/models'

import { apiUri } from '../../../constants'
import { useUserSettings } from '../../../hooks/settings'
import { useStaticDataQuery } from '../../../hooks/useStaticDataQuery'
import { type DistanceProviderConfig } from './types'

export const useAccountSettings = (): ShipperAccountSettingsModel => {
  const userSettings = useUserSettings()
  if (!userSettings.data.settings) {
    throw new Error('Unable to load settings')
  }
  return userSettings.data?.settings
}

const fetchDistanceProviders = async (): Promise<DistanceProviderConfig[]> => {
  const endpoint = `${apiUri}/accounts/settings/distance-providers`
  const response = await fetchJson(
    endpoint,
    buildFetchOptionsWithAuth({ method: 'GET' }),
  )

  if (response.ok) return response.json
  else throw response.errorMessage
}

export const useAvailableDistanceProviders = () => {
  return useStaticDataQuery(['distance-providers'], fetchDistanceProviders)
}

export const useHasMexico = () => {
  const {
    truckload: { enableMexico },
  } = useAccountSettings()
  return enableMexico
}

export const useShowBidExpiration = () => {
  const {
    quoting: { showBidExpiration },
  } = useAccountSettings()
  return showBidExpiration
}
