import { HazardousDetail } from '@fv/client-types'

interface HazardDetailProps {
  hazardDetails?: Array<Partial<HazardousDetail>>
  description?: string
}
/**
 * HazardDetails function which requires hazardDetails as an array
 * @param hazardDetails optional array property
 * @param description optional object property
 * @returns
 */
export const HazardDetails = ({
  hazardDetails,
  description,
}: HazardDetailProps) => {
  let createHazardText = ''
  if (hazardDetails && hazardDetails?.length > 0) {
    hazardDetails.forEach(hd => {
      if (hd?.hazmatId) createHazardText += `- ${hd?.hazmatId}`
      if (description) createHazardText += `, ${description}`
      if (hd?.primaryClass)
        createHazardText += `, ${getHazardClassList(
          hd?.primaryClass,
          hd?.secondaryClass,
          hd?.tertiaryClass,
        )}`
      if (hd?.packingGroup) createHazardText += `, PG ${hd?.packingGroup}`
      if (hd?.permitNumber)
        createHazardText += `, Special permit ${hd?.permitNumber}`
    })
  }

  return <span>{createHazardText}</span>
}

function getHazardClassList(
  primary: string,
  secondary?: string,
  tertiary?: string,
) {
  let classList = `${primary}`

  if (secondary || tertiary) {
    classList += ' ('
    if (secondary) classList += secondary
    if (secondary && tertiary) classList += ', '
    if (tertiary) classList += tertiary
    classList += ')'
  }

  return classList
}
