export function alphabeticalSorter(key) {
  return (a, b) => {
    const aValue = a[key]?.toLowerCase()
    const bValue = b[key]?.toLowerCase()

    if (aValue < bValue) return -1
    if (aValue > bValue) return 1
    return 0
  }
}
