import clsx from 'clsx'
import { useCallback } from 'react'

import { FvButton, FvLinkButton, ValidatedForm } from '@fv/client-components'

import { PillHeader } from '../../../components/PillHeader'
import { maxItemCount } from '../../../constants'
import arrayOfLength from '../../../utils/arrayOfLength'
import { LoadItemForm } from './LoadItemForm'
import { useItemActions, useItemsState } from './loadItemsContext'

export const PagedItemsFormWrapper = () => {
  const { items, activeIndex, itemForm } = useItemsState()
  const {
    addItem,
    validateForm,
    clearCurrentItem,
    updateCurrentItem,
    setFormRef,
    duplicateCurrentItem: duplicateItem,
  } = useItemActions()
  const canAddItem = items.length < maxItemCount

  const handleAddClick = () => {
    if (validateForm()) {
      addItem()
      itemForm?.reset()
    }
  }
  const formRef = useCallback(
    (ref: HTMLFormElement | null) => {
      if (ref !== itemForm) {
        setFormRef(ref)
      }
    },
    [itemForm, setFormRef],
  )
  return (
    <div>
      <ValidatedForm autoReportNextError={false} ref={formRef}>
        <div className="form-row">
          <ItemNav />
          <LoadItemForm
            value={items[activeIndex]}
            onChange={updateCurrentItem}
          />
        </div>
      </ValidatedForm>
      <div className="divided-content divided-content--start pt-4">
        <div>
          <FvButton icon="plus" disabled={!canAddItem} onClick={handleAddClick}>
            Add handling unit
          </FvButton>
        </div>
        <div>
          <FvButton disabled={!canAddItem} icon="clone" onClick={duplicateItem}>
            Duplicate handling unit
          </FvButton>
        </div>
        <div>
          <FvButton icon="times" onClick={clearCurrentItem}>
            Clear
          </FvButton>
        </div>
      </div>
    </div>
  )
}

const ItemNav = () => {
  const { items, activeIndex, itemForm, disabled } = useItemsState()
  const {
    activateItem,
    addItem,
    removeCurrentItem: removeItem,
    validateForm,
  } = useItemActions()
  const itemCount = items.length
  const canAddItem = items.length < maxItemCount

  const handleAddClick = () => {
    if (validateForm()) {
      addItem()
      itemForm?.reset()
    }
  }

  return (
    <PillHeader className="beer-container py-[.2rem]">
      <div className="flex flex-nowrap items-center">
        <span className="whitespace-nowrap">Handling units</span>
        <div className="mx-2 flex flex-1 space-x-2 overflow-x-auto py-1">
          {arrayOfLength(itemCount).map(ix => (
            <FvButton
              theme={ix !== activeIndex ? 'underlined' : undefined}
              className={clsx({
                'boxed-link active bg-fv-blue-200 pointer-events-none rounded-sm text-inherit':
                  ix === activeIndex,
              })}
              key={ix}
              onClick={() => activateItem(ix)}
            >
              {ix + 1}
              {items[ix].handlingUnitType === 'multi' && (
                <span className="text-fv-orange-500 text-xs">M</span>
              )}
            </FvButton>
          ))}
        </div>
        <div className="flex flex-nowrap">
          <FvButton
            className="circle-icon circle-icon--link circle-icon--shipping-items ml-1"
            disabled={disabled || !canAddItem}
            icon="plus"
            onClick={handleAddClick}
            title="Add item"
          />

          {itemCount > 1 && (
            <FvButton
              className="circle-icon circle-icon--link circle-icon--shipping-items ml-2"
              disabled={disabled}
              icon="trash"
              onClick={removeItem}
              title="Remove item"
              transform="shrink-2"
            />
          )}
        </div>
      </div>
    </PillHeader>
  )
}
