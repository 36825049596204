import { FvButton } from '@fv/client-components'

import { Loading } from '../../../components/Loading'
import { useUserSettings } from '../../../hooks/settings'
import { CompanyLogoContent } from '../../company-branding/CompanyLogoContent'
import { LogoUploadButton } from '../../company-branding/LogoUploadButton'
import { useLogoMutations } from '../../company-branding/mutations'
import { PageHeader } from '../PageHeader'

export const CompanyBranding = () => {
  const settingsQuery = useUserSettings()
  const logo = settingsQuery?.data?.customLogo
  const { isAdding, isRemoving, removeLogo, addLogo } = useLogoMutations()
  const loading = isAdding || isRemoving || settingsQuery.isRefetching
  const handleFileSelect = (file: File) => {
    addLogo({ file })
  }
  return (
    <div className="settings-main flex flex-col pb-0">
      <div className="overflow-auto">
        {loading ? <Loading /> : <CompanyLogoContent />}
      </div>
      <div className="flyout-footer-actions mb-0 flex items-center">
        {logo && (
          <>
            <FvButton
              loading={isRemoving || isAdding}
              icon="times"
              theme="default"
              onClick={() => removeLogo()}
            >
              Remove your logo
            </FvButton>
            <span className="mx-2">-or-</span>
          </>
        )}
        <LogoUploadButton loading={loading} onSelect={handleFileSelect} />
      </div>
    </div>
  )
}
