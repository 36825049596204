import clsx from 'clsx'

import { Icon, type IconName } from '@fv/client-components'

type PageProps = {
  title: string
  noBorder?: boolean
  icon?: IconName
  className?: string
}

export const PageHeader = ({ title, noBorder, icon, className }: PageProps) => {
  return (
    <>
      <h5 className={clsx(noBorder ? 'mb-4' : '', className)}>
        {icon && <Icon icon={icon} className="mr-2 text-gray-600" />}
        {title}
      </h5>
      {!noBorder && <hr className="mx-0 mb-6 mt-2" />}
    </>
  )
}
