import { createSearchParams } from 'react-router-dom'

import {
  adminUri,
  apiUri,
  appName,
  appVersion,
  freightviewUri,
  getEnvValue,
  logrocketApp,
  olarkId,
} from '@fv/client-core'
import { type FullShipment, type Workflow } from '@fv/client-types'

import { type BookingMode, type ReferrerType } from './features/booking/types'
import { type RatesListingMode } from './features/quote/useRatesListing'
import {
  ltlRecentFilter,
  ltlSavedFitler,
  parcelRecentFilter,
  parcelSavedFilter,
  tlAwardedFilter,
  tlQuotingFilter,
} from './features/shipment-filters/in-process-filters'
import { type AwardChangeReason } from './hooks/shipments/useRetenderLoad'
import { getFilterQueryString } from './types/SavedFilter'

export {
  adminUri,
  apiUri,
  appName,
  appVersion,
  freightviewUri,
  logrocketApp,
  olarkId,
}
export const defaultPageSize = 20

export const countryCodes = {
  CA: 'ca',
  CAN: 'ca',
  MEX: 'mx',
  MX: 'mx',
  US: 'us',
}

export const refrigeratedEquipmentTypes = ['refrigerated-48', 'refrigerated-53']
export const supportMessage = 'please contact support if the issue persists.'
export const maxItemCount = 35
export const MAX_ALT_EQUIP = 5

// Feature flags
export const hasLegacyDetail = JSON.parse(
  getEnvValue('VITE_HAS_LEGACY_DETAIL') ?? 'false',
)

export type SettingsPageType =
  | 'integration'
  | 'user-preferences'
  | 'account-settings'
  | 'product-catalog'
  | 'tag-management'
  | 'change-password'
  | 'branding'
  | 'bill-to'
  | 'address-book'
  | 'spot-quote'

export const routes = {
  book: ({
    loadId,
    quoteId,
  }: Pick<FullShipment, 'loadId'> & { quoteId: string }) => {
    return `/book/${loadId}/${quoteId}`
  },
  bookTruckload: (opts: {
    mode?: BookingMode
    quoteRequestId: string
    reason?: AwardChangeReason | null
    referrer?: ReferrerType
    selectedQuotes: Record<string, string>
  }) => {
    const params = createSearchParams({
      selected: JSON.stringify(opts.selectedQuotes),
      ...(opts.mode && { mode: opts.mode }),
      ...(opts.reason && { reason: opts.reason }),
      ...(opts.referrer && { ref: opts.referrer }),
    })

    return `/pending/${opts.quoteRequestId}?${params}`
  },
  customerRouted: () => '/book/customer-routed',
  details: (loadId: string) => `/details/${loadId}`,
  quote: (
    workflow: Workflow,
    params?: { id: string; mode: 'copy' | 'edit' },
  ) => {
    let path = `/${workflow}/quote`
    if (workflow === 'truckload') path = '/quote-request'
    if (params) path += `/${params.id}/${params.mode}`
    return path
  },
  rates: (
    load: Pick<FullShipment, 'workflow' | 'loadId' | 'quoteRequestId'>,
    mode?: RatesListingMode,
  ) => {
    const { loadId, quoteRequestId, workflow } = load

    return workflow === 'truckload'
      ? `/quote/${quoteRequestId}`
      : [`/rates/${loadId}`, mode].filter(x => x).join('/')
  },
  recentQuotes: (workflow: Workflow) => {
    const filter =
      workflow === 'truckload'
        ? tlQuotingFilter
        : workflow === 'parcel'
          ? parcelRecentFilter
          : ltlRecentFilter
    return `/pending?${getFilterQueryString(filter)}`
  },
  savedQuotes: (workflow: Workflow) => {
    const filter =
      workflow === 'truckload'
        ? tlAwardedFilter
        : workflow === 'parcel'
          ? parcelSavedFilter
          : ltlSavedFitler
    return `/pending?${getFilterQueryString(filter)}`
  },
  settings: (
    page: SettingsPageType,
    mode?: 'add' | 'edit' | 'fuel',
    rateId?: string,
  ) => {
    let path = `/settings/${page}`
    if (mode) path += `/${mode}`
    if (mode && rateId) path += `/${rateId}`
    return path
  },
  contractedRates: (
    mode?: 'add' | 'edit' | 'fuel' | 'list',
    opts?: {
      rateId?: string
      carrierId?: string
    },
  ) => {
    const root = '/settings/contracted-rates'
    if (mode === 'add' || mode === 'fuel') return `${root}/add`
    if (opts?.carrierId && mode === 'list') {
      return `${root}?carrierId=${opts?.carrierId}`
    }
    if (mode === 'edit' && opts?.rateId) {
      return `${root}/edit/${opts?.rateId}`
    }
    return root
  },

  provisioning: (tab: 'requested' | 'active') => {
    return `/provisioning/${tab}`
  },
}
