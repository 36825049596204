import { formatPhone } from '@fv/models/core'

type Props = {
  val?: string
  ext?: string
}
export const PhoneNumberDisplay = ({ val, ext }: Props) => {
  if (!val) return null

  const disp = formatPhone(val)
  const extDisp = ext ? ` ext. ${ext}` : ''
  return <a href={`tel:${val}`}>{`${disp}${extDisp}`}</a>
}
