import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type ListShipment } from '@fv/client-types'

import { apiUri, supportMessage } from '../../constants'
import { useNotesQueryInvalidator } from '../../hooks/messages/useNotes'
import { useUpdateCachedLoad } from '../../hooks/shipments'

async function editBolNumber(
  loadId: string,
  num: string,
): Promise<ListShipment> {
  const endpoint = `${apiUri}/shipments/${loadId}/bol-number`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ bolNumber: num.trim() }),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useEditBolNumber(loadId: string) {
  const updateCachedLoad = useUpdateCachedLoad()
  const notesInvalidator = useNotesQueryInvalidator()

  return useMutation((n: string) => editBolNumber(loadId, n), {
    onSettled: (updatedLoad, error) => {
      if (error) {
        toast.error(`Unable to update BOL number, ${supportMessage}`)
        return
      }

      notesInvalidator(loadId)
      if (updatedLoad) updateCachedLoad({ updatedLoad })
      toast.success('BOL number updated successfully.')
    },
  })
}
