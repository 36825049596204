import defaultsDeep from 'lodash/defaultsDeep'
import LogRocket from 'logrocket'

import { stringify } from './query-string'

type ReqOptions = RequestInit & {
  body?: string | null
  json?: any
  query?: any
}

export function defaultOptions(overrides = {}) {
  const args = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  }

  return defaultsDeep(overrides, args)
}

export function defaultOptionsWithAuth(
  { token }: { token: string },
  overrides = {},
) {
  const creds = `:${token}`
  const args = defaultsDeep(defaultOptions(), {
    headers: {
      Authorization: `Basic ${btoa(creds)}`,
    },
    credentials: 'include',
  })

  return defaultsDeep(overrides, args)
}

export async function fetchJson(url: string, options: ReqOptions) {
  options = options || {}

  let result: {
    error?: any
    errorMessage?: any
    json?: any
    ok?: boolean
    response?: Response
    status?: number
  } = {}

  try {
    if (options.json) {
      options.body = JSON.stringify(options.json)
      delete options.json
    }

    if (typeof options.query === 'object') {
      if (url.indexOf('?') >= 0) {
        throw new Error(
          "Cannot make a fetch with query options using a url that contains a '?'. Please solely use options.query.",
        )
      }

      url += stringify(options.query)
      delete options.query
    }

    options.headers = {
      ...(options.headers ?? {}),
      ...(LogRocket.sessionURL && { 'X-LogRocket-URL': LogRocket.sessionURL }),
    }
    const response = await fetch(url, options)

    result = {
      ok: response.ok,
      response,
      status: response.status,
    }

    let body: any = await response.text()

    if (body?.length) {
      body = JSON.parse(body) // always assume content is json
    } else {
      body = null
    }

    result.json = body

    if (!response.ok) {
      if (body?.error) {
        result.errorMessage = body.error // first try for a response object
      } else if (body) {
        result.errorMessage = body // kind of fragile
      } else {
        result.errorMessage = response.statusText // if no body, then use the status text
      }
    }
  } catch (err: any) {
    result.error = err
    result.errorMessage = err.message
    result.ok = false
  }

  return result
}
