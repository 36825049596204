import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useEffect,
  useRef,
} from 'react'

interface Props extends HTMLProps<HTMLTextAreaElement> {
  error?: string
  name: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

export const TextAreaField = forwardRef(
  (
    { error, name, onChange, value, ...rest }: Props,
    ref?: ForwardedRef<HTMLTextAreaElement | null>,
  ) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null)

    useEffect(() => {
      if (!error) return localRef.current?.setCustomValidity('')
      localRef.current?.setCustomValidity(error)
      localRef.current?.reportValidity()
    }, [error])

    function localOnChange(e: ChangeEvent<HTMLTextAreaElement>) {
      // Do not allow leading space
      e.target.value = e.target.value.trimStart()
      e.target.setCustomValidity('')
      onChange?.(e)
    }

    function setRef(element: HTMLTextAreaElement | null) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <textarea
        autoComplete="off"
        data-lpignore
        id={name}
        name={name}
        onChange={localOnChange}
        ref={setRef}
        value={value}
        {...rest}
      />
    )
  },
)
