import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

import { AddressBookSlider } from '../features/addresses/AddressBook'
import { ProductCatalog } from '../features/commodities/ProductCatalog'
import { OnboardingProvider } from '../features/onboarding/OnboardingProvider'
import { ManualRatePanel } from '../features/quote/ManualRatePanel'
import { SessionGate } from './auth/SessionGate'
import { AppTooling } from './AppTooling'
import { ShipperRoutes } from './ShipperRoutes'

// Extend dayjs here for use throughout the app (move to util?)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)

type Props = {
  sessionRedirect: string
}

export const AuthenticatedApp = ({ sessionRedirect }: Props) => {
  return (
    <SessionGate sessionRedirect={sessionRedirect}>
      <OnboardingProvider>
        <div className="App">
          <ShipperRoutes />
          <AddressBookSlider />
          <ManualRatePanel />
          <ProductCatalog />
          <AppTooling />
        </div>
      </OnboardingProvider>
    </SessionGate>
  )
}
