import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { type ApiError } from '../../types/ApiError'

export type LoadNote = {
  _id: string
  source: 'shipper' | 'system'
  type: 'general' | 'audit'
  createdDate: Date
  createdBy?: {
    userId: string
    name?: string
  }
  message: string
  isNew?: boolean
}

export type AddLoadNote = Omit<LoadNote, 'createdDate' | '_id' | 'createdBy'>

const getKey = (loadId: string) => [notesQueryKey, loadId]

async function fetchNotes(loadId: string): Promise<LoadNote[]> {
  const endpoint = `${apiUri}/shipments/${loadId}/notes`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

const notesQueryKey = 'notes'
export function useNotes(loadId: string) {
  return useQuery<LoadNote[]>(getKey(loadId), () => fetchNotes(loadId), {
    enabled: Boolean(loadId),
    select: data => {
      return data?.sort(
        (a, b) => +new Date(b.createdDate) - +new Date(a.createdDate),
      )
    },
  })
}

export const useNotesQueryInvalidator = () => {
  const client = useQueryClient()
  return (loadId: string) => {
    client.invalidateQueries(getKey(loadId))
  }
}

const postNote =
  (loadId: string) =>
  async (note: AddLoadNote): Promise<LoadNote> => {
    const endpoint = `${apiUri}/shipments/${loadId}/notes`
    const options = buildFetchOptionsWithAuth({
      method: 'POST',
      body: JSON.stringify(note),
    })
    const response = await fetchJson(endpoint, options)
    if (response.ok) return response.json
    throw response.errorMessage
  }

export const useNoteMutation = (loadId: string) => {
  const queryClient = useQueryClient()
  const addMutation = useMutation(postNote(loadId), {
    onSettled(data, error: ApiError) {
      if (error || !data) {
        toast.error(`Error adding note`)
        return
      }
      queryClient.setQueryData<LoadNote[]>([notesQueryKey, loadId], prev => [
        ...(prev || []),
        { ...data, isNew: true },
      ])
    },
  })

  return {
    isAdding: addMutation.isLoading,
    addNote: (note: AddLoadNote) => addMutation.mutateAsync(note),
  }
}
