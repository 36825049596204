import { FvButton } from '@fv/client-components'
import { type AddressLookupContext } from '@fv/models'

import { InputAdornment } from '../../components/inputs/InputGroup'
import { type SetAddress } from './types'
import { useAddressBook } from './useAddressBook'

type Props = {
  disabled?: boolean
  postalCodeFilter?: string
  setAddress: SetAddress
  context: AddressLookupContext
}

export const AddressBookButton = ({
  disabled,
  postalCodeFilter,
  setAddress,
  context,
}: Props) => {
  const addressBook = useAddressBook()

  return (
    <InputAdornment position="start">
      <FvButton
        aria-label="Open address book"
        theme="default"
        disabled={disabled}
        icon="book"
        onClick={() =>
          addressBook.open(setAddress, { postalCodeFilter, context })
        }
        title="Address book"
        fw
      />
    </InputAdornment>
  )
}
