import { FedEx, FvButton, Icon } from '@fv/client-components'

import { useFxfeWizardContext } from './hooks'

export const FedexMfaSuccess = () => {
  const { onComplete } = useFxfeWizardContext()
  return (
    <div>
      <div className="flex flex-col justify-center">
        <Icon icon="check-circle" className="text" size="10x" color="inherit" />
        <p className="mt-8">
          Your <FedEx /> account is connected! For each company location be sure
          to enter the appropriate account numbers by expanding the <FedEx />{' '}
          carrier and entering the required information.
        </p>
        <p>
          If you have a <FedEx freight /> bill-to account, please connect it in
          the third party section after expanding the <FedEx /> carrier.
        </p>
      </div>
      <div className="flex justify-end">
        <FvButton theme="primary" onClick={onComplete}>
          Continue
        </FvButton>
      </div>
    </div>
  )
}
