import clsx from 'clsx'
import dayjs from 'dayjs'
import { type PropsWithChildren } from 'react'

import { Tooltip } from '@fv/client-components'
import { type SlimTrackDetail, type TrackingStatus } from '@fv/client-types'

const loadProgressMap: Record<TrackingStatus, number> = {
  pending: 0,
  'in-transit': 1,
  delivered: 2,
  'not-found': -1,
  error: -1,
}

const getTrackingSummary = (tracking: SlimTrackDetail): string | undefined => {
  const timeStr = dayjs(tracking.lastUpdatedDate).fromNow()

  if (tracking.currentStatus === 'pending') {
    return `Scheduled ~ Updated ${timeStr}`
  } else if (tracking.currentStatus === 'in-transit') {
    if (tracking.currentLocation) {
      return `In ${tracking.currentLocation.city}, ${tracking.currentLocation.state} ~ ${timeStr}`
    } else {
      return `In Transit ~ ${timeStr}`
    }
  } else if (tracking.currentStatus === 'delivered') {
    return `Delivered ~ ${dayjs(tracking.deliveryDateActual).format(
      'YYYY-MM-DD h:mm A',
    )}`
  }
  return undefined
}

const TrackLabel = ({
  tooltip,
  children,
}: PropsWithChildren<{
  tooltip?: string
}>) => {
  const span = <span className="slideRight">{children}</span>
  return tooltip ? <Tooltip label={tooltip}>{span}</Tooltip> : span
}

type TrackStatusProps = SlimTrackDetail & {
  targetStatus: TrackingStatus
}

const TrackStatus = ({
  children,
  targetStatus,
  ...tracking
}: PropsWithChildren<TrackStatusProps>) => {
  const currentStatus = tracking.currentStatus
  const completed =
    loadProgressMap[currentStatus] > loadProgressMap[targetStatus]
  const active = currentStatus === targetStatus

  return (
    <li
      className={clsx(`${targetStatus} fadeIn`, {
        active,
        completed,
      })}
    >
      {active && (
        <TrackLabel tooltip={getTrackingSummary(tracking)}>
          {children}
        </TrackLabel>
      )}
    </li>
  )
}

type Props = {
  tracking: SlimTrackDetail | undefined
  onClick?: () => unknown
}

export const ShipmentTrackingBar = ({ onClick, tracking }: Props) => {
  const currentStatus = tracking?.currentStatus || 'pending'

  return (
    <ul
      className={clsx(`tracking-visual list-unstyled ${currentStatus}`)}
      onClick={onClick}
    >
      <TrackStatus
        {...tracking}
        currentStatus={currentStatus}
        targetStatus="pending"
      >
        <span>Scheduled</span>
      </TrackStatus>
      <TrackStatus
        {...tracking}
        currentStatus={currentStatus}
        targetStatus="in-transit"
      >
        <span>In-transit</span>
      </TrackStatus>
      <TrackStatus
        {...tracking}
        currentStatus={currentStatus}
        targetStatus="delivered"
      >
        <span>Delivered</span>
      </TrackStatus>
    </ul>
  )
}
