export const dateRangeNames = [
  'this-week',
  'next-week',
  'this-month',
  'this-year',
  'last-7-days',
  'last-30-days',
  'last-month',
  'last-year',
  'last-3-months',
  'yesterday',
  'tomorrow',
  'today',
] as const

export type PredefinedDateRange = (typeof dateRangeNames)[number]
// eslint-disable-next-line @typescript-eslint/ban-types
export type DateRange = PredefinedDateRange | (string & {})
