import { type FormRegister } from '@fv/client-core'
import { type AccountLocationUpsertModel } from '@fv/client-types'

import { AddressInput } from '../../components/inputs/AddressInput'
import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'

type FormProps = {
  value: AccountLocationUpsertModel
  register: FormRegister<AccountLocationUpsertModel>
  onChange: (val: AccountLocationUpsertModel) => unknown
  onLoadingChange?: (loading: boolean) => void
}
export const CompanyLocationForm = ({
  value,
  register,
  onChange,
  onLoadingChange,
}: FormProps) => {
  return (
    <>
      <InputGroup
        label="Street"
        inputType="text"
        required
        inputProps={{ autoFocus: true, required: true, ...register('street') }}
      />
      <InputGroupWrapper
        label="City / State / Postal"
        name="city-state-zip"
        required
      >
        <AddressInput
          name="city-state-zip"
          includeAddressBook={false}
          placeholder="i.e. Overland Park, KS 66204"
          includeAtlas
          location={value}
          required
          onLoadingChange={onLoadingChange}
          onLocationChange={loc => {
            onChange({
              street: value.street,
              city: loc?.city ?? '',
              postalCode: loc?.postalCode ?? '',
              state: loc?.state ?? '',
              country: loc?.country ?? 'us',
            })
          }}
        />
      </InputGroupWrapper>
    </>
  )
}
