import intlTelInput, { Iti, SomeOptions } from 'intl-tel-input'
import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useCallback,
  useEffect,
  useRef,
} from 'react'

interface ValidityData {
  valid: boolean
  errorCode?: number
}
type Props = {
  initialValue?: string
  onChangeNumber?: (number: string) => void
  onChangeCountry?: (country: string) => void
  onChangeValidity?: (data: ValidityData) => void
  usePreciseValidation?: boolean
  initOptions?: SomeOptions
  inputProps?: HTMLProps<HTMLInputElement>
}
/*
  Copied from: https://github.com/jackocnr/intl-tel-input/blob/master/react/src/intl-tel-input/react.tsx
*/

export const IntlTelInput = forwardRef(
  (
    {
      initialValue = '',
      onChangeNumber = (): void => {},
      onChangeCountry = (): void => {},
      onChangeValidity = (): void => {},
      usePreciseValidation = false,
      initOptions = {},
      inputProps = {},
    }: Props,
    ref: ForwardedRef<HTMLInputElement | null>,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const itiRef = useRef<Iti | null>(null)

    const getNum = () => {
      const countryCode = itiRef.current.getSelectedCountryData().dialCode
      // keep us/can phones formatted noationally while any other number will be formatted internationally
      return (
        itiRef.current?.getNumber(
          countryCode === '1'
            ? intlTelInput.utils.numberFormat.NATIONAL
            : intlTelInput.utils.numberFormat.E164,
        ) || ''
      )
    }

    const update = (): void => {
      if (!intlTelInput.utils) return
      // keep us/can phones formatted noationally while any other number will be formatted internationally

      const num = getNum()
      const countryIso = itiRef.current?.getSelectedCountryData().iso2 || ''
      // note: this number will be in standard E164 format, but any container component can use
      // intlTelInput.utils.formatNumber() to convert this to another format
      // as well as intlTelInput.utils.getNumberType() etc. if need be
      onChangeNumber(num)
      onChangeCountry(countryIso)
      validate()
    }

    const validate = useCallback(() => {
      if (itiRef.current && intlTelInput.utils) {
        const num = getNum()
        const isValid =
          (!num && !inputProps?.required) ||
          (usePreciseValidation
            ? itiRef.current.isValidNumberPrecise()
            : itiRef.current.isValidNumber())
        if (isValid) {
          onChangeValidity({ valid: true })
        } else {
          const errorCode = itiRef.current.getValidationError()
          onChangeValidity({ valid: false, errorCode })
        }
      }
    }, [inputProps, onChangeValidity, usePreciseValidation])

    useEffect(() => {
      // store a reference to the current input ref, which otherwise is already lost in the cleanup function
      const inputRefCurrent = inputRef.current
      if (inputRefCurrent) {
        itiRef.current = intlTelInput(inputRefCurrent, initOptions)
        inputRefCurrent.addEventListener('countrychange', update)
        // when plugin initialisation has finished (e.g. loaded utils script), update all the state values
        itiRef.current.promise.then(update)
      }
      return (): void => {
        if (inputRefCurrent) {
          inputRefCurrent.removeEventListener('countrychange', update)
        }
        itiRef.current?.destroy()
      }
    }, [])

    function setRef(element: HTMLInputElement | null) {
      inputRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    useEffect(() => {
      initialValue && validate()
    }, [initialValue, validate])

    return (
      <input
        type="tel"
        onInput={update}
        defaultValue={initialValue}
        ref={setRef}
        {...inputProps}
      />
    )
  },
)
