import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useFvNavigate } from '@fv/client-components'
import { defaultOptions, fetchJson, setToken } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useQueryParam } from '../../hooks/settings'
import InfoBox from '../InfoBox'

const style = { margin: '2rem auto', maxWidth: '11rem' }

const Login = ({ loginRedirect }) => {
  const { registrationToken } = useParams()
  const navigate = useFvNavigate()
  const redirectTo = useQueryParam('redirectTo')
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!registrationToken) {
      window.location.href = loginRedirect
    } else {
      const establishSession = async token => {
        const options = defaultOptions({
          cache: 'no-store',
          json: { token },
          method: 'POST',
        })

        await fetchJson(`${apiUri}/register/verify`, options)
          .then(res => {
            if (res.ok && res.json?.token) return res.json.token
            throw Error('Unable to verify registration token.')
          })
          .catch(e => {
            console.error(e)
            window.location.href = loginRedirect
          })
          .then(sessionToken => {
            if (!sessionToken) return
            setToken(sessionToken)
            navigate(`/${redirectTo?.replace(/^\//, '') ?? 'active'}`, {
              replace: true,
            })
          })
          .catch(() => {
            setError(
              "Unable to access your browser's local storage. Please enable local storage to continue.",
            )
          })
      }

      establishSession(registrationToken)
    }
  }, [navigate, loginRedirect, redirectTo, registrationToken])

  if (error) throw new Error(error)

  // This will eventually become the Freightview login page
  return (
    <div style={style}>
      <InfoBox icon="sync">Authenticating</InfoBox>
    </div>
  )
}

Login.propTypes = {
  loginRedirect: PropTypes.string.isRequired,
}

export default Login
