import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  type InfiniteQueryData,
  setDataByPage,
  showApiError,
} from '@fv/client-core'
import { type LegacyHandlingUnitDTO } from '@fv/client-types'

import { apiUri } from '../../constants'
import { handlingUnitKeys } from './useHandlingUnits'

async function deleteHandlingUnit(handlingUnitId: string): Promise<void> {
  const endpoint = `${apiUri}/handling-units/${handlingUnitId}`
  const options = buildFetchOptionsWithAuth({ method: 'DELETE' })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useDeleteHandlingUnit() {
  const queryClient = useQueryClient()

  return useMutation(deleteHandlingUnit, {
    onSettled: (_, error, handlingUnitId) => {
      if (error) return showApiError('Unable to delete product', error)

      queryClient.setQueriesData<InfiniteQueryData<LegacyHandlingUnitDTO>>(
        handlingUnitKeys.all,
        setDataByPage(handlingUnits =>
          handlingUnits.filter(u => u._id !== handlingUnitId),
        ),
      )

      queryClient.removeQueries({
        queryKey: handlingUnitKeys.id(handlingUnitId),
        exact: true,
      })

      toast.success('Product deleted successfully.')
    },
  })
}
