import clsx from 'clsx'
import dayjs from 'dayjs'

import { Icon } from '@fv/client-components'
import { type Audit } from '@fv/models'

import { List, ListItem } from '../../components/List'

type AuditNotesProps = {
  className?: string
  notes?: Audit['notes']
}

const AuditNotes = ({ className = '', notes }: AuditNotesProps) => {
  if (!notes) return null

  const sortedNotes = notes.sort(
    (a, b) =>
      new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf(),
  )

  return (
    <List className={className}>
      {sortedNotes.map(n => {
        const timestamp = dayjs(n.createdDate).format('ddd MMM D h:mma')

        return (
          <ListItem key={n._id} className="!gap-x-0">
            <Icon
              icon={n.source === 'shipper' ? 'sticky-note' : 'paper-plane'}
              className={clsx('fa-fw', {
                'color-dark': n.source === 'shipper',
                'color-warning': n.source === 'system',
              })}
              transform="up-1"
            />

            <p className="mb-0">
              {timestamp} - {n.message}
            </p>
          </ListItem>
        )
      })}
    </List>
  )
}

export default AuditNotes
