import { useState } from 'react'

import { useDebouncedValue } from '@fv/client-core'
import { CountryMapper, type State, states } from '@fv/models'

import { useHasMexico } from '../../features/settings/account-settings/hooks'
import { TypeaheadField, type TypeaheadProps } from './TypeaheadField'

type Props<T> = Pick<
  TypeaheadProps<T>,
  | 'isClearable'
  | 'error'
  | 'onChange'
  | 'placeholder'
  | 'readOnly'
  | 'required'
  | 'name'
  | 'autoFocus'
  | 'shouldClearInvalidInput'
  | 'value'
>
export const StateSelector = (props: Props<State>) => {
  const [search, setSearch] = useState('')
  const searchVal = useDebouncedValue(search, 500)
  const hasMexico = useHasMexico()
  const options = states
    .filter(
      s =>
        (hasMexico || s.country !== 'mx') &&
        (s.abbreviation.toLowerCase().includes(searchVal) ||
          s.name.toLowerCase().includes(searchVal)),
    )
    .map(s => ({
      label: `${s.name}, ${CountryMapper.toUpper(s.country)}`,
      value: s,
    }))
  return (
    <TypeaheadField
      {...props}
      hasDropdownIcon
      onInputChange={setSearch}
      onChange={val => {
        setSearch(val?.name ?? '')
        props.onChange?.(val)
      }}
      backspaceRemovesValue
      inputValue={search}
      options={options}
    />
  )
}
