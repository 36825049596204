import { type Country } from '@fv/models'

import {
  type CustomsBroker,
  type CustomsBrokerType,
  type FormCustomsBroker,
} from './brokerTypes'

export const defaultFormBroker: FormCustomsBroker = {
  address: '',
  address2: '',
  city: '',
  companyName: '',
  country: 'us',
  email: '',
  phone: '',
  phoneExt: '',
  postalCode: '',
  state: '',
  value: '',
  type: '',
}

export const customsBrokerMapper = {
  dtoToForm: (broker?: CustomsBroker | null): FormCustomsBroker => ({
    address: broker?.address || '',
    address2: broker?.address2 || '',
    city: broker?.city || '',
    companyName: broker?.companyName || '',
    country: broker?.country,
    email: broker?.email || '',
    phone: broker?.phone?.replace(/\D/g, '') || '',
    phoneExt: broker?.phoneExt || '',
    postalCode: broker?.postalCode || '',
    state: broker?.state || '',
    value: String(broker?.value || ''),
    type: broker?.type || '',
  }),
  formToDTO: (broker: FormCustomsBroker): CustomsBroker | undefined => {
    const address = broker.address.trim()
    const address2 = broker.address2.trim()
    const city = broker.city.trim()
    const country = broker.country?.toLowerCase() ?? '' // bug where country can be nullish
    const email = broker.email.trim()
    const phoneExt = broker.phoneExt.trim()
    const postalCode = broker.postalCode.trim()
    const state = broker.state.trim()
    const value = broker.value.trim()
    const type = broker.type.trim()
    const companyName = broker.companyName.trim()
    const phone = broker.phone.trim()
    const adminArea3 = broker.adminArea3?.trim()

    if (!companyName && !phone) {
      return undefined
    }

    return {
      companyName,
      phone,
      ...(adminArea3 && { adminArea3 }),
      ...(address && { address }),
      ...(address2 && { address2 }),
      ...(city && { city }),
      ...(country && { country: country as Country }),
      ...(email && { email }),
      ...(phoneExt && { phoneExt }),
      ...(postalCode && { postalCode }),
      ...(state && { state }),
      ...(value && { value: Number(value) }),
      ...(type && { type: type as CustomsBrokerType }),
    }
  },
}

export function validateCustomsBroker(
  broker?: CustomsBroker | FormCustomsBroker | null,
) {
  return Boolean(broker?.companyName?.trim() && broker.phone?.trim())
}
