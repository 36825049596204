import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { defaultOptions, fetchJson } from '@fv/client-core'
import { type AppSettings } from '@fv/client-types'

import { apiUri } from '../../constants'
import { type ApiError } from '../../types/ApiError'

async function fetchAppSettings(): Promise<AppSettings> {
  const endpoint = `${apiUri}/app-settings`
  const options = defaultOptions()
  const response = await fetchJson(endpoint, options)
  return response.json
}

const select = (settings: AppSettings) => ({
  ...settings,
  enums: {
    ...settings.enums,
    equipmentTypes: settings.enums.equipmentTypes.slice().sort((a, b) => {
      const aName = a.name.toLocaleLowerCase()
      const bName = b.name.toLocaleLowerCase()
      return aName.localeCompare(bName)
    }),
  },
})

export function useAppSettings(enabled = true) {
  const query = useQuery<AppSettings, ApiError>(
    ['app-settings'],
    fetchAppSettings,
    {
      enabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      select,
      staleTime: Infinity,
    },
  )

  // App is unusable without app settings, throw to ErrorBoundary page
  useEffect(() => {
    if (query.error && query.error.status !== 401) {
      throw new Error(query.error.message)
    }
  }, [query.error])

  return query
}

export const useCurrencyTypes = () => {
  const appSettingsQuery = useAppSettings()
  return appSettingsQuery.data?.enums.currencyType ?? []
}
