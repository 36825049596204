import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react'
import toast from 'react-hot-toast'

import { FvButton, Icon } from '@fv/client-components'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import RadioButtonForm from '../../components/inputs/RadioButtonForm'
import { supportMessage } from '../../constants'
import { useCurrency } from '../../hooks/settings'
import { AuditRateBreakdown } from './components/AuditRateBreakdown'
import { AuditCardArrowCtn, AuditCardCtn } from './AuditCardArrowCtn'
import AuditNotes from './AuditNotes'
import { DatePickerInput } from './DatePickerInput'
import {
  useAddNote,
  useAddPayment,
  useAuditSetting,
  useRemoveDispute,
  useResendDisputeEmail,
  useResetAudit,
} from './queries'
import { type Audit, colorSuccess } from './types'

type DisputedAuditFormProps = {
  audit: Audit
  isActive: boolean
  setActive: Dispatch<SetStateAction<string | null>>
}

type DisputedAuditFormState = {
  acceptedAmount: string
  acceptedPortion: string
  invoiceNumber: string
  invoiceDate: string
  radioValue: 'accept-new' | 'accept-portion' | 'accept-all' | 'not-owed' | null
}

const listStatus = 'disputed'

const DisputedAuditForm = ({
  audit,
  isActive,
  setActive,
}: DisputedAuditFormProps) => {
  const {
    disputedAmount,
    invoiceNumber,
    invoiceDate,
    loadId,
    notes,
    quote,
    payments,
  } = audit

  const addNoteMutation = useAddNote()
  const canResendEmail = useAuditSetting('emailDispute')
  const { addPayment, isAddingPayment } = useAddPayment(audit, listStatus)
  const { resendDisputeEmail, isResending } = useResendDisputeEmail()
  const { resetAudit, isResettingAudit } = useResetAudit(audit, listStatus)
  const { removeInvoiceDispute, isRemovingDispute } = useRemoveDispute(
    audit,
    listStatus,
  )

  const hasPayments = (payments?.length ?? 0) > 0
  const noteRef = useRef<HTMLTextAreaElement | null>(null)
  const isBusy =
    addNoteMutation.isLoading ||
    isAddingPayment ||
    isResettingAudit ||
    isRemovingDispute ||
    isResending

  const FORM_DEFAULTS: DisputedAuditFormState = {
    acceptedAmount: '',
    acceptedPortion: '',
    invoiceNumber: invoiceNumber ?? '',
    invoiceDate: invoiceDate ?? '',
    radioValue: null,
  }

  const [formState, setFormState] =
    useState<DisputedAuditFormState>(FORM_DEFAULTS)

  const setValue = useCallback(
    (field: string, value: boolean | string) => {
      setFormState(prev => ({
        ...prev,
        [field]: value,
      }))
    },
    [setFormState],
  )

  const handleInvoiceDate = (date: string) => {
    setValue('invoiceDate', date)
  }

  function addNote() {
    if (isBusy) return

    if (!noteRef.current?.value.trim()) {
      noteRef.current?.setCustomValidity('Note is required.')
      noteRef.current?.reportValidity()
    } else {
      noteRef.current?.setCustomValidity('')

      addNoteMutation
        .mutateAsync({
          loadId,
          note: noteRef.current.value,
        })
        .then(() => {
          if (noteRef.current) noteRef.current.value = ''
        })
        .catch(() => {
          toast.error(`Unable to add note, ${supportMessage}`)
        })
    }
  }

  const priceParts = useCurrency()
  const [dollars, cents] = priceParts(disputedAmount)
  const disputedPriceDisplay = `$${dollars}.${cents}`

  return (
    <>
      <div className="flex items-center gap-2">
        <span className="flex flex-col items-center">
          <div className="price text-fv-orange font-oxygen">
            {disputedPriceDisplay}
          </div>
          <div className="text-xs">
            <Icon icon="chevron-right" className="text-fv-orange" /> In dispute{' '}
            <Icon icon="chevron-left" className="text-fv-orange" />
          </div>
        </span>
        <InputGroup
          className="mb-0"
          inputType="text"
          inputProps={{
            name: 'invoiceNumber',
            placeholder: 'Invoice number',
            onChange: e => {
              setValue(e.target.name, e.target.value)
            },
            value: formState.invoiceNumber ?? '',
          }}
          startContent={<InputAdornment position="start" icon="hashtag" />}
        />
        <DatePickerInput
          className="mb-0"
          isBusy={isBusy}
          invoiceDate={formState.invoiceDate}
          setInvoiceDate={handleInvoiceDate}
        />
        <FvButton
          className=""
          onClick={() => {
            !isBusy && resetAudit(loadId)
          }}
          icon={isResettingAudit ? 'spinner' : 'arrow-left'}
        >
          <span>Move back to outstanding queue</span>
        </FvButton>
      </div>
      <AuditCardArrowCtn className="flex-col">
        <RadioButtonForm
          checked={formState.radioValue === 'accept-new'}
          id={`accept-new_${quote._id}`}
          label="Enter an amount and mark as accepted."
          onChange={() => !isBusy && setValue('radioValue', 'accept-new')}
          onValidSubmit={() => {
            !isBusy &&
              addPayment({
                amount: formState.acceptedAmount,
                invoiceAmount: formState.acceptedAmount,
                invoiceNumber: formState.invoiceNumber,
                invoiceDate: formState.invoiceDate,
                loadId,
              })
          }}
        >
          <InputGroup
            inputType="amount"
            className="mb-0"
            required={true}
            inputProps={{
              name: 'acceptedAmount',
              onChange: e => {
                setValue(e.target.name, e.target.value)
              },
              placeholder: 'Accepted amount',
              disabled: isBusy,
              value: formState.acceptedAmount,
              min: 0,
              isPrice: true,
            }}
            startContent={
              <InputAdornment position="start" icon="dollar-sign" />
            }
          />
          <div className="form-group mb-0">
            <FvButton
              theme="default"
              type="submit"
              icon="archive"
              iconClass={colorSuccess}
              loading={isAddingPayment}
            >
              <span>Save to accepted</span>
            </FvButton>
          </div>
        </RadioButtonForm>

        <RadioButtonForm
          checked={formState.radioValue === 'accept-portion'}
          id={`accept-portion_${quote._id}`}
          label="Enter a portion as accepted and dispute the remainder."
          onChange={() => !isBusy && setValue('radioValue', 'accept-portion')}
          onValidSubmit={() => {
            !isBusy &&
              addPayment({
                amount: formState.acceptedPortion,
                invoiceNumber: formState.invoiceNumber,
                invoiceDate: formState.invoiceDate,
                loadId,
              }).then(() => setFormState(FORM_DEFAULTS))
          }}
        >
          <InputGroup
            inputType="amount"
            className="mb-0"
            required={true}
            inputProps={{
              name: 'acceptedPortion',
              onChange: e => {
                setValue(e.target.name, e.target.value)
              },
              placeholder: 'Accepted portion',
              disabled: isBusy,
              value: formState.acceptedPortion,
              min: 1,
              isPrice: true,
            }}
            startContent={
              <InputAdornment position="start" icon="dollar-sign" />
            }
          />
          <div className="form-group mb-0">
            <FvButton
              theme="default"
              type="submit"
              icon="archive"
              loading={isAddingPayment}
              iconClass={colorSuccess}
            >
              <span>Save to accepted</span>
            </FvButton>
          </div>
        </RadioButtonForm>

        <div className="radio radio--stacked">
          <input
            className="radio__input"
            id={`accept-all_${quote._id}`}
            onChange={() => {
              !isBusy &&
                addPayment({
                  amount: disputedAmount,
                  invoiceNumber: formState.invoiceNumber,
                  invoiceDate: formState.invoiceDate,
                  loadId,
                })
            }}
            type="radio"
          />
          <label
            htmlFor={`accept-all_${quote._id}`}
            className="radio__display"
          />
          <label htmlFor={`accept-all_${quote._id}`} className="radio__label">
            Accept the full invoiced amount.
          </label>
        </div>

        {hasPayments && (
          <div className="radio radio--stacked">
            <input
              className="radio__input"
              id={`not-owed_${quote._id}`}
              onChange={() => {
                !isBusy && removeInvoiceDispute({ loadId })
              }}
              type="radio"
            />
            <label
              htmlFor={`not-owed_${quote._id}`}
              className="radio__display"
            />
            <label htmlFor={`not-owed_${quote._id}`} className="radio__label">
              The remainder is not owed. Move this to archived.
            </label>
          </div>
        )}
      </AuditCardArrowCtn>
      <AuditCardCtn className="mb-5">
        <AuditRateBreakdown audit={audit} />
      </AuditCardCtn>

      <div className="divided-content divided-content--start mb-2">
        <FvButton
          onClick={() => {
            !isBusy && setActive(prev => (prev === loadId ? null : loadId))
          }}
          icon="plus"
        >
          <span>Add new note</span>
        </FvButton>
        {canResendEmail && (
          <FvButton
            onClick={() => {
              !isBusy && resendDisputeEmail({ loadId })
            }}
            className="ml-0"
            icon="paper-plane"
          >
            <span>Resend email</span>
          </FvButton>
        )}
      </div>

      {isActive ? (
        <div className="my-4 flex">
          <textarea
            autoComplete="off"
            className="form-control"
            data-lpignore
            name="note"
            readOnly={isBusy}
            ref={noteRef}
            required
            placeholder="Add note here"
          />
          <FvButton
            onClick={addNote}
            icon="check"
            className="z-10 -ml-1"
            theme="default"
            loading={addNoteMutation.isLoading}
            iconClass={colorSuccess}
          >
            <span>Save note</span>
          </FvButton>
        </div>
      ) : (
        <hr />
      )}

      <AuditNotes notes={notes} />
    </>
  )
}

export default DisputedAuditForm
