import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import values from 'lodash/values'
import { type ReactNode } from 'react'

import { CheckboxField, FvButton, Icon } from '@fv/client-components'
import { type SelectFieldOption } from '@fv/client-types'

import { InfoPanel } from '../../components/InfoPanel'
import { SelectField } from '../../components/inputs/SelectField'
import { Loading } from '../../components/Loading'
import { HasPermission, usePermissions } from '../auth'
import { useRemoveSpotQuoteContact } from '../spot-quote-contacts/mutations'
import { useSpotQuoteGroups } from '../spot-quote-contacts/queries'
import {
  ALL_CONTACTS_ID,
  type SpotQuoteContact,
} from '../spot-quote-contacts/types'
import {
  type ListMode,
  useSpotQuoteListActions,
  useSpotQuoteListGroup,
  useSpotQuoteListSelected,
} from './store'

type ListingProps = {
  filterUsers?: (u: SpotQuoteContact) => boolean
  headerClassName?: string
  className?: string
  listingClassName?: string
  footerComponent?: ReactNode
  mode: ListMode
}
export const SpotQuoteContactListing = ({
  filterUsers,
  listingClassName,
  className,
  footerComponent,
  mode,
  headerClassName,
}: ListingProps) => {
  const { data: groups } = useSpotQuoteGroups()
  const options =
    groups?.map<SelectFieldOption>(g => ({
      value: g.groupId,
      text: g.groupName,
    })) ?? []
  const groupId = useSpotQuoteListGroup()
  const { setGroupId } = useSpotQuoteListActions()
  const currentGroup = groups?.find(g => g.groupId === groupId)
  if (!currentGroup || !options) {
    return <Loading />
  }
  const users = filterUsers
    ? currentGroup.users.filter(filterUsers)
    : currentGroup.users
  const contactsByCarrier = orderBy(
    values(groupBy(users, 'carrierId')),
    i => i[0].carrierName,
  )
  return (
    <div className={clsx('flex flex-col overflow-hidden', className)}>
      <div className={clsx(headerClassName)}>
        <div className="form-group mb-0">
          <SelectField
            name="editing-group"
            value={groupId}
            onChange={e => setGroupId(e.target.value)}
            options={options}
          />
        </div>
        {mode === 'edit' && (
          <>
            <div>
              <Icon icon="arrow-left" className="color-dark" />
            </div>
            <div>
              <span>
                Choose a group
                <HasPermission name="manage"> to edit</HasPermission>
              </span>
            </div>
          </>
        )}
      </div>
      <div className={clsx('flex-1 overflow-y-auto', listingClassName)}>
        {mode === 'edit' && (
          <InfoPanel theme="beer" className="my-4" pointer="bottom">
            <p className="mb-0">
              Here are the contacts to which you will be sending a spot quote.
              If you wish to exclude providers from bidding on this shipment,
              simply un-check them from this list.
            </p>
          </InfoPanel>
        )}
        <div>
          {contactsByCarrier?.map(contactGroup => (
            <CarrierGroup
              mode={mode}
              key={contactGroup[0].carrierId ?? 'other'}
              groupName={currentGroup.groupName}
              contactGroup={contactGroup}
            />
          ))}
        </div>
      </div>
      {footerComponent}
    </div>
  )
}

type CarrierGroupProps = {
  groupName: string
  mode: ListMode
  contactGroup: SpotQuoteContact[]
}
export const CarrierGroup = ({
  groupName,
  mode,
  contactGroup,
}: CarrierGroupProps) => {
  const name = contactGroup[0].carrierName
  return (
    <div className="my-6">
      <div className="border-b-fv-orange mb-2 border-b">
        <span>{name}</span>
      </div>
      {contactGroup.map(contact => (
        <ContactItem
          mode={mode}
          key={contact.userId}
          contact={contact}
          groupName={groupName}
        />
      ))}
    </div>
  )
}

type ContactItemProps = {
  contact: SpotQuoteContact
  mode: ListMode
  groupName: string
}
export const ContactItem = ({ contact, mode, groupName }: ContactItemProps) => {
  const groupId = useSpotQuoteListGroup()
  const emails = useSpotQuoteListSelected()
  const { addEmails, removeEmails } = useSpotQuoteListActions()
  const removeContact = useRemoveSpotQuoteContact(true)
  const { canManage } = usePermissions()
  const handleDeleteClick = async () => {
    const msg =
      groupId === ALL_CONTACTS_ID
        ? `Are you sure you want to remove ${contact.email} from your account?`
        : `Are you sure you want to remove ${contact.email} from ${groupName}`
    if (window.confirm(msg)) {
      return await removeContact.mutateAsync({
        groupId,
        userId: contact.userId,
      })
    }
    return
  }

  return (
    <div
      className={clsx('flex', {
        'border-b leading-10':
          mode === 'send' || mode === 'recipients' || !canManage,
      })}
    >
      {(mode === 'edit' || mode === 'send') && (
        <CheckboxField
          name={contact.userId}
          checked={emails?.includes(contact.email)}
          onChange={e =>
            e.target.checked
              ? addEmails([contact.email])
              : removeEmails([contact.email])
          }
          label={contact.email}
        />
      )}
      {mode === 'recipients' && <span>{contact.email}</span>}

      {mode === 'edit' && canManage && (
        <>
          <div className="border-fv-blue-300 mx-2 flex-1 -translate-y-5 border-b border-dashed" />
          <div>
            <FvButton icon="times" theme="plain" onClick={handleDeleteClick} />
          </div>
        </>
      )}
    </div>
  )
}
