import orderBy from 'lodash/orderBy'
import { type ChangeEvent, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, SelectField } from '@fv/client-components'
import { acceptedFileTypes } from '@fv/client-core'
import { type LoadDocumentType, loadDocumentTypeConfigs } from '@fv/models'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { supportMessage } from '../../constants'
import { useUploadLoadDocument } from '../../features/documents/mutations'

type SelectableDocumentType = LoadDocumentType | 'carrier-bol' | ''

const shipperOptions = orderBy(
  [...loadDocumentTypeConfigs],
  [i => i.key === 'other', i => i.name],
  ['asc', 'asc'],
)
  .filter(c => c.allowedSources.includes('shipper'))
  .map(({ key, name }) => ({
    text: name,
    value: key,
  }))

const fileInputStyle = { display: 'none' }
const MAX_FILE_SIZE = 52428800 // 50 MB
const docTypeOptions: Array<{
  text: string
  value: SelectableDocumentType
}> = [{ text: 'Choose document type...', value: '' }, ...shipperOptions]

type Props = {
  defaultDocType?: LoadDocumentType
  handleClose?: () => void
  loadId: string
  onSuccess?: () => void
  requireDocType?: boolean
}

const UploadDocumentField = ({
  defaultDocType,
  handleClose,
  loadId,
  onSuccess,
  requireDocType,
}: Props) => {
  const [description, setDescription] = useState('')
  const [selectedDocType, setSelectedDocType] =
    useState<SelectableDocumentType>(defaultDocType ?? '')

  const fileInputRef = useRef<HTMLInputElement>(null)
  const uploadDocument = useUploadLoadDocument()

  function openFileSelectionDialog(): void {
    if (uploadDocument.isLoading) return
    if (!selectedDocType) {
      toast.error('Please select a document type first.')
      return
    }

    fileInputRef.current?.click()
  }

  function selectFile(e: ChangeEvent<HTMLInputElement>): void {
    const fileList = e.target.files
    if (!fileList?.length) return

    const [file] = Array.from(fileList)
    if (!file) return

    if (file.size > MAX_FILE_SIZE) {
      if (fileInputRef.current) fileInputRef.current.value = ''
      toast.error(
        `Please choose a file that is smaller than ${MAX_FILE_SIZE / 1024} KB`,
      )
      return
    }

    setDescription(`${file.name} - ${file.size} bytes`)

    if (!selectedDocType) {
      toast.error('No document type selected.')
      return
    }

    const isCarrierBOL = selectedDocType === 'carrier-bol'

    uploadDocument
      .mutateAsync({
        document: file,
        documentSource: isCarrierBOL ? 'carrier' : 'shipper',
        documentType: isCarrierBOL ? 'bol' : selectedDocType,
        loadId,
      })
      .then(() => {
        if (fileInputRef.current) fileInputRef.current.value = ''
        setDescription('')
        setSelectedDocType('')
        onSuccess?.()
      })
      .catch(e => {
        toast.error(
          e?.message ?? `Unable to upload document, ${supportMessage}`,
        )
      })
  }

  return (
    <>
      {requireDocType && (
        <SelectField
          className="form-control form-control--select mb-2"
          disabled={uploadDocument.isLoading}
          name="documentType"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            setSelectedDocType(e.target.value as SelectableDocumentType)
          }}
          options={docTypeOptions}
          value={selectedDocType}
        />
      )}
      <InputGroup
        inputType="text"
        inputProps={{
          value: description,
          name: 'description',
          disabled: true,
        }}
        endContent={
          <>
            <InputAdornment position="end">
              <FvButton
                theme="default"
                onClick={openFileSelectionDialog}
                icon={uploadDocument.isLoading ? 'spinner' : 'file-plus'}
                transform="up-1"
              >
                <span>Choose file</span>
              </FvButton>
            </InputAdornment>
            {requireDocType && handleClose && (
              <InputAdornment position="end" className="self-center">
                <FvButton
                  theme="plain"
                  onClick={handleClose}
                  icon="times"
                  transform="up-1"
                />
              </InputAdornment>
            )}
          </>
        }
      />

      <input
        accept={acceptedFileTypes}
        name="file"
        onChange={selectFile}
        ref={fileInputRef}
        style={fileInputStyle}
        type="file"
      />
    </>
  )
}

export default UploadDocumentField
