import clsx from 'clsx'
import { useRef } from 'react'

import { ValidatedForm } from '@fv/client-components'
import { submitForm } from '@fv/client-core'
import {
  type LegacyReadOnlyDetail,
  type ProductCatalogEntry,
  type ProductMode,
} from '@fv/client-types'

import { FormActions } from '../../components/forms/FormActions'
import InfoBox from '../../components/InfoBox'
import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import {
  useFreightClassOptions,
  useHazardClassOptions,
  usePackingGroupOptions,
  useSecondaryPackageOptions,
  yesNoOptions,
} from '../../hooks/settings/useEnumOptions'
import { buildProductCatalogEntry } from './commodityUtils'
import { FreightClassEstimate } from './FreightClassEstimate'
import { PackagingSelector } from './PackagingSelector'
import { useCreateHandlingUnit } from './useCreateHandlingUnit'
import { useProductCatalog } from './useProductCatalog'
import { useProductCatalogForm } from './useProductCatalogForm'
import { useUpdateHandlingUnit } from './useUpdateHandlingUnit'

type Props = {
  initialValues?: ProductCatalogEntry
  mode: ProductMode
}

export const ProductCatalogForm = ({ initialValues, mode }: Props) => {
  const { handleChange, values, setValues } = useProductCatalogForm(
    mode,
    initialValues,
  )
  const { hazardClassOptions, hazardSubClassOptions } = useHazardClassOptions()
  const createProduct = useCreateHandlingUnit()
  const formRef = useRef<HTMLFormElement>(null)
  const freightClassOptions = useFreightClassOptions()
  const isEditing = !!initialValues
  const packingGroupOptions = usePackingGroupOptions()
  const productType = mode === 'ltl' ? 'LTL / Truckload' : 'parcel'
  const selectProduct = useProductCatalog(s => s.selectProduct)
  const showListView = useProductCatalog(s => s.showList)
  const unitOptions = useSecondaryPackageOptions(mode, values.package)
  const updateProduct = useUpdateHandlingUnit()
  const isLoading = createProduct.isLoading || updateProduct.isLoading

  function onValidSubmit() {
    if (isLoading) return

    const mutation = isEditing ? updateProduct : createProduct

    mutation.mutate(values, {
      onSuccess: handlingUnit => {
        const product = buildProductCatalogEntry(mode, handlingUnit)
        selectProduct(product)
      },
    })
  }

  const buildLockToggle = (name: keyof LegacyReadOnlyDetail) => (
    <InputAdornment className="bg-skin-3">
      <input
        checked={values.readOnly[name]}
        disabled={!values[name]}
        name={`readOnly.${name}`}
        onChange={handleChange}
        type="checkbox"
      />
    </InputAdornment>
  )

  const getInputProps = (name: keyof LegacyReadOnlyDetail) => ({
    className: clsx({ 'form-control--highlight': !!values[name] }),
    disabled: isLoading,
    name,
    onChange: handleChange,
    value: values[name],
  })

  const packageProps = getInputProps('package')

  const lwhRequired = !!values.length || !!values.width || !!values.height

  return (
    <>
      <div className="slider-header">
        {isEditing ? 'Edit' : 'Add'} {productType} product
      </div>

      <InfoBox
        className="mb-8"
        icon="sticky-note"
        iconProps={{ className: '', transform: 'down-3' }}
      >
        Only enter information in the fields that you want to auto populate when
        selecting a product for quoting. Checking a field designates it as
        non-editable during the quoting process.
      </InfoBox>

      <ValidatedForm
        className="form-row"
        onValidSubmit={onValidSubmit}
        ref={formRef}
      >
        <InputGroup
          className={clsx({
            'col-4': mode === 'parcel',
            'col-5': mode === 'ltl',
          })}
          endContent={buildLockToggle('description')}
          inputProps={getInputProps('description')}
          inputType="text"
          label="Description"
          required
        />

        <InputGroup
          className={clsx({
            'col-4': mode === 'parcel',
            'col-5': mode === 'ltl',
          })}
          endContent={buildLockToggle('package')}
          inputProps={packageProps}
          inputType="custom"
          label="Packaging"
        >
          <PackagingSelector
            allowCustom
            includeEmpty
            className={packageProps.className}
            length={Number(values.length)}
            workflow={mode}
            name={packageProps.name}
            value={values.package}
            width={Number(values.width)}
            onChange={val => {
              setValues({
                package: val.type,
                length: val.length,
                width: val.width,
              })
            }}
          />
        </InputGroup>

        {mode === 'parcel' && (
          <InputGroup
            className="col-4"
            endContent={buildLockToggle('declaredValue')}
            inputProps={{
              ...getInputProps('declaredValue'),
              isPrice: true,
              min: 1,
            }}
            inputType="amount"
            label="Declared value"
            startContent={
              <InputAdornment icon="dollar-sign" position="start" />
            }
          />
        )}

        {mode === 'ltl' && (
          <InputGroup
            className="col-2"
            endContent={buildLockToggle('stackable')}
            inputProps={{
              ...getInputProps('stackable'),
              options: yesNoOptions,
            }}
            inputType="select"
            label="Stackable?"
          />
        )}

        {mode === 'ltl' && (
          <InputGroup
            className="col-3"
            endContent={buildLockToggle('pieces')}
            inputProps={{
              ...getInputProps('pieces'),
              min: 1,
            }}
            inputType="amount"
            label="Quantity"
          />
        )}

        <InputGroup
          className="col-3"
          endContent={
            <>
              <InputAdornment label="lbs." />
              {buildLockToggle('weight')}
            </>
          }
          inputProps={{
            ...getInputProps('weight'),
            min: 1,
          }}
          inputType="amount"
          label="Weight"
        />

        <div className="form-group form-group--collection length-width-height col-6">
          <InputGroup
            endContent={buildLockToggle('length')}
            inputProps={{
              ...getInputProps('length'),
              min: 1,
            }}
            inputType="amount"
            label="L"
            required={lwhRequired}
          />

          <InputGroup
            endContent={buildLockToggle('width')}
            inputProps={{
              ...getInputProps('width'),
              min: 1,
            }}
            inputType="amount"
            label="W"
            required={lwhRequired}
          />

          <InputGroup
            endContent={
              <>
                {buildLockToggle('height')}
                <InputAdornment label="in." />
              </>
            }
            inputProps={{
              ...getInputProps('height'),
              min: 1,
            }}
            inputType="amount"
            label="H"
            required={lwhRequired}
          />
        </div>

        {mode === 'ltl' && (
          <FreightClassEstimate
            weight={Number(values.weight)}
            height={Number(values.height)}
            width={Number(values.width)}
            length={Number(values.length)}
            quantity={Number(values.pieces)}
            containerClassName="form-group col-12"
          />
        )}

        {mode === 'parcel' && (
          <InputGroup
            className="col-3"
            endContent={buildLockToggle('nonStandard')}
            inputProps={{
              ...getInputProps('nonStandard'),
              options: yesNoOptions,
            }}
            inputType="select"
            label="Unboxed or crated?"
          />
        )}

        {mode === 'ltl' && (
          <>
            <InputGroup
              className="col-3"
              endContent={buildLockToggle('nmfc')}
              inputProps={getInputProps('nmfc')}
              inputType="text"
              label="NMFC item #"
            />

            <InputGroup
              className="col-2"
              endContent={buildLockToggle('freightClass')}
              inputProps={{
                ...getInputProps('freightClass'),
                options: freightClassOptions,
              }}
              inputType="select"
              label="Freight class"
            />

            <InputGroup
              className="col-2"
              endContent={buildLockToggle('saidToContain')}
              inputProps={{
                ...getInputProps('saidToContain'),
                min: 1,
              }}
              inputType="amount"
              label="Contains"
            />

            <InputGroup
              className="col-3"
              endContent={buildLockToggle('saidToContainPackagingType')}
              inputProps={{
                ...getInputProps('saidToContainPackagingType'),
                options: unitOptions,
              }}
              inputType="select"
              label="Units"
            />

            <InputGroup
              className="col-2"
              endContent={buildLockToggle('hazardous')}
              inputProps={{
                ...getInputProps('hazardous'),
                options: yesNoOptions,
              }}
              inputType="select"
              label="Hazmat?"
            />
          </>
        )}

        {mode === 'ltl' && values.hazardous === 'Yes' && (
          <>
            <InputGroup
              className="col-4"
              endContent={buildLockToggle('hazmatId')}
              inputProps={getInputProps('hazmatId')}
              inputType="text"
              label="Hazmat ID"
            />

            <InputGroup
              className="col-4"
              endContent={buildLockToggle('packingGroup')}
              inputProps={{
                ...getInputProps('packingGroup'),
                options: packingGroupOptions,
              }}
              inputType="select"
              label="Packing group"
            />

            <InputGroup
              className="col-4"
              endContent={buildLockToggle('specialPermit')}
              inputProps={getInputProps('specialPermit')}
              inputType="text"
              label="Special permit #"
            />

            <InputGroup
              className="col-4"
              endContent={buildLockToggle('hazardClass')}
              inputProps={{
                ...getInputProps('hazardClass'),
                options: hazardClassOptions,
              }}
              inputType="select"
              label="Hazard class"
            />

            <InputGroup
              className="col-4"
              endContent={buildLockToggle('secondaryHazardClass')}
              inputProps={{
                ...getInputProps('secondaryHazardClass'),
                options: hazardSubClassOptions,
              }}
              inputType="select"
              label="Sub hazard class 1"
            />

            <InputGroup
              className="col-4"
              endContent={buildLockToggle('tertiaryHazardClass')}
              inputProps={{
                ...getInputProps('tertiaryHazardClass'),
                options: hazardSubClassOptions,
              }}
              inputType="select"
              label="Sub hazard class 2"
            />
          </>
        )}
      </ValidatedForm>

      <hr />
      <FormActions
        onCancel={() => showListView()}
        onSubmit={() => submitForm(formRef.current)}
        submitText="Save and apply"
      />
    </>
  )
}
