import clsx from 'clsx'
import { type ChangeEventHandler, type RefObject } from 'react'

import { Icon } from '@fv/client-components'

import YesNoInput from '../../components/inputs/YesNoInput'
import { useToggleUserPreference } from '../settings/user-preferences/mutations'
import { useConfirmedListParams } from './hooks/useShipmentListParams'

type Props = {
  listRef?: RefObject<HTMLDivElement>
  checked?: boolean
  onChange?: (v: boolean) => void
}
export const CondensedViewToggle = ({ listRef, checked, onChange }: Props) => {
  const { setPreference } = useToggleUserPreference('shipment-list-condensed')
  const { activeLoadId } = useConfirmedListParams()

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange?.(e.target.checked)
    setPreference(e.target.checked)
    setTimeout(() => {
      if (listRef?.current && activeLoadId) {
        const el = listRef?.current?.querySelector(
          `[data-loadid="${activeLoadId}"]`,
        )
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    })
  }
  return (
    <div className="flex items-center px-3">
      <label className={clsx(!checked && 'b1500:hidden')} htmlFor="expanded">
        Condensed view
      </label>
      <Icon icon="list" className={clsx('hidden', !checked && 'b1500:block')} />
      <YesNoInput
        checked={checked}
        className="yes-no-toggle--on-off ml-3"
        name="expanded"
        onChange={handleChange}
      />
    </div>
  )
}
