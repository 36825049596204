import dayjs, { ConfigType } from 'dayjs'
import { memo, useMemo } from 'react'

import { Tooltip } from './Tooltip'

type Props = {
  val: ConfigType
  utc?: boolean
  format?: string
}
export const FvDate = memo(function FvDate({
  val,
  utc = true,
  format = 'MMM D',
}: Props) {
  const date = useMemo(
    () => (utc ? dayjs.utc(val).local() : dayjs(val)).format(format),
    [val, utc, format],
  )
  if (!val) return null
  return <span className="whitespace-nowrap">{date}</span>
})

type RelativeProps = {
  val: ConfigType
  tooltip?: boolean
}
export const FvRelativeDate = ({ val, tooltip }: RelativeProps) => {
  if (!val) return null

  const date = dayjs.utc(val).local()
  const display = date.fromNow()
  return (
    <Tooltip label={tooltip && date.format('YYYY-MM-DD h:mma')}>
      <span>{display}</span>
    </Tooltip>
  )
}
