import { useState } from 'react'

import { type CredentialDetail } from '@fv/client-types'

import { Loading } from '../../../components/Loading'
import { freightviewUri } from '../../../constants'
import { useUserSettings } from '../../../hooks/settings'
import { useAccountCarrierFromState } from '../AccountCarrierFormProvider'
import { type BaseProvisionFormProps } from '../types'
import { AccountAddressStep } from './FedexAddress'
import { FedexMfaInput } from './FedexMfaInput'
import { FedexMfaOptions } from './FedexMfaOptions'
import { FedexMfaSuccess } from './FedexMfaSuccess'
import { TermsStep } from './FedexTerms'
import { useFxfeWizardContext } from './hooks'
import {
  FedexWizardContext,
  type FxfeMfaInitializationRequest,
  type MfaOptionItem,
  type StepType,
} from './types'

const getDetailValue = (
  details: CredentialDetail[] | undefined,
  type: string,
) => details?.find(d => d.type === type)?.value?.toString()

export const FedexProvisioning = ({
  onCancel,
  onSuccess,
}: BaseProvisionFormProps) => {
  const token = useState<string>('')
  const step = useState<StepType>('terms')
  const options = useState<MfaOptionItem[]>([])
  const selectedOption = useState<MfaOptionItem>()
  const accountCarrier = useAccountCarrierFromState()
  const { data: userSettings } = useUserSettings()
  const billTo = userSettings?.registration?.billTo?.[0]
  const details = accountCarrier?.details
  const accountInfo = useState<FxfeMfaInitializationRequest>({
    accountNumber: getDetailValue(details, 'express-account-number'),
    address:
      getDetailValue(details, 'override-address') || billTo?.address || '',
    city: getDetailValue(details, 'override-city') || billTo?.city || '',
    state: getDetailValue(details, 'override-state') || billTo?.state || '',
    postalCode:
      getDetailValue(details, 'override-postal-code') ||
      billTo?.postalCode ||
      '',
    country: 'us',
  })

  return (
    <FedexWizardContext.Provider
      value={{
        options,
        token,
        step,
        selectedOption,
        accountInfo,
        onExit: onCancel,
        onComplete: onSuccess,
      }}
    >
      <div className="pb-4">
        <img
          src={`${freightviewUri}/img/fedex-orange-purple.png`}
          alt="FedEx Logo"
          width={100}
        />
      </div>
      <FedexStepSelector />
    </FedexWizardContext.Provider>
  )
}

const FedexStepSelector = () => {
  const {
    step: [step],
  } = useFxfeWizardContext()

  switch (step) {
    case 'terms':
      return <TermsStep />
    case 'account-address':
      return <AccountAddressStep />
    case 'mfa-option':
      return <FedexMfaOptions />
    case 'mfa-input':
      return <FedexMfaInput />
    case 'success':
      return <FedexMfaSuccess />
    default:
      return <Loading />
  }
}
