import toast from 'react-hot-toast'

import { FvLinkButton } from '@fv/client-components'
import { downloadCSV } from '@fv/client-core'

import InfoBox from '../../components/InfoBox'
import { FileUploadInput } from '../../components/inputs/FileUploadInput'
import { type AddressBookUploadProps } from './types'
import { addressBookTemplate, parseAddressCsv } from './utils'

export const AddressBookUploadField = ({
  onChange,
}: AddressBookUploadProps) => {
  const handleFileSelect = async (file?: File) => {
    if (!file) return
    try {
      const { parseCSVFile } = await import('../../utils/parse')
      const csvData = await parseCSVFile(file)
      const parsed = parseAddressCsv(csvData)
      onChange(
        parsed.length < 10
          ? parsed.concat([...Array(10 - parsed.length)].map(i => ({})))
          : parsed,
      )
    } catch (e) {
      console.error(e)
      toast.error('There was an error parsing the csv file')
    }
  }
  return (
    <InfoBox gradient custom>
      <p className="mb-4">
        Please upload a .csv file that contains your address book. Here's an{' '}
        <FvLinkButton
          theme="underlined"
          onClick={() => {
            downloadCSV(addressBookTemplate, 'AddressBookTemplate')
          }}
        >
          example.csv
        </FvLinkButton>{' '}
        to get you started.{' '}
        <FvLinkButton
          href="https://blog.freightview.com/uploading-your-address-book-to-freightview"
          target="_blank"
          rel="noreferrer"
          theme="underlined"
        >
          Need more help?
        </FvLinkButton>
      </p>
      <FileUploadInput
        label="Choose a CSV file"
        name="file"
        onFileSelect={handleFileSelect}
      />
    </InfoBox>
  )
}
