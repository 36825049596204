import InfoBox from '../../../components/InfoBox'
import { useCarrierDetails } from '../AccountCarrierFormProvider'
import { type CredentialFormProps } from '../types'
import { UsernamePasswordForm } from './UsernamePasswordForm'

export const DeferredProvisioningNotice = () => {
  const carrierDetails = useCarrierDetails()
  return (
    <InfoBox icon="thumbs-up">
      After the onboarding process, we'll walk you through connecting your{' '}
      {carrierDetails.name} account.
    </InfoBox>
  )
}

export const DeferredFedexConnection = (props: CredentialFormProps) => {
  return (
    <div>
      <DeferredProvisioningNotice />
      <UsernamePasswordForm {...props} />
    </div>
  )
}
