import { Children, PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  delimiter: JSX.Element
  className?: string
}>

export const DelimitedContent = ({ children, delimiter, className }: Props) => {
  const arrayChildren = Children.toArray(children)
  return (
    <>
      {Children.map(arrayChildren, (child, index) => {
        return (
          <>
            {index !== 0 && <span className={className}>{delimiter}</span>}
            {child}
          </>
        )
      })}
    </>
  )
}

export const Slash = () => <>&nbsp;/&nbsp;</>
