import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'

interface UpdateDeliveryDate {
  loadId: string
  date?: Date | null
}
async function updateDeliveryDate({
  loadId,
  date,
}: UpdateDeliveryDate): Promise<FullShipment> {
  const endpoint = `${apiUri}/shipments/${loadId}/delivery-date`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      date: date || null,
    }),
    method: 'PUT',
  })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json as FullShipment
  throw response.errorMessage
}

export function useUpdateDeliveryDate() {
  const updateCachedLoad = useUpdateCachedLoad()
  return useMutation(updateDeliveryDate, {
    onSettled(updatedLoad, error) {
      if (error) {
        toast.error('There was an error updating the delivery date')
        return
      }

      if (updatedLoad) updateCachedLoad({ updatedLoad })
    },
  })
}
