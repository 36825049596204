import { type KeyboardEvent, useEffect, useState } from 'react'

import { FvButton } from '@fv/client-components'
import { isEnter } from '@fv/client-core'
import { type PropsWithClassName } from '@fv/client-types'

import { InputAdornment, InputGroup } from './InputGroup'

type Props = {
  initialValue?: string
  onChange: (value: string) => void
  isLoading?: boolean
  placeholder?: string
  autoFocus?: boolean
}
export const SearchField = ({
  initialValue,
  onChange,
  className,
  isLoading,
  placeholder,
  autoFocus,
}: PropsWithClassName<Props>) => {
  const [searchText, setSearchText] = useState(initialValue ?? '')
  const [showClear, setShowClear] = useState(!!initialValue)

  useEffect(() => {
    setShowClear(!!initialValue)
    setSearchText(initialValue ?? '')
  }, [initialValue])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isEnter(e)) {
      onChange(searchText)
      setShowClear(true)
    }
  }
  const handleClearClick = () => {
    setShowClear(false)
    setSearchText('')
    onChange('')
  }
  return (
    <InputGroup
      className={className}
      startContent={
        <InputAdornment
          icon={isLoading ? 'spinner' : 'search'}
          iconClass="text-fv-green-100"
          position="start"
        />
      }
      endContent={
        showClear && (
          <InputAdornment position="end">
            <FvButton
              className="h-full w-full"
              onClick={handleClearClick}
              theme="default"
              icon="times"
            />
          </InputAdornment>
        )
      }
      inputType="text"
      inputProps={{
        placeholder,
        value: searchText,
        autoFocus,
        onKeyDown: handleKeyDown,
        disabled: isLoading,
        onChange: e => setSearchText(e.target.value),
        name: 'search',
      }}
    />
  )
}
