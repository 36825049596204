import { useMemo } from 'react'

import { type LoadDocument } from '@fv/client-types'
import { loadDocumentTypeConfigs } from '@fv/models'

import { alphabeticalSorter } from '../../utils/alphabeticalSorter'

export interface LabeledDocument extends LoadDocument {
  label: string
}

export function useLabeledDocuments(
  documents?: LoadDocument[],
  opts?: { filter?: (d: LoadDocument) => boolean },
): LabeledDocument[] {
  return useMemo(() => {
    if (!documents) return []

    return documents
      .filter(d => {
        // Not showing these right now because there could be a ton of them.
        // Just showing `label-combined` type labels at the moment.
        if (d.type === 'label') return false
        if (opts?.filter) return opts.filter(d)
        return true
      })
      .map(d => ({
        ...d,
        label: composeDocumentLabel(d),
      }))
      .sort((a, b) => {
        const bolTypes = ['bol', 'corrected-bol', 'signed-bol']

        if (bolTypes.includes(a.type) && !bolTypes.includes(b.type)) return -1
        if (bolTypes.includes(b.type) && !bolTypes.includes(a.type)) return 1

        return alphabeticalSorter('label')(a, b)
      })
  }, [documents, opts])
}

function composeDocumentLabel(document: LoadDocument) {
  const { fileName, source, type } = document
  const sources = {
    carrier: 'Carrier',
    shipper: 'Shipper',
    system: 'Freightview',
  }

  if (type === 'bol') return `${sources[source]} BOL`
  if (type === 'label-combined') return `${sources[source]} Label`
  if (type === 'other') return `Other (${fileName})`

  return loadDocumentTypeConfigs.find(c => c.key === type)?.name ?? fileName
}
