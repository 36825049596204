import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  showApiError,
} from '@fv/client-core'
import {
  type CreateSpotQuoteRequestDTO,
  type FullShipment,
  type Mode,
} from '@fv/client-types'
import { type LoadBidExpiration } from '@fv/models'

import { apiUri } from '../../constants'
import { type HydratedRecipient } from '../../types'
import {
  type QuoteSettings,
  quoteSettingsKey,
} from '../settings/useQuoteSettings'
import { loadKeys } from '../shipments/useLoad'

type ApiResponse = {
  errorMessage?: {
    errors?: string[]
    message?: string
    name?: string
    requestId?: string
    status: number
  }
  ok: boolean
  json: HydratedRecipient[]
}

async function sendSpotQuoteRequests(
  dto: CreateSpotQuoteRequestDTO & {
    loadIds: string[]
  },
): Promise<
  Array<{
    id: string
    spotQuoteRecipients: HydratedRecipient[]
  }>
> {
  const {
    alternateTypes,
    emails,
    loadIds,
    selectedGroupIds,
    shipperMessage,
    systemMessage,
    targetPrices,
    bidExpirations: bidExpirationDates,
  } = dto

  const getTargetPrice = (id: string) => {
    if (id && targetPrices && targetPrices.length > 0) {
      const tp = targetPrices.find(t => t.loadId === id)
      return tp ? tp.targetPrice : null
    } else {
      return null
    }
  }

  const getBidExpirationDate = (id: string) => {
    if (id && bidExpirationDates && bidExpirationDates.length > 0) {
      const bid = bidExpirationDates.find(b => b.loadId === id)
      return bid && bid.bidExpiration?.utc ? bid.bidExpiration : null
    } else {
      return null
    }
  }

  const buildOptions = (tp: number, bidExpiration: LoadBidExpiration) => {
    return buildFetchOptionsWithAuth({
      body: JSON.stringify({
        emails,
        shipperMessage,
        systemMessage,
        ...(alternateTypes && { alternateTypes }),
        ...(selectedGroupIds && { selectedGroupIds }),
        ...(tp && { targetPrice: tp }),
        ...(bidExpiration && { bidExpiration }),
      }),
      method: 'POST',
    })
  }

  const responses = await Promise.all(
    loadIds.map(async id => {
      const tp = getTargetPrice(id)
      const bidExpDate = getBidExpirationDate(id)
      const options = buildOptions(tp, bidExpDate)
      const endpoint = `${apiUri}/shipments/${id}/spot-quote-requests`
      const response = (await fetchJson(endpoint, options)) as ApiResponse
      return { id, response }
    }),
  )

  if (responses.every(res => res.response.ok)) {
    return responses.map(res => ({
      id: res.id,
      spotQuoteRecipients: res.response.json,
    }))
  } else {
    const error = responses.find(r => !r.response.ok)?.response
    if (error) {
      throw error.json
    }
    return []
  }
}

export function useSendSpotQuoteRequests(mode: Mode = 'truckload') {
  const queryClient = useQueryClient()

  return useMutation(sendSpotQuoteRequests, {
    onSettled: (loads, error, dto) => {
      if (error) return showApiError('Unable to send out for quotes', error)

      loads?.forEach(load => {
        queryClient.setQueryData(
          ['spot-quote-recipients', load.id],
          () => load.spotQuoteRecipients,
        )
      })

      if (dto.alternateTypes?.length) {
        loads?.forEach(load => {
          queryClient.setQueryData<FullShipment>(loadKeys.id(load.id), prev => {
            return (
              prev && {
                ...prev,
                equipment: {
                  ...prev.equipment,
                  alternateTypes: dto.alternateTypes?.filter(
                    t => t !== prev.equipment.type,
                  ),
                },
              }
            )
          })
        })
      }

      if (dto.selectedGroupIds?.length) {
        queryClient.setQueryData(
          [quoteSettingsKey, mode],
          (prev?: QuoteSettings) => ({
            accessorialTypes: prev?.accessorialTypes ?? [],
            apiEnabledCarriers: prev?.apiEnabledCarriers ?? [],
            equipmentTypes: prev?.equipmentTypes ?? [],
            packageTypes: prev?.packageTypes ?? [],
            recentSpotQuoteGroups: dto.selectedGroupIds?.map(groupId => ({
              groupId,
            })),
          }),
        )
      }
    },
  })
}
