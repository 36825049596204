import { useMutation } from '@tanstack/react-query'

import { shipperFetch } from '../../../utils/shipperFetch'

async function exportShipmentList({
  queryString,
  advancedExport,
}: {
  queryString: string
  advancedExport: boolean
}) {
  const params = new URLSearchParams(queryString) // Ensure no leading `?`
  return await shipperFetch(
    `/shipments/ready-completed/export?${params.toString()}`,
    {
      body: {
        advanced: advancedExport,
      },
      method: 'POST',
    },
  )
}

export function useExportList() {
  return useMutation(exportShipmentList)
}

async function exportUserList({ queryString }: { queryString: string }) {
  const params = new URLSearchParams(queryString) // Ensure no leading `?`
  return await shipperFetch(`/analytics/users-export?${params.toString()}`, {
    method: 'POST',
  })
}

export function useUserExportList() {
  return useMutation(exportUserList)
}
