import './SliderPanel.scss'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

export type SliderPanelProps = PropsWithChildren<{
  className?: string
  closeButtonPosition?: 'bottom' | 'right'
  closePanel?: () => void
  isOpen: boolean
  position?: 'bottom' | 'right'
  width?: string
}>

export const SliderPanel = ({
  children,
  className,
  closeButtonPosition,
  closePanel,
  isOpen,
  position = 'right',
  width = '48rem',
}: SliderPanelProps) => {
  if (position === 'bottom') width = '100vw'

  return createPortal(
    <>
      <div
        className={clsx(
          'fixed z-[150] max-w-[100vw] p-6 transition-transform duration-300 ease-in-out',
          className,
          {
            'bg-[#fafafa]': !className?.includes('bg-'),
            'border-fv-gray bottom-0 left-0 border-t': position === 'bottom',
            'right-0 top-0 h-screen overflow-auto': position === 'right',
            'translate-y-0': position === 'bottom' && isOpen,
            'translate-y-[100vh]': position === 'bottom' && !isOpen,
          },
        )}
        style={{
          width,
          ...(position === 'right' && {
            transform: `translateX(${width})`,
            ...(isOpen && { transform: 'translateX(0)' }),
          }),
        }}
      >
        {isOpen && children}
        {isOpen && closeButtonPosition === 'right' && (
          <button
            className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-[50%] border-[1px] border-solid border-[#b3b3b3] bg-[#fffde9] p-0"
            onClick={closePanel}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </div>

      {isOpen && <div className="flyout-backdrop" onClick={closePanel} />}
    </>,
    document.getElementById('root') as HTMLElement,
  )
}
