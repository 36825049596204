import { type ChangeEvent } from 'react'

import YesNoInput from '../../../components/inputs/YesNoInput'
import { useToggleUserPreference } from './mutations'
import { type PreferenceConfig } from './types'

type Props = {
  checked: boolean
  preference: PreferenceConfig
}

const UserPreferenceToggle = ({ checked, preference }: Props) => {
  const { label, value } = preference
  const { isBusy, setPreference } = useToggleUserPreference(value, true)

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (isBusy) return
    setPreference(e.target.checked)
  }

  return (
    <div className="flex items-center w-full">
      <label htmlFor={value} className="leading-[1.6rem]">
        {label}
      </label>
      <div className="flex-pinline flex-pinline--subtle flex-pinline--tall flex-1" />
      <div>
        <YesNoInput
          checked={checked}
          disabled={isBusy}
          name={value}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default UserPreferenceToggle
