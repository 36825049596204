import {
  vArray,
  vBoolean,
  vFallback,
  vInt,
  vMaxValue,
  vMinValue,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vString,
  vStringToNumber,
} from '../validation'
import { CompanyAddressBase } from './address'
import { AddressType, workflows } from './common'
import { LegacyShipType, LocationAccessorialDTO } from './location-accessorial'

const addressLookupContexts = [
  ...workflows,
  'tl-customs-broker',
  'ltl-customs-broker',
  'address-book',
] as const

export const addressLookupSchema = vObject({
  includeAtlas: vOptional(vBoolean()),
  accountIds: vOptional(vArray(vString())),
  search: vOptional(vString()),
  skip: vFallback(vPipe(vStringToNumber(), vInt(), vMinValue(0)), 0),
  limit: vFallback(vPipe(vStringToNumber(), vInt(), vMaxValue(50)), 50),
  type: vOptional(vPicklist(['billing', 'shipping', 'all'])),
  context: vOptional(vPicklist(addressLookupContexts)),
})

export type AddressLookupDTO = vOutput<typeof addressLookupSchema>
export type AddressLookupContext = (typeof addressLookupContexts)[number]

export interface AddressLookupResult extends CompanyAddressBase {
  _id?: string
  accountId?: string
  accessorials?: LocationAccessorialDTO[]
  mappedAccessorials?: {
    ltl: LocationAccessorialDTO[]
    parcel: LocationAccessorialDTO[]
    truckload: LocationAccessorialDTO[]
  }
  acceptability?: number
  lat: number
  lng: number
  timezone: string
  dockHoursStart: string
  dockHoursEnd: string
  contactName?: string
  contactEmail?: string
  contactPhone?: string
  contactPhoneExt?: string
  isAccountLocation?: boolean
  accountLocationId?: string
  instructions?: string
  shipType?: LegacyShipType | ''
  bolFollowers?: string[]
  sendBOL?: boolean
  type?: AddressType
}
