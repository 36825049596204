import { type PreferenceConfig } from './types'

export const ltlPreferences: PreferenceConfig[] = [
  {
    label: 'Auto-fill origin from my last shipment',
    value: 'ltl_quote_remember_origin',
  },
  {
    label: 'Auto-fill destination from my last shipment',
    value: 'ltl_quote_remember_destination',
  },
  {
    label: 'Auto-fill product from my last shipment',
    value: 'ltl_quote_remember_product',
  },
]

export const parcelPreferences: PreferenceConfig[] = [
  {
    label: 'Auto-fill origin from my last shipment',
    value: 'parcel_quote_remember_origin',
  },
  {
    label: 'Auto-fill destination from my last shipment',
    value: 'parcel_quote_remember_destination',
  },
  {
    label: 'Auto-fill product from my last shipment',
    value: 'parcel_quote_remember_product',
  },
]

export const spotQuotePreferences: PreferenceConfig[] = [
  {
    label:
      'Do not send spot-quote correspondence to my email. Keep only inside of Freightview.',
    value: 'spot-quote-disable-email-notification',
  },
]
