import { vNonEmptyString, vObject, vOutput, vPicklist } from '../validation'

export const apiKeyVersions = [1, 2] as const
export type ApiKeyVersion = (typeof apiKeyVersions)[number]
export interface ApiKey {
  _id: string
  created: Date
  name: string
  key: string
  version: ApiKeyVersion
  lastUsed?: Date
}

export const apiKeyCreateSchema = vObject({
  name: vNonEmptyString(),
  version: vPicklist(apiKeyVersions),
})
export type ApiKeyCreateDto = vOutput<typeof apiKeyCreateSchema>

export interface ApiKeyCreateResult {
  apiKey: string
  created: Date
}

export interface ApiKeyListDto {
  _id: string
  name: string
  created: Date
}
