import orderBy from 'lodash/orderBy'
import upperFirst from 'lodash/upperFirst'

import {
  type ApiChargeDTO,
  type ChargeCode,
  type ChargeName,
  chargeNameFromCode,
} from '@fv/models'

import { type Audit, type ReconciledChargeItem } from './types'

export const getInvoice = (audit: Audit) => {
  const { invoices } = audit

  if (!invoices.length) return null
  return orderBy(invoices, i => i.uploadDate, ['desc'])[0]
}

export const getReconciledChargeArrays = (
  audit: Audit,
): {
  quoteCharges: ReconciledChargeItem[]
  invoiceCharges: ReconciledChargeItem[]
} => {
  const quoteCharges =
    audit.quote.charges?.map<ReconciledChargeItem>(c => ({
      amount: c.amount,
      chargeName: c.name,
      label: getQuoteChargeName(c),
    })) ?? []
  const invoiceCharges = audit.lineItems.map<ReconciledChargeItem>(i => ({
    amount: i.amount,
    chargeName: i.type,
    label: i.description,
  }))

  const sortedQuoteCharges = sortReconciledCharges(quoteCharges)
  const sortedInvoiceCharges = sortChargesByLookup(
    invoiceCharges,
    sortedQuoteCharges,
  )

  return {
    quoteCharges: sortedQuoteCharges,
    invoiceCharges: sortedInvoiceCharges,
  }
}

export function getQuoteChargeName(charge: ApiChargeDTO) {
  const other = 'other'
  if (charge.name && charge.name.toLowerCase() !== other)
    return upperFirst(charge.name)

  if (charge.code) {
    const codeName = chargeNameFromCode(charge.code as ChargeCode)
    if (codeName && codeName !== other) return upperFirst(codeName)
  }

  if (charge.providerDetail) return charge.providerDetail

  return 'Other'
}

const chargeOrderPref: ChargeName[] = [
  'linehaul',
  'base rate',
  'discount',
  'fuel',
  'fuel surcharge',
]
export const sortReconciledCharges = (charges: ReconciledChargeItem[]) => {
  return orderBy(
    charges,
    c => {
      const ix = chargeOrderPref.indexOf(c.chargeName as ChargeName)
      return ix === -1 ? Infinity : ix
    },
    'asc',
  )
}
const sortChargesByLookup = (
  toSort: ReconciledChargeItem[],
  lookup: ReconciledChargeItem[],
) => {
  return orderBy(
    toSort,
    s => {
      const ix = lookup.findIndex(
        l => l.chargeName === s.chargeName || l.amount === s.amount,
      )
      return ix === -1 ? Infinity : ix
    },
    'asc',
  )
}
