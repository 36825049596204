import { FvLinkButton } from '@fv/client-components'

import { type Audit } from '../types'
import {
  getInvoice,
  getQuoteChargeName,
  getReconciledChargeArrays,
  sortReconciledCharges,
} from '../utils'
import { AuditChargeList } from './AuditChargeList'

type Props = {
  audit: Audit
}

export const AuditRateBreakdown = (props: Props) => {
  const { audit } = props
  const { quote } = audit
  const invoice = getInvoice(audit)

  if (audit.source !== 'api') {
    return (
      <AuditChargeList
        titleContents={<span>Quoted amount</span>}
        total={quote.amount}
        manual={quote.method === 'manual'}
        charges={sortReconciledCharges(
          quote.charges?.map(c => ({
            amount: c.amount,
            chargeName: c.name,
            label: getQuoteChargeName(c),
            type: 'charge',
          })) ?? [],
        )}
      />
    )
  }

  const { quoteCharges, invoiceCharges } = getReconciledChargeArrays(audit)

  return (
    <>
      <AuditChargeList
        titleContents={<span>Quoted amount</span>}
        total={quote.amount}
        manual={quote.method === 'manual'}
        charges={quoteCharges}
      />
      <AuditChargeList
        titleContents={
          <div className="flex divide-x divide-fv-gray">
            <span>Invoiced amount</span>

            {invoice && (
              <>
                <FvLinkButton
                  icon="eye"
                  target="_blank"
                  href={invoice.url}
                  className="ml-3 pl-3"
                >
                  View document
                </FvLinkButton>
              </>
            )}
          </div>
        }
        total={audit.invoiceAmount}
        charges={invoiceCharges}
      />
    </>
  )
}
