import dayjs from 'dayjs'
import { useMemo } from 'react'

export type RouteStop = { stopDate?: Date | string | null }

const stopWithDateReducer = (
  currentValue: RouteStop | null,
  stop: RouteStop,
) => {
  if (currentValue) return currentValue
  return stop.stopDate ? stop : null
}

// Returns the min and max date inputs allowed for this stop
export function useRouteStopDateRange({
  sequence,
  shouldRestrictRange = true,
  stops,
}: {
  sequence: number
  shouldRestrictRange?: boolean
  stops: RouteStop[]
}) {
  return useMemo(() => {
    const nextStop = stops.slice(sequence + 1).reduce(stopWithDateReducer, null)
    const prevStop = stops
      .slice(0, sequence)
      .reduceRight(stopWithDateReducer, null)

    const minDate = shouldRestrictRange
      ? dayjs().startOf('day').toDate()
      : undefined

    return {
      maxDate: nextStop?.stopDate ? new Date(nextStop.stopDate) : undefined,
      minDate: prevStop?.stopDate ? new Date(prevStop.stopDate) : minDate,
    }
  }, [shouldRestrictRange, stops, sequence])
}
