import { lazy, Suspense } from 'react'

import { SliderPanel, type SliderPanelProps } from '@fv/client-components'

import InfoBox from '../../components/InfoBox'

const ShipmentUploadForm = lazy(() => import('./ShipmentUploadForm'))

export const ShipmentUploadPanel = (opts: SliderPanelProps) => {
  const close = (doConfirm = false) => {
    if (
      doConfirm &&
      !window.confirm(
        `You haven't completed your upload.  Are you sure you want to cancel?`,
      )
    ) {
      return
    }

    opts?.closePanel?.()
  }

  return (
    <SliderPanel
      isOpen={opts.isOpen}
      width="95vw"
      closePanel={() => close(true)}
    >
      <Suspense fallback={<InfoBox icon="sync">Loading...</InfoBox>}>
        <ShipmentUploadForm close={close} />
      </Suspense>
    </SliderPanel>
  )
}
