import { useEffect, useState } from 'react'

import { submitForm } from '@fv/client-core'

import { validateBillTo } from '../billTo/billToFuncs'
import {
  defaultFormBroker,
  validateCustomsBroker,
} from '../customsBroker/brokerFuncs'
import { validateEmergencyContact } from '../emergencyContact/contactFuncs'
import {
  billToKey,
  brokerKeyPrefix,
  commoditiesKey,
  composeBrokerKey,
  composeLoadKey,
  composeLocationKey,
  emergencyContactKey,
  type FormSection,
  type FormSectionKey,
  loadKeyPrefix,
  type LoadToBook,
  locationKeyPrefix,
} from './types'

export function useFormSections(
  loads: LoadToBook[],
  isHazardous: boolean,
  requireCustomsBroker: boolean,
) {
  const [sections, setSections] = useState(() => {
    const formSections: FormSection[] = []
    const hasCommodities = loads.every(x => x.items.length > 0)
    const isEditing = loads.every(x => x.status !== 'pending')
    const isMultiStop = loads[0].locations.length > 2

    loads[0].locations.forEach(x =>
      formSections.push({
        key: composeLocationKey(x.sequence),
        isValid: isEditing,
        ref: null,
      }),
    )

    if (loads[0].locations.length > 2) {
      formSections.push({
        key: commoditiesKey,
        isValid: isEditing && hasCommodities,
        ref: null,
      })
    }

    loads.forEach(load =>
      formSections.push({
        key: composeLoadKey(load.loadId),
        isValid: isEditing && (isMultiStop || hasCommodities),
        ref: null,
      }),
    )

    if (requireCustomsBroker) {
      const brokers = loads[0].customsBroker?.length
        ? loads[0].customsBroker
        : [defaultFormBroker]

      brokers.forEach((broker, i) =>
        formSections.push({
          key: composeBrokerKey(i),
          isValid: isEditing && validateCustomsBroker(broker),
          ref: null,
        }),
      )
    }

    formSections.push({
      key: billToKey,
      isValid: isEditing && validateBillTo(loads[0].billTo),
      ref: null,
    })

    if (isHazardous) {
      formSections.push({
        key: emergencyContactKey,
        isValid:
          isEditing && validateEmergencyContact(loads[0].emergencyContact),
        ref: null,
      })
    }

    // Set section refs from individual forms
    return formSections
  })

  // Add/remove emergency contact section on `isHazardous` change
  useEffect(() => {
    setSections(prev => {
      const contactSection = prev.find(s => s.key === emergencyContactKey)

      if (contactSection) {
        if (isHazardous) return prev
        return prev.filter(s => s.key !== emergencyContactKey)
      }

      if (!isHazardous) return prev

      return prev.concat({
        key: emergencyContactKey,
        isValid: false,
        ref: null,
      })
    })
  }, [isHazardous])

  useEffect(() => {
    setSections(prev => {
      const hasBrokerSection = prev.some(s => s.key.startsWith(brokerKeyPrefix))

      if (!requireCustomsBroker && hasBrokerSection) {
        return prev.filter(s => !s.key.startsWith(brokerKeyPrefix))
      }

      if (requireCustomsBroker && !hasBrokerSection) {
        return prev.concat({
          key: composeBrokerKey(0),
          isValid: false,
          ref: null,
        })
      }

      return prev
    })
  }, [requireCustomsBroker])

  const activeSection = sections.find(s => !s.isValid)?.key

  function activateSection(key: FormSectionKey) {
    const currentIndex = sections.findIndex(s => s.key === activeSection)
    const nextIndex = sections.findIndex(s => s.key === key)
    const currentSection = sections[currentIndex]
    const isPrevStep = nextIndex < currentIndex

    if (currentSection && !isPrevStep) {
      const formRef = currentSection.ref?.current
      const isValid = formRef?.checkValidity()
      if (!isValid) return submitForm(formRef)
    }

    setSections(prev =>
      prev.map(s => ({
        ...s,
        isValid: s.key === key ? false : s.isValid,
      })),
    )
  }

  function goToNextSection() {
    setSections(p =>
      p.map(s => {
        if (s.key !== activeSection) return s
        return { ...s, isValid: true }
      }),
    )
  }

  function setPickupOnlySections(isPickupOnly: boolean) {
    setSections(prev => {
      const loadSections = prev.filter(s => s.key.startsWith(loadKeyPrefix))
      const locationSections = prev.filter(s =>
        s.key.startsWith(locationKeyPrefix),
      )

      if (isPickupOnly) {
        return [...locationSections, ...loadSections]
      }

      const isMultiStop = locationSections.length > 2
      const nextFormSections = locationSections

      if (isMultiStop) {
        nextFormSections.push({
          isValid: false,
          key: commoditiesKey,
          ref: null,
        })
      }

      loadSections.forEach(loadSection => {
        nextFormSections.push({
          ...loadSection,
          isValid: isMultiStop && loadSection.isValid,
        })
      })

      if (requireCustomsBroker) {
        const brokers = loads[0].customsBroker?.length
          ? loads[0].customsBroker
          : [defaultFormBroker]

        brokers.forEach((_broker, i) =>
          nextFormSections.push({
            key: composeBrokerKey(i),
            isValid: false,
            ref: null,
          }),
        )
      }

      nextFormSections.push({
        key: billToKey,
        isValid: false,
        ref: null,
      })

      if (isHazardous) {
        nextFormSections.push({
          key: emergencyContactKey,
          isValid: false,
          ref: null,
        })
      }

      return nextFormSections
    })
  }

  return {
    activateSection,
    activeSection,
    formSections: sections,
    goToNextSection,
    setFormSections: setSections,
    setPickupOnlySections,
  }
}
