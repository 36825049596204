const reactSelectStyles = {
  container: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    background: 'transparent',
    borderColor: '#b3b3b3',
    borderRadius: 'none',
    borderBottomColor: state.isFocused ? '#ff6501' : '#45abd3',
    borderBottomWidth: '.115rem',
    boxShadow: 0,
    color: '#333333',
    minHeight: 2.8 + 'rem',
    lineHeight: 1.4 + 'rem',
    padding: 0,
    '&:hover': {
      borderColor: '#b3b3b3',
      borderBottomColor: '#ff6501',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#d5ecf5' : '#f2f9fc',
    borderColor: state.isFocused ? '#45abd3' : '#bde1ef',
    color: state.isFocused ? '#333' : '#666',
    marginBottom: 0.5 + 'rem',
    border: '1px solid #bde1ef',
    '&:hover': {
      backgroundColor: '#d5ecf5',
      borderColor: '#45abd3',
      cursor: 'pointer',
      color: '#333',
    },
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid #ccc',
    zIndex: 5,
    padding: 0.5 + 'rem',
    boxShadow: 'none',
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: provided => ({
    ...provided,
    margin: 0,
  }),
  placeholder: (_, { selectProps }) => ({
    color: '#999999',
    marginTop: selectProps.isSearchable ? '-1.8rem' : '-1.2rem',
    fontSize: 0.9 + 'rem',
  }),
  multiValueLabel: provided => ({
    ...provided,
    backgroundColor: '#f2f9fc',
    borderRadius: 0,
    borderWidth: 1 + 'px',
    borderColor: '#ccc',
    borderStyle: 'solid',
    paddingLeft: 0.5 + 'rem',
    paddingRight: 0.5 + 'rem',
    fontSize: 100 + '%',
  }),
  multiValueRemove: provided => ({
    ...provided,
    borderRadius: 0,
    cursor: 'pointer',
    color: '#45abd3',
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1 + 'px',
    borderColor: '#cccccc',
    borderLeftWidth: 0,
    '&:hover': {
      color: '#333',
      backgroundColor: '#fff',
    },
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#45abd3',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#45abd3',
  }),
  input: provided => ({
    ...provided,
    flex: 1,
  }),
  valueContainer: provided => ({
    ...provided,
    paddingBottom: '0 !important',
    paddingTop: '0 !important',
  }),
}

export default reactSelectStyles
