import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type FullShipment } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'

export const quoteRequestKeys = {
  all: ['quote-request'] as const,
  request: (id?: string) => [...quoteRequestKeys.all, id ?? null] as const,
}

async function fetchQuoteRequestShipments(
  quoteRequestId?: string,
): Promise<FullShipment[]> {
  if (!quoteRequestId) {
    throw new Error('`quoteRequestId` required to fetch quote request')
  }

  const endpoint = `${apiUri}/shipments/by-quote-request/${quoteRequestId}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useQuoteRequest(id?: string, enabled = true) {
  const updateCachedLoad = useUpdateCachedLoad()

  return useQuery(
    quoteRequestKeys.request(id),
    () => fetchQuoteRequestShipments(id),
    {
      enabled: !!id && enabled,
      onSuccess: shipments => {
        const keysToSkip: string[] = [...quoteRequestKeys.all]
        const hasNewShipment = shipments.some(s =>
          dayjs().subtract(30, 'seconds').isBefore(s.createdDate),
        )

        // Not in lists yet, don't `setQueryData` to allow invalidation
        if (hasNewShipment) keysToSkip.push('shipments')

        shipments.forEach(updatedLoad => {
          updateCachedLoad({
            updatedLoad,
            keysToSkip,
          })
        })
      },
    },
  )
}
