import clsx from 'clsx'
import dayjs from 'dayjs'
import { type ChangeEvent, useMemo, useState } from 'react'

import { isAlphaNumeric } from '@fv/client-core'
import { type Workflow } from '@fv/client-types'

import { CarrierServiceTypeahead } from '../../components/carrier-service-types/CarrierServiceTypeSelector'
import InfoBox from '../../components/InfoBox'
import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import {
  filterOption,
  TypeaheadField,
  type TypeaheadOption,
} from '../../components/inputs/TypeaheadField'
import { type FormSectionProps } from '../../components/inputs/types'
import { RouteStopDate } from '../../components/QuoteRequest/RouteStopDate'
import { useUserSettings } from '../../hooks/settings'
import { useCarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'
import { useBookingFormCtx } from './BookingFormProvider'
import { type CustomerRoutedDetail } from './types'
import {
  type CustomerRoutedCarrierDTO,
  useCustomerRoutedCarriers,
} from './useCustomerRoutedCarriers'

const getInputName = (name: string) => `customerRouted_${name}`

export const CustomerRoutedForm = ({ disabled }: FormSectionProps) => {
  const actions = useBookingFormCtx(s => s.actions)
  const crDetail = useBookingFormCtx(s => s.customerRoutedDetail)
  const workflow = useBookingFormCtx(s => s.workflow)
  const crQuery = useCustomerRoutedCarriers(workflow)
  const isEdit = useBookingFormCtx(s => s.isEdit)
  const isFreightCollect = useBookingFormCtx(s => s.isFreightCollect)
  const origin = useBookingFormCtx(s => s.locations[0])
  const [carrierName, setCarrierName] = useState(crDetail.carrier?.name || '')
  const [isPickingDate, setPickingDate] = useState(false)
  const isParcel = workflow === 'parcel'
  const isLtl = workflow === 'ltl'
  const requireAccountNumber = useRequireAccountNumber({
    workflow,
    carrierId: crDetail.carrier?.id,
  })

  if (isEdit || !isFreightCollect) return null

  const carrierOpts = (crQuery.data?.carriers ?? [])
    .map<TypeaheadOption<CustomerRoutedCarrierDTO>>(c => ({
      label: c.name.trim(),
      value: c,
    }))
    .filter(c => filterOption(c, carrierName))

  const carrier = crQuery.data?.carriers.find(
    c => c.id === crDetail.carrier?.id,
  )

  function getInputProps(name: keyof CustomerRoutedDetail) {
    return {
      disabled,
      name: getInputName(name),
      onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (name === 'accountNum') {
          e.target.setCustomValidity(
            isAlphaNumeric(e.target.value)
              ? ''
              : 'Only alphanumeric characters are valid',
          )
        }
        actions.setCustomerRoutedValues({ [name]: e.target.value })
      },
      value: crDetail[name]?.toString(),
    }
  }

  return (
    <>
      <h4 className="mb-2 text-lg">Schedule a customer routed pickup</h4>
      <InfoBox className="form-row mb-8 flex flex-wrap" custom>
        <InputGroupWrapper
          className={clsx({
            'basis-1/4': isLtl,
            'basis-1/6': isParcel,
          })}
          label="Carrier"
          name={getInputName('carrier')}
          required
        >
          <TypeaheadField
            isLoading={crQuery.isLoading}
            name={getInputName('carrier')}
            onChange={carrier => {
              const crValues: Partial<CustomerRoutedDetail> = {
                carrier,
                service: undefined,
              }

              setCarrierName(carrier?.name.trim() || '')
              actions.setCustomerRoutedValues(crValues)
            }}
            onInputChange={setCarrierName}
            options={carrierOpts}
            readOnly={disabled}
            required
            shouldClearInvalidInput
            value={carrierName}
          />
        </InputGroupWrapper>

        <InputGroupWrapper
          className="flex-grow"
          required
          name="cr-service"
          label="Service"
        >
          <CarrierServiceTypeahead
            disabled={!carrier}
            scac={carrier?.code}
            workflow={workflow}
            value={crDetail?.service}
            name="cr-service"
            type="select"
            required
            onChange={service =>
              actions.setCustomerRoutedValues({
                service: service?.id ?? undefined,
              })
            }
          />
        </InputGroupWrapper>

        <InputGroup
          className={clsx({
            'basis-1/4': isLtl,
            'basis-1/6': isParcel,
          })}
          inputProps={{
            disabled,
            name: getInputName('pickupDate'),
            onChange: undefined,
            onFocus: () => setPickingDate(true),
            readOnly: true,
            value: dayjs(origin.stopDate).format('ddd MMM D'),
          }}
          inputType="text"
          label="Pick up on"
          required
          startContent={
            <InputAdornment
              icon="calendar-day"
              iconClass="fa-fw color-dark"
              position="start"
            />
          }
        />

        <InputGroup
          className={clsx({
            'basis-1/4': isLtl,
            'basis-1/6': isParcel,
          })}
          inputProps={{
            ...getInputProps('accountNum'),
            placeholder: '123456',
            ...(requireAccountNumber && {
              pattern: '.{4,}',
              onInvalid: e =>
                e.currentTarget.setCustomValidity(
                  'Account number must be a valid account number for the specified carrier.',
                ),
            }),
          }}
          inputType="text"
          required={requireAccountNumber}
          label="Account number"
          startContent={<InputAdornment icon="hashtag" position="start" />}
        />

        {isParcel && (
          <InputGroup
            className={clsx({
              'basis-1/4': isLtl,
              'basis-1/6': isParcel,
            })}
            inputProps={{
              ...getInputProps('postalCode'),
              placeholder: '12345',
            }}
            inputType="text"
            required
            label="Postal Code"
          />
        )}
      </InfoBox>

      <RouteStopDate
        onClose={() => setPickingDate(false)}
        isOpen={isPickingDate}
        sequence={0}
        onChange={data => {
          actions.setLocationValues('origin', data)
        }}
        required
        showStopDetailConfig={false}
        stops={[origin]}
      />
    </>
  )
}

type RequireAccountNumberProps = {
  workflow: Workflow
  carrierId?: string
}

const useRequireAccountNumber = ({
  workflow,
  carrierId,
}: RequireAccountNumberProps) => {
  const { carrierPublicSettings } = useCarrierPublicSettings(carrierId)
  const settingsQuery = useUserSettings()

  return useMemo(() => {
    const accountCarriers = settingsQuery.data?.carriers ?? []
    const hasCarrier = accountCarriers.some(
      c => c._id === carrierId && c.status && c.status !== 'removed',
    )

    return (
      workflow === 'parcel' ||
      (carrierPublicSettings?.requireCustomerRoutedAccountNumber && !hasCarrier)
    )
  }, [
    settingsQuery.data,
    carrierId,
    workflow,
    carrierPublicSettings?.requireCustomerRoutedAccountNumber,
  ])
}
