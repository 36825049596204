import { type PropsWithChildren, useRef } from 'react'
import { type SelectInstance } from 'react-select'

import {
  FvButton,
  FvLinkButton,
  LoadMore,
  SliderPanel,
  TextField,
} from '@fv/client-components'

import InfoBox from '../../components/InfoBox'
import {
  InputAdornment,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { HasPermission } from '../auth'
import { getWorkflowProductMode } from './commodityUtils'
import { Product } from './Product'
import { ProductCatalogForm } from './ProductCatalogForm'
import { ProductCatalogTypeahead } from './ProductCatalogTypeahead'
import { useProducts } from './useHandlingUnits'
import { type ProductCatalogInit, useProductCatalog } from './useProductCatalog'

export const ProductCatalogListSearch = ({ children }: PropsWithChildren) => {
  const productCatalog = useProductCatalog()
  return (
    <div className="flex gap-x-2">
      <div className="input-group flex-1">
        <InputAdornment
          icon="search"
          iconClass="color-success"
          position="start"
        />
        <TextField
          autoFocus
          className="form-control"
          name="productSearch"
          onChange={e => productCatalog.search(e.target.value)}
          placeholder="i.e. Widgets"
          value={productCatalog.searchText}
        />
      </div>
      <HasPermission name="manage">
        <div>
          <FvButton
            icon="plus"
            theme="default"
            onClick={productCatalog.showForm}
          >
            Add product
          </FvButton>
        </div>
      </HasPermission>
      {children}
    </div>
  )
}

type ListProps = {
  inline?: boolean
}
export const ProductCatalogList = ({ inline }: ListProps) => {
  const productCatalog = useProductCatalog()
  const productsQuery = useProducts(
    productCatalog.isOpen || !!inline,
    productCatalog.filter,
    productCatalog.searchText,
  )
  return (
    <>
      {!productsQuery.data.length &&
        (productsQuery.isLoading ? (
          <InfoBox icon="spinner">Loading...</InfoBox>
        ) : (
          <InfoBox>No products found.</InfoBox>
        ))}

      {productsQuery.data.map(p => (
        <Product key={p._id} product={p} />
      ))}

      <LoadMore
        className="mt-4"
        fetchNextPage={productsQuery.fetchNextPage}
        hasNextPage={productsQuery.hasNextPage}
        isLoading={productsQuery.isFetchingNextPage}
      />
    </>
  )
}

export const ProductCatalogPanelContents = () => {
  const productCatalog = useProductCatalog()
  const productsQuery = useProducts(
    productCatalog.isOpen,
    productCatalog.filter,
    productCatalog.searchText,
  )

  const editingProduct = productsQuery.data.find(
    p => p._id === productCatalog.editingId,
  )
  return (
    <>
      {productCatalog.view === 'list' && (
        <>
          <div className="sticky -top-8 z-10 -mx-8 -mt-8 border-b bg-white px-8 py-4">
            <ProductCatalogListSearch />
          </div>

          <div className="mt-8">
            <ProductCatalogList />
          </div>
        </>
      )}

      {productCatalog.view === 'form' && (
        <ProductCatalogForm
          initialValues={editingProduct}
          mode={productCatalog.filter.mode}
        />
      )}
    </>
  )
}
export const ProductCatalog = () => {
  const { isOpen, close, useSlider } = useProductCatalog()
  return (
    <SliderPanel closePanel={close} isOpen={isOpen && useSlider}>
      <ProductCatalogPanelContents />
    </SliderPanel>
  )
}
type Props = {
  className?: string
  disabled?: boolean
  init: ProductCatalogInit
  variant?: 'button' | 'icon'
  label?: string
  onClick?: () => void
}

export const ProductCatalogButton = ({
  disabled,
  onClick,
  init,
  variant = 'button',
  label = 'Product catalog',
}: Props) => {
  const openProductCatalog = useProductCatalog(s => s.open)
  const handleClick = () => onClick ?? openProductCatalog(init)

  if (variant === 'icon') {
    return (
      <FvLinkButton
        aria-label="Open product catalog"
        disabled={disabled}
        icon="box"
        onClick={handleClick}
        title={label}
      />
    )
  }

  return (
    <FvButton
      disabled={disabled}
      theme="default"
      icon="forklift"
      onClick={handleClick}
    >
      {label}
    </FvButton>
  )
}

export const ProductCatalogSearch = ({
  className = 'col-12',
  disabled,
  init,
}: Props) => {
  const {
    filter: { mode },
    setProduct,
  } = init
  const search = useProductCatalog(s => s.search)
  const searchRef = useRef<SelectInstance>(null)
  const searchText = useProductCatalog(s => s.searchText)

  return (
    <InputGroupWrapper
      className={className}
      label="Search"
      name="productSearch"
    >
      <InputAdornment position="start">
        <FvLinkButton
          fw
          icon="search"
          iconClass="color-success"
          onClick={() => {
            searchRef.current?.openMenu('first')
            searchRef.current?.focus()

            if (searchRef.current?.hasValue()) {
              setTimeout(() => {
                searchRef.current?.focusedOptionRef?.click()
              })
            }
          }}
        />
      </InputAdornment>
      <ProductCatalogTypeahead
        filter={{
          mode: getWorkflowProductMode(mode),
        }}
        name="productSearch"
        onInputChange={search}
        searchRef={searchRef}
        setProduct={product => {
          if (disabled) return

          setProduct(product)
          search('')
        }}
        value={searchText}
      />
      <div className="">
        <ProductCatalogButton disabled={disabled} init={init} />
      </div>
    </InputGroupWrapper>
  )
}
