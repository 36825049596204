import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'

import { showApiError } from '@fv/client-core'
import { type UpdateAuditDto } from '@fv/models'

import { shipperFetch } from '../../utils/shipperFetch'
import { auditKeys } from './queries'

type PutInvoiceArgs = UpdateAuditDto & {
  loadId: string
}

const putInvoiceDetails = async ({ loadId, ...body }: PutInvoiceArgs) =>
  shipperFetch(`/audit/${loadId}`, {
    method: 'PUT',
    body: {
      ...body,
      ...(body.invoiceDate && {
        invoiceDate: dayjs(body.invoiceDate).format('YYYY-MM-DD'),
      }),
    },
  })

export const useUpdateInvoice = () => {
  const queryClient = useQueryClient()
  return useMutation(putInvoiceDetails, {
    onSuccess() {
      queryClient.invalidateQueries(auditKeys.list('accepted'))
      toast.success('Updated invoice details')
    },
    onError(e) {
      showApiError('Error updating invoice', e)
    },
  })
}
