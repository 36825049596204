import uniq from 'lodash/uniq'
import { create } from 'zustand'

import { emailRegExpMatcher } from '@fv/client-core'

import { ALL_CONTACTS_ID } from '../spot-quote-contacts/types'

export type ListMode = 'edit' | 'send' | 'recipients'
interface SpotQuoteFormState {
  emails: string
  groupId: string
}
interface SpotQuoteFormActions {
  setGroupId: (groupId: string) => void
  addEmails: (newEmails: string[]) => void
  removeEmails: (remove: string[]) => void
  setEmails: (emails: string[]) => void
  setRawEmail: (emails: string) => void
}
export const useSpotQuoteContactListStore = create<
  SpotQuoteFormState & { actions: SpotQuoteFormActions }
>((set, get) => ({
  emails: '',
  groupId: ALL_CONTACTS_ID,
  actions: {
    setGroupId(groupId: string) {
      set({ groupId })
    },
    addEmails: (newEmails: string[]) => {
      const emails = get().emails.match(emailRegExpMatcher) ?? []
      return get().actions.setEmails([
        ...emails.filter(e => !newEmails.includes(e)),
        ...newEmails,
      ])
    },
    removeEmails: (remove: string[]) => {
      const emails = get().emails.match(emailRegExpMatcher) ?? []
      return get().actions.setEmails(emails.filter(e => !remove.includes(e)))
    },
    setEmails: (emails: string[]) => {
      set({
        emails: uniq(emails).join(';'),
      })
    },
    setRawEmail: (emails: string) => {
      set({ emails })
    },
  },
}))

export const useSpotQuoteListActions = () =>
  useSpotQuoteContactListStore(s => s.actions)

export const useSpotQuoteListSelected = (): string[] =>
  useSpotQuoteContactListStore(s => s.emails).match(emailRegExpMatcher) ?? []

export const useSpotQuoteListGroup = () =>
  useSpotQuoteContactListStore(s => s.groupId)
