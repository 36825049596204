import dayjs from 'dayjs'
import { Fragment } from 'react'
import { useSearchParams } from 'react-router-dom'

import { type FilterConfig } from '../../components/CustomFilterForm/types'
import { useShipmentFilterListContext } from './ShipmentFilterListProvider'

type FilterDescriptionProps = {
  isCustomFilter: boolean
}

export const FilterDescription = ({
  isCustomFilter,
}: FilterDescriptionProps) => {
  const filterConfig = useShipmentFilterListContext(s => s.filterConfig)
  const [searchParams] = useSearchParams()
  if (!isCustomFilter) return null

  const selectedFilters = getFilterDescriptions(filterConfig, searchParams)

  return (
    <>
      <hr className="mx-0 my-3" />
      <p className="mb-3">
        {selectedFilters.map((filter, index) => (
          <Fragment key={filter}>
            {index > 0 && <br />}
            <span>{filter}</span>
          </Fragment>
        ))}
      </p>
    </>
  )
}

export const getFilterDescriptions = (
  filterConfig: FilterConfig[],
  params: URLSearchParams,
): string[] => {
  return filterConfig
    .map(filter => {
      const isMulti = filter.name.slice(-2) === '[]'

      if (isMulti) {
        const values = params.getAll(filter.name)
        if (!values.length) return null

        const valueLabels = values.map(v => {
          const option = filter.options.find(o => o.value === v)
          return option?.text ?? v
        })

        return `${filter.label}: ${valueLabels.join(', ')}`
      }

      const value = params.get(filter.name)
      if (!value) return null

      const option = filter.options.find(o => o.value === value)
      let valueLabel = option?.text ?? value

      if (!option && /date/i.test(filter.name)) {
        // This is a custom date range
        const [start, end] = value.split('-').map(Number)

        if (!isNaN(start) && !isNaN(end)) {
          const startDate = dayjs(start).format('M/D/YY')
          const endDate = dayjs(end).format('M/D/YY')
          valueLabel = `${startDate} - ${endDate}`
        }
      }

      return `${filter.label}: ${valueLabel}`
    })
    .filter(Boolean) as string[]
}
