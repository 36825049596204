import upperFirst from 'lodash/upperFirst'
import { type ChangeEvent } from 'react'

import { routingTypes } from '@fv/models'

import InfoBox from '../../../components/InfoBox'
import { InputGroup } from '../../../components/inputs/InputGroup'
import { ListItem } from '../../../components/List'
import { useAccountSettings, useAvailableDistanceProviders } from './hooks'
import { useAccountSettingMutation } from './mutations'
import { type DistanceProviderConfig, findProvider } from './types'

const toBool = (v: string) => v === 'Yes'
const routeBordersOptions = ['Yes', 'No']
const routingTypeOptions = routingTypes.map(t => ({
  text: upperFirst(t),
  value: t,
}))

const RoutingSoftwareFormControls = ({
  providers,
}: {
  providers: DistanceProviderConfig[]
}) => {
  const accountSettings = useAccountSettings()
  const currentProvider = findProvider(
    providers,
    accountSettings.routing?.distanceProvider,
  )

  const { updateRouteSettings } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = e.target
    const isBorderOption = routeBordersOptions.includes(value)

    updateRouteSettings(
      { [name]: isBorderOption ? toBool(value) : value },
      providers,
    )
  }

  return (
    <ListItem className="gap-x-2">
      <InputGroup
        className="mb-0 flex-1"
        inputProps={{
          name: 'routing.distanceProvider',
          onChange,
          options: providers.map(p => ({ text: p.name, value: p.id })),
          value: currentProvider?.id || '',
        }}
        inputType="select"
        label="Routing software"
      />

      <InputGroup
        className="mb-0 flex-1"
        inputProps={{
          name: 'routing.distanceProviderVersion',
          onChange,
          options: currentProvider?.versions ?? [],
          value: accountSettings.routing?.distanceProviderVersion || '',
        }}
        inputType="select"
        label="Version"
      />

      <InputGroup
        className="mb-0 flex-1"
        inputProps={{
          name: 'routing.routingType',
          onChange,
          options: routingTypeOptions,
          value: accountSettings.routing?.routingType || '',
        }}
        inputType="select"
        label="Route type"
      />

      <InputGroup
        className="whitespace-nowrap mb-0 flex-1"
        inputProps={{
          name: 'routing.bordersOpen',
          onChange,
          options: routeBordersOptions,
          value: accountSettings.routing?.bordersOpen ? 'Yes' : 'No',
        }}
        inputType="select"
        label="International routing?"
      />
    </ListItem>
  )
}

export const RoutingSoftwareForm = () => {
  const { data: providers, isLoading } = useAvailableDistanceProviders()

  return !isLoading && providers !== undefined ? (
    <RoutingSoftwareFormControls providers={providers} />
  ) : (
    <InfoBox icon="sync">Loading...</InfoBox>
  )
}
