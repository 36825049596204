import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

const numberRegexString = '^-?\\d*$'
const priceRegexString = '^\\d*\\.?\\d{0,2}$'
const numberRegEx = new RegExp(numberRegexString)
const priceRegEx = new RegExp(priceRegexString)

export interface AmountFieldProps extends HTMLProps<HTMLInputElement> {
  error?: string
  isPrice?: boolean
  name: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

// TODO: locale for input mask
export const AmountField = forwardRef(
  (
    { error, isPrice, name, onChange, value, min, ...rest }: AmountFieldProps,
    ref?: ForwardedRef<HTMLInputElement | null>,
  ) => {
    const [localVal, setLocalVal] = useState('') // keeping copy of local val for when strings are converted to numbers - this maintains the string copy
    const localRef = useRef<HTMLInputElement | null>(null)
    const pattern = isPrice ? priceRegEx : numberRegEx
    const replacePattern = useMemo(
      () => (isPrice ? /[^0-9.]/ : /[^0-9]/),
      [isPrice],
    )

    const invalidPatternMessage = isPrice
      ? 'Max of two decimal places.'
      : 'Must be a whole number.'

    // Do not change value on mouse wheel events
    useEffect(() => {
      const inputRef = localRef.current
      function handleMouseWheel(e: any) {
        e.preventDefault()
      }

      inputRef?.addEventListener('wheel', handleMouseWheel, { passive: false })

      return () => {
        inputRef?.removeEventListener('wheel', handleMouseWheel)
      }
    }, [])

    useEffect(() => {
      if (!error) return localRef.current?.setCustomValidity('')
      localRef.current?.setCustomValidity(error)
      localRef.current?.reportValidity()
    }, [error])

    useEffect(() => {
      if (parseFloat(value?.toString() || '') !== parseFloat(localVal)) {
        setLocalVal(value?.toString() || '')
      }
    }, [value, localVal])

    const localOnChange = useCallback(
      (target: ChangeEvent<HTMLInputElement>['target']) => {
        if (pattern.test(target.value)) target.setCustomValidity('')
        else target.setCustomValidity(invalidPatternMessage)

        if (typeof min === 'number') {
          const invalid = target.value && Number(target.value) < min
          target.setCustomValidity(
            invalid ? `Must be greater than or equal to ${min}.` : '',
          )
        }
      },
      [invalidPatternMessage, min, pattern],
    )

    useEffect(() => {
      localOnChange(localRef.current)
    }, [localVal, localOnChange])

    function setRef(element: HTMLInputElement | null) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <input
        autoComplete="off"
        data-lpignore
        id={name}
        name={name}
        onChange={e => {
          const value = e.target.value.trim().replace(replacePattern, '')
          setLocalVal(value)
          onChange?.({
            ...e,
            target: {
              ...e.target,
              name: e.target.name,
              value,
            },
          })
        }}
        ref={setRef}
        pattern={isPrice ? priceRegexString : priceRegexString}
        type="text"
        value={localVal}
        {...rest}
      />
    )
  },
)
