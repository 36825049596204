import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

import { PillHeader } from '../../components/PillHeader'

export const SettingsSection = ({
  title,
  children,
  className,
}: PropsWithClassName<
  PropsWithChildren<{
    title: string
  }>
>) => {
  return (
    <div className={clsx(className, 'default-container relative mb-10')}>
      <PillHeader className="beer-container">
        <h6 className="mb-0">{title}</h6>
      </PillHeader>
      <div className="px-4 pb-4 pt-8">{children}</div>
    </div>
  )
}
