import { type Workflow } from '@fv/client-types'

import { freightviewUri } from '../constants'

export const legacyShipmentLink = (loadId: string, workflow?: Workflow) => {
  let baseUrl = `${freightviewUri}/app#shipments`
  if (workflow === 'parcel') baseUrl += '/parcel'
  return `${baseUrl}/${loadId}`
}

export function migrateParams(
  key: string,
  source: URLSearchParams,
  target: URLSearchParams,
) {
  if (key.slice(-2) === '[]') {
    source.getAll(key).forEach(v => {
      target.append(key, v)
    })
  } else {
    const value = source.get(key)
    value && target.set(key, value)
  }
}
