import { type ListShipment } from '@fv/client-types'

import { getCarrierDetails } from '../../utils/shipmentFuncs'
import ActiveShipmentCardHeader from './ActiveShipmentCardHeader'
import PendingShipmentCardHeader from './PendingShipmentCardHeader'

type ShipmentCardHeaderProps = { shipment: ListShipment; active: boolean }

const ShipmentCardHeader = ({ shipment, active }: ShipmentCardHeaderProps) => {
  const { status } = shipment
  const carrierDetails = getCarrierDetails(shipment)
  const HeaderComponent =
    status === 'awarded' || status === 'pending'
      ? PendingShipmentCardHeader
      : ActiveShipmentCardHeader

  return (
    <div className="flex">
      <HeaderComponent
        carrierName={carrierDetails}
        shipment={shipment}
        active={active}
      />
    </div>
  )
}

export default ShipmentCardHeader
