import clsx from 'clsx'

import { Icon } from '@fv/client-components'

export type Props = {
  active?: boolean
  messageSummary?: {
    lastCarrierMessageDate?: string
    lastReadDate?: string
  }
}
const NewMessageIndicator = ({ messageSummary, active }: Props) => {
  const { lastCarrierMessageDate, lastReadDate } = messageSummary ?? {}

  if (!lastCarrierMessageDate) return null

  if (
    lastReadDate &&
    new Date(lastReadDate) > new Date(lastCarrierMessageDate)
  ) {
    return null
  }

  return (
    <a
      className={clsx(
        'shipment-card__message-icon',
        active && 'border-t-fv-orange border-r-fv-orange z-30 ',
      )}
      href="#"
      onClick={e => e.preventDefault()}
      title="You have new messages"
    >
      <Icon
        className="pulse-with-color"
        icon="comment-alt-dots"
        transform="down-1"
      />
    </a>
  )
}

export default NewMessageIndicator
