import { type PropsWithClassName } from '@fv/client-types'

import { InputGroupWrapper } from '../../components/inputs/InputGroup'
import { useBookingForm } from '../booking/hooks'
import { TagSelector } from '../tags/ShipmentTagsControl'
import { useBookingFormCtx, useBookingFormLoad } from './BookingFormProvider'

type Props = {
  tags?: string[]
  onChange: (tags: string[]) => void
}
export const BookingFormTags = ({
  className,
  tags,
  onChange,
}: PropsWithClassName<Props>) => {
  return (
    <InputGroupWrapper
      className={className}
      label="Add tags to this shipment"
      name="tags"
    >
      <TagSelector
        tagNames={tags}
        isLoading={false}
        onChange={v => {
          onChange(v.map(v => v.label))
        }}
      />
    </InputGroupWrapper>
  )
}

export const LtlBookingFormTags = ({ className }: PropsWithClassName) => {
  const load = useBookingFormLoad()
  const tags = useBookingFormCtx(s => s.tags)
  const updateTags = useBookingFormCtx(s => s.actions.updateTags)
  if (!load) return null
  return (
    <BookingFormTags
      className={className}
      tags={tags}
      onChange={tags => updateTags(tags)}
    />
  )
}

export const TLBookingFormTags = ({ className }: PropsWithClassName) => {
  const { setTags, loads, tags } = useBookingForm()
  const load = loads[0]
  if (!load) return null
  return (
    <BookingFormTags
      className={className}
      tags={tags}
      onChange={tags => setTags(tags)}
    />
  )
}
