import { type DateRange } from 'react-day-picker'

import { DayPickerPanel } from '../../features/dates/DayPickerPanel'

export const emptyRange: DateRange = {
  from: undefined,
  to: undefined,
}

type Props = {
  closeDatePicker: () => void
  dateRange: DateRange
  endLabel?: string
  isOpen: boolean
  rangeLabel?: string
  setDateRange: (range: DateRange) => void
  startLabel?: string
}

export const DateRangeSelector = ({
  closeDatePicker: onClose,
  dateRange,
  endLabel = 'last day',
  isOpen,
  rangeLabel = 'Selected',
  setDateRange,
  startLabel = 'first day',
}: Props) => {
  return (
    <DayPickerPanel
      open={isOpen}
      mode="range"
      numberOfMonths={2}
      selected={dateRange}
      onClose={onClose}
      onCancel={onClose}
      showClear
      onSave={range => setDateRange(range)}
      titleContent={(range: DateRange | undefined) => {
        const to = range?.to
        const from = range?.from
        return (
          <>
            <div className="flex items-center">
              <p className="mb-0">
                {!from && !to && `Please select the ${startLabel}.`}
                {from && !to && `Please select the ${endLabel}.`}
                {from &&
                  to &&
                  `${rangeLabel} from ${from.toLocaleDateString()} to ${to.toLocaleDateString()}`}{' '}
              </p>
            </div>

            <hr className="mx-0 my-2 mb-8" />
          </>
        )
      }}
    />
  )
}
