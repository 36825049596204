import { type Workflow } from '@fv/client-types'

import { buildLoadItemDTO } from '../load-items/loadItemHelpers'
import { LoadItemSummary } from '../LoadItemSummary'
import { type LoadHandlingUnitFormModel, type LoadItemFormModel } from './types'

export const PanelItemSummary = ({
  item,
  workflow,
}: {
  item: LoadItemFormModel | LoadHandlingUnitFormModel
  workflow: Workflow
}) => (
  <div>
    <p className="mb-0 mt-1 leading-6">
      {item.description || 'Unknown item'}
      <br />
      <LoadItemSummary
        item={buildLoadItemDTO(item, workflow)}
        workflow={workflow}
      />
    </p>
  </div>
)
