import { type ChangeEvent, useRef } from 'react'

import { FvButton, type FvButtonProps } from '@fv/client-components'

type Props = {
  loading?: boolean
  theme?: FvButtonProps['theme']
  onSelect: (file: File) => void
}
export const LogoUploadButton = ({ loading, theme, onSelect }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const openUploadDialog = () => {
    fileInputRef.current?.click()
  }
  const selectFile = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0]
    file && onSelect?.(file)
  }
  return (
    <>
      <input
        accept="image/*"
        name="file"
        onChange={selectFile}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
      <FvButton
        loading={loading}
        icon="upload"
        onClick={openUploadDialog}
        theme={theme ?? 'default'}
      >
        Upload new logo
      </FvButton>
    </>
  )
}
