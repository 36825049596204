import {
  vNonEmptyString,
  vObject,
  vObjectId,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import { ScheduledReportDTO } from './scheduled-report'

export interface SavedView {
  _id: string
  name: string
  queryString: string
  scheduledReports?: ScheduledReportDTO[]
}

export const systemFilters = [
  'audit-ltl',
  'audit-parcel',
  'audit-truckload',
  'archived',
  'awarded',
  'confirmed',
  'delivered',
  'live',
  'pending',
  'picked-up',
] as const
export type SystemFilter = (typeof systemFilters)[number]

export const filterPages = [
  'analytics',
  'auditList',
  'inProcessList',
  'readyCompletedList',
] as const
export type FilterPage = (typeof filterPages)[number]

export const upsertSavedViewSchema = vObject({
  pageName: vPicklist(filterPages),
  queryString: vNonEmptyString(),
  viewId: vOptional(vObjectId()),
  viewName: vString(),
  systemFilterId: vOptional(vString()),
})
export type UpsertSavedViewDTO = vOutput<typeof upsertSavedViewSchema>
