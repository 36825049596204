import { useState } from 'react'

import { getApiErrorText } from './api-error'
import { download } from './download'

export const acceptedFileTypes = '.pdf, .png, .jpg,'

interface DownloadOpts {
  fetch: () => Promise<Response>
  fileName: string
  error?: string
}
export const useDownloadFile = (opts: DownloadOpts) => {
  const [isDownloading, setDownloading] = useState(false)
  const [error, setError] = useState<string>()

  const fetch = async () => {
    setDownloading(true)
    const response = await opts.fetch()
    if (!response.ok) {
      const error = getApiErrorText(response.body)
      setError(error || 'Could not download file')
      return
    }

    download(await response.blob(), opts.fileName)
    setDownloading(false)
  }

  return {
    isDownloading,
    fetch,
    error,
  }
}
