import { useShareShipmentForm } from '../share/mutations'
import ShareForm from '../share/ShareForm'
import { SharePanelMain } from '../share/SharePanelMain'
import {
  SliderContentFooter,
  SliderContentHeader,
  SliderContentLayout,
} from './SliderContentLayout'
import { type ShipmentContentProps } from './types'

export const DetailsSharePanel = ({
  load,
  setActiveSlider,
}: ShipmentContentProps) => {
  const { shares = [], documents = [], loadId } = load
  const shareForm = useShareShipmentForm({
    loadId,
    documents,
  })

  return (
    <SliderContentLayout>
      <SliderContentHeader {...load} prefix="Documents for" />
      <SharePanelMain
        workflow={load.workflow}
        className="flex-1"
        form={shareForm}
        shares={shares}
      />
      <SliderContentFooter>
        <ShareForm
          form={shareForm}
          shareShipment={emails => {
            shareForm.submitShare(emails, {
              onSuccess: () => setActiveSlider(undefined),
            })
          }}
        />
      </SliderContentFooter>
    </SliderContentLayout>
  )
}
