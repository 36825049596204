import clsx from 'clsx'
import { type MouseEvent } from 'react'

import { FvLinkButton } from '@fv/client-components'

type TruckSelectorProps = {
  active: boolean
  className?: string
  selectTruck: () => void
  truckNumber: number
}

const TruckSelector = ({
  active,
  className,
  selectTruck,
  truckNumber,
}: TruckSelectorProps) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    selectTruck()
  }

  return (
    <FvLinkButton
      className={clsx('boxed-link rounded-sm', {
        'active bg-fv-gray-dark border-fv-gray-dark pointer-events-none text-white':
          active,
        'border-fv-blue hover:bg-fv-blue-100 hover:outline-offset-3 border bg-white ':
          !active,
      })}
      onClick={handleClick}
    >
      {truckNumber}
    </FvLinkButton>
  )
}

// theme={ix !== activeIndex ? 'underlined' : undefined}
// className={clsx({
//   'boxed-link active bg-fv-blue-200 pointer-events-none rounded-sm text-inherit':
//     ix === activeIndex,

export default TruckSelector
