import { createContext, useContext } from 'react'

import { type LoadItemsStore } from './loadItemsFormState'

export const LoadItemsContext = createContext<LoadItemsStore | null>(null)

export const useItemsContext = () => {
  const context = useContext(LoadItemsContext)

  if (!context) {
    throw new Error('Missing LoadItemsContext.Provider in the tree')
  }
  return context
}

export const useItemActions = () => {
  return useItemsContext().itemActions
}
export const useItemStore = <TResult>(
  sel: (store: LoadItemsStore) => TResult,
) => {
  const store = useItemsContext()
  return sel(store)
}
export const useItemsState = () => {
  return useItemsContext().itemState
}
