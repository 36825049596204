import ObjectID from 'bson-objectid'
import { type StateCreator } from 'zustand'

import { submitForm } from '@fv/client-core'
import {
  type FullShipment,
  type LegacyHandlingUnitDTO,
  type LoadHandlingUnit,
  type UIQuote,
  type Workflow,
} from '@fv/client-types'

import {
  defaultLoadHandlingUnit,
  isValidItem,
  toLoadHandlingUnitFormModel,
} from './loadItemHelpers'
import {
  type HandlingUnitFormUpdate,
  type LoadHandlingUnitFormModel,
  type LoadStep,
} from './types'

export interface LoadItemsFormState {
  items: LoadHandlingUnitFormModel[]
  activeIndex: number
  activeContainsIndex?: number
  workflow: Workflow
  step: LoadStep
  disabled?: boolean
  itemForm: HTMLFormElement | null
  carrierCode?: string
  editingContainsUnit?: LoadHandlingUnitFormModel
}

export interface LoadItemActions {
  activateContains: (ix?: number) => void
  activateItem: (ix: number) => void
  addItem: () => void
  clearCurrentItem: () => void
  clearItem: (index: number) => void
  duplicateCurrentItem: () => void
  duplicateItem: (index: number) => void
  setEditingContains: (unit?: LoadHandlingUnitFormModel) => void
  removeCurrentItem: () => void
  removeItem: (index: number) => void
  resetItems: () => void
  setFormRef: (formRef: HTMLFormElement | null) => void
  setItems: (items: LoadHandlingUnitFormModel[]) => void
  updateCurrentItem: (item: HandlingUnitFormUpdate) => void
  updateItem: (index: number, item: HandlingUnitFormUpdate) => void
  validateForm: () => boolean
  validateItems: (workflow: Workflow, step: LoadStep) => boolean
}

export interface LoadItemsStore {
  itemState: LoadItemsFormState
  itemActions: LoadItemActions
}

type ItemStateSetter = (prev: LoadItemsFormState) => Partial<LoadItemsFormState>

const getLoadItemsFormStateCreator = (
  initialState: LoadItemsFormState,
): StateCreator<LoadItemsStore, [], [], LoadItemsStore> => {
  return (set, get) => {
    const setItemState = (fn: ItemStateSetter) => {
      set(prev => ({
        itemState: {
          ...prev.itemState,
          ...fn(prev.itemState),
        },
      }))
    }
    return {
      itemState: initialState,
      itemActions: {
        activateItem: activeIndex => setItemState(() => ({ activeIndex })),
        activateContains: activeContainsIndex =>
          setItemState(() => ({ activeContainsIndex })),
        addItem: () =>
          setItemState(prev => ({
            items: [...prev.items, defaultLoadHandlingUnit(prev.workflow)],
            activeIndex: prev.items.length,
          })),
        clearCurrentItem: () => {
          const {
            itemActions: { clearItem },
            itemState: { activeIndex },
          } = get()
          clearItem(activeIndex)
        },
        clearItem: index => {
          get().itemActions.updateItem(index, {
            description: undefined,
            type: undefined,
            stackable: undefined,
            quantity: undefined,
            weight: undefined,
            length: undefined,
            width: undefined,
            height: undefined,
            freightClass: undefined,
            isHazardous: undefined,
            fullNmfc: undefined,
          })
        },
        duplicateCurrentItem: () => {
          const {
            itemActions: { duplicateItem },
            itemState: { activeIndex },
          } = get()
          duplicateItem(activeIndex)
        },
        duplicateItem: index =>
          setItemState(({ items }) => {
            const duplicate = {
              ...JSON.parse(JSON.stringify(items[index])),
              _id: new ObjectID().toHexString(),
            }
            return {
              items: [
                ...items.slice(0, index),
                duplicate,
                ...items.slice(index),
              ],
              activeIndex: index + 1,
            }
          }),
        setEditingContains: editingContainsUnit =>
          setItemState(() => ({ editingContainsUnit })),
        removeCurrentItem: () => {
          const {
            itemActions: { removeItem },
            itemState: { activeIndex },
          } = get()
          removeItem(activeIndex)
        },
        removeItem: index =>
          setItemState(({ items, activeIndex }) => ({
            items: items.filter((i, ix) => ix !== index),
            activeIndex: Math.max(0, activeIndex - 1),
          })),
        resetItems: () =>
          setItemState(({ workflow }) => ({
            items: [defaultLoadHandlingUnit(workflow)],
            activeIndex: 0,
          })),
        setFormRef: (itemForm: HTMLFormElement | null) =>
          setItemState(() => ({ itemForm })),
        setItems: items => setItemState(() => ({ items })),
        updateCurrentItem: update => {
          const {
            itemActions: { updateItem },
            itemState: { activeIndex },
          } = get()
          updateItem(activeIndex, update)
        },
        updateItem: (index, update) =>
          setItemState(({ items }) => {
            const currentItem = items[index]
            items[index] = {
              ...currentItem,
              ...update,
            }
            return {
              items,
            }
          }),
        validateItems: (workflow: Workflow, step: LoadStep) => {
          const { itemActions, itemState } = get()
          const invalidItem = itemState.items.find((i, ix) => {
            if (!isValidItem(i, workflow, step)) {
              itemActions.activateItem(ix)
              return true
            }
            return false
          })
          if (invalidItem) {
            setTimeout(() => itemActions.validateForm(), 0) // wait for re-render to validate form
          }
          return !invalidItem
        },
        validateForm: () => {
          const form = get().itemState.itemForm
          const valid = form?.checkValidity() ?? false
          if (!valid && form) {
            submitForm(form)
          }
          return valid
        },
      },
    }
  }
}

const initialStateFromWorkflow = ({
  workflow,
  step,
}: StoreInit): LoadItemsFormState => ({
  items: [defaultLoadHandlingUnit(workflow)],
  activeIndex: 0,
  workflow,
  itemForm: null,
  step,
})

type StoreInit = {
  workflow: Workflow
  step: LoadStep
}

export const createItemsStore = (init: StoreInit) =>
  getLoadItemsFormStateCreator(initialStateFromWorkflow(init))
export const buildItemsForStore = (
  items: LoadHandlingUnit[],
  handlingUnitLookup: LegacyHandlingUnitDTO[],
): LoadHandlingUnitFormModel[] => {
  return items.map(i =>
    toLoadHandlingUnitFormModel(
      i,
      handlingUnitLookup.find(h => h._id === i.handlingUnitId),
    ),
  )
}

export const createItemsStoreFromLoad = ({
  load,
  step,
  handlingUnitLookup,
  selectedQuote,
  refreshIds,
}: {
  load: Pick<FullShipment, 'items' | 'workflow' | 'selectedQuote'>
  step: LoadStep
  handlingUnitLookup: LegacyHandlingUnitDTO[]
  selectedQuote?: UIQuote
  refreshIds?: boolean
}) => {
  let loadItems = load.items
  if (refreshIds) {
    loadItems = loadItems.map(i => ({
      ...i,
      _id: new ObjectID().toHexString(),
      contains: i.contains?.map(c => ({
        ...c,
        _id: new ObjectID().toHexString(),
      })),
    }))
  }
  const items = buildItemsForStore(loadItems, handlingUnitLookup)

  return getLoadItemsFormStateCreator({
    items,
    activeIndex: 0,
    workflow: load.workflow,
    itemForm: null,
    step,
    carrierCode: selectedQuote?.assetCarrierCode,
  })
}
