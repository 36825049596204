import { emailRegExpMatcher } from '@fv/client-core'

export function isDirtyForm(formValues: object) {
  return Object.values(formValues).some(
    v => v !== '' && v !== null && v !== undefined,
  )
}

export function validateEmailString(email: string, required = false) {
  const matches = email.match(emailRegExpMatcher)
  const isValid =
    (!email && !required) || (matches?.length === 1 && matches[0] === email)
  return isValid
}

export function validateEmailInput(
  inputEl: HTMLInputElement,
  required?: boolean,
) {
  inputEl.value = inputEl.value.trim()
  const isValid = validateEmailString(inputEl.value, required)
  inputEl.setCustomValidity(isValid ? '' : 'Invalid email format.')
}

export function validateEmailList(emails: string, required?: boolean) {
  const splitEmails = emails
    .split(/[,\s;]/)
    .map(e => e.trim())
    .filter(v => !!v)
  const invalidEmail = splitEmails.some(
    e => !e.match(emailRegExpMatcher)?.length,
  )
  const isValid = (!emails && !required) || !invalidEmail
  return isValid
}
export function validateEmailListInput(
  inputEl: HTMLInputElement,
  required?: boolean,
) {
  const isValid = validateEmailList(inputEl.value, required)
  inputEl.setCustomValidity(
    isValid ? '' : 'Please provide a comma separated list of valid emails.',
  )
}
