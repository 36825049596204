import { countries, type FormRegister } from '@fv/client-core'
import { type Country } from '@fv/models'

import { InputGroup } from '../../../components/inputs/InputGroup'

export interface BillToModel extends Record<string, unknown> {
  address: string
  city: string
  state: string
  postalCode: string
  country: Country
}
type Props = {
  register: FormRegister<BillToModel>
}
export function AddressForm({ register }: Props) {
  return (
    <div>
      <InputGroup
        label="Address"
        className="basis-full"
        inputType="text"
        inputProps={{ ...register('address') }}
      />
      <div className="flex gap-x-2">
        <InputGroup
          label="City"
          className="basis-1/3"
          inputType="text"
          inputProps={{ ...register('city') }}
        />
        <InputGroup
          label="State"
          inputType="text"
          inputProps={{ ...register('state') }}
        />
        <InputGroup
          label="Postal code"
          inputType="text"
          className="basis-1/6"
          inputProps={{ ...register('postalCode') }}
        />
        <InputGroup
          label="Country"
          className="basis-1/6"
          inputType="select"
          inputProps={{ ...register('country'), options: countries }}
        />
      </div>
    </div>
  )
}
