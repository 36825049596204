import { useEffect, useState } from 'react'

import { FvLinkButton, Icon } from '@fv/client-components'

export const ModuleLoadError = () => {
  const [seconds, setSeconds] = useState(10)

  useEffect(() => {
    if (!seconds) {
      window.location.reload()
      return
    }
    const intId = setInterval(() => {
      setSeconds(p => --p)
    }, 1000)
    return () => clearInterval(intId)
  }, [seconds])

  return (
    <div className="flex items-center flex-col pt-8">
      <Icon icon="exclamation-triangle" size="2x" />
      <br />
      <h2 className="text-lg">
        Looks like you're trying to load a part of the site we've recently
        updated. We're going to refresh your page so you get the latest
        features!
      </h2>
      <br />
      <p className="mt-8">
        If your page doesn't refresh automatically in {seconds} seconds, you can
        force a refresh using the button below.
      </p>
      <FvLinkButton
        onClick={() => {
          window.location.reload()
        }}
        icon="repeat-alt"
        theme="plain"
      >
        Force Refresh
      </FvLinkButton>
    </div>
  )
}
