import { CompanyAddressBase, LocationAccessorialDTO } from '@fv/models'

import { MilitaryTime } from './DateTime'
import { RefNum } from './Load'
import { MakeOptional } from './utils'

// Hardcoding for now since these will likely change
export const legacyShipTypes = [
  { text: 'Business w/ dock or forklift', value: 'business dock' },
  { text: 'Business w/out dock or forklift', value: 'business no dock' },
  { text: 'Airport', value: 'airport' },
  { text: 'Construction site', value: 'construction' },
  { text: 'Farm', value: 'farm' },
  { text: 'Limited access', value: 'limited access' },
  { text: 'Government/Military', value: 'military' },
  { text: 'Mine', value: 'mine' },
  { text: 'Place of worship', value: 'place of worship' },
  { text: 'Residential', value: 'residential' },
  { text: 'School', value: 'school' },
  { text: 'Tradeshow', value: 'trade show' },
  { text: 'Pier/Port', value: 'pier' },
  { text: 'Prison', value: 'prison' },
] as const

export type LegacyShipType = (typeof legacyShipTypes)[number]['value']
export type LocationType = 'destination' | 'origin' | 'stop'
export type QuoteStopType = 'origin' | 'destination'
export type StopDateType = 'between' | 'by' | 'on'
export type StopType = 'both' | 'delivery' | 'pickup'
export type SignatureAccessorialType =
  | 'adult-signature-required'
  | 'indirect-signature-required'
  | 'signature-required'

export interface ShipmentLocation
  extends MakeOptional<CompanyAddressBase, 'address'> {
  accessorials?: LocationAccessorialDTO[]
  accountLocationId?: string
  addressBookId?: string
  closesAt?: MilitaryTime
  contactEmail?: string | null
  contactName?: string | null
  contactPhone?: string | null
  contactPhoneExt?: string | null
  instructions?: string
  lat: number
  lng: number
  opensAt?: MilitaryTime
  refNums?: RefNum[]
  sequence: number
  shipType?: LegacyShipType
  stopDate?: string | null
  stopDateType: StopDateType
  stopType: StopType
  timezone?: string
  type: LocationType
}

export function displayShipType(shipType?: LegacyShipType | '') {
  if (
    !shipType ||
    shipType === 'business dock' ||
    shipType === 'business no dock'
  ) {
    return null
  }

  return legacyShipTypes.find(v => v.value === shipType)?.text ?? null
}
