import {
  vBoolean,
  vCheck,
  vCurrency,
  vInput,
  vInt,
  vNumber,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vRegex,
  vString,
  vTransform,
} from '../validation'
import { freightClasses, hazardClasses, workflows } from './common'
import { PackageTypeMapper, packagingTypes } from './packaging-type'
import {
  legacyPackingGroups,
  packingGroups,
  parseLegacyPackingGroup,
} from './packing-group'

const readOnlyDetailSchema = vObject({
  declaredValue: vOptional(vBoolean()),
  description: vOptional(vBoolean()),
  freightClass: vOptional(vBoolean()),
  hazardClass: vOptional(vBoolean()),
  hazardous: vOptional(vBoolean()),
  hazmatId: vOptional(vBoolean()),
  height: vOptional(vBoolean()),
  length: vOptional(vBoolean()),
  nmfc: vOptional(vBoolean()),
  nonStandard: vOptional(vBoolean()),
  package: vOptional(vBoolean()),
  packingGroup: vOptional(vBoolean()),
  pieces: vOptional(vBoolean()),
  saidToContain: vOptional(vBoolean()),
  saidToContainPackagingType: vOptional(vBoolean()),
  secondaryHazardClass: vPipe(
    vBoolean(),
    vTransform(v => !!v),
  ),
  specialPermit: vPipe(
    vBoolean(),
    vTransform(v => !!v),
  ),
  stackable: vOptional(vBoolean()),
  tertiaryHazardClass: vPipe(
    vBoolean(),
    vTransform(v => !!v),
  ),
  weight: vOptional(vBoolean()),
  width: vOptional(vBoolean()),
})

export const hazardDetailSchema = vObject({
  hazmatId: vOptional(vPipe(vString(), vRegex(/^(NA|UN)\d{4}$/))),
  specialPermit: vOptional(vString()),
  packingGroup: vOptional(
    vPipe(
      vPicklist([
        ...legacyPackingGroups,
        ...packingGroups.map(p => p.key),
        ...packingGroups.map(p => p.name),
      ]),
      vTransform(value => parseLegacyPackingGroup(value)),
    ),
  ),
  hazardClass: vOptional(vPicklist(hazardClasses.map(c => c.key))),
  secondaryHazardClass: vOptional(
    vPicklist(hazardClasses.filter(c => c.isValidSubClass).map(c => c.key)),
  ),
  tertiaryHazardClass: vOptional(
    vPicklist(hazardClasses.filter(c => c.isValidSubClass).map(c => c.key)),
  ),
})

export type ReadOnlyDetail = vOutput<typeof readOnlyDetailSchema>

const intSchema = vPipe(vNumber(), vInt())
export const handlingUnitSchema = vPipe(
  vObject({
    declaredValue: vOptional(
      vObject({
        amount: vOptional(vNumber()),
        currency: vCurrency('usd'),
      }),
    ),
    description: vOptional(vString()),
    freightClass: vOptional(vPicklist(freightClasses)),
    nmfc: vOptional(vPipe(vString(), vRegex(/^[0-9-]+$/))),
    mode: vPipe(
      vOptional(vPicklist(workflows)),
      vTransform(value => value || 'ltl'),
    ),
    height: vOptional(intSchema),
    length: vOptional(intSchema),
    weight: vOptional(intSchema),
    width: vOptional(intSchema),
    pieces: vOptional(intSchema),
    saidToContain: vOptional(intSchema),
    package: vPipe(
      vOptional(vPicklist(packagingTypes.map(p => p.key))),
      vTransform(value =>
        value ? PackageTypeMapper.coreToLegacy(value) : value,
      ),
    ),
    saidToContainPackagingType: vPipe(
      vOptional(
        vPicklist(packagingTypes.filter(p => p.canBeContained).map(p => p.key)),
      ),
      vTransform(value =>
        value ? PackageTypeMapper.coreToLegacy(value) : value,
      ),
    ),
    hazardous: vOptional(vBoolean()),
    hazard: vOptional(hazardDetailSchema),
    stackable: vOptional(vBoolean()),
    nonStandard: vOptional(vBoolean()),
    readOnly: vOptional(readOnlyDetailSchema),
    autoGenerated: vOptional(vBoolean()),
  }),
  vCheck(input => {
    const hasAnyDim = input.width || input.height || input.length
    const missingAnyDim = !input.width || !input.height || !input.length
    return !hasAnyDim || !missingAnyDim
  }, 'All dimensions are required if any are specified'),
)

export type HandlingUnitPropsInput = vInput<typeof handlingUnitSchema>
export type HandlingUnitProps = vOutput<typeof handlingUnitSchema>
