import { useQuery } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'

import { shipperFetch } from '../../utils/shipperFetch'
import {
  ALL_CONTACTS_ID,
  type SpotQuoteGroup,
  UNGROUPED_CONTACTS_ID,
} from './types'

const fetchSpotQuoteGroups = () =>
  shipperFetch<SpotQuoteGroup[]>('/spot-quote-groups')
const normalizeGroups = (groups: SpotQuoteGroup[]) =>
  groups.map(g => ({
    ...g,
    users: g.users.filter(u => !!u.email),
  }))

function sortAndFilter(groups: SpotQuoteGroup[]) {
  const orderedGroups = orderBy(groups ?? [], [
    i => i.groupId !== ALL_CONTACTS_ID,
    i => i.groupName.toLowerCase(),
  ])
  return normalizeGroups(orderedGroups)
}

export const spotContactKeys = {
  all: ['spot-quote-groups'],
}

export function useSpotQuoteGroups() {
  return useQuery(spotContactKeys.all, fetchSpotQuoteGroups, {
    select: sortAndFilter,
  })
}

export function useSpotQuoteContacts() {
  const query = useSpotQuoteGroups()
  return {
    ...query,
    data: query.data?.find(g => g.groupId === ALL_CONTACTS_ID)?.users,
  }
}

function sortAndFilterWithoutAll(groups: SpotQuoteGroup[]): SpotQuoteGroup[] {
  const all = groups.find(g => g.groupId === ALL_CONTACTS_ID)
  const others = groups.filter(g => g.groupId !== ALL_CONTACTS_ID)
  const groupedEmails = others.flatMap(g => g.users.map(u => u.email))

  return normalizeGroups([
    ...orderBy(others, o => o.groupName.toLowerCase()),
    {
      groupId: UNGROUPED_CONTACTS_ID,
      groupName: 'Unassigned Contacts',
      users: all?.users.filter(u => !groupedEmails.includes(u.email)) ?? [],
    },
  ])
}
export function useSpotQuoteGroupsWithUngrouped() {
  return useQuery(spotContactKeys.all, fetchSpotQuoteGroups, {
    select: sortAndFilterWithoutAll,
  })
}
