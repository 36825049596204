import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { filterAndSortEvents } from '../track/utils'
import { type SharedShipment } from './types'

async function fetchSharedShipment(loadId: string): Promise<SharedShipment> {
  const endpoint = `${apiUri}/share/${loadId}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useSharedShipment(loadId: string) {
  return useQuery(
    ['shared-shipment', loadId],
    () => fetchSharedShipment(loadId),
    {
      retry: (
        failCount: number,
        error: { message?: string; status: number },
      ) => {
        if (error.status === 404) return false
        return failCount < 3
      },
      select: data => {
        const currentSummary = data.tracking?.currentLocation?.summary
        const events = filterAndSortEvents(data.tracking?.trackEvents)

        return {
          ...data,
          trackEvents:
            events[0]?.summary === currentSummary ? events.slice(1) : events,
        }
      },
    },
  )
}
