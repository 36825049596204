import clsx from 'clsx'
import {
  FormEvent,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

interface FormProps extends HTMLProps<HTMLFormElement> {
  autoReportNextError?: boolean
  onInvalidSubmit?: (invalidEl?: HTMLInputElement) => void
  onValidSubmit?: (form: HTMLFormElement) => void
}

export const ValidatedForm = forwardRef(
  (
    {
      autoReportNextError = true,
      children,
      className,
      onInvalidSubmit,
      onValidSubmit,
      ...rest
    }: FormProps,
    ref?: ForwardedRef<HTMLFormElement | null>,
  ) => {
    const [isSubmitted, setSubmitted] = useState(false)
    const localRef = useRef<HTMLFormElement | null>(null)

    useReportNextError(autoReportNextError, isSubmitted, localRef)

    function onReset() {
      setSubmitted(false)
    }

    function onSubmit(e: FormEvent<HTMLFormElement>) {
      e.preventDefault()
      e.stopPropagation()
      setSubmitted(true)

      const formEl = e.target as HTMLFormElement
      const isValid = formEl.checkValidity()

      if (isValid) return onValidSubmit?.(formEl)

      formEl.reportValidity()

      if (onInvalidSubmit) {
        const firstInvalidEl = Array.from(formEl.elements).find(
          el => !(el as HTMLInputElement).validity.valid,
        )

        onInvalidSubmit(firstInvalidEl as HTMLInputElement)
      }
    }

    const setRef = useCallback(
      (element: HTMLFormElement | null) => {
        localRef.current = element
        if (!ref) return
        if (typeof ref === 'function') ref(element)
        else ref.current = element
      },
      [ref],
    )

    return (
      <form
        autoComplete="off"
        className={clsx(className, { isSubmitted })}
        noValidate
        onReset={onReset}
        onSubmit={onSubmit}
        ref={setRef}
        {...rest}
      >
        {children}
      </form>
    )
  },
)

function useReportNextError(
  autoReportNextError: boolean,
  isSubmitted: boolean,
  ref: MutableRefObject<HTMLFormElement | null>,
) {
  useEffect(() => {
    if (!autoReportNextError || !isSubmitted || !ref.current) return

    const formEl = ref.current
    const isValidForm = formEl.checkValidity()

    if (isValidForm) return

    const formFields = Array.from(formEl.elements) as HTMLInputElement[]
    let isMounted = true

    function reportNextError(this: HTMLInputElement) {
      if (!isMounted) return
      let invalidField: HTMLInputElement | null = null

      for (let i = 0; i < formFields.length; i++) {
        const field = formFields[i]

        if (field.type === 'submit') continue
        if (!field.checkValidity()) {
          invalidField = field
          break
        }
      }

      // Do not re-report focused field
      if (this === invalidField) return
      ref.current?.reportValidity()
    }

    formFields.forEach(field => {
      if (field.type !== 'submit') {
        field.addEventListener('blur', reportNextError)
      }
    })

    return () => {
      isMounted = false

      formFields.forEach(field => {
        field.removeEventListener('blur', reportNextError)
      })
    }
  }, [autoReportNextError, isSubmitted, ref])
}
