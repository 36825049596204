import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type BillToDTO } from '@fv/client-types'

import { apiUri } from '../../constants'

async function fetchLoadBillTo(loadId?: string): Promise<BillToDTO> {
  const endpoint = `${apiUri}/bill-to/load/${loadId}`
  const options = buildFetchOptionsWithAuth({
    method: 'GET',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useFetchLoadBillTo(loadId?: string) {
  return useQuery([], () => fetchLoadBillTo(loadId), {
    staleTime: Infinity,
    enabled: !!loadId,
  })
}
