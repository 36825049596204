import clsx from 'clsx'
import {
  type ChangeEventHandler,
  type ForwardedRef,
  forwardRef,
  type PropsWithChildren,
} from 'react'

import { type PropsWithClassName } from '@fv/client-types'

export interface RadioFieldElement<T extends string> extends HTMLInputElement {
  value: T
}

export type RadioFieldOption<T extends string = string> =
  | T
  | { label: string; value: T; disabled?: boolean }

type Props<T extends string> = {
  className?: string
  disabled?: boolean
  name: string
  onChange?: ChangeEventHandler<RadioFieldElement<T>>
  options: Array<RadioFieldOption<T>>
  value?: string
  orientation?: 'vertical' | 'horizontal'
}

export type RadioItemProps = Pick<
  Props<string>,
  'onChange' | 'name' | 'disabled'
> & {
  checked?: boolean
  id?: string
  label?: string
  value: string
}
export const RadioItem = forwardRef(
  (
    {
      label,
      value,
      checked,
      name,
      className,
      children,
      id = `${name}_${value}`,
      onChange,
      disabled = false,
    }: PropsWithChildren<PropsWithClassName<RadioItemProps>>,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div className={clsx('radio', className)} key={id}>
        <input
          checked={checked}
          className="radio__input"
          disabled={disabled}
          id={id}
          ref={ref}
          name={name}
          onChange={onChange}
          type="radio"
          value={value}
        />
        <label
          htmlFor={id}
          className={clsx('radio__display', {
            'cursor-not-allowed': disabled,
          })}
        />
        <label
          htmlFor={id}
          className={clsx('radio__label', {
            'cursor-not-allowed': disabled,
          })}
        >
          {children}
          {label}
        </label>
      </div>
    )
  },
)

export function RadioField<T extends string>({
  className,
  disabled,
  name,
  onChange,
  options,
  value,
  orientation = 'horizontal',
}: Props<T>) {
  return (
    <div
      className={clsx('flex', className, {
        'flex-col': orientation === 'vertical',
      })}
    >
      {options.map(option => {
        const label = typeof option === 'string' ? option : option.label
        const optionValue = typeof option === 'string' ? option : option.value
        const optionDisabled =
          typeof option === 'string' ? false : option.disabled
        const id = `${name}_${optionValue}`
        const checked =
          typeof value === 'string' ? value === optionValue : undefined

        return (
          <RadioItem
            className={clsx(`radio--${orientation}`, {
              'mb-2': orientation === 'vertical',
            })}
            key={id}
            onChange={onChange}
            id={id}
            disabled={disabled || optionDisabled}
            checked={checked}
            label={label}
            name={name}
            value={optionValue}
          />
        )
      })}
    </div>
  )
}
