import { UseMutateFunction } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { ChangeEvent, useState } from 'react'
import { DayPicker } from 'react-day-picker'

import {
  buildTrackStatusOptions,
  composeStopDetails,
  createStandardTime,
  standardToMilitaryTime,
} from '@fv/client-core'
import { MilitaryTime, ShipmentLocation, TimeOfDay } from '@fv/client-types'

import { FvButton } from './FvButton'
import { Icon } from './Icon'
import { SelectField } from './SelectField'
import { TextField } from './TextField'
import { ValidatedForm } from './ValidatedForm'

export type UpdateTrackStatusDTO = {
  eventDate?: Date
  eventTime?: MilitaryTime
  message?: string
  sequence: number
}

type Props = {
  className?: string
  closeForm: () => void
  initialSequence: number
  loading: boolean
  locations: ShipmentLocation[]
  onSave: UseMutateFunction<unknown, unknown, UpdateTrackStatusDTO, unknown>
}

const timeRegEx = /^\d{1,2}:\d{2}$/

export const ManualTrackForm = ({
  className,
  closeForm,
  initialSequence,
  loading,
  locations,
  onSave,
}: Props) => {
  const [state, setState] = useState({
    date: new Date(),
    sequence: initialSequence,
    timeOfDay: dayjs().format('A') as TimeOfDay,
  })

  const isDelivered = state.sequence === locations.length - 1
  const isInTransit = !isDelivered && state.sequence > 0

  function toggleTimeOfDay() {
    if (loading) return
    setState(prev => ({
      ...prev,
      timeOfDay: prev.timeOfDay === 'AM' ? 'PM' : 'AM',
    }))
  }

  function validateTime(e: ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.trim()
    const isValidFormat = timeRegEx.test(e.target.value)

    if (!isValidFormat) {
      e.target.setCustomValidity('Use HH:MM format.')
      return
    }

    const [h, m] = e.target.value.split(':')
    const isValidHours = Number(h) <= 12
    const isValidMinutes = Number(m) <= 59

    if (!isValidHours || !isValidMinutes) {
      e.target.setCustomValidity(
        isValidHours
          ? 'Minutes cannot be greater than 59.'
          : 'Hours cannot be greater than 12.',
      )
    } else e.target.setCustomValidity('')
  }

  function onValidSubmit(form: HTMLFormElement) {
    if (loading) return

    const getInput = (name: 'eventTime' | 'message') =>
      form.elements.namedItem(name) as HTMLInputElement

    let eventTime = getInput('eventTime').value
    if (eventTime.startsWith('0')) eventTime = eventTime.slice(1)
    const standardTime = createStandardTime(eventTime, state.timeOfDay)

    onSave(
      {
        eventDate: state.date,
        eventTime: standardToMilitaryTime(standardTime),
        message: getInput('message').value,
        sequence: state.sequence,
      },
      { onSuccess: closeForm },
    )
  }

  return (
    <ValidatedForm className={className} onValidSubmit={onValidSubmit}>
      <ul className="general-list general-list-fc-no-pad mb-4">
        {locations.map(x => (
          <li
            className="general-list__item multi-truck-stop-location items-start leading-[1.6rem]"
            key={x.sequence}
          >
            <Icon
              icon="map-marker"
              transform="down-4"
              className={clsx('fa-fw', {
                'text-[#99d87a]': x.type === 'origin',
                'text-[#d4d4a3]': x.type === 'stop',
                'text-[#ff9a59]': x.type === 'destination',
              })}
            />
            <span>
              {composeStopDetails(x)} at {x.city}, {x.state} {x.postalCode}
            </span>
          </li>
        ))}
      </ul>
      <div>
        <div className="mb-4 flex flex-nowrap items-center">
          <div>
            <div className="form-group">
              <label htmlFor="sequence" className="label">
                Status
              </label>
              <SelectField
                className={clsx('form-control', 'form-control--select', {
                  'in-transit': isInTransit,
                  delivered: isDelivered,
                })}
                disabled={loading}
                name="sequence"
                onChange={e => {
                  const sequence = Number(e.target.value)
                  setState(p => ({ ...p, sequence }))
                }}
                options={buildTrackStatusOptions(locations)}
                required
                value={state.sequence.toString()}
              />
            </div>
            <div className="form-group">
              <label htmlFor="eventTime" className="label">
                Time (at location)
              </label>
              <div className="input-group input-group--flex">
                <TextField
                  className="form-control"
                  defaultValue={dayjs().format('h:mm')}
                  name="eventTime"
                  onChange={validateTime}
                  readOnly={loading}
                  required
                />

                <button
                  className="-ml-px flex items-center justify-center whitespace-nowrap border border-[#b3b3b3] bg-white px-[.6rem]"
                  onClick={toggleTimeOfDay}
                  type="button"
                >
                  <Icon icon="arrows-up-down" />
                  <span>{state.timeOfDay}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="ml-8 flex h-[17rem] w-60 items-center justify-center">
            <DayPicker
              defaultMonth={state.date}
              onDayClick={date => {
                if (loading) return
                setState(p => ({ ...p, date }))
              }}
              selected={state.date}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="message" className="label">
            Message (optional)
          </label>
          <TextField
            className="form-control"
            name="message"
            placeholder="i.e. Picked up, in transit to destination"
            readOnly={loading}
          />
        </div>
      </div>

      <div className="mt-8 flex items-center">
        <FvButton
          className=""
          theme="primary"
          type="submit"
          icon={loading ? 'spinner' : 'check'}
        >
          <span>Update tracking</span>
        </FvButton>
        <FvButton onClick={closeForm} type="button" icon="times" theme="plain">
          <span>Cancel</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
