import reject from 'lodash/reject'

export const toggleArrayItem = <TItem extends string>(
  arr: TItem[],
  item: TItem,
  toggle: boolean,
): TItem[] => {
  const newArr = reject(arr, v => v === item)
  if (toggle) {
    return [...newArr, item]
  }
  return newArr
}
