import {
  vBoolean,
  vFallback,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vString,
  vTransform,
} from '../validation'
import { createPaginationSchema } from './paged'

export const opportunitySorts = [
  'pickupDate',
  'bidExpirationDate',
  'lastMessageDate',
] as const
export type OpportunitySort = (typeof opportunitySorts)[number]

type SortDir = 1 | -1

export interface OpportunitySortBase {
  sortBy?: OpportunitySort
  sortDirection?: SortDir
}

export const opportunityStatuses = [
  'open',
  'offered',
  'declined',
  'lost',
  'awarded',
  'confirmed',
] as const
export type OpportunityStatus = (typeof opportunityStatuses)[number]

const MAX_LIMIT = 20

export const opportunityFilterSchema = vObject({
  ...createPaginationSchema({ limit: MAX_LIMIT }).entries,
  accountId: vOptional(vString()),
  status: vPicklist(opportunityStatuses),
  isArchived: vOptional(vBoolean()),
  sortBy: vFallback(vPicklist(opportunitySorts), 'pickupDate'),
  sortDirection: vFallback(
    vPipe(
      vPicklist([1, -1, '1', '-1']),
      vTransform(v => Number(v) as SortDir),
    ),
    1,
  ),
})
export type OpportunityFilterDTO = vOutput<typeof opportunityFilterSchema>

export const opportunityBucketCountQuerySchema = vObject({
  accountId: vOptional(vString()),
})
export type OpportunityBucketCountDto = vOutput<
  typeof opportunityBucketCountQuerySchema
>

export type OpportunityBucketCountResponse = {
  awarded: number
  confirmed: number
  offered: number
  open: number
}

export const opportunityDetailsSchema = vObject({
  carrierName: vOptional(vString()),
  trackingNumber: vOptional(vString()),
  trailerNumber: vOptional(vString()),
  sealNumber: vOptional(vString()),
})

export type OpportunityDetailsDto = vOutput<typeof opportunityDetailsSchema>
