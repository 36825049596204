import pluralize from 'pluralize'
import { forwardRef, useImperativeHandle, useRef } from 'react'

import { FvButton } from '@fv/client-components'
import { militaryToStandardTime } from '@fv/client-core'
import {
  type ScheduledReportDTO,
  type ScheduledReportOutputType,
} from '@fv/models'

import { RadioField } from '../../components/inputs/RadioField'
import YesNoInput from '../../components/inputs/YesNoInput'
import { ListItemActions, ListItemConnector } from '../../components/List'
import { type SavedFilter } from '../../types/SavedFilter'
import {
  ContactListInput,
  type ContactListInputHandle,
  type ValidateAndAddResult,
} from '../spot-quote-contacts/ContactListInput'
import { ReportFrequencyForm } from './ReportFrequencyForm'

type Props = {
  filter: SavedFilter
  onActivate: () => void
  onDeactivate: () => void
  isActive: boolean
  value?: ScheduledReportDTO
  onChange: (value: ScheduledReportDTO) => void
}

export interface ScheduledReportConfigHandle {
  validate: () => ValidateAndAddResult
}

export const ScheduledReportConfig = forwardRef<
  ScheduledReportConfigHandle,
  Props
>(
  (
    { isActive, filter, onActivate, value, onChange, onDeactivate }: Props,
    ref,
  ) => {
    const contactInputRef = useRef<ContactListInputHandle>(null)

    useImperativeHandle(ref, () => ({
      validate: () => contactInputRef.current?.validateAndAddValue(true),
    }))

    return (
      <div className="py-3 ">
        <div className="flex items-center gap-x-3">
          <YesNoInput
            checked={value?.enabled ?? false}
            className="yes-no-toggle--on-off ml-3"
            name={`${filter._id}-enabled`}
            onChange={e => {
              onChange({ ...value, enabled: e.target.checked })
              e.target.checked ? onActivate() : onDeactivate()
            }}
          />
          {filter.name}
          <ListItemConnector />
          {!isActive && (
            <>
              <ReportSummary report={value} />
              <ListItemActions>
                <FvButton icon="pen-alt" onClick={onActivate} />
              </ListItemActions>
            </>
          )}
          {isActive && value && (
            <ReportFrequencyForm
              value={value}
              onChange={updates => onChange({ ...value, ...updates })}
            />
          )}
        </div>
        {isActive && value && (
          <div className="pl-3">
            <div className="h-32">
              <ContactListInput
                value={value.recipients ?? []}
                onChange={recipients => onChange({ ...value, recipients })}
                ref={contactInputRef}
                required
              />
            </div>
            <div className="flex items-center gap-x-3 py-3 pt-5">
              <RadioField
                name="output-type"
                orientation="horizontal"
                value={value.type ?? 'basic'}
                onChange={v =>
                  onChange({
                    ...value,
                    type: v.target.value as ScheduledReportOutputType,
                  })
                }
                options={[
                  { label: 'Basic report (.csv)', value: 'basic' },
                  { label: 'Advanced report (.xslx)', value: 'advanced' },
                ]}
              />
            </div>
          </div>
        )}
      </div>
    )
  },
)

const ReportSummary = ({ report }: { report?: ScheduledReportDTO }) => {
  if (!report?.recipients?.length || !report?.enabled) return null
  return (
    <div>
      <span>
        {report.recipients?.length}{' '}
        {pluralize('recipient', report.recipients?.length)}
      </span>
      &nbsp;/&nbsp;
      <span>
        {report.frequency} at {militaryToStandardTime(report.time)}
      </span>
    </div>
  )
}
