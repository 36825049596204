import clsx from 'clsx'
import {
  type DetailedHTMLProps,
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
  type MouseEventHandler,
} from 'react'

import { Tooltip } from '@fv/client-components'

interface CheckboxFieldProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string
  hintText?: string
  label?: string | JSX.Element
  labelClassName?: string
  name: string
  onHintClick?: MouseEventHandler<HTMLDivElement>
  tooltipOnOverflow?: boolean
}

export const CheckboxField = forwardRef(
  (
    {
      className = '',
      hintText,
      label,
      labelClassName,
      name,
      onHintClick,
      tooltipOnOverflow = false,
      ...rest
    }: CheckboxFieldProps,
    ref?: ForwardedRef<HTMLInputElement>,
  ) => {
    const labelContent = label && (
      <label
        className={clsx('checkbox__label relative', labelClassName)}
        htmlFor={name}
      >
        {label}
      </label>
    )

    return (
      <div className={`checkbox relative ${className}`}>
        <input
          className="checkbox__input"
          id={name}
          name={name}
          ref={ref}
          type="checkbox"
          {...rest}
        />

        <label className="checkbox__display" htmlFor={name} />

        {labelContent &&
          (tooltipOnOverflow && typeof label === 'string' ? (
            <Tooltip label={label} onlyOnOverflow>
              {labelContent}
            </Tooltip>
          ) : (
            labelContent
          ))}

        {hintText && (
          <div
            className="text-fv-blue cursor-pointer text-sm hover:text-[#333] absolute top-[calc(50%+.75rem)] left-[1.9rem]"
            onClick={onHintClick}
          >
            {hintText}
          </div>
        )}
      </div>
    )
  },
)

export default CheckboxField
