import { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useFvNavigate } from '@fv/client-components'

import { dateOptions } from '../../components/CustomFilterForm/filterOptions'
import { useAccountFeatures } from '../auth'
import {
  type AnalyticsPageParams,
  basePath,
  lanes,
  overview,
  providers,
  type RouteParams,
  spotQuote,
  subViews,
  users,
  type UserStatsDTO,
} from './types'

export function useAnalyticsParams() {
  const { pathname, search } = useLocation()
  const { subView, view } = useParams<RouteParams>()
  const navigate = useFvNavigate()

  useEffect(() => {
    const isValidView =
      view === lanes ||
      view === providers ||
      view === spotQuote ||
      view === users ||
      view === overview
    const isValidSubView = !subView || subViews.includes(subView)
    const params = new URLSearchParams(search)
    const prevQueryString = params.toString()

    if (!params.get('pickupDate')) {
      params.set('pickupDate', 'this-year')
    }

    if (
      !isValidView ||
      !isValidSubView ||
      params.toString() !== prevQueryString
    ) {
      let path = isValidView ? pathname : `${basePath}/${overview}`
      if (!isValidSubView)
        path = path.replace(
          subView,
          view === 'providers' ? 'carrier-overview' : 'top-lanes',
        )
      navigate(`${path}?${params.toString()}`, { replace: true })
    }
  }, [navigate, pathname, search, subView, view])

  return useMemo(() => {
    const params = new URLSearchParams(search)

    return {
      pickupDate: params.get('pickupDate'),
      qs: params.toString(),
      subView,
      view,
    } as AnalyticsPageParams
  }, [search, subView, view])
}

export const useAnalyticsDateRange = () => {
  const { pickupDate } = useAnalyticsParams()
  const dateRange = dateOptions.find(o => o.value === pickupDate)
  return dateRange
}

interface UserReportSeriesConfig {
  name: string
  field: keyof UserStatsDTO
  stacked: boolean
  color: string
}
export const useUserReportSeries = () => {
  const { truckLoad, ltl, parcel } = useAccountFeatures()
  const series: UserReportSeriesConfig[] = []

  if (truckLoad) {
    series.push(
      {
        name: 'Quoted Truckload',
        field: 'tlQuotes',
        stacked: false,
        color: '#daeef6',
      },
      {
        name: 'Booked Truckload',
        field: 'tlBooks',
        stacked: false,
        color: '#6abcdc',
      },
    )
  }
  if (ltl) {
    series.push(
      {
        name: 'Quoted LTL',
        field: 'ltlQuotes',
        stacked: true,
        color: '#fee7da',
      },
      {
        name: 'Booked LTL',
        field: 'ltlBooks',
        stacked: true,
        color: '#faa06d',
      },
    )
  }

  if (parcel) {
    series.push(
      {
        name: 'Quoted Parcel',
        field: 'parcelQuotes',
        stacked: true,
        color: '#fffde9',
      },
      {
        name: 'Booked Parcel',
        field: 'parcelBooks',
        stacked: true,
        color: '#f0ebc0',
      },
    )
  }
  return series
}
