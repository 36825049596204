import { defaultOptionsWithAuth, fetchJson } from './fetch-json'
import { redirectToLogin } from './redirect'

// Checks for the token in local storage. If exists, bounce to the /app site. Otherwise, return undefined.
export function getToken() {
  return localStorage.getItem('token') ?? ''
}

export function setToken(token: string) {
  localStorage.setItem('token', token)
}

export function destroyToken() {
  return localStorage.removeItem('token')
}

export function buildFetchOptionsWithAuth(options = {}) {
  return defaultOptionsWithAuth({ token: getToken() }, options)
}

export function logout() {
  destroyToken()
  redirectToLogin(window.location)
}

export async function logoutSession(sessionId: string | null, baseUrl: string) {
  const args = buildFetchOptionsWithAuth({
    method: 'DELETE',
    cache: 'no-store',
  })

  let url = `${baseUrl}/context`
  if (sessionId) url += `/${sessionId}`

  const result = await fetchJson(url, args)
  if (!result.ok) return
  if (!sessionId) return logout() // interactive logout
}
