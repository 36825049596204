import { useState } from 'react'

import {
  FvButton,
  FvDate,
  InputGroup,
  SliderPanel,
  type SliderPanelProps,
  TextAreaField,
} from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { ValidatedForm } from '../../components/inputs'
import { RadioField } from '../../components/inputs/RadioField'
import { useReportMissedPickup } from './mutations'

type MissedPickupProps = SliderPanelProps & {
  load: FullShipment
}

export const MissedPickupSlider = ({ load, ...props }: MissedPickupProps) => {
  const [reported, setReported] = useState(false)
  const [complete, setComplete] = useState(!!load.missedPickup?.dateReported)
  const reportMissedPickup = useReportMissedPickup(load.loadId)

  return (
    <SliderPanel {...props}>
      {complete && (
        <>
          <h2 className="text-xl">Missed pickup reported succesfully</h2>
          <p className="mt-2">
            A missed pickup for this shipment was reported on{' '}
            <strong>
              <FvDate
                val={load.missedPickup?.dateReported ?? new Date()}
                format="dddd, MMM D"
              />
            </strong>
            . Please contact <strong>{load.selectedQuote?.providerName}</strong>{' '}
            and refer to the pickup number if you have questions on the pickup.
            We have added this shipment to our reporting and will ensure there
            were no issues scheduling the pickup within Freightview.
          </p>
          <FvButton onClick={props.closePanel} icon="arrow-right">
            Close
          </FvButton>
        </>
      )}
      {!complete && (
        <ValidatedForm
          className="flex flex-col gap-6"
          onValidSubmit={e => {
            const formFields = e.elements as any
            reportMissedPickup.mutate(
              {
                additionalDetails: formFields.additionalDetails.value.trim(),
                carrierResponse: formFields.carrierResponse.value.trim(),
                isRescheduled: formFields.rescheduled.value === 'yes',
                reason: formFields.reason.value.trim(),
              },
              {
                onSuccess() {
                  setComplete(true)
                },
              },
            )
          }}
        >
          <>
            <header>
              <h2 className="mb-0 text-lg text-black">
                Report a missed pickup
              </h2>
              <hr className="my-2" />
            </header>
            <InputGroup label="How did you find out they missed the pickup?">
              <TextAreaField
                required
                className="border-fv-gray min-h-[80px] border-[1px] p-2"
                name="reason"
              />
            </InputGroup>

            <InputGroup label="If you've already talked to the carrier about it, what did they say?">
              <TextAreaField
                className="border-fv-gray min-h-[80px] border-[1px] p-2"
                name="carrierResponse"
              />
            </InputGroup>

            <InputGroup label="Have you already rescheduled the pickup with the carrier?">
              <hr className="mt-0" />
              <RadioField
                name="rescheduled"
                onChange={e => {
                  setReported(e.target.value === 'yes')
                }}
                value={reported ? 'yes' : 'no'}
                options={[
                  {
                    label: 'Yes, I have',
                    value: 'yes',
                  },
                  {
                    label: 'No, not yet',
                    value: 'no',
                  },
                ]}
              />
              <hr className="mb-2" />
            </InputGroup>

            <InputGroup label="Any other details we should know?">
              <TextAreaField
                className="border-fv-gray min-h-[80px] border-[1px] p-2"
                name="additionalDetails"
              />
            </InputGroup>
            <div>
              <FvButton type="submit" icon="check" theme="primary">
                Submit
              </FvButton>
              <FvButton theme="plain" icon="times" onClick={props.closePanel}>
                Cancel
              </FvButton>
            </div>
          </>
        </ValidatedForm>
      )}
    </SliderPanel>
  )
}
