import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { shipperFetch } from '../../../utils/shipperFetch'

type PasswordRequest = {
  newPassword: string
  currentPassword: string
}

const postNewPassword = async (passwords: PasswordRequest): Promise<string> => {
  return await shipperFetch('/password', {
    body: {
      currentPassword: passwords.currentPassword.trim(),
      newPassword: passwords.newPassword.trim(),
    },
    method: 'POST',
  })
}

export const usePasswordMutations = () => {
  const savePassword = useSavePassword()

  return {
    savePassword: async (passwords: PasswordRequest) => {
      return await savePassword.mutateAsync(passwords)
    },
    isSaving: savePassword.isLoading,
  }
}

const useSavePassword = () => {
  return useMutation(
    (passwords: PasswordRequest) =>
      postNewPassword({
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
      }),
    {
      onSuccess: () => {
        toast.success('Success! Your password has been successfully changed.')
      },
      onError: (error: { message: string }) => {
        toast.error(error.message)
      },
    },
  )
}
