import { useMutation, useQuery } from '@tanstack/react-query'

import { showApiError } from '@fv/client-core'
import { type FullShipment, type UIQuote } from '@fv/client-types'

import { useUpdateCachedLoad } from '../../hooks/shipments'
import { quoteKeys } from '../../hooks/shipments/useQuotes'
import { shipperFetch } from '../../utils/shipperFetch'

async function fetchReratedQuote(loadId?: string) {
  if (!loadId) throw new Error('`loadId` required to fetch quote.')
  return shipperFetch<UIQuote>(`/shipments/${loadId}/quotes/rerated`)
}

export function useReratedQuote(
  load?: Pick<FullShipment, 'loadId' | 'currentQuoteVersion'>,
  enabled = false,
) {
  return useQuery(
    quoteKeys.rerated(load?.loadId, load?.currentQuoteVersion),
    () => fetchReratedQuote(load?.loadId),
    {
      enabled: enabled && !!load,
    },
  )
}

async function acceptRerate(
  load: Pick<FullShipment, 'loadId'>,
): Promise<FullShipment> {
  return shipperFetch(`/shipments/${load.loadId}/quotes/accept-rerate`, {
    method: 'POST',
  })
}

export function useAcceptRerate() {
  const updateCachedLoad = useUpdateCachedLoad()
  return useMutation(acceptRerate, {
    onSettled(updatedLoad, err) {
      if (err) return showApiError('', err)

      updateCachedLoad({
        updatedLoad,
      })
    },
  })
}
