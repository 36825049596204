import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useFvNavigate } from '@fv/client-components'
import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type FullShipment, type QuoteRequestDTO } from '@fv/client-types'

import { apiUri, routes, supportMessage } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'
import { quoteKeys } from '../../hooks/shipments/useQuotes'
import { quoteRequestKeys } from './useQuoteRequest'

async function updateQuoteRequest(
  dto: QuoteRequestDTO & { quoteRequestId?: string },
): Promise<FullShipment[]> {
  if (!dto.quoteRequestId) {
    throw new Error('`quoteRequestId` required to update quote request')
  }

  const endpoint = `${apiUri}/shipments/quote-request/${dto.quoteRequestId}`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(dto),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

type ApiError = { message?: string } | null

export function useUpdateQuoteRequest(quoteRequestId?: string) {
  const navigate = useFvNavigate()
  const queryClient = useQueryClient()
  const updateCachedLoad = useUpdateCachedLoad()

  const mutation = useMutation(
    (dto: QuoteRequestDTO) => updateQuoteRequest({ ...dto, quoteRequestId }),
    {
      onSettled: (shipments, error: ApiError) => {
        if (error || !shipments) {
          let message = 'Unable to update quote request'

          if (error?.message) message += `: ${error?.message}`
          else message += `, ${supportMessage}`

          toast.error(message)
          mutation.reset()
          return
        }

        queryClient.setQueryData(
          quoteRequestKeys.request(quoteRequestId),
          shipments,
        )

        shipments.forEach(updatedLoad => {
          if (updatedLoad.quoteProcessStatus === 'rerating') {
            queryClient.invalidateQueries(quoteKeys.load(updatedLoad.loadId))
          }

          updateCachedLoad({
            updatedLoad,
            keysToSkip: [...quoteRequestKeys.all],
          })
        })

        navigate(routes.rates(shipments[0]))
      },
    },
  )

  return mutation
}
