import { type Quote, type UIQuote, type Workflow } from '@fv/client-types'

import { quoteFuncs } from '../features/quote/quoteFuncs'
import { useUserSettings } from './settings'

export const useRateModifier = () => {
  const userSettings = useUserSettings()

  return (quote: UIQuote | Quote, workflow: Workflow) =>
    quoteFuncs.applyMarkup(quote, workflow, userSettings.data?.rateModifier)
}
