export const distanceProviders = ['pc-miler'] as const
export const routingTypes = ['fastest', 'practical', 'shortest'] as const
export const providerVersions = [
  'Current',
  'PCM24',
  'PCM25',
  'PCM26',
  'PCM27',
  'PCM28',
  'PCM29',
  'PCM30',
  'PCM31',
  'PCM32',
  'PCM33',
  'PCM34',
  'PCM35',
  'PCM36',
  'PCM37',
  'PCM38',
] as const

export type DistanceProviderVersion = (typeof providerVersions)[number]
export type DistanceProvider = (typeof distanceProviders)[number]
export type ProviderVersion = (typeof providerVersions)[number]
export type RoutingType = (typeof routingTypes)[number]
