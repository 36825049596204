import {
  type ChangeEvent,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react'

import { type HoldAtLocation } from '@fv/client-types'

import CheckboxField from '../../components/inputs/CheckboxField'
import {
  type RadioFieldOption,
  RadioItem,
  type RadioItemProps,
} from '../../components/inputs/RadioField'
import { Loading } from '../../components/Loading'
import { quoteFuncs } from '../quote/quoteFuncs'
import { useBookingFormCtx } from './BookingFormProvider'
import { useHoldAtLocations } from './useHoldAtLocations'

const HoldAtLocationItemField = ({ children }: PropsWithChildren) => (
  <div className="border-b py-2 last:border-b-0">{children}</div>
)
const HoldAtLocationItem = ({
  onChange,
  checked,
  loc,
}: Pick<RadioItemProps, 'onChange' | 'checked'> & { loc: HoldAtLocation }) => {
  const id = `hold-at-location_${loc.locationId}`
  return (
    <label
      className="b1100:basis-1/3 mx-2 basis-full border border-gray-300 bg-white p-4"
      htmlFor={id}
    >
      <HoldAtLocationItemField>
        <RadioItem
          label={`${loc.distance} miles from delivery`}
          value={loc.locationId}
          id={id}
          name="hold-at-location"
          onChange={onChange}
          checked={checked}
        />
      </HoldAtLocationItemField>
      {loc.inLocation && (
        <HoldAtLocationItemField>{loc.inLocation}</HoldAtLocationItemField>
      )}
      <HoldAtLocationItemField>{loc.locTypeDisplay}</HoldAtLocationItemField>
      <HoldAtLocationItemField>{`${loc.address} ${loc.city}, ${loc.state}`}</HoldAtLocationItemField>
    </label>
  )
}

export const HoldAtLocationForm = () => {
  const [_, destination] = useBookingFormCtx(s => s.locations)
  const holdAtLocation = useBookingFormCtx(s => s.holdAtLocation)
  const actions = useBookingFormCtx(s => s.actions)
  const quote = useBookingFormCtx(s => s.quote)
  const loadId = useBookingFormCtx(s => s.loadId)
  const [showLocs, setShowLocs] = useState(false)
  const {
    data: locations,
    isLoading,
    isFetching,
  } = useHoldAtLocations(showLocs, { loadId, ...destination })

  useEffect(() => {
    if (locations?.length && showLocs && !holdAtLocation) {
      actions.updateHoldAtLocation(locations[0])
    }
  }, [locations, isFetching, showLocs, actions, holdAtLocation])

  if (!quoteFuncs.isFedex(quote)) return null

  const radioOptions = locations?.map<RadioFieldOption>(l => ({
    label: `${l.address} ${l.city}, ${l.state}`,
    value: l.locationId,
  }))
  const handleLocChange = (e: ChangeEvent<HTMLInputElement>) => {
    const loc = locations?.find(l => l.locationId === e.target.value)
    actions.updateHoldAtLocation(loc)
  }
  const toggleHoldAtLoc = (e: ChangeEvent<HTMLInputElement>) => {
    setShowLocs(e.target.checked)
    if (!e.target.checked) {
      actions.updateHoldAtLocation(undefined)
    }
  }

  return (
    <div className="border-fv-gray mb-8 border bg-fv-gray-100 p-4">
      <div className="form-row">
        <CheckboxField
          name="holdat"
          onChange={toggleHoldAtLoc}
          label="Hold at Location: I would like to have this shipment held at a FedEx location"
        />
      </div>
      {showLocs && (isLoading || isFetching) && <Loading />}
      {showLocs && !!locations?.length && (
        <div className="flex py-2">
          {locations.slice(0, 3).map(loc => (
            <HoldAtLocationItem
              key={loc.locationId}
              loc={loc}
              checked={holdAtLocation?.locationId === loc.locationId}
              onChange={handleLocChange}
            />
          ))}
        </div>
      )}
      {showLocs && !isLoading && !radioOptions?.length && (
        <div>There was an error fetching hold at locations</div>
      )}
    </div>
  )
}
