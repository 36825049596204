import clsx from 'clsx'
import dayjs from 'dayjs'

import { Icon, type IconProps } from '@fv/client-components'
import { type ListShipment } from '@fv/client-types'

import { ShipmentCardWorkflowLabel } from './ShipmentCardWorkflowLabel'

const trackingIcons: { [key: string]: IconProps['icon'] } = {
  'picked-up': 'truck-moving',
  awarded: 'trophy',
  canceled: 'times',
  confirmed: 'clock',
  delivered: 'dolly',
  bolOnly: 'file-contract',
  daily: 'calendar-day',
}

type ActiveShipmentCardHeaderProps = {
  carrierName?: string
  shipment: Pick<
    ListShipment,
    | 'loadId'
    | 'pickup'
    | 'status'
    | 'tracking'
    | 'equipment'
    | 'locations'
    | 'workflow'
  >
}

const ActiveShipmentCardHeader = ({
  carrierName,
  shipment,
}: ActiveShipmentCardHeaderProps) => {
  const { status, equipment, locations, pickup, workflow } = shipment
  const pickupStatus = pickup?.status

  const getIcon = () => {
    if (pickupStatus === 'not-requested') {
      switch (workflow) {
        case 'parcel':
          return trackingIcons['daily']
        case 'ltl':
          return trackingIcons['bolOnly']
        default:
          return trackingIcons[status]
      }
    } else {
      return trackingIcons[status]
    }
  }

  return (
    <>
      <div className="shipment-card__tab shipment-card__tab--completed">
        <ShipmentCardWorkflowLabel
          mode={equipment.mode}
          isMultiStop={locations.length > 2}
          carrierName={carrierName}
        />
      </div>
      <div
        className={clsx('shipment-card__tracking-status', {
          'in-transit': status === 'picked-up',
          canceled: status === 'canceled',
          delivered: status === 'delivered',
          scheduled: status === 'confirmed' && pickupStatus !== 'not-requested',
          'not-requested':
            status === 'confirmed' && pickupStatus === 'not-requested',
        })}
      >
        <div
          className={clsx('tracking-legend__step active', {
            'in-transit': status === 'picked-up',
            canceled: status === 'canceled',
            delivered: status === 'delivered',
            scheduled: status === 'confirmed',
          })}
        >
          <div
            className={clsx(
              pickupStatus === 'not-requested'
                ? 'tracking-legend__circle_not_requested'
                : 'tracking-legend__circle',
            )}
          />
          <Icon className="color-dark" icon={getIcon()} />
          <span>{composeLoadStatus(shipment)}</span>
        </div>
      </div>
    </>
  )
}

export const getTransitStatus = (
  pickupStatus?: string,
  workflow?: string,
): string => {
  if (workflow === 'parcel') {
    return pickupStatus === 'ok' ? 'Scheduled' : 'Daily pickup'
  } else {
    return pickupStatus === 'ok' ? 'Scheduled' : 'Print BOL only'
  }
}

const composeLoadStatus = (
  shipment: ActiveShipmentCardHeaderProps['shipment'],
) => {
  const { pickup, status, tracking, workflow } = shipment

  switch (status) {
    case 'awarded':
      return 'Awarded'
    case 'picked-up':
      return 'In transit'
    case 'canceled':
      return 'Canceled'
    case 'confirmed':
      return getTransitStatus(pickup?.status, workflow)
    case 'delivered': {
      const deliveryDate = tracking?.deliveryDateActual
      return `Delivered${
        deliveryDate ? ` ${dayjs(deliveryDate).format('ddd MMM D')}` : ''
      }`
    }
    default:
      return 'Unknown'
  }
}

export default ActiveShipmentCardHeader
