import { useInfiniteQuery } from '@tanstack/react-query'
import omit from 'lodash/omit'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import {
  flattenPages,
  getNextPageParam,
  parseQueryString,
  stringifyQueryString,
} from '@fv/client-core'
import { type PagedShipments } from '@fv/client-types'

import { defaultPageSize } from '../../../constants'
import { shipperFetch } from '../../../utils/shipperFetch'
import { defaultSortBy, defaultSortDirection } from './useShipmentListParams'

// Ensure order in queryKey used by cache
function normalizeQueryString(qs: string) {
  const parsed = omit(parseQueryString(qs), 'load')
  return stringifyQueryString(Object.fromEntries(Object.entries(parsed).sort()))
}

export const inProcessShipmentKeys = {
  all: ['shipments', 'inProcess'] as const,
  bookingErrors: () => ['shipment-booking-errors'],
  filtered: (qs: string) =>
    [...inProcessShipmentKeys.all, normalizeQueryString(qs)] as const,
}

export function isDefaultFilter(qs: string) {
  const params = new URLSearchParams(qs)
  params.delete('load')
  params.delete('search')
  params.delete('quotedBy[]')
  params.delete('sortBy')
  params.delete('sortDirection')
  return params.toString().length === 0
}

export const fetchInProcessShipments = (
  qs: string,
): Promise<PagedShipments> => {
  return shipperFetch(`/shipments/in-process?${qs}`)
}

export function useInProcessShipments() {
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  if (!params.get('sortBy')) params.set('sortBy', defaultSortBy)
  if (!params.get('sortDirection')) {
    params.set('sortDirection', defaultSortDirection)
  }

  const shipmentsQuery = useInfiniteQuery(
    inProcessShipmentKeys.filtered(params.toString()),
    ({ pageParam = 0 }) => {
      params.set('limit', String(defaultPageSize))
      params.set('skip', String(pageParam))

      return fetchInProcessShipments(params.toString())
    },
    {
      getNextPageParam,
    },
  )

  // Handle invalid filters
  useEffect(() => {
    if (
      shipmentsQuery.error instanceof Error &&
      shipmentsQuery.error.name === 'EventValidationError'
    ) {
      toast.error(shipmentsQuery.error.message)
    }
  }, [shipmentsQuery.error])

  return {
    ...shipmentsQuery,
    data: flattenPages(shipmentsQuery.data?.pages),
  }
}
