import { type ReactNode, useEffect, useMemo } from 'react'

import { type ProductType } from '@fv/models'

import { useAccountFeatures } from './hooks'

type Props = {
  all?: ProductType[]
  children: ReactNode
  name?: ProductType
  oneOf?: ProductType[]
  redirect?: string
}

export const HasFeature = ({ all, children, name, oneOf, redirect }: Props) => {
  const features = useAccountFeatures()

  const hasFeature = useMemo(() => {
    if (name) return features[name]
    if (oneOf) return oneOf.some(f => features[f])
    if (all) return all.every(f => features[f])
    return false
  }, [all, features, name, oneOf])

  useEffect(() => {
    if (hasFeature || !redirect) return
    window.location.href = redirect
  }, [hasFeature, redirect])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (hasFeature) return <>{children}</>
  return null
}
