import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  flattenPages,
  getNextPageParam,
  useDebouncedValue,
} from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'
import { type AddressLookupContext, type AddressLookupDTO } from '@fv/models'

import { shipperFetch } from '../../utils/shipperFetch'
import { type AddressBookResponse } from './types'
import { useAddressBook } from './useAddressBook'

type AddressBookSearchKeyArgs = Pick<AddressLookupDTO, 'search' | 'context'>
const all = ['address-book'] as const
const allSearch = ['address-book', 'search'] as const
export const addressBookKeys = {
  all,
  allSearch,
  search: (search: AddressBookSearchKeyArgs) => [...allSearch, search] as const,
}

type FetchArgs = Omit<AddressLookupDTO, 'limit'>

async function fetchAddresses({
  search,
  context,
  skip = 0,
}: FetchArgs): Promise<AddressBookResponse> {
  return shipperFetch(`/addresses`, {
    query: {
      limit: 50,
      skip,
      context,
      ...(search && { search }),
    },
  })
}

interface AddressBookHookArgs {
  enabled: boolean
  postalCodeFilter?: string
  searchText: string
  context: AddressLookupContext
}

export function useAddressBookAddresses(args: AddressBookHookArgs) {
  const { enabled, postalCodeFilter, searchText, context } = args
  const debouncedSearchText = useDebouncedValue(searchText.trim())
  const search = postalCodeFilter || debouncedSearchText
  const queryClient = useQueryClient()
  const addressQuery = useInfiniteQuery(
    addressBookKeys.search({ search, context }),
    ({ pageParam = 0 }) =>
      fetchAddresses({
        search,
        context,
        skip: pageParam,
      }),
    {
      enabled,
      getNextPageParam,
      keepPreviousData: !postalCodeFilter,
    },
  )

  return {
    ...addressQuery,
    invalidate: () => {
      queryClient.invalidateQueries(addressBookKeys.all)
    },
    data: flattenPages(addressQuery.data?.pages),
  }
}

export const useAddressesFromStore = (enabled?: boolean) => {
  const addressBook = useAddressBook()
  const addressBookQuery = useAddressBookAddresses({
    enabled: addressBook.isOpen || enabled,
    context: addressBook.config.context,
    postalCodeFilter: addressBook.config.postalCodeFilter,
    searchText: addressBook.searchText,
  })
  return addressBookQuery
}

export function useAddressBookEntry(id?: string) {
  return useQuery(
    [...all, id],
    () => shipperFetch<ClientAddressLookupResult>(`/addresses/${id}`),
    { enabled: !!id },
  )
}
