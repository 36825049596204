import clsx from 'clsx'
import { type Dispatch, type SetStateAction } from 'react'

import { FvLinkButton } from '@fv/client-components'

import { AuditPriceDisplay } from './components/AuditPriceDisplay'
import { AuditRateBreakdown } from './components/AuditRateBreakdown'
import { AuditCardArrowCtn } from './AuditCardArrowCtn'
import AuditNotes from './AuditNotes'
import { useResetAudit } from './queries'
import { type Audit } from './types'

type ArchivedAuditFormProps = {
  audit: Audit
  isActive: boolean
  setActive: Dispatch<SetStateAction<string | null>>
}

const ArchivedAuditForm = ({
  audit,
  isActive,
  setActive,
}: ArchivedAuditFormProps) => {
  const { invoiceAmount, loadId, notes, quote } = audit
  const { resetAudit, isResettingAudit } = useResetAudit(audit, 'archived')

  return (
    <>
      <div
        className={clsx('flex', {
          'mb-4': isActive,
        })}
      >
        <AuditPriceDisplay amount={invoiceAmount ?? quote.amount} />

        <div className="divided-content divided-content--start">
          <FvLinkButton
            className="ml-2"
            onClick={() => {
              !isResettingAudit && resetAudit(loadId)
            }}
            icon={isResettingAudit ? 'spinner' : 'arrow-left'}
          >
            <span>Move back to outstanding queue</span>
          </FvLinkButton>
          <FvLinkButton
            onClick={() => {
              setActive(prev => (prev === loadId ? null : loadId))
            }}
            icon={`caret-circle-${isActive ? 'up' : 'down'}`}
          >
            <span>Show audit details</span>
          </FvLinkButton>
        </div>
      </div>
      {isActive && (
        <AuditCardArrowCtn>
          <AuditRateBreakdown audit={audit} />
        </AuditCardArrowCtn>
      )}

      {isActive && <AuditNotes className="mt-4" notes={notes} />}
    </>
  )
}

export default ArchivedAuditForm
