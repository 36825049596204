import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { getNextPageParam, type Page } from '@fv/client-core'
import {
  type CarrierProvisioningDetails,
  type ProvisionFilterBase,
  type ProvisioningEntry,
} from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { type ProvisionerAccountProvisioningDto } from './dto'

export const provisionerQueryKeys = {
  all: ['provisioning'],
  config: () => [...provisionerQueryKeys.all, 'config'],
  filtered: (filter: ProvisionFilterBase) => [
    ...provisionerQueryKeys.all,
    filter.status,
    filter.sort,
    filter.sortDir,
    ...(filter.search ? [filter.search] : []),
  ],
  account: (accountId?: string) => [
    ...provisionerQueryKeys.all,
    'account',
    accountId,
  ],
}

export const useProvisionerList = (filter: ProvisionFilterBase) => {
  return useInfiniteQuery<
    Page<ProvisioningEntry>,
    unknown,
    Page<ProvisioningEntry>
  >(
    provisionerQueryKeys.filtered(filter),
    ({ pageParam = 0 }) =>
      shipperFetch<Page<ProvisioningEntry>>(`/provisioner`, {
        query: {
          ...filter,
          limit: 50,
          skip: pageParam,
        },
      }),
    {
      select: d => ({
        ...d,
        pages: d.pages,
      }),
      getNextPageParam,
      keepPreviousData: true,
    },
  )
}

export const useProvisionerAccountDetails = (accountId?: string) => {
  return useQuery(
    provisionerQueryKeys.account(accountId),
    () =>
      shipperFetch<ProvisionerAccountProvisioningDto>(
        `/provisioner/account/${accountId}`,
      ),
    {
      enabled: !!accountId,
    },
  )
}

export const useProvisionerCarrierConfig = () => {
  return useQuery(
    provisionerQueryKeys.config(),
    () => shipperFetch<CarrierProvisioningDetails>(`/provisioner/config`),
    {
      staleTime: Infinity,
    },
  )
}
