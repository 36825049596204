import { type EmergencyContact } from '@fv/client-types'

import { type FormEmergencyContact } from './contactTypes'

export const defaultFormContact: FormEmergencyContact = {
  contractNumber: '',
  name: '',
  phone: '',
  phoneExt: '',
}

export const emergencyContactMapper = {
  dtoToForm: (contact?: EmergencyContact | null): FormEmergencyContact => ({
    contractNumber: contact?.contractNumber || '',
    name: contact?.name || '',
    phone: contact?.phone?.replace(/\D/g, '') || '',
    phoneExt: contact?.phoneExt || '',
  }),
  formToDTO: (contact: FormEmergencyContact): EmergencyContact => {
    const contractNumber = contact.contractNumber.trim()
    const phoneExt = contact.phoneExt.trim()

    return {
      name: contact.name.trim(),
      phone: contact.phone.trim(),
      ...(contractNumber && { contractNumber }),
      ...(phoneExt && { phoneExt }),
    }
  },
}

export function validateEmergencyContact(
  contact?: EmergencyContact | FormEmergencyContact | null,
) {
  return Boolean(contact?.name?.trim() && contact.phone?.trim())
}
