import { useEffect, useState } from 'react'

import { download } from '@fv/client-core'

import { apiUri } from '../../constants'
import { useAuthenticatedFileDownload } from '../../hooks/useAuthenticatedFileDownload'

export function useDownloadRatesPDF(loadId: string) {
  const [enabled, setEnabled] = useState(false)
  const { data, isFetching, isSuccess, remove } = useAuthenticatedFileDownload(
    `${apiUri}/documents/${loadId}/rates-pdf`,
    enabled,
  )

  useEffect(() => {
    if (!isSuccess) {
      return
    }

    download(data, `${loadId}-rates.pdf`)

    setEnabled(false)
    remove()
  }, [loadId, data, isSuccess, remove])

  return {
    isFetchingFile: isFetching,
    setFetching: setEnabled,
  }
}
