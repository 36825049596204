import clsx from 'clsx'
import dayjs from 'dayjs'
import { type ChangeEvent, type ForwardedRef, forwardRef } from 'react'

import { FvButton, Icon, SelectField } from '@fv/client-components'
import { timeOptions } from '@fv/client-core'
import { militaryTimeFormat } from '@fv/client-types'

type TimeSelectFieldProps = {
  containerClassName?: string
  format?: string
  name: string
  setValue: (t: string | null) => void
  value?: string
}

const TimeSelectField = forwardRef(
  (
    {
      containerClassName,
      format = militaryTimeFormat,
      name,
      setValue,
      value,
    }: TimeSelectFieldProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    const time = value ? dayjs(value, format) : null
    const parsedTime = time ? time.format('h:mm') : null
    const timeOfDay = time ? time.format('A') : 'AM'

    const changeTime = (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = e.target.value

      if (selectedValue === '-') setValue(null)
      else {
        const time = dayjs(`${selectedValue} ${timeOfDay}`, 'h:mm A')
        setValue(time.format(format))
      }
    }

    const changeTimeOfDay = () => {
      if (!parsedTime) return
      const nextTimeOfDay = timeOfDay === 'AM' ? 'PM' : 'AM'
      const time = dayjs(`${parsedTime} ${nextTimeOfDay}`, 'h:mm A')

      setValue(time.format(format))
    }

    return (
      <div
        className={clsx('input-group input-group--flex', containerClassName)}
      >
        <SelectField
          className="form-control form-control--select"
          ref={ref}
          name={name}
          onChange={changeTime}
          options={['-', ...timeOptions]}
          required
          value={parsedTime ?? '-'}
        />
        <FvButton
          theme="default"
          className="input-group--flex__btn"
          id={`${name}TimeOfDay`}
          onClick={changeTimeOfDay}
        >
          <Icon icon="arrows-alt-v" />
          <span>{timeOfDay}</span>
        </FvButton>
      </div>
    )
  },
)

export default TimeSelectField
