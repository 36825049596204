import dayjs from 'dayjs'
import { type ChangeEvent } from 'react'

import { type MilitaryTime, militaryTimeFormat } from '@fv/client-types'
import { type LocationAccessorialDTO } from '@fv/models'

import CheckboxField from './CheckboxField'
import TimeSelectField from './TimeSelectField'

export const apptRequiredKey = 'schedule'

export interface LocationAccessorialWithMilitaryTimes
  extends Omit<LocationAccessorialDTO, 'closesAt' | 'opensAt'> {
  opensAt?: MilitaryTime
  closesAt?: MilitaryTime
}

type AppointmentConfigProps = {
  apptDetails?: LocationAccessorialDTO
  setApptDetails: (
    d: { opensAt?: MilitaryTime; closesAt?: MilitaryTime } | null,
  ) => void
}

const AppointmentConfig = ({
  apptDetails,
  setApptDetails,
}: AppointmentConfigProps) => {
  const myApptDetails = apptDetails as LocationAccessorialWithMilitaryTimes
  const opensAt = myApptDetails?.opensAt
  const closesAt = myApptDetails?.closesAt

  return (
    <>
      <CheckboxField
        checked={!!apptDetails}
        className="checkbox mb-4"
        label="Appointment required"
        name="appointmentRequired"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.checked) {
            setApptDetails({
              opensAt: null,
              closesAt: null,
            })
          } else {
            setApptDetails(null)
          }
        }}
      />

      {!!apptDetails && (
        <div className="flex items-center">
          <TimeSelectField
            containerClassName="col"
            name="opensAt"
            setValue={opensAt => {
              setApptDetails({
                opensAt: opensAt as MilitaryTime,
                closesAt,
              })
            }}
            value={opensAt}
          />

          <span className="ml-2 mr-2">- to -</span>

          <TimeSelectField
            containerClassName="col"
            name="closesAt"
            setValue={closesAt => {
              setApptDetails({
                opensAt,
                closesAt: closesAt as MilitaryTime,
              })
            }}
            value={closesAt}
          />
        </div>
      )}
    </>
  )
}

export function getApptTimes(
  apptDetails?: LocationAccessorialWithMilitaryTimes,
) {
  const { opensAt, closesAt } = apptDetails ?? {}
  const openTime = opensAt ? dayjs(opensAt, militaryTimeFormat) : null
  const closeTime = closesAt ? dayjs(closesAt, militaryTimeFormat) : null

  if (openTime && closeTime) {
    return `${openTime.format('h:mma')} - ${closeTime.format('h:mma')}`
  }

  if (openTime) return `${openTime.format('h:mma')} - end time`
  if (closeTime) return `Start time - ${closeTime.format('h:mma')}`
  return 'Start time - end time'
}

export default AppointmentConfig
