import { type ForwardedRef, forwardRef } from 'react'

import {
  DockTimeInput as BaseDockTimeInput,
  type DockTimeInputProps,
  FvButton,
  type FvButtonProps,
  type SelectFieldProps,
} from '@fv/client-components'

import {
  InputAdornment,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { SelectField } from '../../components/inputs/SelectField'

type Props = Omit<DockTimeInputProps, 'Button' | 'Select'> & {
  label: string
}

const DockTimeButton = forwardRef(
  (
    { children, className, ...props }: FvButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <InputAdornment position="end">
      <FvButton ref={ref} theme="default" {...props}>
        {children}
      </FvButton>
    </InputAdornment>
  ),
)
const DockTimeSelect = forwardRef(
  (props: SelectFieldProps, ref: ForwardedRef<HTMLSelectElement>) => (
    <SelectField
      className="form-control form-control--select"
      ref={ref}
      {...props}
    />
  ),
)
export const DockTimeInput = ({ label, className, ...props }: Props) => {
  return (
    <InputGroupWrapper className={className} label={label} name={props.name}>
      <BaseDockTimeInput
        {...props}
        Select={DockTimeSelect}
        Button={DockTimeButton}
      />
    </InputGroupWrapper>
  )
}
