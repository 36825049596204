import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Workflow } from '@fv/client-types'

import { apiUri } from '../../constants'

const fetchCarrierServiceTypes = async (
  scac: string,
  workflow?: Workflow,
): Promise<CarrierService[]> => {
  const endpoint = `${apiUri}/carriers/${scac}/serviceTypes?workflow=${workflow}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export const useCarrierServiceTypes = (
  scac?: string,
  workflow: Workflow = 'ltl',
) => {
  if (!scac) {
    scac = 'default'
  }
  return useQuery(['carrier-service-types', scac, workflow], () =>
    fetchCarrierServiceTypes(scac ?? 'default', workflow),
  )
}

export type CarrierService = {
  guaranteed?: boolean
  guaranteedByTime?: string
  id: string
  name: string
}
