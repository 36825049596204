import pluralize from 'pluralize'
import { useState } from 'react'
import toast from 'react-hot-toast'

import { FvLinkButton } from '@fv/client-components'

import { DayPickerPanel } from '../../dates/DayPickerPanel'
import { useExtendContractedRates } from './mutations'

type Props = {
  carrierId?: string
  postalCode?: string
  contractId?: string
  rateCount?: number
}

export const ExtendContractedRates = ({
  carrierId,
  postalCode,
  contractId,
  rateCount,
}: Props) => {
  const [sliderOpen, setSlider] = useState(false)
  const extendContractedRates = useExtendContractedRates(carrierId)
  const isFiltered = postalCode || contractId

  function reset() {
    setSlider(false)
  }

  function saveDates(endDate?: Date) {
    if (!endDate) {
      toast.error('No date selected.')
      return
    }

    const confirmed = window.confirm(
      `Are you sure you want to update the expiration date on ${pluralize(
        'rate',
        rateCount,
        true,
      )}?`,
    )
    if (!confirmed) return

    extendContractedRates.mutate(
      { endDate, carrierId, postalCode, contractId },
      { onSuccess: reset },
    )
  }

  return (
    <>
      <FvLinkButton
        onClick={e => {
          setSlider(true)
        }}
        icon="calendar-day"
        transform="up-.5"
      >
        Extend {isFiltered ? 'filtered' : 'all'} contract dates
      </FvLinkButton>
      <DayPickerPanel
        mode="single"
        numberOfMonths={2}
        open={sliderOpen}
        onClose={() => setSlider(false)}
        onCancel={reset}
        isSaving={extendContractedRates.isLoading}
        saveText="Update"
        onSave={saveDates}
        titleContent={() => (
          <>
            Please select your new contract end date.
            <hr className="my-6" />
          </>
        )}
      />
    </>
  )
}
