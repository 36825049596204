import { type ChangeEvent } from 'react'
import { useParams } from 'react-router-dom'

import {
  CheckboxField,
  FvButton,
  FvLinkButton,
  useFvNavigate,
} from '@fv/client-components'
import { isEnter, isEnterPrevented } from '@fv/client-core'
import { type Workflow } from '@fv/client-types'
import { hasMixedPallets } from '@fv/models/core'

import InfoBox from '../../components/InfoBox'
import { type FormSectionProps } from '../../components/inputs/types'
import { Loading } from '../../components/Loading'
import { routes } from '../../constants'
import { useQuoteSettings } from '../../hooks/settings'
import { useCancelLoad } from '../../hooks/shipments'
import { crStorageKey } from '../book/CustomerRoutedBookingPage'
import { FedExHomeDeliveryQuoting } from '../book/FedExHomeDelivery'
import { LoadItemsFormProvider } from '../commodities/load-items/LoadItemsFormProvider'
import { useCreateQuoteRequest } from '../quote-request/useCreateQuoteRequest'
import { useUpdateQuoteRequest } from '../quote-request/useUpdateQuoteRequest'
import { QuoteFormLocations } from './QuoteFormLocations'
import { type QuoteRequestMode } from './quoteFuncs'
import {
  useQuotePageCtx,
  useQuotePageStore,
  useQuotePageWorkflow,
} from './QuotePageProvider'
import { type QuoteFormSettings } from './types'

type SettingsPanelProps = FormSectionProps & { workflow: Workflow }

const SettingsPanel = ({ disabled, workflow }: SettingsPanelProps) => {
  const actions = useQuotePageCtx(s => s.actions)
  const settings = useQuotePageCtx(s => s.settings)

  const getCheckboxProps = (name: keyof QuoteFormSettings) => ({
    checked: settings[name],
    className: 'checkbox checkbox-long',
    disabled: disabled,
    name,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      actions.handleSettingsChange(
        e.target.name as keyof QuoteFormSettings,
        e.target.checked,
      )
    },
  })

  return (
    <div className="bg-fv-blue-50 border-fv-blue-300 -mx-6 -mt-10 mb-8 flex items-center border-b border-r-0 border-dashed px-6 py-4">
      <ul className="mb-0 flex items-center">
        <li className="whitespace-nowrap border-0 py-0">
          <FvLinkButton
            disabled={disabled}
            fw
            icon="history"
            to={routes.recentQuotes(workflow)}
          >
            <span>Recent quotes</span>
          </FvLinkButton>
        </li>
        <li className="ml-4 mr-4 whitespace-nowrap border-0 border-l border-r py-0 pl-4 pr-4">
          <FvLinkButton
            disabled={disabled}
            fw
            icon="star-sharp"
            to={routes.savedQuotes(workflow)}
            transform="up-1"
          >
            <span>Saved quotes</span>
          </FvLinkButton>
        </li>
      </ul>
      <ul className="flex items-center space-x-5">
        <li>Autofill from last shipment &gt;</li>

        <li>
          <CheckboxField
            {...getCheckboxProps('autoFillOrigin')}
            label="Origin"
          />
        </li>

        <li>
          <CheckboxField
            {...getCheckboxProps('autoFillDestination')}
            label="Destination"
          />
        </li>

        <li>
          <CheckboxField
            {...getCheckboxProps('autoFillProduct')}
            label="Product"
          />
        </li>
      </ul>
    </div>
  )
}

export type QuotePageFormParams = {
  loadId?: string
  mode?: QuoteRequestMode
}

export const QuotePageForm = () => {
  const { loadId, mode } = useParams<QuotePageFormParams>()
  const store = useQuotePageStore()
  const actions = useQuotePageCtx(s => s.actions)
  const items = useQuotePageCtx(s => s.itemState.items)
  const workflow = useQuotePageWorkflow()
  const isEditing = loadId && mode === 'edit'
  const isFreightCollect = useQuotePageCtx(s => s.load.isFreightCollect)
  const navigate = useFvNavigate()
  const requestQuotes = useCreateQuoteRequest()
  const cancelLoad = useCancelLoad(loadId, false)
  const quoteRequestId = useQuotePageCtx(s => s.load.quoteRequestId)
  const updateQuoteRequest = useUpdateQuoteRequest(quoteRequestId)
  const quoteSettings = useQuoteSettings(workflow)

  const isLoading =
    quoteSettings.isLoading ||
    requestQuotes.isLoading ||
    requestQuotes.isSuccess ||
    updateQuoteRequest.isLoading ||
    updateQuoteRequest.isSuccess ||
    cancelLoad.isLoading ||
    cancelLoad.isSuccess

  const parentClasses =
    'ltl-quote-route-ctn row-start-2 b1350:row-start-3 b1200:pb-28 b1350:col-span-full'

  async function submitForm() {
    if (isLoading) return

    const quoteRequest = actions.buildRequest()
    if (!quoteRequest) return

    if (isFreightCollect) {
      if (isEditing) {
        // The only way editing & isFreightCollect is true is if they created the load as non-freight-collect originally
        // so we cancel the original load
        try {
          await cancelLoad.mutateAsync(false)
        } catch {
          // if we fail to cancel the original load for any reason - the worst that could happen is they have
          // a duplicate entry in their in process page.  This doesn't seem worth preventing them from creating
          // the CR shipment
        }
      }
      const crData = JSON.stringify(quoteRequest)
      localStorage.setItem(crStorageKey, crData)
      return navigate(routes.customerRouted())
    }

    if (isEditing) {
      updateQuoteRequest.mutate(quoteRequest)
    } else {
      requestQuotes.mutate(quoteRequest, {
        onSuccess: loads => {
          navigate(routes.rates(loads[0]))
        },
      })
    }
  }

  if (mode === 'return') {
    const quoteRequest = actions.buildRequest()
    if (quoteRequest && !isLoading) {
      requestQuotes.mutate(quoteRequest, {
        onSuccess: loads => {
          navigate(routes.rates(loads[0]))
        },
      })
    }
    return (
      <div className={parentClasses}>
        <div className="parcel-from-to b900:flex-col flex gap-x-6 b1500:gap-x-4 items-stretch b1350:gap-x-6">
          <Loading>Loading Rates...</Loading>
        </div>
      </div>
    )
  }

  if (
    workflow === 'parcel' &&
    !quoteSettings.data?.apiEnabledCarriers?.length
  ) {
    return (
      <div className={parentClasses}>
        <div className="parcel-from-to b900:flex-col flex gap-x-6 b1500:gap-x-4 items-stretch b1350:gap-x-6">
          <InfoBox>
            For a more detailed look at our{' '}
            <a
              className="text-[#45abd3]"
              href="https://blog.freightview.com/parcel-in-freightview"
              target="blank"
            >
              parcel product
            </a>{' '}
            and how to add it to your account please visit our{' '}
            <a
              className="text-[#45abd3]"
              href="https://blog.freightview.com/parcel-in-freightview"
              target="blank"
            >
              blog article
            </a>
            . If you have any more questions please contact our Support Team at{' '}
            <a href="mailto:support@freightview.com" className="text-[#45abd3]">
              support@freightview.com
            </a>
          </InfoBox>
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading ? (
        <div className={parentClasses}>
          <div className="parcel-from-to b900:flex-col flex gap-x-6 b1500:gap-x-4 items-stretch b1350:gap-x-6">
            <Loading>Loading Shipment...</Loading>
          </div>
        </div>
      ) : (
        <div
          className={parentClasses}
          onKeyDown={e => {
            isEnter(e) && !isEnterPrevented(e) && submitForm()
          }}
        >
          <SettingsPanel disabled={isLoading} workflow={workflow} />
          <div className="parcel-from-to b900:flex-col flex gap-x-6 b1500:gap-x-4 items-stretch b1350:gap-x-6">
            <QuoteFormLocations disabled={isLoading} />

            <div className="ltl-quoting-destination basis-1/2 bg-gray-50 pt-10 px-4 pb-6 relative border border-fv-gray">
              <LoadItemsFormProvider type="paged" store={store} />
            </div>
          </div>

          {workflow === 'parcel' && <FedExHomeDeliveryQuoting />}
        </div>
      )}

      <div className="footer-actions row-start-[-1] col-start-1 col-span-3 bg-fv-blue-150 p-3 flex justify-center items-center border-fv-blue-400 border-t b1200:fixed b1200:bottom-0 w-screen">
        {loadId && mode && (
          <>
            <FvButton icon="times" onClick={() => navigate(-1)} theme="default">
              Cancel
            </FvButton>
            <span className="ml-2 mr-2">-or-</span>
          </>
        )}

        <FvButton
          fwd
          icon={isLoading ? 'spinner' : 'dollar-sign'}
          onClick={submitForm}
          theme="primary"
        >
          {isFreightCollect
            ? 'Book shipment'
            : isEditing
              ? 'Save changes'
              : hasMixedPallets(items)
                ? 'Add rates'
                : 'Show my rates'}
        </FvButton>
      </div>
    </>
  )
}
