import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type CarrierStatus } from '@fv/client-types'

import { apiUri } from '../constants'
import { useStaticDataQuery } from './useStaticDataQuery'

type Methods = 'manual' | 'email' | 'internal' | 'auto' | 'disabled'
export type CarrierPublicSettings = {
  cancelPhone?: string
  cancelEmail?: string
  dispatchPhone?: string
  hideFreightviewBOLFromSpotQuote?: boolean
  allowChangeBOL?: boolean
  allowBolOnly?: boolean
  cancelMethod?: Methods
  requiresOriginEmail?: boolean
  requireCustomsBrokerValue?: boolean
  requireCustomerRoutedAccountNumber?: boolean
  dispatchMethod?: Methods
  status?: CarrierStatus
}

async function fetchCarrierPublicSettings(
  carrierId?: string,
): Promise<CarrierPublicSettings> {
  if (!carrierId) {
    throw new Error('carrierId required to fetch carrier public settings')
  }

  const endpoint = `${apiUri}/carrier-public-settings/${carrierId}`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json
  throw response.errorMessage
}

export function useCarrierPublicSettings(carrierId?: string) {
  const carrierSettingsQuery = useStaticDataQuery(
    ['carrier-public-settings', carrierId],
    () => fetchCarrierPublicSettings(carrierId),
    { enabled: Boolean(carrierId) },
  )

  const { isFetching, data } = carrierSettingsQuery
  return { isFetching, carrierPublicSettings: data }
}
