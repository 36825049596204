import clsx from 'clsx'
import { useCallback, useMemo } from 'react'

import { SelectField } from '@fv/client-components'

import { useToggleUserPreference } from '../../features/settings/user-preferences/mutations'
import { useUserSettings } from '../../hooks/settings'

const feature = 'spot-quote-list-show-all'

type QuotedByFilterProps = {
  quotedById: string | null
  className?: string
  onChange: (value: string | null) => void
}

type User = { _id: string; label: string }

const QuotedByFilter = ({
  quotedById,
  onChange,
  className,
}: QuotedByFilterProps) => {
  const { setPreference } = useToggleUserPreference(feature)
  const settingsQuery = useUserSettings()

  const { preferences, users } = settingsQuery.data ?? {}
  const filterByAll = preferences?.[feature]

  const setQuotedBy = useCallback(
    (nextQuotedById: string) => {
      if (nextQuotedById) {
        filterByAll && setPreference(false)
      } else {
        !filterByAll && setPreference(true)
      }

      // let's the mutation update the locally stored data before firing the change event to the parent
      // The parent depends on the user preferences to be modified to correctly populate the query string
      setTimeout(() => onChange(nextQuotedById), 0)
    },
    [filterByAll, setPreference, onChange],
  )

  const userOptions = useMemo(
    () => [
      { text: 'All users', value: '' },
      ...(users ?? []).map((u: User) => ({
        text: u.label,
        value: u._id,
      })),
    ],
    [users],
  )

  return (
    <SelectField
      className={clsx(
        'form-control form-control--select form-control--user-select mr-4',
        className,
      )}
      name="quotedBy"
      onChange={e => setQuotedBy(e.target.value)}
      options={userOptions}
      value={quotedById ?? ''}
    />
  )
}

export default QuotedByFilter
