import { useQuery, useQueryClient } from '@tanstack/react-query'

import { type ClientAddressLookupResult } from '@fv/client-types'
import { type AddressLookupDTO } from '@fv/models'

import { shipperFetch } from '../../utils/shipperFetch'
import { type AddressBookResponse } from './types'

export type AddressType = 'billing' | 'shipping'
export interface AddressSearch
  extends Omit<AddressLookupDTO, 'skip' | 'limit'> {
  includeAddressBook: boolean
}

export const addressSearchKeys = {
  all: ['address-search'] as const,
  search: (args: AddressSearch) => [...addressSearchKeys.all, args] as const,
}

async function searchAddresses(
  dto: AddressSearch,
): Promise<AddressBookResponse> {
  return shipperFetch<AddressBookResponse>(`/addresses`, {
    query: dto,
  })
}

export function useAddressSearch(filter: AddressSearch) {
  return useQuery(
    addressSearchKeys.search(filter),
    async () => (await searchAddresses(filter)).data,
    {
      enabled: filter.search.length > 1,
      select: data => {
        if (filter.includeAddressBook) return data
        return data.filter(a => !a.accountId)
      },
    },
  )
}

export function useCacheFormattedAddress() {
  const queryClient = useQueryClient()

  // Not all fields are searchable in Atlas. This helper caches
  // formatted addresses so they're searchable from AddressInputs.
  function cacheFormattedAddress(
    filter: Partial<AddressSearch>,
    location: ClientAddressLookupResult,
  ) {
    const searchOpts: AddressSearch = {
      type: 'shipping',
      includeAddressBook: true,
      includeAtlas: true,
      ...filter,
    }

    // AddressBook & Atlas
    queryClient.setQueryData(addressSearchKeys.search(searchOpts), [location])

    // AddressBook only
    queryClient.setQueryData(
      addressSearchKeys.search({ ...searchOpts, includeAtlas: false }),
      [location],
    )

    // Atlas only
    queryClient.setQueryData(
      addressSearchKeys.search({ ...searchOpts, includeAddressBook: false }),
      [location],
    )
  }

  return cacheFormattedAddress
}
