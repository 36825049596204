import { useQueries } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type UIQuote } from '@fv/client-types'
import { type TerminalType } from '@fv/models'

import { apiUri } from '../../constants'
import { staticQueryOptions } from '../../hooks/useStaticDataQuery'
import { removeUndefinedValues } from '../../utils/removeUndefinedValues'

export type Terminal = {
  _id: string
  address: string
  address2?: string
  carrierId: string
  city: string
  contactEmail?: string
  contactName?: string
  contactPhone?: string
  country?: 'US' | 'CA' | 'MX'
  lat?: number
  lng?: number
  postalCode: string
  state: string
  terminalCode?: string
  timezone?: string
}

export type TerminalAndType = Terminal & { type?: TerminalType }

async function fetchTerminal(terminalId: string): Promise<Terminal> {
  const endpoint = `${apiUri}/terminals/${terminalId}`
  const response = await fetchJson(endpoint, buildFetchOptionsWithAuth())
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useTerminals(terminals?: UIQuote['terminals']) {
  const queries = useQueries({
    queries: (terminals?.slice() ?? [])
      .sort((a, b) => {
        if (a.terminalType === 'origin terminal') return -1
        if (b.terminalType === 'origin terminal') return 1
        return 0
      })
      .map(t => ({
        ...staticQueryOptions,
        queryFn: () => fetchTerminal(t.terminalId),
        queryKey: ['terminal', t.terminalId],
      })),
  })

  return {
    data: removeUndefinedValues<TerminalAndType>(
      terminals?.map(t => {
        const queryData = queries.find(q => q.data?._id === t.terminalId)?.data
        return queryData
          ? {
              ...queryData,
              type: t.terminalType,
            }
          : undefined
      }) ?? [],
    ),
    loading: queries.some(q => q.isLoading),
  }
}
