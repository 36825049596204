import ObjectID from 'bson-objectid'

import {
  type BillToDTO,
  type EmergencyContact,
  type EquipmentDTO,
  type LoadEquipment,
  type LoadHandlingUnit,
  type QuoteRequestDTO,
} from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { formStopsToLocationsDTO } from '../../utils/locationFuncs'
import { removeNullProperties } from '../../utils/removeNullProperties'
import {
  buildFormCommodity,
  buildHandlingUnitDTO,
} from '../commodities/commodityUtils'
import { type FormCommodity } from '../commodities/FormCommodity'
import { type CustomsBroker } from '../customsBroker/brokerTypes'
import {
  type QuoteFormLoad,
  type QuoteFormState,
  type QuoteRequestLoads,
} from './types'

export function buildLoad(type: EquipmentType): QuoteFormLoad {
  return {
    accessorials: [],
    alternateTypes: [],
    declaredValue: '',
    declaredValueCurrency: 'usd',
    description: '',
    isHazardous: false,
    items: [],
    loadId: new ObjectID().toString(),
    mode: 'truckload',
    type,
    weight: '',
    weightUOM: 'lbs',

    // Front-end only
    isActiveInUI: false,
    formRef: null, // Set from form component
    revalidationDelay: null,
  }
}

export function countLoads(loads: QuoteRequestLoads) {
  let loadCount = 0

  for (const type of loads.keys()) {
    loadCount += loads.get(type)?.length ?? 0
  }

  return loadCount
}

export function getHasItems(loads: QuoteRequestLoads) {
  return Array.from(loads.values()).some(loadsOfType =>
    loadsOfType.some(x => !!x.items.length),
  )
}

export function getItemsBySequence(
  loads: QuoteRequestLoads,
  sequence: number,
): FormCommodity[] {
  const itemsBySequence: FormCommodity[] = []

  for (const type of loads.keys()) {
    const loadsOfType = loads.get(type) ?? []
    const itemsOfType = loadsOfType
      .flatMap(x => x.items)
      .filter(i => i.pickSequence === sequence || i.dropSequence === sequence)

    itemsBySequence.push(...itemsOfType)
  }

  // Sort deliveries to front
  return itemsBySequence.sort(a => {
    if (a.dropSequence === sequence) return -1
    if (a.pickSequence === sequence) return 1
    return 0
  })
}

function mapBillTo({ contactEmail, ...dto }: BillToDTO): BillToDTO {
  return {
    ...dto,
    ...(contactEmail && { contactEmail }),
  }
}

export function formLoadToEquipmentDTO(load: QuoteFormLoad): EquipmentDTO {
  return {
    accessorials: load.accessorials,
    alternateTypes: load.alternateTypes,
    declaredValueCurrency: load.declaredValueCurrency,
    description: load.description.trim(),
    loadId: load.loadId,
    isHazardous: load.isHazardous,
    items: load.items.map(buildHandlingUnitDTO).map(removeNullProperties),
    mode: load.mode,
    type: load.type,
    weight: Number(load.weight),
    weightUOM: load.weightUOM,
    ...(load.billTo &&
      Object.values(load.billTo).some(b => !!b) && {
        billTo: mapBillTo(load.billTo),
      }),
    ...(load.customsBroker && { customsBroker: load.customsBroker }),
    ...(load.declaredValue && { declaredValue: Number(load.declaredValue) }),
    ...(load.emergencyContact && { emergencyContact: load.emergencyContact }),
  }
}

export function equipmentToFormLoad({
  billTo,
  customsBroker,
  emergencyContact,
  equipment,
  items,
  loadId,
}: {
  billTo?: BillToDTO
  customsBroker?: CustomsBroker[]
  emergencyContact?: EmergencyContact
  equipment: LoadEquipment
  items?: LoadHandlingUnit[]
  loadId: string
}): QuoteFormLoad {
  return {
    accessorials: (equipment.accessorials ?? []).map(a => ({
      key: a.key,
      ...(a.uom && { uom: a.uom }),
      ...(typeof a.count === 'number' && { count: a.count }),
      ...(typeof a.max === 'number' && { max: a.max }),
      ...(typeof a.min === 'number' && { min: a.min }),
      ...(typeof a.size === 'number' && { size: a.size }),
    })),
    alternateTypes: equipment.alternateTypes ?? [],
    declaredValue: String(equipment.declaredValue || ''),
    declaredValueCurrency: equipment.declaredValueCurrency,
    description: equipment.description,
    loadId,
    formRef: null,
    isActiveInUI: false,
    isHazardous: equipment.isHazardous,
    items: (items ?? []).map(i =>
      buildFormCommodity({
        dropSequence: i.dropLocationSequence,
        item: i,
        loadId,
        pickSequence: i.pickupLocationSequence,
      }),
    ),
    mode: equipment.mode,
    revalidationDelay: null,
    type: equipment.type,
    weight: String(equipment.weight),
    weightUOM: equipment.weightUOM,
    ...(billTo && { billTo }),
    ...(customsBroker && { customsBroker }),
    ...(emergencyContact && { emergencyContact }),
  }
}

export function buildQuoteRequestDTO({
  isLiveLoad,
  loads,
  orderNumber,
  stops,
}: QuoteFormState): QuoteRequestDTO {
  return {
    equipment: Array.from(loads.values()).flatMap(loadsOfType =>
      loadsOfType.map(formLoadToEquipmentDTO),
    ),
    isLiveLoad,
    locations: formStopsToLocationsDTO(stops),
    orderNumber: orderNumber.trim(),
    workflow: 'truckload',
  }
}
