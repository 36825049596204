import capitalize from 'lodash/capitalize'
import defaults from 'lodash/defaults'

import { type SelectFieldOption } from '@fv/client-types'
import {
  type ScheduledReportDayOfMonthType,
  type ScheduledReportDTO,
  scheduledReportFrequencies,
  type ScheduledReportFrequencyType,
} from '@fv/models'

import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import TimeSelectField from '../../components/inputs/TimeSelectField'

const frequencyOptions = [
  ...scheduledReportFrequencies.map<
    SelectFieldOption<ScheduledReportFrequencyType>
  >(f => ({
    value: f,
    text: capitalize(f),
  })),
]

const dayOfWeekOptions = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

type Props = {
  value: Partial<ScheduledReportDTO>
  onChange: (value: Partial<ScheduledReportDTO>) => void
}
export const ReportFrequencyForm = ({ value, onChange }: Props) => {
  const val = defaults(value, { frequency: 'daily', time: '06:00' })
  const dayLabel =
    value.frequency === 'monthly' ? 'Day of month' : 'Day of week'
  const isDaily = val.frequency === 'daily'
  const isMonthly = val.frequency === 'monthly'
  const isWeekly = val.frequency === 'weekly'
  return (
    <div className="flex items-center gap-x-2">
      <InputGroup
        inputType="select"
        label="Frequency"
        inputProps={{
          name: 'frequency',
          value: val.frequency ?? 'daily',
          label: 'Frequency',
          options: frequencyOptions,
          onChange: e => {
            const frequency = e.target.value as ScheduledReportFrequencyType
            onChange({
              ...val,
              frequency,
              dayOfMonth: frequency !== 'monthly' ? undefined : 'first-day',
              dayOfWeek: frequency !== 'weekly' ? undefined : 1,
            })
          },
        }}
      />
      <InputGroup
        inputType="select"
        label={dayLabel}
        inputProps={{
          disabled: isDaily,
          readOnly: isDaily,
          name: 'day',
          value: isMonthly ? val.dayOfMonth : val.dayOfWeek,
          options: isDaily
            ? ['----------------']
            : isMonthly
              ? ['First day']
              : dayOfWeekOptions.map((o, ix) => ({
                  text: o,
                  value: ix.toString(),
                })),
          onChange: e => {
            onChange({
              ...val,
              dayOfMonth: isMonthly
                ? (e.target.value as ScheduledReportDayOfMonthType)
                : undefined,
              dayOfWeek: isWeekly ? parseInt(e.target.value) : undefined,
            })
          },
        }}
      />
      <InputGroupWrapper label="Time" name="time">
        <TimeSelectField
          name="time"
          value={val.time}
          setValue={time => onChange({ ...val, time })}
        />
      </InputGroupWrapper>
    </div>
  )
}
