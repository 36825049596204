import sumBy from 'lodash/sumBy'

export type Page<T> = {
  continuationToken?: string
  data: T[]
  hasMore?: boolean
}

export type InfiniteQueryData<T> =
  | {
      pageParams: Array<number | null>
      pages: Page<T>[]
    }
  | undefined

export function flattenPages<T>(pages?: Page<T>[]): T[] {
  return (pages ?? []).flatMap(p => p.data)
}

// Next value used for `skip` param
export function getNextPageParam<T>(
  lastPage?: Page<T>,
  allPages?: Page<T>[],
): number | undefined {
  if (!lastPage?.hasMore) return
  return sumBy(allPages, p => p.data.length)
}

export function setDataByPage<T>(
  getPageData: (pageData: Page<T>['data']) => Page<T>['data'],
): (prevData: InfiniteQueryData<T>) => InfiniteQueryData<T> {
  return (prevData: InfiniteQueryData<T>) =>
    prevData && {
      ...prevData,
      pages: prevData.pages?.map(p => ({
        ...p,
        data: getPageData(p.data),
      })),
    }
}
