import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import uniq from 'lodash/uniq'

import { type ClientAddressLookupResult } from '@fv/client-types'

import { staticQueryOptions } from '../../hooks/useStaticDataQuery'
import { removeUndefinedValues } from '../../utils/removeUndefinedValues'
import { shipperFetch } from '../../utils/shipperFetch'
import { type AccountLocationDto } from './types'

export const addressKeys = {
  all: ['address'] as const,
  accountLocations: () => [...addressKeys.all, 'account-locations'],
  id: (id = '') => [...addressKeys.all, id] as const,
}

async function fetchAddress(
  addressId?: string,
): Promise<ClientAddressLookupResult> {
  if (!addressId) throw new Error('Missing addressId')
  return shipperFetch<ClientAddressLookupResult>(`/addresses/${addressId}`)
}

// Stable reference while loading or no data
const defaultData: ClientAddressLookupResult[] = []

// Used to fetch address book entries for share settings
export function useAddresses(
  load?: {
    locations?: Array<{ addressBookId?: string }>
  } | null,
) {
  const queryClient = useQueryClient()
  const ids = removeUndefinedValues(
    (load?.locations ?? []).map(x => x.addressBookId).filter(id => !!id),
  )

  const queries = useQueries({
    queries: uniq(ids).map(id => ({
      ...staticQueryOptions,
      queryFn: () => fetchAddress(id),
      queryKey: addressKeys.id(id),
      retry: false,
      onError: () => {
        queryClient.setQueryData(addressKeys.id(id), null)
      },
    })),
  })

  const data = removeUndefinedValues(queries.map(q => q.data).filter(d => !!d))
  const loading = queries.some(q => q.isLoading)

  return {
    data: loading || !data.length ? defaultData : data,
    loading,
  }
}

export const useAccountLocations = (enabled = true) => {
  return useQuery(
    addressKeys.accountLocations(),
    () => shipperFetch<AccountLocationDto[]>('/addresses/account-locations'),
    {
      enabled,
    },
  )
}
