import { useMemo } from 'react'

import { useUserSettings } from '../../hooks/settings'
import { usePermissions } from '../auth'

export type ShipmentTagOption = {
  label: string
  value: string
}
type ShipmentTagSettings = {
  canCreate: boolean
  canModify: boolean
  isLoading: boolean
  options: ShipmentTagOption[]
}
export const useTagSettings = () => {
  const { canBook, canManage } = usePermissions()
  const settingsQuery = useUserSettings()
  const accountTags = settingsQuery.data?.tags
  const tagSettings = settingsQuery.data?.settings?.tags

  return useMemo<ShipmentTagSettings>(
    () => ({
      canCreate:
        canManage || !!(tagSettings && !tagSettings.preventUsersCreateTags),
      canModify: canBook,
      isLoading: settingsQuery.isLoading,
      options: accountTags?.map(t => ({ label: t.label, value: t._id })) ?? [],
    }),
    [accountTags, canBook, canManage, settingsQuery.isLoading, tagSettings],
  )
}
