import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  type InfiniteQueryData,
  setDataByPage,
  showApiError,
} from '@fv/client-core'
import {
  type LegacyHandlingUnitDTO,
  type ProductCatalogEntry,
} from '@fv/client-types'

import { apiUri } from '../../constants'
import { buildLegacyHandlingUnitDTO } from './commodityUtils'
import { handlingUnitKeys } from './useHandlingUnits'

async function updateHandlingUnit(
  product: ProductCatalogEntry,
): Promise<LegacyHandlingUnitDTO> {
  const handlingUnit = buildLegacyHandlingUnitDTO(product)
  const endpoint = `${apiUri}/handling-units/${product._id}`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(handlingUnit),
    method: 'PUT',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUpdateHandlingUnit() {
  const queryClient = useQueryClient()

  return useMutation(updateHandlingUnit, {
    onSettled: (handlingUnit, error) => {
      if (error) return showApiError('Unable to update product', error)

      if (handlingUnit) {
        queryClient.setQueriesData<InfiniteQueryData<LegacyHandlingUnitDTO>>(
          handlingUnitKeys.filter({ mode: handlingUnit.mode }),
          setDataByPage(handlingUnits =>
            handlingUnits.map(u =>
              u._id === handlingUnit._id ? handlingUnit : u,
            ),
          ),
        )

        queryClient.setQueryData(
          handlingUnitKeys.id(handlingUnit._id),
          handlingUnit,
        )
      }

      toast.success('Product updated successfully.')
    },
  })
}
