import { type PropsWithChildren, useMemo } from 'react'

import { type IconName } from '@fv/client-components'

import { type FilterConfig } from '../../components/CustomFilterForm/types'
import { type SavedFilter } from '../../types/SavedFilter'
import { useAnalyticsParams } from '../analytics/hooks'
import { ShipmentFilterListProvider } from './ShipmentFilterListProvider'
import {
  bookedDateFilter,
  directionFilter,
  interlineFilter,
  pricingMethodFilter,
  pricingTypeFilter,
  shippingModeFilter,
  useAccountFilters,
} from './useFilterConfigs'
import { useHiddenViews, useSavedViews } from './useSavedViews'

export const AnalyticsFiltersProvider = ({ children }: PropsWithChildren) => {
  const { view } = useAnalyticsParams()
  const filterConfig = useAnalyticsFilterConfig()
  const userFilterConfig = useUserAnalyticsFilterConfig()
  const filters = useSavedAnalyticsFilters()
  return (
    <ShipmentFilterListProvider
      state={{
        listType: 'analytics',
        filters,
        canSearch: false,
        filterConfig: view === 'users' ? userFilterConfig : filterConfig,
      }}
    >
      {children}
    </ShipmentFilterListProvider>
  )
}

function useAnalyticsFilterConfig(): FilterConfig[] {
  const {
    carrierFilter,
    quotedByFilter,
    bookedByFilter,
    tagFilter,
    locationFilter,
  } = useAccountFilters()

  return [
    directionFilter,
    shippingModeFilter,
    carrierFilter,
    quotedByFilter,
    bookedByFilter,
    bookedDateFilter,
    interlineFilter,
    pricingMethodFilter,
    pricingTypeFilter,
    tagFilter,
    locationFilter,
  ]
}

function useUserAnalyticsFilterConfig(): FilterConfig[] {
  const { bookedByFilter, tagFilter, locationFilter } = useAccountFilters()

  return [
    bookedByFilter,
    bookedDateFilter,
    interlineFilter,
    pricingMethodFilter,
    pricingTypeFilter,
    tagFilter,
    locationFilter,
  ]
}
function useSavedAnalyticsFilters(): SavedFilter[] {
  const hiddenViews = useHiddenViews()
  const savedViews = useSavedViews('analytics')

  return useMemo(() => {
    const systemFilters = [
      {
        _id: 'all',
        icon: 'list' as IconName,
        isDefault: true,
        isLocked: true,
        isSystemFilter: true,
        name: 'All',
        queryString: '',
      },
    ]

    return [
      ...systemFilters.filter(
        f => !hiddenViews.find((id: string) => id === f._id),
      ),
      ...savedViews,
    ]
  }, [hiddenViews, savedViews])
}
