import {
  type MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  showApiError,
} from '@fv/client-core'
import { type Currency, type UIQuote } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { apiUri } from '../../constants'
import { quoteKeys } from '../../hooks/shipments/useQuotes'
import {
  type ManualCarrierRate,
  type ManualRateType,
  type ManualSpotQuote,
} from './useManualRateForm'

export const newQuoteId = 'newQuote'

type BaseManualQuoteDTO = {
  amount: number
  carrierId: string
  carrierName: string
  currency: Currency
  loadId: string
  quoteNum: string
  type: ManualRateType
}

type ManualCarrierRateDTO = BaseManualQuoteDTO & {
  days?: number
  serviceType?: string
  serviceId?: string
  type: 'manual-rate'
}

type ManualSpotQuoteDTO = BaseManualQuoteDTO & {
  autoCreateCarrierName: string
  carrierEmail: string
  equipmentType: EquipmentType
  type: 'spot-quote'
}

async function postQuoteToRequest(
  dto: ManualCarrierRateDTO | ManualSpotQuoteDTO,
): Promise<UIQuote> {
  const endpoint = `${apiUri}/shipments/${dto.loadId}/quotes`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(dto),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

type CreateQuoteOptions = MutateOptions<
  UIQuote,
  unknown,
  ManualCarrierRateDTO | ManualSpotQuoteDTO
>

type CreateQuoteValues = {
  loadId: string
  quote: ManualCarrierRate | ManualSpotQuote
}

export function useCreateQuote() {
  const queryClient = useQueryClient()
  const createQuoteMutation = useMutation(postQuoteToRequest, {
    onSettled(quote, error, dto) {
      queryClient.setQueryData<UIQuote[]>(
        quoteKeys.load(dto.loadId, quote?.loadQuoteVersion),
        prev => {
          const quotes = prev?.filter(
            q => q._id !== quote?._id && q._id !== newQuoteId,
          )

          if (!quote) return quotes
          return quotes?.concat(quote) || [quote]
        },
      )

      if (error) showApiError('Unable to add quote', error)
    },
  })

  function createQuote(
    values: CreateQuoteValues,
    options?: CreateQuoteOptions,
  ) {
    if (createQuoteMutation.isLoading) return

    const { loadId, quote } = values
    const baseDTO: BaseManualQuoteDTO = {
      amount: Number(quote.price),
      carrierId: quote.carrierId,
      carrierName: quote.carrierName.trim(),
      currency: 'usd',
      loadId,
      quoteNum: quote.quoteNumber.trim(),
      type: quote.rateType,
    }

    if (baseDTO.type === 'manual-rate') {
      const { days, serviceType, serviceId } = quote as ManualCarrierRate
      const dto: ManualCarrierRateDTO = {
        ...baseDTO,
        ...(days && { days: Number(days) }),
        ...(serviceType.trim() && { serviceType: serviceType.trim() }),
        ...(serviceId.trim() && { serviceId: serviceId.trim() }),
        type: baseDTO.type,
      }

      return createQuoteMutation.mutate(dto, options)
    }

    if (baseDTO.type === 'spot-quote') {
      const { carrierEmail, equipmentType } = quote as ManualSpotQuote
      const dto: ManualSpotQuoteDTO = {
        ...baseDTO,
        autoCreateCarrierName: quote.carrierName.trim(),
        carrierEmail: carrierEmail.trim(),
        equipmentType,
        type: baseDTO.type,
      }

      return createQuoteMutation.mutate(dto, options)
    }
  }

  return createQuote
}
