import toast from 'react-hot-toast'

import { type FullShipment } from '@fv/client-types'

import { useSpotQuoteEmailMessage } from '../../features/spot-quote/SpotQuoteEmailPreview'
import { useSendSpotQuoteRequests } from '../../hooks/spotQuotes'
import { type SpotQuoteFormData } from './types'

type SubmitFormArgs = {
  load: FullShipment
}

export function useSubmitForm({ load }: SubmitFormArgs) {
  const { equipment, loadId } = load
  const loads = [load]
  const formData: SpotQuoteFormData[] = loads.map(l => ({
    loadId: l.loadId,
    bidExpiration: l.bidExpiration,
    targetPrice: l.targetPrice,
  }))
  const sendRequests = useSendSpotQuoteRequests(equipment.mode)
  const systemMessage = useSpotQuoteEmailMessage(
    loads,
    load.equipment.alternateTypes ?? [],
    formData,
  )

  return {
    isSending: sendRequests.isLoading,
    isSent: sendRequests.isSuccess,
    onSubmit: (emails: string[]) => {
      if (emails.length < 1) {
        toast.error('Must select at least one contact to send out for quotes.')
        return
      }

      return sendRequests.mutateAsync({
        emails,
        systemMessage,
        targetPrices: formData,
        bidExpirations: formData,
        loadIds: [loadId],
      })
    },
  }
}
