import { type ParsedQuery } from 'query-string'
import { useEffect, useRef, useState } from 'react'

import { FvButton, LoadMore } from '@fv/client-components'

import InfoBox from '../../components/InfoBox'
import TopNav from '../../components/TopNav'
import { supportMessage } from '../../constants'
import { AuditFiltersProvider } from '../shipment-filters/AuditFiltersProvider'
import { CompactFilterBar } from '../shipment-filters/CompactFilterBar'
import { ShipmentFilterPanel } from '../shipment-filters/FilterPanel'
import { useHandleFilterChange } from '../shipment-filters/hooks'
import AuditListCard from './AuditListCard'
import AuditListHeader from './AuditListHeader'
import {
  defaultAuditStatus,
  useAuditList,
  useAuditListStatus,
  useRemitPayments,
} from './queries'
import { colorSuccess } from './types'

export const AuditPage = () => {
  const auditsQuery = useAuditList()
  const auditStatus = useAuditListStatus() ?? defaultAuditStatus
  const { remitPayments, isArchiving, isExporting } = useRemitPayments()
  const status = useAuditListStatus()

  const [activeAudit, setActiveAudit] = useState<string | null>(null)
  const [auditsToArchive, setAuditsToArchive] = useState<string[]>([])
  const archiveCount = auditsToArchive.length
  const isAccepted = auditStatus === 'accepted'
  const isBusy = isArchiving || isExporting
  const listRef = useRef<HTMLDivElement | null>(null)
  const { handle: handleFilterChange } = useHandleFilterChange(listRef)
  useEffect(() => {
    setActiveAudit(null)
  }, [auditStatus])

  useEffect(() => {
    if (isAccepted) {
      setAuditsToArchive(auditsQuery.data.map(a => a.loadId))
    }
  }, [auditsQuery.data, isAccepted])

  const handleChange = (data: ParsedQuery) => {
    handleFilterChange({
      ...data,
      status: [status],
    })
  }

  return (
    <div className="grid bg-fv-gray-100 grid-auditing grid-cols-[19rem_1fr] grid-rows-[auto_1fr_auto] h-screen b1300:grid-cols-1">
      <AuditFiltersProvider>
        <TopNav />
        <ShipmentFilterPanel onChange={handleChange} />

        <div className="audit-cards-ctn" ref={listRef}>
          <AuditListHeader />
          <div className="b1300:block border-t-fv-gray -mt-2 mb-6 hidden basis-full border-t border-dashed pt-4">
            <CompactFilterBar onChange={handleChange} />
          </div>
          {auditsQuery.isError && (
            <InfoBox icon="triangle">
              Unable to load audit list, {supportMessage}
            </InfoBox>
          )}

          {auditsQuery.isLoading ? (
            <InfoBox icon="spinner">Loading...</InfoBox>
          ) : (
            <>
              {auditsQuery.data.map(a => (
                <AuditListCard
                  auditsToArchive={auditsToArchive}
                  data={a}
                  isActive={a.loadId === activeAudit}
                  isRemitting={isBusy && auditsToArchive.includes(a.loadId)}
                  key={a.loadId}
                  setActiveAudit={setActiveAudit}
                  setAuditsToArchive={setAuditsToArchive}
                  status={auditStatus}
                />
              ))}

              {!auditsQuery.data.length && (
                <InfoBox>No {auditStatus} invoices found.</InfoBox>
              )}
            </>
          )}

          <LoadMore
            className="mt-4"
            fetchNextPage={auditsQuery.fetchNextPage}
            hasNextPage={auditsQuery.hasNextPage}
            isLoading={auditsQuery.isFetchingNextPage}
          />
        </div>

        {isAccepted && archiveCount > 0 && (
          <div className="footer-actions row-start-[-1] col-span-full">
            <FvButton
              onClick={() => {
                !isBusy &&
                  remitPayments({
                    loadIds: auditsToArchive,
                  })
              }}
              icon="archive"
              loading={isArchiving}
              theme="default"
            >
              <span>Archive only - {archiveCount} checked items</span>
            </FvButton>
            <span className="ml-2 mr-2">-or-</span>
            <FvButton
              theme="primary"
              onClick={() => {
                !isBusy &&
                  remitPayments({
                    doExport: true,
                    loadIds: auditsToArchive,
                  })
              }}
              icon="archive"
              loading={isExporting}
              iconClass={colorSuccess}
            >
              <span>Archive & export - {archiveCount} checked items</span>
            </FvButton>
          </div>
        )}
      </AuditFiltersProvider>
    </div>
  )
}
