import { useEffect, useState } from 'react'

import { getEnvValue } from '@fv/client-core'

import { Icon } from '../Icon'
import {
  OlarkInfo,
  OlarkOperatorCommandEvent,
  useOlarkHooks,
} from './useOlarkHooks'

const olarkId = getEnvValue('VITE_OLARK_ID')

type Props = OlarkInfo
window.fvOlarkHooks = {
  onBeginConversation() {
    // placeholder
  },
  onOperatorCommand() {
    // placeholder
  },
}

// TODO: for carrier, `View as customer: ${adminUri}/search/${userId}`
export const OlarkChat = (props: Props) => {
  const [isLoaded, setLoaded] = useState(Boolean(window.olark))
  const [isChatting, setChatting] = useState(false)
  useOlarkHooks(props)

  // Adding to window to call from Olark's `api.chat.onReady` callback.
  // This will re-run our hooks below to set our user details in Olark.
  if (!isLoaded) {
    window.SET_OLARK_LOADED = () => {
      setLoaded(true)

      window.olark?.('api.box.onShrink', () => {
        window.olark?.('api.box.hide', true)
        setChatting(false)
      })

      window.olark?.('api.chat.onBeginConversation', () => {
        window.fvOlarkHooks.onBeginConversation()
      })
      window.olark?.(
        'api.chat.onCommandFromOperator',
        (e: OlarkOperatorCommandEvent) => {
          window.fvOlarkHooks.onOperatorCommand(e)
        },
      )
    }
  }

  useEffect(() => {
    // Do not add script more than once
    if (!olarkId || window.olark) return

    const script = document.createElement('script')

    script.setAttribute('id', 'olark-react-snippet')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', 'true')
    script.innerHTML = `
      ;(function(o,l,a,r,k,y){if(o.olark)return;
      r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0];
      y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);
      y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)};
      y.extend=function(i,j){y("extend",i,j)};
      y.identify=function(i){y("identify",k.i=i)};
      y.configure=function(i,j){y("configure",i,j);k.c[i]=j};
      k=y._={s:[],t:[+new Date],c:{},l:a};
      })(window,document,"static.olark.com/jsclient/loader.js");
      olark.identify('${olarkId}');
      olark.configure('system.is_single_page_application', true);
      olark.configure('box.start_hidden', true)
      olark('api.box.hide', true)
      olark('api.chat.onReady', function(){
        window.SET_OLARK_LOADED()
        delete window.SET_OLARK_LOADED
      });
    `

    document.body.appendChild(script)
  }, [])

  const openChatBox = () => {
    if (!olarkId) {
      console.log('olark id not found')
    }

    window.olark?.('api.box.expand')
    setChatting(true)
  }

  if (isChatting) {
    return null
  }

  return (
    <div
      id="olark_tab"
      className="fixed bottom-40 right-0 z-[5000] origin-bottom-right -rotate-90"
    >
      <button
        onClick={openChatBox}
        className="bg-[#76C7E7] px-4 py-2 text-[#333333]"
      >
        <Icon
          icon="message-dots"
          className="pointer-events-none pr-2 text-[#333333]"
        />
        Chat
      </button>
    </div>
  )
}
