import { type ShippingLabelSize } from '@fv/client-types'

export type LabelConfig = {
  className: string
  count?: number
  label?: string
  size: ShippingLabelSize
  tooltip: string
  type: 'roll' | 'sheet'
}

export const labelOptions: LabelConfig[] = [
  {
    className: 'one',
    count: 1,
    size: '8.5x11',
    tooltip:
      'Compatible with Avery Shipping Labels 5165, 5265, 6470, 8165, 8255, 8465, 8665, 18665',
    type: 'sheet',
  },
  {
    className: 'two',
    count: 2,
    size: '5.5x8.5',
    tooltip:
      'Compatible with Avery Shipping Labels 5126, 5127, 5526, 4783, 5912, 8126, 8127, 15516, 18126, 85783',
    type: 'sheet',
  },
  {
    className: 'four',
    count: 4,
    size: '3.5x5',
    tooltip: 'Compatible with Avery Shipping Labels 5168, 6878, 8168',
    type: 'sheet',
  },
  {
    className: 'six',
    count: 6,
    size: '4x3.3',
    tooltip:
      'Compatible with Avery Shipping Labels 5164, 5264, 5524, 5664, 6874, 8164, 8254, 8464, 18664, 48464, 55164, 58165',
    type: 'sheet',
  },
  {
    className: 'ten',
    count: 10,
    size: '4x2',
    tooltip:
      'Compatible with Avery 5164, 5263, 5352, 5523, 5663, 5963, 5978, 8163, 8253, 8463, 8663, 15513, 18163, 18663, 28663, 48163, 55163, 58163',
    type: 'sheet',
  },
  {
    className: 'four-x-eight',
    label: '4 x 8',
    size: '4x8',
    tooltip: 'Compatible with Zebra and Dymo',
    type: 'roll',
  },
  {
    className: 'four-x-six',
    label: '4 x 6',
    size: '4x6',
    tooltip: 'Compatible with Zebra and Dymo',
    type: 'roll',
  },
  {
    className: 'five-x-three',
    label: '5 x 3',
    size: '3x5',
    tooltip: 'Compatible with Zebra and Dymo',
    type: 'roll',
  },
  {
    className: 'three-x-four',
    label: '3 x 4',
    size: '3x4',
    tooltip: 'Compatible with Zebra and Dymo',
    type: 'roll',
  },
  {
    className: 'four-x-two-eighth',
    label: '4 x 2.125',
    size: '4x2.125',
    tooltip: 'Compatible with Zebra and Dymo',
    type: 'roll',
  },
  {
    className: 'four-x-one',
    label: '4 x 1',
    size: '4x1',
    tooltip: 'Compatible with Zebra and Dymo',
    type: 'roll',
  },
]
