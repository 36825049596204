import { Loading } from '../../components/Loading'
import { useAccountCarrierState } from './AccountCarrierFormProvider'
import { provisionLocationComponentProvider } from './CarrierComponentProvider'
import { useAccountCarriers, useCarrierProvisioningDetails } from './queries'
import { type BaseProvisionFormProps } from './types'

type Props = BaseProvisionFormProps
export const AccountCarrierLocationForm = ({ onSuccess, onCancel }: Props) => {
  const { carrierId, locationId } = useAccountCarrierState()
  const { data: carrier } = useCarrierProvisioningDetails(carrierId)
  const { data: carriers } = useAccountCarriers()
  const accountCarrierData = carriers?.find(c => c.carrierId === carrierId)

  if (!carrier || !accountCarrierData) {
    return <Loading />
  }

  const CarrierComponent = provisionLocationComponentProvider[
    carrier.code ?? ''
  ]?.(!!accountCarrierData.isBeta)
  if (CarrierComponent) {
    return (
      <CarrierComponent
        carrierId={carrierId}
        locationId={locationId}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    )
  }

  return null
}
