import 'intl-tel-input/build/css/intlTelInput.min.css'

import { ChangeEvent, CSSProperties, useRef } from 'react'

import { shipperUri } from '@fv/client-core'

import { IntlTelInput } from './IntlTelInput'
import { TextFieldProps } from './TextField'

type Props = TextFieldProps
export const PhoneNumberField = ({
  disabled,
  name,
  required,
  onChange,
  value,
  className,
  readOnly,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null)
  const handleChange = (num: string) => {
    onChange({
      target: {
        ...(ref.current ?? {}),
        value: num,
      },
    } as ChangeEvent<HTMLInputElement>)
  }

  return (
    <IntlTelInput
      ref={ref}
      initialValue={value as string}
      onChangeValidity={({ valid }) => {
        ref.current?.setCustomValidity(
          valid ? '' : 'Please enter a valid phone number',
        )
      }}
      onChangeNumber={handleChange}
      inputProps={{
        name,
        required,
        readOnly,
        disabled,
        className,
        style: {
          '--iti-right-hand-selected-country-padding': '0px', // overwrite bad css var
        } as CSSProperties,
      }}
      initOptions={{
        showFlags: false,
        allowDropdown: false,
        containerClass: 'w-full',
        initialCountry: 'us',
        strictMode: true,
        customPlaceholder: () => '(___) ___-____',
        utilsScript: `${shipperUri}/intl-tel-input-23.0.8-utils.js`,
      }}
    />
  )
}
