import { type ListShipment } from '@fv/client-types'

import { type ImportantQuoteProps } from '../../features/quote/quoteFuncs'
import {
  composeCarrierBolUrl,
  composeFvBolUrl,
  isBolProcessing,
} from '../../utils/shipmentFuncs'
import { useCarrierPublicSettings } from '../useCarrierPublicSettings'

export type ShipmentBolState = {
  isProcessing?: boolean
  carrierBolUrl?: string
  hideFvBolFromSpotQuote?: boolean
  fvBolUrl?: string
  canFinishBol?: boolean
}

type HookProps = Pick<
  ListShipment,
  'bol' | 'status' | 'documents' | 'pickup' | 'workflow'
> & {
  selectedQuote?: ImportantQuoteProps
}

export function useBolLinkState(shipment?: HookProps): ShipmentBolState {
  const { selectedQuote, status, workflow } = shipment ?? {}
  const isProcessing = isBolProcessing(shipment)
  // some carriers fallback after a BOL error and still process the pickup
  // so this purposely only checks pickup status and not bol status
  const isError = shipment?.pickup?.status === 'error'
  const carrierBolUrl = composeCarrierBolUrl(shipment)
  const fvBolUrl = composeFvBolUrl(shipment)
  const { isFetching, carrierPublicSettings } = useCarrierPublicSettings(
    selectedQuote?.carrierId,
  )
  const canFinishBol =
    status !== 'canceled' &&
    shipment?.bol?.status === 'not-requested' &&
    !isProcessing &&
    !!selectedQuote?._id &&
    workflow === 'truckload'

  const hideFvBolFromSpotQuote =
    isFetching || carrierPublicSettings?.hideFreightviewBOLFromSpotQuote
  return {
    isProcessing,
    hideFvBolFromSpotQuote,
    canFinishBol,
    fvBolUrl: isProcessing || isError ? undefined : fvBolUrl,
    carrierBolUrl: isProcessing || isError ? undefined : carrierBolUrl,
  }
}
