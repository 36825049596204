import pluralize from 'pluralize'
import toast from 'react-hot-toast'

import {
  FvButton,
  SliderPanel,
  type SliderPanelProps,
} from '@fv/client-components'

import {
  type QuoteReasonPayload,
  useUpdateMultipleQuoteReasons,
} from '../features/quote-reason/hooks'
import AwardCarrierRow, { type AwardCarrierLoad } from './AwardCarrierRow'
import InfoBox from './InfoBox'

type TruckloadAwardConfirmationProps = Pick<
  SliderPanelProps,
  'closePanel' | 'isOpen'
> & {
  loadsToBook: AwardCarrierLoad[]
  showUnawarded?: boolean
  isBookingPage?: boolean
  submitLabel?: string
  onSubmit: () => void
  onCancel?: () => void
  cancelText?: string
  title?: string
}
export const TruckloadAwardConfirmation = ({
  closePanel,
  isOpen,
  loadsToBook,
  isBookingPage,
  showUnawarded,
  submitLabel,
  onSubmit,
  onCancel,
  cancelText,
  title = 'Award carrier confirmation',
}: TruckloadAwardConfirmationProps) => {
  const quoteReasons: Record<string, QuoteReasonPayload> = {}
  const quoteReasonMutation = useUpdateMultipleQuoteReasons()
  const loads = loadsToBook.filter(load => showUnawarded || !!load.quoteId)
  const allTrucksAwarded = !loads.some(l => !l.quoteId)
  const showIncompleteConfirmation = !allTrucksAwarded && !isBookingPage
  const defaultBookingLabel = allTrucksAwarded
    ? `Book ${pluralize('this', loadsToBook.length)} ${pluralize(
        'truck',
        loadsToBook.length,
      )}`
    : `Continue booking selected ${pluralize('truck', loadsToBook.length)}`

  const handleSubmit = async () => {
    if (isBookingPage && Object.keys(quoteReasons).length) {
      try {
        await quoteReasonMutation.mutateAsync(quoteReasons)
        onSubmit()
      } catch {
        toast.error('There was an error saving your selection')
      }
    } else {
      onSubmit()
    }
  }

  return (
    <SliderPanel closePanel={closePanel} isOpen={isOpen}>
      <div>
        <h6 className="header-divider">{title}</h6>
        {loads.map((load, i) => (
          <AwardCarrierRow
            key={load.loadId}
            load={load}
            quoteId={load.quoteId}
            truckNumber={i + 1}
            onQuoteReasonChange={
              isBookingPage
                ? reason => (quoteReasons[load.loadId] = reason)
                : undefined
            }
          />
        ))}

        {showIncompleteConfirmation && (
          <InfoBox className="mb-6">
            This is a multi-truck shipment. Would you like to wait to book this
            shipment until all trucks are chosen? If not, you may proceed to the
            booking details for the chosen trucks. You can come back here and
            fill remaining trucks at any time.
          </InfoBox>
        )}

        <div className="flex items-center justify-end">
          {showIncompleteConfirmation && (
            <FvButton theme="default" onClick={closePanel} icon="ban">
              <span>Wait until I select all trucks</span>
            </FvButton>
          )}

          {onCancel && cancelText && (
            <FvButton theme="plain" icon="times" onClick={onCancel}>
              {cancelText}
            </FvButton>
          )}

          <FvButton
            theme="primary"
            fwd
            loading={quoteReasonMutation.isLoading}
            icon="check"
            onClick={handleSubmit}
          >
            {submitLabel ?? defaultBookingLabel}
          </FvButton>
        </div>
      </div>
    </SliderPanel>
  )
}
