import { vOutput, vPicklist } from '../validation'

export const loadDocumentSources = ['carrier', 'shipper', 'system'] as const
export const loadDocumentSourceSchema = vPicklist(loadDocumentSources)
export type LoadDocumentSource = vOutput<typeof loadDocumentSourceSchema>

export const loadDocMimes = [
  'application/pdf',
  'application/zpl',
  'image/jpeg',
  'image/png',
] as const

export const loadDocumentMimeSchema = vPicklist(loadDocMimes)
export type LoadDocumentMime = vOutput<typeof loadDocumentMimeSchema>

export const loadDocTypes = [
  'appointment-confirmation',
  'authorization-for-special-service',
  'bol',
  'bol-request',
  'bol-response',
  'carta-porte',
  'commercial-invoice',
  'contact-time-critical',
  'corrected-bol',
  'customer-quote',
  'customs-document',
  'us-customs-declaration',
  'delivery-return-receipt',
  'dimension-certificate',
  'dispatch-request',
  'dispatch-response',
  'freight-forwarding-delivery-order',
  'inspection-certificate',
  'inspection-report',
  'invoice',
  'label',
  'label-combined',
  'letter-of-authority',
  'lumper-certificate',
  'mtr',
  'nmfc-certificate',
  'origin-certificate',
  'other',
  'packing-slip',
  'proof-of-delivery',
  'quote',
  'rate-confirmation',
  'rate-detail',
  'reconsignment-authorization',
  'refused-delivery-receipt',
  'signed-bol',
  'tally-record-signature',
  'weight-and-research-inspection',
  'weight-cert',
  'weights-and-corrections',
  'weights-and-research-photos',
] as const
export const loadDocumentTypeSchema = vPicklist(loadDocTypes)
export type LoadDocumentType = vOutput<typeof loadDocumentTypeSchema>

export const loadDocumentTypeConfigs: {
  key: LoadDocumentType
  name: string
  allowedSources: LoadDocumentSource[]
}[] = [
  {
    key: 'appointment-confirmation',
    name: 'Appointment confirmation',
    allowedSources: [],
  },
  {
    key: 'authorization-for-special-service',
    name: 'Authorization for special service',
    allowedSources: [],
  },
  {
    key: 'bol',
    name: 'Bill of lading',
    allowedSources: ['shipper', 'carrier'],
  },
  { key: 'bol-request', name: '', allowedSources: [] },
  { key: 'bol-response', name: '', allowedSources: [] },
  {
    key: 'carta-porte',
    name: 'Carta porte',
    allowedSources: ['shipper', 'carrier'],
  },
  {
    key: 'commercial-invoice',
    name: 'Commercial invoice',
    allowedSources: ['shipper', 'carrier'],
  },
  {
    key: 'contact-time-critical',
    name: 'Contact time critical',
    allowedSources: [],
  },
  { key: 'corrected-bol', name: 'Corrected BOL', allowedSources: [] },
  { key: 'customer-quote', name: 'Customer rate detail', allowedSources: [] },
  { key: 'customs-document', name: 'Customs document', allowedSources: [] },
  {
    key: 'delivery-return-receipt',
    name: 'Delivery return receipt',
    allowedSources: [],
  },
  {
    key: 'dimension-certificate',
    name: 'Dimension certificate',
    allowedSources: ['shipper'],
  },
  { key: 'dispatch-request', name: '', allowedSources: [] },
  { key: 'dispatch-response', name: '', allowedSources: [] },
  {
    key: 'freight-forwarding-delivery-order',
    name: 'Freight forwarding delivery order',
    allowedSources: [],
  },
  {
    key: 'inspection-certificate',
    name: 'Inspection certificate',
    allowedSources: [],
  },
  { key: 'inspection-report', name: 'Inspection report', allowedSources: [] },
  { key: 'invoice', name: 'Invoice', allowedSources: ['shipper', 'carrier'] },
  { key: 'label', name: 'Label', allowedSources: ['shipper'] },
  {
    key: 'label-combined',
    name: 'Label combined',
    allowedSources: ['shipper'],
  },
  {
    key: 'letter-of-authority',
    name: 'Letter of authority',
    allowedSources: [],
  },
  { key: 'lumper-certificate', name: 'Lumper certificate', allowedSources: [] },
  {
    key: 'mtr',
    name: 'Material test report (MTR)',
    allowedSources: ['shipper', 'carrier'],
  },
  { key: 'nmfc-certificate', name: 'NMFC certificate', allowedSources: [] },
  {
    key: 'origin-certificate',
    name: 'Certificate of origin',
    allowedSources: ['shipper', 'carrier'],
  },
  { key: 'other', name: 'Other', allowedSources: ['shipper', 'carrier'] },
  {
    key: 'packing-slip',
    name: 'Packing slip',
    allowedSources: ['shipper', 'carrier'],
  },
  {
    key: 'proof-of-delivery',
    name: 'Proof of delivery',
    allowedSources: ['shipper', 'carrier'],
  },
  { key: 'quote', name: '', allowedSources: [] },
  {
    key: 'rate-confirmation',
    name: 'Rate Confirmation',
    allowedSources: ['carrier'],
  },
  { key: 'rate-detail', name: 'Rate detail', allowedSources: [] },
  {
    key: 'reconsignment-authorization',
    name: 'Reconsignment authorization',
    allowedSources: [],
  },
  {
    key: 'refused-delivery-receipt',
    name: 'Refused delivery receipt',
    allowedSources: [],
  },
  {
    key: 'signed-bol',
    name: 'Signed bill of lading',
    allowedSources: ['shipper', 'carrier'],
  },
  {
    key: 'tally-record-signature',
    name: 'Tally record signature',
    allowedSources: [],
  },
  {
    key: 'us-customs-declaration',
    name: 'U.S. Customs Declaration',
    allowedSources: ['shipper', 'carrier'],
  },
  {
    key: 'weight-and-research-inspection',
    name: 'Weight and research inspection',
    allowedSources: [],
  },
  {
    key: 'weight-cert',
    name: 'Weight certificate',
    allowedSources: ['carrier'],
  },
  {
    key: 'weights-and-corrections',
    name: 'Weights and corrections',
    allowedSources: [],
  },
  {
    key: 'weights-and-research-photos',
    name: 'Weights and research photos',
    allowedSources: [],
  },
]

export const carrierDocTypes: LoadDocumentType[] = [
  ...loadDocumentTypeConfigs
    .filter(c => c.allowedSources.includes('carrier'))
    .map(c => c.key),
]

export const carrierViewableDocTypes: readonly LoadDocumentType[] = [
  'commercial-invoice',
]

export interface LoadDocumentDTO {
  _id?: string
  fileName: string
  mimeType: LoadDocumentMime
  source: LoadDocumentSource
  storageKey: string
  type: LoadDocumentType
  uploadDate: Date
  url: string
  uploadedByUserId?: string
  isShared?: boolean
  itemId?: string
  unitIndex?: number
}
