import { type Dispatch, type SetStateAction, useRef } from 'react'
import toast from 'react-hot-toast'

import { FvButton, SliderPanel } from '@fv/client-components'
import {
  submitForm,
  useFormModelState,
  validateEmailString,
} from '@fv/client-core'

import { ValidatedForm } from '../../../components/inputs/ValidatedForm'
import { freightviewUri } from '../../../constants'
import { useAppContext } from '../../../hooks/settings'
import { type ProfileInfoModel } from '../../../types/ShipperAccountUser'
import { useUpdateProfile } from '../../profiile/mutations'
import { ProfileInfoForm } from '../../profiile/ProfileInfoForm'
import { PageHeader } from '../PageHeader'

type Props = {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const EditContactInfoPanel = ({ isOpen, setOpen }: Props) => {
  const contextQuery = useAppContext()
  const user = contextQuery.data?.user
  const formRef = useRef<HTMLFormElement>(null)
  const updateUserInfo = useUpdateProfile(true)
  const { register, value, setValue } = useFormModelState<ProfileInfoModel>({
    initialValue: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      company: user?.company ?? '',
      title: user?.title ?? '',
      email: user?.email ?? '',
      phoneNumber: user?.phoneNumber?.replace(/\D/g, '') ?? '',
    },
    schema: {
      email: {
        validate: e => (validateEmailString(e) ? '' : 'Must be a valid email'),
      },
    },
  })

  const handleUpdate = (): void => {
    if (updateUserInfo.isLoading) return
    if (!formRef.current?.checkValidity()) {
      return submitForm(formRef.current)
    }

    updateUserInfo
      .mutateAsync(value, {
        onSuccess: e => {
          if (e?.email !== user?.email) {
            window.location.href = `${freightviewUri}/login`
          }
          setOpen(false)
        },
      })
      .catch(e => {
        const msg = e?.message || `Unable to update profile`
        toast.error(msg)
      })
  }

  return (
    <SliderPanel
      className="flex flex-col flex-nowrap items-stretch"
      closePanel={() => setOpen(false)}
      isOpen={isOpen}
    >
      <ValidatedForm
        onValidSubmit={handleUpdate}
        ref={formRef}
        className="flex h-full flex-col"
      >
        <div className="mb-10 overflow-auto">
          <PageHeader title="Edit your contact information" />
          <div className="form-row">
            <ProfileInfoForm
              value={value}
              register={register}
              onChange={setValue}
              autoFocus
              firstNameClassName="basis-3/12"
              lastNameClassName="basis-4/12"
              companyClassName="basis-5/12"
              titleClassName="basis-3/12"
              emailClassName="basis-6/12"
              phoneNumberClassName="basis-3/12"
            />
          </div>
        </div>

        <div className="flyout-footer-actions flex items-center">
          <FvButton
            loading={updateUserInfo.isLoading}
            icon={updateUserInfo.isLoading ? 'sync' : 'check'}
            theme="primary"
            type="submit"
          >
            Save contact info
          </FvButton>
          <FvButton icon="times" theme="plain" onClick={() => setOpen(false)}>
            Cancel
          </FvButton>
        </div>
      </ValidatedForm>
    </SliderPanel>
  )
}
