import clsx from 'clsx'

import { FvLinkButton, Icon } from '@fv/client-components'

type SharePanelHeaderProps = {
  className?: string
  closePanel: () => void
}

const SharePanelHeader = ({ className, closePanel }: SharePanelHeaderProps) => {
  return (
    <nav
      className={clsx(
        'divided-content divided-content--start shipment-list-ancillary-details__nav',
        className,
      )}
    >
      <FvLinkButton icon="arrow-to-left" onClick={closePanel}>
        Back
      </FvLinkButton>
      <div>
        <Icon icon="share" className="color-dark" />
        <span>Share this shipment</span>
      </div>
    </nav>
  )
}

export default SharePanelHeader
