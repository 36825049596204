import { FvLinkButton } from '@fv/client-components'
import { registerCredentialDetailHelper } from '@fv/client-core'

import { InputGroup } from '../../../components/inputs/InputGroup'
import { UsernamePasswordForm } from '../components/UsernamePasswordForm'
import { type CredentialFormProps } from '../types'

export const FedexLegacyCredForm = (props: CredentialFormProps) => {
  const { value, onChange } = props
  return (
    <div>
      <UsernamePasswordForm {...props} />
      <InputGroup
        label="FedEx Express account number"
        inputType="text"
        className="basis-full lg:basis-1/3"
        inputProps={{
          type: 'text',
          ...registerCredentialDetailHelper(
            'express-account-number',
            value,
            onChange,
          ),
          required: true,
        }}
      />
      <p className="-mt-3 text-xs italic">
        Your FedEx Express account number is a 9 digit number that is needed to
        access FedEx rates. If you do not have a FedEx Express account,{' '}
        <FvLinkButton
          theme="callout"
          href="https://www.fedex.com/en-us/create-account.html"
          target="_blank"
        >
          please create one
        </FvLinkButton>{' '}
        and then enter it below.
      </p>
    </div>
  )
}
