import clsx from 'clsx'

import { type PropsWithClassName } from '@fv/client-types'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'

type Props = {
  value: number
  onChange: (val: number) => void
  disabled?: boolean
}
export const MarkupField = ({
  className,
  value,
  onChange,
  disabled,
}: PropsWithClassName<Props>) => {
  return (
    <InputGroup
      inputType="amount"
      className={clsx(className)}
      inputProps={{
        name: 'markup',
        value: value,
        disabled,
        onChange: e => onChange(Number(e.target.value || '0')),
      }}
      startContent={
        <InputAdornment position="start" className="whitespace-nowrap">
          Mark up rates
        </InputAdornment>
      }
      endContent={<InputAdornment position="end">%</InputAdornment>}
    />
  )
}
