import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type AwardLoadsDTO, type ListShipment } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useNotesQueryInvalidator } from '../../hooks/messages/useNotes'
import { useRemoveFromList, useUpdateCachedLoad } from '../../hooks/shipments'
import { useMutateRecentAccountData } from './hooks'

async function awardLoads({
  billTo,
  bolNumber,
  createBOL,
  customsBroker,
  emergencyContact,
  isEdit,
  loads,
  locations,
  retender,
  retenderReason,
  schedulePickup = true,
  shares,
  tags,
  rateLocationType,
}: AwardLoadsDTO): Promise<{
  isEdit: boolean
  isRetender: boolean
  loads: ListShipment[]
}> {
  const endpoint = isEdit
    ? `${apiUri}/shipments/edit`
    : `${apiUri}/shipments/award`

  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({
      billTo,
      bolNumber,
      createBOL,
      customsBroker,
      emergencyContact,
      loads,
      locations,
      schedulePickup,
      tags,
      ...(rateLocationType && { rateLocationType }),
      ...(retender && {
        retender,
        ...(retenderReason && { retenderReason }),
      }),
      ...(shares && { shares }),
    }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)

  if (response.ok) {
    return {
      isEdit,
      isRetender: !!retender,
      loads: response.json,
    }
  }

  throw response.errorMessage
}

export function useAwardLoads() {
  const { clear: clearRecentAccountData } = useMutateRecentAccountData()
  const queryClient = useQueryClient()
  const removeFromList = useRemoveFromList()
  const updateCachedLoad = useUpdateCachedLoad()
  const notesInvalidator = useNotesQueryInvalidator()
  return useMutation(awardLoads, {
    onSettled: (data, error, variables) => {
      // Handle errors from callsite
      if (error || !data) return

      const { isEdit, isRetender, loads } = data
      const loadId = data.loads[0].loadId

      if (isRetender) {
        removeFromList({
          loadId,
          queryFilter: ['shipments', 'confirmed'],
        })
      }

      loads.forEach(updatedLoad => {
        updateCachedLoad({
          updatedLoad: {
            ...updatedLoad,
            emergencyContact: variables.emergencyContact,
          },
        })
      })

      // May require a rerate, only toast success if no longer pending
      if (loads[0].status !== 'pending') {
        toast.success(
          `${loads.length > 1 ? 'Loads' : 'Load'} ${
            isRetender ? 'retendered' : isEdit ? 'updated' : 'booked'
          } successfully.`,
        )
      }

      queryClient.invalidateQueries(['customs-brokers'])
      notesInvalidator(loads[0].loadId)
      clearRecentAccountData()
    },
  })
}
