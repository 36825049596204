import pluralize from 'pluralize'

import {
  DelimitedContent,
  FvLinkButton,
  Icon,
  Tooltip,
} from '@fv/client-components'
import { type FullShipment, type UIQuote } from '@fv/client-types'

import { routes } from '../../constants'
import { mapPaymentTerms } from '../../utils/shipmentFuncs'
import { quoteFuncs } from '../quote/quoteFuncs'

const TransitDays = ({
  load,
  quote,
}: {
  load: FullShipment
  quote: UIQuote
}) => {
  const daysText = `${quote.transitDaysMin} ${pluralize(
    'day',
    quote.transitDaysMin,
  )}`
  return (
    <Tooltip
      label={`ETA ${quoteFuncs
        .getEstimatedDeliveryDate(load.pickupDate, quote)
        ?.format('MM-DD-YYYY')}`}
    >
      <span className="decoration-fv-blue ml-0 underline">{daysText}</span>
    </Tooltip>
  )
}
type Props = {
  load?: FullShipment
  quote: UIQuote
}
export const BookingRateDetails = ({ load, quote }: Props) => {
  const paymentTerms = mapPaymentTerms({ selectedQuote: quote })
  const transitDays = load && quote.transitDaysMin && (
    <TransitDays load={load} quote={quote} />
  )
  return (
    <div className="-mx-6 mb-10 flex divide-x divide-[#e8e0b2] border-b border-[#e8e0b2] bg-fv-beer px-6 py-4">
      <p className="mb-0">
        <DelimitedContent delimiter={<> / </>}>
          {quote.assetCarrierName ?? quote.providerName}
          {quote.serviceDescription && <> {quote.serviceDescription} </>}
          <>${quote.amount.toFixed(2)}</>
          {quote.paymentTerms && paymentTerms}
          {transitDays && transitDays}
          {!!quote.quoteNum && <>Quote # {quote.quoteNum}</>}
        </DelimitedContent>
      </p>
      {!!load && (
        <FvLinkButton
          icon="list"
          className="ml-4 pl-4 leading-6"
          to={routes.rates(load)}
        >
          View other rates
        </FvLinkButton>
      )}
    </div>
  )
}
