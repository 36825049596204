import { type BillToDTO, type FormBillTo } from '@fv/client-types'
import { type Country } from '@fv/models'
import { formatPhone } from '@fv/models/core'

export const defaultFormBillTo: FormBillTo = {
  address: '',
  address2: '',
  city: '',
  company: '',
  contactEmail: '',
  contactName: '',
  contactPhone: '',
  contactPhoneExt: '',
  country: 'us',
  postalCode: '',
  state: '',
}

export const billToMapper = {
  dtoToForm: (billTo?: BillToDTO | null): FormBillTo => ({
    address: billTo?.address || '',
    address2: billTo?.address2 || '',
    adminArea3: billTo?.adminArea3 || '',
    city: billTo?.city || '',
    company: billTo?.company || '',
    contactEmail: billTo?.contactEmail || '',
    contactName: billTo?.contactName || '',
    contactPhone: billTo?.contactPhone?.replace(/\D/g, '') || '',
    contactPhoneExt: billTo?.contactPhoneExt || '',
    country: billTo?.country || 'us',
    postalCode: billTo?.postalCode || '',
    state: billTo?.state || '',
  }),
  formToDTO: (billTo: FormBillTo): BillToDTO => {
    const address2 = billTo.address2.trim()
    const adminArea3 = billTo.adminArea3?.trim()
    const contactEmail = billTo.contactEmail.trim()
    const contactPhone = formatPhone(billTo.contactPhone.trim())
    const contactPhoneExt = billTo.contactPhoneExt.trim()

    return {
      address: billTo.address.trim(),
      city: billTo.city.trim(),
      company: billTo.company.trim(),
      contactName: billTo.contactName.trim(),
      country: billTo.country.toLowerCase() as Country,
      postalCode: billTo.postalCode.trim(),
      state: billTo.state.trim(),
      ...(address2 && { address2 }),
      ...(adminArea3 && { adminArea3 }),
      ...(contactEmail && { contactEmail }),
      ...(contactPhone && { contactPhone }),
      ...(contactPhoneExt && { contactPhoneExt }),
    }
  },
}

export function validateBillTo(
  billTo?: BillToDTO | FormBillTo | null,
  required?: {
    contactEmail?: boolean
    contactPhone?: boolean
  },
) {
  return Boolean(
    billTo?.address?.trim() &&
      billTo.city?.trim() &&
      billTo.company?.trim() &&
      billTo.contactName?.trim() &&
      billTo.country?.trim() &&
      billTo.postalCode?.trim() &&
      billTo.state?.trim() &&
      (!required?.contactEmail || billTo.contactEmail?.trim()) &&
      (!required?.contactPhone || billTo.contactPhone?.trim()),
  )
}
