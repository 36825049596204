import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import { type MilitaryTime, militaryTimeFormat } from '@fv/client-types'

import TimeSelectField from '../../components/inputs/TimeSelectField'
import { DayPickerPanel } from '../dates/DayPickerPanel'

type DatePickerProps = {
  onClose: () => void
  value?: string
  open?: boolean
  isSaving?: boolean
  onSave: (dateTime: Date | undefined) => void
}

export const ExpirationDatePickerPanel = ({
  onClose,
  value,
  isSaving,
  open,
  onSave,
}: DatePickerProps) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const [expTime, setExpTime] = useState<string>(undefined)

  const disabledDays = (day: Date) => {
    return dayjs().startOf('day').isAfter(day)
  }

  const handleTimeSelect = (time: MilitaryTime) => {
    setExpTime(time)
  }
  const handleSave = (date: Date) => {
    if (!expTime && !date) {
      return onSave(undefined)
    }
    if (!expTime && date && selectRef.current) {
      selectRef.current.setCustomValidity('Time is required')
      selectRef.current.reportValidity()
      return
    }
    selectRef.current.setCustomValidity('')

    const expireTime = dayjs(expTime, militaryTimeFormat)
    const expireDateTime = dayjs(date)
      .set('h', expireTime.get('h'))
      .set('minutes', expireTime.get('minutes'))

    onSave(expireDateTime.toDate())
  }

  useEffect(() => {
    if (!open) {
      setExpTime(undefined)
    } else {
      const initialExp = value ? dayjs(value) : undefined
      setExpTime(initialExp ? initialExp.format(militaryTimeFormat) : undefined)
    }
  }, [open, value])

  return (
    <DayPickerPanel
      mode="single"
      onClose={onClose}
      open={open}
      isSaving={isSaving}
      disabled={disabledDays}
      selected={value ? dayjs(value).toDate() : undefined}
      onSave={handleSave}
      showClear
      onCancel={() => onClose()}
      onClear={() => {
        setExpTime(undefined)
      }}
      rightContent={
        <div>
          <div>{'Expiration time and date'}</div>
          <hr className="mx-0 my-2 mb-4" />
          <TimeSelectField
            containerClassName="col"
            ref={selectRef}
            name="endsAt"
            setValue={t => handleTimeSelect(t as MilitaryTime)}
            value={expTime}
          />
        </div>
      }
    />
  )
}
