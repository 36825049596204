import orderBy from 'lodash/orderBy'
import { useParams } from 'react-router-dom'
import { useToggle } from 'usehooks-ts'

import {
  FvButton,
  Icon,
  SliderPanel,
  useFvNavigate,
} from '@fv/client-components'
import { type DetailedAccountProvisioningDto } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import { List, ListHeaderActions } from '../../components/List'
import { Loading } from '../../components/Loading'
import { HasPermission } from '../auth'
import { SettingsSection } from '../settings/SettingsSection'
import { AccountCarrierEntry } from './AccountCarrierEntry'
import { AccountCarrierFormProvider } from './AccountCarrierFormProvider'
import { AccountCarrierListItem } from './AccountCarrierListItem'
import { AvailableCarrierList } from './AddCarrierContent'
import { useRemoveCarrier } from './mutations'
import { useAccountCarriers } from './queries'

export const AccountCarriersPage = () => {
  const query = useAccountCarriers()
  const [isAdding, toggleAdding, setIsAdding] = useToggle()
  const removeCarrier = useRemoveCarrier()
  const params = useParams()
  const navigate = useFvNavigate()
  const carrierId = params['carrierId']
  const locationId = params['locationId']

  const closePanel = () => {
    navigate('/settings/carriers')
    setIsAdding(false)
  }

  const handleDeleteClick = async (carrier: DetailedAccountProvisioningDto) => {
    if (
      window.confirm(
        `Are you sure you want to remove ${carrier.carrierName} from your account?`,
      )
    ) {
      await removeCarrier.mutateAsync({ carrierId: carrier.carrierId })
    }
  }

  if (query.isLoading && !query.data) {
    return (
      <div className="p-8">
        <Loading />
      </div>
    )
  }

  return (
    <div className="settings-main">
      {!query.data?.length && (
        <InfoBox className="mb-8" custom>
          <div className="flex gap-x-1">
            <Icon
              icon="badge-check"
              transform="down-3 d grow-5"
              className="text-fv-orange"
            />
            <p>
              Welcome to Freightview. You are currently on our settings page,
              where you can add carriers, customer addresses, products,
              spot-quote contacts, and more to customize our app. Let's begin by
              adding any carriers from which you wish to receive rates.
              Alternatively, you can choose another action from the settings
              menu or navigate through our app.
            </p>
          </div>
        </InfoBox>
      )}
      <SettingsSection title="Carriers">
        <HasPermission name="manage">
          <ListHeaderActions>
            <FvButton icon="plus" iconClass="fa-fw" onClick={toggleAdding}>
              Add carrier
            </FvButton>
          </ListHeaderActions>
        </HasPermission>
        {query.data && (
          <List>
            {orderBy(query.data, 'carrierStatus').map(c => (
              <AccountCarrierListItem
                carrier={c}
                key={c.carrierId}
                onEditClick={locId => {
                  let rte = `/settings/carriers/${c.carrierId}`

                  if (locId) {
                    rte += `/${locId}`
                  }

                  navigate(rte)
                }}
                onDeleteClick={() => handleDeleteClick(c)}
              />
            ))}
          </List>
        )}
      </SettingsSection>

      <SliderPanel
        className="flex flex-col"
        isOpen={isAdding || !!carrierId}
        closePanel={closePanel}
      >
        {!carrierId && (
          <AvailableCarrierList
            onSelect={c => navigate(`/settings/carriers/${c}`)}
          />
        )}
        {carrierId && (
          <AccountCarrierFormProvider
            state={{
              isAdding,
              carrierId,
              locationId,
              onClose: closePanel,
            }}
          >
            <AccountCarrierEntry />
          </AccountCarrierFormProvider>
        )}
      </SliderPanel>
    </div>
  )
}
