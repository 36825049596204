import upperFirst from 'lodash/upperFirst'
import pluralize from 'pluralize'

import { FvButton, FvLinkButton, Icon } from '@fv/client-components'
import { formatNumber, totalShipmentWeight } from '@fv/client-core'
import { type PropsWithLoad } from '@fv/client-types'
import { type EquipmentType } from '@fv/models'

import { routes } from '../../constants'
import { getDimensions } from '../../features/commodities/commodityUtils'
import { useLocale, useTypeNames } from '../../hooks/settings'
import { ListItem } from '../List'

type SummaryProps = PropsWithLoad<{
  equipmentType?: EquipmentType
  isBooking?: boolean
  showItems?: () => void
}>

// TODO: Dims & class if multiple items?
export const LoadSummaryDetails = ({ load, showItems }: SummaryProps) => {
  const { equipment, items, workflow } = load
  const { packageName } = useTypeNames()
  const locale = useLocale()

  if (workflow === 'truckload') return null

  const { description, weightUOM } = equipment
  const totalWeight = totalShipmentWeight(load)
  const dimensions = getDimensions(items[0])
  const freightClass = items[0]?.freightClass
  const handlingUnits = items
    .map(i => {
      const name = packageName(i.type)
      return pluralize(name, i.quantity, true)
    })
    .join(', ')

  return (
    <>
      <ListItem className="leading-5">
        <FvButton
          theme="underlined"
          icon="quote-left"
          iconClass="fa-fw flex-shrink-0"
          onClick={showItems}
          transform="down-2"
          className="flex !whitespace-normal text-left"
        >
          <p className="mb-0">
            {upperFirst(description) || 'No description given'}
          </p>
        </FvButton>
      </ListItem>

      <ListItem itemsStart>
        <Icon
          icon="ramp-loading"
          className="fa-fw color-dark flex-shrink-0"
          transform="down-2.5"
        />
        <p className="mb-0">{handlingUnits}</p>
      </ListItem>

      <ListItem>
        <Icon icon="weight-hanging" className="fa-fw color-dark" />
        <span>
          {formatNumber(totalWeight, locale)} {weightUOM}
        </span>
      </ListItem>

      {(!!dimensions || !!freightClass) && (
        <ListItem>
          <Icon icon="ruler" className="fa-fw color-dark" />
          <span>
            {dimensions}
            {dimensions && freightClass ? ' / ' : ''}
            {freightClass ? `Class ${freightClass}` : ''}
          </span>
        </ListItem>
      )}
    </>
  )
}

export const LoadSummaryActions = ({
  load,
  className,
}: PropsWithLoad & { className?: string }) => {
  const { isFreightCollect, status, workflow } = load

  if (isFreightCollect || status !== 'pending' || workflow === 'truckload') {
    return null
  }

  return (
    <li className={className}>
      <FvLinkButton
        icon="pen-alt"
        to={routes.quote(workflow, { id: load.loadId, mode: 'edit' })}
      >
        <span>Change quote details</span>
      </FvLinkButton>
    </li>
  )
}
