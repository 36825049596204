import { standardToMilitaryTime } from '@fv/client-core'
import {
  type CSVRow,
  type LegacyShipType,
  legacyShipTypes,
} from '@fv/client-types'
import {
  type CompanyAddressBase,
  type LocationAccessorialDTO,
} from '@fv/models'

import { type FormAddress } from './types'

export interface AddressBookUploadRecord extends Partial<CompanyAddressBase> {
  shipType?: LegacyShipType

  contactEmail?: string
  contactPhone?: string
  contactName?: string

  dockHoursStart?: string
  dockHoursEnd?: string
  specialInstructions?: string
  bolFollowers?: string

  adultSignatureRequired?: boolean
  callNotifyBeforeDelivery?: boolean
  deliveryAppointment?: boolean
  insideDelivery?: boolean
  insidePickup?: boolean
  liftgateDelivery?: boolean
  liftgatePickup?: boolean
  noSignatureRequired?: boolean
  signatureRequired?: boolean
  sortAndSegregate?: boolean
  indirectSignature?: boolean
}

const boolColumns: Array<keyof AddressBookUploadRecord> = [
  'adultSignatureRequired',
  'callNotifyBeforeDelivery',
  'deliveryAppointment',
  'insideDelivery',
  'insidePickup',
  'liftgateDelivery',
  'liftgatePickup',
  'noSignatureRequired',
  'signatureRequired',
  'sortAndSegregate',
  'indirectSignature',
]

const getMappedCol = (
  colName: string,
): keyof AddressBookUploadRecord | undefined => {
  if (colName.match(/company/i)) return 'company'
  if (colName.match(/address\s?2/i)) return 'address2'
  if (colName.match(/address/i)) return 'address'
  if (colName.match(/city/i)) return 'city'
  if (colName.match(/state/i)) return 'state'
  if (colName.match(/zip|postal/i)) return 'postalCode'
  if (colName.match(/country/i)) return 'country'

  if (colName.match(/type/i)) return 'shipType'

  if (colName.match(/email/i)) return 'contactEmail'
  if (colName.match(/phone/i)) return 'contactPhone'
  if (colName.match(/name/i)) return 'contactName'
  if (colName.match(/dock/i) && colName.match(/start/i)) return 'dockHoursStart'
  if (colName.match(/dock/i) && colName.match(/end/i)) return 'dockHoursEnd'
  if (colName.match(/special/i)) return 'specialInstructions'
  if (colName.match(/notify/i)) return 'callNotifyBeforeDelivery'
  if (colName.match(/appointment/i)) return 'deliveryAppointment'
  if (colName.match(/inside/i) && colName.match(/delivery/i))
    return 'insideDelivery'
  if (colName.match(/inside/i) && colName.match(/pickup/i))
    return 'insidePickup'
  if (colName.match(/liftgate/i) && colName.match(/delivery/i))
    return 'liftgateDelivery'
  if (colName.match(/liftgate/i) && colName.match(/pickup/i))
    return 'liftgatePickup'

  if (colName.match(/adult/i)) return 'adultSignatureRequired'
  if (colName.match(/signature/i) && colName.match(/no/i))
    return 'noSignatureRequired'
  if (colName.match(/signature/i)) return 'signatureRequired'

  if (colName.match(/sort/i)) return 'sortAndSegregate'
  if (colName.match(/indirect/i)) return 'indirectSignature'
  if (colName.match(/share/i)) return 'bolFollowers'
  return
}

export const parseAddressCsv = (data: CSVRow[]): AddressBookUploadRecord[] => {
  if (!data.length) return []
  const colMap = Object.keys(data[0]).map(k => ({
    original: k,
    mapped: getMappedCol(k),
  }))

  return data.map(d =>
    Object.fromEntries(
      Object.entries(d)
        .map<[keyof AddressBookUploadRecord | undefined, string]>(
          ([key, value]) => [
            colMap.find(c => c.original === key)?.mapped,
            value,
          ],
        )
        .filter(([key]) => !!key)
        .map(([key, value]) => [
          key,
          key && boolColumns.includes(key) ? !!value?.match(/x/i) : value,
        ]),
    ),
  )
}

const shipTypes = legacyShipTypes.map(v => v.value)
export const isValidShipType = (value?: string) => {
  if (!value) return true

  return shipTypes.includes(value as LegacyShipType)
}

export const rowHasError = (row: AddressBookUploadRecord): boolean => {
  const { address, city, state, postalCode, company, shipType } = row
  const hasMissingProp =
    !address || !city || !state || !postalCode || !company || !shipType
  const invalidShipType = !isValidShipType(shipType)
  const invalid = hasMissingProp || invalidShipType
  return rowHasData(row) && invalid
}

export const rowHasData = ({
  address,
  city,
  state,
  postalCode,
  company,
  shipType,
}: AddressBookUploadRecord): boolean => {
  return !!(address || city || state || postalCode || company || shipType)
}

export const buildCreateModel = (row: AddressBookUploadRecord): FormAddress => {
  const ltlAccessorials: LocationAccessorialDTO[] = []
  const parcelAccessorials: LocationAccessorialDTO[] = []

  if (row.adultSignatureRequired) {
    parcelAccessorials.push({ key: 'adult-signature-required' })
  }
  if (row.signatureRequired) {
    parcelAccessorials.push({ key: 'signature-required' })
  }
  if (row.indirectSignature) {
    parcelAccessorials.push({ key: 'indirect-signature-required' })
  }
  if (row.liftgateDelivery || row.liftgatePickup) {
    ltlAccessorials.push({ key: 'liftgate' })
  }
  if (row.insidePickup || row.insideDelivery) {
    ltlAccessorials.push({ key: 'inside' })
  }
  if (row.callNotifyBeforeDelivery) {
    ltlAccessorials.push({ key: 'notify' })
  }
  if (row.deliveryAppointment) {
    ltlAccessorials.push({ key: 'schedule' })
  }
  if (row.sortAndSegregate) {
    ltlAccessorials.push({ key: 'sort-and-segregate' })
  }
  return {
    address: row.address ?? '',
    address2: row.address2 ?? '',
    city: row.city ?? '',
    state: row.state ?? '',
    company: row.company ?? '',
    postalCode: row.postalCode ?? '',
    instructions: row.specialInstructions ?? '',
    contactEmail: row.contactEmail ?? '',
    contactName: row.contactName ?? '',
    contactPhone: row.contactPhone ?? '',
    contactPhoneExt: '',
    country: row.country || 'us',
    isAccountLocation: false,
    closesAt: row.dockHoursEnd
      ? standardToMilitaryTime(row.dockHoursEnd)
      : undefined,
    opensAt: row.dockHoursStart
      ? standardToMilitaryTime(row.dockHoursStart)
      : undefined,

    mappedAccessorials: {
      ltl: ltlAccessorials,
      parcel: parcelAccessorials,
      truckload: [],
    },
    bolFollowers: row.bolFollowers ?? '',
    sendBOL: false,
    shipType: row.shipType ?? '',
  }
}

export const addressBookTemplate = `Company*,Address*,City*,State*,Zip*,Address 2,Contact name,Contact phone,Contact email,Country,Location type*,Share with,Special instructions,Dock hours start,Dock hours end,Liftgate pickup,Liftgate delivery,Inside pickup,Inside delivery,Call/notify before delivery,Delivery appointment,Sort and segregate,No signature required,Signature required,Adult signature required,Indirect signature
My Company,901 Carondelet Dr,Kansas City,MO,64114,,,5555555555,,,business dock,share@example.com,,8:00AM,5:00PM,x,x,x,x,x,x,x,x,x,x,x
Another Company,901 Carondelet Dr,Kansas City,MO,64114,,,,,,business no dock,,,,,,,,,,,,,,,`
