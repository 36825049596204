import { type ChangeEvent } from 'react'

import { addressFuncs, validateEmailInput } from '@fv/client-core'
import {
  type ClientAddressLookupResult,
  type FormBillTo,
} from '@fv/client-types'
import { type Workflow } from '@fv/models'
import { formatPhone } from '@fv/models/core'

import { AddressInput } from '../../components/inputs/AddressInput'
import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'
import { type FormSectionProps } from '../../components/inputs/types'

const getInputName = (name: string) => `billTo-${name}`

type Props = FormSectionProps & {
  autoFocus?: boolean
  nameLabel?: string
  required?: boolean
  requireEmail?: boolean
  requirePhone?: boolean
  setValues: (values: Partial<FormBillTo>) => void
  values: FormBillTo
  workflow: Workflow
}

export const BillToForm = ({
  autoFocus = false,
  disabled,
  required = true,
  requireEmail = false,
  requirePhone = false,
  setValues,
  values,
  workflow,
}: Props) => {
  if (!required) return null

  function getInputProps(
    name: keyof FormBillTo,
    inputProps?: { autoFocus?: boolean },
  ) {
    return {
      disabled,
      name: getInputName(name),
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        if (name === 'contactEmail') {
          validateEmailInput(e.target)
        }

        setValues({ [name]: e.target.value })
      },
      value: values[name] ?? '',
      ...inputProps,
    }
  }

  function selectAddress(x: ClientAddressLookupResult | null) {
    setValues({
      address: x?.address || values.address,
      address2: x?.address2 || values.address2,
      adminArea3: x?.adminArea3,
      city: x?.city || '',
      company: x?.company || values.company,
      contactEmail: x?.contactEmail || values.contactEmail,
      contactName: x?.contactName || values.contactName,
      contactPhone:
        formatPhone(x?.contactPhone) || formatPhone(values.contactPhone),
      contactPhoneExt: x?.contactPhoneExt || values.contactPhoneExt,
      country: x?.country || values.country,
      postalCode: x?.postalCode || '',
      state: x?.state || '',
    })
  }

  return (
    <>
      <InputGroupWrapper
        className="b1450:basis-4/12 basis-4/12"
        label="Bill to company"
        name={getInputName('company')}
        required
      >
        <AddressInput
          allowFreeText
          autoFocus={autoFocus}
          formatOptionLabel={addressFuncs.companyAddress}
          formatAddress={x => x.company ?? values.company}
          includeAddressBook
          includeAtlas={false}
          isClearable={false}
          location={{
            ...values,
            country: values.country || 'us',
          }}
          addressType="billing"
          name={getInputName('company')}
          onInputChange={company => setValues({ company })}
          onLocationChange={selectAddress}
          placeholder="Company name"
          required
        />
      </InputGroupWrapper>

      <InputGroup
        className="col-4"
        inputProps={getInputProps('address')}
        inputType="text"
        label="Bill to address"
        required
      />

      <InputGroup
        className="col-4"
        inputProps={getInputProps('address2')}
        inputType="text"
        label="Address 2"
      />

      <InputGroupWrapper
        className="col-4"
        label="Bill to city, state, zip, country"
        name={getInputName('cityStateZipCountry')}
        required
      >
        <AddressInput
          formatOptionLabel={addressFuncs.companyAddress}
          formatAddress={addressFuncs.cityStateZip}
          addressType="billing"
          context={workflow}
          includeAddressBook
          location={{
            ...values,
            country: values.country || 'us',
          }}
          name={getInputName('cityStateZipCountry')}
          onLocationChange={selectAddress}
          readOnly={disabled}
          required
        />
      </InputGroupWrapper>

      <InputGroup
        className="col-2"
        inputProps={getInputProps('contactName')}
        inputType="text"
        label="Name"
        required
      />

      <InputGroup
        className="col-3"
        inputProps={getInputProps('contactEmail')}
        inputType="text"
        label="Email"
        required={requireEmail}
      />

      <ContactPhoneInput
        className="col-3"
        disabled={disabled}
        label="Phone"
        name={getInputName('contactPhone')}
        onChange={setValues}
        required={requirePhone}
        values={values}
      />
    </>
  )
}
