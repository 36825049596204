import clsx from 'clsx'

import { SelectField } from '@fv/client-components'
import {
  type PackagingType,
  type PropsWithClassName,
  type Workflow,
} from '@fv/client-types'

import {
  usePackageOptions,
  useSecondaryPackageOptions,
} from '../../hooks/settings/useEnumOptions'
import {
  dimsFromPackageType,
  normalizePackageType,
  packageTypeFromDims,
} from './commodityUtils'
import { type SelectedPackage } from './types'

type Props = {
  value: PackagingType | ''
  width?: number
  length?: number
  name: string
  allowCustom?: boolean
  carrierCode?: string
  disabled?: boolean
  required?: boolean
  onChange: (value: SelectedPackage) => void
  includeEmpty?: boolean
  isContained?: boolean
  parentPackageType?: PackagingType
  workflow: Workflow
}

export const PackagingSelector = ({
  includeEmpty,
  allowCustom,
  carrierCode,
  className,
  disabled,
  length,
  workflow,
  name,
  onChange,
  value,
  width,
  required,
  isContained,
  parentPackageType,
}: PropsWithClassName<Props>) => {
  const packageOptions = usePackageOptions(
    workflow,
    includeEmpty ?? false,
    allowCustom ?? false,
    carrierCode,
  )
  const containableOptions = useSecondaryPackageOptions(
    workflow,
    parentPackageType ?? 'pallet',
  )

  return (
    <SelectField
      disabled={disabled}
      value={packageTypeFromDims(value, workflow, { length, width })}
      name={name}
      options={isContained ? containableOptions : packageOptions}
      className={clsx(className, 'form-control--select', 'form-control')}
      required={required}
      onChange={el => {
        const type = el.currentTarget.value as PackagingType
        // TODO  do we need width/length to be strings here?
        const dims = dimsFromPackageType(type)

        onChange({
          type: normalizePackageType(type),
          length: dims.length ?? length?.toString(),
          width: dims.width ?? width?.toString(),
          lengthNum: dims.length !== undefined ? Number(dims.length) : length,
          widthNum: dims.width !== undefined ? Number(dims.width) : width,
        })
      }}
    />
  )
}
