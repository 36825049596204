import { FvLinkButton } from '@fv/client-components'
import { type PropsWithLoad } from '@fv/client-types'
import { formatPhone } from '@fv/models/core'

import { quoteFuncs } from '../../features/quote/quoteFuncs'
import { type CarrierPublicSettings } from '../../hooks/useCarrierPublicSettings'

type CancelationMessageProps = PropsWithLoad<{
  carrierSettings: CarrierPublicSettings
}>

const PhoneLink = ({ phone }: { phone?: string }) => {
  if (!phone) return null
  return <FvLinkButton href={`tel:${phone}`}>{formatPhone(phone)}</FvLinkButton>
}

export const CancelationMessage = ({
  load,
  carrierSettings,
}: CancelationMessageProps) => {
  const { cancel, selectedQuote } = load
  const carrier =
    selectedQuote?.providerName ??
    selectedQuote?.assetCarrierName ??
    'the carrier'

  if (!load.schedulePickup) {
    return (
      <div>
        This load was marked as "Print BOL Only". If you have since scheduled a
        pickup with {carrier}, you will need to contact them to request a
        cancellation. Your pickup has been marked as canceled in Freightview.
      </div>
    )
  }

  if (cancel?.mode === 'api') {
    return <div>This shipment has been canceled and will not be picked up.</div>
  }

  if (quoteFuncs.isSpotWorkflow(selectedQuote)) {
    return (
      <div>
        A notification has been sent to the carrier informing them of the
        cancelation. Your pickup has been marked canceled in Freightview.
      </div>
    )
  }

  if (cancel?.mode === 'email') {
    return (
      <div>
        We sent an email to {carrierSettings?.cancelEmail || carrier} asking
        them to cancel the pickup. Your pickup has been marked as canceled in
        Freightview.
      </div>
    )
  }

  return (
    <div>
      Contact the carrier {carrierSettings?.cancelPhone && 'at '}
      <PhoneLink phone={carrierSettings?.cancelPhone} /> to cancel this
      shipment. It is marked as canceled in Freightview, but {carrier} is still
      planning to pick it up.
    </div>
  )
}
