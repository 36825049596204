import { FvButton } from '@fv/client-components'

import { useDeleteLoadDocument } from './mutations'

type Props = {
  documentId?: string
  loadId: string
}

const DeleteDocumentButton = ({ documentId, loadId }: Props) => {
  const deleteDocument = useDeleteLoadDocument(loadId, documentId)

  function onClick() {
    if (deleteDocument.isLoading) return
    if (window.confirm('Are you sure you want to delete this document?')) {
      deleteDocument.mutate()
    }
  }

  return (
    <FvButton
      className="ml-2"
      onClick={onClick}
      icon={deleteDocument.isLoading ? 'spinner' : 'trash'}
      iconClass="color-warning"
    />
  )
}

export default DeleteDocumentButton
