import { Mode } from './common'

export const legacyParcelPackageTypes = [
  'FedEx-envelope',
  'FedEx-pak',
  'FedEx-box',
  'FedEx-small-box',
  'FedEx-medium-box',
  'FedEx-large-box',
  'FedEx-extra-large-box',
  'FedEx-tube',
  'FedEx-10kg-box',
  'FedEx-25kg-box',
  'UPS-letter',
  'UPS-tube',
  'UPS-pak',
  'UPS-express-box-small',
  'UPS-express-box-medium',
  'UPS-express-box-large',
  'UPS-10kg-box',
  'UPS-25kg-box',
] as const
export type LegacyParcelPackageType = (typeof legacyParcelPackageTypes)[number]

export const legacyStandardPackageTypes = [
  'Bags',
  'Bales',
  'Baskets',
  'Boxes',
  'Bundles',
  'Carpets',
  'Cases',
  'Coils',
  'Crates',
  'Cylinders',
  'Drums',
  'Jerricans',
  'Kegs',
  'Pails',
  'Pallets_120x100',
  'Pallets_120x120',
  'Pallets_120x80',
  'Pallets_48x40',
  'Pallets_48x48',
  'Pallets_60x48',
  'Pallets_other',
  'Pieces',
  'Reels',
  'Rolls',
  'Slipsheets',
  'Totes',
  'TubesPipes',
  'Unit',
  'Custom',
  ...legacyParcelPackageTypes,
] as const
export const legacyFluidPackageTypes = ['gallons', 'liters', 'ounces'] as const
export type LegacyStandardPackageType =
  (typeof legacyStandardPackageTypes)[number]
export type LegacyFluidPackageType = (typeof legacyFluidPackageTypes)[number]
export type LegacyPackageType =
  | LegacyStandardPackageType
  | LegacyFluidPackageType
  | LegacyParcelPackageType

const PALLET_MAP: Array<[number, number, LegacyPackageType]> = [
  [48, 48, 'Pallets_48x48'],
  [48, 40, 'Pallets_48x40'],
  [60, 48, 'Pallets_60x48'],
]

export const PackageTypeMapper = {
  legacyToCore(packageType?: LegacyPackageType | string): PackagingType {
    switch (packageType) {
      case 'Bags':
        return 'bag'
      case 'Bales':
        return 'bale'
      case 'Baskets':
        return 'basket'
      case 'Boxes':
        return 'box'
      case 'Bundles':
        return 'bundle'
      case 'Carpets':
        return 'carpet'
      case 'Cases':
        return 'case'
      case 'Coils':
        return 'coil'
      case 'Crates':
        return 'crate'
      case 'Cylinders':
        return 'cylinder'
      case 'Drums':
        return 'drum'
      case 'gallons':
        return 'gallon'
      case 'Jerricans':
        return 'jerrican'
      case 'Kegs':
        return 'keg'
      case 'liters':
        return 'liter'
      case 'ounces':
        return 'ounce'
      case 'Pails':
        return 'pail'
      case 'Pallets_120x100':
        return 'pallet'
      case 'Pallets_120x120':
        return 'pallet'
      case 'Pallets_120x80':
        return 'pallet'
      case 'Pallets_48x40':
        return 'pallet'
      case 'Pallets_48x48':
        return 'pallet'
      case 'Pallets_60x48':
        return 'pallet'
      case 'Pallets_other':
        return 'pallet'
      case 'Pieces':
        return 'piece'
      case 'Reels':
        return 'reel'
      case 'Rolls':
        return 'roll'
      case 'Slipsheets':
        return 'slipsheet'
      case 'Totes':
        return 'tote'
      case 'Tubes/Pipes':
        return 'tube'
      case 'TubesPipes':
        return 'tube'
      case 'Unit':
        return 'unit'
      case 'Custom':
        return 'custom'
      case 'FedEx-envelope':
        return 'fedex-envelope'
      case 'FedEx-pak':
        return 'fedex-pak'
      case 'FedEx-box':
        return 'fedex-box'
      case 'FedEx-small-box':
        return 'fedex-small-box'
      case 'FedEx-medium-box':
        return 'fedex-medium-box'
      case 'FedEx-large-box':
        return 'fedex-large-box'
      case 'FedEx-extra-large-box':
        return 'fedex-extra-large-box'
      case 'FedEx-tube':
        return 'fedex-tube'
      case 'FedEx-10kg-box':
        return 'fedex-10kg-box'
      case 'FedEx-25kg-box':
        return 'fedex-25kg-box'
      case 'UPS-letter':
        return 'ups-letter'
      case 'UPS-tube':
        return 'ups-tube'
      case 'UPS-pak':
        return 'ups-pak'
      case 'UPS-express-box-small':
        return 'ups-express-box-small'
      case 'UPS-express-box-medium':
        return 'ups-express-box-medium'
      case 'UPS-express-box-large':
        return 'ups-express-box-large'
      case 'UPS-10kg-box':
        return 'ups-10kg-box'
      case 'UPS-25kg-box':
        return 'ups-25kg-box'
      default:
        return 'piece'
    }
  },

  coreToLegacy(
    packageType?: PackagingType,
    dims?: { length?: number; width?: number },
  ): LegacyPackageType {
    switch (packageType) {
      case 'bag':
        return 'Bags'
      case 'bale':
        return 'Bales'
      case 'basket':
        return 'Baskets'
      case 'box':
        return 'Boxes'
      case 'bundle':
        return 'Bundles'
      case 'carpet':
        return 'Carpets'
      case 'case':
        return 'Cases'
      case 'coil':
        return 'Coils'
      case 'crate':
        return 'Crates'
      case 'custom':
        return 'Custom'
      case 'cylinder':
        return 'Cylinders'
      case 'drum':
        return 'Drums'
      case 'gallon':
        return 'gallons'
      case 'jerrican':
        return 'Jerricans'
      case 'keg':
        return 'Kegs'
      case 'liter':
        return 'liters'
      case 'ounce':
        return 'ounces'
      case 'pail':
        return 'Pails'
      case 'pallet': {
        const sizedPallet = PALLET_MAP.find(
          ([length, width]) => length === dims?.length && width === dims?.width,
        )?.[2]

        return sizedPallet ?? 'Pallets_other'
      }
      case 'piece':
        return 'Pieces'
      case 'reel':
        return 'Reels'
      case 'roll':
        return 'Rolls'
      case 'slipsheet':
        return 'Slipsheets'
      case 'tote':
        return 'Totes'
      case 'tube':
        return 'TubesPipes'
      case 'unit':
        return 'Unit'
      case 'package':
        return 'Custom'
      case 'fedex-envelope':
        return 'FedEx-envelope'
      case 'fedex-pak':
        return 'FedEx-pak'
      case 'fedex-box':
        return 'FedEx-box'
      case 'fedex-small-box':
        return 'FedEx-small-box'
      case 'fedex-medium-box':
        return 'FedEx-medium-box'
      case 'fedex-large-box':
        return 'FedEx-large-box'
      case 'fedex-extra-large-box':
        return 'FedEx-extra-large-box'
      case 'fedex-tube':
        return 'FedEx-tube'
      case 'fedex-10kg-box':
        return 'FedEx-10kg-box'
      case 'fedex-25kg-box':
        return 'FedEx-25kg-box'
      case 'ups-letter':
        return 'UPS-letter'
      case 'ups-tube':
        return 'UPS-tube'
      case 'ups-pak':
        return 'UPS-pak'
      case 'ups-express-box-small':
        return 'UPS-express-box-small'
      case 'ups-express-box-medium':
        return 'UPS-express-box-medium'
      case 'ups-express-box-large':
        return 'UPS-express-box-large'
      case 'ups-10kg-box':
        return 'UPS-10kg-box'
      case 'ups-25kg-box':
        return 'UPS-25kg-box'
      default:
        return 'Pieces'
    }
  },
}

const packagingTypesConst = [
  {
    key: 'bag',
    name: 'Bag',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'bale',
    name: 'Bale',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'barrel',
    name: 'Barrel',
    modes: ['truckload'],
  },
  {
    key: 'basket',
    name: 'Basket',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'beam',
    name: 'Beam',
    modes: ['truckload'],
  },
  {
    key: 'bin',
    name: 'Bin',
    modes: ['truckload'],
  },
  {
    key: 'box',
    name: 'Box',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'bucket',
    name: 'Bucket',
    modes: ['truckload'],
  },
  {
    key: 'bulkhead',
    name: 'Bulkhead',
    modes: ['truckload'],
  },
  {
    key: 'bulkfloor',
    name: 'Bulklfoor-loaded',
    modes: ['truckload'],
  },
  {
    key: 'bundle',
    name: 'Bundle',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'can',
    name: 'Can',
    modes: ['truckload'],
  },
  {
    key: 'carboy',
    name: 'Carboy',
    modes: ['truckload'],
  },
  {
    key: 'carpet',
    name: 'Carpet',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'carton',
    name: 'Carton',
    modes: ['truckload'],
  },
  {
    key: 'case',
    name: 'Case',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'cask',
    name: 'Cask',
    modes: ['truckload'],
  },
  {
    key: 'chest',
    name: 'Chest',
    modes: ['truckload'],
  },
  {
    key: 'coil',
    name: 'Coil',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'container',
    name: 'Container',
    modes: ['truckload'],
  },
  {
    key: 'crate',
    name: 'Crate',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'custom',
    name: 'Custom Packaging',
    modes: ['parcel'],
  },
  {
    key: 'cylinder',
    name: 'Cylinder',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    containsFluid: true,
    isContainer: true,
  },
  {
    key: 'drum',
    name: 'Drum',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    containsFluid: true,
    isContainer: true,
  },
  {
    key: 'fedex-envelope',
    name: 'FedEx® Envelope',
    modes: ['parcel'],
  },
  {
    key: 'fedex-pak',
    name: 'FedEx® Pak',
    modes: ['parcel'],
  },
  {
    key: 'fedex-box',
    name: 'FedEx® Box',
    modes: ['parcel'],
  },
  {
    key: 'fedex-small-box',
    name: 'FedEx® Small Box',
    modes: ['parcel'],
  },
  {
    key: 'fedex-medium-box',
    name: 'FedEx® Medium Box',
    modes: ['parcel'],
  },
  {
    key: 'fedex-large-box',
    name: 'FedEx® Large Box',
    modes: ['parcel'],
  },
  {
    key: 'fedex-extra-large-box',
    name: 'FedEx® Extra Large Box',
    modes: ['parcel'],
  },
  {
    key: 'fedex-tube',
    name: 'FedEx® Tube',
    modes: ['parcel'],
  },
  {
    key: 'fedex-10kg-box',
    name: 'FedEx® 10kg Box',
    modes: ['parcel'],
  },
  {
    key: 'fedex-25kg-box',
    name: 'FedEx® 25kg Box',
    modes: ['parcel'],
  },
  {
    key: 'firkin',
    name: 'Firkin',
    modes: ['truckload'],
  },
  {
    key: 'gallon',
    name: 'Gallon',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isFluid: true,
  },
  {
    key: 'gaylord',
    name: 'Gaylord',
    modes: ['truckload'],
  },
  {
    key: 'hamper',
    name: 'Hamper',
    modes: ['truckload'],
  },
  {
    key: 'hogshead',
    name: 'Hogshead',
    modes: ['truckload'],
  },
  {
    key: 'jar',
    name: 'Jar',
    modes: ['truckload'],
  },
  {
    key: 'jerrican',
    name: 'Jerrican',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    containsFluid: true,
    isContainer: true,
  },
  {
    key: 'keg',
    name: 'Keg',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    containsFluid: true,
    isContainer: true,
  },
  {
    key: 'kit',
    name: 'Kit',
    modes: ['truckload'],
  },
  {
    key: 'liter',
    name: 'Liter',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isFluid: true,
  },
  {
    key: 'loose',
    name: 'Loose',
    modes: ['truckload'],
  },
  {
    key: 'ounce',
    name: 'Ounce',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isFluid: true,
  },
  {
    key: 'package',
    name: 'Package',
    modes: ['truckload'],
  },
  {
    key: 'pail',
    name: 'Pail',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    containsFluid: true,
    isContainer: true,
  },
  {
    key: 'pallet',
    name: 'Pallet',
    modes: ['ltl', 'truckload'],
    isContainer: true,
  },
  {
    key: 'piece',
    name: 'Piece',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'platform',
    name: 'Platform',
    modes: ['truckload'],
  },
  {
    key: 'rack',
    name: 'Rack',
    modes: ['truckload'],
  },
  {
    key: 'reel',
    name: 'Reel',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'roll',
    name: 'Roll',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'skid',
    name: 'Skid',
    modes: ['truckload'],
  },
  {
    key: 'slipsheet',
    name: 'Slip Sheet',
    modes: ['truckload'],
  },
  {
    key: 'spool',
    name: 'Spool',
    modes: ['truckload'],
  },
  {
    key: 'stack',
    name: 'Stack',
    modes: ['truckload'],
  },
  {
    key: 'tank',
    name: 'Tank',
    modes: ['truckload'],
  },
  {
    key: 'tote',
    name: 'Tote',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'trunk',
    name: 'Trunk',
    modes: ['truckload'],
  },
  {
    key: 'tub',
    name: 'Tub',
    modes: ['truckload'],
  },
  {
    key: 'tube',
    name: 'Tube/Pipe',
    modes: ['ltl', 'truckload'],
    canBeContained: true,
    isContainer: true,
  },
  {
    key: 'unit',
    name: 'Unit',
    modes: ['ltl', 'truckload'],
    isContainer: true,
  },
  {
    key: 'ups-letter',
    name: 'UPS Letter',
    modes: ['parcel'],
  },
  {
    key: 'ups-tube',
    name: 'UPS Tube',
    modes: ['parcel'],
  },
  {
    key: 'ups-pak',
    name: 'UPS Pak',
    modes: ['parcel'],
  },
  {
    key: 'ups-express-box-small',
    name: 'UPS Express Box Small',
    modes: ['parcel'],
  },
  {
    key: 'ups-express-box-medium',
    name: 'UPS Express Box Medium',
    modes: ['parcel'],
  },
  {
    key: 'ups-express-box-large',
    name: 'UPS Express Box Large',
    modes: ['parcel'],
  },
  {
    key: 'ups-10kg-box',
    name: 'UPS 10kg Box',
    modes: ['parcel'],
  },
  {
    key: 'ups-25kg-box',
    name: 'UPS 25kg Box',
    modes: ['parcel'],
  },
] as const

export type PackagingType = (typeof packagingTypesConst)[number]['key']

// workaround for strongly typed consts- https://github.com/microsoft/TypeScript/issues/31062
export const packagingTypes = packagingTypesConst as unknown as Array<
  Readonly<{
    key: PackagingType
    name: string
    modes: Mode[]
    canBeContained?: boolean
    containsFluid?: boolean
    isContainer?: boolean
    isFluid?: boolean
  }>
>
