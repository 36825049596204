import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import {
  type FullShipment,
  type MakeOptional,
  type ShipmentTag,
} from '@fv/client-types'

import { type FilterOption } from '../../components/CustomFilterForm/types'
import { apiUri, supportMessage } from '../../constants'
import { userSettingsKey } from '../../hooks/settings/useUserSettings'
import { type UserSettings } from '../../types/UserSettings'
import { type ShipmentTagOption } from './useShipmentTags'

type AccountTag = {
  _id: string
  name: string
}
type TagRequest = {
  loadId: string
  tag: string
}

type TagResponse = {
  accountTags: AccountTag[]
  loadTags: Pick<ShipmentTag, 'accountTagId'>[]
}

async function postTag({ loadId, tag }: TagRequest): Promise<TagResponse> {
  const endpoint = `${apiUri}/shipments/${loadId}/tags`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify({ tag }),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

async function deleteTag({ loadId, tag }: TagRequest): Promise<TagResponse> {
  const endpoint = `${apiUri}/shipments/${loadId}/tags/${tag}`
  const options = buildFetchOptionsWithAuth({
    method: 'DELETE',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

const removeTagFromCollection = (
  tags: ShipmentTag[] | undefined,
  { name, accountTagId }: MakeOptional<ShipmentTag, 'name'>,
): ShipmentTag[] => {
  if (!tags) return []
  return [...tags].filter(
    t =>
      (name && t.name !== name) ||
      (accountTagId && t.accountTagId !== accountTagId),
  )
}

const addTagToCollection = (
  tags: ShipmentTag[] | undefined,
  tag: ShipmentTagOption,
): ShipmentTag[] => {
  if (!tags) return []
  return [...tags].concat({
    accountTagId: tag.value ?? tag.label,
    name: tag.label,
  })
}

const useTagMutationHelpers = (loadId: string) => {
  const queryClient = useQueryClient()
  const { modifyAccountTags } = useAccountTagHelpers()

  const modifyQueryTags = (
    fn: (s: ShipmentTag[] | undefined) => ShipmentTag[],
  ) => {
    queryClient.setQueryData<FullShipment>(
      ['load', loadId],
      prev =>
        prev && {
          ...prev,
          tags: fn(prev.tags),
        },
    )
  }
  const onSuccess = ({ accountTags, loadTags }: TagResponse) => {
    modifyQueryTags(() =>
      loadTags
        .map(l => ({
          loadTag: l,
          accountTag: accountTags.find(a => a._id === l.accountTagId),
        }))
        .filter(i => !!i.accountTag)
        .map(t => ({
          accountTagId: t.accountTag?._id ?? '',
          name: t.accountTag?.name ?? '',
        })),
    )
    modifyAccountTags(
      () => accountTags?.map(a => ({ label: a.name, _id: a._id })) ?? [],
    )
  }
  return {
    modifyQueryTags,
    onSuccess,
  }
}

const useRemoveMutation = (loadId: string) => {
  const { modifyQueryTags, onSuccess } = useTagMutationHelpers(loadId)
  return useMutation(
    (option: ShipmentTagOption) => deleteTag({ loadId, tag: option.label }),
    {
      onMutate(option) {
        modifyQueryTags(tags =>
          removeTagFromCollection(tags, { accountTagId: option.value }),
        )
      },
      onError(e, optionRemoved) {
        modifyQueryTags(tags => addTagToCollection(tags, optionRemoved))
        toast.error(`Unable to remove tag, ${supportMessage}`)
      },
      onSuccess,
    },
  )
}

const useAddMutation = (loadId: string) => {
  const { modifyQueryTags, onSuccess } = useTagMutationHelpers(loadId)
  const { modifyAccountTags } = useAccountTagHelpers()
  return useMutation(
    (option: ShipmentTagOption) => postTag({ loadId, tag: option.label }),
    {
      onMutate(option) {
        modifyQueryTags(tags => addTagToCollection(tags, option))
      },
      onError(e, option) {
        modifyAccountTags(
          tags => tags?.filter(t => t._id !== option.label) || [], // remove the account tag that was added with an _id of label
        )
        toast.error(`Unable to add tag, ${supportMessage}`)
      },
      onSuccess,
    },
  )
}

export const useShipmentTagMutations = (loadId: string) => {
  const removeMutation = useRemoveMutation(loadId)
  const addMutation = useAddMutation(loadId)
  const cleanTag = (tag: ShipmentTagOption): ShipmentTagOption => {
    const { label, value } = tag
    return {
      label: label.trim(),
      value,
    }
  }
  return {
    removeTag: async (tag: ShipmentTagOption): Promise<TagResponse> =>
      removeMutation.mutateAsync(cleanTag(tag)),
    addTag: async (
      tag: ShipmentTagOption,
    ): Promise<TagResponse | undefined> => {
      const cleaned = cleanTag(tag)
      if (!cleaned.label) {
        toast.error('Invalid tag name')
        return undefined
      }
      return await addMutation.mutateAsync(cleanTag(tag))
    },
    isAdding: addMutation.isLoading,
    isRemoving: removeMutation.isLoading,
  }
}

export const useAccountTagHelpers = () => {
  const queryClient = useQueryClient()
  const modifyAccountTags = (
    fn: (t: FilterOption[] | undefined) => FilterOption[],
  ) => {
    queryClient.setQueryData<UserSettings>(
      userSettingsKey,
      p =>
        p && {
          ...p,
          tags: fn(p.tags),
        },
    )
  }
  return {
    modifyAccountTags,
  }
}
