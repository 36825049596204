import { AddressBase, CompanyAddressBase, LegacyAddressBase } from '@fv/models'

interface CompanyAddressOpts {
  includeLine2?: boolean
  dependentOnCompany?: boolean
  includeCityStateZip?: boolean
}

export const addressFuncs = {
  cityStateZip: (
    x: Partial<AddressBase | LegacyAddressBase>,
    includeCountry = true,
  ) => {
    if (!x.postalCode) return ''

    let address = `${x.city}, ${x.state} ${x.postalCode}`

    if (x.country === 'mx' && x.adminArea3) {
      address = `${x.adminArea3}, ${address}`
    }

    if (includeCountry && x.country && x.country.toLowerCase() !== 'us') {
      address += `, ${x.country.toLocaleUpperCase()}`
    }

    return address
  },
  companyAddress: (
    x: Partial<CompanyAddressBase>,
    opts: CompanyAddressOpts = {
      dependentOnCompany: true,
      includeCityStateZip: true,
    },
  ) => {
    if (!x.postalCode) return ''

    const cityStateZip = addressFuncs.cityStateZip(x, true)

    if (opts.dependentOnCompany && !x.company) {
      return cityStateZip
    }
    const parts = [
      x.company,
      x.address,
      opts.includeLine2 ? x.address2 : undefined,
      opts.includeCityStateZip !== false ? cityStateZip : undefined,
    ]

    return parts.filter(v => !!v).join(', ')
  },
  streetAddress: (x: Partial<AddressBase>) => {
    if (!x.postalCode) return ''
    const cityStateZip = addressFuncs.cityStateZip(x, false)
    return `${x.address}, ${cityStateZip}`
  },
}
