import { useState } from 'react'

import { FvButton } from '@fv/client-components'

import {
  AddressBookUploader,
  type AddressUploadCompletedState,
  UploadResults,
} from '../../addresses/AddressBookUploader'
import { AddressBookUploadField } from '../../addresses/AddressBookUploadField'
import { AddressBookUploadPreview } from '../../addresses/AddressBookUploadPreview'
import {
  type AddressBookUploadRecord,
  rowHasError,
} from '../../addresses/utils'

type Props = {
  onCancel?: () => void
  onComplete?: () => void
}
export const AddressBookUpload = ({ onCancel, onComplete }: Props) => {
  const [data, setData] = useState<Array<AddressBookUploadRecord>>([])
  const [doUpload, setDoUpload] = useState(false)
  const [uploadCompleteState, setUploadCompleteState] =
    useState<AddressUploadCompletedState>()

  const handleCancelClick = () => {
    if (
      data &&
      !window.confirm(
        `You haven't completed your upload.  Are you sure you want to cancel?`,
      )
    ) {
      return
    }
    onCancel?.()
  }

  if (doUpload) {
    return (
      <div>
        <AddressBookUploader
          uploadRecords={data ?? []}
          onComplete={setUploadCompleteState}
        >
          {uploadCompleteState && <UploadResults state={uploadCompleteState} />}
        </AddressBookUploader>
        {!!uploadCompleteState && (
          <div className="flex justify-end">
            <FvButton icon="arrow-left" onClick={onComplete} theme="default">
              Back to address book
            </FvButton>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="max-w-full">
      <AddressBookUploadField value={data} onChange={setData} />

      {!data.length && (
        <div className="flex leading-7">
          <FvButton icon="arrow-left" onClick={onComplete} theme="default">
            Back to address book
          </FvButton>
        </div>
      )}
      {!!data.length && !doUpload && (
        <div>
          <div
            style={{
              height: 'calc(100vh - 370px)',
            }}
          >
            <AddressBookUploadPreview value={data} onChange={setData} />
          </div>
          <div className="flex justify-end py-4">
            <FvButton icon="times" onClick={handleCancelClick}>
              Cancel
            </FvButton>
            <FvButton
              theme="primary"
              icon="cloud-upload"
              disabled={data.some(rowHasError)}
              onClick={() => setDoUpload(true)}
            >
              Upload
            </FvButton>
          </div>
        </div>
      )}
    </div>
  )
}
