import dayjs from 'dayjs'
import {
  ChangeEvent,
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react'

import {
  createStandardTime,
  defaultCloseTime,
  defaultOpenTime,
  militaryTimeOfDay,
  militaryToStandardTime,
  standardToMilitaryTime,
  stripMeridiem,
  timeOptions,
} from '@fv/client-core'
import { MilitaryTime, militaryTimeFormat, TimeOfDay } from '@fv/client-types'

import { FvButtonProps } from './FvButton'
import { SelectFieldProps } from './SelectField'

type DockInputName = 'opensAt' | 'closesAt'

type TimeProps = {
  opensAt?: MilitaryTime
  closesAt?: MilitaryTime
}

const defaultValues: TimeProps = {
  closesAt: defaultCloseTime,
  opensAt: defaultOpenTime,
}

export type DockTimeInputProps = {
  className?: string
  disabled?: boolean
  value: TimeProps
  name: DockInputName
  onChange: (values: TimeProps) => void
  required?: boolean
  Select: ForwardRefExoticComponent<
    SelectFieldProps & RefAttributes<HTMLSelectElement>
  >
  Button: ForwardRefExoticComponent<
    FvButtonProps & RefAttributes<HTMLButtonElement>
  >
}

export const DockTimeInput = ({
  disabled,
  value,
  name,
  onChange,
  required,
  Select,
  Button,
}: DockTimeInputProps) => {
  const timeRef = useRef<HTMLSelectElement>(null)
  let time: MilitaryTime | '' = ''
  let timeOfDay: TimeOfDay = 'AM'

  if (required || value[name]) {
    time = value[name] ?? defaultValues[name] ?? ''
    timeOfDay = militaryTimeOfDay(time as MilitaryTime)
  }

  const validateTime = useCallback(
    (values: Partial<TimeProps>) => {
      const nextLocation = { ...value, ...values }

      const { opensAt, closesAt } = nextLocation
      const startTime = dayjs(opensAt, militaryTimeFormat)
      const endTime = dayjs(closesAt, militaryTimeFormat)
      const isValid = opensAt && closesAt ? startTime.isBefore(endTime) : true // only validate if both values are present

      timeRef.current?.setCustomValidity(
        isValid ? '' : 'Ready time must be before close time.',
      )
    },
    [value],
  )

  function handleTimeChange(e: ChangeEvent<HTMLSelectElement>) {
    const values = {
      [name]: e.target.value
        ? standardToMilitaryTime(createStandardTime(e.target.value, timeOfDay))
        : undefined,
    } as TimeProps

    validateTime(values)
    onChange(values)
  }

  function toggleTimeOfDay() {
    if (disabled) return

    const hours = timeOfDay === 'AM' ? 12 : -12
    const newTime = dayjs(`${time}`, militaryTimeFormat).add(hours, 'hours')
    const values = {
      [name]: newTime.format(militaryTimeFormat),
    } as TimeProps

    validateTime(values)
    onChange(values)
  }

  useEffect(() => validateTime(value), [validateTime, value])

  const options = required ? timeOptions : ['', ...timeOptions]

  return (
    <>
      <Select
        className="form-control form-control--select"
        disabled={disabled}
        name={name}
        onChange={handleTimeChange}
        options={options}
        ref={timeRef}
        required={required}
        value={!time ? time : stripMeridiem(militaryToStandardTime(time))}
      />
      <Button onClick={toggleTimeOfDay} icon="arrows-alt-v">
        <span>{timeOfDay}</span>
      </Button>
    </>
  )
}
