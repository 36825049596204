import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { Icon, useFvNavigate } from '@fv/client-components'
import { type FullShipment, type UIQuote } from '@fv/client-types'

import { LoadSummary } from '../../components/LoadSummary/LoadSummary'
import { routes } from '../../constants'
import { useCarrierBookSettings, useQueryParam } from '../../hooks/settings'
import { useQuoteRequestQuotes } from '../../hooks/shipments'
import { useAddresses } from '../addresses/useAddress'
import { BookingForm } from './BookingForm'
import { BookingFormProvider } from './BookingFormProvider'

type Props = {
  loads: FullShipment[]
}

export const BookingPage = ({ loads }: Props) => {
  const [activeTruckIndex, setActiveTruckIndex] = useState(0)
  const navigate = useFvNavigate()
  const selected = useQueryParam('selected')

  const loadQuotes: { [keyof: string]: string } = JSON.parse(selected ?? '{}')
  const loadsToBook: Array<FullShipment & { quoteId: string }> = loads
    .filter(load => loadQuotes[load.loadId])
    .map(load => ({
      ...load,
      quoteId: loadQuotes[load.loadId],
    }))

  const addressQuery = useAddresses(loadsToBook[0]) // used inside booking form provider
  const quotesQuery = useQuoteRequestQuotes(
    loadsToBook.map(load => load.loadId),
  )

  const selectedQuotes = loadsToBook
    .map(load => {
      const { loadId, quoteId } = load
      return quotesQuery.data[loadId]?.find(q => q._id === quoteId)
    })
    .filter((q): q is UIQuote => !!q)

  const carrierIds = selectedQuotes.map(q => q.carrierId)
  const bookSettingsQuery = useCarrierBookSettings(carrierIds)
  const firstLoad = loads[0]
  const isLoading =
    quotesQuery.isLoading || bookSettingsQuery.isLoading || addressQuery.loading

  useEffect(() => {
    if (quotesQuery.isLoading) return

    if (!loads.length) {
      toast.error('No shipments found for booking.', { id: 'BookingPage' })
      return navigate('/pending')
    }

    if (!selected) {
      toast.error('No quotes selected for booking.', { id: 'BookingPage' })
      return navigate(routes.rates(firstLoad))
    }

    if (!loadsToBook.length || !selectedQuotes.length) {
      toast.error('Selected quotes not found.', { id: 'BookingPage' })
      return navigate(routes.rates(firstLoad))
    }
  }, [
    firstLoad,
    loads.length,
    loadsToBook.length,
    navigate,
    quotesQuery.isLoading,
    selected,
    selectedQuotes.length,
  ])

  if (
    !loads.length ||
    !selected ||
    !loadsToBook.length ||
    !selectedQuotes.length
  ) {
    return null
  }

  const activeLoad = loadsToBook[activeTruckIndex]
  const activeQuote = selectedQuotes.find(q => q._id === activeLoad.quoteId)

  return (
    <>
      <LoadSummary
        activeIndex={activeTruckIndex}
        equipmentType={activeQuote?.equipmentType}
        isBooking
        isEditingLoad={loadsToBook[0].status !== 'pending'}
        load={activeLoad}
        numberOfTrucks={loadsToBook.length}
        selectTruck={i => setActiveTruckIndex(i)}
      />

      {isLoading ? (
        <div style={{ margin: 'auto' }}>
          <Icon icon="spinner" size="4x" />
        </div>
      ) : (
        <BookingFormProvider
          loadsToBook={loadsToBook}
          selectedQuotes={selectedQuotes}
          settings={bookSettingsQuery.data}
          allQuotes={quotesQuery.data}
        >
          <BookingForm />
        </BookingFormProvider>
      )}
    </>
  )
}
