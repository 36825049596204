import { useQuery } from '@tanstack/react-query'
import sortBy from 'lodash/sortBy'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type HoldAtLocation } from '@fv/client-types'
import { type Country } from '@fv/models'

import { apiUri } from '../../constants'

export interface HoldAtLocationLookupDTO {
  loadId?: string
  address?: string
  address2?: string
  city?: string
  state?: string
  postalCode?: string
  country?: Country
}

async function fetchHoldAtLocations({
  loadId,
  address,
  address2,
  city,
  state,
  postalCode,
  country,
}: HoldAtLocationLookupDTO) {
  const endpoint = `${apiUri}/hold-at-locations`
  const response = await fetchJson(
    endpoint,
    buildFetchOptionsWithAuth({
      method: 'POST',
      body: JSON.stringify({
        loadId,
        address,
        address2,
        city,
        state,
        postalCode,
        country,
      }),
    }),
  )

  if (response.ok) return response.json as HoldAtLocation[]
  throw response.errorMessage
}

export function useHoldAtLocations(
  enabled: boolean,
  dto: HoldAtLocationLookupDTO,
) {
  const { loadId, ...loc } = dto
  return useQuery(
    ['hold-at-locations', loadId, JSON.stringify(loc)],
    () => fetchHoldAtLocations(dto),
    {
      enabled: enabled && !!loadId,
      select(data) {
        return sortBy(data, 'distance')
      },
    },
  )
}
