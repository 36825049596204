import YesNoToggle from '../../../components/inputs/YesNoInput'
import { AccountSettingRow } from './AccountSettingRow'
import { useAccountSettings } from './hooks'
import { useAccountSettingMutation } from './mutations'

export const CarrierEmailSuppressionForm = () => {
  const name = 'suppressLoserEmail'
  const accountSettings = useAccountSettings()
  const { updateAccountSetting } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  const disabledEmails = accountSettings.spotQuote?.disabledCarrierEmails ?? []

  return (
    <AccountSettingRow
      label="Suppress spot quote email notification to losing carriers when awarding a load"
      name={name}
    >
      <YesNoToggle
        checked={disabledEmails.includes('not-awarded')}
        name={name}
        onChange={v =>
          updateAccountSetting(
            'spotQuote.disabledCarrierEmails' as any, // The Flatten type doesn't handle array paths
            v.target.checked ? ['not-awarded'] : [],
          )
        }
      />
    </AccountSettingRow>
  )
}
