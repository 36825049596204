import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  IconButton,
  type IconProps,
  useFvNavigate,
} from '@fv/client-components'
import { type AuditStatus } from '@fv/models'

import ExportOptions from '../../components/ExportOptions'
import AuditListTab from './AuditListTab'
import { useAuditListStatus } from './queries'
import { colorSuccess } from './types'

const auditListTabs = [
  {
    icon: 'file-invoice-dollar' as IconProps['icon'],
    label: 'Outstanding',
    status: 'outstanding' as AuditStatus,
  },
  {
    icon: 'archive' as IconProps['icon'],
    iconClassName: colorSuccess,
    label: 'Accepted',
    status: 'accepted' as AuditStatus,
  },
  {
    icon: 'archive' as IconProps['icon'],
    iconClassName: 'color-warning',
    label: 'Disputed',
    status: 'disputed' as AuditStatus,
  },
  {
    icon: 'archive' as IconProps['icon'],
    label: 'Archived',
    status: 'archived' as AuditStatus,
  },
]

const AuditListHeader = () => {
  const { search } = useLocation()
  const [isExporting, setExporting] = useState(false)
  const navigate = useFvNavigate()
  const status = useAuditListStatus()
  const exportParams = new URLSearchParams(search)

  exportParams.set('auditStatus[]', status ?? '')
  exportParams.delete('status[]')

  const onClick = useCallback(
    (e: any) => {
      e.preventDefault()
      const tabStatus = e.currentTarget.id as AuditStatus

      if (tabStatus !== status) {
        const params = new URLSearchParams(search)
        params.set('status[]', tabStatus)
        navigate(`/audit?${params.toString()}`)
      }
    },
    [navigate, search, status],
  )

  return (
    <div className="divided-content divided-content--start mb-8">
      {auditListTabs.map(t => (
        <AuditListTab
          {...t}
          isActive={t.status === status}
          key={t.status}
          onClick={onClick}
        />
      ))}

      <IconButton icon="file-export" onClick={() => setExporting(true)}>
        Export list
      </IconButton>

      <ExportOptions
        closePanel={() => setExporting(false)}
        isOpen={isExporting}
        queryString={exportParams.toString()}
      />
    </div>
  )
}

export default AuditListHeader
