import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'

async function fetchUnreadCount() {
  const endpoint = `${apiUri}/notifications/unread-count`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useUnreadNotificationCount() {
  return useQuery(['unread-notification-count'], fetchUnreadCount)
}
