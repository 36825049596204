import toast from 'react-hot-toast'

import { getApiErrorText } from './api-error'

const supportMessage = 'please contact support if the issue persists.'

export function showApiError(
  prefix: string,
  error: unknown,
  pathAliases?: Record<string, string>,
): void {
  const message = getApiErrorText(error, pathAliases)

  if (!message) return

  const toastPrefix = prefix ? `${prefix}: ` : ''
  toast.error(`${toastPrefix}${message.replace(/\.$/, '')}, ${supportMessage}`)
}
