import { useEffect, useRef } from 'react'

import { FvLinkButton } from '@fv/client-components'

import { PillHeader } from '../../../components/PillHeader'
import { maxItemCount } from '../../../constants'
import { LoadItemForm } from './LoadItemForm'
import { isValidCommodity } from './loadItemHelpers'
import { useItemActions, useItemStore } from './loadItemsContext'
import { type LoadHandlingUnitFormModel } from './types'

export const InlineItemsFormWrapper = () => {
  const items = useItemStore(s => s.itemState.items)
  const disabled = useItemStore(s => s.itemState.disabled)
  const { addItem, duplicateItem, updateItem, removeItem, clearItem } =
    useItemActions()
  const canAddItem = items.length < maxItemCount
  return (
    <div>
      {items.map((item, index) => (
        <div
          className="bg-fv-beer-50 relative mb-10 flex border-[.115rem] border-[#e8e0b2] p-4 pt-10"
          key={item._id ?? `loaditemform-${index}`}
          data-cy="item-form"
        >
          <div className="form-row">
            <PillHeader className="default-container">
              <div className="divided-content divided-content--start divided-content--end">
                <span>Handling unit {index + 1}</span>
                <HazardousPillNotification item={items[index]} />
                <FvLinkButton
                  disabled={disabled}
                  icon="times"
                  onClick={() => clearItem(index)}
                  title="Clear fields"
                />
                <FvLinkButton
                  disabled={disabled || !canAddItem}
                  icon="clone"
                  onClick={() => duplicateItem(index)}
                  title="Duplicate Item"
                />
                {items.length > 1 && (
                  <FvLinkButton
                    disabled={disabled}
                    icon="trash"
                    onClick={() => removeItem(index)}
                    title="Remove item"
                  />
                )}
              </div>
            </PillHeader>
            <LoadItemForm
              value={items[index]}
              onChange={update => updateItem(index, update)}
            />
          </div>
        </div>
      ))}
      <div>
        <FvLinkButton disabled={!canAddItem} icon="plus" onClick={addItem}>
          Add another handling unit
        </FvLinkButton>
      </div>
    </div>
  )
}

type HazardousPillProps = {
  item: LoadHandlingUnitFormModel
}
const HazardousPillNotification = ({ item }: HazardousPillProps) => {
  const { setEditingContains, activateContains } = useItemActions()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.setCustomValidity('Missing Hazmat Info')
  }, [inputRef])

  if (
    !item.contains?.some(i => i.hazardous) ||
    !item.contains?.some(c => !isValidCommodity(c, 'booking'))
  ) {
    return null
  }

  // the pills are only shown in booking mode so step='booking' is safe
  const firstMissingIx = item.contains.findIndex(
    c => !isValidCommodity(c, 'booking'),
  )
  return (
    <div className="relative">
      <input
        className="pointer-events-none absolute left-0 top-0 -z-10 h-full w-full border-transparent bg-transparent"
        type="text"
        ref={inputRef}
      />
      <FvLinkButton
        icon="exclamation-triangle"
        className=""
        onClick={() => {
          setEditingContains(item)
          activateContains(firstMissingIx)
        }}
      >
        Additional Hazmat Info Required
      </FvLinkButton>
    </div>
  )
}
