import clsx from 'clsx'

import { PhoneNumberField, TextField } from '@fv/client-components'

type ContactPhoneValues = {
  contactPhone: string
  contactPhoneExt?: string
}

type Props = {
  className?: string
  disabled?: boolean
  label?: string
  name: string
  onChange: (values: ContactPhoneValues) => void
  readOnly?: boolean
  required?: boolean
  values: ContactPhoneValues
  hideExt?: boolean
}

export const ContactPhoneInput = ({
  className,
  disabled,
  label = 'Contact phone',
  name,
  onChange,
  readOnly,
  required,
  values,
  hideExt,
}: Props) => {
  const { contactPhone, contactPhoneExt } = values
  const extensionName = `${name}Ext`

  return (
    <div className={clsx('form-group flex flex-nowrap', className)}>
      <div className={hideExt ? 'col-12' : 'col-8'}>
        <label htmlFor={name} className={clsx('label', { required })}>
          {label}
        </label>
        <PhoneNumberField
          name={name}
          className="form-control"
          disabled={disabled}
          onChange={e =>
            onChange({
              contactPhone: e.target.value,
              contactPhoneExt: contactPhoneExt ?? '',
            })
          }
          readOnly={readOnly}
          required={required}
          value={contactPhone}
        />
      </div>

      {!hideExt && (
        <div className="col-4">
          <label htmlFor={extensionName} className="label">
            Ext
          </label>
          <TextField
            className="form-control border-l-0"
            name={extensionName}
            onChange={e => {
              onChange({
                contactPhone,
                contactPhoneExt: e.target.value.replace(/\D/g, ''),
              })
            }}
            pattern="\d*"
            disabled={disabled}
            readOnly={readOnly}
            value={contactPhoneExt}
          />
        </div>
      )}
    </div>
  )
}
