import { useQueryClient } from '@tanstack/react-query'

import { AccountCarrierForm } from './AccountCarrierForm'
import {
  useAccountCarrierActions,
  useAccountCarrierState,
} from './AccountCarrierFormProvider'
import { AccountCarrierLocationForm } from './LocationForm'

export const AccountCarrierEntry = () => {
  const queryClient = useQueryClient()
  const { carrierId, locationId } = useAccountCarrierState()
  const { close } = useAccountCarrierActions()

  const handleCancel = () => {
    close()
  }
  const handleSuccess = () => {
    queryClient.invalidateQueries(['account-carriers'])
    close()
  }
  if (locationId && carrierId) {
    return (
      <AccountCarrierLocationForm
        onCancel={handleCancel}
        onSuccess={handleSuccess}
      />
    )
  }

  if (carrierId) {
    return (
      <AccountCarrierForm onCancel={handleCancel} onSuccess={handleSuccess} />
    )
  }

  return null
}
