import { HandlingUnitContainsPanel } from './HandlingUnitContainsPanel'
import { InlineItemsFormWrapper } from './InlineItemsForm'
import {
  LoadItemsContext,
  useItemActions,
  useItemsState,
} from './loadItemsContext'
import { PagedItemsFormWrapper } from './PagedItemsForm'
import { type LoadItemsFormProviderProps } from './types'

type Props = LoadItemsFormProviderProps & {
  type: 'inline' | 'paged'
}
export const LoadItemsFormProvider = ({ store, type }: Props) => {
  return (
    <LoadItemsContext.Provider value={store}>
      <LoadItemsFormProviderContents type={type} />
    </LoadItemsContext.Provider>
  )
}

const LoadItemsFormProviderContents = ({ type }: Pick<Props, 'type'>) => {
  const { editingContainsUnit, items } = useItemsState()
  const { setEditingContains, updateItem, activateContains } = useItemActions()

  const handleContainsClose = (fromSave: boolean) => {
    if (
      fromSave ||
      window.confirm(
        `You have not saved your changes.  Are you sure you're finished editing commodities?`,
      )
    ) {
      setEditingContains(undefined)
      activateContains(undefined)
    }
  }

  return (
    <>
      {type === 'inline' ? (
        <InlineItemsFormWrapper />
      ) : (
        <PagedItemsFormWrapper />
      )}

      <HandlingUnitContainsPanel
        isOpen={!!editingContainsUnit}
        closePanel={() => handleContainsClose(false)}
        handlingUnit={editingContainsUnit}
        onSubmit={({ contains }) => {
          if (!editingContainsUnit) return
          const ix = items.indexOf(editingContainsUnit)
          updateItem(ix, { contains })
          handleContainsClose(true)
        }}
      />
    </>
  )
}
