import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton } from '@fv/client-components'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { supportMessage } from '../../constants'
import { newMessageId, usePostMessage } from '../../hooks/messages'
import { messageKeys } from '../../hooks/messages/useMessages'
import { type Message } from '../../hooks/messages/usePostMessage'
import { useSpotQuoteRecipients } from '../../hooks/spotQuotes'

type Props = {
  carrierId?: string
  loadId: string
  userId?: string
}

const MessageField = ({ carrierId, loadId, userId }: Props) => {
  const [message, setMessage] = useState('')
  const postMessage = usePostMessage()
  const queryClient = useQueryClient()
  const recipientsQuery = useSpotQuoteRecipients(loadId)
  const hasCarrierRecipient = recipientsQuery.data?.some(
    d => d.carrierId === carrierId,
  )

  useEffect(() => {
    setMessage('')
  }, [carrierId, userId, loadId])

  if (carrierId && !hasCarrierRecipient) return null

  function sendMessage() {
    const messageText = message.trim()
    if (postMessage.isLoading || !messageText) return

    postMessage
      .mutateAsync({
        loadId,
        messageText,
        ...(carrierId && { carrierId }),
        ...(userId && { userId }),
      })
      .catch(() => {
        toast.error(`Unable to send message, ${supportMessage}`)

        queryClient.setQueryData(
          messageKeys.load(loadId),
          (prev?: Message[]) => prev?.filter(m => m._id !== newMessageId) ?? [],
        )
      })

    setMessage('')
  }

  return (
    <InputGroup
      className=""
      inputType="text"
      inputProps={{
        name: 'message',
        value: message,
        onChange: e => {
          setMessage(e.target.value)
        },
        onKeyDown: e => {
          e.key === 'Enter' && sendMessage()
        },
      }}
      endContent={
        <InputAdornment position="end">
          <FvButton
            onClick={sendMessage}
            icon="paper-plane"
            transform="up-1"
            theme="default"
          >
            <span>Send</span>
          </FvButton>
        </InputAdornment>
      }
    />
  )
}

export default MessageField
