import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { type FullShipment } from '@fv/client-types'

import { supportMessage } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments'
import { shipperFetch } from '../../utils/shipperFetch'

export type MissedPickupDTO = {
  reason: string
  carrierResponse: string
  isRescheduled: boolean
  additionalDetails: string
  isArchived?: boolean
}

async function reportMissedPickup(loadId: string, dto: MissedPickupDTO) {
  return shipperFetch<FullShipment>(`/shipments/${loadId}/missed-pickup`, {
    body: dto,
    method: 'POST',
  })
}

export function useReportMissedPickup(loadId: string) {
  const updateCachedLoad = useUpdateCachedLoad()

  return useMutation(
    (dto: MissedPickupDTO) => reportMissedPickup(loadId, dto),
    {
      onSettled: (updatedLoad, error) => {
        if (error) {
          toast.error(`Unable to report missed pickup, ${supportMessage}`)
          return
        }
        if (updatedLoad) updateCachedLoad({ updatedLoad })
        toast.success('Missed pickup reported successfully.')
      },
    },
  )
}
