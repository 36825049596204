import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import orderBy from 'lodash/orderBy'

import { showApiError } from '@fv/client-core'
import {
  type FullShipment,
  type LocationType,
  type ShipmentLocation,
  type StopType,
} from '@fv/client-types'

import { useCreateQuoteRequest } from '../../features/quote-request/useCreateQuoteRequest'
import { defaultStopDate } from '../../utils/shipmentFuncs'

function reverseStopType(stopType: StopType): StopType {
  if (stopType === 'both') {
    return stopType
  }

  return stopType === 'pickup' ? 'delivery' : 'pickup'
}

function flipLocationType(locType: LocationType): LocationType {
  if (locType === 'stop') {
    return 'stop'
  }

  return locType === 'origin' ? 'destination' : 'origin'
}

export function reverseLocations(
  locations: ShipmentLocation[],
): ShipmentLocation[] {
  const sorted = orderBy(locations, ['sequence'], ['desc'])

  return sorted.map<ShipmentLocation>((loc, i) => {
    const type = flipLocationType(loc.type)
    const stopDate = defaultStopDate(type)

    return {
      ...loc,
      sequence: i,
      stopDate: stopDate ? dayjs.utc(stopDate).format('YYYY-MM-DD') : null,
      stopType: reverseStopType(loc.stopType),
      type,
    }
  })
}

export function useCreateReturnShipment() {
  const createQuoteRequest = useCreateQuoteRequest()

  return useMutation(async (load: FullShipment) => {
    return await createQuoteRequest.mutateAsync(
      {
        equipment: [
          {
            ...load.equipment,
            loadId: load.loadId,
            accessorials: load.equipment.accessorials ?? [],
            alternateTypes: load.equipment.alternateTypes ?? [],
            items: (load.items ?? []).map(i => ({
              ...i,
              ...(i.contains && {
                contains: i.contains.map(c => ({
                  ...c,
                  ...(!c.description && { description: i.description }),
                })),
              }),
            })),
            billTo: load.billTo,
            customsBroker: load.customsBroker,
            emergencyContact: load.emergencyContact,
          },
        ],
        isLiveLoad: true,
        locations: reverseLocations(load.locations),
        orderNumber: '',
        workflow: load.workflow,
        billTo: load.billTo,
        isFreightCollect: load.isFreightCollect ?? false,
      },
      {
        onError: err => {
          showApiError('Unable to create return shipment', err)
        },
      },
    )
  })
}
