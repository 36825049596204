import {
  type AccountSettingsKey,
  type ShippingLabelSize,
} from '@fv/client-types'
import { type DistanceProviderVersion } from '@fv/models'

export type AccountSettingsFormItem = {
  label: string
  setting: AccountSettingsKey
}

export type ShippingOptionConfig = {
  label: string
  size: ShippingLabelSize
}

export type DistanceProviderConfig = {
  name: string
  id: string
  versions: DistanceProviderVersion[]
}

export const defaultDistanceProvider = 'pc-miler'

export const findProvider = (
  providers: DistanceProviderConfig[],
  id?: string,
) =>
  providers.find(p => (id && p.id === id) || p.id === defaultDistanceProvider)
