import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

type CtnProps = PropsWithChildren<
  PropsWithClassName<{
    arrow?: boolean
  }>
>
export const AuditCardCtn = ({ className, children, arrow }: CtnProps) => {
  return (
    <div
      className={clsx(
        'relative p-4 border border-fv-gray mt-4 bg-fv-gray-50 flex',
        className,
      )}
    >
      {arrow && (
        <div className="bg-fv-gray-50 left-8 rotate-45 absolute w-4 h-4 -top-[calc(.5rem+1px)] border-fv-gray border border-r-0 border-b-0" />
      )}
      {children}
    </div>
  )
}

type Props = PropsWithChildren<PropsWithClassName>

export const AuditCardArrowCtn = ({ children, className }: Props) => {
  return (
    <AuditCardCtn arrow className={clsx('flex-1 gap-y-4', className)}>
      {children}
    </AuditCardCtn>
  )
}
