import { useEffect } from 'react'

import { type PropsWithClassName, type Workflow } from '@fv/client-types'

import { SelectField } from '../inputs/SelectField'
import { TypeaheadField, type TypeaheadOption } from '../inputs/TypeaheadField'
import { type CarrierService, useCarrierServiceTypes } from './hooks'

type Props = {
  disabled?: boolean
  name: string
  onChange: (v: CarrierService | null) => void
  required?: boolean
  scac: string | undefined
  type: 'select' | 'typeahead'
  value: string | undefined
  workflow?: Workflow
}

export const CarrierServiceTypeahead = ({
  className,
  disabled,
  name,
  onChange,
  scac,
  required,
  type,
  value,
  workflow,
}: PropsWithClassName<Props>) => {
  const { isLoading, data: serviceTypes } = useCarrierServiceTypes(
    scac,
    workflow,
  )

  const options =
    serviceTypes?.map<TypeaheadOption<CarrierService>>(t => ({
      label: t.name,
      value: t,
    })) ?? []

  useEffect(() => {
    if (!value && serviceTypes?.[0]) {
      onChange(serviceTypes[0])
    }
  }, [serviceTypes, value, onChange])

  if (isLoading || type === 'typeahead') {
    return (
      <TypeaheadField
        className={className}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        onInputChange={e => onChange(!e ? null : { id: e, name: e })}
        name={name}
        value={value}
        isLoading={!disabled && isLoading}
        required={required}
      />
    )
  }

  if (type === 'select') {
    return (
      <SelectField
        name={name}
        disabled={disabled}
        className={className}
        required={required}
        value={value}
        options={options.map(o => ({ text: o.label, value: o.value.id }))}
        onChange={e =>
          onChange(serviceTypes?.find(t => t.id === e.target.value) ?? null)
        }
      />
    )
  }

  return null
}
