import { useMemo } from 'react'

import { useSpotQuoteRecipients } from '../spotQuotes'
import { type QuoteRequestResponse } from './useQuoteRequestResponses'

export function useInactiveResponses(loadId?: string): QuoteRequestResponse[] {
  const recipientsQuery = useSpotQuoteRecipients(loadId)

  return useMemo(() => {
    const recipients = recipientsQuery.data ?? []

    return recipients
      .filter((r, i) => {
        if (!r.carrierId) return true
        if (r.hasResponded && !r.hasDeclined) return false

        // Remove duplicates if carrier has multiple users
        const firstIndex = recipients.findIndex(
          ({ carrierId }) => carrierId === r.carrierId,
        )

        return i === firstIndex
      })
      .map(c => ({
        ...c,
        id: c.carrierId || c.userId,
        name: c.carrierName || c.email || c.name || 'Unknown',
      }))
  }, [recipientsQuery.data])
}
