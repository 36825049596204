import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth } from '@fv/client-core'

async function fetchFile(uri: string): Promise<Blob> {
  const options = buildFetchOptionsWithAuth()
  const response = await fetch(uri, options)

  return await response.blob()
}

export function useAuthenticatedFileDownload(uri: string, enabled: boolean) {
  const query = useQuery(['file-download', uri], () => fetchFile(uri), {
    enabled,
  })

  return query
}
