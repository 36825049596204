import { type ChangeEventHandler, type MouseEvent } from 'react'

import { Icon } from '@fv/client-components'
import {
  type LocationAccessorialDTO,
  type LocationAccessorialType,
} from '@fv/models'

import { useAccessorialOptions } from '../../hooks/settings'
import {
  apptRequiredKey,
  getApptTimes,
  type LocationAccessorialWithMilitaryTimes,
} from './AppointmentConfig'
import CheckboxField from './CheckboxField'

type LocationAccessorialsProps = {
  accessorials: LocationAccessorialDTO[]
  className?: string
  inputNamePrefix?: string
  onChange: ChangeEventHandler<HTMLInputElement>
  openDatePicker: (e: MouseEvent<HTMLDivElement>) => void
}

const LocationAccessorials = ({
  accessorials,
  className,
  inputNamePrefix,
  onChange,
  openDatePicker,
}: LocationAccessorialsProps) => {
  const accessorialOptions = useAccessorialOptions('location')

  return (
    <div className={className}>
      {!accessorialOptions.length && <Icon icon="spinner" />}

      {accessorialOptions.map(o => {
        const accKey = o.key as LocationAccessorialType
        const accDetails = accessorials.find(a => a.key === accKey)
        const isAppointment = accKey === apptRequiredKey
        const name = `${inputNamePrefix ?? ''}${accKey}`

        return (
          <CheckboxField
            checked={!!accDetails}
            hintText={
              !!accDetails && isAppointment
                ? getApptTimes(
                    accDetails as LocationAccessorialWithMilitaryTimes,
                  )
                : ''
            }
            key={accKey}
            label={o.name}
            name={name}
            onChange={onChange}
            onHintClick={openDatePicker}
            value={accKey}
          />
        )
      })}
    </div>
  )
}

export default LocationAccessorials
