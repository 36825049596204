import { useMemo } from 'react'

import { useUserSettings } from '../../hooks/settings'
import { alphabeticalSorter } from '../../utils/alphabeticalSorter'
import { type SavedViewPageName } from '../shipment-list/hooks/types'

export function useSavedViews(pageName: SavedViewPageName) {
  const settingsQuery = useUserSettings()

  return useMemo(() => {
    const views = settingsQuery.data?.savedViews[pageName]

    if (!views) return []

    return views.slice().sort(alphabeticalSorter('name'))
  }, [pageName, settingsQuery.data?.savedViews])
}

export function useHiddenViews() {
  const settingsQuery = useUserSettings()
  return settingsQuery.data?.savedViews?.hidden ?? []
}
