import { useQuery } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { type CustomsBroker } from './brokerTypes'

async function fetchCustomsBrokers() {
  const endpoint = `${apiUri}/addresses/customs-brokers`
  const options = buildFetchOptionsWithAuth()
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json as CustomsBroker[]
  throw response.errorMessage
}

export function useCustomsBrokers(enabled = true) {
  return useQuery(['customs-brokers'], fetchCustomsBrokers, {
    enabled,
  })
}

export type CustomsBrokerByLocation = {
  addressBookId?: string
  postalCode: string
}

async function fetchDefaultBroker({
  addressBookId,
  postalCode,
}: CustomsBrokerByLocation) {
  const endpoint = `${apiUri}/addresses/location-customs-broker`
  const options = buildFetchOptionsWithAuth({
    query: {
      postalCode,
      addressBookId,
    },
  })
  const response = await fetchJson(endpoint, options)

  if (response.ok) return response.json as CustomsBroker | null
  throw response.errorMessage
}

export function useCustomsBrokerByLocation(
  args: CustomsBrokerByLocation,
  enabled = !!args.addressBookId || !!args.postalCode,
) {
  return useQuery(
    ['location-customs-broker', args.addressBookId || args.postalCode],
    () => fetchDefaultBroker(args),
    {
      enabled,
      refetchOnMount: q => (q.state.data === null ? 'always' : true),
    },
  )
}
