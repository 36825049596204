import { type PropsWithChildren, useState } from 'react'

import InfoBox from '../../components/InfoBox'
import {
  MutationProgressBar,
  type MutationProgressResult,
} from '../../components/MutationProgressBar'
import { type FormAddress } from './types'
import { useCreateAddress } from './useCreateAddress'
import {
  type AddressBookUploadRecord,
  buildCreateModel,
  rowHasData,
} from './utils'

const errorPathAliases: Partial<Record<keyof AddressBookUploadRecord, string>> =
  {
    contactPhone: 'Contact phone',
    contactEmail: 'Contact email',
    dockHoursEnd: 'Dock hours end',
    dockHoursStart: 'Dock hours start',
    bolFollowers: 'Shared with',
    postalCode: 'Postal code',
  }

export type AddressUploadCompletedState = {
  results: MutationProgressResult[]
  models: FormAddress[]
}
type UploaderProps = PropsWithChildren<{
  uploadRecords: AddressBookUploadRecord[]
  onComplete: (state: AddressUploadCompletedState) => void
}>
export const AddressBookUploader = ({
  uploadRecords,
  onComplete,
  children,
}: UploaderProps) => {
  const createAddress = useCreateAddress(false)
  // purposely caching this in the state to prevent reuploads in the middle of processing
  const [uploadData] = useState(
    uploadRecords.filter(rowHasData).map(buildCreateModel),
  )

  return (
    <>
      <div className="bg-fv-beer-50 border-fv-beer-700 mb-4 rounded-sm border p-4">
        <div className="mb-2">Uploading address book entries...</div>
        <MutationProgressBar
          data={uploadData}
          mutation={createAddress}
          onComplete={results => {
            onComplete({
              results,
              models: uploadData,
            })
          }}
          pathAliases={errorPathAliases}
        />
      </div>
      <InfoBox gradient custom>
        {children}
      </InfoBox>
    </>
  )
}

type UploadResultProps = {
  state: AddressUploadCompletedState
}
export const UploadResults = ({ state }: UploadResultProps) => {
  const { results, models } = state
  if (!results) return null

  const renderData = (ix: number) => {
    const entry = models[ix]
    return `${entry.address} ${entry.city}, ${entry.state} ${entry.postalCode}`
  }

  return (
    <>
      <p className="py-3">
        {results.filter(r => r.success).length} address book entries
        successfully uploaded.
      </p>

      {results.some(r => !r.success) && (
        <div className="border-t-fv-gray border-t pt-2">
          <p>The following entries failed to upload:</p>
          <ul>
            {results
              .filter(r => !r.success)
              .map(r => (
                <li key={r.index} className="py-2">
                  Row {r.index + 1}: {renderData(r.index)}.{' '}
                  {r.error ? `Error: ${r.error}` : ''}
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  )
}
