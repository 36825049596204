import { useEffect, useState } from 'react'

import { SliderPanel } from '@fv/client-components'

import { List } from '../../../components/List'
import { Loading } from '../../../components/Loading'
import {
  AddressBookList,
  AddressBookListSearch,
} from '../../addresses/AddressBook'
import { AddressForm } from '../../addresses/AddressForm'
import { useAddressBook } from '../../addresses/useAddressBook'
import { useAddressBookEntry } from '../../addresses/useAddressBookAddresses'
import { SettingsPageLayout } from '../SettingsPageLayout'
import { AddressBookUpload } from './AddressBookUpload'

export const AddressBookPage = () => {
  const { editingId, edit, view, setConfig } = useAddressBook()

  useEffect(() => {
    setConfig({ context: 'address-book' })
  }, [setConfig])

  const editAddressQuery = useAddressBookEntry(editingId)
  const [isUploading, setIsUploading] = useState(false)

  return (
    <SettingsPageLayout>
      {isUploading && (
        <AddressBookUpload
          onCancel={() => setIsUploading(false)}
          onComplete={() => setIsUploading(false)}
        />
      )}
      {!isUploading && (
        <>
          <div className="bg-fv-gray-100 pb-4">
            <AddressBookListSearch onUploadClick={() => setIsUploading(true)} />
          </div>
          <List className="flex-1 overflow-auto px-6 pt-4 bg-white border border-fv-gray">
            <AddressBookList inline canRemove />
          </List>
          <SliderPanel
            isOpen={!!editingId || view === 'form'}
            closePanel={() => {
              edit(undefined)
            }}
          >
            {editAddressQuery.isLoading && !!editingId ? (
              <Loading />
            ) : (
              <AddressForm
                context={'address-book'}
                initialValues={editAddressQuery.data}
              />
            )}
          </SliderPanel>
        </>
      )}
    </SettingsPageLayout>
  )
}
