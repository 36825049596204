import clsx from 'clsx'
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useRef,
} from 'react'

type Props = ComponentPropsWithoutRef<'input'> & {
  label?: ReactNode
  labelClassName?: string
  name: string
}

export const CheckboxField = forwardRef(
  (
    { className, label, labelClassName, name, ...rest }: Props,
    ref?: ForwardedRef<HTMLInputElement | null>,
  ) => {
    const localRef = useRef<HTMLInputElement | null>(null)

    const setRef = useCallback(
      (element: HTMLInputElement | null) => {
        localRef.current = element
        if (!ref) return
        if (typeof ref === 'function') ref(element)
        else ref.current = element
      },
      [ref],
    )

    return (
      <div className={clsx('checkbox', className)}>
        <input
          className="checkbox__input"
          id={name}
          name={name}
          ref={setRef}
          type="checkbox"
          {...rest}
        />

        <label className="checkbox__display" htmlFor={name} />

        {label && (
          <label
            className={clsx('checkbox__label', labelClassName)}
            htmlFor={name}
          >
            {label}
          </label>
        )}
      </div>
    )
  },
)
