import dayjs from 'dayjs'

import { emailRegExpMatcher, militaryToStandardTime } from '@fv/client-core'
import {
  type BaseClientAddress,
  type ClientAddressLookupResult,
  militaryTimeFormat,
  type StandardTime,
  type TimeOfDay,
  type Workflow,
} from '@fv/client-types'
import { type Country } from '@fv/models'

import { type FormLocation } from '../../types/FormLocation'
import { type FormAddress } from './types'

export type PartialLocation = Partial<
  Omit<BaseClientAddress, 'lat' | 'lng' | 'timezone'>
>
export type AddressWithCompany = PartialLocation & { company?: string }

export const addressMapper = {
  dtoToFormLocation: (
    location: ClientAddressLookupResult | null,
    workflow: Workflow,
  ): Partial<FormLocation> => {
    const {
      accountLocationId,
      address = '',
      address2,
      city = '',
      company,
      contactEmail,
      contactName,
      contactPhone,
      country,
      dockHoursEnd,
      dockHoursStart,
      instructions,
      lat = 0,
      lng = 0,
      mappedAccessorials,
      postalCode = '',
      state = '',
      timezone = '',
      shipType = '',
    } = location ?? {}

    const closeTime = dockHoursEnd && dayjs(dockHoursEnd, militaryTimeFormat)
    const readyTime =
      dockHoursStart && dayjs(dockHoursStart, militaryTimeFormat)

    return {
      accessorials: mappedAccessorials?.[workflow] ?? [],
      address,
      address2: address2 || '',
      city,
      country: (country?.toLowerCase() || 'us') as Country,
      lat,
      lng,
      postalCode,
      shipType,
      state,
      timezone,
      ...(accountLocationId && { accountLocationId }),
      ...(closeTime && {
        closeTime: closeTime.format('h:mm'),
        closeTimeOfDay: closeTime.format('A') as TimeOfDay,
      }),
      ...(company && { company }),
      ...(contactEmail && { contactEmail }),
      ...(contactName && { contactName }),
      ...(contactPhone && {
        contactPhone,
        contactPhoneExt: '',
      }),
      ...(instructions && { instructions }),
      ...(readyTime && {
        readyTime: readyTime.format('h:mm'),
        readyTimeOfDay: readyTime.format('A') as TimeOfDay,
      }),
    }
  },
  dtoToFormStop: (
    location: ClientAddressLookupResult | null,
    workflow: Workflow,
  ): Partial<FormLocation> => {
    return {
      accessorials: location?.mappedAccessorials?.[workflow] ?? [],
      accountLocationId: location?.accountLocationId ?? '',
      address: location?.address ?? '',
      address2: location?.address2 ?? '',
      adminArea3: location?.adminArea3,
      addressBookId: location?._id ?? '',
      city: location?.city,
      closesAt: location?.dockHoursEnd,
      company: location?.company ?? '',
      contactEmail: location?.contactEmail ?? '',
      contactName: location?.contactName ?? '',
      contactPhone: location?.contactPhone ?? '',
      country: location?.country,
      instructions: location?.instructions ?? '',
      lat: location?.lat ?? 0,
      lng: location?.lng ?? 0,
      opensAt: location?.dockHoursStart,
      postalCode: location?.postalCode ?? '',
      shipType: location?.shipType ?? '',
      state: location?.state ?? '',
      timezone: location?.timezone ?? '',
    }
  },
  formAddressToDTO: (
    a: FormAddress,
  ): Omit<ClientAddressLookupResult, 'dockHoursEnd' | 'dockHoursStart'> & {
    dockHoursEnd?: StandardTime
    dockHoursStart?: StandardTime
  } => {
    const adminArea3 = a.adminArea3?.trim()
    const contactEmail = a.contactEmail.trim()
    const contactName = a.contactName.trim()
    const contactPhone = a.contactPhone.trim()
    const contactPhoneExt = a.contactPhoneExt.trim()
    const opensAt = a.opensAt?.trim()
    const closesAt = a.closesAt?.trim()

    return {
      address: a.address.trim(),
      address2: a.address2.trim(),
      bolFollowers: a.bolFollowers.match(emailRegExpMatcher) || [],
      city: a.city.trim(),
      company: a.company.trim(),
      country: a.country,
      instructions: a.instructions.trim(),
      lat: a.lat ?? 0,
      lng: a.lng ?? 0,
      mappedAccessorials: a.mappedAccessorials,
      postalCode: a.postalCode.trim(),
      sendBOL: !!a.sendBOL,
      shipType: a.shipType,
      state: a.state.trim(),
      timezone: a.timezone ?? '',
      ...(adminArea3 && { adminArea3 }),
      ...(opensAt && { dockHoursStart: militaryToStandardTime(opensAt) }),
      ...(closesAt && { dockHoursEnd: militaryToStandardTime(closesAt) }),
      ...(contactEmail && { contactEmail }),
      ...(contactName && { contactName }),
      ...(contactPhone && { contactPhone }),
      ...(contactPhoneExt && { contactPhoneExt }),
    }
  },
}
