import { Fragment, useEffect, useState } from 'react'

import {
  FvButton,
  FvLinkButton,
  Icon,
  SliderPanel,
  ValidatedForm,
} from '@fv/client-components'
import { formatNumber } from '@fv/client-core'

import { LoadItemFields } from '../../features/booking/LoadItemFields'
import { buildFormCommodity } from '../../features/commodities/commodityUtils'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import { ProductCatalogPanelContents } from '../../features/commodities/ProductCatalog'
import { useProductCatalog } from '../../features/commodities/useProductCatalog'
import { getHasItems } from '../../features/quote-request/quoteRequestUtils'
import {
  type QuoteFormLoad,
  type QuoteRequestLoads,
} from '../../features/quote-request/types'
import {
  useQuoteFormActions,
  useQuoteFormMetadata,
} from '../../features/quote-request/useQuoteFormState'
import { useTypeNames } from '../../hooks/settings'
import { useLocale } from '../../hooks/settings/useLocale'
import { type FormLocation } from '../../types/FormLocation'
import { getFirstSequenceOfType } from '../../utils/shipmentFuncs'
import InfoBox from '../InfoBox'
import { List } from '../List'
import { PillHeader } from '../PillHeader'
import { StopList } from '../shared/StopList'

type LoadItemsProps = {
  isMultiTruck: boolean
  load: QuoteFormLoad
  locations: FormLocation[]
  setItemValues: (
    loadId: string,
    itemId: string,
    values: FormCommodity | null,
  ) => void
  truckNum: number
}

const LoadItems = ({
  isMultiTruck,
  load,
  locations,
  setItemValues,
  truckNum,
}: LoadItemsProps) => {
  const { equipmentName } = useTypeNames()
  const { items, loadId, type, weight, weightUOM } = load
  const locale = useLocale()
  let header = 'Enter each handling unit group that will be delivered.'

  if (isMultiTruck) {
    header = `Truck ${truckNum} / ${equipmentName(type)}`
    if (weight) header += ` / ${formatNumber(weight, locale)} ${weightUOM}.`
  }

  return (
    <div className="relative mt-10 pt-10">
      <PillHeader className="beer-container">
        <Icon icon="box" className="color-dark fa-fw" />
        <span>{header}</span>
      </PillHeader>

      {!items.length && <InfoBox>No handling units have been added.</InfoBox>}

      {items.map((values, ix) => (
        <LoadItemFields
          autoFocus={!values.quantity && truckNum === 1}
          canRemove={ix > 0}
          key={values.id}
          locations={locations}
          requiresDeclaredValue={false}
          isInSlider
          setValues={nextValues => {
            setItemValues(loadId, values.id, nextValues)
          }}
          values={values}
        />
      ))}
    </div>
  )
}

type QuoteItemsFormProps = {
  loads: QuoteRequestLoads
  locations: FormLocation[]
}

export const QuoteItemsForm = ({ loads, locations }: QuoteItemsFormProps) => {
  const { setMetadata, updateLoads } = useQuoteFormActions()
  const dropSequence = getFirstSequenceOfType(locations, 'delivery')
  const hasItems = getHasItems(loads)
  const metadata = useQuoteFormMetadata()
  const { isOpen: productCatalogOpen, close: closeProductCatalog } =
    useProductCatalog()

  const { addItem, formLoads, setItemValues } = useLocalFormLoads(
    loads,
    dropSequence,
    metadata.isAddingItems,
  )

  const equipmentHeader = useEquipmentHeader(formLoads, metadata.orderNumber)
  const isMultiTruck = formLoads.length > 1

  function closePanel() {
    const hasUnsavedChanges = getHasItemChanges(loads, formLoads)
    let shouldClose = true

    if (hasUnsavedChanges) {
      shouldClose = window.confirm(
        'You have unsaved changes, are you sure you want to close this form?',
      )
    }

    shouldClose && setMetadata({ isAddingItems: false })
    closeProductCatalog()
  }

  function onValidSubmit() {
    updateLoads(formLoads)
    setMetadata({ isAddingItems: false })
  }

  return (
    <SliderPanel
      closeButtonPosition="right"
      closePanel={closePanel}
      isOpen={metadata.isAddingItems}
      width="58rem"
      className="min-w-[58rem]"
    >
      {productCatalogOpen ? (
        <div>
          <div className="bg-fv-gray-200 -ml-8 -mr-8 -mt-8 mb-8 px-8 py-4">
            <FvLinkButton icon="arrow-left" onClick={closeProductCatalog}>
              Back to commodities
            </FvLinkButton>
          </div>
          <ProductCatalogPanelContents />
        </div>
      ) : (
        <>
          <List>
            <div className="items-start flex pb-3">
              <Icon
                icon="truck"
                className="color-dark fa-fw"
                transform="down-2"
              />
              <p className="mb-0">{equipmentHeader}</p>
            </div>
            <StopList locations={locations} workflow="truckload" />
          </List>

          <ValidatedForm onValidSubmit={onValidSubmit}>
            {formLoads.map((load, ix) => (
              <Fragment key={load.loadId}>
                <LoadItems
                  isMultiTruck={isMultiTruck}
                  load={load}
                  locations={locations}
                  setItemValues={setItemValues}
                  truckNum={ix + 1}
                />
                <div className="mt-6 flex items-center gap-4">
                  {!isMultiTruck && (
                    <>
                      <FvButton theme="default" className="" fwd type="submit">
                        I am finished {hasItems ? 'editing' : 'adding'} units
                      </FvButton>
                      <span className="ml-2">- or -</span>
                    </>
                  )}

                  <FvButton
                    theme="default"
                    icon="plus"
                    onClick={() => addItem(load.loadId)}
                  >
                    Add another handling unit group
                  </FvButton>

                  {isMultiTruck && ix === formLoads.length - 1 && (
                    <>
                      <span>-or-</span>
                      <FvButton theme="default" className="" fwd type="submit">
                        I am finished {hasItems ? 'editing' : 'adding'} units
                      </FvButton>
                    </>
                  )}
                </div>
              </Fragment>
            ))}
          </ValidatedForm>
        </>
      )}
    </SliderPanel>
  )
}

function useEquipmentHeader(loads: QuoteFormLoad[], refNum?: string) {
  const { equipmentName } = useTypeNames()
  const firstLoad = loads[0]
  const truckCount = loads.length
  const isMultiTruck = truckCount > 1

  let header = firstLoad?.type
    ? equipmentName(firstLoad.type)
    : 'No equipment selected'

  if (isMultiTruck) header = `Multi truck shipment / ${truckCount} trucks`
  if (refNum) header += ` / ${refNum}`
  return header
}

function initializeLoadItems(
  load: QuoteFormLoad,
  dropSequence = 1,
): QuoteFormLoad {
  if (load.items.length > 0) return load

  const {
    declaredValue,
    declaredValueCurrency,
    description,
    isHazardous,
    loadId,
    weight,
    weightUOM,
  } = load

  const item = buildFormCommodity({
    dropSequence,
    item: {
      description,
      ...(isHazardous && { hazardous: [] }),
      ...(declaredValue && {
        declaredValue: Number(declaredValue),
        declaredValueCurrency,
      }),
      ...(weight && {
        weight: Number(weight),
        weightUOM,
      }),
    },
    loadId,
  })

  return { ...load, items: [item] }
}

function initializeFormLoads(
  loads: QuoteRequestLoads,
  dropSequence = 1,
): QuoteFormLoad[] {
  const formLoads: QuoteFormLoad[] = []
  const sortedTypes = Array.from(loads.keys()).sort((a, b) =>
    a.localeCompare(b),
  )

  sortedTypes.forEach(t => {
    const loadsOfType = loads.get(t)
    loadsOfType && formLoads.push(...loadsOfType)
  })

  return formLoads.map(load => initializeLoadItems(load, dropSequence))
}

function rawItems(load: QuoteFormLoad): Omit<FormCommodity, 'id'>[] {
  return load.items.map(({ id, ...i }) => i)
}

function getHasItemChanges(
  initialLoads: QuoteRequestLoads,
  nextFormLoads: QuoteFormLoad[],
): boolean {
  const initialItems = initializeFormLoads(initialLoads).flatMap(rawItems)
  const nextItems = nextFormLoads.flatMap(rawItems)
  const initialSerializedItems = JSON.stringify(initialItems)
  const nextSerializedItems = JSON.stringify(nextItems)
  return nextSerializedItems !== initialSerializedItems
}

function useLocalFormLoads(
  loads: QuoteRequestLoads,
  dropSequence: number,
  isAddingItems: boolean,
) {
  const [formLoads, setFormLoads] = useState<QuoteFormLoad[]>([])

  useEffect(() => {
    if (!isAddingItems) return
    const localLoads = initializeFormLoads(loads, dropSequence)
    setFormLoads(localLoads)
  }, [dropSequence, isAddingItems, loads])

  function setLoadData(
    loadId: string,
    getNextLoad: (prevLoad: QuoteFormLoad) => QuoteFormLoad,
  ) {
    setFormLoads(prev =>
      prev.map(load => {
        if (load.loadId !== loadId) return load
        return getNextLoad(load)
      }),
    )
  }

  function addItem(loadId: string) {
    const newItem = buildFormCommodity({
      dropSequence,
      loadId,
    })

    setLoadData(loadId, prevLoad => ({
      ...prevLoad,
      items: prevLoad.items.concat(newItem),
    }))
  }

  function setItemValues(
    loadId: string,
    itemId: string,
    values: FormCommodity | null,
  ) {
    setLoadData(loadId, prevLoad => ({
      ...prevLoad,
      items: values
        ? prevLoad.items.map(i => {
            if (i.id !== itemId) return i
            return values
          })
        : prevLoad.items.filter(i => i.id !== itemId),
    }))
  }

  return {
    addItem,
    formLoads,
    setItemValues,
  }
}
