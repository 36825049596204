import './MultiFilterRow.scss'

import clsx from 'clsx'
import { type ChangeEventHandler, useState } from 'react'

import { Icon, SelectField } from '@fv/client-components'
import { type SelectFieldOption } from '@fv/client-types'

type Props = {
  addRow: () => void
  canAdd?: boolean
  canRemove?: boolean
  isLastRow?: boolean
  name: string
  onChange: ChangeEventHandler<HTMLSelectElement>
  options: SelectFieldOption[]
  removeRow: () => void
  value: string
}
export const MultiFilterRow = ({
  addRow,
  canAdd,
  canRemove,
  isLastRow,
  name,
  onChange,
  options,
  removeRow,
  value,
}: Props) => {
  const [isHovering, setHovering] = useState(false)

  return (
    <div className="filter-type__options flex items-center">
      <SelectField
        className={clsx(
          'form-control form-control--select',
          'MultiFilterRow_Select',
        )}
        name={name}
        onChange={onChange}
        options={options}
        value={value}
      />{' '}
      {canAdd && (
        <a
          href="#"
          className="MultiFilterRow_iconButton"
          onClick={e => {
            e.preventDefault()
            addRow()
          }}
        >
          <Icon icon="plus" />
        </a>
      )}
      {!canAdd && canRemove && (
        <a
          href="#"
          className="MultiFilterRow_iconButton"
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          onClick={e => {
            e.preventDefault()
            removeRow()
          }}
        >
          {isHovering || isLastRow ? <Icon icon="times" /> : 'OR'}
        </a>
      )}
    </div>
  )
}
