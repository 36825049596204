import {
  vArray,
  vBoolean,
  vEmail,
  vNumber,
  vObject,
  vObjectId,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'

export const scheduledReportFrequencies = [
  'daily',
  'weekly',
  'monthly',
] as const
const scheduledReportTypes = ['basic', 'advanced'] as const
const scheduledReportDayOfMonths = ['first-day'] as const

export const scheduledReportSchema = vObject({
  recipients: vArray(vEmail),
  frequency: vPicklist(scheduledReportFrequencies),
  time: vString(),
  type: vPicklist(scheduledReportTypes),
  enabled: vBoolean(),
  dayOfWeek: vOptional(vNumber()),
  dayOfMonth: vOptional(vPicklist(scheduledReportDayOfMonths)),
})

export const savedViewScheduledReportSchema = vObject({
  savedViewId: vOptional(vObjectId()),
  systemFilter: vOptional(
    vObject({
      filterId: vString(),
      name: vString(),
      query: vString(),
    }),
  ),
  scheduledReport: scheduledReportSchema,
})

export type ScheduledReportDTO = vOutput<typeof scheduledReportSchema>
export type SavedViewScheduledReportDTO = vOutput<
  typeof savedViewScheduledReportSchema
>
export type ScheduledReportOutputType = ScheduledReportDTO['type']
export type ScheduledReportFrequencyType = ScheduledReportDTO['frequency']
export type ScheduledReportDayOfMonthType = ScheduledReportDTO['dayOfMonth']
