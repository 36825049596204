export function getEquipmentLabel({
  altEquipmentNames,
  equipmentName,
  isAwarded = false,
}: {
  altEquipmentNames?: string[]
  equipmentName: string
  isAwarded?: boolean
}): string {
  if (isAwarded || !altEquipmentNames?.length) {
    return equipmentName
  }

  return `${equipmentName} -or- ${altEquipmentNames.join(' -or- ')}`
}
