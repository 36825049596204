import { isFedex } from '@fv/client-core'
import {
  type PackagingType,
  type SelectFieldOption,
  type Workflow,
} from '@fv/client-types'

import { useAppSettings } from './useAppSettings'
import { type PackageOption, useQuoteSettings } from './useQuoteSettings'

export function useFreightClassOptions(
  addEmptyOption = true,
): SelectFieldOption[] {
  const settingsQuery = useAppSettings()
  const freightClasses = settingsQuery.data?.enums.freightClasses ?? []
  const options = freightClasses.map(c => String(c))

  return addEmptyOption ? ['', ...options] : options
}

export function useHazardClassOptions(addEmptyOption = true): {
  hazardClassOptions: SelectFieldOption[]
  hazardSubClassOptions: SelectFieldOption[]
} {
  const settingsQuery = useAppSettings()
  const hazardClasses = settingsQuery.data?.enums.hazardClasses ?? []

  const options = hazardClasses.map(c => ({
    text: c.key,
    value: c.key,
  }))

  const subClassOptions = hazardClasses
    .filter(c => c.isValidSubClass)
    .map(c => ({
      text: c.key,
      value: c.key,
    }))

  return {
    hazardClassOptions: addEmptyOption ? ['', ...options] : options,
    hazardSubClassOptions: addEmptyOption
      ? ['', ...subClassOptions]
      : subClassOptions,
  }
}

export function usePackageOptions(
  workflow: Workflow,
  addEmptyOption = true,
  allowCustomOption = true,
  carrierCode?: string,
): SelectFieldOption<PackagingType>[] | SelectFieldOption<PackagingType>[][] {
  const settingsQuery = useQuoteSettings(workflow)
  const packages = settingsQuery.data?.packageOptions ?? []

  if (workflow === 'parcel') {
    const customOptions = packages.filter(p => p.key === 'custom')
    const fedexOptions = packages.filter(p => p.key.startsWith('fedex'))
    const upsOptions = packages.filter(p => p.key.startsWith('ups'))
    const options = []
    if (allowCustomOption) {
      options.push(toSelectOptions(customOptions))
    }
    if (!carrierCode || isFedex(carrierCode)) {
      options.push(toSelectOptions(fedexOptions))
    }
    if (!carrierCode || !isFedex(carrierCode)) {
      options.push(toSelectOptions(upsOptions))
    }
    return options
  }

  let options: SelectFieldOption<PackagingType>[] = toSelectOptions(
    packages.filter(
      p => workflow !== 'ltl' || (p.isContainer && p.key !== 'pallet'),
    ),
  )

  if (workflow === 'ltl') {
    options = [...palletOptions, ...options]
  }

  return addEmptyOption ? ['', ...options] : options
}

export function useSecondaryPackageOptions(
  workflow: Workflow,
  primaryPackageType: PackagingType | '',
  addEmptyOption = true,
): SelectFieldOption[] {
  const settingsQuery = useQuoteSettings(workflow)
  if (workflow !== 'ltl' && workflow !== 'truckload') return []

  const packages = settingsQuery.data?.packageOptions ?? []
  const primaryPackage = packages.find(p => p.key === primaryPackageType)
  const options = packages
    .filter(
      p =>
        p.canBeContained &&
        (!p.isFluid || primaryPackage?.containsFluid) &&
        primaryPackage?.isContainer,
    )
    .map(o => ({
      text: o.name,
      value: o.key,
    }))

  return addEmptyOption ? ['', ...options] : options
}

export function usePackingGroupOptions(
  addEmptyOption = true,
): SelectFieldOption[] {
  const settingsQuery = useAppSettings()
  const packingGroups = settingsQuery.data?.enums.packingGroups ?? []
  const options = packingGroups.map(g => ({
    text: g.name,
    value: g.key,
  }))

  return addEmptyOption ? ['', ...options] : options
}

export const yesNoOptions = ['Yes', 'No']

const palletOptions: SelectFieldOption<PackagingType>[] = [
  {
    text: 'Pallet - Custom',
    value: 'pallet',
  },
  {
    text: 'Pallet (48" x 40")',
    value: 'pallet-48x40',
  },
  {
    text: 'Pallet (48" x 48")',
    value: 'pallet-48x48',
  },
  {
    text: 'Pallet (60" x 40")',
    value: 'pallet-60x40',
  },
]
const toSelectOptions = (options: Array<PackageOption>) =>
  options.map(({ name, key }) => ({
    text: name,
    value: key,
  }))
