import { SelectFieldOption } from '@fv/client-types'
import { Country } from '@fv/models'

export const usCountryOption: SelectFieldOption<Country> = {
  text: 'USA',
  value: 'us',
}
export const caCountryOption: SelectFieldOption<Country> = {
  text: 'CAN',
  value: 'ca',
}
export const mxCountryOption: SelectFieldOption<Country> = {
  text: 'MEX',
  value: 'mx',
}

export const countries: Array<SelectFieldOption<Country>> = [
  usCountryOption,
  caCountryOption,
]
