import clsx from 'clsx'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import upperFirst from 'lodash/upperFirst'
import values from 'lodash/values'
import { Fragment, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FvLinkButton, Icon } from '@fv/client-components'
import { type FullShipment, type UIQuote } from '@fv/client-types'
import { hasMixedPallets } from '@fv/models/core'

import InfoBox from '../../components/InfoBox'
import YesNoInput from '../../components/inputs/YesNoInput'
import { routes } from '../../constants'
import { isBookedAndDispatched } from '../../utils/shipmentFuncs'
import { SpotQuotePanel } from '../spot-quote/SpotQuotePanel'
import { AddRateButton } from './ManualRatePanel'
import { PrintRatesButton } from './PrintRatesButton'
import { RateAlerts } from './RateAlerts'
import { PendingRate, RateGroup, RateRow } from './RateGroup'
import {
  getCarrierIdentifier,
  type RateListingData,
  rateSortTypes,
  type SortType,
  useRateListing,
} from './useRatesListing'

type Props = {
  load: FullShipment
  rates?: UIQuote[]
}

export const RatesListing = ({ load, rates }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [saveRateId, setSaveRateId] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [sortType, setSortBy] = useState<SortType>('cheapest')
  const { mode } = useParams<{ mode?: string }>()
  const mixedPallets = useMemo(() => hasMixedPallets(load.items), [load.items])
  const isReadOnly = mode === 'view'
  const availableRates =
    isBookedAndDispatched(load) && !isReadOnly
      ? rates?.filter(r => r.carrierId === load.selectedQuote?.carrierId)
      : rates

  const rateListingData = useRateListing(load, availableRates, sortType)
  const { activeRateGroups, erroredRates, pendingApiRates } = useLTLRateGroups(
    rateListingData,
    mixedPallets,
  )

  const hasAnyDirectRates =
    activeRateGroups.length || erroredRates.length || pendingApiRates.length

  const getRateGroupKey = (group: UIQuote[]) => {
    const keyBase = `${getCarrierIdentifier(group[0])}_${group[0].method}`

    if (group[0].method === 'manual') {
      // we don't group manual quotes
      return `${keyBase}_${group[0]._id}`
    }
    return keyBase
  }

  return (
    <div className="b1200:overflow-visible flex flex-col overflow-auto">
      {!isReadOnly &&
        (load.workflow === 'ltl' || load.workflow === 'parcel') && (
          <RateAlerts load={load} rates={rates} />
        )}

      <div className="ltl-rates-ctn b1200:pb-20 relative w-full flex-1 overflow-auto bg-slate-50 px-6 py-8 [scrollbar-gutter:stable]">
        <div className="divide-fv-gray mb-8 flex items-center divide-x ">
          {!isReadOnly && (
            <div className="inline-flex">
              Sort by
              {rateSortTypes.map((s, ix) => (
                <Fragment key={s.type}>
                  <FvLinkButton
                    className={clsx('mx-3', {
                      'active-hint-indicator': sortType === s.type,
                    })}
                    icon={s.icon}
                    onClick={() => setSortBy(s.type)}
                  >
                    {upperFirst(s.type)}
                  </FvLinkButton>

                  {ix < rateSortTypes.length - 1 && <span>- or -</span>}
                </Fragment>
              ))}
            </div>
          )}
          {isReadOnly && (
            <FvLinkButton
              to={routes.details(load.loadId)}
              icon="arrow-left"
              className="whitespace-nowrap px-3"
            >
              Back to details
            </FvLinkButton>
          )}
          <div className="flex items-center px-3">
            <label htmlFor="expanded">Expand all rate details</label>

            <YesNoInput
              checked={expanded}
              className="yes-no-toggle--on-off ml-3"
              name="expanded"
              onChange={e => setExpanded(e.target.checked)}
            />
          </div>

          <PrintRatesButton
            loadId={load.loadId}
            className="whitespace-nowrap px-3"
          >
            Print rates
          </PrintRatesButton>
          {load.status === 'pending' &&
            load.workflow === 'ltl' &&
            !isReadOnly && (
              <AddRateButton
                className="px-3"
                icon="plus"
                load={load}
                rateType="manual-rate"
              >
                Add rate
              </AddRateButton>
            )}
        </div>

        {load.workflow === 'ltl' &&
          activeRateGroups.map(group => (
            <RateGroup
              expanded={expanded}
              key={getRateGroupKey(group)}
              loadId={load.loadId}
              locations={load.locations}
              rates={group}
              saveRateId={saveRateId}
              setSaveRateId={setSaveRateId}
              isReadOnly={isReadOnly}
              pickupDate={load.pickupDate}
            />
          ))}
        {load.workflow === 'parcel' &&
          rateListingData.activeApiRates.map(r => (
            <div className="mb-3" key={r._id}>
              <RateRow
                loadId={load.loadId}
                isReadOnly={isReadOnly}
                saveRateId={saveRateId}
                setSaveRateId={setSaveRateId}
                locations={load.locations}
                expanded={expanded}
                highlight={r.status === 'awarded'}
                rate={r}
                pickupDate={load.pickupDate}
              />
            </div>
          ))}

        {pendingApiRates.map(r => (
          <PendingRate
            key={`pending-${getCarrierIdentifier(r)}`}
            carrierName={r.providerName}
          />
        ))}

        {!activeRateGroups.length && !pendingApiRates.length && (
          <InfoBox>
            No rates available for this shipment. You can either{' '}
            <AddRateButton
              className="text-fv-blue"
              load={load}
              rateType="manual-rate"
            >
              Add a manual rate
            </AddRateButton>{' '}
            or request spot quotes.
          </InfoBox>
        )}

        {!!erroredRates.length && load.status === 'pending' && (
          <div className="b1200:mt-8 mt-6 flex space-x-4 divide-x divide-gray-300">
            <div>{erroredRates.length} carriers did not return rates</div>
            <FvLinkButton
              className="pl-4"
              icon="arrows-from-line"
              onClick={() => setShowErrors(p => !p)}
            >
              Show carriers
            </FvLinkButton>
          </div>
        )}

        {showErrors && (
          <div className="mb-3">
            {flatten(erroredRates).map(r => {
              return (
                <div
                  className="ltl-rates-ctn__rate mt-4 flex flex-nowrap"
                  key={r._id}
                >
                  <InfoBox className="mb-0 w-full" custom>
                    {r.assetCarrierName}
                    <div className="text-xs">Your direct rate</div>
                    <hr className="my-3" />
                    <div className="flex">
                      <Icon
                        className="color-secondary fa-fw"
                        icon="exclamation-triangle"
                      />
                      <span>{r.errors?.[0]?.message}</span>
                    </div>
                  </InfoBox>
                </div>
              )
            })}
          </div>
        )}
      </div>

      {!isReadOnly && load.workflow !== 'parcel' && (
        <SpotQuotePanel
          defaultOpen={!hasAnyDirectRates && !mixedPallets}
          label="Get more rates using spot quotes"
          loads={load ? [load] : []}
        />
      )}
    </div>
  )
}

function useLTLRateGroups(
  { manualRates, activeApiRates, pendingRates, erroredRates }: RateListingData,
  mixedPallets: boolean,
): {
  activeRateGroups: UIQuote[][]
  erroredRates: UIQuote[][]
  pendingApiRates: UIQuote[]
} {
  // Add API rates under manual rates
  const ratesByCarrier = activeApiRates.reduce((groups, rate) => {
    const carrierIdentifier = getCarrierIdentifier(rate)
    const rateGroup = groups.find(
      g => getCarrierIdentifier(g[0]) === carrierIdentifier,
    )

    if (!rateGroup) {
      const carrierRates = activeApiRates.filter(
        r => getCarrierIdentifier(r) === carrierIdentifier,
      )

      groups.push(carrierRates)
    }

    return groups
  }, [] as UIQuote[][])

  const activeRateGroups: UIQuote[][] = [
    ...manualRates.map(r => [r]),
    ...ratesByCarrier,
  ]

  // Group errored by carriers that don't already have successful rates
  const errorsWithoutSuccesses = erroredRates.filter(
    e => !activeRateGroups.some(group => group[0].carrierId === e.carrierId),
  )
  const errorsByCarrier = groupBy(errorsWithoutSuccesses, 'carrierSort')

  return {
    activeRateGroups,
    erroredRates: mixedPallets
      ? []
      : sortBy(values(errorsByCarrier), rates => rates[0].carrierSort),
    pendingApiRates: mixedPallets ? [] : pendingRates,
  }
}
