import { PropsWithChildren } from 'react'

type Props = PropsWithChildren

export const Modal = ({ children }: Props) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-[1000] bg-white opacity-95">
      <div className="flex h-screen w-full flex-wrap items-center justify-center overflow-auto px-8 py-14">
        {children}
      </div>
    </div>
  )
}
