import { useMutation, useQueryClient } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { type Message, messageKeys } from './useMessages'

async function markMessagesRead(opts: {
  loadId?: string
  messageIds: string[]
}): Promise<Message[]> {
  if (!opts.loadId) throw new Error('`loadId` required to mark messages read')

  const { loadId, messageIds } = opts
  const options = buildFetchOptionsWithAuth({ method: 'POST' })

  const responses = await Promise.all(
    messageIds.map(messageId => {
      const endpoint = `${apiUri}/shipments/${loadId}/messages/${messageId}/read`
      return fetchJson(endpoint, options)
    }),
  )

  if (responses.every(res => res.ok)) {
    return responses.map(res => res.json)
  }

  const errorResponse = responses.find(res => !res.ok)
  throw errorResponse?.errorMessage
}

export function useMarkMessagesRead(loadId?: string) {
  const queryClient = useQueryClient()

  return useMutation(
    (messageIds: string[]) => {
      return markMessagesRead({ loadId, messageIds })
    },
    {
      onSuccess: updatedMessages => {
        queryClient.setQueryData(
          messageKeys.load(loadId),
          (prev?: Message[]) => {
            if (!prev) return updatedMessages

            return prev.map(
              p => updatedMessages.find(m => m._id === p._id) ?? p,
            )
          },
        )
      },
    },
  )
}
