import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { emailRegExpMatcher } from '@fv/client-core'
import { type Quote } from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { provisioningQueryKeys } from './queries'
import {
  type AccountCarrierLocationUpsertDto,
  type AccountCarrierUpsertModel,
} from './types'

export const useUpsertCarrier = () => {
  return useMutation((model: AccountCarrierUpsertModel) =>
    shipperFetch(`/provisioning/account/carriers`, {
      method: 'PUT',
      body: {
        ...model,
        salesReps:
          model.salesRepEmails
            ?.match(emailRegExpMatcher)
            ?.map(email => ({ email })) ?? [],
      },
    }),
  )
}

export const useUpdateAccountCarrierLocation = () => {
  return useMutation(
    ({ carrierId, locationId, ...model }: AccountCarrierLocationUpsertDto) =>
      shipperFetch(
        `/provisioning/account/carriers/${carrierId}/${locationId}`,
        {
          method: 'PUT',
          body: model,
        },
      ),
  )
}

type LocationTestResponse = {
  success: boolean
  quotes: Quote[]
}
export const useTestAccountCarrierLocation = () => {
  return useMutation(
    ({ carrierId, locationId }: { carrierId: string; locationId: string }) =>
      shipperFetch<LocationTestResponse>(
        `/provisioning/account/carriers/${carrierId}/${locationId}/test`,
        {
          method: 'POST',
        },
      ),
  )
}

type RemoveCarrierArgs = {
  carrierId: string
}
export const useRemoveCarrier = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ carrierId }: RemoveCarrierArgs) =>
      shipperFetch(`/provisioning/account/carriers/${carrierId}`, {
        method: 'DELETE',
      }),
    {
      onSuccess() {
        toast.success('Removed carrier from your account')
      },
      onError() {
        toast.error('Error removing carrier from your account')
      },
      onSettled() {
        queryClient.invalidateQueries(provisioningQueryKeys.account)
      },
    },
  )
}
