import { FvLinkButton } from '@fv/client-components'

import { freightviewUri } from '../../../constants'

export const ProvisionerDisclaimer = () => {
  return (
    <div>
      <p>
        By accessing this software, you agree to keep confidential any
        information related to the software, including the content of the
        software and how you use the software. This software is proprietary to
        Freightview Labs, LLC, and you agree to not assist others in copying,
        offering for sale, or selling the software, in whole or in part.
        Further, you agree to take reasonable steps to prevent unauthorized
        disclosure or use of the software, including notifying Freightview Labs,
        LLC by email to{' '}
        <FvLinkButton theme="underlined" href="mailto:support@freightview.com">
          support@freightview.com
        </FvLinkButton>{' '}
        in the event of any unauthorized disclosure or use. This software is
        patent pending.
      </p>
      <p>
        By continuing to use this website, you acknowledge that you have read
        and agree to the{' '}
        <FvLinkButton
          target="_blank"
          href={`${freightviewUri}/terms`}
          theme="underlined"
        >
          Terms & Conditions
        </FvLinkButton>
        .
      </p>
      <p>
        If you run into any problems or questions, use the live chat in the
        bottom right corner of this page or send an email to{' '}
        <FvLinkButton theme="underlined" href="mailto:support@freightview.com">
          support@freightview.com
        </FvLinkButton>
      </p>
    </div>
  )
}
