import { Quote } from '@fv/client-types'

type Props = {
  quote?: Pick<Quote, 'assetCarrierName' | 'providerName'>
}

export const composeQuoteCarrier = ({ quote }: Props) => {
  if (!quote) return null
  if (quote.assetCarrierName && quote.providerName !== quote.assetCarrierName) {
    return `${quote.assetCarrierName} via ${quote.providerName}`
  }
  return quote.assetCarrierName ?? quote.providerName
}

export const QuoteCarrier = (props: Props) => (
  <span>{composeQuoteCarrier(props)}</span>
)
