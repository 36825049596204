import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { createStore, useStore } from 'zustand'

import { type ShipmentFiltersState, type ShipmentFiltersStore } from './types'

type ShipmentFiltersStoreEntity = ReturnType<typeof createFilterStore>
const createFilterStore = (state?: Partial<ShipmentFiltersState>) => {
  const defaultStore: ShipmentFiltersState = {
    listType: 'readyCompletedList',
    filters: [],
    filterConfig: [],
    canSearch: true,
    forcePanelOpen: false,
    isCreating: false,
    ...state,
  }
  return createStore<ShipmentFiltersStore>()((set, get) => ({
    ...defaultStore,
    actions: {
      setForcePanelOpen: forcePanelOpen => set({ forcePanelOpen }),
      setEditingFilter: editingFilter => set({ editingFilter }),
      setIsCreating: isCreating => set({ isCreating }),
    },
  }))
}

const ShipmentFilterContext = createContext<ShipmentFiltersStoreEntity | null>(
  null,
)

type Props = PropsWithChildren<{
  state?: Partial<ShipmentFiltersState>
}>
export const ShipmentFilterListProvider = ({ children, state }: Props) => {
  const store = useRef(createFilterStore(state)).current
  useEffect(() => {
    store.setState({
      filters: state?.filters ?? [],
      filterConfig: state?.filterConfig ?? [],
    })
  }, [store, state])
  return (
    <ShipmentFilterContext.Provider value={store}>
      {children}
    </ShipmentFilterContext.Provider>
  )
}

export function useShipmentFilterListContext<T>(
  selector: (state: ShipmentFiltersStore) => T,
): T {
  const store = useContext(ShipmentFilterContext)
  if (!store) throw new Error('Missing ShipmentFilterProvider in the tree')
  return useStore(store, selector)
}

export const useShipmentFilters = () =>
  useShipmentFilterListContext(s => s.filters)

export const useShipmentFilterActions = () =>
  useShipmentFilterListContext(s => s.actions)
