import { type ChangeEvent, type ChangeEventHandler, useState } from 'react'

import { setPathValue, setPathValues } from '@fv/client-core'
import {
  type FlattenedKey,
  type ProductCatalogEntry,
  type ProductMode,
} from '@fv/client-types'

import { useQuoteSettings } from '../../hooks/settings'
import {
  buildLegacyHazardDetail,
  buildProductCatalogEntry,
  normalizePackageType,
  validateHazmatIdChange,
  validateNmfcChange,
} from './commodityUtils'

export type HandlingUnitKey = FlattenedKey<ProductCatalogEntry>

export function useProductCatalogForm(
  mode: ProductMode,
  initialValues?: ProductCatalogEntry,
): {
  values: ProductCatalogEntry
  handleChange: ChangeEventHandler
  setValues: (values: Partial<ProductCatalogEntry>) => void
} {
  const settingsQuery = useQuoteSettings(mode)
  const packages = settingsQuery.data?.packageOptions ?? []
  const [values, setValues] = useState(
    initialValues ?? buildProductCatalogEntry(mode),
  )

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setValues(prev => {
      const name = e.target.name as HandlingUnitKey
      const nextValues = { ...prev }

      if (e.target.type === 'checkbox') {
        const input = e.target as HTMLInputElement
        setPathValue(nextValues, name, input.checked)
        return nextValues
      }

      // Run custom validation
      if (name === 'hazmatId') {
        validateHazmatIdChange(e as ChangeEvent<HTMLInputElement>)
      }

      if (name === 'nmfc') {
        validateNmfcChange(e as ChangeEvent<HTMLInputElement>)
      }

      const value = e.target.value
      setPathValue(nextValues, name, value)

      // Reset `readOnly` if clearing input
      if (value === '') {
        const readOnlyKey = `readOnly.${name}` as HandlingUnitKey
        setPathValue(nextValues, readOnlyKey, false)
      }

      // Reset hazmat fields if not hazardous
      if (name === 'hazardous' && value === 'No') {
        setPathValues(nextValues, buildLegacyHazardDetail())
      }

      // Reset fluid unit if non-fluid package type
      if (name === 'package' && nextValues.package) {
        const normalized = normalizePackageType(nextValues.package)
        const type = packages.find(p => p.key === normalized)
        const containedType = packages.find(
          p => p.key === nextValues.saidToContainPackagingType,
        )

        if (containedType?.isFluid && !type?.containsFluid) {
          setPathValue(nextValues, 'saidToContainPackagingType', '')
        }

        if (nextValues.package === 'pallet-48x48') {
          setPathValues(nextValues, {
            length: '48',
            width: '48',
          })
        }

        if (nextValues.package === 'pallet-48x40') {
          setPathValues(nextValues, {
            length: '48',
            width: '40',
          })
        }

        if (nextValues.package === 'pallet-60x40') {
          setPathValues(nextValues, {
            length: '60',
            width: '40',
          })
        }
      }

      return nextValues
    })
  }

  return {
    handleChange,
    values,
    setValues(values) {
      setValues(prev => {
        return {
          ...prev,
          ...values,
        }
      })
    },
  }
}
