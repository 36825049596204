import { useMutation, useQueryClient } from '@tanstack/react-query'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'

import { apiUri } from '../../constants'
import { loadKeys } from '../../hooks/shipments/useLoad'
import { quoteKeys } from '../../hooks/shipments/useQuotes'

type RerateOptions = {
  loadId: string
}

async function forceRerate(opts: RerateOptions) {
  const endpoint = `${apiUri}/shipments/${opts.loadId}/quotes/rerate`
  const options = buildFetchOptionsWithAuth({
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useForceRerate(loadId: string) {
  const queryClient = useQueryClient()

  return useMutation(async () => await forceRerate({ loadId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(loadKeys.id(loadId))
      queryClient.invalidateQueries(quoteKeys.load(loadId))
    },
  })
}
