export function toOxfordComma(array: string[]): string {
  if (!array) {
    return ''
  }

  if (array.length === 2) {
    return array.join(' and ')
  }

  if (array.length > 2) {
    return array
      .slice(0, array.length - 1)
      .concat(`and ${array.slice(-1)}`)
      .join(', ')
  }

  return array.join(',')
}
