import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { appContextQueryKey } from '../../hooks/settings/useAppContext'
import { type ProfileInfoModel } from '../../types/ShipperAccountUser'
import { shipperFetch } from '../../utils/shipperFetch'

const updateUser = async (user: ProfileInfoModel) => {
  return shipperFetch<ProfileInfoModel>('/user/profile', {
    body: user,
    method: 'PUT',
  })
}

export const useUpdateProfile = (notify?: boolean) => {
  const queryClient = useQueryClient()
  return useMutation(updateUser, {
    onSuccess: () => {
      notify &&
        toast.success('User updated successfully.', {
          id: 'contact-information-update',
        })
    },
    onSettled() {
      queryClient.invalidateQueries(appContextQueryKey)
    },
  })
}
