import './ReactSelectClasses.scss'
import './ReactSelectOverlay.scss'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { forwardRef, useRef } from 'react'
import Select, { components } from 'react-select'

import { TextField } from '@fv/client-components'

import reactSelectStyles from '../../styles/reactSelectStyles'

const defaultFilterOption = () => true
const searchableFilterOption = ({ data }, input) =>
  !input || data.label.toLocaleLowerCase().includes(input.toLocaleLowerCase())

// https://github.com/JedWatson/react-select/issues/1826
const Input = props => (
  <components.Input
    {...props}
    autoComplete="off"
    data-lpignore
    isHidden={false}
  />
)

// https://github.com/JedWatson/react-select/issues/4327
// HTML5 validation is not currently supported by react-select
// so we are using a dummy TextField for validation styles.
const MultiSelectField = forwardRef(
  (
    {
      containerClassName,
      filterOption = defaultFilterOption,
      isSearchable = false,
      menuPlacement = 'auto',
      name,
      onChange,
      options,
      readOnly,
      required,
      values,
      ...reactSelectProps
    },
    ref,
  ) => {
    const localRef = useRef(null)
    const requiredInputRef = useRef(null)

    function setRef(element) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <div className={clsx('ReactSelectOverlay_container', containerClassName)}>
        <Select
          {...reactSelectProps}
          className="ReactSelectOverlay multi"
          classNamePrefix="react-select"
          components={{ Input }}
          filterOption={isSearchable ? searchableFilterOption : filterOption} // Passes object with `data` prop
          isDisabled={readOnly}
          isMulti
          isSearchable={isSearchable}
          menuPlacement={menuPlacement}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          onChange={onChange} // Passes array of next values
          onMenuOpen={() => {
            // Hide error message so it does not block menu
            requiredInputRef.current?.setCustomValidity('')
          }}
          options={options}
          ref={setRef}
          styles={reactSelectStyles}
          value={values}
        />

        {required && (
          <TextField
            className="ReactSelectOverlay_requiredInput form-control"
            id={name}
            name={name}
            onChange={Function.prototype}
            onFocus={() => localRef.current?.focus()}
            ref={requiredInputRef}
            required
            tabIndex={-1}
            value={
              values?.length
                ? JSON.stringify(values.map(v => v.id ?? v.value))
                : ''
            }
          />
        )}
      </div>
    )
  },
)

MultiSelectField.propTypes = {
  className: PropTypes.string,
  filterOption: PropTypes.func,
  isSearchable: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    }),
  ).isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    }),
  ).isRequired,
}

export default MultiSelectField
