import { type PropsWithChildren, useCallback, useState } from 'react'

import { SliderPanel, useOlarkHelper } from '@fv/client-components'
import { type PropsWithLoad } from '@fv/client-types'

import { supportMessage } from '../../constants'
import { ConfirmationPanel } from '../../features/confirm/ConfirmationPanel'
import ListingDocumentsPanel from '../../features/documents/ListingDocumentsPanel'
import { ShipmentLabels } from '../../features/labels/ShipmentLabels'
import { SharePanel } from '../../features/share/SharePanel'
import { SpotQuoteRequestPanel } from '../../features/spot-quote'
import { useLoad } from '../../hooks/shipments'
import InfoBox from '../InfoBox'
import { ActiveLoadDetail } from './ActiveLoadDetail'
import MessagesNotesTagsPanel from './MessagesNotesTagsPanel'
import { PendingLoadDetail } from './PendingLoadDetail'
import QuoteHistoryPanel from './QuoteHistoryPanel'
import UpdateTrackingPanel from './UpdateTrackingPanel'

const DetailPanelWrapper = ({ children }: PropsWithChildren) => (
  <div className="shipment-list-ancillary-details-ctn">
    <div className="shipment-list-ancillary-details overflow-auto">
      {children}
    </div>
  </div>
)

export type PanelName =
  | 'confirm'
  | 'detail'
  | 'label'
  | 'message'
  | 'quote'
  | 'request'
  | 'share'
  | 'track'
  | 'upload'

const detailPanel = 'detail'
const panelComponents: Record<
  PanelName,
  ((p: PropsWithLoad<{ closePanel: () => void }>) => JSX.Element) | null
> = {
  confirm: p => (
    <DetailPanelWrapper>
      <ConfirmationPanel {...p} />
    </DetailPanelWrapper>
  ),
  detail: null,
  label: null,
  message: MessagesNotesTagsPanel,
  quote: QuoteHistoryPanel,
  request: SpotQuoteRequestPanel,
  share: SharePanel,
  track: UpdateTrackingPanel,
  upload: ListingDocumentsPanel,
}

type LoadDetailPanelProps = {
  loadId: string | null
}

const LoadDetailPanel = ({ loadId }: LoadDetailPanelProps) => {
  const loadQuery = useLoad(loadId)
  const [activePanel, setActivePanel] = useState<PanelName>(detailPanel)
  const closePanel = useCallback(() => setActivePanel(detailPanel), [])
  useOlarkHelper({ loadId })

  if (!loadId || loadQuery.isLoading || !loadQuery.data) {
    return (
      <DetailPanelWrapper>
        {!loadId && <InfoBox>Please select a shipment.</InfoBox>}

        {loadId &&
          (loadQuery.isFetching ? (
            <InfoBox icon="sync">Loading...</InfoBox>
          ) : (
            <InfoBox>Unable to load shipment, {supportMessage}</InfoBox>
          ))}
      </DetailPanelWrapper>
    )
  }

  const ActivePanelComponent = panelComponents[activePanel]

  if (ActivePanelComponent) {
    return (
      <ActivePanelComponent closePanel={closePanel} load={loadQuery.data} />
    )
  }

  const isInProcess =
    loadQuery.data.status === 'awarded' || loadQuery.data.status === 'pending'

  const LoadDetailComponent = isInProcess ? PendingLoadDetail : ActiveLoadDetail

  return (
    <>
      <LoadDetailComponent
        load={loadQuery.data}
        setActivePanel={setActivePanel}
      />

      <SliderPanel closePanel={closePanel} isOpen={activePanel === 'label'}>
        <ShipmentLabels load={loadQuery.data} setActiveSlider={closePanel} />
      </SliderPanel>
    </>
  )
}

export default LoadDetailPanel
