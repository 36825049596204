import { type Dispatch, type SetStateAction } from 'react'

import { type FormRegister } from '@fv/client-core'

import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import { InputGroup } from '../../components/inputs/InputGroup'
import { type ProfileInfoModel } from '../../types/ShipperAccountUser'

type ClassNameMap = {
  [K in keyof ProfileInfoModel as `${K}ClassName`]?: string
}
type Props = ClassNameMap & {
  register: FormRegister<ProfileInfoModel>
  autoFocus?: boolean
  onChange?: Dispatch<SetStateAction<ProfileInfoModel>>
  value: ProfileInfoModel
}
export const ProfileInfoForm = ({
  register,
  autoFocus,
  onChange,
  value,
  ...props
}: Props) => {
  return (
    <>
      <InputGroup
        className={props.firstNameClassName}
        inputProps={{
          ...register('firstName'),
          autoFocus: autoFocus,
        }}
        inputType="text"
        label="First name"
        required
      />

      <InputGroup
        className={props.lastNameClassName}
        inputProps={{
          ...register('lastName'),
        }}
        inputType="text"
        label="Last name"
        required
      />

      <InputGroup
        className={props.companyClassName}
        inputProps={{
          ...register('company'),
        }}
        inputType="text"
        label="Company name"
        required
      />

      <InputGroup
        className={props.titleClassName}
        inputProps={{
          ...register('title'),
        }}
        inputType="text"
        label="Job Title"
        required
      />

      <InputGroup
        className={props.emailClassName}
        inputProps={{
          ...register('email'),
        }}
        inputType="text"
        label="Email"
        required
      />

      <ContactPhoneInput
        className={props.phoneNumberClassName}
        required
        name="contactPhone"
        onChange={e => onChange?.(f => ({ ...f, phoneNumber: e.contactPhone }))}
        values={{
          contactPhone: value.phoneNumber || '',
        }}
        hideExt
      />
    </>
  )
}
