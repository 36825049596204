import clsx from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

import {
  SelectField as SharedSelectField,
  type SelectFieldProps,
} from '@fv/client-components'

export const SelectField = forwardRef(
  (
    { className, ...props }: SelectFieldProps,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => (
    <SharedSelectField
      className={clsx('form-control form-control--select', className)}
      ref={ref}
      {...props}
    />
  ),
)
