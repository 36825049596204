import clsx from 'clsx'
import {
  type ChangeEvent,
  type ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
} from 'react'

import { type InputProps } from './types'

const TextInput = forwardRef(
  (
    { error, name, onChange, className, ...rest }: InputProps,
    ref?: ForwardedRef<HTMLInputElement | null>,
  ) => {
    const localRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
      if (!error) return localRef.current?.setCustomValidity('')
      localRef.current?.setCustomValidity(error)
      localRef.current?.reportValidity()
    }, [error, ref])

    function localOnChange(e: ChangeEvent<HTMLInputElement>) {
      e.target.value = e.target.value.trimStart()
      e.target.setCustomValidity('')
      onChange && onChange(e)
    }

    function setRef(element: HTMLInputElement | null) {
      localRef.current = element
      if (!ref) return
      if (typeof ref === 'function') ref(element)
      else ref.current = element
    }

    return (
      <input
        autoComplete="off"
        data-lpignore
        id={name}
        name={name}
        onChange={localOnChange}
        ref={setRef}
        type="text"
        className={clsx('form-control', className)}
        {...rest}
      />
    )
  },
)

export default TextInput
