import dayjs from 'dayjs'
import {
  type ChangeEvent,
  type LegacyRef,
  type PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import {
  CarrierTrackingLink,
  FvButton,
  Icon,
  SliderPanel,
  TextAreaField,
} from '@fv/client-components'
import {
  defaultOptions,
  fetchJson,
  getTrackingNumLabel,
  recaptchaKey,
} from '@fv/client-core'

import InfoBox from '../../components/InfoBox'
import { ShipmentTrackingBar } from '../../components/ShipmentTrackingBar'
import { useBolLinkState } from '../../hooks/shipments/useBolLinkState'
import DocumentList from '../documents/DocumentList'
import { composeLabelsUrl, getParcelLabelUrl } from '../labels/hooks'
import { ShipmentLabels } from '../labels/ShipmentLabels'
import { useShareShipmentForm } from './mutations'
import { useSharedShipment } from './queries'
import SharedShipmentCard from './SharedShipmentCard'
import SharedShipmentHeader from './SharedShipmentHeader'
import ShareForm from './ShareForm'
import SharePanelHeader from './SharePanelHeader'

type ActivePanel = 'share' | 'labels' | null
type RequestDocsFn = (url: string) => Promise<void>

type DownloadLinkOpts = PropsWithChildren<{
  url: string
  name: string
  requestDocs: RequestDocsFn
  loading?: boolean
}>

const ITEM_KEY = 'fv.pat'

const promptMessage =
  'In order to receive the requested documents, enter the passcode included in the initial share email you received from Freightview.'

function isTokenExpired() {
  const token = localStorage.getItem(ITEM_KEY)

  if (!token) return true

  try {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    return Math.floor(new Date().getTime() / 1000) >= expiry
  } catch (e) {
    return true
  }
}

export const DownloadLink = ({
  url,
  requestDocs,
  loading,
  children,
}: DownloadLinkOpts) => {
  return (
    <FvButton
      icon="file-contract"
      iconClass="fa-fw"
      fw
      onClick={() => requestDocs(url)}
      className="p-3 block w-full text-left pl-0"
      loading={loading}
    >
      {children}
    </FvButton>
  )
}

export const SharedShipmentPage = () => {
  const { loadId } = useParams() as { loadId: string }
  const shipmentQuery = useSharedShipment(loadId)
  const [activePanel, setActivePanel] = useState<ActivePanel>(null)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const closePanel = useCallback(() => setActivePanel(null), [])
  const recaptchaRef: LegacyRef<ReCAPTCHA> = useRef()
  const [currentToken, setCurrentToken] = useState<string>()

  const requestDocs = useCallback(
    async (url: string) => {
      if (loading) return

      let token = currentToken

      if (!token && isTokenExpired()) {
        token = window.prompt(promptMessage)
      }

      if (!token && isTokenExpired()) return

      setLoading(true)
      const recaptchaToken =
        recaptchaRef.current && (await recaptchaRef.current.executeAsync())

      const response = await fetchJson(
        url,
        defaultOptions({
          json: {
            token,
            recaptchaToken,
          },
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem(ITEM_KEY)}`,
          },
        }),
      )

      if (response.ok) {
        token && setCurrentToken(token)

        toast.success(
          'Success! The requested documents should arrive in your email in a few moments.',
          {
            duration: 10000,
            position: 'top-center',
          },
        )

        localStorage.setItem(ITEM_KEY, response.response.headers.get('X-Token'))
      } else if (response.status === 404) {
        toast.error('Invalid passcode provided. Please try again.', {
          duration: 5000,
          position: 'top-center',
        })
      } else {
        toast.error(
          'We are unable to generate the requested documents at this time. Please try again later or contact Freightview Support for assistance.',
          {
            duration: 5000,
            position: 'top-center',
          },
        )
      }

      setLoading(false)
    },
    [recaptchaRef, currentToken],
  )

  const shareForm = useShareShipmentForm({
    loadId,
  })

  const { fvBolUrl, carrierBolUrl, hideFvBolFromSpotQuote } = useBolLinkState(
    shipmentQuery.data,
  )

  const shareShipment = (emails: string[]) => {
    shareForm.submitShare(emails, {
      onSuccess: closePanel,
    })
  }

  if (shipmentQuery.isLoading) {
    return (
      <div className="flex flex-col h-screen">
        <SharedShipmentHeader>Loading shipment...</SharedShipmentHeader>
        <div className="flex-1 flex items-center justify-center">
          <Icon icon="sync" size="2x" />
        </div>
      </div>
    )
  }

  if (shipmentQuery.isError || !shipmentQuery.data) {
    return (
      <div className="flex flex-col h-screen">
        <SharedShipmentHeader>Freightview</SharedShipmentHeader>
        <div className="flex-1 flex items-center justify-center">
          <InfoBox icon="triangle">
            {shipmentQuery.error?.message ?? 'Sorry, something went wrong.'}
          </InfoBox>
        </div>
      </div>
    )
  }

  const {
    bol,
    canPrintLabels,
    carrierPhone,
    carrierTracking,
    company,
    documents,
    pickup,
    refNums,
    tracking,
    status,
    workflow,
  } = shipmentQuery.data

  const {
    currentStatus,
    deliveryDateActual,
    deliveryDateEstimate,
    trackingNumber,
  } = tracking ?? {}

  const deliveryDate = deliveryDateActual ?? deliveryDateEstimate
  const labelUrl =
    workflow === 'parcel' ? getParcelLabelUrl({ documents }) : undefined

  return (
    <div className="App">
      <div className="grid grid-cols-[19rem_1fr_30.5rem] grid-rows-[auto_1fr_auto] h-screen overflow-hidden">
        <SharedShipmentHeader>
          {company} has shared this shipment with you.
        </SharedShipmentHeader>

        <div className="p-6 border-fv-gray border-r">
          <div className="divide-y divide-fv-gray">
            {fvBolUrl && !hideFvBolFromSpotQuote && (
              <DownloadLink
                url={fvBolUrl}
                name="freightview-bol.pdf"
                requestDocs={requestDocs}
                loading={loading}
              >
                Print Freightview BOL
              </DownloadLink>
            )}

            {carrierBolUrl && (
              <DownloadLink
                url={carrierBolUrl}
                name="carrier-bol.pdf"
                requestDocs={requestDocs}
                loading={loading}
              >
                Print Carrier BOL
              </DownloadLink>
            )}

            {canPrintLabels && (
              <>
                {labelUrl && (
                  <div>
                    <DownloadLink
                      url={labelUrl}
                      name="label.pdf"
                      requestDocs={requestDocs}
                      loading={loading}
                    />
                  </div>
                )}
                {!labelUrl && (
                  <div>
                    <FvButton
                      icon="tags"
                      iconClass="fa-fw"
                      onClick={() => setActivePanel('labels')}
                      className="p-3 pl-0 block w-full text-left"
                      loading={loading}
                    >
                      Print Labels
                    </FvButton>
                  </div>
                )}
              </>
            )}

            <a
              href="#"
              className="block p-3 pl-0"
              onClick={e => {
                e.preventDefault()
                setActivePanel('share')
              }}
            >
              <Icon icon="share" className="fa-fw" />
              <span>Share this shipment</span>
            </a>
          </div>
        </div>

        <div className="p-6 bg-[#fafafa] overflow-auto">
          {trackingNumber && deliveryDate && (
            <>
              <p className="mb-0">
                {getTrackingNumLabel(shipmentQuery.data.workflow)}{' '}
                {carrierTracking ? (
                  <CarrierTrackingLink
                    Button={props => (
                      <a {...props} className="callout-link">
                        {trackingNumber}
                      </a>
                    )}
                    carrierTracking={carrierTracking}
                  />
                ) : (
                  trackingNumber
                )}{' '}
                {currentStatus === 'delivered'
                  ? 'was delivered on '
                  : 'is estimated to deliver on '}
                {dayjs(deliveryDate).format('dddd, MMM D')}
              </p>
              <hr />
            </>
          )}

          <ShipmentTrackingBar tracking={shipmentQuery.data?.tracking} />
          <SharedShipmentCard shipment={shipmentQuery.data} />
        </div>

        <div className="p-6 border-fv-gray border-l overflow-auto">
          {status === 'canceled' && (
            <InfoBox>This shipment has been canceled.</InfoBox>
          )}

          <p className="mb-0 !leading-[1.6rem]">
            {refNums?.length > 0 &&
              `Ref# ${refNums.map(n => n.value).join(' / ')}`}
            {refNums?.length > 0 && <br />}
            {pickup?.confirmationNumber &&
              `Pickup Confirmation# ${pickup.confirmationNumber}`}
            {pickup?.confirmationNumber && <br />}
            {bol?.bolNumber && `BOL# ${bol.bolNumber}`}
            {bol?.bolNumber}
          </p>
          <div className="shipment-list-documents-ctn">
            <p className="mb-0">Documents for this shipment</p>
          </div>

          <hr className="mx-0 my-2" />
          <DocumentList
            documents={documents}
            onClick={doc => requestDocs(doc.url)}
          />
        </div>

        <div className="public-shipment-footer col-span-full">
          <div className="divided-content divided-content--start flex !justify-center">
            <CarrierTrackingLink
              Button={props => (
                <a {...props}>
                  <Icon icon="external-link-alt" className="fa-fw" />
                  <span>View carrier tracking</span>
                </a>
              )}
              carrierTracking={carrierTracking}
            />

            {carrierPhone && (
              <a href={`tel:${carrierPhone}`}>
                <Icon icon="phone" />
                <span>Contact carrier at {carrierPhone}</span>
              </a>
            )}
          </div>
        </div>
      </div>

      <SliderPanel isOpen={activePanel === 'share'} closePanel={closePanel}>
        <SharePanelHeader
          className="header-divider mb-6"
          closePanel={closePanel}
        />

        <TextAreaField
          className="form-control mb-4"
          name="message"
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setMessage(e.target.value)
          }}
          placeholder="Enter optional message here"
          readOnly={shareForm.isSaving}
          value={message}
        />

        <ShareForm form={shareForm} shareShipment={shareShipment} />
      </SliderPanel>

      <SliderPanel closePanel={closePanel} isOpen={activePanel === 'labels'}>
        <ShipmentLabels
          load={shipmentQuery.data}
          setActiveSlider={closePanel}
          onSubmit={async labelOpts => {
            await requestDocs(
              composeLabelsUrl({ ...labelOpts, isPublic: true }),
            )
            closePanel()
          }}
        />
      </SliderPanel>
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={recaptchaKey} />
    </div>
  )
}
