import { type IconProps } from '@fv/client-components'
import { type LoadDocument, type ShareStatus } from '@fv/client-types'

// https://github.com/freightview/freightview/blob/7310ca589f927cd0d6a9ab8df87f131b721c4943/src/client/app/views/shipment-documents/shipment-documents-item.coffee#L20-L21
export const filterDocs = (d: LoadDocument) => {
  if (d.type === 'invoice') return false
  if (d.source === 'system' && d.type === 'quote') return false
  if (d.source === 'carrier' && ['invoice', 'other'].includes(d.type)) {
    return false
  }
  return true
}

type DeliveryStatusProps = {
  icon: IconProps['icon']
  label: 'Delivered' | 'Sent' | 'Failed'
}
export const getDeliveryStatusProps = (
  status: ShareStatus,
): DeliveryStatusProps => {
  switch (status) {
    case 'delivered':
      return { icon: 'check', label: 'Delivered' }
    case 'queued':
    case 'sent':
      return { icon: 'paper-plane', label: 'Sent' }
    default:
      return { icon: 'ban', label: 'Failed' }
  }
}
