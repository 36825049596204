import Fuse from 'fuse.js'
import { useMemo } from 'react'

type StringKeys<T> = Extract<keyof T, string>

type Options<T> = {
  keys?: Array<StringKeys<T>>
  threshold?: number
}
export const useFuseSearch = <T>(
  items: T[] | undefined,
  options: Options<T>,
) => {
  const fuse = useMemo(
    () =>
      new Fuse(items ?? [], {
        includeScore: true,
        ignoreLocation: true,
        keys: options.keys,
        threshold: options.threshold,
      }),
    [items, options],
  )
  return fuse
}
