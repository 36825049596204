import { useState } from 'react'

import {
  ClipboardButton,
  FvLinkButton,
  TextAreaField,
  ValidatedForm,
} from '@fv/client-components'
import { showApiError, useFormModelState } from '@fv/client-core'
import { type ApiKeyVersion } from '@fv/models'
import { capitalizeFirstLetter } from '@fv/models/core'

import { FormActions } from '../../../components/forms/FormActions'
import InfoBox from '../../../components/InfoBox'
import {
  InputAdornment,
  InputGroup,
  InputGroupWrapper,
} from '../../../components/inputs/InputGroup'
import { SliderPanelLayout } from '../../../components/SliderPanelLayout'
import { useAppContext } from '../../../hooks/settings'
import { useCreateApiKey } from './mutations'
import { apiDocumentationLink } from './utils'

type CreateFormProps = {
  onClose: () => void
  version: ApiKeyVersion
}
export const CreateApiKeyForm = ({ onClose, version }: CreateFormProps) => {
  const keyLabel = version === 1 ? 'API Key' : 'client secret'
  const developerLink = apiDocumentationLink(version)
  const createKey = useCreateApiKey()
  const contextQuery = useAppContext()
  const { _id: clientId } = contextQuery.data?.user ?? {}
  const [apiKey, setApiKey] = useState<string>()
  const { register, value } = useFormModelState({ initialValue: { name: '' } })
  const handleSubmit = async () => {
    try {
      const result = await createKey.mutateAsync({
        name: value.name,
        version,
      })
      setApiKey(result.apiKey)
    } catch (e) {
      showApiError(`Couldn't create api key`, e)
    }
  }

  return (
    <SliderPanelLayout title={`Create a ${keyLabel}`} noBorder>
      {!apiKey ? (
        <ValidatedForm onValidSubmit={handleSubmit}>
          <InfoBox>
            Give this credential a name to help you identify where it's being
            used later.
          </InfoBox>
          <InputGroup
            inputType="text"
            label="Name"
            required
            inputProps={{
              ...register('name'),
              disabled: createKey.isLoading,
            }}
          />
          <FormActions onCancel={onClose} loading={createKey.isLoading} />
        </ValidatedForm>
      ) : (
        <div>
          <InfoBox>
            <p>
              Copy the {keyLabel} below and keep it somewhere safe. Once you
              close this slider, you will not be able to view this secret again.
            </p>
            <div className="bg-fv-beer-50 border border-fv-gray text-sm p-2 mt-3 rounded-sm">
              You can find documentation for v{version} of the API here:{' '}
              <FvLinkButton
                theme="underlined"
                href={developerLink}
                rel="noreferrer"
                target="_blank"
                className="text-nowrap"
              >
                {developerLink}
              </FvLinkButton>
            </div>
          </InfoBox>
          {version === 2 && (
            <>
              <InputGroup
                inputType="text"
                label="Client ID"
                inputProps={{
                  name: 'clientId',
                  value: clientId,
                  readOnly: true,
                }}
                endContent={
                  <InputAdornment>
                    <ClipboardButton value={apiKey} />
                  </InputAdornment>
                }
              />
            </>
          )}
          <InputGroup
            inputType="text"
            label={capitalizeFirstLetter(keyLabel)}
            inputProps={{
              name: 'apiKey',
              value: apiKey,
              readOnly: true,
            }}
            endContent={
              <InputAdornment>
                <ClipboardButton value={apiKey} />
              </InputAdornment>
            }
          />
          <InputGroupWrapper label="Example usage" name="example">
            <TextAreaField
              className="font-mono bg-fv-gray-200 w-full p-2 text-xs resize-none border-fv-gray-500 border h-60"
              name="example"
              readOnly
            >
              {version === 1
                ? getBasicCurlEx(apiKey)
                : getClientCredCurlEx(clientId, apiKey)}
            </TextAreaField>
          </InputGroupWrapper>
          <FormActions submitText="Close" onSubmit={onClose} />
        </div>
      )}
    </SliderPanelLayout>
  )
}

const getClientCredCurlEx = (clientId: string, clientSecret: string) =>
  `curl -H 'Content-Type: application/json' \\
-d '{ "client_id": "${clientId}", "client_secret": "${clientSecret}", "grant_type": "client_credentials" }' \\
-X POST \\
https://api.freightview.com/v2.0/auth/token
`

const getBasicCurlEx = (apiKey: string) =>
  `curl -u ${apiKey}: \\
-H "Content-Type: application/json" \\
-X POST "https://www.freightview.com/api/v1.0/rates" \\
-d '{
    "pickupDate":"2020-08-20",
    "originPostalCode":"30303",
    "destPostalCode":"60606",
    "items":[{
        "weight":500,
        "freightClass":50
        }]
}'`
