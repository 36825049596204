import { useState } from 'react'

import {
  CarrierTrackingLink,
  FvButton,
  FvLinkButton,
  Icon,
  ManualTrackForm,
  ValidatedForm,
} from '@fv/client-components'
import { getCurrentSequence, getTrackingNumLabel } from '@fv/client-core'
import { type ShipmentLocation } from '@fv/client-types'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { UpdateTrackingSequenceForm } from '../../components/list/UpdateTrackingSequenceForm'
import { usePermissions } from '../auth'
import { useTracking } from '../track/queries'
import { TrackingHistory } from '../track/TrackingHistory'
import { useEditTrackingNumber } from '../track/useEditTrackingNumber'
import { useUpdateTrackStatus } from '../track/useUpdateTrackStatus'
import {
  SliderContentFooter,
  SliderContentHeader,
  SliderContentLayout,
} from './SliderContentLayout'
import { type ShipmentContentProps } from './types'

type TrackingFormProps = {
  closeForm: () => void
  loadId: string
  trackingNumber?: string
}

const TrackingNumberForm = ({
  closeForm,
  loadId,
  trackingNumber,
}: TrackingFormProps) => {
  const editTrackingNum = useEditTrackingNumber(loadId)

  async function onValidSubmit(form: HTMLFormElement) {
    if (editTrackingNum.isLoading) return

    const input = form.elements.namedItem('trackingNumber') as HTMLInputElement
    const value = input.value.trim()

    if (value === trackingNumber) closeForm()
    else editTrackingNum.mutate(value, { onSuccess: closeForm })
  }

  return (
    <ValidatedForm onValidSubmit={onValidSubmit}>
      <InputGroup
        inputType="text"
        className="my-4"
        inputProps={{
          name: 'trackingNumber',
          defaultValue: trackingNumber,
          onChange: undefined,
        }}
        endContent={
          <InputAdornment>
            <FvButton
              theme="default"
              icon={editTrackingNum.isLoading ? 'spinner' : 'check'}
              type="submit"
            >
              Save
            </FvButton>
          </InputAdornment>
        }
      />
    </ValidatedForm>
  )
}

type TrackingStatusFormProps = {
  closeForm: () => void
  currentSequence: number
  loadId: string
  locations: ShipmentLocation[]
}

const TrackingStatusForm = ({
  closeForm,
  currentSequence,
  loadId,
  locations,
}: TrackingStatusFormProps) => {
  const [nextSequence, setNextSequence] = useState('')
  const updateTrackStatus = useUpdateTrackStatus(loadId)
  const trackSequence = nextSequence || String(currentSequence)

  return !nextSequence ? (
    <UpdateTrackingSequenceForm
      currentSequence={Number(trackSequence)}
      locations={locations}
      setSequence={setNextSequence}
    />
  ) : (
    <ManualTrackForm
      closeForm={closeForm}
      initialSequence={Number(nextSequence)}
      loading={updateTrackStatus.isLoading}
      locations={locations}
      onSave={updateTrackStatus.mutate}
    />
  )
}

export const TrackingPanel = ({
  load,
  item,
  setActiveSlider,
}: ShipmentContentProps & { item?: { id: string; index: number } }) => {
  const { data } = useTracking(load.loadId)
  const { canBook } = usePermissions()
  const { carrierTracking, loadId, workflow } = load
  const [showEditPro, setShowEditPro] = useState(false)
  const [showEditTracking, setShowEditTracking] = useState(false)

  const tracking = item
    ? data?.items?.find(i => i.itemId === item.id && i.unitIndex === item.index)
    : data

  const defaultNav = (
    <>
      <FvLinkButton icon="hashtag" onClick={() => setShowEditPro(p => !p)}>
        Add or Edit {getTrackingNumLabel(workflow)}
      </FvLinkButton>
      <FvLinkButton
        icon="thumbtack"
        transform="down-1"
        onClick={() => {
          setShowEditPro(false)
          setShowEditTracking(p => !p)
        }}
      >
        Update tracking status
      </FvLinkButton>
    </>
  )

  const formNav = (
    <FvLinkButton
      icon="arrow-to-left"
      onClick={() => setShowEditTracking(p => !p)}
    >
      Back
    </FvLinkButton>
  )

  return (
    <SliderContentLayout>
      <SliderContentHeader
        {...load}
        tracking={tracking}
        showBolNumber={false}
        trackingNumLabel={getTrackingNumLabel(workflow)}
      />

      {canBook && !item && (
        <div className="divided-content divided-content--start">
          {!showEditTracking ? defaultNav : formNav}
        </div>
      )}

      {showEditPro && (
        <TrackingNumberForm
          closeForm={() => setShowEditPro(false)}
          loadId={loadId}
          trackingNumber={tracking?.trackingNumber}
        />
      )}

      {canBook && !item && !showEditPro && <hr />}
      <div className="flex-1 overflow-auto">
        {showEditTracking ? (
          <TrackingStatusForm
            closeForm={() => setShowEditTracking(false)}
            currentSequence={getCurrentSequence(tracking)}
            loadId={loadId}
            locations={load.locations}
          />
        ) : (
          <TrackingHistory
            tracking={tracking}
            trackingEvents={tracking?.trackEvents}
            theme="full"
            loadId={loadId}
          />
        )}
      </div>

      {!item && (
        <SliderContentFooter>
          <div className="divided-content divided-content--start !justify-center">
            <FvLinkButton onClick={() => setActiveSlider('share')} icon="share">
              Share
            </FvLinkButton>

            {!!carrierTracking && (
              <CarrierTrackingLink
                Button={props => (
                  <a {...props}>
                    <Icon icon="square-arrow-up-right" />
                    <span>External tracking</span>
                  </a>
                )}
                carrierTracking={carrierTracking}
              />
            )}
          </div>
        </SliderContentFooter>
      )}
    </SliderContentLayout>
  )
}
