import { getProperty } from 'dot-prop'
import { type PropsWithChildren } from 'react'

import YesNoInput from '../../../components/inputs/YesNoInput'
import { List } from '../../../components/List'
import { HasFeature, useAccountFeatures } from '../../auth'
import { SettingsSection } from '../SettingsSection'
import {
  addressBookSettings,
  auditSettings,
  bookingSettings,
  notificationSettings,
  productCatalogSettings,
  quotingSettings,
  tagSettings,
} from './account-settings-config'
import { AccountSettingRow } from './AccountSettingRow'
import { AuditThresholdForm } from './AuditThresholdForm'
import { CarrierEmailSuppressionForm } from './CarrierEmailSuppressionForm'
import { DefaultWorkflowForm } from './DefaultWorkflowForm'
import { useAccountSettings } from './hooks'
import { useAccountSettingMutation } from './mutations'
import { RoutingSoftwareForm } from './RoutingSoftwareForm'
import { ShippingLabelForm } from './ShippingLabelForm'
import { TruckloadSettings } from './TruckloadSettings'
import { type AccountSettingsFormItem } from './types'

const AccountSettingsList = ({
  settings,
}: {
  settings: AccountSettingsFormItem[]
}) => {
  const accountSettings = useAccountSettings()
  const { updateAccountSetting } = useAccountSettingMutation(
    accountSettings,
    true,
  )

  return (
    <>
      {settings.map(s => (
        <AccountSettingRow key={s.setting} label={s.label} name={s.setting}>
          <YesNoInput
            checked={!!getProperty(accountSettings, s.setting)}
            name={s.setting}
            onChange={v => updateAccountSetting(s.setting, v.target.checked)}
          />
        </AccountSettingRow>
      ))}
    </>
  )
}

const AccountSettingsSection = ({
  title,
  children,
  settings,
}: PropsWithChildren<{
  title: string
  settings?: AccountSettingsFormItem[]
}>) => {
  return (
    <SettingsSection title={title}>
      <List fcp0 lcp0>
        {settings && <AccountSettingsList settings={settings} />}
        {children}
      </List>
    </SettingsSection>
  )
}

const AccountSettingsPage = () => {
  const { truckLoad } = useAccountFeatures()
  return (
    <div className="settings-main">
      <AccountSettingsSection title="Quoting" settings={quotingSettings}>
        <DefaultWorkflowForm />
        <HasFeature name="fuelAndDistance">
          <RoutingSoftwareForm />
        </HasFeature>
      </AccountSettingsSection>
      <AccountSettingsSection title="Booking" settings={bookingSettings}>
        <CarrierEmailSuppressionForm />
      </AccountSettingsSection>
      {truckLoad && (
        <AccountSettingsSection title="Truckload">
          <TruckloadSettings />
        </AccountSettingsSection>
      )}
      <AccountSettingsSection title="Labels">
        <ShippingLabelForm />
      </AccountSettingsSection>
      <AccountSettingsSection title="Audit" settings={auditSettings}>
        <HasFeature name="automatedAuditing">
          <AuditThresholdForm />
        </HasFeature>
      </AccountSettingsSection>
      <AccountSettingsSection
        title="Product Catalog"
        settings={productCatalogSettings}
      />
      <AccountSettingsSection
        title="Notifications"
        settings={notificationSettings}
      />
      <AccountSettingsSection
        title="Address Book"
        settings={addressBookSettings}
      />
      <AccountSettingsSection title="Tags" settings={tagSettings} />
    </div>
  )
}

export default AccountSettingsPage
