import { PropsWithChildren } from 'react'

import { PropsWithClassName } from '@fv/client-types'

type InputGroupProps = {
  label: string
}

export function InputGroup({
  children,
  className,
  label,
}: PropsWithClassName<PropsWithChildren<InputGroupProps>>) {
  return (
    <div className={`${className} input-group flex flex-col`}>
      <label className="mb-2">{label}:</label>
      {children}
    </div>
  )
}
