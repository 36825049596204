import clsx from 'clsx'
import { useState } from 'react'

import { FvLinkButton } from '@fv/client-components'

import InfoBox from '../../components/InfoBox'
import { List, ListItem } from '../../components/List'
import { Loading } from '../../components/Loading'
import { PageHeader } from '../settings/PageHeader'
import { NotificationRow } from './NotificationRow'
import { useDelete, useNotifications } from './useNotification'

export const NotificationsPage = () => {
  const [filter, setFilter] = useState('unread')
  const notificationsQuery = useNotifications(filter)
  const notifications = notificationsQuery?.data
  const deleteOne = useDelete()

  const deleteNotification = async (id: string) => {
    if (!id) return
    if (window.confirm('Are you sure you to delete this notification?')) {
      await deleteOne.mutateAsync(id)
    }
  }

  const isLoading = notificationsQuery.isLoading

  const Nav = () => {
    return (
      <div className="border-r-fv-gray overflow-auto border-r bg-fv-blue-75 py-8">
        <PageHeader
          title="Notifications"
          noBorder
          icon="bell-on"
          className="pl-5"
        />
        <List className="border-fv-blue-200 border-b">
          <ListItem
            custom
            className={clsx('standard-list__action !border-fv-blue-200', {
              'bg-fv-beer ': filter === 'unread',
            })}
          >
            <FvLinkButton
              icon="envelope"
              fw
              onClick={() => setFilter('unread')}
            >
              Unread
            </FvLinkButton>
          </ListItem>
          <ListItem
            custom
            className={clsx('standard-list__action !border-fv-blue-200', {
              'bg-fv-beer ': filter === 'read',
            })}
          >
            <FvLinkButton
              icon="envelope-open"
              transform="up-1"
              fw
              onClick={() => setFilter('read')}
            >
              Read
            </FvLinkButton>
          </ListItem>
        </List>
      </div>
    )
  }

  return (
    <>
      <Nav />
      <div className="overflow-auto bg-fv-gray-100 p-6">
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <ul>
              {notifications?.map((n, index) => (
                <NotificationRow
                  key={index}
                  html={n.html}
                  handleDelete={() => deleteNotification(n._id)}
                />
              ))}
            </ul>

            {!notifications?.length && (
              <InfoBox custom={true}>
                You do not have any {filter} notifcations at the moment.
              </InfoBox>
            )}
          </div>
        )}
      </div>
    </>
  )
}
