import {
  type BookFormLoad,
  type LoadToBook,
} from '../../features/booking/types'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import { buildFormCommodity } from '../commodities/commodityUtils'

export function buildCommodities(
  load: LoadToBook,
  dropSequence: number,
): FormCommodity[] {
  if (!load.items?.length) {
    const {
      declaredValue,
      declaredValueCurrency,
      description,
      isHazardous,
      weight,
      weightUOM,
    } = load.equipment

    return [
      buildFormCommodity({
        dropSequence,
        item: {
          declaredValue,
          declaredValueCurrency,
          description,
          weight,
          weightUOM,
          ...(isHazardous && { hazardous: [] }),
        },
        loadId: load.loadId,
      }),
    ]
  }

  return load.items.map(i =>
    buildFormCommodity({
      dropSequence: i.dropLocationSequence ?? dropSequence,
      item: i,
      loadId: load.loadId,
      pickSequence: i.pickupLocationSequence ?? 0,
    }),
  )
}

export function getBookingItemsBySequence(
  loads: BookFormLoad[],
  sequence: number,
): FormCommodity[] {
  return loads.reduce(
    (items, load) =>
      items.concat(
        load.items.filter(
          i => i.pickSequence === sequence || i.dropSequence === sequence,
        ),
      ),
    [] as FormCommodity[],
  )
}
