import { useState } from 'react'

import { FvLinkButton } from '@fv/client-components'

import InfoBox from '../../components/InfoBox'
import UploadDocumentField from '../../components/inputs/UploadDocumentField'
import DocumentList from '../documents/DocumentList'
import {
  SliderContentFooter,
  SliderContentHeader,
  SliderContentLayout,
} from './SliderContentLayout'
import {
  type DetailsDocumentPanelProps,
  type ShipmentContentProps,
} from './types'

export const DetailsDocumentPanel = ({
  setActiveSlider,
  load,
  uploadFileType,
}: DetailsDocumentPanelProps & ShipmentContentProps) => {
  const [showUploadForm, setShowUploadForm] = useState(!!uploadFileType)
  const { documents, loadId, locations, bol } = load
  return (
    <SliderContentLayout>
      <SliderContentHeader
        {...{ loadId, locations, bol }}
        prefix="Documents for"
      />

      <InfoBox>
        <p className="!leading-[1.6rem]">
          Current documents are shown below. To upload a new file select 'Add
          document' below.
        </p>
      </InfoBox>

      <DocumentList
        className="mb-6 overflow-auto"
        canDelete={true}
        {...{ documents, loadId }}
      />

      <SliderContentFooter>
        {!showUploadForm ? (
          <div className="divided-content divided-content--start !justify-center">
            <FvLinkButton onClick={() => setActiveSlider('share')} icon="share">
              Share
            </FvLinkButton>
            <FvLinkButton onClick={() => setShowUploadForm(true)} icon="upload">
              Add document
            </FvLinkButton>
          </div>
        ) : (
          <UploadDocumentField
            handleClose={() => setActiveSlider(undefined)}
            loadId={load.loadId}
            requireDocType
            defaultDocType={uploadFileType}
          />
        )}
      </SliderContentFooter>
    </SliderContentLayout>
  )
}
