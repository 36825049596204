import { vDate, vObject, vOptional, vOutput, vString } from '../validation'

export const updateBidExpirationDto = vObject({
  local: vOptional(vString()),
  utc: vOptional(vDate()),
})
export type UpdateBidExpirationDto = vOutput<typeof updateBidExpirationDto>

export interface LoadBidExpiration {
  utc: Date
  local: string
}
