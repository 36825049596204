import { type FormRegister, type FormSchema } from '@fv/client-core'
import {
  type DeepPartial,
  type HazardousDetail,
  type LegacyReadOnlyDetail,
  type LoadHandlingUnit,
  type LoadItem,
  type PropsWithClassName,
} from '@fv/client-types'

import { type RadioFieldOption } from '../../../components/inputs/RadioField'
import { isCarrierPackaging } from '../../../utils/isCarrierPackaging'
import { validateNmfc } from '../commodityUtils'
import { type LoadItemsStore } from './loadItemsFormState'

export type HandlingUnitType = 'single' | 'multi'
export type LoadStep = 'quoting' | 'booking'
export type LoadItemFormModel = DeepPartial<
  Omit<LoadItem, 'hazardous'> & {
    hazardous?: HazardousDetail
    fullNmfc?: string
    isHazardous?: boolean
  }
> & {
  _id: string
}

// TODO
// There's lots of dancing around hazaroud being an array on the server and a single item on the client
// the server needs to be changed to have a single hazardous item in its schema
// but its exposed to the external API
export type LoadHandlingUnitFormModel = LoadItemFormModel &
  // get rid of load item properties and take them from LoadItemFormModel instead
  DeepPartial<
    Omit<LoadHandlingUnit, 'contains' | keyof LoadItem> & {
      readOnly?: LegacyReadOnlyDetail
      handlingUnitType?: HandlingUnitType
    }
  > & {
    contains?: LoadItemFormModel[]
  }

export type HandlingUnitFormUpdate = Omit<LoadHandlingUnitFormModel, '_id'>
export type ItemFormUpdate = Omit<LoadItemFormModel, '_id'>

export type ChangeFn = (v: HandlingUnitFormUpdate) => void
export type ItemChangeFn = (v: ItemFormUpdate) => void

export type FormProps = {
  value: LoadHandlingUnitFormModel
  onChange: ChangeFn
}

export type ItemFieldProps = PropsWithClassName<{
  label?: string
  register: FormRegister<LoadItemFormModel>
}>
export type HandlingUnitFieldProps = Omit<ItemFieldProps, 'register'> & {
  register: FormRegister<LoadHandlingUnitFormModel>
}

export type DetailedItemFieldProps = ItemFieldProps & {
  value: LoadHandlingUnitFormModel
  onChange: ItemChangeFn
}
export type DetailedFieldProps = HandlingUnitFieldProps & {
  value: LoadHandlingUnitFormModel
  onChange: ChangeFn
}

export const unitTypeOptions: Array<RadioFieldOption<HandlingUnitType>> = [
  { label: 'Same commodity handling unit(s)', value: 'single' },
  { label: 'Multi-commodity handling unit(s)', value: 'multi' },
]

export type FormLayoutProps = {
  register: FormRegister<LoadHandlingUnitFormModel>
  value: LoadHandlingUnitFormModel
  onChange: ChangeFn
}

const requireDims = (v: LoadHandlingUnitFormModel) =>
  !!(v.width || v.height || v.length)

export const LoadHandlingUnitSchema: FormSchema<LoadHandlingUnitFormModel> = {
  stackable: {
    valueAsChecked: true,
    readOnly: v => v.readOnly?.stackable ?? false,
  },
  nonStandard: {
    valueAsChecked: true,
    readOnly: v =>
      v.readOnly?.nonStandard ?? (false || isCarrierPackaging(v.type)),
  },
  quantity: {
    min: 1,
    valueAsNumber: true,
    readOnly: v => v.readOnly?.pieces ?? false,
    required: () => true,
  },
  type: {
    readOnly: v => v.readOnly?.package ?? false,
  },
  weight: {
    valueAsNumber: true,
    min: 1,
    readOnly: v => v.readOnly?.weight ?? false,
    required: () => true,
  },
  height: {
    min: 1,
    valueAsNumber: true,
    readOnly: v => (v.readOnly?.height ?? false) || isCarrierPackaging(v.type),
    required: requireDims,
  },
  width: {
    min: 1,
    valueAsNumber: true,
    readOnly: v => (v.readOnly?.width ?? false) || isCarrierPackaging(v.type),
    required: requireDims,
  },
  length: {
    min: 1,
    valueAsNumber: true,
    readOnly: v => (v.readOnly?.length ?? false) || isCarrierPackaging(v.type),
    required: requireDims,
  },
  fullNmfc: {
    readOnly: v => v.readOnly?.nmfc ?? false,
    validate: validateNmfc,
  },
  isHazardous: {
    valueAsChecked: true,
  },
}
export const LoadItemSchema: FormSchema<LoadItemFormModel> = {
  quantity: {
    min: 1,
    valueAsNumber: true,
    required: v => !!v.type,
  },
  isHazardous: {
    valueAsChecked: true,
  },
}

export type LoadItemsFormProviderProps = {
  store: LoadItemsStore
}
