type Props = {
  count: number
}
export const CountIndicator = ({ count }: Props) => {
  return (
    <div className="bg-fv-beer-light border-fv-beer-700 min-w-[2.5rem] rounded-lg border px-1 py-[.1rem] text-center text-sm">
      {count}
    </div>
  )
}
