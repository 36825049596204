import { useState } from 'react'

import { CheckboxField, FvButton, ValidatedForm } from '@fv/client-components'
import { useFormModel } from '@fv/client-core'
import {
  type LTLCatalogSettings,
  type ParcelCatalogSettings,
  type ProductMode,
} from '@fv/client-types'

import InfoBox from '../../../components/InfoBox'
import { useAccountSettings } from '../account-settings/hooks'
import { useAccountSettingMutation } from '../account-settings/mutations'

const defaultLtlSettings: LTLCatalogSettings = {
  class: false,
  dims: false,
  hazardous: false,
  nmfc: false,
  package: false,
  quantity: false,
  saidToContain: false,
  stackable: false,
  weight: false,
}
const defaultParcelSettings: ParcelCatalogSettings = {
  declaredValue: false,
  dims: false,
  nonStandard: false,
  package: false,
  weight: false,
}

type FormProps = {
  onCancel?: () => void
  onSave?: () => void
}
type Props = FormProps & {
  mode: ProductMode
}
export const ProductSettings = ({ mode, ...formProps }: Props) => {
  return <FormElements type={mode} {...formProps} />
}

type FormElementsProps = FormProps & {
  type: 'ltl' | 'parcel'
}
const FormElements = ({ type, onSave, onCancel }: FormElementsProps) => {
  const accountSettings = useAccountSettings()
  const def = type === 'parcel' ? defaultParcelSettings : defaultLtlSettings
  const [model, setModel] = useState({
    ...def,
    ...(accountSettings?.productCatalog?.[type] ?? {}),
  })
  const { updateAccountSettings, isBusy } = useAccountSettingMutation(
    accountSettings,
    true,
  )
  const { register } = useFormModel<LTLCatalogSettings | ParcelCatalogSettings>(
    {
      onChange: setModel,
      value: model,
      schema: Object.fromEntries(
        Object.keys(def).map(key => [key, { valueAsChecked: true }]),
      ),
    },
  )

  const handleSubmit = async () => {
    await updateAccountSettings(
      Object.fromEntries(
        Object.entries(model).map(([key, value]) => [
          `productCatalog.${type}.${key}`,
          value,
        ]),
      ),
    )
    onSave?.()
  }
  return (
    <ValidatedForm
      onValidSubmit={handleSubmit}
      className="flex h-full flex-col"
    >
      <div className="overflow-auto">
        <InfoBox className="mb-8">
          Check which product details are auto-filled when selecting a
          Freightview generated product from your catalog. Any products you add
          manually have their own preferences and will override this setting.
        </InfoBox>
        <div className="mb-10 flex flex-col gap-4">
          {type === 'ltl' && (
            <>
              <CheckboxField label="Package type" {...register('package')} />
              <CheckboxField label="Quantity" {...register('quantity')} />
              <CheckboxField label="Weight" {...register('weight')} />
              <CheckboxField label="Dims" {...register('dims')} />
              <CheckboxField label="NMFC" {...register('nmfc')} />
              <CheckboxField label="Class" {...register('class')} />
              <CheckboxField label="Hazardous" {...register('hazardous')} />
              <CheckboxField
                label="Said to contain"
                {...register('saidToContain')}
              />
              <CheckboxField label="Stackable" {...register('stackable')} />
            </>
          )}
          {type === 'parcel' && (
            <>
              <CheckboxField label="Package type" {...register('package')} />
              <CheckboxField label="Weight" {...register('weight')} />
              <CheckboxField label="Dims" {...register('dims')} />
              <CheckboxField
                label="Declared value"
                {...register('declaredValue')}
              />
              <CheckboxField
                label="Unboxed or crated?"
                {...register('nonStandard')}
              />
            </>
          )}
        </div>
      </div>
      <div className="flyout-footer-actions flex items-center">
        <FvButton type="submit" icon="check" theme="primary" loading={isBusy}>
          Save
        </FvButton>
        <FvButton theme="plain" onClick={onCancel} icon="times">
          Cancel
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
