import dayjs from 'dayjs'

type HolidayReturnType = {
  key: string
  label: string
  holiday: string
  observed: string[]
}
export const getHolidayMessage = (holiday: HolidayReturnType): string => {
  let prefix = ''
  switch (holiday.key) {
    case 'new_years':
      prefix = 'Happy New Year!'
      break
    case 'memorial_day':
      prefix = 'In honor of Memorial Day'
      break
    case 'independence_day':
      prefix = 'Happy 4th of July!'
      break
    case 'labor_day':
      prefix = 'Happy Labor Day!'
      break
    case 'thanksgiving':
      prefix = 'Have a great Thanksgiving!'
      break
    case 'christmas':
      prefix = 'Happy holidays!'
      break
  }

  const datesMsg = `Carriers aren't making pickups on ${holiday.observed.map(o => dayjs(o).format('ddd MMM D')).join(' and ')}`
  return `${prefix} ${datesMsg}`
}
