import clsx from 'clsx'
import pluralize from 'pluralize'
import { type RefObject, useEffect, useRef } from 'react'

import { FvButton } from '@fv/client-components'
import { toOxfordComma } from '@fv/client-core'

import InfoBox from '../../components/InfoBox'
import { ValidatedForm } from '../../components/inputs'
import {
  commoditiesKey,
  type FormSectionKey,
} from '../../features/booking/types'
import { type FormCommodity } from '../../features/commodities/FormCommodity'
import { type FormLocation } from '../../types/FormLocation'
import { getFirstSequenceOfType } from '../../utils/shipmentFuncs'
import { buildFormCommodity } from '../commodities/commodityUtils'
import { LoadItemFields } from './LoadItemFields'

type Props = {
  commodities: FormCommodity[]
  loadId: string
  locations: FormLocation[]
  onValidSubmit: () => void
  requiresDeclaredValue: boolean
  setCommodities: (cb: (p: FormCommodity[]) => FormCommodity[]) => void
  setFormRef: (
    key: FormSectionKey,
    ref: RefObject<HTMLFormElement | null>,
  ) => void
}

export const CommoditiesForm = ({
  commodities,
  loadId,
  locations,
  onValidSubmit,
  requiresDeclaredValue,
  setCommodities,
  setFormRef,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    setFormRef(commoditiesKey, formRef)
  }, [setFormRef])

  const locsMissingPick = locations.filter(loc => {
    return (
      (loc.stopType === 'pickup' || loc.stopType === 'both') &&
      !commodities.some(c => c.pickSequence === loc.sequence)
    )
  })

  const locsMissingDrop = locations.filter(loc => {
    return (
      (loc.stopType === 'delivery' || loc.stopType === 'both') &&
      !commodities.some(c => c.dropSequence === loc.sequence)
    )
  })

  function addCommodity() {
    const dropSequence = getFirstSequenceOfType(locations, 'delivery')

    setCommodities(prev =>
      prev.concat(buildFormCommodity({ dropSequence, loadId })),
    )
  }

  function setValuesById(id: string) {
    return (item: FormCommodity | null) => {
      setCommodities(prev =>
        item
          ? prev.map(c => (c.id === id ? item : c))
          : prev.filter(c => c.id !== id),
      )
    }
  }

  function buildCommoditiesWarning(
    type: 'delivery' | 'pickup',
    stops: FormLocation[],
  ) {
    if (!stops.length) return ''

    const locs = toOxfordComma(stops.map(loc => loc.company || loc.postalCode))
    const count = stops.length
    const is = pluralize('is', count)
    const has = pluralize('has', count)

    return `${locs} ${is} marked for ${type} but ${has} no items specified for ${type}.`
  }

  const showWarning = locsMissingPick.length > 0 || locsMissingDrop.length > 0

  return (
    <ValidatedForm onValidSubmit={onValidSubmit} ref={formRef}>
      {showWarning && (
        <InfoBox
          icon="exclamation-triangle"
          className={clsx(commodities.length > 1 && 'mb-6')}
        >
          {[
            buildCommoditiesWarning('pickup', locsMissingPick),
            buildCommoditiesWarning('delivery', locsMissingDrop),
          ].join(' ')}
        </InfoBox>
      )}

      {commodities.map((values, i) => {
        const setValues = setValuesById(values.id)

        return (
          <LoadItemFields
            autoFocus={!values.quantity && i === 0}
            canRemove={commodities.length > 1}
            className=""
            key={values.id}
            locations={locations}
            requiresDeclaredValue={requiresDeclaredValue}
            setValues={setValues}
            values={values}
          />
        )
      })}
      <div className="mt-8 flex items-center">
        <FvButton theme="secondary" fwd type="submit" icon="arrow-down-to-line">
          <span>Looks good, proceed</span>
        </FvButton>
        <span className="ml-6">- or -</span>
        <FvButton className="ml-4" icon="plus" onClick={addCommodity}>
          <span>Add another handling unit group</span>
        </FvButton>
      </div>
    </ValidatedForm>
  )
}
