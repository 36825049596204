import { ForwardedRef, forwardRef, HTMLProps, useRef } from 'react'

import { CarrierTracking } from '@fv/client-types'

type ComponentProps = HTMLProps<HTMLAnchorElement>
type Props = {
  carrierTracking?: CarrierTracking | null
  className?: string
  Button: (props: ComponentProps) => JSX.Element
}
const CarrierTrackingForm = forwardRef(
  (
    { carrierTracking }: Omit<Props, 'Button'>,
    ref: ForwardedRef<HTMLFormElement>,
  ) => {
    if (!carrierTracking?.verb || !carrierTracking?.action) {
      return null
    }
    return (
      <form
        action={carrierTracking.action}
        method={carrierTracking.verb}
        style={{ display: 'none' }}
        target="_blank"
        ref={ref}
      >
        {carrierTracking.params?.map(p => (
          <input key={p.name} name={p.name} readOnly value={p.val} />
        ))}
      </form>
    )
  },
)
export const CarrierTrackingLink = ({ carrierTracking, Button }: Props) => {
  const formRef = useRef<HTMLFormElement | null>(null)
  if (!carrierTracking) return null
  const isLink = carrierTracking.verb === 'link'
  const href = isLink ? carrierTracking.url : '#'

  return (
    <>
      {carrierTracking.verb === 'post' && (
        <CarrierTrackingForm ref={formRef} carrierTracking={carrierTracking} />
      )}
      <Button
        href={href}
        rel="noreferrer"
        target={isLink ? '_blank' : undefined}
        onClick={
          carrierTracking.verb === 'link'
            ? undefined
            : e => {
                e.preventDefault()
                formRef.current?.submit()
              }
        }
      />
    </>
  )
}
