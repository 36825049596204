import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  showApiError,
} from '@fv/client-core'
import {
  type BillToDTO,
  type EmergencyContact,
  type FullShipment,
  type MakeOptional,
  type QuoteRequestDTO,
  type ShipmentShare,
} from '@fv/client-types'

import { apiUri } from '../../constants'
import { loadKeys } from '../../hooks/shipments/useLoad'
import { type CustomsBroker } from '../customsBroker/brokerTypes'

export type CustomerRoutedBillTo = MakeOptional<
  BillToDTO,
  | 'company'
  | 'address'
  | 'city'
  | 'state'
  | 'postalCode'
  | 'contactName'
  | 'country'
>
export type CreateCustomerRoutedLoadDTO = Omit<QuoteRequestDTO, 'billTo'> & {
  carrierId: string
  createBOL?: boolean
  customsBroker?: CustomsBroker[]
  emergencyContact?: EmergencyContact
  schedulePickup?: boolean
  serviceId: string
  shares?: ShipmentShare[]
  billTo: CustomerRoutedBillTo
  tags?: string[]
}

async function createCustomerRoutedLoad(dto: CreateCustomerRoutedLoadDTO) {
  const endpoint = `${apiUri}/shipments/customer-routed`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(dto),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json as FullShipment[]
  throw response.errorMessage
}

export function useCreateCustomerRoutedLoad() {
  const queryClient = useQueryClient()

  return useMutation(createCustomerRoutedLoad, {
    onSettled: (shipments, error) => {
      if (error) return showApiError('Unable to create shipment', error)

      shipments?.forEach(s => {
        queryClient.setQueryData(loadKeys.id(s.loadId), s)
      })

      queryClient.invalidateQueries(['shipments'])
      toast.success('Shipment created successfully.')
    },
  })
}
