import clsx from 'clsx'
import uniq from 'lodash/uniq'
import { useState } from 'react'
import toast from 'react-hot-toast'

import { Icon, ValidatedForm } from '@fv/client-components'
import {
  createCredentialDetailsFromModel,
  createCredentialEditModel,
  useFormModel,
} from '@fv/client-core'
import {
  type CarrierLocationStatusType,
  type ProvisionerLocationDto,
} from '@fv/client-types'

import { FormActions } from '../../../components/forms/FormActions'
import { Loading } from '../../../components/Loading'
import { useProvisionerAccountId } from '../hooks'
import { useTestLocation, useUpdateLocationProvisioning } from '../mutations'
import { useProvisionerCarrierConfig } from '../queries'
import { ProvisioningField } from './ProvisioningField'
import { ProvisionerSection } from './Section'

export const ProvisionerLocation = ({
  loc,
}: {
  loc: ProvisionerLocationDto
}) => {
  const accountId = useProvisionerAccountId()
  const { data: config, isLoading } = useProvisionerCarrierConfig()
  const updateLoc = useUpdateLocationProvisioning()
  const testLoc = useTestLocation()
  const credTypes = config?.locationCredentialTypes ?? []
  const [errors, setErrors] = useState<string[]>([])
  const [model, setModel] = useState(
    createCredentialEditModel(credTypes, loc.details ?? []),
  )
  const { register } = useFormModel({ value: model, onChange: setModel })
  const handleSubmit = async (status: CarrierLocationStatusType) => {
    if (!accountId) return
    setErrors([])
    const details = createCredentialDetailsFromModel(model)
    await updateLoc.mutateAsync({
      accountId,
      locationId: loc.locationId,
      model: { status, details },
    })
    if (status === 'active') {
      const result = await testLoc.mutateAsync({
        accountId,
        locationId: loc.locationId,
      })
      if (!result.success) {
        setErrors(
          result.quotes?.flatMap(q => q.errors?.map(e => e.message) ?? []) ?? [
            'An unkonwn error occurred',
          ],
        )
      } else {
        toast.success('Rates returned successfully')
      }
    }
  }
  if (isLoading) {
    return <Loading />
  }
  return (
    <ProvisionerSection>
      <div className="flex justify-between">
        <div className="text-md flex-1">{loc.address}</div>
        <div className="basis-1/6">
          <LocationBadge status={loc.status} />
        </div>
        <div className="flex basis-5/12 justify-end">
          <ValidatedForm onValidSubmit={() => handleSubmit('active')}>
            {credTypes.map(c => (
              <ProvisioningField key={c.name} register={register} cred={c} />
            ))}
            <FormActions
              loading={updateLoc.isLoading || testLoc.isLoading}
              onCancel={() => handleSubmit('none')}
              submitText={loc.status === 'active' ? 'Update' : 'Activate'}
              cancelText="No rates"
            />
          </ValidatedForm>
        </div>
      </div>
      <div className="text-fv-gray-dark flex">
        {testLoc.isLoading && (
          <div className="mt-2">
            <Icon icon="spinner" />
            &nbsp;Testing configuration
          </div>
        )}
        {!!errors.length && (
          <div className="mt-2">
            Our test of this account configuration returned the error below:
            <br />
            {uniq(errors).map(e => (
              <>
                &#8226; {e}
                <br />
              </>
            ))}
          </div>
        )}
      </div>
    </ProvisionerSection>
  )
}

const LocationBadge = ({ status }: { status: CarrierLocationStatusType }) => {
  if (status === 'new') return null

  return (
    <span
      className={clsx('px-2 py-1 text-sm', {
        'bg-green-400 text-gray-700':
          status === 'testing' || status === 'active',
        'bg-red-400 text-white': status === 'none',
      })}
    >
      {(status === 'testing' || status === 'active') && <>Active</>}
      {status === 'none' && <>No rates</>}
    </span>
  )
}
