import clsx from 'clsx'
import { useRef, useState } from 'react'

import { FvButton, type IconName } from '@fv/client-components'

import { InputAdornment, InputGroupWrapper } from './InputGroup'
import TextInput from './TextInput'

export type FileUploadInputProps = {
  name: string
  label: string
  className?: string
  icon?: IconName
  onFileSelect?: (file?: File) => void
  isLoading?: boolean
}
export const FileUploadInput = ({
  name,
  isLoading,
  className,
  icon = 'upload',
  label,
  onFileSelect,
}: FileUploadInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState<string>('')

  function openFilePicker() {
    if (isLoading) return
    fileInputRef.current?.click()
  }

  return (
    <InputGroupWrapper name={name} className={clsx('flex-1', className)}>
      <InputAdornment position="start">
        <FvButton
          theme="default"
          onClick={openFilePicker}
          icon={icon}
          className="z-10"
        >
          {label}
        </FvButton>
      </InputAdornment>
      <TextInput
        name="fileName"
        value={fileName}
        onFocus={e => {
          openFilePicker()
          e.target.blur()
        }}
      />

      <input
        accept=".csv"
        name="csvFile"
        onChange={e => {
          const file = e.target.files?.[0]
          setFileName(file?.name ?? '')
          onFileSelect?.(file)
        }}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
    </InputGroupWrapper>
  )
}
