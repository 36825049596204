import { type ChangeEvent, useEffect, useState } from 'react'

import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import { InputGroup } from '../../components/inputs/InputGroup'
import { Loading } from '../../components/Loading'
import { useRecentAccountData } from '../booking/hooks'
import { type FormEmergencyContact } from './contactTypes'

type Props = {
  disabled?: boolean
  nameLabel?: string
  required?: boolean
  setValues: (values: Partial<FormEmergencyContact>) => void
  values: FormEmergencyContact
}

export const EmergencyContactForm = ({
  disabled = false,
  nameLabel = 'Emergency contact name',
  required = true,
  setValues,
  values,
}: Props) => {
  const { getInputProps, setFormValues, loading } = useForm({
    disabled,
    required,
    setValues,
    values,
  })

  if (!required) return null

  if (loading) return <Loading />

  return (
    <>
      <InputGroup
        className="basis-4/12"
        inputProps={getInputProps('name')}
        inputType="text"
        label={nameLabel}
        required
      />

      <ContactPhoneInput
        className="basis-3/12"
        disabled={disabled}
        label="Phone"
        name={getInputName('phone')}
        onChange={v => {
          setFormValues({
            phone: v.contactPhone,
            phoneExt: v.contactPhoneExt,
          })
        }}
        required
        values={{
          contactPhone: values?.phone ?? '',
          contactPhoneExt: values?.phoneExt ?? '',
        }}
      />

      <InputGroup
        className="flex-1"
        inputProps={getInputProps('contractNumber')}
        inputType="text"
        label="Contract number"
      />
    </>
  )
}

function getInputName(name: string) {
  return `emergencyContact_${name}`
}

function useForm(config: {
  disabled: boolean
  required: boolean
  setValues: (values: Partial<FormEmergencyContact>) => void
  values: FormEmergencyContact
}) {
  const { disabled, required, setValues, values } = config
  const [loading, setLoading] = useState(true)
  const recentDataQuery = useRecentAccountData()

  useEffect(() => {
    if (!loading || !required || !recentDataQuery.data) {
      if (loading && !recentDataQuery.isLoading) {
        setLoading(false)
      }
      return
    }

    const contractNumber = recentDataQuery.data.emergencyContractNumber?.trim()
    const name = recentDataQuery.data.emergencyName?.trim()
    const phone = recentDataQuery.data.emergencyPhone?.replace(/\D/g, '')
    const phoneExt = recentDataQuery.data.emergencyPhoneExt?.trim()

    if (name && phone && !values?.name) {
      setValues({
        name,
        phone,
        ...(contractNumber && { contractNumber }),
        ...(phoneExt && { phoneExt }),
      })
    }
    setLoading(false)
  }, [
    recentDataQuery.data,
    recentDataQuery.isLoading,
    required,
    setValues,
    loading,
    values,
  ])

  function setFormValues(values: Partial<FormEmergencyContact>) {
    setValues(values)
  }

  return {
    loading,
    getInputProps: (name: 'contractNumber' | 'name') => ({
      disabled,
      name: getInputName(name),
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setFormValues({ [name]: e.target.value })
      },
      value: values[name] ?? '',
    }),
    setFormValues,
  }
}
