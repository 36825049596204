import { type ReactNode, useEffect } from 'react'

import { type Permission } from '../../types/Permission'
import { useHasPermission } from './hooks'

type Props = {
  children: ReactNode
  fallback?: JSX.Element
  name: Permission
  redirect?: string
}

export const HasPermission = ({
  children,
  fallback,
  name,
  redirect,
}: Props) => {
  const hasPermission = useHasPermission(name)

  useEffect(() => {
    if (hasPermission || !redirect) return
    window.location.href = redirect
  }, [hasPermission, redirect])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (hasPermission) return <>{children}</>
  if (fallback) return fallback
  return null
}
