import { type Dispatch, type SetStateAction, useCallback } from 'react'

import { type IconProps } from '@fv/client-components'
import { type FullShipment } from '@fv/client-types'

import { PrintRatesButton } from '../features/quote/PrintRatesButton'
import { type QuoteRequestResponses } from '../hooks/shipments/useQuoteRequestResponses'
import arrayOfLength from '../utils/arrayOfLength'
import QuoteListFilter from './QuoteRequest/QuoteListFilter'
import {
  type ListState,
  type ListStatus,
} from './QuoteRequest/QuoteRequestDetail'
import TruckSelector from './shared/TruckSelector'
import InfoBox from './InfoBox'
import ResponseRow from './ResponseRow'

const emptyStates = {
  active: 'Nobody has responded yet.',
  declined: 'Nobody has declined.',
  pending: 'No pending responses.',
}

type ResponseListFilter = {
  icon: IconProps['icon']
  label: string
  name: ListStatus
}

const filters: ResponseListFilter[] = [
  {
    icon: 'comment-alt-lines',
    label: 'Responses',
    name: 'active',
  },
  {
    icon: 'comment-alt-minus',
    label: 'Yet to respond',
    name: 'pending',
  },
  {
    icon: 'comment-alt-slash',
    label: 'Declined',
    name: 'declined',
  },
]

type ResponseListProps = {
  filter: ListStatus
  listState: ListState
  loadId: string
  loads: FullShipment[]
  responses: QuoteRequestResponses
  selectTruck: (i: number) => void
  setFilter: Dispatch<SetStateAction<ListStatus>>
  setListState: Dispatch<SetStateAction<ListState>>
}

export const ResponseList = ({
  filter,
  listState,
  loadId,
  loads,
  responses,
  selectTruck,
  setFilter,
  setListState,
}: ResponseListProps) => {
  const loadIndex = loads.findIndex(load => load.loadId === loadId)
  const activeLoad = loads[loadIndex]

  const setFilterId = useCallback(
    (id: string) => {
      setListState(prev => ({
        ...prev,
        [loadId]: {
          ...prev[loadId],
          [filter]: id,
        },
      }))
    },
    [filter, loadId, setListState],
  )

  const toggleSelectedId = useCallback(
    (id: string) => {
      setListState(prev => ({
        ...prev,
        [loadId]: {
          ...prev[loadId],
          [filter]: id,
          selected: prev[loadId].selected === id ? '' : id,
        },
      }))
    },
    [filter, loadId, setListState],
  )

  if (!loads.length) {
    return (
      <div className="rates-table p-8">
        <InfoBox icon="spinner" className="mb-0">
          Loading...
        </InfoBox>
      </div>
    )
  }

  return (
    <div className="ltl-rates-ctn relative flex w-full flex-col overflow-auto bg-slate-50 [scrollbar-gutter:stable]">
      <div className="b1200:pt-6 mb-6 flex items-center p-8 pb-0">
        {loads.length > 1 && (
          <div className="flex items-center gap-x-1 mr-4">
            <span className="mr-1">Truck</span>

            {arrayOfLength(loads.length).map(i => (
              <TruckSelector
                active={i === loadIndex}
                className="callout-link boxed-link px-2 py-1"
                key={i}
                selectTruck={() => selectTruck(i)}
                truckNumber={i + 1}
              />
            ))}
          </div>
        )}

        <nav className="divided-content divided-content--start col-auto">
          {filters.map(({ icon, label, name }, index) => (
            <QuoteListFilter
              active={filter === name}
              icon={icon}
              key={index}
              label={label}
              onClick={() => setFilter(name)}
            />
          ))}

          <PrintRatesButton loadId={loadId}>Print rates</PrintRatesButton>
        </nav>
      </div>

      <div className="rates-table flex-1 overflow-auto px-8">
        {responses[filter].length < 1 ? (
          <InfoBox icon="ban" className="mb-0">
            {emptyStates[filter]}
          </InfoBox>
        ) : (
          responses[filter].map(data => (
            <ResponseRow
              data={data}
              equipmentType={activeLoad.equipment.type}
              filter={filter}
              handleChange={() => toggleSelectedId(data.id)}
              handleRowClick={() => setFilterId(data.id)}
              isActive={data.id === listState[loadId]?.[filter]}
              isAwarded={activeLoad.selectedQuote?._id === data.id}
              isDisabled={Boolean(activeLoad.selectedQuote)}
              isSelected={data.id === listState[loadId]?.selected}
              key={data.id}
            />
          ))
        )}
      </div>
    </div>
  )
}
