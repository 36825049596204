import { type BookingSettings, shipperAccountSettingsSchema } from '@fv/models'
import { createDefault } from '@fv/models/validation'

import { useUserSettings } from '.'

export function useBookSettings() {
  const settingsQuery = useUserSettings()
  const bookSettings: BookingSettings =
    settingsQuery.data?.settings.booking ??
    createDefault(shipperAccountSettingsSchema).booking

  return bookSettings
}
