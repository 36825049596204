import {
  useAppContext,
  useSubscriptionStatus,
  useUserSettings,
} from '../../hooks/settings'
import { type Permission } from '../../types/Permission'
import { isCarrierAccountUser } from '../../utils/accountFuncs'

export function useAccountFeatures() {
  const settingsQuery = useUserSettings()
  const subscriptionStatus = useSubscriptionStatus()
  const features = settingsQuery.data?.products
  const isTrial = subscriptionStatus === 'trial'
  const hasTruckload = !!features?.includes('truckLoad')

  return {
    mixedPallets: !!features?.includes('mixedPallets'),
    parcel: isTrial || !!features?.includes('parcel'),
    truckLoad: isTrial || hasTruckload,
    fuelAndDistance:
      isTrial || (!!features?.includes('fuelAndDistance') && hasTruckload),
    automatedAuditing: !!features?.includes('automatedAuditing'),
    ltl: !!features?.includes('ltl'),
  }
}

export function useHasPermission(name: Permission) {
  const contextQuery = useAppContext()
  const permissions = contextQuery.data?.user.permissions

  return !!permissions?.includes(name)
}

export function usePermissions() {
  return {
    canAudit: useHasPermission('audit'),
    canBook: useHasPermission('book'),
    canManage: useHasPermission('manage'),
    canQuote: useHasPermission('quote'),
  }
}

export const useIsCarrierAccount = () => {
  const context = useAppContext()
  if (!context.data?.user) return false

  return isCarrierAccountUser(context.data.user)
}
