import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  buildFetchOptionsWithAuth,
  fetchJson,
  showApiError,
} from '@fv/client-core'
import { type ListShipment, type QuoteRequestDTO } from '@fv/client-types'

import { apiUri } from '../../constants'
import { useUpdateCachedLoad } from '../../hooks/shipments/useUpdateCachedLoad'
import { inProcessShipmentKeys } from '../shipment-list/hooks/useInProcessShipments'

async function postQuoteRequest(
  quoteRequest: QuoteRequestDTO,
): Promise<ListShipment[]> {
  const endpoint = `${apiUri}/shipments/quote-request`
  const options = buildFetchOptionsWithAuth({
    body: JSON.stringify(quoteRequest),
    method: 'POST',
  })

  const response = await fetchJson(endpoint, options)
  if (response.ok) return response.json
  throw response.errorMessage
}

export function useCreateQuoteRequest() {
  const queryClient = useQueryClient()
  const updateCachedLoad = useUpdateCachedLoad()

  const mutation = useMutation(postQuoteRequest, {
    onSettled: (loads, error) => {
      if (error) {
        showApiError('Unable to create quote request', error, {
          'locations.0': 'Origin',
          'locations.1': 'Destination',
        })
        mutation.reset()
        return
      }

      if (loads) {
        queryClient.resetQueries({
          exact: true,
          queryKey: ['recent-shipment', loads[0].workflow],
        })
      }
      loads?.forEach(updatedLoad => {
        updateCachedLoad({
          // Not in lists yet, don't `setQueryData` to allow invalidation
          keysToSkip: ['shipments'],
          updatedLoad,
        })
      })

      queryClient.invalidateQueries(inProcessShipmentKeys.all)
    },
  })

  return mutation
}
