import identity from 'lodash/identity'
import { type CSSProperties, useEffect, useState } from 'react'

import { addressFuncs, useDebouncedValue } from '@fv/client-core'
import { type ClientAddressLookupResult } from '@fv/client-types'
import { type AddressLookupContext } from '@fv/models'

import { type AddressWithCompany } from '../../features/addresses/addressFuncs'
import {
  type AddressSearch,
  type AddressType,
  useAddressSearch,
  useCacheFormattedAddress,
} from '../../features/addresses/useAddressSearch'
import { TypeaheadField } from './TypeaheadField'

export type AddressOption = { label: string; value: ClientAddressLookupResult }
type InputLocation = AddressWithCompany
export type AddressInputProps = {
  addressType?: AddressType
  allowFreeText?: boolean
  autoFocus?: boolean
  cacheFormattedLocation?: boolean
  error?: string
  filterOptions?: (options: AddressOption[]) => AddressOption[]
  formatAddress?: (location: InputLocation) => string
  formatOptionLabel?: (location: InputLocation) => string
  includeAddressBook?: boolean
  includeAtlas?: boolean
  isClearable?: boolean
  location: InputLocation
  name: string
  onInputChange?: (value: string) => void
  onLocationChange: (location: ClientAddressLookupResult | null) => void
  placeholder?: string
  readOnly?: boolean
  required?: boolean
  style?: CSSProperties
  onLoadingChange?: (loading: boolean) => void
  context?: AddressLookupContext
}

export const AddressInput = ({
  addressType,
  allowFreeText = false,
  autoFocus,
  // Not all fields are searchable in Atlas.
  // If true, we'll cache the formatted address so it's searchable
  // and the input won't clear itself due to no matching results.
  cacheFormattedLocation = true,
  error,
  filterOptions = identity, // must be stable!
  formatAddress = addressFuncs.companyAddress, // must be stable!
  formatOptionLabel, // must be stable!
  includeAddressBook = false,
  includeAtlas = true,
  isClearable = true,
  location,
  name,
  onInputChange,
  onLocationChange,
  placeholder,
  readOnly,
  required = false,
  style,
  onLoadingChange,
  context,
}: AddressInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const searchText = useDebouncedValue(inputValue, 500)
  const filter: AddressSearch = {
    search: searchText,
    includeAddressBook,
    includeAtlas,
    type: addressType,
    context,
  }
  const cacheFormattedAddress = useCacheFormattedAddress()
  const addressSearchQuery = useAddressSearch(filter)
  const options = (addressSearchQuery.data ?? []).map(value => ({
    label: formatOptionLabel ? formatOptionLabel(value) : formatAddress(value),
    value,
  }))

  // Update address input when location changes
  useEffect(() => {
    const address = formatAddress(location) || ''
    setInputValue(address)
  }, [formatAddress, location])

  function localOnInputChange(value: string) {
    onInputChange?.(value)
    setInputValue(value)
  }

  function selectLocation(nextLocation: ClientAddressLookupResult | null) {
    if (nextLocation && cacheFormattedLocation) {
      const formatted = formatAddress(nextLocation)
      cacheFormattedAddress(
        {
          ...filter,
          search: formatted,
        },
        nextLocation,
      )
    }

    onLocationChange(nextLocation)
  }

  useEffect(() => {
    onLoadingChange?.(addressSearchQuery.isLoading)
  }, [onLoadingChange, addressSearchQuery.isLoading])

  return (
    <TypeaheadField
      error={error}
      hasDropdownIcon={false}
      isClearable={isClearable}
      isLoading={addressSearchQuery.isFetching}
      name={name}
      autoFocus={autoFocus}
      onChange={selectLocation}
      onInputChange={localOnInputChange}
      options={filterOptions(options)}
      placeholder={placeholder}
      readOnly={readOnly}
      required={required}
      shouldClearInvalidInput={!allowFreeText}
      style={style}
      value={inputValue}
    />
  )
}
