import clsx from 'clsx'
import { Navigate, useParams } from 'react-router-dom'

import { Icon, OlarkHelper } from '@fv/client-components'
import { type FullShipment, type UIQuote } from '@fv/client-types'

import { routes } from '../../constants'
import { useLoad, useQuotes } from '../../hooks/shipments'
import { useAddresses } from '../addresses/useAddress'
import { usePermissions } from '../auth'
import { useHandlingUnits } from '../commodities/useHandlingUnits'
import { BookingForm } from './BookingForm'
import { BookingFormProvider } from './BookingFormProvider'

type Props = {
  hasError: boolean
  load?: FullShipment
  quote?: UIQuote
}

const BookingFormContainer = ({ hasError, load, quote }: Props) => {
  const addressesQuery = useAddresses(load)
  const handlingUnitsQuery = useHandlingUnits(load)

  if (!load || !quote || addressesQuery.loading || handlingUnitsQuery.loading) {
    return (
      <div className="parcel-ltl-booking-main row-start-2">
        <Icon
          className={clsx({ 'color-secondary': hasError })}
          icon={hasError ? 'exclamation-triangle' : 'sync'}
        />
        <span className="ml-2">
          {hasError
            ? !load
              ? 'Shipment not found.'
              : 'Selected quote is no longer valid for this shipment.'
            : 'Loading...'}
        </span>
      </div>
    )
  }

  if (load.workflow !== 'truckload') {
    return (
      <BookingFormProvider
        addresses={addressesQuery.data}
        handlingUnits={handlingUnitsQuery.data}
        load={load}
        quote={quote}
      >
        <BookingForm />
      </BookingFormProvider>
    )
  }

  if (load.workflow === 'truckload') {
    return (
      <Navigate
        replace
        to={routes.bookTruckload({
          quoteRequestId: load.quoteRequestId,
          selectedQuotes: { [load.loadId]: quote._id },
        })}
      />
    )
  }

  return null
}

type Params = {
  loadId: string
  quoteId: string
}

export const BookingPage = () => {
  const { canBook } = usePermissions()
  const { loadId, quoteId } = useParams<Params>()
  const loadQuery = useLoad(loadId, { refetchWhileQuoting: true })
  const quotesQuery = useQuotes(loadId)
  const isCurrentQuote = loadQuery.data?.selectedQuote?._id === quoteId
  const selectedQuote = isCurrentQuote
    ? loadQuery.data?.selectedQuote
    : quotesQuery.data?.find(q => q._id === quoteId)

  if (
    !selectedQuote &&
    loadQuery.data &&
    loadQuery.data?.status !== 'pending'
  ) {
    return <Navigate to={routes.details(loadQuery.data.loadId)} />
  }

  if (
    (!selectedQuote &&
      loadQuery.data?.quoteProcessStatus === 'rerate-no-compat' &&
      loadQuery.data?.status === 'pending') ||
    (!canBook && loadQuery.data)
  ) {
    quotesQuery.invalidate()
    return <Navigate to={routes.rates(loadQuery.data)} />
  }

  return (
    <>
      <BookingFormContainer
        hasError={loadQuery.isError || quotesQuery.isError}
        load={loadQuery.data}
        quote={selectedQuote}
      />
      <OlarkHelper loadId={loadQuery.data?.loadId} />
    </>
  )
}
