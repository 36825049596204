import { useQuery } from '@tanstack/react-query'

import { type ApiKeyListDto, type ApiKeyVersion } from '@fv/models'

import { shipperFetch } from '../../../utils/shipperFetch'

export const apiKeysQueryKey = {
  all: ['api-keys'],
  version: (version: ApiKeyVersion) => [...apiKeysQueryKey.all, version],
}
export const useApiKeys = ({ version }: { version: ApiKeyVersion }) => {
  return useQuery(apiKeysQueryKey.version(version), () =>
    shipperFetch<ApiKeyListDto[]>('/api-keys', { query: { version } }),
  )
}
