import { useState } from 'react'

import { FvButton, useFvNavigate } from '@fv/client-components'
import { type UIQuote } from '@fv/client-types'

import InfoBox from '../../components/InfoBox'
import CheckboxField from '../../components/inputs/CheckboxField'
import { PillHeader } from '../../components/PillHeader'
import { TruckloadAwardConfirmation } from '../../components/TruckloadAwardConfirmation'
import { routes } from '../../constants'
import { useCurrency } from '../../hooks/settings'
import { TLBookingBolField } from '../book/BookingFormBolField'
import { TLBookingFormShare } from '../book/BookingFormShare'
import { TLBookingFormTags } from '../book/BookingFormTags'
import { QuoteReasonSlider } from '../quote-reason/QuoteReasonForm'
import { BookingCommodities } from './BookingCommodities'
import { BookingCustomsBroker } from './BookingCustomsBroker'
import { BookingLoad } from './BookingLoad'
import { BookingLocations } from './BookingLocations'
import { EmergencyContact } from './EmergencyContact'
import { useBookingForm, useBookingPageNavigation } from './hooks'
import { PaymentTerms } from './PaymentTerms'
import { type BookFormLoad } from './types'

export const BookingForm = () => {
  const navigate = useFvNavigate()
  const {
    bookShipment,
    customsBrokers,
    hasPickupOnlyPrompt,
    isBooking,
    isPickupOnly,
    isRetender,
    loads,
    onPickupOnlyChange,
    pickup,
    requireQuoteReason,
    quoteSelectionReason,
    selectedQuotes,
  } = useBookingForm()

  const firstLoad = loads[0]
  const [hasSubmittedQuoteReason, setHasSubmittedQuoteReason] = useState(false)
  const { leave } = useBookingPageNavigation()
  const priceParts = useCurrency()
  const isEditing = firstLoad.status !== 'pending' && pickup?.status !== 'error'

  const buttonLabel = isEditing ? 'Update this shipment' : 'Book this shipment'
  const isMultiTruck = loads.length > 1

  const firstQuote = selectedQuotes.find(q => q._id === firstLoad.quoteId)
  const [dollars, cents] = priceParts(firstQuote?.amount)
  const headerText = composeHeaderText({
    firstQuote,
    isRetender,
    loads,
  })

  return (
    <>
      <div className="booking-main-content-ctn row-start-2 b1200:overflow-visible relative">
        <PillHeader st className="beer-container mb-4">
          <h6 className="mb-0">
            <span className="!align-middle">{headerText}</span>

            {!isMultiTruck && (
              <span className="price !align-middle">
                <span className="price__dollars">${dollars}</span>
                <span className="price__cents">{cents}</span>
                <span className="price__currency">
                  {firstQuote?.currency.toUpperCase()}
                </span>
              </span>
            )}
          </h6>
        </PillHeader>

        {isRetender && (
          <InfoBox icon="exclamation-triangle">
            {firstQuote?.providerName} requires handling unit and commodity
            information to book this shipment.
          </InfoBox>
        )}

        {hasPickupOnlyPrompt && (
          <div className="help-box boxed gradient mb-10">
            <CheckboxField
              checked={isPickupOnly}
              label={
                <p className="mb-0">
                  <span className="b1350:hidden ">
                    I only wish to enter information required for the carrier to
                    confirm the load. I will enter other information prior to
                    printing a BOL.
                  </span>
                  <span className="b1350:inline hidden ">
                    Only input information required for the carrier to confirm
                    the load. Enter other info prior to printing a BOL.
                  </span>
                </p>
              }
              labelClassName="whitespace-normal"
              name="isPickupOnly"
              onChange={onPickupOnlyChange}
            />
          </div>
        )}

        {pickup?.status === 'error' && (
          <InfoBox icon="exclamation-triangle">
            Booking failed: {pickup.error ?? 'Unknown error.'}
          </InfoBox>
        )}

        <BookingLocations />
        <BookingCommodities />

        {loads.map(load => (
          <BookingLoad key={load.loadId} load={load} />
        ))}

        {customsBrokers.map((b, i) => (
          <BookingCustomsBroker key={i} index={i} />
        ))}
        <PaymentTerms />
        <EmergencyContact />

        {loads.length === 1 && (
          <div className="gray-container flex gap-2 px-4 pb-2 pt-3">
            <TLBookingBolField className="basis-3/12" />
            <TLBookingFormShare className={'basis-5/12'} />
            <TLBookingFormTags className={'basis-4/12'} />
          </div>
        )}
      </div>

      <div className="footer-actions row-start-[-1] col-start-1 col-span-3">
        {isEditing && (
          <FvButton
            icon="arrow-to-left"
            theme="default"
            onClick={() => {
              leave(loads, true)
            }}
            type="button"
          >
            <span>Go back</span>
          </FvButton>
        )}

        <FvButton
          className=""
          onClick={bookShipment}
          theme="primary"
          type="button"
          fwd
          icon={isBooking ? 'spinner' : 'check'}
        >
          <span>{buttonLabel}</span>
        </FvButton>
      </div>
      {loads.length === 1 && firstQuote && (
        <QuoteReasonSlider
          load={{
            loadId: firstLoad.loadId,
            workflow: 'truckload',
            quoteRequestId: firstLoad.quoteRequestId,
            status: firstLoad.status,
            quoteSelectionReason,
          }}
          quote={firstQuote}
        />
      )}
      {loads.length > 1 && requireQuoteReason && (
        <TruckloadAwardConfirmation
          isOpen={!hasSubmittedQuoteReason}
          title=""
          isBookingPage={true}
          onCancel={() => {
            navigate(
              routes.rates({
                loadId: firstLoad.loadId,
                quoteRequestId: firstLoad.quoteRequestId,
                workflow: 'truckload',
              }),
            )
          }}
          cancelText="Go back to my rates"
          onSubmit={() => setHasSubmittedQuoteReason(true)}
          submitLabel="Submit and proceed"
          loadsToBook={loads}
        />
      )}
    </>
  )
}

function composeHeaderText({
  firstQuote,
  isRetender,
  loads,
}: {
  firstQuote?: UIQuote
  isRetender: boolean
  loads: BookFormLoad[]
}) {
  const isMultiTruck = loads.length > 1
  if (isMultiTruck) return 'This is a multi-truck shipment.'

  const carrier = firstQuote?.providerName
  const status = loads[0].status

  if (status === 'pending') {
    return `Book this load with ${carrier} for `
  }

  if (isRetender) {
    return `Retendering this load to ${carrier} for `
  }

  return `Load booked with ${carrier} for `
}
