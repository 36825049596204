import { vOutput } from '../validation'
import {
  vArray,
  vBoolean,
  vCoerceArray,
  vObject,
  vOptional,
  vPick,
  vPicklist,
  vString,
} from '../validation/schema/core'
import { workflows } from './common'
import { readyCompletedFilterSchema } from './shipment-filters'

export const adminExportAccountLoadsSchema = vObject({
  ...vPick(readyCompletedFilterSchema, ['pickupDate', 'status']).entries,
  workflow: vArray(vPicklist(workflows)),
  excludeFedEx: vOptional(vBoolean()),
  emails: vCoerceArray(vString()),
})
export type AdminExportAccountLoadsDto = vOutput<
  typeof adminExportAccountLoadsSchema
>
