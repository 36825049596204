import { type UseMutationResult } from '@tanstack/react-query'
import clsx from 'clsx'
import { useCallback, useEffect, useRef, useState } from 'react'

import { type ErrorPathAliases, getApiErrorText } from '@fv/client-core'

export type MutationProgressResult = {
  index: number
  success: boolean
  error?: string
}
type Props<TModel, TResult> = {
  data: TModel[]
  mutation: UseMutationResult<TResult, unknown, TModel, unknown>
  onComplete: (results: MutationProgressResult[]) => void
  pathAliases?: ErrorPathAliases
}
export function MutationProgressBar<TModel, TResult>({
  data,
  mutation,
  onComplete,
  pathAliases,
}: Props<TModel, TResult>) {
  const initialized = useRef(false)
  const [myData] = useState(data)
  const [index, setIndex] = useState(0)
  const [isComplete, setIsComplete] = useState(false)
  const [results, setResults] = useState<MutationProgressResult[]>([])

  const processUpload = useCallback(async () => {
    for (let i = 0; i < myData.length; i++) {
      setIndex(i)
      try {
        await mutation.mutateAsync(myData[i])
        setResults(p => [...p, { index: i, success: true }])
      } catch (e: any) {
        setResults(p => [
          ...p,
          { index: i, success: false, error: getApiErrorText(e, pathAliases) },
        ])
      }
    }
  }, [myData, mutation, pathAliases])

  useEffect(() => {
    if (!isComplete && myData.length === results.length) {
      setIsComplete(true)
      onComplete(results)
    }
  }, [myData, results, onComplete, isComplete])

  useEffect(() => {
    !initialized.current && processUpload()
  }, [processUpload])

  useEffect(() => {
    initialized.current = true
  }, [])
  return (
    <div className="h-4 w-full border">
      <div
        style={{
          transform: `scaleX(${index / (data.length - 1)})`,
        }}
        className={clsx(
          'bg-fv-blue-600 h-full w-full origin-left transition-transform',
        )}
      />
    </div>
  )
}
