import clsx from 'clsx'
import upperFirst from 'lodash/upperFirst'
import pluralize from 'pluralize'
import { type MouseEvent } from 'react'

import { Icon } from '@fv/client-components'
import { type PackagingType, type ProductCatalogEntry } from '@fv/client-types'

import { ActionList, ActionListItem } from '../../components/ActionList'
import { PillHeader } from '../../components/PillHeader'
import { useTypeNames } from '../../hooks/settings'
import { HasPermission } from '../auth'
import { getDimensions } from './commodityUtils'
import { useDeleteHandlingUnit } from './useDeleteHandlingUnit'
import { useProductCatalog } from './useProductCatalog'

const _NA = 'NA'

type ProductContainsProps = {
  saidToContain: string
  saidToContainPackagingType: PackagingType | ''
}

const ProductContains = ({
  saidToContain,
  saidToContainPackagingType,
}: ProductContainsProps) => {
  const { packageName: getPackageName } = useTypeNames()
  const quantity = Number(saidToContain || 2)
  const packageName = getPackageName(saidToContainPackagingType)
  const packageLabel = pluralize(packageName, quantity)
  const contains = `${saidToContain} ${upperFirst(packageLabel)}`.trim()

  return <div>Contains: {contains || _NA}</div>
}

type ProductPackageProps = {
  package: PackagingType | ''
  pieces: string
}

const ProductPackage = (props: ProductPackageProps) => {
  const { packageName: getPackageName } = useTypeNames()

  if (!props.package) return null

  const quantity = Number(props.pieces || 2)
  const packageName = getPackageName(props.package)
  const packageLabel = pluralize(packageName, quantity)

  return <div>{`${props.pieces} ${upperFirst(packageLabel)}`.trim()}</div>
}

type ProductProps = {
  product: ProductCatalogEntry
}

export const Product = ({ product }: ProductProps) => {
  const deleteProduct = useDeleteHandlingUnit()
  const dimensions = getDimensions(product)
  const editProduct = useProductCatalog(s => s.edit)
  const { selectProduct, setProduct } = useProductCatalog(s => s)
  function selectHandlingUnit() {
    if (deleteProduct.isLoading) return
    if (setProduct) {
      selectProduct(product)
    } else {
      editProduct(product._id)
    }
  }

  function editHandlingUnit(e: MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation()
    if (deleteProduct.isLoading) return
    editProduct(product._id)
  }

  function deleteHandlingUnit(e: MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation()
    if (deleteProduct.isLoading) return

    const isConfirmed = window.confirm(
      `Are you sure you want to delete ${product.description} from your product catalog?`,
    )

    if (!isConfirmed) return
    deleteProduct.mutate(product._id)
  }

  return (
    <div
      className="hover:border-fv-blue group relative mt-[2.25rem] cursor-pointer border border-[#bde1ef] bg-fv-blue-75 px-4 pb-10 first:mt-0"
      onClick={selectHandlingUnit}
    >
      <PillHeader
        st
        className="beer-container group-hover:border-fv-beer-800 relative -left-6 -top-4"
      >
        <p className="mb-0 flex">
          <Icon
            icon="forklift"
            className={clsx(
              'color-dark',
              product.description && 'mt-[.175rem]',
            )}
            transform="right-3"
          />
          <span>{product.description}</span>
        </p>
      </PillHeader>

      <div className="divided-content divided-content--start [&>*]:border-[#bde1ef]">
        <ProductPackage {...product} />
        <div>Dimensions: {dimensions || _NA}</div>
        <div>
          Total weight: {product.weight ? `${product.weight} lbs` : _NA}
        </div>
        <div>Freight class: {product.freightClass || _NA}</div>
      </div>

      <hr className="mx-0 my-2 bg-[#bde1ef]" />

      <div className="divided-content divided-content--start [&>*]:border-[#bde1ef]">
        <div>NMFC#: {product.nmfc || _NA}</div>
        <ProductContains {...product} />
        <div>Stackable: {product.stackable}</div>
        <div>Hazardous: {product.hazardous}</div>
      </div>

      {product.hazardous && <hr className="mx-0 my-2 bg-[#bde1ef]" />}

      {product.hazardous && (
        <div className="divided-content divided-content--start [&>*]:border-[#bde1ef]">
          <div>Hazmat ID: {product.hazmatId || _NA}</div>
          <div>Hazard class: {product.hazardClass || _NA}</div>
          <div>Packing group: {product.packingGroup || _NA}</div>

          {product.specialPermit && (
            <div>Special permit: {product.specialPermit}</div>
          )}
        </div>
      )}

      <HasPermission name="manage">
        <ActionList className="absolute bottom-4 right-1">
          <ActionListItem onClick={editHandlingUnit} icon="pen" />
          <ActionListItem
            icon={deleteProduct.isLoading ? 'spinner' : 'trash'}
            iconClass="text-fv-orange"
            onClick={deleteHandlingUnit}
          />
        </ActionList>
      </HasPermission>
    </div>
  )
}
