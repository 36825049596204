import { createContext, type Dispatch, type SetStateAction } from 'react'

import { type Country } from '@fv/models'

import { type BillToModel } from '../components/AddressForm'

export type FxfeMfaInitializationRequest = {
  customerName?: string
  accountNumber?: string
  address?: string
  city?: string
  state?: string
  postalCode?: string
  country?: Country
}

const mfaRequestOptions = [
  'sms',
  'email',
  'call',
  'invoice',
  'support',
] as const
export type MfaRequestType = (typeof mfaRequestOptions)[number]
export interface FxfeProvisionOptionRequest {
  accountToken: string
  method: MfaRequestType
}

export interface FxfeMfaValidationRequestDto
  extends FxfeMfaInitializationRequest {
  invoiceNumber?: string
  invoiceAmount?: string
  invoiceCurrency?: string
  invoiceDate?: string
  pin?: string
  accountToken: string
}

export interface FxfeProvisionResponse {
  details?: Array<{
    type: 'account-token'
    value: string
  }>
  errors?: Array<{
    type: string
    message: string
    rawMessage?: string
  }>
  status: 'error' | 'ok' | 'pending'
}
export interface MfaOptionItem {
  type: MfaRequestType
  value?: string
}
export interface FxFeProvisionOptionsResponse extends FxfeProvisionResponse {
  options: MfaOptionItem[]
}

export const isOptionsResponse = (
  v: FxFeProvisionOptionsResponse | FxfeProvisionResponse,
): v is FxFeProvisionOptionsResponse =>
  v.status === 'pending' && !!(v as FxFeProvisionOptionsResponse).options

export type StepType =
  | 'terms'
  | 'account-address'
  | 'mfa-option'
  | 'mfa-input'
  | 'success'
type StatePart<S> = [S, Dispatch<SetStateAction<S>>]

export type FedexWizardState = {
  accountInfo: StatePart<FxfeMfaInitializationRequest>
  onComplete: () => void
  onExit: () => void
  options: StatePart<MfaOptionItem[]>
  selectedOption: StatePart<MfaOptionItem | undefined>
  step: StatePart<StepType>
  token: StatePart<string>
}
export const FedexWizardContext = createContext<FedexWizardState | null>(null)

export interface BillToWithAccountNumber extends BillToModel {
  // TODO - have to make accountNumber optional due to a weird type issue in register
  // would be nice to pass a register function with an interface but it's going to take more investigation
  accountNumber?: string
}
