export const downloadCSV = (data: string | string[], fileName: string) => {
  if (Array.isArray(data)) data = data.join('\n')

  const csvFile = new Blob([data], { type: 'text/csv' })
  const tempLink = document.createElement('a')

  tempLink.download = `${fileName}.csv`
  tempLink.href = window.URL.createObjectURL(csvFile)
  tempLink.style.display = 'none'

  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
}
