import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import {
  FvButton,
  type FvButtonProps,
  FvNavLink,
  Icon,
} from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

import { ActionList } from './ActionList'

type Props = PropsWithChildren<
  PropsWithClassName<
    Pick<FvButtonProps, 'icon'> & {
      isActive?: boolean
      to?: string
      onClick?: () => void
      iconClass?: string
      loading?: boolean
    }
  >
>
export const TabLink = ({
  to,
  isActive,
  onClick,
  className,
  children,
  icon,
  iconClass,
  loading,
}: Props) => {
  if (!to) {
    return (
      <div className="px-3">
        <FvButton
          onClick={onClick}
          theme="plain"
          icon={icon}
          iconClass={clsx(iconClass, isActive ? 'text-fv-blue' : '')}
          className={clsx(
            className,
            '!h-auto !bg-transparent !px-0 !py-1 !leading-none relative',
            {
              'after:bg-fv-orange after:absolute after:-bottom-[.1rem] after:left-0 after:mt-4 after:h-px after:w-full':
                isActive,
            },
          )}
        >
          {children}
        </FvButton>
      </div>
    )
  } else {
    const compIsActive = isActive
    return (
      <div className="px-3">
        <FvNavLink
          to={to}
          className={({ isActive }) =>
            clsx(
              '!px=0 relative block py-1 leading-none first:pl-0',
              className,
              {
                'after:bg-fv-orange after:absolute after:-bottom-[.1rem] after:left-0 after:right-0 after:mt-4 after:h-px':
                  isActive && compIsActive !== false,
              },
            )
          }
        >
          {loading && <Icon icon="spinner" />}
          {icon && !loading && <Icon icon={icon} />}
          {children && <span>{children}</span>}
        </FvNavLink>
      </div>
    )
  }
}

export const TabLinkList = (props: PropsWithChildren<PropsWithClassName>) => (
  <ActionList {...props} />
)
