import clsx from 'clsx'

import { FvButton } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

type FormActionsProps = PropsWithClassName<{
  loading?: boolean
  onSubmit?: () => void
  onCancel?: () => void
  submitText?: string
  cancelText?: string
  jstart?: boolean
  jend?: boolean
}>

export const FormActions = ({
  submitText,
  className,
  loading,
  onCancel,
  onSubmit,
  cancelText,
  jstart,
}: FormActionsProps) => {
  return (
    <div
      className={clsx(
        'flex justify-end',
        className,
        jstart && '!justify-start',
      )}
    >
      {onCancel && (
        <FvButton
          icon="times"
          className={clsx(jstart && 'order-2')}
          transform="down-1"
          onClick={() => {
            if (loading) return
            onCancel()
          }}
          theme="plain"
        >
          {cancelText ?? 'Cancel'}
        </FvButton>
      )}
      <FvButton
        loading={loading}
        icon="check"
        className={clsx(jstart && 'ml-0 mr-[.35rem]')}
        theme="primary"
        type={onSubmit ? 'button' : 'submit'}
        onClick={onSubmit}
      >
        {submitText || 'Submit'}
      </FvButton>
    </div>
  )
}
