type Props = {
  email?: string
  name?: string
  fallback?: string
}

export const LoadUserDisplay = ({ email, name, fallback }: Props) => {
  const display = name?.trim() || email?.trim() || fallback?.trim()

  if (!display) {
    return null
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{display}</>
}
