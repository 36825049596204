import dayjs from 'dayjs'

import { type TrackEvent } from '@fv/client-types'

export type SummarizedTrackingEvent = TrackEvent & {
  timestamp: string
  summary: string
}

export const filterAndSortEvents = (
  data?: TrackEvent[],
): SummarizedTrackingEvent[] => {
  return (data ?? [])
    .filter(e => !!e.summary)
    .map(e => {
      // Manual events should sort by createdDate instead of eventDate
      const isManualEvent = e.eventMode === 'manual'
      let timestamp = e.createdDate

      if (!isManualEvent && e.eventDate && e.eventTime) {
        try {
          timestamp = dayjs
            .utc(`${e.eventDate} ${e.eventTime}`, 'YYYY-MM-DD hh:mm A')
            .toISOString()
        } catch (err) {
          timestamp = dayjs
            .utc(`${e.eventDate} ${e.eventTime}`, 'YYYY-MM-DD HH:mm')
            .toISOString()
        }
      }

      if (!isManualEvent && e.eventDate && !e.eventTime) {
        const createdDate = dayjs.utc(e.createdDate)
        const eventDate = dayjs.utc(e.eventDate)
        const createdOnEventDate = createdDate.isSame(eventDate, 'day')

        if (!createdOnEventDate) {
          timestamp = eventDate.add(12, 'hours').toISOString()
        }
      }

      return {
        ...e,
        summary: e.summary ?? 'Unknown event',
        timestamp,
      }
    })
    .sort((a, b) => {
      const sortValue = b.timestamp.localeCompare(a.timestamp)

      // Default sort to reverse order for matching timestamps
      if (sortValue === 0) return -1
      return sortValue
    })
}
