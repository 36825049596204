import clsx from 'clsx'
import { useLocation } from 'react-router-dom'

import { Icon, type IconProps, useFvNavigate } from '@fv/client-components'

import { type SortBy } from './hooks/useShipmentListParams'

type SortButtonProps = {
  currentSortBy: SortBy
  icon: IconProps['icon']
  label: string
  value: SortBy
}

const SortButton = ({ currentSortBy, icon, label, value }: SortButtonProps) => {
  const { pathname, search } = useLocation()
  const navigate = useFvNavigate()

  return (
    <a
      className={clsx({ 'active-hint-indicator': value === currentSortBy })}
      href="#"
      onClick={e => {
        e.preventDefault()
        if (value === currentSortBy) return

        const params = new URLSearchParams(search)
        params.set('sortBy', value)
        params.delete('load')
        navigate(`${pathname}?${params.toString()}`)
      }}
    >
      <Icon icon={icon} />
      <span
        className={clsx({
          'label-sort-messages': value === 'messageDate',
        })}
      >
        {label}
      </span>
    </a>
  )
}

export default SortButton
