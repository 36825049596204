import { addressFuncs, type FormRegister } from '@fv/client-core'
import { type AccountBillTo, CountryMapper } from '@fv/models'

import { AddressInput } from '../../components/inputs/AddressInput'
import { ContactPhoneInput } from '../../components/inputs/ContactPhoneInput'
import {
  InputGroup,
  InputGroupWrapper,
} from '../../components/inputs/InputGroup'

type ClassNameMap = {
  [K in keyof AccountBillTo as `${K}ClassName`]?: string
}
type Props = ClassNameMap & {
  register: FormRegister<AccountBillTo>
  onChange?: (value: AccountBillTo) => unknown
  value: AccountBillTo
  readOnly?: boolean
  onLoadingChange?: (loading: boolean) => void
}

export const AccountBillToForm = ({
  register,
  readOnly,
  onChange,
  value,
  onLoadingChange,
  ...props
}: Props) => {
  return (
    <>
      <InputGroup
        className={props.companyClassName}
        inputProps={{
          ...register('company'),
          readOnly,
          autoFocus: true,
        }}
        inputType="text"
        label="Company name"
        required
      />

      <InputGroup
        className={props.addressClassName}
        inputProps={{
          ...register('address'),
          readOnly,
        }}
        inputType="text"
        label="Address"
        required
      />

      <InputGroupWrapper
        className={props.postalCodeClassName}
        label="City, state & zip code"
        name="cityStateZip"
        required
      >
        <AddressInput
          formatAddress={addressFuncs.cityStateZip}
          formatOptionLabel={addressFuncs.companyAddress}
          isClearable={false}
          location={{
            ...value,
            country: CountryMapper.legacyToCore(value.country),
          }}
          name="cityStateZip"
          onLocationChange={val =>
            onChange?.({
              ...value,
              city: val?.city ?? '',
              state: val?.state ?? '',
              postalCode: val?.postalCode ?? '',
              country: 'US',
            })
          }
          onLoadingChange={onLoadingChange}
          placeholder="Zip/postal code"
          readOnly={readOnly}
          required
        />
      </InputGroupWrapper>

      <InputGroup
        className={props.contactNameClassName}
        inputProps={{
          ...register('contactName'),
          readOnly,
        }}
        inputType="text"
        label="Contact name"
        required
      />

      <ContactPhoneInput
        className={props.contactPhoneClassName}
        required
        name="contactPhone"
        onChange={e => {
          onChange?.({
            ...value,
            contactPhone: e.contactPhone,
            contactPhoneExt: e.contactPhoneExt ?? '',
          })
        }}
        values={{
          contactPhone: value.contactPhone || '',
          contactPhoneExt: value.contactPhoneExt || '',
        }}
        readOnly={readOnly}
      />

      <InputGroup
        className={props.contactEmailClassName}
        inputProps={{
          ...register('contactEmail'),
          readOnly,
        }}
        inputType="text"
        label="Contact email"
        required
      />
    </>
  )
}
