import {
  vArray,
  vCurrency,
  vDateUtcNoon,
  vMaxLength,
  vMinValue,
  vNumber,
  vNumberToString,
  vObject,
  vObjectId,
  vOptional,
  vOutput,
  vPicklist,
  vPipe,
  vString,
} from '../validation'
import { chargeNames } from './charge'
import { vEquipmentType } from './equipment-type'

export const upsertOppChargeSchema = vObject({
  name: vPicklist([...chargeNames]),
  amount: vPipe(vNumber(), vMinValue(1)),
  providerDetail: vOptional(vString()),
})
export type UpsertOppChargeDto = vOutput<typeof upsertOppChargeSchema>

export const upsertOpportunityQuoteSchema = vObject({
  quoteId: vOptional(vObjectId()),
  equipmentType: vEquipmentType(),
  amount: vPipe(vNumber(), vMinValue(0)),
  quoteNum: vPipe(vNumberToString(), vMaxLength(50)),
  currency: vCurrency(),
  expirationDate: vOptional(vDateUtcNoon()), // TODO - is this used?
  charges: vOptional(vArray(upsertOppChargeSchema)),
})

export type UpsertOpportunityQuoteDto = vOutput<
  typeof upsertOpportunityQuoteSchema
>

export const upsertOpportunityQuotesSchema = vObject({
  message: vOptional(vPipe(vString(), vMaxLength(500))),
  quotes: vArray(upsertOpportunityQuoteSchema),
})

export type UpsertOpportunityQuotesDto = vOutput<
  typeof upsertOpportunityQuotesSchema
>
