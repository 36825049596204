import { FvButton, ValidatedForm } from '@fv/client-components'

import { InputGroup } from '../../../components/inputs/InputGroup'
import { Loading } from '../../../components/Loading'
import { useContractedRateCarriers } from './queries'

export type ContractedRatesFilter = {
  carrierId: string
  locSearch?: string
  contractSearch?: string
}
type Props = {
  onChange: (filter: ContractedRatesFilter) => void
  value: ContractedRatesFilter
}
export const ContractedRatesListFilter = ({ value, onChange }: Props) => {
  const carriersQuery = useContractedRateCarriers()
  const carrierOptions =
    carriersQuery.data
      ?.map(d => ({
        text: d.carrierName ?? '',
        value: d.carrierId,
      }))
      .filter(v => !!v.text) ?? []

  const handleChange = (data: Partial<ContractedRatesFilter>) => {
    onChange({
      ...value,
      ...data,
    })
  }

  if (carriersQuery.isLoading) {
    return <Loading />
  }
  return (
    <ValidatedForm className="form-row flex items-center">
      <InputGroup
        inputType="select"
        className="flex-1"
        inputProps={{
          name: 'carrierId',
          options: carrierOptions,
          value: value.carrierId,
          onChange: e => handleChange({ carrierId: e.target.value }),
        }}
      />
      <InputGroup
        inputType="text"
        inputProps={{
          className: 'flex-1',
          value: value.locSearch,
          name: 'zip',
          placeholder: 'Zip code or state',
          onChange: e => handleChange({ locSearch: e.target.value }),
        }}
      />

      <InputGroup
        inputType="text"
        inputProps={{
          className: 'flex-1',
          value: value.contractSearch,
          name: 'contract',
          placeholder: 'Contract number',
          onChange: e => handleChange({ contractSearch: e.target.value }),
        }}
      />

      <FvButton
        icon="times"
        theme="plain"
        className="mb-4"
        onClick={() =>
          onChange({
            carrierId: value.carrierId,
            locSearch: '',
            contractSearch: '',
          })
        }
      />
    </ValidatedForm>
  )
}
