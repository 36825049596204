import { Currency } from './Currency'
import { HazardousDetail } from './HazardousDetail'
import { PackagingType } from './PackagingType'
import { DimensionsUOM, WeightUOM } from './UOM'
import { DeepPartial } from './utils'

export type ItemRefNum = 'tracking-num' | 'other'

export type LoadItem = {
  _id: string
  description: string
  type?: PackagingType
  quantity?: number
  freightClass?: number
  nmfcNumber?: number
  nmfcSubNumber?: number
  hazardous?: HazardousDetail[]
  weight?: number
  weightUOM?: WeightUOM
  length?: number
  width?: number
  height?: number
  dimensionsUOM?: DimensionsUOM
  declaredValue?: number
  declaredValueCurrency?: Currency
  customsValue?: number
  customsValueCurrency?: Currency
  // refNums?: ItemRefNum[] // commenting this out for now since it makes no sense
}

export type LoadHandlingUnit = LoadItem & {
  _id: string
  pickupLocationSequence: number
  dropLocationSequence: number
  stackable?: boolean
  nonStandard?: boolean
  handlingUnitId?: string
  contains?: LoadItem[]

  weight: number
  weightUOM: WeightUOM
  description: string
  type: PackagingType

  //overrides
  quantity: number
}

export const isHandlingUnit = (
  item: DeepPartial<LoadItem | LoadHandlingUnit>,
): item is LoadHandlingUnit => !!(item as LoadHandlingUnit).contains
