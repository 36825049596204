import { FvButton, SelectField, ValidatedForm } from '@fv/client-components'
import { type ShippingLabelSize } from '@fv/client-types'

import arrayOfLength from '../../utils/arrayOfLength'
import { composeLabelsUrl } from './hooks'
import { type LabelConfig } from './labelConfig'

interface FormElements extends HTMLFormControlsCollection {
  count: HTMLSelectElement
  start?: HTMLSelectElement
}

type Props = {
  closePanel: () => void
  defaultCount: number
  labelConfig: LabelConfig
  loadId: string
  pieceQuantity?: number
  unitQuantity: number
  onSubmit?: (props: {
    count: number
    loadId: string
    size: ShippingLabelSize
    start?: number
  }) => void
}

export const LabelOptionForm = ({
  closePanel,
  defaultCount,
  labelConfig,
  loadId,
  unitQuantity,
  pieceQuantity,
  onSubmit,
}: Props) => {
  const countOptions = buildCountOptions(unitQuantity)
  const pieceOptions: string[] = []

  if (pieceQuantity && !countOptions.includes(pieceQuantity.toString())) {
    pieceOptions.push(`${pieceQuantity}`)
  }

  const onValidSubmit = (form: HTMLFormElement) => {
    const elements = form.elements as FormElements
    const countString = elements.count.value
    const startString = elements.start?.value ?? '1'
    const url = composeLabelsUrl({
      count: Number(countString),
      loadId,
      size: labelConfig.size,
      start: Number(startString) - 1,
    })

    if (onSubmit) {
      onSubmit({
        count: Number(countString),
        loadId,
        size: labelConfig.size,
        start: Number(startString) - 1,
      })
    } else {
      closePanel()
      window.open(url, '_blank', 'noreferrer')
    }
  }

  return (
    <ValidatedForm className="flex-1" onValidSubmit={onValidSubmit}>
      {labelConfig.type === 'sheet' && labelConfig.count !== 1 && (
        <div className="input-group mb-2">
          <div className="input-group__prepend col-6">
            <div className="input-group__addon w-full">Position</div>
          </div>
          <SelectField
            className="form-control form-control--select basis-1/2"
            defaultValue="1"
            name="start"
            options={buildStartOptions(labelConfig)}
          />
        </div>
      )}

      <div className="input-group mb-2">
        <div className="input-group__prepend basis-1/2">
          <div className="input-group__addon w-full">Labels</div>
        </div>
        <SelectField
          className="form-control form-control--select col-6"
          defaultValue={String(defaultCount)}
          name="count"
          options={[countOptions, pieceOptions]}
          groups={['Units', 'Pieces']}
        />
      </div>
      <FvButton icon="tags" theme="primary" type="submit">
        Build labels
      </FvButton>
    </ValidatedForm>
  )
}

function buildCountOptions(quantity: number) {
  const optionsSet = new Set<string>()

  optionsSet.add('1')
  optionsSet.add(String(1 * quantity))
  optionsSet.add(String(2 * quantity))
  optionsSet.add(String(4 * quantity))

  return Array.from(optionsSet)
}

function buildStartOptions(config: LabelConfig) {
  return arrayOfLength(config.count ?? 0).map(i => String(i + 1))
}
