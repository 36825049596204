import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import {
  FvButton,
  SliderPanel,
  type SliderPanelProps,
  ValidatedForm,
} from '@fv/client-components'
import { emailRegExpMatcher } from '@fv/client-core'

import { InputAdornment, InputGroup } from '../../components/inputs/InputGroup'
import { HasPermission } from '../auth'
import {
  useCreateSpotQuoteContacts,
  useUpsertSpotQuoteGroup,
} from '../spot-quote-contacts/mutations'
import {
  ALL_CONTACTS_ID,
  UNGROUPED_CONTACTS_ID,
} from '../spot-quote-contacts/types'
import { SpotQuoteContactListing } from './SpotQuoteContactListing'
import {
  useSpotQuoteListActions,
  useSpotQuoteListGroup,
  useSpotQuoteListSelected,
} from './store'

type Props = Pick<SliderPanelProps, 'isOpen' | 'closePanel'> & {
  initialGroupId?: string
}

export const SpotQuoteGroupEditor = ({
  isOpen,
  closePanel,
  initialGroupId,
}: Props) => {
  const groupId = useSpotQuoteListGroup()
  const { setGroupId } = useSpotQuoteListActions()
  const useAllContacts =
    !initialGroupId || initialGroupId === UNGROUPED_CONTACTS_ID
  useEffect(() => {
    setGroupId(useAllContacts ? ALL_CONTACTS_ID : initialGroupId)
  }, [setGroupId, useAllContacts, initialGroupId])

  useEffect(() => () => setGroupId(ALL_CONTACTS_ID), [setGroupId])
  return (
    <SliderPanel {...{ isOpen, closePanel }} className="flex !p-0">
      <SpotQuoteContactListing
        mode="edit"
        className="flex-1"
        headerClassName="flex items-center gap-2 border-b border-b-gray-300 px-8 pb-4 pt-8"
        listingClassName="px-8"
        footerComponent={
          <HasPermission name="manage">
            <div className="footer-actions">
              <GroupEditorFooterForm groupId={groupId} />
            </div>
          </HasPermission>
        }
      />
    </SliderPanel>
  )
}

type GroupEditorFormProps = {
  groupId: string
}
const GroupEditorFooterForm = ({ groupId }: GroupEditorFormProps) => {
  const [newContact, setNewContact] = useState('')
  const [newGroup, setNewGroup] = useState('')
  const [creating, setCreating] = useState(false)
  const createContact = useCreateSpotQuoteContacts(true)
  const createGroup = useUpsertSpotQuoteGroup(true)
  const emails = useSpotQuoteListSelected()
  const { setGroupId } = useSpotQuoteListActions()
  const handleAddContactSubmit = async () => {
    if (!newContact.match(emailRegExpMatcher)) {
      toast.error('Please enter a valid email address')
      return
    }
    await createContact.mutateAsync({
      groupId: groupId,
      model: {
        emails: [newContact],
      },
    })
    setNewContact('')
  }
  const handleCreateGroupSubmit = async () => {
    if (!emails?.length) {
      toast.error('Please select at least one contact to add to the group')
      return
    }
    const result = await createGroup.mutateAsync({
      groupName: newGroup,
      emails: emails,
    })
    setGroupId(
      result.find(g => g.groupName === newGroup)?.groupId ?? ALL_CONTACTS_ID,
    )
    setCreating(false)
    setNewGroup('')
  }
  if (creating) {
    return (
      <ValidatedForm
        className="flex"
        onValidSubmit={handleCreateGroupSubmit}
        key="group-form"
      >
        <div className="flex w-full items-center">
          <InputGroup
            className="mb-0 flex-1"
            inputType="text"
            inputProps={{
              name: 'new-group-name',
              placeholder: 'New group name',
              onChange: e => {
                setNewGroup(e.target.value)
              },
              value: newGroup,
            }}
            endContent={
              <InputAdornment position="end">
                {' '}
                <FvButton
                  theme="default"
                  loading={createGroup.isLoading}
                  type="submit"
                  icon="check"
                >
                  Create
                </FvButton>
              </InputAdornment>
            }
          />
          <FvButton
            loading={createGroup.isLoading}
            onClick={() => setCreating(false)}
            key="cancel-create-group"
            theme="plain"
            icon="times"
          />
        </div>
      </ValidatedForm>
    )
  }
  return (
    <ValidatedForm
      className="flex"
      onValidSubmit={handleAddContactSubmit}
      key="contact-form"
    >
      <InputGroup
        className="mb-0 flex-1"
        inputType="text"
        required
        inputProps={{
          onChange: e => {
            setNewContact(e.target.value)
          },
          value: newContact,
          name: 'new-contact',
          placeholder: 'test@example.com',
        }}
        endContent={
          <InputAdornment position="end">
            <FvButton theme="default" icon={'plus'} type="submit">
              Add to group
            </FvButton>
          </InputAdornment>
        }
      />

      <FvButton
        theme="default"
        className="ml-2"
        icon="check"
        onClick={() => setCreating(true)}
      >
        New group from selected
      </FvButton>
    </ValidatedForm>
  )
}
