import dayjs from 'dayjs'

import {
  type CarrierMode,
  type CarrierProvisioningDetails,
  type CredentialDetail,
  type DetailedAccountProvisioningDto,
} from '@fv/client-types'

import { type AccountCarrierUpsertModel } from './types'

export const getCarrierModeDisplay = (
  override: string | undefined,
  mode: CarrierMode,
) => {
  if (override) return override

  if (mode === 'tl') return 'Truckload'
  if (mode === 'parcel') return 'Parcel'
  return 'LTL'
}

export const toFormModel = (
  val: DetailedAccountProvisioningDto | undefined,
): AccountCarrierUpsertModel => ({
  carrierId: val?.carrierId ?? '',
  isOnboarding: false,
  details: val?.details ?? [],
  salesRepEmails: val?.salesReps?.map(e => e.email).join() ?? '',
  salesRepFirstName: val?.salesRepFirstName ?? '',
  salesRepLastName: val?.salesRepLastName ?? '',
  salesRepPhone: val?.salesRepPhone ?? '',
  enableLTL: val?.enableLTL ?? true,
  enableTL: val?.enableTL ?? true,
  enableParcel: val?.enableParcel ?? true,
})

export const fillModeDefaults = (
  model: AccountCarrierUpsertModel,
  carrier: CarrierProvisioningDetails,
): AccountCarrierUpsertModel => ({
  ...model,
  enableLTL: model.enableLTL ?? carrier.ltl.enabled,
  enableParcel: model.enableParcel ?? carrier.parcel.enabled,
  enableTL: model.enableTL ?? carrier.tl.enabled,
})

export function needsShipperAttention({
  code,
  details,
  isBeta,
}: {
  code?: string
  details?: CredentialDetail[]
  isBeta?: boolean
}) {
  if (code === 'upgf' || code === 'tfin') {
    return !details?.some(
      d =>
        d.type === 'refresh-token' &&
        d.value &&
        dayjs.utc(d.expires).isAfter(dayjs.utc()),
    )
  }

  if (code === 'upss') {
    const hasIdAndSecret =
      details?.some(d => d.type === 'client-id' && d.value) &&
      details?.some(d => d.type === 'client-secret' && d.value)
    return !hasIdAndSecret
  }

  if (code === 'fxfe' && isBeta) {
    const hasKeyAndSecret =
      details?.some(d => d.type === 'child-key' && d.value) &&
      details?.some(d => d.type === 'child-secret' && d.value)
    return !hasKeyAndSecret
  }

  return false
}
