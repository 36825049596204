import { FvLinkButton } from '@fv/client-components'

export type SortDir = '1' | '-1'

type Props = {
  onChange: (dir: SortDir) => void
  value: SortDir
}

export const SortDirectionToggle = ({ value, onChange }: Props) => {
  return (
    <FvLinkButton
      theme="underlined"
      className="ml-1"
      icon="sort-alt"
      onClick={() => {
        onChange(value === '1' ? '-1' : '1')
      }}
    >
      {value === '1' ? 'ASC' : 'DESC'}
    </FvLinkButton>
  )
}
