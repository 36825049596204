import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

import { type FullShipment, type ListShipment } from '@fv/client-types'

import { quoteFuncs } from '../../features/quote/quoteFuncs'
import { useQuoteSettings } from '../settings'
import { useSpotQuoteRecipients } from '../spotQuotes'
import { type QuoteRequestResponse } from './useQuoteRequestResponses'
import { useQuotes } from '.'

export function useActiveResponses(opts: {
  filter?: 'date' | 'price'
  includePendingApiRates?: boolean
  load?: FullShipment | ListShipment
}) {
  const { createdDate, loadId } = opts.load ?? {}
  const quotesQuery = useQuotes(loadId)
  const recipientsQuery = useSpotQuoteRecipients(loadId)
  const settingsQuery = useQuoteSettings('truckload')
  const apiEnabledCarriers = settingsQuery.data?.apiEnabledCarriers
  const isLoading =
    (quotesQuery.isLoading && quotesQuery.isFetching) ||
    (recipientsQuery.isLoading && recipientsQuery.isFetching)

  const [isFetchingApiRates, setFetchingApiRates] = useState(false)
  const filter = opts.filter ?? 'price'
  const includePendingApiRates = opts.includePendingApiRates ?? false

  // Give API a 30 second window to return rates
  useEffect(() => {
    if (!createdDate) return
    const ratingEndDate = dayjs(createdDate).add(30, 's')
    setFetchingApiRates(dayjs().isBefore(ratingEndDate))
  }, [createdDate])

  // Reset `isFetchingApiRates` after rating window closes
  useEffect(() => {
    if (!isFetchingApiRates) return

    const ratingEndDate = dayjs(createdDate).add(30, 's')
    const timeRemaining = ratingEndDate.diff(dayjs())
    if (timeRemaining < 0) return setFetchingApiRates(false)

    const timeout = setTimeout(() => {
      setFetchingApiRates(false)
    }, timeRemaining)

    return () => clearTimeout(timeout)
  }, [createdDate, isFetchingApiRates])

  return useMemo(() => {
    if (!loadId || isLoading) return []

    const quotes = quotesQuery.data ?? []
    const recipients = recipientsQuery.data ?? []

    let activeResponses: QuoteRequestResponse[] = quotes
      .filter(q =>
        includePendingApiRates
          ? q.status !== 'retracted'
          : q.status !== 'error' && q.status !== 'retracted',
      )
      .filter(q => quoteFuncs.isSpotWorkflow(q))
      .map(q => ({
        ...q,
        id: q._id,
        name: q.providerName,
        ...(q.method === 'api' && {
          lastResponseDate: q.createdDate,
        }),
      }))

    // Add active carriers that have responded but not with a quote
    recipients
      .filter(r => r.hasResponded && !r.hasDeclined)
      .forEach(r => {
        // Carrier could already be active if has multiple users
        if (activeResponses.find(a => a.id === r.carrierId)) return

        const quote = quotes.find(
          q => q.carrierId && q.carrierId === r.carrierId && q.isSpot,
        )

        if (!quote) {
          activeResponses.push({
            ...r,
            id: r.carrierId || r.userId,
            name: r.carrierName || r.email || r.name || 'Unknown',
            status: 'active',
          })
        }
      })

    if (apiEnabledCarriers?.length && createdDate && includePendingApiRates) {
      apiEnabledCarriers.forEach(c => {
        const activeResponse = activeResponses.find(r => r.carrierId === c._id)

        if (activeResponse) return

        activeResponses.push({
          amount: 0,
          carrierId: c._id,
          currency: 'usd',
          errors: isFetchingApiRates
            ? undefined
            : [
                {
                  message: 'API response has timed out.',
                  type: 'timeout',
                },
              ],
          id: `${c._id}_${isFetchingApiRates ? 'processing' : 'error'}`,
          isProcessing: isFetchingApiRates,
          lastResponseDate: dayjs.utc().toISOString(),
          name: c.label,
          method: 'api',
          status: isFetchingApiRates ? 'active' : 'error',
        })
      })
    }

    // Add `lastResponseDate` if sorting by latest activity
    if (filter === 'date') {
      activeResponses = activeResponses.map(a => {
        if (a.lastResponseDate) return a

        const carrierRecipients = recipients.filter(
          r => r.carrierId === a.carrierId,
        )

        const lastResponseDate = carrierRecipients.reduce(
          (date: string | undefined, r) => {
            if (!date) return r.lastResponseDate
            if (!r.lastResponseDate) return date

            return date.localeCompare(r.lastResponseDate) < 0
              ? r.lastResponseDate
              : date
          },
          undefined,
        )

        return { ...a, lastResponseDate }
      })
    }

    return activeResponses.sort((a, b) => {
      if (filter === 'price') {
        if (!a.amount) return 1
        if (!b.amount) return -1
        return a.amount - b.amount
      }

      if (!a.lastResponseDate) return 1
      if (!b.lastResponseDate) return -1
      return b.lastResponseDate.localeCompare(a.lastResponseDate)
    })
  }, [
    apiEnabledCarriers,
    createdDate,
    filter,
    includePendingApiRates,
    isLoading,
    isFetchingApiRates,
    loadId,
    quotesQuery.data,
    recipientsQuery.data,
  ])
}
