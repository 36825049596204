import './ReactSelectOverlay.scss'

import {
  type CSSProperties,
  type HTMLInputTypeAttribute,
  type MutableRefObject,
  useRef,
} from 'react'
import Select, {
  type ActionMeta,
  components,
  type FormatOptionLabelMeta,
  type InputActionMeta,
  type InputProps,
  type Props as SelectProps,
  type SelectInstance,
} from 'react-select'

import { TextField } from '@fv/client-components'

import reactSelectStyles from '../../styles/reactSelectStyles'

export type TypeaheadOption<T> = {
  label: string
  value: T
}

export interface TypeaheadProps<T>
  extends Omit<SelectProps, 'filterOption' | 'onChange' | 'onInputChange'> {
  error?: string
  filterOption?: (option: TypeaheadOption<T>, inputValue: string) => boolean
  hasDropdownIcon?: boolean
  name: string
  onChange: (value: T | null) => void
  onInputChange?: (value: string) => void
  options: TypeaheadOption<T>[]
  placeholder?: string
  reactSelectRef?: MutableRefObject<SelectInstance | null>
  readOnly?: boolean
  required?: boolean
  shouldClearInvalidInput?: boolean
  style?: CSSProperties
  type?: HTMLInputTypeAttribute
  value?: string
}

// https://github.com/JedWatson/react-select/issues/1826
const Input = (props: InputProps) => (
  <components.Input
    {...props}
    autoComplete="off"
    data-lpignore
    data-prevent-enter-submit
    isHidden={false}
  />
)

// https://github.com/JedWatson/react-select/issues/4327
// HTML5 validation is not currently supported by react-select
// so we are using a dummy TextField for validation styles.
export function TypeaheadField<T>({
  blurInputOnSelect = false,
  error,
  filterOption,
  hasDropdownIcon = true,
  isClearable = true,
  isLoading = false,
  name,
  onChange,
  onInputChange,
  options,
  placeholder = '',
  reactSelectRef,
  readOnly,
  required = false,
  shouldClearInvalidInput = false,
  style,
  type = 'text',
  value,
  ...reactSelectProps
}: TypeaheadProps<T>) {
  const localRef = useRef<SelectInstance | null>(null)
  const requiredInputRef = useRef<HTMLInputElement>(null)
  const menuIsOpen =
    readOnly || (!hasDropdownIcon && !options.length) ? false : undefined

  function localFilterOption(option: unknown, inputValue: string) {
    if (!filterOption) return true
    return filterOption(option as TypeaheadOption<T>, inputValue)
  }

  function localOnChange(option: unknown, { action }: ActionMeta<unknown>) {
    if (readOnly) return
    if (action === 'clear' || action === 'select-option') {
      onChange((option as TypeaheadOption<T>)?.value ?? null)
    }
  }

  function localOnInputChange(inputValue: string, { action }: InputActionMeta) {
    if (readOnly) return
    if (action === 'input-change') onInputChange?.(inputValue)
    if (action === 'input-blur') {
      const selectedOption = options.find(o => o.label === value)

      if (shouldClearInvalidInput && !selectedOption) {
        onChange(options[0]?.value ?? null)
      }

      if (selectedOption) onChange(selectedOption.value)
    }
  }

  function setRef(instance: SelectInstance | null) {
    localRef.current = instance
    if (!reactSelectRef) return
    reactSelectRef.current = instance
  }

  return (
    <div className="ReactSelectOverlay_container" style={style} data-cy={name}>
      <Select
        {...reactSelectProps}
        blurInputOnSelect={blurInputOnSelect}
        className="ReactSelectOverlay"
        classNamePrefix="react-select"
        components={{
          Input,
          ...(!hasDropdownIcon && { DropdownIndicator: null }),
        }}
        controlShouldRenderValue={false}
        filterOption={localFilterOption}
        formatOptionLabel={boldSearchText}
        inputValue={value}
        isClearable={isClearable}
        isLoading={isLoading}
        isSearchable
        menuIsOpen={menuIsOpen}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        onChange={localOnChange}
        onInputChange={localOnInputChange}
        onMenuOpen={() => {
          // Hide error message so it does not block menu
          requiredInputRef.current?.setCustomValidity('')
        }}
        options={options}
        placeholder=""
        ref={setRef as any}
        styles={reactSelectStyles}
        // Only using `value` to display "clear" button
        value={value ? {} : null}
      />

      <TextField
        className="ReactSelectOverlay_requiredInput form-control"
        error={error}
        name={name}
        onFocus={() => localRef.current?.focus()}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={requiredInputRef}
        required={required}
        tabIndex={-1}
        type={type}
        value={value ?? ''}
      />
    </div>
  )
}

function boldSearchText<T>(
  optionData: unknown,
  context: FormatOptionLabelMeta<unknown>,
): JSX.Element | string {
  const option = optionData as TypeaheadOption<T>
  const label = option.label.toLowerCase()
  const value = context.inputValue.toLowerCase()
  const matchStartIndex = label.indexOf(value)

  if (matchStartIndex < 0) return option.label

  const matchEndIndex = matchStartIndex + value.length
  const prefix = option.label.slice(0, matchStartIndex)
  const match = option.label.slice(matchStartIndex, matchEndIndex)
  const suffix = option.label.slice(matchEndIndex)

  return (
    <span>
      <span>{prefix}</span>
      <strong>{match}</strong>
      <span>{suffix}</span>
    </span>
  )
}

export function filterOption(
  { label }: { label?: string },
  inputValue: string,
) {
  label = label?.toLowerCase() ?? ''
  inputValue = inputValue.trim().toLowerCase()
  return label.includes(inputValue)
}
