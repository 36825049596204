import { useMutation, useQueryClient } from '@tanstack/react-query'
import pluralize from 'pluralize'
import toast from 'react-hot-toast'

import { type ListShipment, type ShipmentStatus } from '@fv/client-types'

import { supportMessage } from '../../constants'
import { confirmedShipmentKeys } from '../../features/shipment-list/hooks/useConfirmedShipments'
import { shipperFetch } from '../../utils/shipperFetch'
import { useUpdateCachedLoad } from '.'

type ArchiveArgs = {
  loadIds: string[]
  shouldArchive?: boolean
}

export function useArchiveLoads(status: ShipmentStatus) {
  const queryClient = useQueryClient()
  const updateCachedLoad = useUpdateCachedLoad()

  const mutation = useMutation(
    (body: ArchiveArgs) =>
      shipperFetch<ListShipment[]>(`/shipments/archive`, {
        method: 'PUT',
        body,
      }),
    {
      onSuccess: loads => {
        const { isArchived } = loads[0]
        queryClient.invalidateQueries(confirmedShipmentKeys.all)
        loads.forEach(updatedLoad => {
          updateCachedLoad({ updatedLoad })
        })

        toast.success(
          `${isArchived ? 'Archived' : 'Unarchived'} ${pluralize(
            'shipment',
            loads.length,
          )} successfully.`,
        )
      },
    },
  )

  const canArchive = status !== 'awarded' && status !== 'pending'
  const isArchiving = mutation.isLoading

  function archive({ loadIds, shouldArchive }: ArchiveArgs) {
    if (isArchiving || !canArchive) return

    return mutation.mutateAsync({ loadIds, shouldArchive }).catch(() => {
      toast.error(
        `Unable to ${shouldArchive ? 'archive' : 'unarchive'} ${pluralize(
          'shipment',
          loadIds.length,
        )}, ${supportMessage}`,
      )
    })
  }

  return {
    archive,
    canArchive,
    isArchiving,
  }
}
