import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'

import { dateToStringWithLocalTimezoneAbbr } from '@fv/client-core'
import { type UpdateBidExpirationDto } from '@fv/models'

import { shipperFetch } from '../../utils/shipperFetch'

type UpdateDto = {
  loadId: string
  localDateTime?: Date
}

const postBidExpirationDate = async ({ loadId, localDateTime }: UpdateDto) => {
  const date = dayjs(localDateTime)
  const body: UpdateBidExpirationDto = !localDateTime
    ? { local: null, utc: null }
    : {
        local: dateToStringWithLocalTimezoneAbbr(date),
        utc: date.utc().toDate(),
      }
  return await shipperFetch(`/shipments/${loadId}/update-bid-expiration-date`, {
    body,
    method: 'POST',
  })
}

export const useUpdateBidExpirationDate = () => {
  return useMutation(postBidExpirationDate)
}
