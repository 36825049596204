import { useQuery, useQueryClient } from '@tanstack/react-query'

import { type TrackDetail } from '@fv/client-types'

import { shipperFetch } from '../../utils/shipperFetch'
import { filterAndSortEvents } from './utils'

export const trackingKeys = {
  all: ['tracking'] as const,
  load: (id: string) => [...trackingKeys.all, id] as const,
}

async function fetchTracking(loadId: string) {
  const data = await shipperFetch<TrackDetail>(`/shipments/${loadId}/track`)
  const sortEvents = (detail: TrackDetail) => {
    let events = filterAndSortEvents(detail.trackEvents)
    if (events[0]?.summary === detail.currentLocation?.summary)
      events = events.slice(1)
    return events
  }

  const items = data.items ?? []

  return {
    ...data,
    trackEvents: sortEvents(data),
    items: items.map(i => ({
      ...i,
      trackEvents: sortEvents(i),
    })),
  }
}

export function usePrefetchTracking() {
  const queryClient = useQueryClient()

  return async (loadId: string) => {
    await queryClient.prefetchQuery(trackingKeys.load(loadId), () =>
      fetchTracking(loadId),
    )
  }
}

export function useTracking(loadId: string, enabled = true) {
  return useQuery(trackingKeys.load(loadId), () => fetchTracking(loadId), {
    enabled,
  })
}
