import { type PropsWithClassName, type Quote } from '@fv/client-types'
import { capitalizeFirstLetter } from '@fv/models/core'

import { quoteFuncs } from '../quote/quoteFuncs'

type Props = PropsWithClassName<{
  quote: Pick<Quote, 'charges'>
}>
export const ChargeList = ({ className, quote }: Props) => {
  const canShow = useCanShowCharges(quote)
  if (!canShow) return null
  return (
    <div
      className={`${className} divide-y divide-dotted divide-fv-gray  [&>div]:py-2`}
    >
      {quoteFuncs.sortCharges(quote.charges).map((charge, ix) => (
        <div className="flex items-center gap-x-2" key={ix}>
          <span>{capitalizeFirstLetter(charge.name)}</span>
          {charge.name === 'other' && !!charge.providerDetail && (
            <span>({charge.providerDetail})</span>
          )}
          <span className="border-t border-fv-gray border-dashed flex-1" />
          <span className="font-mono">${charge.amount}</span>
        </div>
      ))}
    </div>
  )
}

export const useCanShowCharges = (quote: Pick<Quote, 'charges'>) => {
  return quote.charges?.length > 1
}
