import { UseInfiniteQueryResult } from '@tanstack/react-query'
import {
  ColumnDef,
  getCoreRowModel,
  OnChangeFn,
  SortingState,
  Table,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer, Virtualizer } from '@tanstack/react-virtual'
import omit from 'lodash/omit'
import { MutableRefObject, useMemo, useState } from 'react'

import { MakeOptional } from '@fv/models'

type FvPaged<TData> = {
  data: TData[]
  hasMore?: boolean
}
export type FvTableOpts<TData> = MakeOptional<
  TableOptions<TData>,
  'getCoreRowModel' | 'columns'
> & {
  cols: ColumnDef<TData>[]
  initialSortState?: SortingState
}
type InfiniteOpts<TData> = Omit<FvTableOpts<TData>, 'data'> & {
  query: UseInfiniteQueryResult<FvPaged<TData>, unknown>
  sortState: TableSortState
  scrollElement: MutableRefObject<HTMLElement | null>
  estimateSize?: (index: number) => number
  overscan?: number
}

export type FvTable<TData> = Table<TData>
export type FvTableResult<TData> = {
  table: FvTable<TData>
  sortingState: TableSortState
}
export type FvVirtualizedTableResult<TData> = FvTableResult<TData> & {
  rowVirtualizer: Virtualizer<HTMLElement, Element>
}

export function useInfiniteQueryTable<TData>(
  opts: InfiniteOpts<TData>,
): FvVirtualizedTableResult<TData> {
  const [sorting, setSorting] = opts.sortState
  const data = useMemo(
    () => opts.query.data?.pages?.flatMap(page => page.data) ?? [],
    [opts.query.data],
  )
  const result = useFvTable({
    data,
    ...opts,
    state: {
      sorting,
    },
  })
  const { table, sortingState } = result

  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    overscan: opts.overscan ?? 5,
    getScrollElement: () => opts.scrollElement.current,
    estimateSize: opts.estimateSize ?? (() => 50),
  })
  const handleSortingChange: OnChangeFn<SortingState> = updater => {
    setSorting(updater)
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0)
    }
  }

  table.setOptions(prev => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }))
  return { table, sortingState, rowVirtualizer }
}

export const useFvTable = <TData>(
  opts: FvTableOpts<TData>,
): FvTableResult<TData> => {
  const sortingState = useTableSortState(opts.initialSortState)
  const [sorting, setSorting] = sortingState
  const table = useReactTable({
    columns: opts.cols ?? opts.columns,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    ...omit(opts, 'columns'),
    state: {
      sorting,
      ...(opts.state ?? {}),
    },
  })
  return { table, sortingState }
}

type TableSortState = ReturnType<typeof useTableSortState>
export const useTableSortState = (initialState?: SortingState) => {
  return useState<SortingState>(initialState ?? [])
}
