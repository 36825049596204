import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

type Props = {
  st?: boolean // static is reserved
}
export const PillHeader = ({
  children,
  className,
  st,
}: PropsWithClassName<PropsWithChildren<Props>>) => (
  <div
    className={clsx(
      className,
      { 'absolute -left-2 -top-3.5': !st },
      'inline-block rounded-full px-4 py-2',
    )}
  >
    {children}
  </div>
)
