import { ForwardedRef, forwardRef } from 'react'

import { copyToClipboard } from '@fv/client-core'

import { FvButton } from './FvButton'
import { Tooltip } from './Tooltip'

type Props = {
  value: string
}
export const ClipboardButton = forwardRef(
  ({ value }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    if (!value) {
      return null
    }
    return (
      <Tooltip label="Copy">
        <FvButton
          ref={ref}
          icon="clone"
          transform="shrink-3"
          onClick={() => copyToClipboard(value)}
        />
      </Tooltip>
    )
  },
)
